import React from "react";

const Logout = () => {
  return (
    <div>
      <h2>gvhggg</h2>
    </div>
  );
};

export default Logout;
