import React, { useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Card,
  Grid,
  Tooltip,
  TextField,
  Typography,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import TableHead from "@mui/material/TableHead";
import { useEffect } from "react";
import AddDesignation from "../HumanResource/AddDesignation";
import DeleteModal from "../Academic/DeleteModal";
import { urls } from "../../../services/urlConstant";
import { del, get, post, put } from "../../../services/apis";

import styles from "../../../styles/components/Layout/Administrator/UserRole.module.css";

const Designation = () => {
  const [schoolDesignation, setSchoolDesignation] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateDesignation, setUpdateDesignation] = useState(undefined);
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ designation: "asc", note: "asc" });

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUpdateDesignation(undefined);
  };

  const handleDelete = async (id) => {
    try {
      const res = await del(urls.designation.delDesignation, id);
      if (res.data.success) {
        const filteredSection = schoolDesignation.filter(
          (item) => item._id !== res.data.data._id
        );
        setSchoolDesignation(filteredSection);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e, data) => {
    try {
      e.preventDefault();
      const res = await post(urls.designation.postDesignation, data);
      setSchoolDesignation([...schoolDesignation, res.data.data]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async (data, id) => {
    try {
      const response = await put(urls.designation.putDesignation, id, data);
      setSchoolDesignation(
        schoolDesignation.map((e) => {
          if (e._id === response.data.data._id) {
            return response.data.data;
          } else {
            return e;
          }
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
  const handleEdit = async (id) => {
    try {
      setOpen(true);
      const getDesignationById = schoolDesignation.find(
        (item) => item._id === id
      );
      setUpdateDesignation(getDesignationById);
    } catch (error) {}
  };

  useEffect(() => {
    const getSchoolDesignation = async () => {
      try {
        const res = await get(urls.designation.getAllDesignation);
        setSchoolDesignation([...res.data.data]);
        setSearchFilter([...res.data.data]);
      } catch (error) {
        console.log(error);
      }
    };
    getSchoolDesignation();
  }, []);
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      setSearchFilter(
        schoolDesignation.filter((ele) => ele.designation.includes(value))
      );
    } else {
      setSearchFilter([]);
    }
  };

  const designationSorted = () => {
    console.log("designationSorted");
    if (search) {
      if (sort.designation === "asc") {
        let sortedList = [
          ...searchFilter.sort((a, b) =>
            b.designation.localeCompare(a.designation)
          ),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, designation: "des" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) =>
            a.designation.localeCompare(b.designation)
          ),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, designation: "asc" });
      }
    } else {
      if (sort.designation === "asc") {
        let sortedList = [
          ...schoolDesignation.sort((a, b) =>
            b.designation.localeCompare(a.designation)
          ),
        ];
        setSchoolDesignation(sortedList);
        setSort({ ...sort, designation: "des" });
      } else {
        let sortedList = [
          ...schoolDesignation.sort((a, b) =>
            a.designation.localeCompare(b.designation)
          ),
        ];
        setSchoolDesignation(sortedList);
        setSort({ ...sort, designation: "asc" });
      }
    }
  };

  const noteSorted = () => {
    console.log("noteSorted");
    if (search) {
      if (sort.note === "asc") {
        let sortedList = [
          ...searchFilter.sort((a, b) => b.note.localeCompare(a.note)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, note: "des" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) => a.note.localeCompare(b.note)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, note: "asc" });
      }
    } else {
      if (sort.note === "asc") {
        let sortedList = [
          ...schoolDesignation.sort((a, b) => b.note.localeCompare(a.note)),
        ];
        setSchoolDesignation(sortedList);
        setSort({ ...sort, note: "des" });
      } else {
        let sortedList = [
          ...schoolDesignation.sort((a, b) => a.note.localeCompare(b.note)),
        ];
        setSchoolDesignation(sortedList);
        setSort({ ...sort, note: "asc" });
      }
    }
  };

  console.log("searchFilter", searchFilter);
  return (
    <div>
      <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span style={{ color: "red", fontFamily: "cursive" }}>
              Designation :
            </span>
          </Grid>
        </Grid>
      </Card>
      <div className={styles.searchInputContainer}>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid xs="auto">
            <TextField
              size="small"
              value={search}
              onChange={handleSearch}
              label="Search"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </div>
      <div style={{ margin: "15px" }}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">
                      Designation
                      <span
                        onClick={designationSorted}
                        style={{ cursor: "pointer" }}
                      >
                        {sort.designation === "asc" ? "▲" : "▼"}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">
                      Note
                      <span onClick={noteSorted} style={{ cursor: "pointer" }}>
                        {sort.note === "asc" ? "▲" : "▼"}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {search
                  ? searchFilter.map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.designation}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.note}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="edit-delete">
                            <Tooltip title="Edit" disableInteractive>
                              <Button onClick={() => handleEdit(row._id)}>
                                <Edit />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Delete" disableInteractive>
                              <Button onClick={() => setDeleteModal(row._id)}>
                                <Delete />
                              </Button>
                            </Tooltip>
                          </div>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={row._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  : schoolDesignation.map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.designation}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.note}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="edit-delete">
                            <Tooltip title="Edit" disableInteractive>
                              <Button onClick={() => handleEdit(row._id)}>
                                <Edit />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Delete" disableInteractive>
                              <Button onClick={() => setDeleteModal(row._id)}>
                                <Delete />
                              </Button>
                            </Tooltip>
                          </div>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={row._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!schoolDesignation.length && !search.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </Paper>
        <div className="add-icon">
          <AddDesignation
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            handleUpdate={handleUpdate}
            updateDesignation={updateDesignation}
          />
        </div>
      </div>
    </div>
  );
};

export default Designation;
