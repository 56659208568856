import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState } from "react";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  TextField,
  Tooltip,
} from "@mui/material";
import { display } from "@mui/system";
import { get, post, put, del } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import { Clear, Delete, Edit } from "@mui/icons-material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import DeleteModal from "../Academic/DeleteModal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Gallery = (props) => {
  const [value, setValue] = React.useState(0);
  const [formData, setFormDate] = useState({});
  const [images, setImages] = useState([]);
  const [editImages, setEditImages] = useState([]);
  const [galleries, setGalleries] = useState([]);
  const [editGallery, setEditGallery] = useState({});
  const [showEdit, setShowEdit] = useState(false);
  const [date, setDate] = useState(dayjs());
  const [editDate, setEditDate] = useState(dayjs());
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [sort, setSort] = useState({
    title: "asc",
    webView: "asc",
  });

  const sortByTitle = () => {
    console.log("Title");
    if (sort.title === "asc") {
      let sortedList = [
        ...galleries.sort((a, b) => b.title.localeCompare(a.title)),
      ];

      setGalleries(sortedList);
      setSort({ ...sort, title: "des" });
    } else {
      let sortedList = [
        ...galleries.sort((a, b) => a.title.localeCompare(b.title)),
      ];
      setGalleries(sortedList);
      setSort({ ...sort, title: "asc" });
    }
  };

  const sortByWebView = () => {
    console.log("WebView");
    if (sort.webView === "asc") {
      let sortedList = [
        ...galleries.sort((a, b) => b.webView.localeCompare(a.webView)),
      ];

      setGalleries(sortedList);
      setSort({ ...sort, webView: "des" });
    } else {
      let sortedList = [
        ...galleries.sort((a, b) => a.webView.localeCompare(b.webView)),
      ];
      setGalleries(sortedList);
      setSort({ ...sort, webView: "asc" });
    }
  };

  const handleClearEditImg = async (id) => {
    const editedImage = { ...editGallery };
    editedImage.images = editedImage.images.filter((img) => img._id !== id);
    setEditGallery(editedImage);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormDate((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleEditFormChange = (e) => {
    const { name, value } = e.target;
    setEditGallery((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const formStyle = {
    width: "400px",
    margin: "10px",
  };
  useEffect(() => {
    const getGalleries = async () => {
      const res = await get("/gallery");
      setGalleries(res.data.data);
    };
    getGalleries();
  }, []);
  const handleImages = (e) => {
    setImages((prev) => [...prev, ...e.target.files]);
  };
  const handleImagesEdit = (e) => {
    setEditImages((prev) => [...prev, ...e.target.files]);
  };
  const handleGallerySubmit = async (e) => {
    e.preventDefault();
    try {
      if (!showEdit) {
        let imageIds = [];
        const formImage = new FormData();
        if (images.length > 0) {
          for (const img of images) {
            formImage.append("files", img);
          }
          const res = await post(urls.fileUpload.postFileUpload, formImage);
          imageIds = res.data.result.map((id) => id._id);
          const galleryData = {
            ...formData,
            images: imageIds,
            date: dayjs(date).format("YYYY-MM-DD"),
          };
          await post(`/gallery`, galleryData);
          const getUpdated = await get("/gallery");
          setGalleries(getUpdated.data.data);
          setValue(0);
          setFormDate({});
          setImages([]);
        }
      } else {
        let imageIds = [];
        const formImage = new FormData();
        if (editImages.length > 0) {
          for (const img of editImages) {
            formImage.append("files", img);
          }
          const res = await post(urls.fileUpload.postFileUpload, formImage);
          imageIds = res.data.result.map((id) => id._id);
        }
        const galleryData = {
          ...editGallery,
          date: dayjs(editDate).format("YYYY-MM-DD"),
          images: [...editGallery.images, ...imageIds],
        };
        await put(`/gallery/${editGallery._id}`, "", galleryData);
        const getUpdated = await get("/gallery");
        setGalleries(getUpdated.data.data);
        setShowEdit(false);
        setValue(0);
        setEditImages([]);
        setEditGallery({});
      }
    } catch (error) {
      console.log(error);
    }
  };
  const removeImage = (index) => {
    setImages(images.filter((img, pos) => pos !== index));
  };
  const removeImageEdit = (index) => {
    setEditImages(editImages.filter((img, pos) => pos !== index));
  };
  const handleEdit = (id) => {
    const editGallery = galleries.find((gallery) => gallery._id === id);
    setEditGallery(editGallery);
    setEditDate(dayjs(editGallery.date));
    setShowEdit(true);
    setValue(2);
  };
  const handleGalleryDel = async (id) => {
    try {
      const delGallery = await del(`/gallery/${id}`, "");
      if (delGallery.data.success) {
        setGalleries(galleries.filter((gallery) => gallery._id !== id));
      }
    } catch (error) {}
  };
  return (
    <Box sx={{ width: "100%", marginTop: "10px" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Gallery List" {...a11yProps(0)} />
          <Tab label="Add Gallery" {...a11yProps(1)} />
          {showEdit && <Tab label="Edit gallery" {...a11yProps(2)} />}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div
          style={{
            margin: "15px",
          }}
        >
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer>
              <Table sx={{ minWidth: 650 }}>
                <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
                  <TableRow sx={{ color: "white" }}>
                    <TableCell align="center" sx={{ color: "inherit" }}>
                      #Sl
                    </TableCell>
                    <TableCell align="center" sx={{ color: "inherit" }}>
                      <span> Gallery Title</span>
                      <span
                        onClick={sortByTitle}
                        style={{ cursor: "pointer", color: "#fff" }}
                      >
                        {sort.title === "asc" ? "▲" : "▼"}
                      </span>
                    </TableCell>
                    <TableCell align="center" sx={{ color: "inherit" }}>
                      <span> Is view on web</span>
                      <span
                        onClick={sortByWebView}
                        style={{ cursor: "pointer", color: "#fff" }}
                      >
                        {sort.webView === "asc" ? "▲" : "▼"}
                      </span>
                    </TableCell>
                    <TableCell align="center" sx={{ color: "inherit" }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {galleries.map((gallery, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{gallery.title}</TableCell>
                      <TableCell align="center">{gallery.webView}</TableCell>
                      <TableCell align="center">
                        <div className="edit-delete">
                          <Tooltip title="Edit" disableInteractive>
                            <Button onClick={() => handleEdit(gallery._id)}>
                              <Edit />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Delete" disableInteractive>
                            <Button onClick={() => setDeleteModal(gallery._id)}>
                              <Delete />
                            </Button>
                          </Tooltip>
                        </div>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleGalleryDel}
                          id={gallery._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem",
            padding: "10px 0",
          }}
        >
          <div
            style={{
              width: "800px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Paper sx={{ width: "100%" }}>
              <form onSubmit={handleGallerySubmit}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <TextField
                      style={formStyle}
                      id="outlined-basic"
                      label="Gallery Title"
                      variant="outlined"
                      name="title"
                      required
                      value={formData.title || ""}
                      onChange={handleFormChange}
                      fullWidth
                    />
                  </div>
                  <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Date"
                        inputFormat="DD-MM-YYYY"
                        value={date}
                        onChange={(val) => setDate(val)}
                        renderInput={(params) => (
                          <TextField
                            sx={{ width: 400 }}
                            fullWidth
                            required
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div>
                    <TextareaAutosize
                      style={{ ...formStyle, height: "100px" }}
                      placeholder="Note"
                      name="note"
                      required
                      value={formData.note || ""}
                      onChange={handleFormChange}
                      fullWidth
                    />
                  </div>
                  <div>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Web View
                      </InputLabel>
                      <Select
                        sx={{ width: 400 }}
                        name="webView"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formData.webView || ""}
                        label="Web View"
                        onChange={handleFormChange}
                        fullWidth
                      >
                        <MenuItem value={"yes"}>Yes</MenuItem>
                        <MenuItem value={"no"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    {images.map((image, index) => (
                      <div key={index}>
                        {image.name}
                        <IconButton onClick={() => removeImage(index)}>
                          <Clear />
                        </IconButton>
                      </div>
                    ))}
                  </div>
                  <div style={formStyle}>
                    <input
                      type="file"
                      name="image"
                      accept="image/*"
                      multiple
                      onChange={handleImages}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px",
                  }}
                >
                  <Button type="submit" variant="contained">
                    Submit
                  </Button>
                </div>
              </form>
            </Paper>
          </div>
        </div>
      </TabPanel>
      {showEdit && (
        <TabPanel value={value} index={2}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              padding: "10px 0",
            }}
          >
            <div
              style={{
                width: "800px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Paper sx={{ width: "100%" }}>
                <form onSubmit={handleGallerySubmit}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <TextField
                        style={formStyle}
                        id="outlined-basic"
                        label="Gallery Title"
                        variant="outlined"
                        name="title"
                        required
                        value={editGallery.title || ""}
                        onChange={handleEditFormChange}
                        fullWidth
                      />
                    </div>
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          label="Date"
                          inputFormat="MM/DD/YYYY"
                          value={editDate}
                          onChange={(val) => setEditDate(val)}
                          renderInput={(params) => (
                            <TextField
                              sx={{ width: 400 }}
                              fullWidth
                              required
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                    <div>
                      <TextareaAutosize
                        style={{ ...formStyle, height: "100px" }}
                        placeholder="Note"
                        name="note"
                        required
                        value={editGallery.note || ""}
                        onChange={handleEditFormChange}
                        fullWidth
                      />
                    </div>
                    <div>
                      <FormControl>
                        <InputLabel id="demo-simple-select-label">
                          Web View
                        </InputLabel>
                        <Select
                          name="webView"
                          sx={{ width: 400 }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={editGallery.webView || ""}
                          label="Web View"
                          onChange={handleEditFormChange}
                        >
                          <MenuItem value={"yes"}>Yes</MenuItem>
                          <MenuItem value={"no"}>No</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div style={formStyle}>
                      <input
                        type="file"
                        name="image"
                        accept="image/*"
                        multiple
                        onChange={handleImagesEdit}
                      />
                    </div>
                    <div>
                      {editImages.map((image, index) => (
                        <div key={index}>
                          {image.name}
                          <IconButton onClick={() => removeImageEdit(index)}>
                            <Clear />
                          </IconButton>
                        </div>
                      ))}
                    </div>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      {editGallery.images &&
                        editGallery.images.map((img) => (
                          <div>
                            <div>
                              <img
                                width="50"
                                height="50"
                                src={`${img.link}`}
                                alt=""
                              />
                            </div>
                            <div>
                              <IconButton
                                onClick={() => handleClearEditImg(img._id)}
                              >
                                <Clear />
                              </IconButton>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "10px",
                      gap: "10px",
                    }}
                  >
                    <Button size="small" type="submit" variant="contained">
                      Update
                    </Button>
                    <Button
                      size="small"
                      onClick={() => {
                        setShowEdit(false);
                        setValue(0);
                      }}
                      variant="contained"
                    >
                      Cancel
                    </Button>
                  </div>
                </form>
              </Paper>
            </div>
          </div>
        </TabPanel>
      )}
    </Box>
  );
};

export default Gallery;
