import React, { useEffect, useState } from "react";

import css from '../../../../styles/fees/FeeRemittanceAdd.module.css'
import dayjs from "dayjs";
import { urls } from "../../../../services/urlConstant";
import { get, post } from "../../../../services/apis";
import { Box, Button, Card, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const FeeRemittanceAdd = () => {
    const [formData,setFormData] = useState({});
    const [feeReceiptsBooks,setFeeReceiptBooks] = useState([]);
    const [fromDate,setFromDate] = useState(dayjs().subtract(1,'day'));
    const [toDate,setToDate] = useState(dayjs()); 
    const [totalAmount,setTotalAmount] = useState(0);
    const [toptotalAmount,setTopTotalAmount] = useState(0);
    const [submitFormData,setSubmitFormData] = useState({});
    const [employees,setEmployees] = useState([]); 


    useEffect(() => {
        const getEmps = async () => {
          try {
            const getEmps = await get(urls.employee.getAllEmployee);
            setEmployees(getEmps.data.data);
          } catch (error) {
            console.log(error);
          }
        }
        getEmps();
      },[]);

    useEffect(() => {
        const getReceiptBooks = async () => {
            try {
                const {data,status} = await get(`${urls.recepitBook.getAll}`);
                if(status > 199 && status < 299) {
                    setFeeReceiptBooks(data.data)
                }
            } catch (error) {
                console.log(error);
            }
        }
        getReceiptBooks();
    },[])
    const handleFormChange = (e) => {
        const {name,value} = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]:value
        }))
    }
    const handleEmpFormChange = (e) => {
        const {name,value} = e.target;
        setSubmitFormData((prev) => ({
            ...prev,
            [name]:value
        }))
    }
    const handleFetchTotalAmount = async (e) => {
        e.preventDefault();
        try {
            const {data,status} = await get(`${urls.feeRemittance.totalAmount}`,{
                params:{
                    receiptItem:formData.feeReceipt,
                    fromDate:fromDate,
                    toDate:toDate
                }
            });
            if(status > 199 && status < 299) {
                setTotalAmount(data.data)
                setTopTotalAmount(data.data)
                console.log(totalAmount,"jjjjjjjjjjjj")
            }
           
        } catch (error) {
            console.log(error);
        }
    }
    const handleSubmitFormChange = (event) => {
        const { name, value } = event.target;

        if (name === 'deposite') {
          const updatedDeposite = parseFloat(value) || 0;
          const updatedTotalAmount = toptotalAmount - updatedDeposite;
    
          setSubmitFormData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
          setTotalAmount( updatedTotalAmount);
        } else {
          setSubmitFormData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const data = {
                collectedFromDate:fromDate,
                collectedToDate:toDate,
                receiptType:formData.feeReceipt,
                cashDeposited:submitFormData.deposite,
                cashInHand:submitFormData.cashInHand,
                depositTo:submitFormData.depositTo,
                bankName:submitFormData.bankName,
                branchName:submitFormData.branchName,
                challanNumber:submitFormData.challanNo,
                challanImage:submitFormData.fileId,
                employeeDepositedToId:submitFormData.empdeposit
            }
            const {status,data:newFeeRemittance} = await post(`${urls.feeRemittance.create}`,data);
            if(status > 199 && status < 299) {
                console.log(newFeeRemittance)
            }
        } catch (error) {
            console.log(error);
        }
    }
    const handleFileUpload = async (e) => {
        try {
            const formDataFile = new FormData();
            formDataFile.append('files',e.target.files[0]);
            const {data:file,status} = await post(`${urls.fileUpload.postFileUpload}`,formDataFile);
            if(status > 199 && status < 299) {
                setSubmitFormData((prev) => ({
                    ...prev,
                    fileId:file.result[0]._id
                }))
            }
        } catch (error) {
            console.log(error);
        }
    }
    return(
        <div>
            <Card className={css.cardForm}>
                <Box>
                    <form onSubmit={handleFetchTotalAmount}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={3}>
                                <FormControl fullWidth required>
                                    <InputLabel>Fee Receipt</InputLabel>
                                    <Select
                                    value={formData.feeReceipt || ''}
                                    name='feeReceipt'
                                    size='small'
                                    label="Fee Receipt"
                                    onChange={handleFormChange}
                                    >
                                        {feeReceiptsBooks.map((feeReceipt) => (
                                            <MenuItem key={feeReceipt._id} value={feeReceipt._id}>{feeReceipt.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker 
                                    label="From Date"
                                    value={fromDate}
                                    onChange={(newDate) => setFromDate(newDate)}
                                    renderInput={(params) => (
                                        <TextField {...params} size="small" fullWidth />
                                    )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker 
                                    label="To Date"
                                    value={toDate}
                                    onChange={(newDate) => setToDate(newDate)}
                                    renderInput={(params) => (
                                        <TextField {...params} size="small" fullWidth />
                                    )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <Button variant='contained' type='submit'>Fetch</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Card>
            {toptotalAmount ? (
            <div>
                <div className={css.totalAmountHeader}>
                    Total Amount:- {`₹${toptotalAmount}`}
                </div>
                <Card className={css.cardForm}>
                    <Box>
                        <form style={{display:'flex',gap:'10px',flexDirection:'column'}} onSubmit={handleSubmit}>
                            <div>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <TextField 
                                        value={submitFormData.deposite || ''}
                                        name="deposite"
                                        label="Deposite Amount"
                                        onChange={handleSubmitFormChange}
                                        size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <TextField 
                                        value={`₹${totalAmount}`}
                                        name="totalAmount"
                                        label="totalAmount"
                                        disabled
                                        // onChange={handleSubmitFormChange}
                                        size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <Select 
                                        value={submitFormData.depositTo ||''}
                                        name="depositTo"
                                        label="depositTo"
                                        onChange={handleSubmitFormChange}
                                        size="small"
                                        style={{width:'220px'}}
                                        >
                                         <MenuItem value="Bank">Bank</MenuItem>
                                         <MenuItem value="Others">Others</MenuItem>
                                         </Select>
                                    </Grid>
                                    </Grid>
                            </div>
                                    {submitFormData.depositTo === 'Bank' && (
                                   
                          
                            <div>
                                  <Grid container spacing={2}>
                                 <Grid item xs={12} md={6} lg={3}>
                                        <TextField 
                                        value={submitFormData.bankName || ''}
                                        name="bankName"
                                        label="Bank Name"
                                        onChange={handleSubmitFormChange}
                                        size="small"
                                        />
                                    </Grid>                             
                                    <Grid item xs={12} md={6} lg={3}>
                                        <TextField 
                                        value={submitFormData.branchName || ''}
                                        name="branchName"
                                        label="Branch Name"
                                        onChange={handleSubmitFormChange}
                                        size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <TextField
                                        type="number"
                                        value={submitFormData.challanNo || ''}
                                        name="challanNo"
                                        label="Challan Number"
                                        onChange={handleSubmitFormChange}
                                        size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <input type="file" onChange={handleFileUpload} />
                                    </Grid>
                                </Grid>
                            </div>)}

                            {submitFormData.depositTo === 'Others' && (
                            <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={3}>
                              {/* <TextField
                                value={submitFormData.field1 || ''}
                                name="field1"
                                label="Field 1"
                                onChange={handleSubmitFormChange}
                                size="small"
                              /> */}
                           <FormControl fullWidth required>
                                    <InputLabel>Fee Receipt</InputLabel>
                                    <Select
                                    value={submitFormData.empdeposit || ''}
                                    name='empdeposit'
                                    size='small'
                                    label="Employee Deposit"
                                    onChange={handleEmpFormChange}
                                    >
                                        {employees.map((emp) => (
                                            <MenuItem key={emp._id} value={emp._id}>{emp.basicInfo.empName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                     
                          </Grid>
                          )}
                            <div>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <Button type="submit" variant="contained">Submit</Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </form>
                    </Box>
                </Card>
            </div>
            ):null}
        </div>
    )
}

export default FeeRemittanceAdd