import React from "react";
import { Link } from "react-router-dom";
import "../../styles/components/Chairman/QualifiedTeacher.scss";

const Qualifiedteacher = () => {
  return (
    <div>
      <div className="more__teacher text-center">
        <a className="teacher__quali">
          90+ Qualified Teachers
        </a>
        <Link to={'/facality'} className="teacher__btn">
          View All Here <i className="fa-solid fa-caret-right" />
        </Link>
        <Link className="teacher__car" to={'/home-careers'}>
          Careers
        </Link>
      </div>
    </div>
  );
};

export default Qualifiedteacher;
