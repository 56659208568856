import React, { useEffect, useRef } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Modal,
  styled,
  useMediaQuery,
  useTheme,
  Tooltip,
  Stack,
} from "@mui/material";
import { toast } from "react-toastify";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";

// Icons
import AddIcon from "@mui/icons-material/Add";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PermMediaOutlinedIcon from "@mui/icons-material/PermMediaOutlined";
import QuizIcon from "@mui/icons-material/Quiz";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import DeveloperModeRoundedIcon from "@mui/icons-material/DeveloperModeRounded";
import ModalDialog from "@mui/joy/ModalDialog";
import deleteIcon from "../../../../assets/Images/deleteIcon.png";

import ClearIcon from "@mui/icons-material/Clear";
import Download from "@mui/icons-material/Download";
// apis
import { urls } from "../../../../services/urlConstant";

import { get, post, put, del } from "../../../../services/apis";

import { useContext } from "react";

const Buttons1 = styled(Button)(() => ({
  backgroundColor: "#e0e0e0",
  color: "black",
  border: "1px solid e0e0e0",
  borderRadius: 20,
  fontSize: "12px",
  height: "35px",
  width: "200px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: 3,
  textTransform: "uppercase",
  marginTop: "2px",
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
}));

const style = {
  width: "100%",
  position: "relative",
  border: "2px solid white",
  bgcolor: "background.paper",
  p: 2,
};

const RadioButtons = styled(Radio)(() => ({}));

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const InputLabels = styled(InputLabel)(() => ({
  fontSize: "12px",
  fontWeight: 580,
  color: "#616161",
  paddingLeft: "10px",
}));

const Boxes = styled(Box)(() => ({
  display: "flex",
}));
const DownloadBox = styled(Box)(() => ({
  background: `rgb(133 140 223 / 19%)`,
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "10px",
  //   color: `${theme.palette.primary.main}`,
}));
export default function UploadCourseTable({
  courseId,
  chapter,
  updateList,
  courseName,
}) {
  const fileInputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [itemToDelete, setItemToDelete] = useState("");
  const [deletingContent, setDeletingContent] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [sorting, setSorting] = useState(false);
  const [contentHours, setContentHours] = useState(0);

  // video
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const [video, setVideo] = useState("");
  const [chapterLoading, setChapterLoading] = useState(false);
  //material
  const [openMaterial, setOpenMaterial] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [loadMaterial, setLoadMaterial] = useState(false);
  const [materialName, setMaterialName] = useState("");
  const [fileCode, setFileCode] = useState(null);
  const [fileCodeName, setFileCodename] = useState("");
  const [loadCode, setLoadCode] = useState(false);
  const handleOpenCode = () => setOpenCode(true);
  const [codeDescription, setCodeDescription] = useState("");
  const [openCode, setOpenCode] = useState(false);
  const handleCodeclose = () => {
    setOpenCode(false);
    setFileCode(null);
    setTitle("");
    setFileCodename("");
    setContentHours(0);
    setCodeDescription("");
    setItemToEdit(null);
  };
  const handleCodeChange = (e) => {
    const selectedFile = e.target.files[0];
    setFileCode(selectedFile);
    setFileCodename(selectedFile.name);
  };

  const handleCodeSubmit = async (event) => {
    event.preventDefault();
    setLoadCode(true);
    const formsData = new FormData();

    const material = {
      type: "CodePractice",
      orderSequence: chapter.contents ? chapter.contents.length + 1 : 1,
      title: title,
      chapterId: chapter._id,
      description: description,
      contentId: itemToEdit._id,
      contentHours,
    };

    formsData.append("material", JSON.stringify(material));

    // Convert material to JSON string
    formsData.append("file", fileCode);

    console.log(formsData, "--add");

    try {
      const response = await put(
        `${urls.courseContent.updateCourseContent}/${courseId}`,
        "",
        formsData
      );

      setLoadCode(false);
      updateList();
      handleCodeclose();
      // setTimeout(() => {
      //   handleClose();
      //   setName("");
      //   setVideoFile("");
      //   setChapterDescription("");
      // }, 1000);
    } catch (error) {
      console.error(error);
      setLoadCode(false);
    }
  };
  const handleRemoveCodeFile = () => {
    setFileCode(null);
    setFileCodename("");
  };

  const handleMaterialClose = () => {
    setOpenMaterial(false);
    setFile(null);
    setFileName("");
    setItemToEdit(null);
  };
  const handleMaterialChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);
  };
  const handleMaterialSubmit = async (event) => {
    event.preventDefault();
    setLoadMaterial(true);

    const material = {
      type: "Material",
      orderSequence: chapter.contents ? chapter.contents.length + 1 : 1,
      title: title,
      chapterId: chapter._id,
      contentId: itemToEdit._id,
      contentHours,
    };

    const formsData = new FormData();

    formsData.append("material", JSON.stringify(material));
    formsData.append("file", file);

    console.log(formsData, "--formsData");

    try {
      const response = await put(
        `${urls.courseContent.updateCourseContent}/${courseId}`,
        "",
        formsData
      );

      setLoadMaterial(false);
      updateList();
      handleMaterialClose();
      // setTimeout(() => {
      //   handleClose();
      //   setName("");
      //   setVideoFile("");
      //   setChapterDescription("");
      // }, 1000);
    } catch (error) {
      console.error(error);
      setLoadMaterial(false);
    }
  };
  const handleDownload = () => {
    try {
      window.open(itemToEdit.contentMaterial, "_blank");
    } catch (error) {
      console.error(error);
    }
  };
  const handleRemoveMaterialFile = () => {
    setFile(null);
    setFileName("");
  };
  const closeVideoModal = () => {
    setOpenVideoModal(false);
    setItemToEdit(null);
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setVideoFile(URL.createObjectURL(file));
    setVideo(file);
  };

  const handleVideoUpdate = async (e) => {
    e.preventDefault();
    setChapterLoading(true);

    const material = {
      type: "Video",
      title: title,
      description: description,
      chapterId: chapter._id,
      contentId: itemToEdit._id,
      contentHours,
    };

    const formsData = new FormData();

    formsData.append("material", JSON.stringify(material));
    formsData.append("file", video);

    try {
      const response = await put(
        `${urls.courseContent.updateCourseContent}/${courseId}`,
        "",
        formsData
      );

      setChapterLoading(false);
      updateList();
      setTimeout(() => {
        closeVideoModal();
        setTitle("");
        setVideoFile("");
        setDescription("");
      }, 500);
    } catch (error) {}
    setChapterLoading(false);
  };

  // quiz
  const [openQuiz, setOpenQuiz] = useState(false);
  const [updatingQuiz, setUpdatingQuiz] = useState(false);

  const handleCloseQuiz = () => {
    setQuizData([]);
    setOpenQuiz(false);
    setItemToEdit(null);
  };

  const handleAddQuiz = () => {
    setQuizData([
      ...quizData,
      {
        question: "",
        options: ["", "", "", ""],
        correctAnswer: "",
      },
    ]);
  };

  const removeQuiz = (index) => {
    setQuizData([...quizData.filter((q, i) => i !== index)]);
  };

  const [quizData, setQuizData] = useState([]);

  const handleQuestionChange = (e, i) => {
    setQuizData(
      quizData.map((q, index) =>
        index === i ? { ...q, question: e.target.value } : q
      )
    );
  };

  const handleSetCorrectOption = (o, questionIndex) => {
    setQuizData(
      quizData.map((q, i) =>
        i === questionIndex ? { ...q, correctAnswer: o } : q
      )
    );
  };

  const handleOptionChange = (e, questionIndex, optionIndex) => {
    let questionToUpdate = quizData.filter((q, i) => i === questionIndex)[0];
    let options = questionToUpdate.options;
    let newOptions = options.map((o, i) =>
      i === optionIndex ? e.target.value : o
    );
    setQuizData(
      quizData.map((q, i) =>
        i === questionIndex ? { ...q, options: newOptions } : q
      )
    );
  };

  const handleQuizUpdate = async (e) => {
    e.preventDefault();
    try {
      const quizToUpdate = quizData.map((q) => ({
        question: q.question,
        correctOption: q.correctAnswer,
        options: [
          { name: q.options[0] },
          { name: q.options[1] },
          { name: q.options[2] },
          { name: q.options[3] },
        ],
      }));

      if (quizToUpdate.filter((q) => !q.question || !q.options).length)
        return toast.error("Question cannot be empty");
      if (
        quizToUpdate.some((q) => q.options.some((option) => option.name === ""))
      )
        return toast.error("Option cannot be empty");

      if (quizToUpdate.filter((q) => !q.correctOption).length)
        return toast.error("Please select a correct option");

      setUpdatingQuiz(true);

      const material = {
        type: "Quiz",
        quiz: quizToUpdate,
        title: title,
        description: description,
        chapterId: chapter._id,
        contentId: itemToEdit._id,
        contentHours,
      };

      const formData = new FormData();

      formData.append("material", JSON.stringify(material));

      const response = await put(
        `${urls.courseContent.updateCourseContent}/${courseId}`,
        "",
        formData
      );
      updateList();
      setUpdatingQuiz(false);
      handleCloseQuiz();
    } catch (error) {
      console.log(error);
    }
    setUpdatingQuiz(false);
  };

  // flash cards
  const [openFlashCard, setOpenFlashCard] = useState(false);
  const [flashcard, setFlashcard] = useState("Text");
  const [flashText, setFlashText] = useState("");
  const [imageFile, setImageFile] = useState([]);
  const [image, setImage] = useState(null);
  const [updatingFlashCard, setUpdatingFlashCard] = useState(false);
  const handleChangeFlash = (event) => {
    setFlashcard(event.target.value);
    if (event.target.value !== "Image") {
      setImageFile(null);
      setImage(null);
    }
  };
  const handleUploadImg = () => {
    fileInputRef.current.click();
  };

  const handleFileChangeFlash = (event) => {
    const file = event.target.files[0];
    setImageFile(URL.createObjectURL(file));
    setImage(file);
  };
  const handleCloseFlashCard = () => {
    setItemToEdit(null);
    setOpenFlashCard(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditIconClick = (item) => {
    console.log(item, "000");
    setItemToEdit(item);
  };

  const handleUpdateFlashCard = async (e) => {
    e.preventDefault();

    try {
      const material = {
        type: "FlashCard",

        flashCard: {
          cardType: flashcard,
          text: flashText,
        },
        title: title,
        description: description,
        chapterId: chapter._id,
        contentId: itemToEdit._id,
        contentHours,
      };

      if (flashcard === "Text" && !flashText)
        return toast.error("Please enter flash card info for your flash card!");

      setUpdatingFlashCard(true);

      const formData = new FormData();
      formData.append("material", JSON.stringify(material));
      formData.append("file", image);

      const response = await put(
        `${urls.courseContent.updateCourseContent}/${courseId}`,
        "",
        formData
      );
      updateList();
      setUpdatingFlashCard(false);
      handleCloseFlashCard();
    } catch (error) {
      console.error(error);
      setUpdatingFlashCard(false);
    }
    setUpdatingFlashCard(false);
  };

  // delete
  const handleOpenDeleteModal = (item) => {
    setItemToDelete(item._id);
    setOpen(true);
  };

  const handleDelete = async () => {
    if (!itemToDelete) return toast.success("Nothing to delete!");
    try {
      const res = await put(
        urls.courseContent.deleteContent + "/" + courseId,
        "",
        { contentId: itemToDelete, chapterId: chapter._id }
      );
      updateList();
      setOpen(false);
      setItemToDelete("");
      setDeletingContent(false);
    } catch (error) {
      console.log(error);
    }
    setDeletingContent(false);
  };

  const sortContent = async (id, type) => {
    setSorting(true);
    try {
      const { data } = await put(
        urls.courseContent.changeOrderSequence + "/" + courseId,
        "",
        { contentId: id, type, chapterId: chapter._id }
      );
      updateList();
      setSorting(false);
    } catch (error) {
      console.log(error);
      setSorting(false);
    }
  };

  useEffect(() => {
    if (itemToEdit) {
      setTitle(itemToEdit.title);
      setDescription(itemToEdit.description);

      setContentHours(itemToEdit.contentHours || 0);
      if (itemToEdit.type === "FlashCard") {
        setFlashcard(itemToEdit.flashCard.cardType);
        setFlashText(itemToEdit.flashCard.text);
        setImageFile(itemToEdit.flashCard.image);
        setOpenFlashCard(true);
      } else if (itemToEdit.type === "Quiz") {
        setOpenQuiz(true);
        setQuizData(
          itemToEdit.quiz.map((q) => ({
            question: q.question,
            options: q.options.map((o) => o.name),
            correctAnswer: q.correctOption || "",
          }))
        );
      } else if (itemToEdit.type === "Video") {
        setVideoFile(itemToEdit.video);
        setOpenVideoModal(true);
      } else if (itemToEdit.type === "Material") {
        setTitle(itemToEdit.title);

        setFileName(
          itemToEdit.contentMaterial
            ? decodeURIComponent(
                itemToEdit.contentMaterial.split("/").pop()
              ).replace("%", " ")
            : ""
        );
        setOpenMaterial(true);
      } else if (itemToEdit.type === "CodePractice") {
        setOpenCode(true);

        setFileCodename(
          itemToEdit.contentMaterial
            ? decodeURIComponent(
                itemToEdit.contentMaterial.split("/").pop()
              ).replace("%", " ")
            : ""
        );
      }
    }
  }, [itemToEdit]);

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                sx={{ width: "150px", fontSize: "15px", fontWeight: "bold" }}>
                S.No
              </TableCell>
              <TableCell
                align="center"
                sx={{ width: "150px", fontSize: "15px", fontWeight: "bold" }}>
                Content Type
              </TableCell>
              <TableCell
                align="center"
                sx={{ width: "150px", fontSize: "15px", fontWeight: "bold" }}>
                Content Title
              </TableCell>

              <TableCell
                align="center"
                sx={{ width: "150px", fontSize: "15px", fontWeight: "bold" }}>
                Action
              </TableCell>

              <TableCell
                align="center"
                sx={{ width: "150px", fontSize: "15px", fontWeight: "bold" }}>
                Sort
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chapter.contents?.map((item, index) => (
              <TableRow>
                <TableCell align="center">
                  {" "}
                  <Typography sx={{ pr: "10px" }}>{index + 1}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      columnGap: "5px",
                    }}>
                    {item.type === "Video" ? (
                      <Tooltip title="Video">
                        <YouTubeIcon fontSize="small" color="error" />
                      </Tooltip>
                    ) : null}
                    {item.type === "Quiz" ? (
                      <Tooltip title="Quiz">
                        <QuizIcon fontSize="small" color="success" />
                      </Tooltip>
                    ) : null}
                    {item.type === "FlashCard" ? (
                      <Tooltip title="Flashcard">
                        <ViewAgendaIcon fontSize="small" color="warning" />
                      </Tooltip>
                    ) : null}{" "}
                    {item.type === "Material" ? (
                      <Tooltip title="Material">
                        <InsertDriveFileIcon fontSize="small" color="info" />
                      </Tooltip>
                    ) : null}
                    {item.type === "CodePractice" ? (
                      <Tooltip title="Code Practice">
                        <DeveloperModeRoundedIcon
                          fontSize="small"
                          sx={{ color: "#ffd600" }}
                        />
                      </Tooltip>
                    ) : null}
                  </Box>
                </TableCell>

                <TableCell align="center">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <Typography variant="inherit">
                      {item.title?.substring(0, 80)}
                    </Typography>
                  </Box>
                </TableCell>

                <TableCell align="center">
                  <Tooltip title="Update">
                    <IconButton onClick={() => handleEditIconClick(item)}>
                      <EditIcon
                        fontSize="small"
                        style={{
                          color: "#1b3779",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      color="error"
                      onClick={() => handleOpenDeleteModal(item)}>
                      <DeleteIcon color="error" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </TableCell>

                <TableCell align="center">
                  {index === chapter.contents.length - 1 ? null : (
                    <IconButton onClick={() => sortContent(item._id, "down")}>
                      <ArrowDownwardIcon />
                    </IconButton>
                  )}
                  {index === 0 ? null : (
                    <IconButton onClick={() => sortContent(item._id, "up")}>
                      <ArrowUpwardIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={open}
        onClose={handleClose}>
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          sx={{
            background: "#fff",
            border: "none",
          }}>
          <Box sx={{ textAlign: "center", margin: "10px auto" }}>
            <img src={deleteIcon} width={50} height={50} />
          </Box>

          <Box p={2}>
            <Typography
              id="alert-dialog-modal-description"
              textAlign="center"
              my={1}>
              Are you sure you want to remove this?
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 2,
                mt: 2,
              }}>
              <Button
                variant="plain"
                color="neutral"
                size="small"
                onClick={handleClose}
                sx={{
                  background: "#ddd",
                  ":hover": { background: "#ddd", opacity: 0.8 },
                  objectFit: "contain",
                }}>
                No,Cancel
              </Button>

              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={handleDelete}>
                Yes, I'm Sure
              </Button>
            </Box>
          </Box>
        </ModalDialog>
      </Modal>

      {/* vedio- model update--------------- */}
      {itemToEdit && itemToEdit.type === "Video" ? (
        <Dialog
          fullScreen={fullScreen}
          open={openVideoModal}
          onClose={closeVideoModal}
          aria-labelledby="responsive-dialog-title">
          <Box sx={style}>
            <form onSubmit={handleVideoUpdate}>
              <Grid container rowSpacing={2}>
                <Grid item xs={12} md={12} my={1}>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontWeight: "bolder",
                      fontSize: "20px",
                    }}>
                    Update Video for Course:{" "}
                    {courseName.find((m) => m.value === courseId)?.label}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box
                    sx={{
                      display: "flex",
                      columnGap: "5px",
                      marginBottom: "10px",
                    }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "50%",
                      }}>
                      <InputLabels>Video Name ({title.length}/80)*</InputLabels>
                      <TextField
                        fullWidth
                        size="small"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        inputProps={{ maxLength: 80 }}
                      />
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "50%",
                      }}>
                      <InputLabels>Content Hours</InputLabels>

                      <TextField
                        fullWidth
                        type="number"
                        size="small"
                        placeholder="Type here..."
                        value={contentHours}
                        onChange={(e) => setContentHours(e.target.value)}
                      />
                    </Box>
                  </Box>

                  <Button
                    sx={{ width: "100%", height: "45px" }}
                    variant="outlined"
                    size="small"
                    onClick={handleUploadButtonClick}
                    startIcon={<UploadFileIcon />}>
                    Upload Video
                  </Button>

                  {/* <Buttons1
                    sx={{
                      width: "100%",
                      height: "45px",
                      backgroundColor: "white",
                    }}
                    variant="outlined"
                    size="small"
                    onClick={handleUploadButtonClick}
                    startIcon={<UploadFileIcon />}>
                    Upload Video
                  </Buttons1> */}
                  <input
                    type="file"
                    accept="video/*"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />

                  {/* <TextField
                    fullWidth
                    size="small"
                    placeholder="Enter Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    sx={{ marginTop: "25px" }}
                  /> */}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{
                    display: "flex",
                    fontSize: "20px",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}>
                  {videoFile && (
                    <video
                      src={videoFile}
                      style={{
                        backgroundColor: "black",
                        alignItems: "center",
                        borderRadius: "10px",
                        overflow: "hidden",
                      }}
                      width="90%"
                      height="auto"
                      controls
                    />
                  )}
                </Grid>

                {!title || !videoFile ? null : (
                  <Grid
                    sx={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "center",
                      my: 1,
                    }}
                    item
                    xs={12}
                    md={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: 2,
                        alignSelf: "center",
                        flex: 1,
                        width: "100%",
                      }}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={closeVideoModal}
                        size="small">
                        Cancel
                      </Button>

                      <LoadingButton
                        type="submit"
                        variant="contained"
                        size="small"
                        sx={{
                          background: "rgb(27, 55, 121)",
                          ":hover": { background: "rgb(27, 55, 121)" },
                        }}
                        loading={chapterLoading}>
                        Submit
                      </LoadingButton>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </form>
          </Box>
        </Dialog>
      ) : null}
      {/* Flash Cards */}
      {itemToEdit && itemToEdit.type == "FlashCard" ? (
        <Dialog
          open={openFlashCard}
          onClose={handleCloseFlashCard}
          fullScreen={fullScreen}
          aria-labelledby="responsive-dialog-title">
          <Box p={2}>
            <form onSubmit={handleUpdateFlashCard}>
              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: "bolder",
                    fontSize: "20px",
                  }}>
                  Update FlashCard for Course:{" "}
                  {courseName.find((m) => m.value === courseId)?.label}
                </Typography>
              </Grid>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={6} md={6}>
                  <InputLabels>Title {title.length}/80*</InputLabels>

                  <TextField
                    fullWidth
                    value={title}
                    size="small"
                    inputProps={{ maxLength: 80 }}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabels>Content Hours</InputLabels>

                  <TextField
                    fullWidth
                    type="number"
                    size="small"
                    placeholder="Type here..."
                    value={contentHours}
                    onChange={(e) => setContentHours(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <InputLabels>Description</InputLabels>

                  <TextField
                    value={description}
                    fullWidth
                    size="small"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <InputLabels>Card Type</InputLabels>

                  <FormControl required fullWidth>
                    <Select
                      value={flashcard}
                      onChange={handleChangeFlash}
                      displayEmpty
                      size="small"
                      inputProps={{ "aria-label": "Without label" }}>
                      <MenuItem value="Text">Flashcard Text</MenuItem>
                      <MenuItem value="Image">Flashcard Image</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  {flashcard === "Text" ? (
                    <>
                      <TextField
                        value={flashText}
                        required
                        fullWidth
                        size="small"
                        placeholder="Enter Flashcard Text"
                        onChange={(e) => setFlashText(e.target.value)}
                      />
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} md={12} my={2}>
                        {flashcard === "Image" && imageFile && (
                          <>
                            <img
                              src={imageFile}
                              alt="Flashcard"
                              style={{ width: 300 }}
                            />
                          </>
                        )}
                      </Grid>
                      <Button
                        variant="outlined"
                        size="small"
                        startIcon={<UploadFileIcon />}
                        onClick={handleUploadImg}>
                        Upload Image
                      </Button>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChangeFlash}
                        style={{ display: "none" }}
                        ref={fileInputRef}
                      />
                    </>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    gap: 2,
                    mt: 2,
                  }}>
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={handleCloseFlashCard}>
                    Cancel
                  </Button>
                  <LoadingButton
                    variant="contained"
                    size="small"
                    sx={{
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                    }}
                    onClick={handleUpdateFlashCard}
                    loading={updatingFlashCard}>
                    Submit
                  </LoadingButton>
                </Box>
              </Grid>
            </form>
          </Box>
        </Dialog>
      ) : null}
      {/* Quiz model----------------------- */}
      {itemToEdit && itemToEdit.type == "Quiz" ? (
        <Dialog
          open={openQuiz}
          onClose={handleCloseQuiz}
          fullScreen={fullScreen}
          aria-labelledby="responsive-dialog-title">
          <form onSubmit={handleQuizUpdate}>
            <Box sx={{ padding: 2 }}>
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  fontSize: "20px",
                  marginBottom: "20px",
                }}>
                Update Quiz:{" "}
                {courseName.find((m) => m.value === courseId)?.label}
              </Typography>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} md={6}>
                  <InputLabels> Title {title.length}/80*</InputLabels>

                  <TextField
                    fullWidth
                    size="small"
                    value={title}
                    name="title"
                    sx={{ marginBottom: "25px" }}
                    inputProps={{ maxLength: 80 }}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <InputLabels>Content Hours</InputLabels>

                  <TextField
                    fullWidth
                    type="number"
                    size="small"
                    placeholder="Type here..."
                    value={contentHours}
                    onChange={(e) => setContentHours(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <InputLabels> Description</InputLabels>

                  <TextField
                    fullWidth
                    value={description}
                    size="small"
                    multiline
                    name="description"
                    sx={{ marginBottom: "25px" }}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Grid>
              </Grid>

              {quizData?.map((q, questionIndex) => (
                <Grid
                  container
                  rowSpacing={2}
                  key={questionIndex}
                  sx={{
                    marginTop: "20px",
                    marginBottom: "40px",
                    backgroundColor: theme.palette.grey[100],
                    borderRadius: "5px",
                    padding: "0 15px 20px 15px",
                    border: "1px solid lightgrey",
                  }}>
                  <Grid item xs={12} md={12}>
                    <InputLabels>
                      {questionIndex + 1}.{" "}
                      {q.question
                        ? "Update Your Question!"
                        : "Write your question"}
                    </InputLabels>
                    <TextField
                      fullWidth
                      size="small"
                      name="question"
                      placeholder="Type here..."
                      value={q.question}
                      onChange={(e) => handleQuestionChange(e, questionIndex)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {questionIndex > 0 ? (
                              <IconButton
                                color="error"
                                size="small"
                                onClick={() => removeQuiz(questionIndex)}>
                                <DeleteIcon fontSize="small" color="error" />
                              </IconButton>
                            ) : null}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Grid container rowGap={2} columnSpacing={2}>
                      {q.options.map((o, i) => (
                        <Grid key={i} item xs={12} md={6}>
                          <Boxes
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}>
                            <RadioButtons
                              size="small"
                              {...label}
                              color="default"
                              checked={q.correctAnswer && q.correctAnswer === o}
                              onChange={(e) =>
                                handleSetCorrectOption(o, questionIndex)
                              }
                              value={o}
                              type="radio"
                            />
                            <InputLabels>Option {i + 1}</InputLabels>
                          </Boxes>
                          <TextField
                            fullWidth
                            size="small"
                            name={`option${i + 1}`}
                            value={o}
                            onChange={(e) =>
                              handleOptionChange(e, questionIndex, i)
                            }
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              ))}

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "15px",
                      marginBottom: "15px",
                    }}>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={handleAddQuiz}
                      startIcon={<AddIcon />}>
                      Question
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      gap: 2,
                    }}>
                    <Button
                      variant="contained"
                      size="small"
                      color="error"
                      onClick={handleCloseQuiz}>
                      Cancel
                    </Button>

                    <LoadingButton
                      type="submit"
                      size="small"
                      sx={{
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                      variant="contained"
                      loading={updatingQuiz}>
                      Submit
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Dialog>
      ) : null}

      {/* Materials...---------- */}
      {itemToEdit && itemToEdit.type == "Material" ? (
        <Dialog
          fullScreen={fullScreen}
          open={openMaterial}
          onClose={handleMaterialClose}
          aria-labelledby="responsive-dialog-title">
          <form onSubmit={handleMaterialSubmit}>
            <Grid container spacing={2} p={2}>
              <Grid item xs={12} md={12} my={1}>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: "bolder",
                    fontSize: "20px",
                  }}>
                  Update Material:{" "}
                  {courseName.find((m) => m.value === courseId)?.label}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabels>Material Name</InputLabels>
                <TextField
                  fullWidth
                  size="small"
                  name="title"
                  required
                  value={title}
                  placeholder="Enter Material Name"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabels>Content Hours</InputLabels>

                <TextField
                  fullWidth
                  type="number"
                  size="small"
                  placeholder="Type here..."
                  value={contentHours}
                  onChange={(e) => setContentHours(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Tooltip title="Upload Material">
                  <Button
                    component="label"
                    variant="outlined"
                    fullWidth
                    sx={{
                      padding: "10px",
                      // maxWidth: 200,
                    }}
                    startIcon={<FileUploadIcon fontSize="small" />}
                    size="small">
                    upload material
                    <input
                      hidden
                      type="file"
                      onChange={handleMaterialChange}
                      style={{ display: "block" }}
                    />
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item xs={12} md={12}>
                {fileName ? (
                  <DownloadBox px={2} py={1}>
                    <Typography>{fileName}</Typography>
                    <Stack direction={"row"}>
                      {itemToEdit.contentMaterial ? (
                        <Button
                          size="small"
                          onClick={handleDownload}
                          color="error">
                          <Download fontSize="small" color="primary" />
                        </Button>
                      ) : null}

                      <Button
                        size="small"
                        onClick={handleRemoveMaterialFile}
                        color="error">
                        <ClearIcon fontSize="small" />
                      </Button>
                    </Stack>
                  </DownloadBox>
                ) : (
                  ""
                )}
              </Grid>

              <Grid
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
                item
                xs={12}
                md={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 2,
                    alignSelf: "center",
                    flex: 1,
                    width: "100%",
                  }}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleMaterialClose}
                    size="small">
                    Cancel
                  </Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    size="small"
                    sx={{
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                    }}
                    loading={loadMaterial}>
                    Submit
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Dialog>
      ) : null}

      {/*Code Practice....---------------*/}
      {itemToEdit && itemToEdit.type == "CodePractice" ? (
        <Dialog
          fullScreen={fullScreen}
          open={openCode}
          onClose={handleCodeclose}
          aria-labelledby="responsive-dialog-title">
          <form onSubmit={handleCodeSubmit}>
            <Grid container spacing={2} p={2}>
              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}>
                  Add Material:{" "}
                  {courseName.find((m) => m.value === courseId)?.label}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                // sx={{
                //   textAlign: "center",
                //   display: "flex",
                //   alignItems: "center",
                //   justifyContent: "space-between",
                //   columnGap: 2,
                // }}
              >
                <InputLabels>Title</InputLabels>
                <TextField
                  fullWidth
                  size="small"
                  name="title"
                  required
                  value={title}
                  placeholder="Enter Title"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabels>Content Hours</InputLabels>

                <TextField
                  fullWidth
                  type="number"
                  size="small"
                  placeholder="Type here..."
                  value={contentHours}
                  onChange={(e) => setContentHours(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <InputLabels> Description</InputLabels>

                <TextField
                  fullWidth
                  value={description}
                  multiline
                  size="small"
                  name="description"
                  sx={{ marginBottom: "25px" }}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                {" "}
                <Tooltip title="Upload Material">
                  <Button
                    component="label"
                    variant="outlined"
                    fullWidth
                    sx={{
                      padding: "10px",
                      // maxWidth: 200,
                    }}
                    startIcon={<FileUploadIcon fontSize="small" />}
                    size="small">
                    upload material
                    <input
                      hidden
                      type="file"
                      onChange={handleCodeChange}
                      style={{ display: "block" }}
                    />
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item xs={12} md={12}>
                {fileCodeName ? (
                  <DownloadBox px={2} py={1}>
                    <Typography>{fileCodeName}</Typography>
                    <Button onClick={handleRemoveCodeFile} color="error">
                      <ClearIcon />
                    </Button>
                  </DownloadBox>
                ) : (
                  ""
                )}
              </Grid>

              <Grid
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
                item
                xs={12}
                md={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 2,
                    alignSelf: "center",
                    flex: 1,
                    width: "100%",
                  }}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleCodeclose}
                    size="small">
                    Cancel
                  </Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    sx={{
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                    }}
                    size="small"
                    loading={loadCode}>
                    Submit
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Dialog>
      ) : null}
    </>
  );
}
