import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Delete } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { get, post, put, del } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import DeleteModal from "../../Layout/Academic/DeleteModal";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 550,
  bgcolor: "background.paper",
  p: 4,
};
const styles = {
  cardsMargin: {
    margin: "15px",
  },
  cardStyle: {
    margin: "15px",
    padding: "10px",
  },
  icon: {
    padding: "10px",
    fontSize: "12px",
    fontWeight: 500,
  },
  card: {
    padding: 15,
    marginBottom: 20,
    width: "100%",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
    marginLeft: "5px",
    marginTop: "5px",
  },

  button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
    marginLeft: "5px",
    marginTop: "5px",
  },

  addbutton: {
    paddingTop: "6.2px",
    paddingBottom: "6.2px",
  },
};

const VehicleMaintenanceView = () => {
  const [tabValue, setTabValue] = useState(0);
  const [date, setDate] = useState(dayjs(Date.now()));

  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [addForm, setAddForm] = useState({});
  const [addFormFuel, setAddFormFuel] = useState({
    toDate: "",
    fromDate: "",
  });
  const [fromDate, setFromDate] = useState(Date.now());
  const [toDate, setToDate] = useState(Date.now());
  const [file, setFile] = useState();
  const [vehicleNumber, setVehicleNumber] = React.useState([]);
  const [firmName, setFirmName] = React.useState([]);
  const [editFuel, setEditFuel] = useState();
  const [list, setList] = React.useState([]);
  const [selectedVehicleNumber, setSelectedVehicleNumber] = useState("");
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [editVehicleFuel, setEditVehicleFuel] = useState();
  const [loadingBtn, setLoadingBtn] = useState(false);

  useEffect(() => {
    getFuel();
  }, []);

  const getFuel = async () => {
    try {
      const { data, status } = await get(urls.vehicleFuel.getList, {
        params: {
          vehicleNumber: addForm.vehicleNumber,
          firmName: addForm.firmName,
          fromDate: fromDate,
          toDate: toDate,
        },
      });
      setList(data.data);
      console.log(data.data, "jjjjjkkkkkkfff");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getVehicleNumber = async () => {
      try {
        const { data, status } = await get(`${urls.transport.getList}`, {});
        if (status > 199 && status < 299) {
          setVehicleNumber(data.data);
          console.log(data.data, "dattttttttttt");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getVehicleNumber();
  }, []);

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name == "vehicleNumber") {
      let vehicle = vehicleNumber.filter((v) => v._id == value)[0];
      setSelectedVehicleNumber(vehicle.vehicleNumber);
    }
  };

  const handleUpdateDate = (e) => {
    const { name, value } = e.target;
    setAddFormFuel((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleDeleteone = async (id) => {
    try {
      const res = await del(`${urls.vehicleFuel.delete}`, id);
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClickOpen = () => {
    if (!addForm.vehicleNumber)
      return alert("Please select the vehicle number");
    setOpen(true);
  };
  const handleClose = () => {
    setEditVehicleFuel(null);
    setOpen(false);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  useEffect(() => {
    const getFirmName = async () => {
      try {
        const { data, status } = await get(`${urls.firm.getList}`);
        if (status > 199 && status < 299) {
          setFirmName(data.data);
          console.log(data.data, "firmnammmmmmmeeee");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getFirmName();
  }, []);

  // const handleAddSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const { status } = await post(`${urls.vehicleFuel.create}`, {
  //       vehicleNumber: addForm.vehicleNumber,
  //       firmName: addForm.firmName,
  //       date: date,
  //       billNo: addForm.billNo,
  //       fuelQuantity: addForm.fuelQuantity,
  //       rate: addForm.rate,
  //       amount: addForm.amount,
  //       kiloMeter: addForm.kiloMeter,
  //       mileage: addForm.mileage,
  //       note: addForm.note,
  //     });
  //     getFuel();
  //     handleClose();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!editVehicleFuel) {
        const { status } = await post(`${urls.vehicleFuel.create}`, {
          vehicleNumber: addForm.vehicleNumber,
          firmName: addForm.firmName,
          date: date,
          billNo: addForm.billNo,
          fuelQuantity: addForm.fuelQuantity,
          rate: addForm.rate,
          amount: addForm.amount,
          kiloMeter: addForm.kiloMeter,
          mileage: addForm.mileage,
          note: addForm.note,
        });
        getFuel();
        handleClose();
      } else {
        const { status } = await put(
          `${urls.vehicleFuel.update}/${editVehicleFuel}`,
          "",
          {
            vehicleNumber: addForm.vehicleNumber,
            firmName: addForm.firmName,
            date: date,
            billNo: addForm.billNo,
            fuelQuantity: addForm.fuelQuantity,
            rate: addForm.rate,
            amount: addForm.amount,
            kiloMeter: addForm.kiloMeter,
            mileage: addForm.mileage,
            note: addForm.note,
          }
        );
        getFuel();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFuelEdit = (id, data) => {
    setEditVehicleFuel(id);
    setAddForm({
      vehicleNumber: data.vehicleNumber,
      firmName: data.firmName,
      date: date,
      billNo: data.billNo,
      fuelQuantity: data.fuelQuantity,
      rate: data.rate,
      amount: data.amount,
      kiloMeter: data.kiloMeter,
      mileage: data.mileage,
      note: data.note,
    });
    handleClickOpen();
  };

  const handleGetPrintPdf = async (e) => {
    e.preventDefault();

    try {
      const getPrint = await get(
        `/vehiclefuel/downloadpdf/${addForm.vehicleNumber}/${addForm.firmName}/${fromDate}/${toDate}`,
        {
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(getPrint.data);
      window.open(uri, "__blank");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div style={styles.cardsMargin}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid>
              <Button
                variant="contained"
                style={styles.addbutton}
                onClick={handleClickOpen}
              >
                <AddIcon />
                Add
              </Button>
            </Grid>
          </Grid>
        </div>

        <div style={{ marginTop: "5px" }}>
          <Card style={styles.card}>
            <Box>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} lg={3}>
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 227 }}
                    >
                      <InputLabel required sx={{ fontSize: 12 }}>
                        Vehicle Number
                      </InputLabel>
                      <Select
                        labelId="demo-simpless-select-filled-label"
                        id="demo-simple-select-filled-l"
                        label="vehicle number"
                        name="vehicleNumber"
                        onChange={handleAddForm}
                        value={addForm.vehicleNumber || ""}
                      >
                        {vehicleNumber.map((name) => (
                          <MenuItem key={name._id} value={name._id}>
                            {name.vehicleNumber}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 227 }}
                    >
                      <InputLabel required sx={{ fontSize: 12 }}>
                        Firm Name
                      </InputLabel>
                      <Select
                        labelId="demo-simpless-select-filled-label"
                        id="demo-simple-select-filled-l"
                        label="Firm Name"
                        name="firmName"
                        onChange={handleAddForm}
                        value={addForm.firmName || ""}
                      >
                        {firmName.map((name) => (
                          <MenuItem key={name._id} value={name._id}>
                            {name.firmName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3} sx={{ alignSelf: "center" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={2}>
                        <DatePicker
                          label="From Date"
                          inputFormat="DD-MM-YYYY"
                          value={fromDate || ""}
                          onChange={setFromDate}
                          renderInput={(params) => (
                            <TextField variant="standard" {...params} />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3} sx={{ alignSelf: "center" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={2}>
                        <DatePicker
                          label="To Date"
                          inputFormat="DD-MM-YYYY"
                          value={toDate || ""}
                          onChange={setToDate}
                          renderInput={(params) => (
                            <TextField variant="standard" {...params} />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Stack spacing={2} direction="row">
                  <Button
                    variant="contained"
                    onClick={getFuel}
                    style={styles.button}
                  >
                    Find
                  </Button>

                  <LoadingButton
                    onClick={handleGetPrintPdf}
                    loading={loadingBtn}
                  >
                    <Button variant="contained" style={styles.Button}>
                      Print
                    </Button>{" "}
                  </LoadingButton>
                </Stack>
              </form>
            </Box>
          </Card>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form className="form-tag" onSubmit={handleAddSubmit}>
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign="center"
                marginBottom="30px"
                onChange={handleAddForm}
              >
                {editVehicleFuel ? "EDIT" : "ADD"}
              </Typography>
              <div>
                <h4>{selectedVehicleNumber}</h4>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 227 }}>
                  <InputLabel required sx={{ fontSize: 12 }}>
                    Firm Name
                  </InputLabel>
                  <Select
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    label="Firm Name"
                    name="firmName"
                    onChange={handleAddForm}
                    value={addForm.firmName || ""}
                  >
                    {firmName.map((name) => (
                      <MenuItem key={name._id} value={name._id}>
                        {name.firmName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date"
                    value={date}
                    onChange={(newValue) => {
                      setDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{ m: 1.3, minWidth: 227 }}
                        variant="standard"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>

                <TextField
                  required
                  id="filled-required"
                  variant="standard"
                  sx={{ m: 1, minWidth: 227 }}
                  label="Bill No"
                  name="billNo"
                  onChange={handleAddForm}
                  value={addForm.billNo || ""}
                />
              </div>
              <div>
                <TextField
                  required
                  id="filled-required"
                  variant="standard"
                  sx={{ m: 1, minWidth: 227 }}
                  label="Fuel Qnty"
                  name="fuelQuantity"
                  onChange={handleAddForm}
                  value={addForm.fuelQuantity || ""}
                />
                <TextField
                  required
                  id="filled-required"
                  variant="standard"
                  label="Rate"
                  name="rate"
                  value={addForm.rate || ""}
                  onChange={handleAddForm}
                  sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                />

                <TextField
                  required
                  id="filled-required"
                  variant="standard"
                  label="Amount"
                  name="amount"
                  value={addForm.amount || ""}
                  onChange={handleAddForm}
                  sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                />
                <div>
                  <TextField
                    required
                    id="filled-required"
                    variant="standard"
                    sx={{ m: 1, minWidth: 227 }}
                    label="KM"
                    name="kiloMeter"
                    onChange={handleAddForm}
                    value={addForm.kiloMeter || ""}
                  />
                  <TextField
                    required
                    id="filled-required"
                    variant="standard"
                    label="Message"
                    name="note"
                    value={addForm.note || ""}
                    onChange={handleAddForm}
                    sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                  />
                </div>

                <div className="cancel-submit-btn">
                  <div>
                    <Tooltip title="Cancel">
                      <Button
                        onClick={handleClose}
                        variant="outlined"
                        size="medium"
                        type="button"
                        sx={{ m: 1 }}
                      >
                        Cancel
                      </Button>
                    </Tooltip>
                    <Tooltip title="Submit">
                      <Button
                        variant="contained"
                        size="medium"
                        type="submit"
                        sx={{
                          background: "rgb(27, 55, 121)",
                          ":hover": { background: "rgb(27, 55, 121)" },
                        }}
                      >
                        Add
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </Box>
          </form>
        </Modal>

        <Table>
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow sx={{ color: "white" }}>
              <TableCell sx={{ color: "white" }} align="center">
                #SL
              </TableCell>

              <TableCell sx={{ color: "white" }} align="center">
                Date
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Firm
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Bill No
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Amount
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                KM
              </TableCell>

              <TableCell sx={{ color: "white" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((fuel, index) => (
              <TableRow>
                <TableCell align="center">{index + 1}</TableCell>

                <TableCell align="center">
                  {dayjs(fuel.date).format("DD-MM-YYYY")}
                </TableCell>

                <TableCell align="center">{fuel.firmName.firmName}</TableCell>
                <TableCell align="center">{fuel.billNo}</TableCell>

                <TableCell align="center">{fuel.amount}</TableCell>
                <TableCell align="center">{fuel.kiloMeter}</TableCell>
                <TableCell align="center">
                  <div className="edit-delete">
                    <Button
                      style={{ color: "#1b3779" }}
                      onClick={() => handleFuelEdit(fuel._id, fuel)}
                    >
                      <EditIcon />
                    </Button>
                    <Button
                      style={{
                        color: "#1b3779",
                      }}
                      onClick={() => setDeleteModal(fuel._id, fuel)}
                    >
                      <Delete />
                    </Button>
                    <DeleteModal
                      deleteModal={deleteModal}
                      handleDelete={handleDeleteone}
                      id={fuel._id}
                      setDeleteModal={setDeleteModal}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {!list.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
      </div>
    </div>
  );
};

export default VehicleMaintenanceView;
