import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "./../styles/components/navbar/Navbar.scss";
import { Language } from "@mui/icons-material";
import "./../styles/components/navbar/NavTop.scss";
import css from "./Addmission.module.css";
import Footer from "./Footer/Footer";
import TopNav from "./navbar/TopNav";
import Navbar from "./navbar/Navbar";
import { get } from "../services/apis";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Card, CardContent, Typography } from "@mui/material";
import {
  useTheme,
  useMediaQuery,
  GridList,
  GridListTile,
  makeStyles,
  Modal,
  Backdrop,
} from "@material-ui/core";

import "react-slideshow-image/dist/styles.css";
import "./gallery.css";
import { Slide } from "react-slideshow-image";

const useStyles = makeStyles((theme) => ({
  gridList: {
    display: "flex",
    flexDirection: "row",
    // flexWrap: "wrap",
    transform: "translateZ(0)",
  },
  modal: {
    display: "flex",
    // flexDirection:"row",
    alignItems: "center",

    justifyContent: "center",
    "&:hover": {
      backgroundcolor: "red",
    },
  },
  url: {
    width: "500px",
    height: "300px",
    outline: "none",
  },
}));
const GalleryHome = () => {
  const classes = useStyles();
  const [addClass, updateClass] = useState(false);
  const [stkyClass, setStkyClass] = useState(false);
  const [mega, setMega] = useState(false);
  const [galleries, setGalleries] = useState([]);
  const [image, setImage] = useState([]);
  const [isPopUp, setIsPopUp] = useState(false);
  const [open, setOpen] = useState(0);

  const handleClickCross = () => {
    updateClass(false);
    window.scrollTo(0, 0);
  };
  const scrollHandleScroll = () => {
    if (window.scrollY > 20) {
      setStkyClass(true);
    } else {
      setStkyClass(false);
    }
  };
  const handleMega = () => {
    setMega(!mega);
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollHandleScroll);
  }, []);

  const menuItems = [
    {
      title: "Home",
      children: [],
    },
    {
      title: "About",
      children: [
        {
          title: "Our School",
          link: "/our-school",
        },
        {
          title: "Principal Desk",
          link: "/principal-desk",
        },
        {
          title: "Faculty",
          link: "/faculty",
        },
        {
          title: "Guardian Feedback",
          link: "/guardian-feedback",
        },
      ],
    },
    {
      title: "Gallery",
      children: [],
    },
    {
      title: "Academics",
      children: [],
    },
    {
      title: "Facilities",
      children: [
        {
          title: "Library",
          link: "/library",
        },
        {
          title: "Sports",
          link: "/sports",
        },
        {
          title: "Canteen",
          link: "/canteen",
        },
        {
          title: "Transport",
          link: "/transport",
        },
        {
          title: "Edusmart",
          link: "/Edusmart",
        },
      ],
    },
    {
      title: "Jobs",
      children: [],
    },
    {
      title: "Exam",
      children: [
        {
          title: "Exam Time Table",
          link: "/birth-day",
        },
        {
          title: "Hall Ticket",
          link: "/annual-day",
        },
        {
          title: "Results",
          link: "/freshers-day",
        },
      ],
    },
    {
      title: "Contact Us",
      children: [],
    },
  ];
  useEffect(() => {
    const getgalleryRes = async () => {
      const galleryRes = await get("/gallery", {
        params: {
          search: {
            webView: "yes",
          },
        },
      });
      setGalleries(galleryRes.data.data);
    };
    getgalleryRes();
  }, []);
  useEffect(() => {
    const getgallery = async () => {
      const imagess = [];
      const response = await get("/gallery");
      const { success, data } = response.data;
      console.log(data);
      for (let i = 0; i < data.length; i++) {
        // for( let j=0;j<data[i].images.length;j++){

        imagess.push(data[i].images);

        // }
      }
      console.log("images", imagess);
      if (success) {
        // const images=imagess.slice(0,8);
        setImage(imagess);
      }
    };
    getgallery();
  }, []);

  function handleImageClick(value) {
    setOpen(true);
    setIsPopUp(image[value]);
    console.log("popup2", image[value]);
  }

  const handleClose = () => {
    setOpen(false);
  };

  function handlePrevClick() {
    let selectindex = image.findIndex((img) => img._id === isPopUp._id);
    setIsPopUp(image[selectindex - 1]);
  }

  function handleNextClick() {
    let selectindex = image.findIndex((img) => img._id === isPopUp._id);
    setIsPopUp(image[selectindex + 1]);
  }
  const handleImageGroupClick = (gallery) => {
    setImage(gallery.images);
    setIsPopUp(gallery.images[0]);
    setOpen(true);
  };
  return (
    <>
      <TopNav />
      <Navbar />
      <div className={css.headerContainer}>
        <div>
          <h1>GALLERY</h1>
          <h5>
            <Link
              style={{
                color: "white",
              }}
              to="/"
            >
              Home
            </Link>{" "}
            / GALLERY
          </h5>
        </div>
      </div>
      <div
        style={{
          margin: "2rem",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          gap: "3rem",
        }}
      >
        {galleries.map((gallery) => (
          <Card sx={{ width: 400 }} key={gallery._id}>
            <CardContent>
              <Slide>
                {gallery.images.map((img, index) => (
                  <div
                    key={img._id}
                    className="each-slide-effect"
                    onClick={() => handleImageGroupClick(gallery)}
                  >
                    <div
                      style={{
                        width: "400px",
                        height: "220px",
                        backgroundImage: `url(${img.link})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                    ></div>
                  </div>
                ))}
              </Slide>

              {isPopUp && (
                <Modal
                  className={classes.modal}
                  open={open}
                  onClose={handleClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 5,
                  }}
                >
                  <div className="popup-image">
                    <div className="Licon">
                      <KeyboardDoubleArrowLeftIcon onClick={handlePrevClick} />
                    </div>

                    <img
                      src={isPopUp.link}
                      alt="asd"
                      // width="700px"
                      height="530px"
                    />
                    {/* <div className="Cicon" style={{color:"white",marginBottom:"160px"}}>
        <HighlightOffIcon onClick={handleClose} />  
         </div> */}

                    <div className="Ricon">
                      <KeyboardDoubleArrowRightIcon onClick={handleNextClick} />
                    </div>
                  </div>
                </Modal>
              )}
              <Typography gutterBottom variant="h5" component="div">
                {gallery.title}
              </Typography>
              <div>{gallery.note}</div>
            </CardContent>
          </Card>
        ))}
      </div>
      <Footer />
    </>
  );
};
export default GalleryHome;
