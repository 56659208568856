import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
  Box,
  Card,
  FormControl,
  Grid,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Delete } from "@mui/icons-material";
import { get, post, put, del } from "../../services/apis";
import { urls } from "../../services/urlConstant";
import DeleteModal from "../Layout/Academic/DeleteModal";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 450,
  bgcolor: "background.paper",
  p: 4,
};
const ManageRoomType = () => {
  const [search, setSearch] = useState("");
  const [open, setOpen] = React.useState(false);
  const [editRoomType, setEditRoomType] = useState();
  const [addForm, setAddForm] = useState({});
  const [list, setList] = React.useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [searchFilter, setSearchFilter] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setEditRoomType(null);
    setAddForm({});

    setOpen(false);
  };
  const styles = {
    button: {
      backgroundColor: "orange",
    },
    textfield: {
      marginTop: "25px",
      width: "70%",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
  };
  // const handleSearch = (e) => {
  //   const { value } = e.target;
  //   setSearch(value.trim());
  // };

  useEffect(() => {
    getRoomType();
  }, []);

  const getRoomType = async () => {
    console.log("rrr");
    try {
      const response = await get(`${urls.roomType.getList}`, {
        params: {
          search: {},
          pageNumber: 1,
          limit: 1000,
        },
      });
      setList(response.data.data);
      console.log(response.data.data, "ddddd");
    } catch (error) {
      console.log(error);
    }
  };
  console.log(list, "aaaaa");
  const handleAddSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!editRoomType) {
        const { status } = await post(`${urls.roomType.create}`, {
          name: addForm.name,
          specification: addForm.specification,
        });
        console.log(status, "ssss");
        getRoomType();
        handleClose();
      } else {
        const { status } = await put(
          `${urls.roomType.editRoomType}${editRoomType}`,
          "",
          {
            name: addForm.name,
            specification: addForm.specification,
          }
        );
        getRoomType();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleAddForm = (event) => {
    const { name, value } = event.target;
    setAddForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleClassMapEdit = (id, data) => {
    setEditRoomType(id);
    setAddForm({
      name: data.name,
      specification: data.specification,
    });
    handleClickOpen();
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(`${urls.roomType.delRoom}`, id);
      console.log(res);
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      list.length > 0 &&
        setSearchFilter(
          list.filter((ele) =>
            ele.name.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  return (
    <div>
      <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span
              style={{
                color: "red",
                fontFamily: "cursive",
                textAlign: "start",
              }}
            >
              Hostel Room Type:
            </span>
          </Grid>
        </Grid>
      </Card>
      <div style={{ margin: "15px" }}>
        <Paper sx={{ width: "100%", height: "60px", overflow: "hidden" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid xs={3}>
                <TextField
                  size="small"
                  style={styles.textfield}
                  value={search}
                  onChange={handleSearch}
                  label="Search"
                  variant="outlined"
                />
              </Grid>
              <Grid>
                <Button
                  variant="contained"
                  sx={{ mt: 3.2 }}
                  style={styles.addbutton}
                  onClick={handleClickOpen}
                >
                  <AddIcon />
                  Add Room_type
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form className="form-tag" onSubmit={handleAddSubmit}>
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign="center"
                marginBottom="30px"
              >
                {editRoomType ? "Update Room Type" : "Add Room"}
              </Typography>
              <div>
                <TextField
                  required
                  id="filled-required"
                  label="Name"
                  name="name"
                  value={addForm.name || ""}
                  onChange={handleAddForm}
                  sx={{ minWidth: 227, label: { fontSize: 12 } }}
                  style={{
                    width: 712,
                    maxHeight: 110,
                    marginTop: 25,
                  }}
                />

                <TextField
                  required
                  id="filled-required"
                  label="Specification"
                  name="specification"
                  value={addForm.specification || ""}
                  onChange={handleAddForm}
                  sx={{ minWidth: 227, label: { fontSize: 12 } }}
                  style={{
                    width: 712,
                    maxHeight: 110,
                    marginTop: 25,
                  }}
                />
              </div>
              <div className="cancel-submit-btn">
                <div>
                  <Tooltip title="Cancel">
                    <Button
                      onClick={handleClose}
                      variant="outlined"
                      size="medium"
                      type="button"
                      sx={{ m: 1 }}
                    >
                      Cancel
                    </Button>
                  </Tooltip>
                  <Tooltip title="Submit">
                    <Button
                      variant="contained"
                      size="medium"
                      type="submit"
                      sx={{
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      {editRoomType ? "update" : "Add"}
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </Box>
          </form>
        </Modal>

        <Table>
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow sx={{ color: "white" }}>
              <TableCell sx={{ color: "white" }} align="center">
                #SL
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Name
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Specification
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {search
              ? searchFilter.map((room, index) => (
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{room.name}</TableCell>
                    <TableCell align="center">{room.specification}</TableCell>

                    <TableCell align="center">
                      <div style={{ marginLeft: "30px" }}>
                        <Button
                          style={{
                            color: "#1b3779",
                          }}
                          onClick={() => handleClassMapEdit(room._id, room)}
                        >
                          <EditIcon />
                        </Button>

                        <Button
                          style={{
                            color: "#1b3779",
                          }}
                          onClick={() => setDeleteModal(room._id, room)}
                        >
                          <Delete />
                        </Button>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={room._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              : list.map((room, index) => (
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{room.name}</TableCell>
                    <TableCell align="center">{room.specification}</TableCell>

                    <TableCell align="center">
                      <div style={{ marginLeft: "30px" }}>
                        <Button
                          style={{
                            color: "#1b3779",
                          }}
                          onClick={() => handleClassMapEdit(room._id, room)}
                        >
                          <EditIcon />
                        </Button>

                        <Button
                          style={{
                            color: "#1b3779",
                          }}
                          onClick={() => setDeleteModal(room._id, room)}
                        >
                          <Delete />
                        </Button>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={room._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
        {!list.length && !search.trim() && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        {search.trim() && !searchFilter.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
      </div>
    </div>
  );
};
export default ManageRoomType;
