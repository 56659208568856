import React, { useState } from "react";
import {
  Box,
  Card,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  withStyles,
  FormHelperText,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "../../../App.scss";
import { CloudUploadRounded } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { useEffect } from "react";
import { get, post, put } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import { useNavigate } from "react-router-dom";
import { objHas } from "../../../utils/utils";
import dayjs from "dayjs";
import LoadingButton from "@mui/lab/LoadingButton";
let styles = {
  card: {
    padding: 15,
    marginBottom: 20,
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
};

const style = (theme) => ({
  field: {
    margin: "10px 0",
  },
  countryList: {
    ...theme.typography.body1,
  },
});

const AddAdmitStudent = (props) => {
  // const [studentPhoto, setStudentPhoto] = useState("");
  const [loading, setLoading] = useState(false);
  const [academicYears, setAcademicYears] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [studentDetails, setStudentDetails] = useState({});
  const [admissionDate, setAdmissionDate] = useState(dayjs());
  const [dob, setDob] = useState(dayjs());

  const navigate = useNavigate();

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setStudentDetails({ ...studentDetails, [name]: value });
  };
  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    setStudentDetails({ ...studentDetails, [name]: value });
    try {
      const res = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      setSections(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handlePhoto = async (e) => {
    console.log(e.target.files);
    const formData = new FormData();
    formData.append("files", e.target.files[0]);
    try {
      const res = await post(urls.fileUpload.postFileUpload, formData);
      console.log(res);
      const { name } = e.target;
      setStudentDetails({ ...studentDetails, [name]: res.data.result[0]._id });
      console.log(res.data.result[0]._id);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const temp = props.student ? { ...props.student.academicInfo } : {};
    try {
      const data = {
        basicInfo: {
          name: e.target.name.value,
          admissionDate: dayjs(admissionDate).toDate(),
          dob: dayjs(dob).toDate(),
          gender: e.target.gender.value,
          bloodGroup: e.target.bloodGroup.value,
          religion: e.target.religion.value,
          rte: e.target.rte.value,
          caste: e.target.caste.value,
          casteGroup: e.target.casteGroup.value,
          cicn: e.target.cicn.value,
          motherTongue: e.target.motherTongue.value,
          birthPlace: e.target.birthPlace.value,
          satNo: e.target.satNo.value,
          grNo: e.target.grNo.value,
          penNo: e.target.penNo.value,
          aadharNo: e.target.aadharNo.value,
        },
        contactInfo: {
          guardianName: e.target.guardianName.value,
          guardianPhone: e.target.guardianPhone.value,
          guardianPhone2: e.target.guardianPhone2.value,
          guardianRelation: e.target.guardianRelation.value,
          nationId: e.target.nationId.value,
          presentAddress: e.target.presentAddress.value,
          permanentAddress: e.target.permanentAddress.value,
        },
        academicInfo: {
          ...temp,
          class: e.target.class.value,
          section: e.target.section.value,
          rollNo: e.target.rollNo.value,
        },
        prevSchInfo: {
          prevSchool: e.target.prevSchool.value,
          tcNo: e.target.tcNo.value,
          transferCertificate: studentDetails.transferCertificate,
        },
        fatherInfo: {
          fatherName: e.target.fatherName.value,
          fatherPhone: e.target.fatherPhone.value,
          fatherEdu: e.target.fatherEdu.value,
          fatherProfession: e.target.fatherProfession.value,
          fatherDesignation: e.target.fatherDesig.value,
          fatherPhoto: studentDetails.fatherPhoto,
        },
        motherInfo: {
          motherName: e.target.motherName.value,
          motherPhone: e.target.motherPhone.value,
          motherEdu: e.target.motherEdu.value,
          motherProfession: e.target.motherProfession.value,
          motherDesignation: e.target.motherDesignation.value,
          motherPhoto: studentDetails.motherPhoto,
        },
        otherInfo: {
          email: e.target.email.value,
          healthCondition: e.target.healthCondition.value,
          // extraInfo: e.target.extraInfo.value,
        },
        studentPhoto: studentDetails.studentPhoto,
        academicYear: studentDetails.academicYear,
        contactNumber: e.target.contactNumber.value,
        status: e.target.status.value,
      };
      if (props.student) {
        const res = await put(`/student/${props.student._id}`, "", data);
        if (res.data.success) {
          navigate("/admitstudent");
        } else {
          console.log("error ", res.data);
        }
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      } else {
        const res = await post(urls.students.postStudent, data);
        console.log(res);
        navigate("/admitstudent");
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll);
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        setClasses(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);

  // useEffect(() => {
  //   const getSections = async () => {
  //     try {
  //       const res = await get(urls.schoolSection.getAllSection);
  //       setSections(res.data.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   getSections();
  // }, []);

  useEffect(() => {
    if (props.student) {
      const update = {
        ...props.student.basicInfo,
        ...props.student.academicInfo,
        ...props.student.contactInfo,
        ...props.student.prevSchInfo,
        ...props.student.fatherInfo,
        ...props.student.otherInfo,
        ...props.student.motherInfo,
        academicYear: props.student.academicYear._id,
        class: props.student.academicInfo.class._id,
        section: props.student.academicInfo.section
          ? props.student.academicInfo.section._id
          : "",
        contactNumber: props.student.contactNumber,
        status: props.student.status,
      };
      setStudentDetails(update);
      setAdmissionDate(dayjs(props.student.basicInfo.admissionDate));
      setDob(dayjs(props.student.basicInfo.dob));
    }
  }, [props]);
  useEffect(() => {
    const updateSection = async () => {
      try {
        const getSections = await get(urls.schoolSection.getAllSection, {
          params: {
            search: {
              class: props.student.academicInfo.class,
            },
          },
        });
        setSections(getSections.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    if (props.student) {
      updateSection();
    }
  }, [props]);
  const handleFile = async (e) => {
    console.log("reach");
    console.log(e.target.name);
    const formData = new FormData();
    if (e.target.files.length > 0) {
      formData.append("files", e.target.files[0]);
      try {
        const fileUpoad = await post(urls.fileUpload.postFileUpload, formData);
        const { name } = e.target;
        setStudentDetails({
          ...studentDetails,
          [name]: fileUpoad.data.result[0]._id,
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div style={{ margin: "15px" }}>
          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <h5 style={{ color: "#1b3779" }}>
                    <span style={{ color: "red" }}> Note:</span> Student will be
                    admited to session
                  </h5>
                </Grid>
                <Grid item sx={12} md={6} lg={3}>
                  <FormControl
                    variant="standard"
                    style={styles.textfield}
                    required
                  >
                    <InputLabel id="demo-simple-select-filled-label">
                      Academic Year
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="academicYear"
                      value={studentDetails.academicYear || ""}
                      defaultValue={objHas(
                        props,
                        "student.academicYear._id",
                        ""
                      )}
                      onChange={(event) => handleOnChange(event)}
                    >
                      {academicYears &&
                        academicYears.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.academicYearFrom} - {row.academicYearTo}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Card>
          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3} lg={3}>
                  <h5 style={{ color: "#1b3779" }}>Basic Information:</h5>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="Name"
                    name="name"
                    required
                    // value={studentDetails.name || ''}
                    // onChange={(event) => handleOnChange(event)}
                    defaultValue={objHas(props, "student.basicInfo.name", "")}
                    style={styles.textfield}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="Admission No"
                    style={styles.textfield}
                    disabled
                  />
                  <FormHelperText>Auto generated</FormHelperText>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  {/* <TextField
                    id="filled-"
                    variant="standard"
                    type="date"
                    label="Admission Date"
                    name="admissionDate"
                    value={studentDetails.admissionDate}
                    onChange={(event) => handleOnChange(event)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={styles.textfield}
                  /> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Admission Date"
                      value={admissionDate}
                      inputFormat="DD-MM-YYYY"
                      onChange={(newValue) => {
                        setAdmissionDate(newValue);
                      }}
                      required
                      renderInput={(params) => (
                        <TextField
                          style={styles.textfield}
                          variant="standard"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  {/* <TextField
                    id="filled-"
                    variant="standard"
                    label="Birth Date "
                    type="date"
                    name="dob"
                    value={studentDetails.dob}
                    onChange={(event) => handleOnChange(event)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={styles.textfield}
                  /> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date of Birth"
                      inputFormat="DD-MM-YYYY"
                      value={dob}
                      onChange={(newValue) => {
                        setDob(newValue);
                      }}
                      required
                      renderInput={(params) => (
                        <TextField
                          style={styles.textfield}
                          variant="standard"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl
                    variant="standard"
                    style={styles.textfield}
                    required
                  >
                    <InputLabel id="demo-simple-select-filled-label">
                      Gender
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="gender"
                      // value={studentDetails.gender}
                      // onChange={(event) => handleOnChange(event)}
                      defaultValue={objHas(
                        props,
                        "student.basicInfo.gender",
                        ""
                      )}
                    >
                      <MenuItem
                        value={"male"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Male
                      </MenuItem>
                      <MenuItem
                        value={"female"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Female
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl variant="standard" style={styles.textfield}>
                    <InputLabel id="demo-simple-select-filled-label">
                      Blood Group
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="bloodGroup"
                      // value={studentDetails.bloodGroup}
                      // onChange={(event) => handleOnChange(event)}
                      defaultValue={objHas(
                        props,
                        "student.basicInfo.bloodGroup",
                        ""
                      )}
                    >
                      <MenuItem
                        value={"a+"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        A+
                      </MenuItem>
                      <MenuItem
                        value={"a-"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        A-
                      </MenuItem>
                      <MenuItem
                        value={"b+"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        B+
                      </MenuItem>
                      <MenuItem
                        value={"b-"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        B-
                      </MenuItem>
                      <MenuItem
                        value={"o+"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        O+
                      </MenuItem>
                      <MenuItem
                        value={"o-"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        O-
                      </MenuItem>
                      <MenuItem
                        value={"ab+"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        AB+
                      </MenuItem>
                      <MenuItem
                        value={"ab-"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        AB-
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="Religion"
                    name="religion"
                    // value={studentDetails.religion}
                    // onChange={(event) => handleOnChange(event)}
                    style={styles.textfield}
                    defaultValue={objHas(
                      props,
                      "student.basicInfo.religion",
                      ""
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl
                    variant="standard"
                    style={styles.textfield}
                    required
                  >
                    <InputLabel id="demo-simple-select-filled-label">
                      RTE
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="rte"
                      // value={studentDetails.rte}
                      // onChange={(event) => handleOnChange(event)}
                      defaultValue={objHas(props, "student.basicInfo.rte", "")}
                    >
                      <MenuItem
                        value={"yes"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        yes
                      </MenuItem>
                      <MenuItem
                        value={"no"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        No
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="Caste"
                    name="caste"
                    // value={studentDetails.caste}
                    // onChange={(event) => handleOnChange(event)}
                    defaultValue={objHas(props, "student.basicInfo.caste", "")}
                    style={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="Caste Group"
                    name="casteGroup"
                    // value={studentDetails.caste}
                    // onChange={(event) => handleOnChange(event)}
                    defaultValue={objHas(
                      props,
                      "student.basicInfo.casteGroup",
                      ""
                    )}
                    style={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="Caste Income Certificate Number"
                    name="cicn"
                    // value={studentDetails.cicn}
                    // onChange={(event) => handleOnChange(event)}
                    defaultValue={objHas(props, "student.basicInfo.cicn", "")}
                    style={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="Mother Tongue"
                    name="motherTongue"
                    // value={studentDetails.motherTongue}
                    // onChange={(event) => handleOnChange(event)}
                    defaultValue={objHas(
                      props,
                      "student.basicInfo.motherTongue",
                      ""
                    )}
                    style={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="Birth Place"
                    name="birthPlace"
                    // value={studentDetails.birthPlace}
                    // onChange={(event) => handleOnChange(event)}
                    defaultValue={objHas(
                      props,
                      "student.basicInfo.birthPlace",
                      ""
                    )}
                    style={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    label="Aadhar No"
                    type="number"
                    id="filled-"
                    name="aadharNo"
                    // value={studentDetails.aadharNo}
                    // onChange={(event) => handleOnChange(event)}
                    defaultValue={objHas(
                      props,
                      "student.basicInfo.aadharNo",
                      ""
                    )}
                    variant="standard"
                    InputProps={{
                      inputProps: {
                        pattern: "^\\d{12}$",
                      },
                    }}
                    style={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    defaultValue={objHas(props, "student.basicInfo.satNo", "")}
                    label="SAT No"
                    name="satNo"
                    style={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="GR No"
                    defaultValue={objHas(props, "student.basicInfo.grNo", "")}
                    name="grNo"
                    style={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="PEN No"
                    defaultValue={objHas(props, "student.basicInfo.penNo", "")}
                    name="penNo"
                    style={styles.textfield}
                  />
                </Grid>
              </Grid>
            </Box>
          </Card>
          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3} lg={3}>
                    <h5
                      style={{
                        color: "#1b3779",
                        marginLeft: "16px",
                        marginTop: "16px",
                      }}
                    >
                      Contact Information:
                    </h5>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="Contact Number"
                    name="contactNumber"
                    type="number"
                    required
                    InputProps={{
                      inputProps: {
                        pattern: "^\\d{12}$",
                      },
                    }}
                    // value={studentDetails.contactNumber}
                    // onChange={(event) => handleOnChange(event)}
                    defaultValue={objHas(props, "student.contactNumber", "")}
                    style={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="Guardian Name"
                    name="guardianName"
                    required
                    // value={studentDetails.guardianName}
                    // onChange={(event) => handleOnChange(event)}
                    defaultValue={objHas(
                      props,
                      "student.contactInfo.guardianName",
                      ""
                    )}
                    style={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    variant="standard"
                    name="guardianPhone"
                    type="number"
                    required
                    // value={studentDetails.guardianPhone}
                    // onChange={(event) => handleOnChange(event)}
                    defaultValue={objHas(
                      props,
                      "student.contactInfo.guardianPhone",
                      ""
                    )}
                    label="Guardian Number"
                    style={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    variant="standard"
                    name="guardianPhone2"
                    type="number"
                    // value={studentDetails.guardianPhone2}
                    // onChange={(event) => handleOnChange(event)}
                    defaultValue={objHas(
                      props,
                      "student.contactInfo.guardianPhone2",
                      ""
                    )}
                    style={styles.textfield}
                    label="Alternate Number"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl variant="standard" style={styles.textfield}>
                    <InputLabel id="demo-simple-select-filled-label">
                      Relation With Guardian
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="guardianRelation"
                      // value={studentDetails.guardianRelation}
                      defaultValue={objHas(
                        props,
                        "student.contactInfo.guardianRelation",
                        ""
                      )}
                      // onChange={(event) => handleOnChange(event)}
                    >
                      <MenuItem
                        value={"father"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Father
                      </MenuItem>
                      <MenuItem
                        value={"mother"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Mother
                      </MenuItem>
                      <MenuItem
                        value={"sister"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Sister
                      </MenuItem>
                      <MenuItem
                        value={"brother"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Brother
                      </MenuItem>
                      <MenuItem
                        value={"uncle"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Uncle
                      </MenuItem>
                      <MenuItem
                        value={"maternalUncle"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Maternal Uncle
                      </MenuItem>
                      <MenuItem
                        value={"otherrelative"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Other Relative
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="National ID"
                    name="nationId"
                    // value={studentDetails.nationId}
                    // onChange={(event) => handleOnChange(event)}
                    defaultValue={objHas(
                      props,
                      "student.contactInfo.nationId",
                      ""
                    )}
                    style={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="Present Address"
                    name="presentAddress"
                    // value={studentDetails.presentAddress}
                    // onChange={(event) => handleOnChange(event)}
                    defaultValue={objHas(
                      props,
                      "student.contactInfo.presentAddress",
                      ""
                    )}
                    style={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    id="filled-"
                    label="Permanent Address"
                    name="permanentAddress"
                    // value={studentDetails.permanentAddress}
                    // onChange={(event) => handleOnChange(event)}
                    defaultValue={objHas(
                      props,
                      "student.contactInfo.permanentAddress",
                      ""
                    )}
                    variant="standard"
                    style={styles.textfield}
                  />
                </Grid>
              </Grid>
            </Box>
          </Card>
          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3} lg={3}>
                    <h5
                      style={{
                        color: "#1b3779",
                        marginLeft: "16px",
                        marginTop: "16px",
                      }}
                    >
                      Academic Information:
                    </h5>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl
                    variant="standard"
                    style={styles.textfield}
                    required
                  >
                    <InputLabel id="demo-simple-select-filled-label">
                      Class
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="class"
                      value={studentDetails.class || ""}
                      defaultValue={objHas(
                        props,
                        "student.academicInfo.class._id",
                        ""
                      )}
                      onChange={(event) => handleClassChange(event)}
                    >
                      {classes &&
                        classes.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.className}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl
                    variant="standard"
                    style={styles.textfield}
                    required
                  >
                    <InputLabel id="demo-simple-select-filled-label">
                      Section
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="section"
                      // value={studentDetails.section}
                      defaultValue={objHas(
                        props,
                        "student.academicInfo.section._id",
                        ""
                      )}
                      // onChange={(event) => handleOnChange(event)}
                    >
                      {sections &&
                        sections.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.sectionName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="Roll No"
                    name="rollNo"
                    value={studentDetails.rollNo}
                    onChange={(event) => handleOnChange(event)}
                    style={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}></Grid>
              </Grid>
            </Box>
          </Card>
          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <h5
                      style={{
                        color: "#1b3779",
                        marginLeft: "16px",
                        marginTop: "16px",
                      }}
                    >
                      Previous School Information:
                    </h5>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="Previous School"
                    name="prevSchool"
                    value={studentDetails.prevSchool}
                    onChange={(event) => handleOnChange(event)}
                    style={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="Previous Class"
                    style={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="TC Number"
                    name="tcNo"
                    value={studentDetails.tcNo}
                    onChange={(event) => handleOnChange(event)}
                    style={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={2} lg={2}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <div
                      style={{
                        marginLeft: 10,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ fontSize: 12, color: "#666" }}>
                        Transfer Certificate:
                      </div>
                      <div>
                        <IconButton
                          sx={{
                            color: "#1b3779",
                            ":hover": { color: "#1b3779" },
                          }}
                          color="primary"
                          aria-label="upload picture"
                          component="label"
                        >
                          <input
                            hidden
                            label="Transfer Certificate"
                            accept=".pdf, .doc, .docx, .txt"
                            type="file"
                            name="transferCertificate"
                            onChange={(event) => handleFile(event)}
                          />
                          <CloudUploadRounded />
                        </IconButton>
                      </div>
                    </div>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Card>
          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <h5
                      style={{
                        color: "#1b3779",
                        marginLeft: "16px",
                        marginTop: "16px",
                      }}
                    >
                      Father Information:
                    </h5>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="Father Name"
                    name="fatherName"
                    // value={studentDetails.fatherName}
                    // onChange={(event) => handleOnChange(event)}
                    defaultValue={objHas(
                      props,
                      "student.fatherInfo.fatherName",
                      ""
                    )}
                    style={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="Father Phone"
                    name="fatherPhone"
                    // value={studentDetails.fatherPhone}
                    // onChange={(event) => handleOnChange(event)}
                    defaultValue={objHas(
                      props,
                      "student.fatherInfo.fatherPhone",
                      ""
                    )}
                    style={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="Father Education"
                    name="fatherEdu"
                    // value={studentDetails.fatherEdu}
                    // onChange={(event) => handleOnChange(event)}
                    defaultValue={objHas(
                      props,
                      "student.fatherInfo.fatherEdu",
                      ""
                    )}
                    style={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={5}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="Father Profession"
                    name="fatherProfession"
                    // value={studentDetails.fatherProfession}
                    // onChange={(event) => handleOnChange(event)}
                    defaultValue={objHas(
                      props,
                      "student.fatherInfo.fatherProfession",
                      ""
                    )}
                    style={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={5}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="Father Designation"
                    name="fatherDesig"
                    // value={studentDetails.fatherDesig}
                    // onChange={(event) => handleOnChange(event)}
                    defaultValue={objHas(
                      props,
                      "student.fatherInfo.fatherDesignation",
                      ""
                    )}
                    style={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={2} lg={2}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <div
                      style={{
                        marginLeft: 10,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ fontSize: 12, color: "#666" }}>
                        Father Photo
                      </div>
                      <div>
                        <IconButton
                          sx={{
                            color: "#1b3779",
                            ":hover": { color: "#1b3779" },
                          }}
                          color="primary"
                          aria-label="upload picture"
                          component="label"
                        >
                          <input
                            hidden
                            label="Father Photo"
                            accept=".jpg, .jpeg, .png, .gif"
                            name="fatherPhoto"
                            onChange={(event) => handleFile(event)}
                            type="file"
                          />
                          <CloudUploadRounded />
                        </IconButton>
                      </div>
                    </div>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Card>
          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <h5
                      style={{
                        color: "#1b3779",
                        marginLeft: "16px",
                        marginTop: "16px",
                      }}
                    >
                      Mother Information:
                    </h5>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="Mother Name"
                    name="motherName"
                    // value={studentDetails.motherName}
                    // onChange={handleOnChange}
                    defaultValue={objHas(
                      props,
                      "student.motherInfo.motherName",
                      ""
                    )}
                    style={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="Mother Phone"
                    name="motherPhone"
                    // value={studentDetails.motherPhone}
                    // onChange={handleOnChange}
                    defaultValue={objHas(
                      props,
                      "student.motherInfo.motherPhone",
                      ""
                    )}
                    style={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="Mother Education"
                    name="motherEdu"
                    // value={studentDetails.motherEdu}
                    // onChange={handleOnChange}
                    defaultValue={objHas(
                      props,
                      "student.motherInfo.motherEdu",
                      ""
                    )}
                    style={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={5}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="Mother Profession"
                    name="motherProfession"
                    // value={studentDetails.motherProfession}
                    // onChange={handleOnChange}
                    defaultValue={objHas(
                      props,
                      "student.motherInfo.motherProfession",
                      ""
                    )}
                    style={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={5}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="Mother Designation"
                    name="motherDesignation"
                    // value={studentDetails.motherDesignation}
                    // onChange={handleOnChange}
                    defaultValue={objHas(
                      props,
                      "student.motherInfo.motherDesignation",
                      ""
                    )}
                    style={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={2} lg={2}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <div
                      style={{
                        marginLeft: 10,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ fontSize: 12, color: "#666" }}>
                        Mother Photo
                      </div>
                      <div>
                        <IconButton
                          sx={{
                            color: "#1b3779",
                            ":hover": { color: "#1b3779" },
                          }}
                          color="primary"
                          aria-label="upload picture"
                          component="label"
                        >
                          <input
                            name="motherPhoto"
                            onChange={(e) => handleFile(e)}
                            hidden
                            label="Mother Photo"
                            accept=".jpg, .jpeg, .png, .gif"
                            type="file"
                          />
                          <CloudUploadRounded />
                        </IconButton>
                      </div>
                    </div>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Card>
          <Card style={styles.card}>
            <Box>
              <Grid container spacing={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <h5
                      style={{
                        color: "#1b3779",
                        marginLeft: "16px",
                        marginTop: "16px",
                      }}
                    >
                      Other Information:
                    </h5>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    type="email"
                    name="email"
                    // value={studentDetails.email}
                    // onChange={(event) => handleOnChange(event)}
                    defaultValue={objHas(props, "student.otherInfo.email", "")}
                    label="Email"
                    style={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="Health Condition"
                    name="healthCondition"
                    // value={studentDetails.healthCondition}
                    // onChange={(event) => handleOnChange(event)}
                    defaultValue={objHas(
                      props,
                      "student.otherInfo.healthCondition",
                      ""
                    )}
                    style={styles.textfield}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <FormControl variant="standard" style={styles.textfield}>
                    <InputLabel id="demo-simple-select-filled-label">
                      Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="status"
                      required
                      defaultValue={objHas(props, "student.status", "")}
                    >
                      <MenuItem
                        value={"active"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Active
                      </MenuItem>
                      <MenuItem
                        value={"inactive"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Inactive
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl variant="standard" style={styles.textfield}>
                    <InputLabel id="demo-simple-select-filled-label">
                      Hostel
                    </InputLabel>
                    <Select
                      defaultValue={objHas(
                        props,
                        "student.otherInfo.hostelMember",
                        ""
                      )}
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="hosteMember"
                      onChange={handleOnChange}
                    >
                      <MenuItem
                        value={"yes"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Yes
                      </MenuItem>
                      <MenuItem
                        value={"no"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        No
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl variant="standard" style={styles.textfield}>
                    <InputLabel id="demo-simple-select-filled-label">
                      Transport
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                    >
                      <MenuItem
                        value={"yes"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Yes
                      </MenuItem>
                      <MenuItem
                        value={"no"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        No
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={5}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="Bus Stop"
                    style={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={5}>
                  <TextField
                    id="filled-"
                    variant="standard"
                    label="Other Info"
                    style={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={2} lg={2}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <div
                      style={{
                        marginLeft: 10,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ fontSize: 12, color: "#666" }}>
                        Student Photo
                      </div>
                      <div>
                        <IconButton
                          sx={{
                            color: "#1b3779",
                            ":hover": { color: "#1b3779" },
                          }}
                          color="primary"
                          aria-label="upload picture"
                          component="label"
                        >
                          <input
                            hidden
                            accept=".jpg, .jpeg, .png, .gif"
                            type="file"
                            name="studentPhoto"
                            onChange={handleFile}
                          />
                          <CloudUploadRounded />
                        </IconButton>
                      </div>
                    </div>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Card>
          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  onClick={() => navigate("/admitstudent")}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  loading={loading}
                  variant="contained"
                  sx={{
                    backgroundColor: "#1b3779",
                    ":hover": { backgroundColor: "#1b3779" },
                  }}
                >
                  {props.student ? "UPDATE" : "SUBMIT"}
                </LoadingButton>
              </div>
            </Box>
          </Card>
        </div>
      </form>
    </div>
  );
};

export default AddAdmitStudent;
