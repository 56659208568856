import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Fab, TextareaAutosize, TextField, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 360,
  bgcolor: "background.paper",
  p: 4,
};

const AddDepartment = (props) => {
  const [department, setDepartment] = useState("");
  const [note, setNote] = useState("");

  useEffect(() => {
    if (props.updateDepartment) {
      setDepartment(props.updateDepartment.department);
      setNote(props.updateDepartment.note);
    }
  }, [props.updateDepartment]);

  const handleDepartment = (e) => {
    setDepartment(e.target.value);
  };

  const handleDepartmentNote = (e) => {
    setNote(e.target.value);
  };

  const handleSubmit = (e) => {
    if (props.updateDepartment) {
      props.handleUpdate(
        {
          department,
          note,
        },
        props.updateDepartment._id
      );
    } else {
      props.handleSubmit(e, {
        department,
        note,
      });
    }
    setDepartment("");
    setNote("");
    props.handleClose();
  };

  const handleCloseModal = () => {
    setDepartment("");
    setNote("");
    props.handleClose();
  };
  const handleClose = () => {
    setDepartment("");
    setNote("");
    props.handleClose();
  };
  return (
    <div>
      <Tooltip title="Add" disableInteractive>
        <Fab
          size="medium"
          style={{ background: "#1b3779", color: "#fff" }}
          aria-label="add"
          onClick={props.handleOpen}
        >
          <Add />
        </Fab>
      </Tooltip>

      <Modal
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleSubmit}>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
            >
              Add Department
            </Typography>
            <div>
              <TextField
                required
                id="filled-required"
                variant="standard"
                label="Department"
                value={department}
                onChange={handleDepartment}
                sx={{ m: 1, minWidth: "96%", label: { fontSize: 12 } }}
              />
              <div>
                <TextareaAutosize
                  maxRows={4}
                  aria-label="maximum height"
                  placeholder="Drop a note"
                  value={note}
                  onChange={handleDepartmentNote}
                  style={{
                    width: 712,
                    maxHeight: 110,
                    margin: 8,
                    padding: 10,
                    minHeight: 118,
                  }}
                />
              </div>
            </div>

            <div className="cancel-submit-btn">
              <div>
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  size="medium"
                  type="button"
                  sx={{
                    m: 1,
                    color: "rgb(27, 55, 121)",
                    borderColor: "rgb(27, 55, 121)",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!department}
                  variant="contained"
                  size="medium"
                  type="submit"
                  sx={{ background: "rgb(27, 55, 121)" }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
};

export default AddDepartment;
