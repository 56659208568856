const objHas = (obj, path, defaultVal) => {
  let cur = obj;
  let keys = path.split(".");
  for (let i = 0; i < keys.length; i++) {
    if (
      cur === null ||
      typeof cur !== "object" ||
      !cur.hasOwnProperty(keys[i])
    ) {
      return defaultVal;
    }
    cur = cur[keys[i]];
  }
  return cur;
};
const subMenuPermissionChecker = (functionalities, role) => {
  // if(!Array.isArray(functionalities)) return false
  let giveAccess = false;
  for (const func of functionalities) {
    if (role[func] && role[func]["accessible"]) {
      giveAccess = true;
      break;
    }
  }
  return giveAccess;
};
const getRandomColor = () => {
  let color = "#";
  let letters = "0123456789ABCDEF";

  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
};

export { objHas, subMenuPermissionChecker, getRandomColor };
