import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Fab, Grid, TextField, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import "../../../styles/components/AcademicYear/DateModal.scss";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 400,
  bgcolor: "background.paper",
  p: 4,
};

const DateModal = (props) => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [note, setNote] = useState("");

  const handleFormDate = (e) => {
    setFromDate(e.target.value);
  };
  const handleToDate = (e) => {
    setToDate(e.target.value);
  };
  const handleNote = (e) => {
    setNote(e.target.value);
  };
  useEffect(() => {
    if (props.updateAcademicYear) {
      console.log(props.updateAcademicYear);
      setFromDate(props.updateAcademicYear.academicYearFrom);
      setToDate(props.updateAcademicYear.academicYearTo);
      setNote(props.updateAcademicYear.note);
    }
  }, [props.updateAcademicYear]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (props.updateAcademicYear) {
      props.handleUpdate(
        {
          academicYearFrom: fromDate,
          academicYearTo: toDate,
          note,
        },
        props.updateAcademicYear._id
      );
    } else {
      props.handleSubmit(e, {
        academicYearFrom: fromDate,
        academicYearTo: toDate,
        note,
      });
    }
    setFromDate("");
    setNote("");
    setToDate("");
    props.handleClose();
  };

  const handleCloseModal = () => {
    setFromDate("");
    setNote("");
    setToDate("");
    props.handleClose();
  };

  const handleClose = () => {
    setFromDate("");
    setNote("");
    setToDate("");
    props.handleClose();
  };
  return (
    <div>
      <Tooltip title="Add" disableInteractive>
      <Fab
        size="medium"
        style={{ background: "#1b3779", color: "#fff" }}
        aria-label="add"
        onClick={props.handleOpen}
      >
        <Add />
      </Fab>
      </Tooltip>
      <Modal
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleSubmit}>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
              marginBottom="30px"
            >
              Add Academic Year
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  id="filled-required"
                label="From Year"
                  value={fromDate}
                  onChange={handleFormDate}
                  variant="standard"
                  sx={{ width: "100%" }}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  id="filled-required"
                label="To Year"
                  value={toDate}
                  onChange={handleToDate}
                  variant="standard"
                  sx={{ width: "100%" }}
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginTop: "30px" }}>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <TextareaAutosize
                  value={note}
                  onChange={handleNote}
                  maxRows={4}
                  aria-label="maximum height"
                  placeholder="Drop a note"
                  style={{
                    width: "100%",
                    height: 100,
                    maxHeight: 100,
                    padding: "10px",
                  }}
                />
              </Grid>
            </Grid>
            <div className="cancel-submit-btn">
              <div>
                <Tooltip title="Cancel" disableInteractive>
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  size="medium"
                  type="button"
                  sx={{
                    m: 1,
                    color: "rgb(27, 55, 121)",
                    borderColor: "rgb(27, 55, 121)",
                   
                  }}
                >
                  Cancel
                </Button>
                </Tooltip>
                <Tooltip title="Submit" disableInteractive>
                <Button
                  variant="contained"
                  size="medium"
                  sx={{
                    m:1,
                    background: "#1b3779",
                    ":hover": { background: "#1b3779" },                  
                  }}
                  type="submit"
                >
                  Submit
                </Button>
                </Tooltip>
              </div>
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
};

export default DateModal;
