import React from "react";
import SchoolClass from "./SchoolClass";
import { Card, Grid } from "@mui/material";

const Academic = () => {
  return (
    <div>
      <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span
              style={{
                color: "red",
                fontFamily: "cursive",
              }}
            >
              Class :
            </span>
          </Grid>
        </Grid>
      </Card>
      <SchoolClass />
    </div>
  );
};

export default Academic;
