import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  CircularProgress,
  Stack,
  TextField,
} from "@mui/material";
import { del, get, post, put } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import dayjs from 'dayjs';
 import { PickerViewRoot } from '@mui/x-date-pickers/internals/components/PickerViewRoot';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { toast } from 'react-toastify';
  const styles = {
    icon: {
      padding: "10px",
      fontSize: "12px",
      fontWeight: 500,
    },
    card: {
      padding: 15,
      marginBottom: 20,
      width:'100%'
    },
    textfield: {
      width: "100%",
      label: { fontSize: 12 },
    },
    Button: {
      background: "#1b3779",
      color: "#fff",
      ":hover": { background: "#1b3779" },
    },
  };
const FeeOverview=()=>{
    const [academicYears, setAcademicYears] = useState([]);
    const [classes, setClasses] = useState([]);
    const [sections, setSections] = useState([]);
    const [formData, setFormData] = useState([]);
    const[receipts,setReceipts]=useState([]);
    const [collected,setCollected]=useState([]);
    const [fromDate, setFromDate] = useState(dayjs().subtract(1,'day'));
  const [toDate, setToDate] = useState(null);
  const [totalCount, setTotalCount] = useState();
  const [masterFeeOverview, setMasterFeeOverview] = useState([]);
  const [filterFeeOverview, setFilterFeeOverview] = useState([]);
  const [differentFeeOverView, setDifferentFeeOverView] = useState({
    modes: {},
    total: 0,
  });
  const [staffCopy, setStaffCopy] = useState([]);

  const [category, setCategory] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const [sort, setSort] = useState({
    name: "asc",
    classNumber: "asc",
    admissionNumber: "asc",
  });

  const name = () => {
    console.log("name");
    if (searchValue) {
      if (sort.name === "asc") {
        let sortedList = [
          ...filterFeeOverview.sort((a, b) =>
            b.studentDetails.name.localeCompare(a.studentDetails.name)
          ),
        ];
        setFilterFeeOverview(sortedList);
        setSort({ ...sort, name: "des" });
      } else {
        let sortedList = [
          ...filterFeeOverview.sort((a, b) =>
            a.studentDetails.name.localeCompare(b.studentDetails.name)
          ),
        ];
        setFilterFeeOverview(sortedList);
        setSort({ ...sort, name: "asc" });
      }
    } else {
      if (sort.name === "asc") {
        let sortedList = [
          ...filterFeeOverview.sort((a, b) =>
            b.studentDetails.name.localeCompare(a.studentDetails.name)
          ),
        ];
        setFilterFeeOverview(sortedList);
        setSort({ ...sort, name: "des" });
      } else {
        let sortedList = [
          ...filterFeeOverview.sort((a, b) =>
            a.studentDetails.name.localeCompare(b.studentDetails.name)
          ),
        ];
        setFilterFeeOverview(sortedList);
        setSort({ ...sort, name: "asc" });
      }
    }
  };

  const classNameSorted = () => {
    console.log("classNumber");
    let sortedList;
    if (searchValue) {
      if (sort.classNumber === "asc") {
        sortedList = [
          ...filterFeeOverview.sort(
            (a, b) =>
              b.studentDetails.classNumber - a.studentDetails.classNumber
          ),
        ];
        setSort({ ...sort, classNumber: "des" });
      } else {
        sortedList = [
          ...filterFeeOverview.sort(
            (a, b) =>
              a.studentDetails.classNumber - b.studentDetails.classNumber
          ),
        ];
        setSort({ ...sort, classNumber: "asc" });
      }
    } else {
      if (sort.classNumber === "asc") {
        sortedList = [
          ...filterFeeOverview.sort(
            (a, b) =>
              b.studentDetails.classNumber - a.studentDetails.classNumber
          ),
        ];
        setSort({ ...sort, classNumber: "des" });
      } else {
        sortedList = [
          ...filterFeeOverview.sort(
            (a, b) =>
              a.studentDetails.classNumber - b.studentDetails.classNumber
          ),
        ];
        setSort({ ...sort, classNumber: "asc" });
      }
    }

    setFilterFeeOverview(sortedList);
  };

  const admissionNumberSorted = () => {
    console.log("Admission Number");
    let sortedList;
    if (searchValue) {
      if (sort.admissionNumber === "asc") {
        sortedList = [
          ...filterFeeOverview.sort(
            (a, b) =>
              b.studentDetails.admissionNumber -
              a.studentDetails.admissionNumber
          ),
        ];
        setSort({ ...sort, admissionNumber: "des" });
      } else {
        sortedList = [
          ...filterFeeOverview.sort(
            (a, b) =>
              a.studentDetails.admissionNumber -
              b.studentDetails.admissionNumber
          ),
        ];
        setSort({ ...sort, admissionNumber: "asc" });
      }
    } else {
      if (sort.admissionNumber === "asc") {
        sortedList = [
          ...filterFeeOverview.sort(
            (a, b) =>
              b.studentDetails.admissionNumber -
              a.studentDetails.admissionNumber
          ),
        ];
        setSort({ ...sort, admissionNumber: "des" });
      } else {
        sortedList = [
          ...filterFeeOverview.sort(
            (a, b) =>
              a.studentDetails.admissionNumber -
              b.studentDetails.admissionNumber
          ),
        ];
        setSort({ ...sort, admissionNumber: "asc" });
      }
    }
    setFilterFeeOverview(sortedList);
  };

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll);
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data[0]._id,
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);

  useEffect(() => {
    const getAllReceipts = async () => {
      try {
        const res = await get(urls.recepitBook.getAll);
        setFormData((prev) => ({
          ...prev,
          name: res.data.data[0]._id,
        }));
        setReceipts(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAllReceipts();
  }, []);

  useEffect(() => {
    const getCollected = async () => {
      try {
        const roleResponse = await get(urls.role.getAllRole, {
          params: {
            search: {
              roleName: "ACCOUNTANT",
            },
          },
        });
        console.log(roleResponse, "roleResponse");
        const roleId = roleResponse.data.data[0]._id;

        const employeeResponse = await get(urls.employee.getAllEmployee, {
          params: {
            search: {
              role: roleId,
            },
          },
        });
        setFormData((prev) => ({
          ...prev,
          name: employeeResponse.data.data[0]._id,
        }));
        setCollected(employeeResponse.data.data);
        setTotalCount(employeeResponse.data.count);
      } catch (error) {
        console.log("error", error);
      }
    };
    getCollected();
  }, []);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    console.log(name, "NAME");
    console.log(value, "values");
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      setFormData((prev) => ({
        ...prev,
        section: "all",
      }));
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };

      useEffect(() => {
        const getClasses = async () => {
          try {
            const res = await get(urls.class.getAllClass);
            const resSections = await get(urls.schoolSection.getAllSection,{
              params:{
                search:{
                  class:res.data.data[0]._id
                }
              }
            });
            const searchStudents =await get(urls.students.getAllStudent,{
              params:{
                search:{
                  academicYear: formData.academicYear,
                  "academicInfo.class": res.data.data[0]._id,
                  
                }
              }
            });
            // setSchoolStudents(searchStudents.data.data);
            setSections(resSections.data.data)
            setClasses(res.data.data);
            setFormData((prev) => ({
              ...prev,
              className: res.data.data[0]._id,
              section:"all"
            }));
          } catch (error) {
            console.log(error);
          }
        };
        getClasses();
      }, []);
      const handleGetPrintPdf = async (id) => {
   
        try {
         {
            const getPrint = await get(
              `/fee-receipt/download/report/staff/fee-receipt/${id}`,
              {
                responseType: "blob",
              }
            );
    
            const uri = URL.createObjectURL(getPrint.data);
            window.open(uri, "__blank");
          }
         
        } catch (error) {
          console.log(error);
        }
      };
      const handleMarksExcelDownload = async () => {
        try {
           {
            const getMarksListSheet = await get(`/fee-receipt/download/report/fee-overview`,{
              params: {
                academicYearId: formData.academicYear,
                cashierId:formData.collectedby,
                receiptType: formData.feereceipt,
                classId: formData.class,
                sectionId: formData.section,
                fromDate:fromDate,
                toDate:toDate
              },
              responseType:'blob'
            })
          
            if(getMarksListSheet.status === 200) {
              const uri = URL.createObjectURL(getMarksListSheet.data);
              const link = document.createElement('a');
              link.href = uri;
              const academicYearGet=formData.academicYear
              const getExcelByYearId=academicYears.find((excel)=> excel._id===academicYearGet)
              const classename=formData.class
              const getExcelByClassId=classes.find((excel)=> excel._id===classename)
              const sectionsname=formData.section
              const getExcelsectionById=sections.find((excel)=> excel._id===sectionsname)
              const receiptss=formData.feereceipt
              const getExcelreceiptById=receipts.find((excel)=> excel._id===receiptss)
              const cashier=formData.collectedby
              const getExcelcashierById=receipts.find((excel)=> excel._id===receiptss)
              
              link.setAttribute('download',`${getExcelByYearId.academicYearFrom}-${getExcelByYearId.academicYearTo} ${getExcelByClassId.className}th std ,${getExcelsectionById.sectionName}, ${getExcelreceiptById.name}.xlsx`);
              document.body.appendChild(link);
              link.click();
           
              
              link.parentNode.removeChild(link);
            }

          } 
         
        } catch (error) {
         console.log(error,"error")
          toast.error(error.message)    
        }
      }
      const handleFeeOverviewSearchSubmit = async (e) => {
        e.preventDefault();
        try {
          
          const searchParams = {
            params: {
              academicYearId: formData.academicYear,
              cashierId:formData.collectedby,
              receiptType: formData.feereceipt,
              classId: formData.class,
              sectionId: formData.section,
              fromDate:fromDate,
              toDate:toDate
            },
          };
          const differentMode = await get(urls.studentsFeeOverview.getAmountCollectedWithDifferentModes, {...searchParams,sectionId:""});
            setDifferentFeeOverView({
              modes:differentMode.data.data.finalResult,
              total:differentMode.data.data.results[0].totalCollectedAmount
            });

      const searchStudent = await get(
        urls.studentsFeeOverview.getAll,
        searchParams
      );
      setMasterFeeOverview(searchStudent.data.data);
      setFilterFeeOverview(searchStudent.data.data);
      setTotalCount(searchStudent.data.count);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(differentFeeOverView, "difffff");
  console.log(masterFeeOverview, "studeee");

      console.log(differentFeeOverView,"difffff")
      console.log(masterFeeOverview,"studeee")

      const handleCategoryChange = (event) => {
        setCategory(event.target.value);
        // filterData(event.target.value, searchValue);
    
      };
    
      const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
        filterData(category, event.target.value);
      };
    
      const filterData = (category, search) => {
        const filteredResults = masterFeeOverview.filter((data) => {
          if (category=='receiptNumber'){
            let lengthofgivensearch=search.length
            let receiptNumber=data.receiptNumber.substring(0,lengthofgivensearch)
     return search==receiptNumber
    } else if (category === 'totalAmountPaid') {
      return data.totalAmountPaid.toString().includes(search);
    } else if (category === 'paymentMode') {
      let paymentMode=data.paymentMode.toLowerCase().includes(search.toLowerCase())
      return paymentMode;
   } else if (category === 'name') {
      return data.studentDetails.name.toLowerCase().includes(search.toLowerCase());
    } else if (category === 'admissionNumber') {
      let admissionNumber=data.studentDetails.admissionNumber.toLowerCase().includes(search.toLowerCase())
      return admissionNumber;
    }
     return true;
        });
        setFilterFeeOverview(filteredResults);
      };
      
    
    return(
        <div>
            <div>
           <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span style={{ color: "red", fontFamily: "cursive" }}>
            Fee Overview:
            </span>
          </Grid>
        </Grid>
      </Card>
      <Card style={{ margin: 15, padding: 10 }}>
      <Box sx={{ flexGrow: 1 }}>
          <form onSubmit={handleFeeOverviewSearchSubmit}>
          <Grid container spacing={2} marginBottom={"5px"}>
          <Grid item xs={12} md={6} lg={3}>
      <FormControl fullWidth required>
        <InputLabel >
                    Academic Year
                  </InputLabel>
                  <Select
                    label="Academic Year"
                    id="demo-simple-select-filled"
                    name="academicYear"
                    value={formData.academicYear || ""}
                    onChange={handleOnChange} 
                    size='small'
                  >
                    {academicYears &&
                      academicYears.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                       
                        >
                          {row.academicYearFrom} - {row.academicYearTo}
                        </MenuItem>
                      ))}
                  </Select>
      </FormControl>
    </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                     <FormControl fullWidth required>
                <InputLabel >
                    Collected By
                        </InputLabel>
                  <Select
                    label="Collected By"
                    id="demo-simple-select-filled-collectedby"
                    name="collectedby"
                    value={formData.collectedby || ""}
                    onChange={handleOnChange} 
                    size='small'
                  >
                    {collected &&
                      collected.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}                       
                        >
                          {row.basicInfo.empName}
                        </MenuItem>
                      ))}
                  </Select>                                
                   </FormControl>
                        </Grid>       
                  <Grid item xs={12} md={6} lg={3}>
                      <FormControl fullWidth required>
                      <InputLabel >
                  Fee Receipt
                  </InputLabel>
                  <Select
                    label="Fee Receipt"
                    id="demo-simple-select-filled-feereceipt"
                    name="feereceipt"
                    value={formData.feereceipt || ""}
                    onChange={handleOnChange} 
                    size='small'
                  >
                    {receipts &&
                      receipts.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}                      
                        >
                          {row.name} 
                        </MenuItem>
                      ))}
                  </Select>
                      </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                                <FormControl fullWidth required>
                    <InputLabel>
                   Class
                  </InputLabel>
                  <Select
                    label="Class"
                    id="demo-simple-select-filled"
                    name="class"
                    value={formData.class || ""}
                    onChange={handleOnChange} 
                    size='small'
                  >
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                       
                        >
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                        </FormControl>
                            </Grid>
              </Grid>
              <Grid container spacing={2} marginTop={"5px"}>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl fullWidth required>
                    <InputLabel>Section</InputLabel>
                    <Select
                      label="Section"
                      id="demo-simple-select-filled"
                      name="section"
                      value={formData.section || ""}
                      onChange={handleOnChange}
                      size="small"
                    >
                      {sections &&
                        sections.map((row, index) => (
                          <MenuItem key={row._id} value={row._id}>
                            {row.sectionName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={3} sx={{ alignSelf: "center" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="From Date"
                      value={fromDate}
                      onChange={(newValue) => setFromDate(newValue)}
                      renderInput={(params) => (
                        <TextField {...params} size="small" fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} md={6} lg={3} sx={{ alignSelf: "center" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="To Date"
                      value={toDate}
                      onChange={(newValue) => setToDate(newValue)}
                      renderInput={(params) => (
                        <TextField {...params} size="small" fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={1}
                  style={{ alignSelf: "flex-end" }}
                >
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: "187px" }}
                  >
                    find
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Card>
      </div>
      <div>
        <div>
          <div style={{ margin: 7, padding: 10 }}>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead style={{ background: "rgb(27 55 121)" }}>
                    <TableRow>
                      <TableCell align="center">
                        <span className="class-table-header">Payment</span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="class-table-header">Total</span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="class-table-header">Cash</span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="class-table-header">Cheque</span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="class-table-header">DD</span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="class-table-header">Chalan</span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="class-table-header">NetBanking</span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="class-table-header">Online</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {!Object.keys(differentFeeOverView.modes).length ? (
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center" colSpan={8}>
                          <Typography variant="h6" sx={{ textAlign: "center" }}>
                            No data found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">
                          Amount
                          <CurrencyRupeeIcon size="small" />
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {differentFeeOverView.total}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {differentFeeOverView.modes.Cash}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {differentFeeOverView.modes.Cheque}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {differentFeeOverView.modes.DD}{" "}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {differentFeeOverView.modes.Card}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {differentFeeOverView.modes.Netbanking}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {differentFeeOverView.modes.Upi}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Paper>
          </div>
          <div>
            <div style={{ marginLeft: "715px" }}>
              <div>
                <Select
                  value={category}
                  onChange={handleCategoryChange}
                  size="small"
                  style={{ marginRight: "10px" }}
                  sx={{ width: "160px" }}
                >
                  <MenuItem value="receiptNumber">Receipt No</MenuItem>
                  <MenuItem value="totalAmountPaid">Amount</MenuItem>
                  <MenuItem value="paymentMode">Payment Mode</MenuItem>
                  <MenuItem value="name">Name</MenuItem>
                  <MenuItem value="admissionNumber">Admission No</MenuItem>
                </Select>
                <TextField
                  label="Search"
                  value={searchValue}
                  onChange={handleSearchChange}
                  size="small"
                />
              </div>{" "}
            </div>

            <div style={{ margin: 7, padding: 10 }}>
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead style={{ background: "rgb(27 55 121)" }}>
                      <TableRow>
                        <TableCell align="center">
                          <span className="class-table-header">#SL</span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="class-table-header">Receipt No</span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="class-table-header">Paid Date</span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="class-table-header">Amount</span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="class-table-header">
                            Payment Mode
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="class-table-header">Name</span>
                          <span
                            onClick={name}
                            style={{ cursor: "pointer", color: "#fff" }}
                          >
                            {sort.name === "asc" ? "▲" : "▼"}
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="class-table-header">Class</span>

                          <span
                            onClick={classNameSorted}
                            style={{ cursor: "pointer", color: "#fff" }}
                          >
                            {sort.classNumber === "asc" ? "▲" : "▼"}
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="class-table-header">
                            Admission No
                          </span>
                          <span
                            onClick={admissionNumberSorted}
                            style={{ cursor: "pointer", color: "#fff" }}
                          >
                            {sort.admissionNumber === "asc" ? "▲" : "▼"}
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="class-table-header">View</span>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filterFeeOverview.map((row, index) => (
                        <TableRow
                          key={row._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.receiptNumber}
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {/* {row.createdAt} */}
                            {dayjs(row.createdAt).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.totalAmountPaid}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.paymentMode}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.studentDetails.name}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.studentDetails.classNumber}
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.studentDetails.admissionNumber}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            <Button
                              size="small"
                              variant="contained"
                              color="primary"
                              onClick={() => handleGetPrintPdf(row._id)}
                            >
                              STAFF COPY
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {!masterFeeOverview.length && (
                  <Typography
                    variant="h6"
                    sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
                  >
                    No data found
                  </Typography>
                )}
              </Paper>
            </div>
          </div>
        </div>

        <Button
          onClick={handleMarksExcelDownload}
          size="small"
          variant="contained"
          color="primary"
          style={{ marginLeft: "1000px", marginTop: "-10px" }}
        >
          DOWNLOAD
        </Button>
      </div>
    </div>
  );
};

export default FeeOverview;
