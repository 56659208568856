import React, { useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
  Card,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TableHead,
  TextField,
  Tooltip,
  Modal,
} from "@mui/material";

import { useEffect } from "react";
import { urls } from "../../../services/urlConstant";
import "../../../styles/components/Exam/Exam.scss";
import { get, post, put } from "../../../services/apis";

const styles = {
  card: {
    padding: 10,
    width: "100%",
    marginBottom: "10px",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
    m: 1,
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
    marginTop: "10px",
  },
};
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fitContent",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ManageMark = () => {
  const [studentsManageMarks, setStudentsManageMarks] = useState(undefined);
  const [grades, setGrades] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [examTerms, setExamterms] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [manageDetails, setManageDetails] = useState({});
  const [bulkUploadModal, setBulkUploadModal] = useState(false);
  const [fileChoosen, setFileChoosen] = useState(null);
  const [sheet, setSheet] = useState(undefined);

  const handleClasses = async (e) => {
    try {
      const { name, value } = e.target;
      setManageDetails({ ...manageDetails, [name]: value });
      const res = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: value,
          },
        },
      });
      setSections(res.data.data);
      const response = await get(urls.schoolSubject.getAllSubject, {
        params: {
          search: {
            class: value,
            section: manageDetails.sectionName,
          },
        },
      });
      setSubjects(response.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (manageDetails.className && manageDetails.sectionName) {
      const updateSubjects = async () => {
        const response = await get(urls.schoolSubject.getAllSubject, {
          params: {
            search: {
              class: manageDetails.className,
              section: manageDetails.sectionName,
            },
          },
        });
        setSubjects(response.data.data);
      };

      updateSubjects();
    }
  }, [manageDetails.sectionName, manageDetails.className]);
  const handleSections = (e) => {
    const { name, value } = e.target;
    setManageDetails({ ...manageDetails, [name]: value });
  };

  const handleExamTerms = (e) => {
    const { name, value } = e.target;
    setManageDetails({ ...manageDetails, [name]: value });
  };

  const handleSubjects = (e) => {
    const { name, value } = e.target;
    setManageDetails({ ...manageDetails, [name]: value });
  };

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        setClasses(res.data.data);
      } catch (error) {}
    };
    getClasses();
  }, []);

  useEffect(() => {
    const getExamTerms = async () => {
      try {
        const res = await get(urls.examTerm.getAllExamTerm);
        setExamterms(res.data.data);
      } catch (error) {}
    };
    getExamTerms();
  }, []);
  const handleFindStudentsMarks = async (e) => {
    e.preventDefault();
    console.log("Managedetails========", manageDetails);
    try {
      const res = await get(
        `/studentmark/exam-term/${manageDetails.examTitle}/class/${manageDetails.className}/subject/${manageDetails.subjectName}/section/${manageDetails.sectionName}`
      );
      const resGrade = await get(`/examgrade`);
      if (res.data.success && resGrade.data.success) {
        setStudentsManageMarks(res.data.data);
        setGrades(resGrade.data.data);
      } else {
      }
    } catch (error) {}
  };
  const handleMarksSubmit = async () => {
    try {
      const res = await put(`/student-mark/multiple`, "", {
        marks: studentsManageMarks,
      });
      if (res.data.success) {
      }
    } catch (error) {}
  };
  const handleMarkChange = async (e, id) => {
    setStudentsManageMarks(
      studentsManageMarks.map((mark) => {
        if (mark._id == id) {
          return {
            ...mark,
            writtenMarks: e.target.value,
          };
        } else {
          return mark;
        }
      })
    );
  };
  const handleGradeChange = async (e, id) => {
    setStudentsManageMarks(
      studentsManageMarks.map((mark) => {
        if (mark._id == id) {
          return {
            ...mark,
            grade: e.target.value,
          };
        } else {
          return mark;
        }
      })
    );
  };

  const handleGetUploadSheet = async () => {
    try {
      const { className, sectionName, examTitle, subjectName } = manageDetails;
      const getMarksSheet = await get(
        `/subjectmarks-update-sheet/${className}/${sectionName}/${examTitle}/${subjectName}`,
        {
          responseType: "blob",
        }
      );
      console.log("class", classes);
      const url = window.URL.createObjectURL(new Blob([getMarksSheet.data]));
      const link = document.createElement("a");
      link.href = url;
      const classename = className;
      const getExcelById = classes.find((excel) => excel._id === classename);
      const sectionsname = sectionName;
      const getExcelsectionById = sections.find(
        (excel) => excel._id === sectionsname
      );
      const excelname = examTitle;
      const getExcelExamById = examTerms.find(
        (excel) => excel._id === excelname
      );

      link.setAttribute(
        "download",
        `${getExcelById.className}Std ,${getExcelsectionById.sectionName},${getExcelExamById.examTitle}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetAllUploadSheet = async () => {
    try {
      const { className, examTitle, sectionName } = manageDetails;
      const getAllMarksSheet = await get(
        `/allsubjectmarks-update-sheet/${className}/${sectionName}/${examTitle}`,
        {
          responseType: "blob",
        }
      );
      console.log("class", classes);
      const url = window.URL.createObjectURL(new Blob([getAllMarksSheet.data]));
      const link = document.createElement("a");
      link.href = url;
      const classename = className;
      const getExcelById = classes.find((excel) => excel._id === classename);

      const excelname = examTitle;
      const getExcelExamById = examTerms.find(
        (excel) => excel._id === excelname
      );

      link.setAttribute(
        "download",
        `${getExcelById.className}Std ,${getExcelExamById.examTitle}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileChoosen(file);
    } else {
      setFileChoosen(null);
    }
  };

  const handleSheetUpload = async (e) => {
    e.preventDefault();
    console.log("test ", fileChoosen);
    try {
      if (!fileChoosen) {
        console.error("No file selected.");
        return;
      }

      const formData = new FormData();
      formData.append("sheet", fileChoosen);

      const { className, sectionName, examTitle, subjectName } = manageDetails;
      const response = await post(
        `/subjectmarks-update-sheet/${className}/${sectionName}/${examTitle}/${subjectName}`,
        formData
      );

      console.log("Response:", response.data);
      console.log("Sheet uploaded successfully!");
    } catch (error) {
      console.error(error);
    }
  };

  // const handleSheetUpload = async (e) => {
  //   e.preventDefault();
  //   console.log("test ", fileChoosen);
  //   try {
  //     if (!fileChoosen) {
  //       console.error("No file selected.");
  //       return;
  //     }

  //     const formData = new FormData();
  //     formData.append("sheet", fileChoosen);

  //     const { className, sectionName, examTitle, subjectName } = manageDetails;
  //     const uploadSheet = await put(
  //       `/subjectmarks-update-sheet/${className}/${sectionName}/${examTitle}/${subjectName}`,
  //       "",
  //       formData
  //     );
  //     console.log("Sheet uploaded successfully!");
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const handleSheetUpload = async (e) => {
  //   e.preventDefault();
  //   console.log("test ", fileChoosen);
  //   try {
  //     if (!fileChoosen) {
  //       console.error("No file selected.");
  //       return;
  //     }

  //     const formData = new FormData();
  //     formData.append("sheet", fileChoosen);

  //     const { className, sectionName, examTitle, subjectName } = manageDetails;
  //     const uploadSheet = await put(
  //       `/subjectmarks-update-sheet/${className}/${sectionName}/${examTitle}/${subjectName}`,
  //       "",
  //       formData
  //     );
  //     console.log("Sheet uploaded successfully!");
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  return (
    <div>
      <div className="examfilter-searchbar">
        <Card style={styles.card}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <span
                  style={{
                    color: "red",
                    fontFamily: "cursive",
                  }}
                >
                  Manage Mark :
                </span>
              </Grid>
            </Grid>
          </Box>
        </Card>
        <form onSubmit={handleFindStudentsMarks}>
          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={2}>
                  <FormControl
                    variant="standard"
                    sx={styles.textfield}
                    required
                  >
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Class
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="className"
                      value={manageDetails.className}
                      onChange={(event) => handleClasses(event)}
                    >
                      {classes &&
                        classes.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.className}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl
                    variant="standard"
                    sx={styles.textfield}
                    required
                  >
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Section
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="sectionName"
                      value={manageDetails.sectionName}
                      onChange={(event) => handleSections(event)}
                    >
                      {sections &&
                        sections.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.sectionName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl
                    variant="standard"
                    sx={styles.textfield}
                    required
                  >
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Exam
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="examTitle"
                      value={manageDetails.examTitle}
                      onChange={(event) => handleExamTerms(event)}
                    >
                      {examTerms &&
                        examTerms.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.examTitle}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl
                    variant="standard"
                    sx={styles.textfield}
                    required
                  >
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Subject
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="subjectName"
                      value={manageDetails.subjectName}
                      onChange={(event) => handleSubjects(event)}
                    >
                      {subjects &&
                        subjects.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.subjectName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={1}
                  sx={{
                    alignSelf: "center",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Tooltip title="Find">
                    <Button
                      variant="contained"
                      style={styles.Button}
                      size="small"
                      type="submit"
                    >
                      Find
                    </Button>
                  </Tooltip>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={2}
                  sx={{
                    alignSelf: "center",
                  }}
                >
                  {manageDetails.className &&
                    manageDetails.sectionName &&
                    manageDetails.examTitle &&
                    manageDetails.subjectName && (
                      <Button
                        size="small"
                        variant="contained"
                        style={styles.Button}
                        onClick={() => setBulkUploadModal(true)}
                      >
                        Bulk Upload
                      </Button>
                    )}
                </Grid>
              </Grid>
            </Box>
          </Card>
        </form>
      </div>
      {!studentsManageMarks ? (
        <div className="notfoundStyle">
          <img style={{ height: 241 }} src="./record.webp" alt="notfound" />
        </div>
      ) : (
        <div style={{ margin: "15px" }}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead style={{ background: "rgb(27 55 121)" }}>
                  <TableRow>
                    <TableCell align="center">
                      <span className="class-table-header">#SL</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Roll No</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Name</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Written</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Grade</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Comment</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {studentsManageMarks.length > 0 &&
                    studentsManageMarks.map((studentMark, index) => (
                      <TableRow
                        key={studentMark._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {studentMark.student.academicInfo.rollNo}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {studentMark.student.basicInfo.name}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <TextField
                            onChange={(e) =>
                              handleMarkChange(e, studentMark._id)
                            }
                            size="small"
                            id="outlined-basic"
                            label="Student Mark"
                            variant="standard"
                            value={studentMark.writtenMarks}
                            defaultValue={studentMark.writtenMarks}
                            type="number"
                          />
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <FormControl
                            size="small"
                            sx={{ m: 1, minWidth: "90px" }}
                          >
                            <InputLabel id="demo-simple-select-label">
                              Grade
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              variant="standard"
                              value={studentMark.grade}
                              label="Grade"
                              onChange={(e) =>
                                handleGradeChange(e, studentMark._id)
                              }
                              defaultValue={studentMark.grade}
                            >
                              {grades.map((grade) => (
                                <MenuItem value={grade._id}>
                                  {grade.examGrade}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <TextField
                            id="outlined-basic"
                            label="Comment"
                            variant="standard"
                            size="small"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {studentsManageMarks.length > 0 && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip title="Submit">
                <Button
                  size="small"
                  style={styles.Button}
                  onClick={handleMarksSubmit}
                  variant="contained"
                >
                  Submit
                </Button>
              </Tooltip>
            </div>
          )}
        </div>
      )}
      <Modal
        open={bulkUploadModal}
        onClose={() => setBulkUploadModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div style={{ textAlign: "center", marginBottom: "30px" }}>
            <span style={{ color: "red", fontFamily: "cursive" }}>
              Bulk Upload
            </span>
          </div>
          <form onSubmit={handleSheetUpload}>
            <div>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <Button
                      variant="contained"
                      style={styles.Button}
                      onClick={handleGetUploadSheet}
                    >
                      Download
                    </Button>
                  </div>

                  <div style={{ position: "relative" }}>
                    <Button
                      variant="contained"
                      sx={styles.Button}
                      component="label"
                    >
                      Upload
                      <input
                        onChange={handleFileChange}
                        name="file"
                        hidden
                        accept=".xlsx, .xls, xlsm, .xlsb, .csv, .ods"
                        type="file"
                      />
                    </Button>
                    <div
                      style={{
                        position: "fixed",
                        top: 70,
                        right: 10,
                        visibility: fileChoosen ? "visible" : "hidden",
                      }}
                    >
                      {fileChoosen && fileChoosen.name}
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <Button
                      variant="contained"
                      style={styles.Button}
                      onClick={handleGetAllUploadSheet}
                    >
                      Download All Student
                    </Button>
                  </div>
                  <Button variant="contained" sx={styles.Button} type="submit">
                    submit
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default ManageMark;
