import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  styled,
  TextField,
  TextareaAutosize,
  Paper,
  CardMedia,
  CardContent,
  Tooltip,
  InputLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useContext } from "react";
import { get, post, put, del } from "../../services/apis";
import { urls } from "../../services/urlConstant";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useCallback } from "react";
import Carousel from "react-spring-3d-carousel";
import avatar from "../../assets/Images/avatar.jpg";
import { config } from "react-spring";
import { useMediaQuery } from "@mui/material";
import { FaQuoteLeft } from "react-icons/fa";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormInput from "../FormInput";
import { LoadingButton } from "@mui/lab";
import Fab from "@mui/material/Fab";
import Avatar from "@mui/joy/Avatar";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const DotsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 3,
}));

const Dot = styled(Paper)(({ theme, active }) => ({
  height: "15px",
  width: "15px",
  borderRadius: "50%",
  cursor: "pointer",
}));
const InputLables = styled(InputLabel)(({}) => ({
  color: "#000",
  fontSize: "14px",
}));
const Papers = styled(InputLabel)(({}) => ({
  color: "#000",
  fontSize: "14px",
}));

const FaqStyle = {
  fontSize: "130px",
  position: "absolute",
  top: "15%",
  left: "30%",
  color: "#5d50c6",
  opacity: 0.1,
};

const cardStyle = {
  display: "flex",
  width: "100%",
  maxWidth: 500,
  minWidth: 300,
  backgroundColor: "whitesmoke",
};

const FeedbackItem = ({ feedback, parentName, studentName }) => {
  console.log(feedback);
  return (
    <Card elevation={0} sx={cardStyle}>
      <Box sx={{ display: { xs: "none", md: "block" }, mt: 2 }}>
        {/* <CardMedia
          component="img"
          sx={{ width: 130 }}
          image={avatar}
          alt="Loading..."
        /> */}
        <Avatar
          variant="solid"
          sx={{
            width: 130,
            height: 130,
            backgroundColor: "#3f51b5",
            color: "#fff",
          }}
        />
      </Box>
      <CardContent sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
          {parentName}
        </Typography>
        <Typography
          variant="subtitle1"
          color="text.secondary"
          component="div"
          fontSize="18px"
          lineHeight={1.6}
          fontWeight="500"
        >
          {feedback}
        </Typography>
        <Typography
          sx={{
            color: "#1eaaf1",
            fontFamily: "Work Sans , Arial, sans-serif",
          }}
          mt={3}
        >
          Student
        </Typography>
        <Typography variant="body1" mt={1} mb={1}>
          {" "}
          {studentName ? studentName : "NA"}
        </Typography>
      </CardContent>
    </Card>
  );
};

const Feedbacks = () => {
  const [open, setOpen] = React.useState(false);

  const [list, setList] = useState([]);
  const [loader, setLoader] = useState(false);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isMobile = useMediaQuery("(max-width:600px)");
  const theme = useTheme();
  const navigate = useNavigate();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const slidesToShow = isMobile ? 1 : 3;
  const handleOpen = () => {
    navigate("/guardian-feedback");
  };
  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const onChangeSlide = useCallback((newSlide) => {
    console.log(newSlide, "===newSlide");
    setCurrentSlide(newSlide);
  }, []);

  console.log(currentSlide, "current slide");

  useEffect(() => {
    let interval = setInterval(() => {
      if (list.length) {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % list.length);
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [list]);

  useEffect(() => {
    getlist();
  }, []);

  const getlist = async () => {
    try {
      const res = await get(urls.gaurdianfeedback.getList);
      const feedbacks = res.data.data.filter((f) => f.approved).slice(-6);
      setList(feedbacks.reverse());
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      parentName: "",
      studentName: "",
      className: "",
      contactNumber: "",
      feedback: "",
    },
    validationSchema: Yup.object({
      parentName: Yup.string().required().label("Parent name"),
      studentName: Yup.string().label("Student name"),
      className: Yup.string().label("Class name").max(50),
      contactNumber: Yup.number().required().label("Contact number").nullable(),
      feedback: Yup.string().required().label("Feedback").max(300),
    }),
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      setLoader(true);
      try {
        const res = await post(`${urls.gaurdianfeedback.create}`, {
          parentName: values.parentName,
          studentName: values.studentName,
          className: values.className,
          feedback: values.feedback,
          approved: false,
          contactNumber: values.contactNumber,
        });

        if (res.data.success) {
          handleClose();
          formik.resetForm();
          //   getlist();
          setLoader(false);
        }
      } catch (error) {
        console.error(error);
        setLoader(false);
      }
    },
    validateOnBlur: false,
  });
  const { values, handleChange, handleBlur, touched, errors } = formik;

  const focused = {
    parentName: touched.parentName || values.parentName !== "",
    studentName: touched.studentName || values.studentName !== "",
    className: touched.className || values.className !== "",
    contactNumber: touched.contactNumber || values.contactNumber !== "",
    feedback: touched.feedback || values.feedback !== "",
  };

  const hasContent = {
    parentName: values.parentName !== "",
    studentName: values.studentName !== "",
    className: values.className !== "",
    contactNumber: values.contactNumber !== "",
    feedback: values.feedback !== "",
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        {" "}
        <h1>
          <div>
            <div>
              <span style={{ color: "#f07b47" }}> Guardian </span>
              Feedback{" "}
            </div>
            <div className="all-line1">
              <div className="first-line"></div>
              <div className="middle-line"></div>
              <div className="first-line last-line"></div>
            </div>
          </div>
        </h1>
      </Box>
      {/* <Tooltip title="Gaurdian Feedback">
        <Fab
          color="primary"
          aria-label="add"
          style={{
            position: "fixed",
            bottom: 320,
            right: 8,
            width: 59,
            zIndex: 999,
          }}
          onClick={handleOpen}>
          <FeedbackIcon />
        </Fab>
      </Tooltip> */}

      {!list.length ? null : (
        <Box
          sx={{
            height: "50vh",
            width: "80%",
            margin: "auto",
          }}
        >
          <Carousel
            slides={list.map((data) => ({
              key: data._id,
              content:
                data.approved === true ? (
                  <FeedbackItem
                    studentName={data.studentName}
                    feedback={data.feedback}
                    parentName={data.parentName}
                  />
                ) : (
                  ""
                ),
            }))}
            showNavigation={false}
            autoPlay={true}
            goToSlide={currentSlide}
            animationConfig={config.default}
            slidesToShow={slidesToShow}
            offsetRadius={1}
          />
          <DotsContainer style={{ textAlign: "center" }}>
            {list.map(
              (slide, index) =>
                slide.approved && (
                  <Dot
                    key={slide.key}
                    onClick={() => onChangeSlide(index)}
                    style={{
                      backgroundColor:
                        index === currentSlide
                          ? "#1eaaf1"
                          : "rgba(0, 0, 0, 0.2)",
                    }}
                  />
                )
            )}
          </DotsContainer>
        </Box>
      )}
    </>
  );
};

export default Feedbacks;
