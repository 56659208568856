import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Grid,
  Paper,
  Modal,
  TextField,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  TextareaAutosize,
  MenuItem,
  Tooltip,
  Dialog,
  DialogTitle,
  IconButton,
  InputAdornment,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import DeleteModal from "../Layout/Academic/DeleteModal";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Delete, Search } from "@mui/icons-material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { get, post, put, del } from "../../services/apis";
import { urls } from "../../services/urlConstant";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 450,
  bgcolor: "background.paper",
  p: 4,
};
const modal = {
  position: "absolute",
  top: "50%",
  left: "50%",

  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const Periodical = () => {
  const [search, setSearch] = useState("");
  const [list, setList] = React.useState([]);
  const [searchFilter, setSearchFilter] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = useState(dayjs(Date.now()));
  const [file, setFile] = useState();
  const [openUpload, setOpenUpload] = React.useState(false);
  const [dialog, setOpenDialog] = React.useState(false);
  const [editPerdiodical, setEditPeriodical] = useState();
  const [addForm, setAddForm] = useState({});
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [bulkUpdateModal, setBulkUpdateModal] = useState(false);
  const [fileChoosen, setFileChoosen] = useState(undefined);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    getperiodical();
  }, []);
  const getperiodical = async () => {
    try {
      const data = await get(urls.periodical.getList);

      setList(data.data.data);
      console.log(data.data.data, "ddaaaaaaattaaaaa");
    } catch (error) {
      console.log(error);
    }
  };

  const styles = {
    button: {
      backgroundColor: "orange",
    },
    textfield: {
      marginTop: "25px",
      width: "70%",
      marginRight: "5%",
    },
    addbutton: {
      paddingTop: "7px",
      paddingBottom: "7px",
      marginLeft: "-5%",
    },
    tab: {
      width: "100%",
      borderCollapse: "collapse",
    },
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      list.length > 0 &&
        setSearchFilter(
          list.filter((ele) =>
            ele.periodicalTitle
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(`${urls.periodical.delPeriodical}`, id);
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetDownloadSheet = async (e) => {
    e.preventDefault();
    try {
      const response = await get(`/periodical/downloadexcel`, {
        responseType: "blob",
      });

      const downloadUrl = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `periodical-stock.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetCountDownloadSheet = async (e) => {
    e.preventDefault();
    try {
      const response = await get(`/periodical/downloadcountexcel`, {
        responseType: "blob",
      });

      const downloadUrl = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `periodical-count.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetSampleSheet = async (e) => {
    e.preventDefault();
    try {
      const response = await get(`/periodical/downloadsample`, {
        responseType: "blob",
      });

      const downloadUrl = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `sample-periodicalfile.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileChange = (e) => {
    setFileChoosen(e.target.files[0]);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setEditPeriodical(null);
    setOpenDialog(false);
    resetForm();
  };
  const resetForm = () => {
    setAddForm({});
  };
  const handleClickOpenUpload = () => {
    setOpenUpload(true);
  };
  const handleCloseUpload = () => {
    setOpenUpload(false);
  };
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  const handleImages = (e) => {
    setImages((prev) => [...prev, ...e.target.files]);
  };
  const handleAddSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!editPerdiodical) {
        let imageIds = [];
        const formImage = new FormData();
        if (images.length > 0) {
          for (const img of images) {
            formImage.append("files", img);
          }
          const res = await post(urls.fileUpload.postFileUpload, formImage);
          imageIds = res.data.result.map((id) => id._id);
        }
        const { status } = await post(`${urls.periodical.create}`, {
          periodicalTitle: addForm.periodicalTitle,
          type: addForm.type,
          department: addForm.department,
          issueNumber: addForm.issueNumber,
          volumeNumber: addForm.volumeNumber,
          quantity: addForm.quantity,
          price: addForm.price,
          entrydate: date,
          backCover: imageIds,
        });
        getperiodical();
        handleClose();
      } else {
        const { status } = await put(
          `${urls.periodical.editPerdiodical}${editPerdiodical}`,
          "",
          {
            periodicalTitle: addForm.periodicalTitle,
            type: addForm.type,
            department: addForm.department,
            issueNumber: addForm.issueNumber,
            volumeNumber: addForm.volumeNumber,
            quantity: addForm.quantity,
            price: addForm.price,
            entrydate: date,
            // backCover: imageIds,
          }
        );
        getperiodical();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleTypeChange = (e) => {
    if (e.target.name === "bookCover") {
      setAddForm({
        ...addForm,
        bookCover: e.target.files[0],
      });
    } else {
      {
        setAddForm({
          ...addForm,
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  const handleEditChange = (id, data) => {
    setEditPeriodical(id);
    setAddForm({
      periodicalTitle: data.periodicalTitle,
      type: data.type,
      department: data.department,
      issueNumber: data.issueNumber,
      volumeNumber: data.volumeNumber,
      quantity: data.quantity,
      price: data.price,
      entrydate: date,
      // backCover: images.links,
    });
    handleClickOpen();
  };

  const handleSheetUpload = async (e) => {
    e.preventDefault();
    console.log("test ", fileChoosen);
    try {
      if (!fileChoosen) {
        console.error("No file selected.");
        return;
      }

      const formData = new FormData();
      formData.append("sheet", fileChoosen);
      const response = await put(`/periodical/uploadsample`, "", formData);
      console.log("Response:", response.data);
      console.log("Sheet uploaded successfully!");
      getperiodical();
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div style={{ margin: "10px" }}>
      <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span
              style={{
                color: "red",
                fontFamily: "cursive",
                textAlign: "start",
              }}
            >
              Periodical List:
            </span>
          </Grid>
        </Grid>
      </Card>

      <div style={{ margin: "15px" }}>
        <Paper sx={{ width: "100%", height: "60px", overflow: "hidden" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid xs={3}>
                <TextField
                  size="small"
                  style={styles.textfield}
                  value={search}
                  onChange={handleSearch}
                  label="Search"
                  variant="outlined"
                  placeholder="enter perodical "
                />
              </Grid>
              <Grid>
                <Button
                  variant="contained"
                  sx={{ mt: 3.2, ml: -2 }}
                  style={styles.addbutton}
                  onClick={handleClickOpen}
                >
                  <AddIcon />
                  Add Periodical
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>

        <Modal
          open={bulkUpdateModal}
          onClose={() => setBulkUpdateModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modal}>
            <div style={{ textAlign: "center", marginBottom: "30px" }}>
              <span style={{ color: "red", fontFamily: "cursive" }}>
                Bulk Upload
              </span>
            </div>
            <form onSubmit={handleSheetUpload}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Tooltip title="GetSample">
                    <LoadingButton
                      variant="contained"
                      size="small"
                      type="submit"
                      loading={loadingBtn}
                      onClick={handleGetSampleSheet}
                      style={styles.Button}
                    >
                      Sample
                    </LoadingButton>
                  </Tooltip>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>
                    <Tooltip title="Upload">
                      <Button
                        variant="contained"
                        style={styles.Button}
                        component="label"
                      >
                        Upload
                        <input
                          name="bulkUpdate"
                          onChange={handleFileChange}
                          hidden
                          accept=".xlsx, .xls, xlsm, .xlsb, .csv, .ods"
                          type="file"
                        />
                      </Button>
                    </Tooltip>

                    <div>{fileChoosen && fileChoosen.name}</div>
                  </div>
                </div>
                <div>
                  <Tooltip title="Submit">
                    <Button
                      variant="contained"
                      style={styles.Button}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </form>
          </Box>
        </Modal>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form className="form-tag" onSubmit={handleAddSubmit}>
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign="center"
                marginBottom="30px"
                // onChange={handleAddForm}
              >
                {editPerdiodical ? "update" : "Add"}
              </Typography>
              <div>
                <TextField
                  required
                  id="filled-required"
                  variant="standard"
                  label="Periodical Title"
                  name="periodicalTitle"
                  value={addForm.periodicalTitle || ""}
                  onChange={handleTypeChange}
                  sx={{
                    m: 1,
                    minWidth: 227,
                    label: { fontSize: 12 },
                    label: { fontSize: 12 },
                  }}
                />

                <FormControl variant="standard" sx={{ m: 0.3, minWidth: 227 }}>
                  <InputLabel sx={{ fontSize: 8 }}>Type</InputLabel>
                  <Select
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    label="type"
                    name="type"
                    value={addForm.type || ""}
                    onChange={handleTypeChange}
                  >
                    <MenuItem value={"journal"}>journal</MenuItem>
                    <MenuItem value={"magazines"}>magazines</MenuItem>
                    <MenuItem value={"newspaper"}>newspaper</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  required
                  id="filled-required"
                  variant="standard"
                  sx={{ m: 1, minWidth: 227 }}
                  label="Department"
                  name="department"
                  value={addForm.department || ""}
                  onChange={handleTypeChange}
                />
                <div>
                  <TextField
                    required
                    id="filled-required"
                    variant="standard"
                    sx={{ m: 1, minWidth: 227 }}
                    label="Issue Number"
                    name="issueNumber"
                    value={addForm.issueNumber || ""}
                    onChange={handleTypeChange}
                  />

                  <TextField
                    required
                    id="filled-required"
                    variant="standard"
                    label="Valume Number"
                    name="volumeNumber"
                    value={addForm.volumeNumber || ""}
                    onChange={handleTypeChange}
                    sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                  />

                  <TextField
                    required
                    id="filled-required"
                    variant="standard"
                    label="Quantity"
                    name="quantity"
                    value={addForm.quantity || ""}
                    onChange={handleTypeChange}
                    sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                  />
                </div>

                <div>
                  <TextField
                    id="filled-required"
                    variant="standard"
                    sx={{ m: 1, minWidth: 227 }}
                    label="Price"
                    name="price"
                    value={addForm.price || ""}
                    onChange={handleTypeChange}
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="From Date"
                      value={date}
                      onChange={(newDate) => setDate(newDate)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          size="small"
                          style={{ marginTop: "5px" }}
                        />
                      )}
                    />
                  </LocalizationProvider>

                  {editPerdiodical ? (
                    ""
                  ) : (
                    <div style={{ marginTop: "2px", display: "flex" }}>
                      <FormControl
                        variant="standard"
                        sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                      >
                        <label>Book Cover</label>
                        <input
                          type="file"
                          name="image"
                          accept="image/*"
                          onChange={handleImages}
                        />
                      </FormControl>
                    </div>
                  )}
                </div>
              </div>
              <div className="cancel-submit-btn">
                <div>
                  <Tooltip title="Cancel">
                    <Button
                      onClick={handleClose}
                      variant="outlined"
                      size="medium"
                      type="button"
                      sx={{ m: 1 }}
                    >
                      Cancel
                    </Button>
                  </Tooltip>
                  <Tooltip title="Submit">
                    <Button
                      variant="contained"
                      size="medium"
                      type="submit"
                      sx={{
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      Submit
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </Box>
          </form>
        </Modal>

        <div>
          <Paper
            sx={{ width: "100%", height: "60px", overflow: "hidden", mt: 3 }}
          >
            <Stack spacing={2} direction="row" marginTop={1.5} marginLeft={1}>
              <Button variant="contained" onClick={handleGetDownloadSheet}>
                Download
              </Button>
              <Button variant="contained" onClick={handleGetCountDownloadSheet}>
                Count By title
              </Button>
              <Button
                variant="contained"
                onClick={() => setBulkUpdateModal(true)}
              >
                Bulk Upload
              </Button>
            </Stack>
          </Paper>
        </div>
        <Paper
          sx={{ width: "100%", height: "60px", overflow: "hidden", mt: 2 }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: "22px",
            }}
          ></div>
        </Paper>
        <Modal
          open={openUpload}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form className="form-tag">
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign="center"
                marginBottom="30px"
                // onChange={handleAddForm}
              >
                Bulk Upload
              </Typography>

              <div style={{ marginTop: "2px", display: "flex" }}>
                <FormControl
                  variant="standard"
                  sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                >
                  <label>Upload File</label>
                  <input type="file" onChange={handleChange} />
                </FormControl>
              </div>

              <div className="cancel-submit-btn">
                <div>
                  <Tooltip title="Cancel">
                    <Button
                      onClick={handleCloseUpload}
                      variant="outlined"
                      size="medium"
                      type="button"
                      sx={{ m: 1 }}
                    >
                      Cancel
                    </Button>
                  </Tooltip>
                  <Tooltip title="Submit">
                    <Button
                      variant="contained"
                      size="medium"
                      type="submit"
                      sx={{
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      Add
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </Box>
          </form>
        </Modal>

        <Table>
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow sx={{ color: "white" }}>
              <TableCell sx={{ color: "white" }} align="center">
                #SL
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Title
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Type
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Issue Number
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Valume Number
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Book Cover
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Price
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Quantity
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Journal Entry Date
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {search
              ? searchFilter.map((data, index) => (
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{data.periodicalTitle}</TableCell>
                    <TableCell align="center">{data.type}</TableCell>
                    <TableCell align="center">{data.issueNumber}</TableCell>
                    <TableCell align="center">{data.volumeNumber}</TableCell>
                    <TableCell align="center">
                      {" "}
                      {data.backCover ? (
                        <img
                          src={data.backCover.link}
                          alt="NA"
                          style={{
                            width: "100px",
                            height: "60px",
                            resize: "contain",
                          }}
                        ></img>
                      ) : (
                        "NA"
                      )}
                    </TableCell>
                    <TableCell align="center">{data.price}</TableCell>
                    <TableCell align="center">{data.quantity}</TableCell>
                    <TableCell align="center">
                      {dayjs(data.entrydate).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          style={{ color: "#1b3779" }}
                          onClick={() => handleEditChange(data._id, data)}
                        >
                          <EditIcon />
                        </Button>
                        <Button
                          style={{
                            color: "#1b3779",
                          }}
                          onClick={() => setDeleteModal(data._id, data)}
                        >
                          <Delete />
                        </Button>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={data._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              : list.map((data, index) => (
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{data.periodicalTitle}</TableCell>
                    <TableCell align="center">{data.type}</TableCell>
                    <TableCell align="center">{data.issueNumber}</TableCell>
                    <TableCell align="center">{data.volumeNumber}</TableCell>
                    <TableCell align="center">
                      {" "}
                      {data.backCover ? (
                        <img
                          src={data.backCover.link}
                          alt="NA"
                          style={{
                            width: "100px",
                            height: "60px",
                            resize: "contain",
                          }}
                        ></img>
                      ) : (
                        "NA"
                      )}
                    </TableCell>
                    <TableCell align="center">{data.price}</TableCell>
                    <TableCell align="center">{data.quantity}</TableCell>
                    <TableCell align="center">
                      {dayjs(data.entrydate).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          style={{ color: "#1b3779" }}
                          onClick={() => handleEditChange(data._id, data)}
                        >
                          <EditIcon />
                        </Button>
                        <Button
                          style={{
                            color: "#1b3779",
                          }}
                          onClick={() => setDeleteModal(data._id, data)}
                        >
                          <Delete />
                        </Button>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={data._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
        {!list.length && !search.trim() && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        {search.trim() && !searchFilter.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
      </div>
    </div>
  );
};

export default Periodical;
