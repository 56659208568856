import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import { useContext, useEffect, useRef } from "react";
import SettingContext from "../../../context/SettingsContext";
import { get } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import { useState } from "react";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import CustomSelect from "../../CustomSelect";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";

import {
  PieChart,
  Pie,
  BarChart,
  Bar,
  Cell,
  ResponsiveContainer,
  Legend,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  LabelList,
} from "recharts";
import ProgressReportClassNine from "./PogressReportClassNine";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: "1px solid black",
  backgroundColor: "#fff",
  textAlign: "center",
  textTransform: "uppercase",
  padding: "4px",
  fontWeight: "bold",
}));
const StyledTableHead = styled(TableCell)(({ theme }) => ({
  border: "1px solid black",
  textAlign: "center",
  padding: "4px",
  textTransform: "uppercase",
  color: "white",
  fontWeight: "bold",
  backgroundColor: "#03a9f4",
  fontSize: "16px",
}));
const Title = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#ff5722",
  borderBottom: 0,
  textTransform: "uppercase",

  border: "1px solid black",
  fontWeight: 600,
  color: "white",
  fontSize: "16px",
  padding: "4px",
}));

function generateRandomColors(length) {
  const randomColor = () => Math.floor(Math.random() * 256);
  const colors = new Set();

  while (colors.size < length) {
    const color = `rgb(${randomColor()}, ${randomColor()}, ${randomColor()})`;
    colors.add(color);
  }

  return Array.from(colors);
}

const colors = [
  "rgb(78, 36, 100)",
  "rgb(163, 174, 156)",
  "rgb(184, 60, 211)",
  "rgb(99, 179, 255)",
  "rgb(93, 152, 142)",
  "rgb(20, 177, 139)",
  "rgb(94, 16, 27)",
  "rgb(47, 225, 244)",
  "rgb(24, 162, 87)",
  "rgb(71, 249, 247)",
];

// const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", ""];
const COLORS = [
  "rgb(78, 36, 100)",
  "rgb(163, 174, 156)",
  "rgb(184, 60, 211)",
  "rgb(99, 179, 255)",
  "rgb(93, 152, 142)",
  "rgb(20, 177, 139)",
  "rgb(94, 16, 27)",
  "rgb(47, 225, 244)",
  "rgb(24, 162, 87)",
  "rgb(71, 249, 247)",
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(1)}%`}
    </text>
  );
};

const StudenttData = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: "black",
}));

const list = [
  {
    title:
      "This Achievement Record mirrors the progress and achievment of yor child in the  Scholastic and Co-scholastic areas. It is intended to help you to know and understand your child better.",
  },
  {
    title:
      "The grading system followed / mentioned in the CCE. The CCE comprises two semisters having 2 Formative & Summative Assessment each.",
  },
  {
    title:
      "The focus is on identifying the talents of your child and empowerinng him/her with Positive inputs.",
  },
  {
    title:
      "Kindly ensure that your child does not miss any Formative Or Summative assessment.",
  },
  {
    title:
      "The Achievment Record is in important document. Please ensure that the record is not lost.",
  },
];

const ProgressReport = () => {
  const { settingsContext } = useContext(SettingContext);
  const [settingDetails, setSettingDetails] = useState({});
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [gettingData, setGettingData] = useState(false);
  const [reports, setReports] = useState([]);
  const componentRef = useRef();

  const handleGetReportCard = async (values) => {
    let filter = {
      className: values.class,
    };
    if (values.studentId !== "all") {
      filter["studentId"] = values.studentId;
    }
    setGettingData(true);
    try {
      const selectedClass = classes.find((c) => c._id === values.class);
      const url =
        selectedClass && selectedClass.className === "9"
          ? urls.progressReport.getReportNinthClass
          : urls.progressReport.getReport;

      const { data } = await get(url, {
        params: { ...filter },
      });
      console.log(data.data, "1234567899yyyyyyy");
      setReports(data.data);
      setGettingData(false);
    } catch (error) {
      setGettingData(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      class: "",
      studentId: "",
    },
    onSubmit: handleGetReportCard,
  });

  useEffect(() => {
    setReports([]);
  }, [formik.values.class]);

  const getClasses = async () => {
    try {
      const { data } = await get(urls.class.getAllClass);
      setClasses(
        data.data.map((c) => ({ ...c, label: c.className, value: c._id }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getClasses();
  }, []);

  const getStudents = async () => {
    try {
      const { data } = await get(urls.students.getAllStudent, {
        params: { search: { "academicInfo.class": formik.values.class } },
      });
      setStudents([
        { label: "All", value: "all" },
        ...data.data.map((s) => ({
          ...s,
          label: s.basicInfo.name,
          value: s._id,
        })),
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (formik.values.class) {
      getStudents();
    }
  }, [formik.values.class]);

  useEffect(() => {
    const getSettings = async () => {
      try {
        const response = await get(urls.settings.getAllSettings);
        setSettingDetails(response.data.data[0]);
      } catch (error) {
        console.log(error);
      }
    };
    getSettings();
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const selectedClass = classes.find((c) => c._id === formik.values.class);

  return (
    <>
      <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span
              style={{
                color: "red",
                fontFamily: "cursive",
                textAlign: "start",
              }}
            >
              Progress Report:
            </span>
          </Grid>
        </Grid>
      </Card>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "10px",
          justifyContent: "flex-start",
          columnGap: "10px",
          marginBottom: "20px",
          padding: "10px",
          margin: "10px",
          borderRadius: "5px",
        }}
        component={Paper}
      >
        <CustomSelect
          formik={formik}
          label="Select Class"
          name="class"
          options={classes}
          required={true}
        />
        <CustomSelect
          formik={formik}
          label="Select Student"
          name="studentId"
          options={students}
          required={true}
        />
        <LoadingButton
          disabled={!formik.values.studentId || !formik.values.class}
          loading={gettingData}
          onClick={formik.handleSubmit}
          variant="contained"
          size="small"
        >
          Submit
        </LoadingButton>
      </Box>
      {selectedClass && selectedClass.className === "9" ? (
        <ProgressReportClassNine
          reports={reports}
          formik={formik}
          classes={classes}
        />
      ) : (
        <>
          {reports.length ? (
            <Box
              component={Paper}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                padding: "10px",
                margin: "10px",
              }}
            >
              <ReactToPrint
                trigger={() => (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handlePrint}
                  >
                    Print
                  </Button>
                )}
                content={() => componentRef.current}
              />
            </Box>
          ) : null}
          <Box
            ref={componentRef}
            sx={{
              breakInside: "avoid",
              overflow: "hidden",
              zoom: 0.64,
              // pageBreakAfter: "always",
              padding: "10px",
            }}
          >
            {reports.map((student, i) => (
              <Box key={student._id} sx={{ padding: "20px" }}>
                <Box sx={{ border: "1px solid black" }}>
                  <Paper elevation={0} sx={{ borderBottom: "1px solid black" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "20px",
                        padding: "20px 0",
                      }}
                    >
                      {" "}
                      <img
                        src={settingsContext.schoolLogo}
                        style={{ objectFit: "contain" }}
                        width={120}
                        height={120}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#03a9f4",
                            fontSize: "34px",
                            fontWeight: 700,
                          }}
                        >
                          {settingsContext.schoolName}
                        </Typography>
                        <Typography sx={{ fontSize: "24px", color: "#ff5722" }}>
                          {settingDetails.address}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            color: "black",
                            fontSize: "24px",
                          }}
                        >
                          Progress Report {settingsContext.activeAcademicYear}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            color: "black",
                            fontSize: "24px",
                          }}
                        >
                          DISE Code : 29030504818
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                  <Box sx={{ backgroundColor: "whitesmoke", padding: "10px" }}>
                    <Typography
                      sx={{
                        textAlign: "center",
                        paddingTop: "10px",
                        fontWeight: 600,
                        fontSize: "20px",
                        paddingTop: "10px",
                      }}
                    >
                      STUDENT PROFILE
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "20px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <StudenttData>
                          Student Name &nbsp; : {student.basicInfo.name}
                        </StudenttData>
                        <StudenttData>
                          Father Name &nbsp; &nbsp; :{" "}
                          {student.fatherInfo.fatherName}
                        </StudenttData>
                        <StudenttData>
                          Mother Name &nbsp; &nbsp;:{" "}
                          {student.motherInfo.motherName}
                        </StudenttData>
                        <StudenttData>
                          Class &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; : {student.academicInfo.class.className}
                        </StudenttData>
                        <StudenttData>
                          Section &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                          &nbsp; : {student.academicInfo.section.sectionName}
                        </StudenttData>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",

                          flexDirection: "column",
                        }}
                      >
                        <StudenttData>
                          G.R.No &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;:{" "}
                          {student.basicInfo.grNo}
                        </StudenttData>
                        <StudenttData>
                          SATS No &nbsp; &nbsp; &nbsp; &nbsp; :{" "}
                          {student.basicInfo.satNo}
                        </StudenttData>

                        <StudenttData>
                          Date of Birth &nbsp; :{" "}
                          {dayjs(student.basicInfo.dob).format("DD/MM/YYYY")}
                        </StudenttData>
                        <StudenttData>
                          Mobile No &nbsp; &nbsp; &nbsp; :{" "}
                          {student.contactNumber}
                        </StudenttData>
                        <StudenttData>
                          Roll No. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; :{" "}
                          {student.academicInfo.rollNo}
                        </StudenttData>
                      </Box>
                      <Box>
                        <img
                          src={student.studentPhoto?.link}
                          alt="student photo"
                          height={150}
                          width={150}
                          style={{ objectFit: "contain" }}
                        />
                      </Box>
                    </Box>
                    <Table sx={{ width: "100%", marginBottom: "20px" }}>
                      <TableHead>
                        <TableRow>
                          <Title align="center" colSpan={13}>
                            Semister 1
                          </Title>
                        </TableRow>
                        <TableRow>
                          <StyledTableHead
                            sx={{ width: "30px" }}
                            align="center"
                            rowSpan={2}
                          >
                            S.NO
                          </StyledTableHead>
                          <StyledTableHead
                            align="center"
                            sx={{ width: "60px" }}
                            rowSpan={2}
                          >
                            Subjects
                          </StyledTableHead>
                          <StyledTableHead
                            align="center"
                            colSpan={3}
                            sx={{ width: "100px" }}
                          >
                            FA1
                          </StyledTableHead>
                          <StyledTableHead
                            align="center"
                            colSpan={3}
                            sx={{ width: "100px" }}
                          >
                            FA2
                          </StyledTableHead>
                          <StyledTableHead
                            align="center"
                            colSpan={3}
                            sx={{ width: "100px" }}
                          >
                            SA1
                          </StyledTableHead>
                          <StyledTableHead
                            width="120px"
                            align="center"
                            rowSpan={2}
                          >
                            50%
                          </StyledTableHead>

                          <StyledTableHead
                            width="120px"
                            align="center"
                            rowSpan={2}
                          >
                            GR
                          </StyledTableHead>
                        </TableRow>
                        <TableRow>
                          <StyledTableHead width="120px" align="center">
                            25
                          </StyledTableHead>
                          <StyledTableHead width="120px" align="center">
                            10%
                          </StyledTableHead>
                          <StyledTableHead width="120px" align="center">
                            GR
                          </StyledTableHead>
                          <StyledTableHead width="120px" align="center">
                            25
                          </StyledTableHead>
                          <StyledTableHead width="120px" align="center">
                            10%
                          </StyledTableHead>
                          <StyledTableHead width="120px" align="center">
                            GR
                          </StyledTableHead>
                          <StyledTableHead width="120px" align="center">
                            50
                          </StyledTableHead>
                          <StyledTableHead width="120px" align="center">
                            30%
                          </StyledTableHead>
                          <StyledTableHead width="120px" align="center">
                            GR
                          </StyledTableHead>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {student.semesterOneData?.map((d, i) => (
                          <TableRow key={i}>
                            <StyledTableCell>{i + 1}</StyledTableCell>
                            {d.map((m, i) => (
                              <StyledTableCell align="center" key={i}>
                                {typeof m == "string" ? m : m?.toFixed(1)}
                              </StyledTableCell>
                            ))}
                          </TableRow>
                        ))}

                        <TableRow>
                          <StyledTableHead align="center"></StyledTableHead>
                          <StyledTableHead align="center">
                            Total
                          </StyledTableHead>
                          <StyledTableHead align="center">
                            {Number(student.semesterOneTotal.fa1).toFixed(1)}
                          </StyledTableHead>
                          <StyledTableHead align="center">
                            {Number(student.semesterOneTotal.FA1).toFixed(1)}
                          </StyledTableHead>

                          <StyledTableHead align="center">
                            {Number(
                              student.semesterOneTotal.fa1Percentage
                            ).toFixed(1)}
                            %
                          </StyledTableHead>
                          <StyledTableHead align="center">
                            {Number(student.semesterOneTotal.fa2).toFixed(1)}
                          </StyledTableHead>
                          <StyledTableHead align="center">
                            {Number(student.semesterOneTotal.FA2).toFixed(1)}
                          </StyledTableHead>

                          <StyledTableHead align="center">
                            {Number(
                              student.semesterOneTotal.fa2Percentage
                            ).toFixed(1)}
                            %
                          </StyledTableHead>
                          <StyledTableHead align="center">
                            {Number(student.semesterOneTotal.sa1).toFixed(1)}
                          </StyledTableHead>
                          <StyledTableHead align="center">
                            {Number(student.semesterOneTotal.SA1).toFixed(1)}
                          </StyledTableHead>

                          <StyledTableHead align="center">
                            {Number(
                              student.semesterOneTotal.sa1Percentage
                            ).toFixed(1)}
                            %
                          </StyledTableHead>
                          <StyledTableHead align="center" colSpan={2}>
                            {Number(student.semesterOneTotal.total).toFixed(1)}
                          </StyledTableHead>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <Table sx={{ width: "100%", marginBottom: "20px" }}>
                      <TableHead>
                        <TableRow>
                          <Title align="center" colSpan={13}>
                            Semister 2
                          </Title>
                        </TableRow>
                        <TableRow>
                          <StyledTableHead
                            sx={{ width: "30px" }}
                            align="center"
                            rowSpan={2}
                          >
                            S.NO
                          </StyledTableHead>
                          <StyledTableHead
                            align="center"
                            sx={{ width: "60px" }}
                            rowSpan={2}
                          >
                            Subjects
                          </StyledTableHead>
                          <StyledTableHead
                            align="center"
                            colSpan={3}
                            sx={{ width: "100px" }}
                          >
                            FA3
                          </StyledTableHead>
                          <StyledTableHead
                            align="center"
                            colSpan={3}
                            sx={{ width: "100px" }}
                          >
                            FA4
                          </StyledTableHead>
                          <StyledTableHead
                            align="center"
                            colSpan={3}
                            sx={{ width: "100px" }}
                          >
                            SA2
                          </StyledTableHead>
                          <StyledTableHead
                            width="120px"
                            align="center"
                            rowSpan={2}
                          >
                            50%
                          </StyledTableHead>

                          <StyledTableHead
                            width="120px"
                            align="center"
                            rowSpan={2}
                          >
                            GR
                          </StyledTableHead>
                        </TableRow>
                        <TableRow>
                          <StyledTableHead width="120px" align="center">
                            25
                          </StyledTableHead>
                          <StyledTableHead width="120px" align="center">
                            10%
                          </StyledTableHead>
                          <StyledTableHead width="120px" align="center">
                            GR
                          </StyledTableHead>
                          <StyledTableHead width="120px" align="center">
                            25
                          </StyledTableHead>
                          <StyledTableHead width="120px" align="center">
                            10%
                          </StyledTableHead>
                          <StyledTableHead width="120px" align="center">
                            GR
                          </StyledTableHead>
                          <StyledTableHead width="120px" align="center">
                            {classes.find((c) => c._id == formik.values.class)
                              ?.className === "8"
                              ? 60
                              : 50}
                          </StyledTableHead>
                          <StyledTableHead width="120px" align="center">
                            30%
                          </StyledTableHead>
                          <StyledTableHead width="120px" align="center">
                            GR
                          </StyledTableHead>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {student.semesterTwoData?.map((d, i) => (
                          <TableRow key={i}>
                            <StyledTableCell>{i + 1}</StyledTableCell>
                            {d.map((m, i) => (
                              <StyledTableCell align="center" key={i}>
                                {typeof m == "string" ? m : m?.toFixed(1)}
                              </StyledTableCell>
                            ))}
                          </TableRow>
                        ))}

                        <TableRow>
                          <StyledTableHead align="center"></StyledTableHead>
                          <StyledTableHead align="center">
                            Total
                          </StyledTableHead>
                          <StyledTableHead align="center">
                            {Number(student.semesterTwoTotal.fa3).toFixed(1)}
                          </StyledTableHead>
                          <StyledTableHead align="center">
                            {Number(student.semesterTwoTotal.FA3).toFixed(1)}
                          </StyledTableHead>

                          <StyledTableHead align="center">
                            {Number(
                              student.semesterTwoTotal.fa3Percentage
                            ).toFixed(1)}
                            %
                          </StyledTableHead>
                          <StyledTableHead align="center">
                            {Number(student.semesterTwoTotal.fa4).toFixed(1)}
                          </StyledTableHead>
                          <StyledTableHead align="center">
                            {student.semesterTwoTotal.FA4.toFixed(1)}
                          </StyledTableHead>

                          <StyledTableHead align="center">
                            {Number(
                              student.semesterTwoTotal.fa4Percentage
                            ).toFixed(1)}
                            %
                          </StyledTableHead>
                          <StyledTableHead align="center">
                            {Number(student.semesterTwoTotal.sa2).toFixed(1)}
                          </StyledTableHead>

                          <StyledTableHead align="center">
                            {Number(student.semesterTwoTotal.SA2).toFixed(1)}
                          </StyledTableHead>

                          <StyledTableHead align="center">
                            {Number(
                              student.semesterTwoTotal.sa2Percentage
                            ).toFixed(1)}
                            %
                          </StyledTableHead>
                          <StyledTableHead align="center" colSpan={2}>
                            {Number(student.semesterTwoTotal.total).toFixed(1)}
                          </StyledTableHead>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <Table sx={{ width: "100%", marginBottom: "20px" }}>
                      <TableHead sx={{ backgroundColor: "#03a9f4" }}>
                        <TableRow>
                          <Title align="center" colSpan={13}>
                            Total Semister1 + Semister2
                          </Title>
                        </TableRow>
                        <TableRow>
                          <StyledTableHead align="center">SL</StyledTableHead>
                          <StyledTableHead align="center">
                            Subjects
                          </StyledTableHead>
                          <StyledTableHead align="center">
                            Semister-1(50%)
                          </StyledTableHead>
                          <StyledTableHead align="center">
                            Semister-2(50%)
                          </StyledTableHead>
                          <StyledTableHead align="center">
                            Grand Total(100%)
                          </StyledTableHead>
                          <StyledTableHead align="center">GR</StyledTableHead>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {student.totalSemesterData.map((d, i) => (
                          <TableRow key={i}>
                            <StyledTableCell>{i + 1}</StyledTableCell>
                            {d.map((m, index) => (
                              <StyledTableCell align="center" key={index}>
                                {typeof m == "string" ? m : m?.toFixed(1)}
                              </StyledTableCell>
                            ))}
                          </TableRow>
                        ))}

                        <TableRow>
                          <StyledTableHead align="center"></StyledTableHead>
                          <StyledTableHead align="center">
                            Total
                          </StyledTableHead>
                          <StyledTableHead align="center">
                            {Number(student.totalSemesterTotal[0]).toFixed(1)}
                          </StyledTableHead>
                          <StyledTableHead align="center">
                            {Number(student.totalSemesterTotal[1]).toFixed(1)}
                          </StyledTableHead>
                          <StyledTableHead align="center">
                            {Number(student.totalSemesterTotal[2]).toFixed(1)}/
                            {student.semesterOneData.length * 100}
                          </StyledTableHead>
                          <StyledTableHead align="center">
                            {student.totalSemesterTotal[3]}
                          </StyledTableHead>
                        </TableRow>
                        <TableRow sx={{ backgroundColor: "white" }}>
                          <StyledTableCell colSpan={5}></StyledTableCell>
                          <StyledTableCell
                            colSpan={1}
                            sx={{
                              textAlign: "center",
                              textTransform: "capitalize",
                              backgroundColor: "#ff5722",
                            }}
                          >
                            <Typography
                              sx={{ fontWeight: "bold", color: "white" }}
                            >
                              {" "}
                              Percentage :{" "}
                              {(
                                (student.totalSemesterTotal[2] /
                                  (student.semesterOneData.length * 100)) *
                                100
                              ).toFixed(2)}
                              %
                            </Typography>
                          </StyledTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <Box paddingTop="20px">
                      <Table
                        sx={{
                          width: "100%",
                          marginBottom: "20px",
                          pageBreakBefore: "always",
                        }}
                      >
                        <TableHead sx={{ backgroundColor: "#03a9f4" }}>
                          <TableRow>
                            <Title align="center" colSpan={13}>
                              Co-Scholastic Area
                            </Title>
                          </TableRow>
                          <TableRow>
                            <StyledTableHead alignItems="center">
                              SL
                            </StyledTableHead>
                            <StyledTableHead alignItems="center">
                              Subjects
                            </StyledTableHead>
                            <StyledTableHead alignItems="center">
                              FA1
                            </StyledTableHead>
                            <StyledTableHead alignItems="center">
                              FA2
                            </StyledTableHead>
                            <StyledTableHead alignItems="center">
                              SA1
                            </StyledTableHead>
                            <StyledTableHead alignItems="center">
                              FA3
                            </StyledTableHead>
                            <StyledTableHead alignItems="center">
                              FA4
                            </StyledTableHead>
                            <StyledTableHead alignItems="center">
                              SA2
                            </StyledTableHead>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <StyledTableCell alignItems="center">
                              1
                            </StyledTableCell>
                            <StyledTableCell alignItems="center">
                              Computer Science
                            </StyledTableCell>
                            <StyledTableCell alignItems="center">
                              A
                            </StyledTableCell>
                            <StyledTableCell alignItems="center">
                              A
                            </StyledTableCell>
                            <StyledTableCell alignItems="center">
                              A
                            </StyledTableCell>
                            <StyledTableCell alignItems="center">
                              A
                            </StyledTableCell>
                            <StyledTableCell alignItems="center">
                              A
                            </StyledTableCell>
                            <StyledTableCell alignItems="center">
                              A
                            </StyledTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "20px",
                      }}
                    >
                      <Grid item xs={6} md={5}>
                        <Box>
                          <ResponsiveContainer
                            width="50%"
                            height={student.examWiseReport.length * 50}
                          >
                            <BarChart
                              layout="vertical"
                              data={student.examWiseReport}
                              barGap={0}
                              barCategoryGap={1}
                              barSize={35}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis type="number" />
                              <YAxis dataKey="examName" type="category" />
                              <Tooltip />
                              <Bar dataKey="marks">
                                {student.examWiseReport.map((entry, index) => (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={colors[index % colors.length]}
                                  />
                                ))}
                                <LabelList
                                  dataKey="marks"
                                  position="right"
                                  fill="black"
                                  width={60}
                                />
                              </Bar>
                            </BarChart>
                          </ResponsiveContainer>
                        </Box>
                        <Box
                          sx={{
                            textAlign: "left",
                            marginTop: "20px",
                            width: "50%",
                            mx: "auto",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: "20px",
                            }}
                          >
                            Exam wise marks chart
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={6} md={6}>
                        <Box sx={{ marginTop: "-100px" }}>
                          <ResponsiveContainer
                            width="50%"
                            height={student.subjectWiseMarks.length * 60}
                          >
                            <PieChart>
                              <Legend
                                layout="vertical"
                                align="right"
                                verticalAlign="middle"
                                margin={{
                                  top: 0,
                                  right: 300,
                                  left: 0,
                                  bottom: 0,
                                }}
                              />
                              <Pie
                                data={student.subjectWiseMarks}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                label={renderCustomizedLabel}
                                outerRadius={100}
                                fill="#8884d8"
                                dataKey="value"
                              >
                                {student.subjectWiseMarks.map(
                                  (entry, index) => (
                                    <Cell
                                      key={`cell-${index}`}
                                      fill={COLORS[index % COLORS.length]}
                                    />
                                  )
                                )}
                              </Pie>
                            </PieChart>
                          </ResponsiveContainer>
                        </Box>
                        <Box
                          sx={{
                            textAlign: "left",

                            width: "50%",
                            mx: "auto",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: "20px",
                            }}
                          >
                            Subject wise marks chart
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Table
                      sx={{
                        width: "100%",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      <TableHead sx={{ backgroundColor: "#03a9f4" }}>
                        <TableRow>
                          <Title align="center" colSpan={13}>
                            Attendence
                          </Title>
                        </TableRow>
                        <TableRow>
                          <StyledTableHead></StyledTableHead>

                          <StyledTableHead alignItems="center">
                            Semister-1
                          </StyledTableHead>
                          <StyledTableHead alignItems="center">
                            Semister-2
                          </StyledTableHead>
                          <StyledTableHead alignItems="center">
                            Total
                          </StyledTableHead>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <StyledTableCell alignItems="center">
                            Total working days
                          </StyledTableCell>
                          <StyledTableCell alignItems="center">
                            109
                          </StyledTableCell>
                          <StyledTableCell alignItems="center">
                            135
                          </StyledTableCell>
                          <StyledTableCell alignItems="center">
                            244
                          </StyledTableCell>
                        </TableRow>

                        <TableRow>
                          <StyledTableCell alignItems="center">
                            Attendence of the student
                          </StyledTableCell>
                          <StyledTableCell alignItems="center"></StyledTableCell>
                          <StyledTableCell alignItems="center"></StyledTableCell>
                          <StyledTableCell alignItems="center"></StyledTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <Typography
                      my={3}
                      sx={{
                        fontWeight: 700,
                        textTransform: "upperCase",
                        fontSize: "20px",
                      }}
                      textAlign="center"
                    >
                      Grading System
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={6} md={6}>
                        <Table sx={{ width: "100%", height: "100%" }}>
                          <TableHead sx={{ backgroundColor: "#03a9f4" }}>
                            <TableRow>
                              <Title align="center" colSpan={13}>
                                Co-Scholastic Area(Grading On % Point Scale)
                              </Title>
                            </TableRow>
                            <TableRow>
                              <StyledTableHead alignItems="center">
                                Marks Range
                              </StyledTableHead>
                              <StyledTableHead alignItems="center">
                                Grade
                              </StyledTableHead>
                              <StyledTableHead alignItems="center">
                                Grade Points
                              </StyledTableHead>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <StyledTableCell alignItems="center">
                                90-100
                              </StyledTableCell>
                              <StyledTableCell alignItems="center">
                                A+
                              </StyledTableCell>
                              <StyledTableCell alignItems="center">
                                9-10
                              </StyledTableCell>
                            </TableRow>
                            <TableRow>
                              <StyledTableCell alignItems="center">
                                70-89
                              </StyledTableCell>
                              <StyledTableCell alignItems="center">
                                A
                              </StyledTableCell>
                              <StyledTableCell alignItems="center">
                                7-8
                              </StyledTableCell>
                            </TableRow>
                            <TableRow>
                              <StyledTableCell alignItems="center">
                                50-69
                              </StyledTableCell>
                              <StyledTableCell alignItems="center">
                                B+
                              </StyledTableCell>
                              <StyledTableCell alignItems="center">
                                5-6
                              </StyledTableCell>
                            </TableRow>
                            <TableRow>
                              <StyledTableCell alignItems="center">
                                30-49
                              </StyledTableCell>
                              <StyledTableCell alignItems="center">
                                B
                              </StyledTableCell>
                              <StyledTableCell alignItems="center">
                                3-4
                              </StyledTableCell>
                            </TableRow>
                            <TableRow>
                              <StyledTableCell alignItems="center">
                                0-29
                              </StyledTableCell>
                              <StyledTableCell alignItems="center">
                                C
                              </StyledTableCell>
                              <StyledTableCell alignItems="center">
                                1-2
                              </StyledTableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>

                      <Grid item xs={6} md={6}>
                        <Table sx={{ width: "100%", height: "100%" }}>
                          <TableHead sx={{ backgroundColor: "#03a9f4" }}>
                            <TableRow>
                              <Title align="center" colSpan={13}>
                                Co-Scholastic Area(Grading On % Point Scale)
                              </Title>
                            </TableRow>
                            <TableRow>
                              <StyledTableHead alignItems="center">
                                Grade
                              </StyledTableHead>
                              <StyledTableHead alignItems="center">
                                Description
                              </StyledTableHead>
                              <StyledTableHead alignItems="center">
                                Grade Points
                              </StyledTableHead>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <StyledTableCell alignItems="center">
                                A+
                              </StyledTableCell>
                              <StyledTableCell alignItems="center">
                                Most indicators in a skill
                              </StyledTableCell>
                              <StyledTableCell alignItems="center">
                                4.1-5.0
                              </StyledTableCell>
                            </TableRow>
                            <TableRow>
                              <StyledTableCell alignItems="center">
                                A
                              </StyledTableCell>
                              <StyledTableCell alignItems="center">
                                Many indicators n a skill
                              </StyledTableCell>
                              <StyledTableCell alignItems="center">
                                3.1-4.0
                              </StyledTableCell>
                            </TableRow>
                            <TableRow>
                              <StyledTableCell alignItems="center">
                                B+
                              </StyledTableCell>
                              <StyledTableCell alignItems="center">
                                Some indicators in a skill
                              </StyledTableCell>
                              <StyledTableCell alignItems="center">
                                2.1-3.0
                              </StyledTableCell>
                            </TableRow>
                            <TableRow>
                              <StyledTableCell alignItems="center">
                                B
                              </StyledTableCell>
                              <StyledTableCell alignItems="center">
                                Few indicators in a skill
                              </StyledTableCell>
                              <StyledTableCell alignItems="center">
                                1.1-2.0
                              </StyledTableCell>
                            </TableRow>
                            <TableRow>
                              <StyledTableCell alignItems="center">
                                C
                              </StyledTableCell>
                              <StyledTableCell alignItems="center">
                                Very few indicators in a skill
                              </StyledTableCell>
                              <StyledTableCell alignItems="center">
                                0-1.0
                              </StyledTableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                    <Table sx={{ width: "100%", marginTop: "20px" }}>
                      <TableHead>
                        <TableRow>
                          <Title align="center" colSpan={13}>
                            Remarks
                          </Title>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(() => {
                          const rows = [];
                          for (let i = 0; i < 5; i++) {
                            rows.push(
                              <TableRow sx={{ height: "35px" }}>
                                <StyledTableCell key={i}></StyledTableCell>
                              </TableRow>
                            );
                          }
                          return rows;
                        })()}
                      </TableBody>
                    </Table>
                    <Typography
                      my={2}
                      sx={{
                        fontWeight: "bold",
                        color: "#000",
                        textAlign: "left",
                        fontSize: "20px",
                      }}
                    >
                      CONGRATULATION!!! PROMOTED TO NEXT CLASS{" "}
                      <span
                        style={{
                          borderBottom: "1px solid black",
                          borderWidth: "2px",
                        }}
                      >
                        {parseInt(
                          classes.filter((c) => c._id == formik.values.class)[0]
                            .className
                        ) + 1}
                      </span>{" "}
                      .Your next academic year starts from{" "}
                      <span
                        style={{
                          borderWidth: "2px",
                        }}
                      >
                        ________________
                      </span>{" "}
                    </Typography>
                    <Typography
                      my={2}
                      sx={{
                        fontWeight: "bold",
                        textAlign: "right",
                        color: "#000",

                        fontSize: "20px",
                      }}
                    >
                      Final Result{" "}
                      <span
                        style={{
                          backgroundColor: "white",
                          fontSize: "20px",

                          padding: "2px 2px",
                        }}
                      >
                        PASS
                      </span>
                    </Typography>{" "}
                    <ul>
                      {list.map((item, index) => (
                        <li
                          key={index}
                          style={{
                            paddingRight: "130px",
                            paddingLeft: "0px",
                            textAlign: "left",
                          }}
                        >
                          {item.title}
                        </li>
                      ))}
                    </ul>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "80px 10px 20px 10px",
                      }}
                    >
                      <Typography fontWeight="600">
                        Class Teacher Signature
                      </Typography>
                      <Typography fontWeight="600">
                        Principal Signature
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </>
      )}
    </>
  );
};

export default ProgressReport;
