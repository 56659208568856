import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "../../styles/components/navbar/Navbar.scss";
import Button from "@mui/material/Button";
import DrawerComponent from "../navbar/Navbar-drawer";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
const Navbar = () => {
  const [addClass, updateClass] = useState(false);
  const [stkyClass, setStkyClass] = useState(false);
  const [mega, setMega] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const handleClickCross = () => {
    updateClass(false);
    window.scrollTo(0, 0);
  };
  const scrollHandleScroll = () => {
    if (window.scrollY > 20) {
      setStkyClass(true);
    } else {
      setStkyClass(false);
    }
  };
  const handleMega = () => {
    setMega(!mega);
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollHandleScroll);
  }, []);

  const menuItems = [
    {
      title: "Home",
      link: "/",
      children: [],
    },
    {
      title: "About",
      children: [
        {
          title: "Our School",
          link: "/our-school",
        },
        {
          title: "Principal Desk",
          link: "/principal",
        },
        {
          title: "Faculty",
          link: "/facality",
        },
        {
          title: "Guardian Feedback",
          link: "/guardian-feedback",
        },
      ],
    },
    {
      title: "Admission",
      link: "/addmission",
      children: [],
    },
    {
      title: "Gallery",
      link: "/home-gallery",
      children: [],
    },
    {
      title: "Academics",
      children: [
        {
          title: "OVERVIEW",
          link: "/academics",
        },
        {
          title: "PRIMARY",
          link: "/academics/primary",
        },
        {
          title: "HIGH SCHOOL",
          link: "/academics/high-school",
        },
      ],
    },
    {
      title: "Facilities",
      children: [
        {
          title: "Library",
          link: "/facilities/library",
        },
        {
          title: "Sports",
          link: "/facilities/sports",
        },
        {
          title: "Canteen",
          link: "/facilities/canteen",
        },
        {
          title: "Transport",
          link: "/facilities/transport",
        },
        {
          title: "Edusmart",
          link: "/facilities/edusmart",
        },
      ],
    },
    {
      title: "Assignment",
      link: "/view-assignment",
      children: [],
    },
    {
      title: "Results",
      link: "/results",
      children: [],
    },
    {
      title: "Careers",
      link: "/home-careers",
      children: [],
    },
    {
      title: "Contact Us",
      link: "/contact-us",
      children: [],
    },
  ];

  return (
    <>
      <Grid
        style={{ marginBottom: "2rem" }}
        container
        spacing={2}
        className={"navbar__wrapper" + (stkyClass ? " mystky" : "")}
      >
        <Grid item xs={12}>
          <div className="navbar__inner_container">
            <div className="menu">
              <img src="Logo.webp" alt="" />
              {isMobile ? (
                <DrawerComponent menus={menuItems} />
              ) : (
                <ul className={addClass ? "vissible" : "hidden"}>
                  {menuItems.map((item, index) => {
                    return (
                      <li key={item.title}>
                        {item.link ? (
                          <NavLink to={`${item.link}`}>{item.title}</NavLink>
                        ) : (
                          <a>{item.title}</a>
                        )}
                        {item.children.length > 0 ? (
                          <ul
                            style={{ width: "200px" }}
                            className={"mega " + (mega ? "mega__visible" : "")}
                          >
                            {item.children.map((elements, index2) => {
                              return (
                                <li key={elements.title}>
                                  <Link to={`${elements.link}`}>
                                    {elements.title}
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        ) : (
                          ""
                        )}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
            <div className="login-btn">
              {/* <button href="/login">
                <span>
                  <i className="fa-solid fa-right-to-bracket" />
                </span>
                Login
              </button> */}
              <Button href="/login" variant="contained">
                {" "}
                <i className="fa-solid fa-right-to-bracket" /> Login
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
export default Navbar;
