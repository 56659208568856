import {
  Box,
  Button,
  Dialog,
  Modal,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import ClearIcon from "@mui/icons-material/Clear";
// custom imports

//Icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Download } from "@mui/icons-material";
import ModalDialog from "@mui/joy/ModalDialog";
import deleteIcon from "../../../../assets/Images/deleteIcon.png";

// apis
import { urls } from "../../../../services/urlConstant";

import { get, post, put, del } from "../../../../services/apis";

import UploadCourseButtons from "./UploadCourseButton";
import UploadCourseTable from "./UploadCourseTable";

const DownloadBox = styled(Box)(() => ({
  background: `rgb(133 140 223 / 19%)`,
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "10px",
  //   color: `${theme.palette.primary.main}`,
}));

export default function ShowUploadTable({
  chapter,
  course,
  courseName,
  updateList = () => {},
}) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [updatingChapter, setUpdatingChapter] = useState(false);
  const [openChapter, setOpenChapter] = useState(false);
  const [title, setTitle] = useState(chapter.title);
  const [material, setMaterial] = useState(null);
  const [description, setDescription] = useState(chapter.description);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState(
    chapter.material
      ? decodeURIComponent(chapter.material.split("/").pop()).replace("%", " ")
      : ""
  );

  const [materialURL, setMaterialURL] = useState();
  const [selectedChapter, setSelectedChapter] = useState("");
  console.log(course, "123course123");
  const handleChapterClose = () => {
    setOpenChapter(false);
    setFileName("");
    setTitle(chapter.title ? chapter.title : "");
  };
  useEffect(() => {
    if (chapter.material) {
      setFileName(
        decodeURIComponent(chapter.material.split("/").pop()).replace("%", " ")
      );
    }
  }, [chapter]);

  const openChapterDetails = () => {
    setFileName(
      chapter.material
        ? decodeURIComponent(chapter.material.split("/").pop()).replace(
            "%",
            " "
          )
        : ""
    );
    setTitle(chapter.title ? chapter.title : "");
    setOpenChapter(true);
  };

  const handleUpdateChapter = async () => {
    setUpdatingChapter(true);

    try {
      const body = {
        chapterId: chapter._id,
        title,
        material,
      };
      const formData = new FormData();
      formData.append("chapterId", chapter._id);
      formData.append("title", title);
      formData.append("material", material);
      if (selectedFile) {
        formData.append("file", selectedFile);
      }
      console.log(formData, "formData");
      const response = await put(
        `${urls.courseContent.updateChapter}/${course.courseId}`,
        "",
        formData
      );

      console.log(response.data, "response");
      handleChapterClose();
      updateList();
    } catch (error) {
      console.error(error);
    }

    setUpdatingChapter(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteChapter = async () => {
    try {
      const { data } = await put(
        urls.courseContent.deleteChapter + course.courseId,
        "",
        { chapterId: chapter._id }
      );
      updateList();
      handleClose();
    } catch (error) {
      console.log(error);
      handleClose();
    }
  };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setSelectedFile(selectedFile);
    setFileName(selectedFile.name);
  };
  const handleDownload = () => {
    try {
      window.open(chapter.material, "_blank");
    } catch (error) {
      console.error(error);
    }
  };
  const handleDeleteMaterial = async (e) => {
    e.preventDefault();
    if (!chapter.material) {
      setSelectedFile(null);
      return setFileName("");
    }
    try {
      const res = await put(
        `${urls.courseContent.deleteChapterMaterial}/${course.courseId}`,
        "",
        { chapterId: chapter._id }
      );
      setFileName("");
      setOpenChapter(false);
      updateList();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Box
        sx={{
          border: "1px solid lightgray",
          borderRadius: "5px",
          px: 1,
          mb: 3,
          backgroundColor: theme.palette.grey[100],
        }}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 2,
            }}>
            <Typography
              width="100%"
              variant="h6"
              component="div"
              fontWeight={600}
              fontSize={16}
              display="flex"
              alignItems="center">
              <Tooltip title="Delete Chapter">
                <IconButton
                  onClick={handleClickOpen}
                  size="small"
                  color="error">
                  <DeleteIcon fontSize="small" color="error" />
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Update Chapter"
                style={{
                  color: "#1b3779",
                }}>
                <IconButton onClick={openChapterDetails} size="small">
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              Chapter: {chapter.title}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6} mt={{ xs: 0, sm: 1, md: 2 }}>
            <UploadCourseButtons
              updateList={updateList}
              courseId={course.courseId}
              chapter={chapter}
              courseName={courseName}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <UploadCourseTable
              updateList={updateList}
              courseId={course.courseId}
              chapter={chapter}
              courseName={courseName}
            />
          </Grid>
        </Grid>
      </Box>

      <Dialog
        id={chapter._id}
        fullScreen={fullScreen}
        open={openChapter}
        onClose={handleChapterClose}
        aria-labelledby="responsive-dialog-title">
        <Grid container rowSpacing={2} p={2}>
          <Grid item xs={12} md={12}>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: 600,
              }}>
              Update Chapter Details: {chapter.title}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              columnGap: 2,
            }}>
            <TextField
              fullWidth
              size="small"
              name="title"
              value={title}
              placeholder="Enter Chapter Name"
              onChange={(e) => setTitle(e.target.value)}
            />
            {!chapter.material ? (
              <Button
                component="label"
                variant="outlined"
                fullWidth
                style={{
                  maxWidth: 200,
                  padding: "7px",
                }}
                startIcon={<FileUploadIcon fontSize="small" />}
                size="small">
                Upload Material
                <input
                  hidden
                  type="file"
                  onChange={handleFileChange}
                  style={{ display: "block" }}
                />
              </Button>
            ) : null}
          </Grid>

          {fileName ? (
            <DownloadBox p={1} mt={1.5}>
              <Typography>{fileName}</Typography>
              <Stack direction={"row"}>
                {chapter.material ? (
                  <Button size="small" onClick={handleDownload} color="error">
                    <Download fontSize="small" color="primary" />
                  </Button>
                ) : null}

                <Button
                  size="small"
                  onClick={handleDeleteMaterial}
                  color="error">
                  <ClearIcon fontSize="small" />
                </Button>
              </Stack>
            </DownloadBox>
          ) : (
            ""
          )}

          <Grid
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              justifyContent: "center",
              // my: 1,
            }}
            item
            xs={12}
            md={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 2,
                alignSelf: "center",
                flex: 1,
                width: "100%",
              }}>
              <Button
                variant="outlined"
                color="error"
                onClick={handleChapterClose}
                sx={{ width: "45%" }}>
                Cancel
              </Button>

              <LoadingButton
                type="submit"
                variant="contained"
                sx={{
                  width: "45%",
                  background: "rgb(27, 55, 121)",
                  ":hover": { background: "rgb(27, 55, 121)" },
                }}
                loading={updatingChapter}
                onClick={handleUpdateChapter}>
                Update
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
      {/* Delete Chapter Modal */}

      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={open}
        onClose={handleClose}>
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          sx={{
            background: "#fff",
            border: "none",
          }}>
          <Box sx={{ textAlign: "center", margin: "10px auto" }}>
            <img src={deleteIcon} width={50} height={50} />
          </Box>

          <Box p={2}>
            <Typography
              id="alert-dialog-modal-description"
              textAlign="center"
              my={1}>
              Are you sure you want to remove this?
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 2,
                mt: 2,
              }}>
              <Button
                variant="plain"
                color="neutral"
                size="small"
                onClick={handleClose}
                sx={{
                  background: "#ddd",
                  ":hover": { background: "#ddd", opacity: 0.8 },
                  objectFit: "contain",
                }}>
                No,Cancel
              </Button>

              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={handleDeleteChapter}>
                Yes, I'm Sure
              </Button>
            </Box>
          </Box>
        </ModalDialog>
      </Modal>
    </>
  );
}
