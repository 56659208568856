import React from "react";
import {
  Grid,
  Card,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Tooltip,
  Button,
  Autocomplete,
} from "@mui/material";
import { useState, useEffect } from "react";
import { post, get } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import { toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";

const styles = {
  card1: {
    padding: 15,
    marginBottom: 20,
    margin: "70px 200px",
  },
  card: {
    margin: 15,
    padding: 10,
    marginBottom: 20,
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
    m: 1,
  },
};

const ResetPasswordUser = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [roles, setRoles] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectEmployee, setSelectEmployee] = useState("");

  //useEffect.......

  useEffect(() => {
    const getRole = async () => {
      try {
        const res = await get(urls.role.getAllRole);
        setRoles(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getRole();
  }, []);

  const handleRoleChange = async (e) => {
    try {
      const { name, value } = e.target;
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
      const getEmployees = await get(urls.employee.getAllEmployee, {
        params: {
          search: {
            role: value,
          },
        },
      });
      if (getEmployees.data.success) setEmployees(getEmployees.data.data);
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleEmployeeAuto = (e, val) => {
    setSelectEmployee(val);
  };
  const handleFormChange = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleUserChangePassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await post(`/reset-user-password/${selectEmployee._id}`, {
        password: formData.password,
      });
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } catch (error) {
      toast.error(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };
  const handleSendPassword = async () => {
    try {
      await post(`/sendsms-employee-credentails`, {
        employees: [selectEmployee._id],
      });
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <div>
      <Card style={styles.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span
              style={{
                color: "red",
                fontFamily: "cursive",
              }}
            >
              Reset user password
            </span>
          </Grid>
        </Grid>
      </Card>
      <Card style={styles.card1}>
        <Box sx={{ flexGrow: 1 }}>
          <form onSubmit={handleUserChangePassword}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={12} lg={12}>
                <FormControl variant="standard" sx={styles.textfield} required>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    User Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="role"
                    onChange={handleRoleChange}
                    value={formData.role || ""}
                  >
                    {roles &&
                      roles.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.roleName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Autocomplete
                  fullWidth
                  onChange={handleEmployeeAuto}
                  options={employees}
                  value={selectEmployee}
                  getOptionLabel={(option) =>
                    option
                      ? `${option.basicInfo.empName} || ${option.username}`
                      : ""
                  }
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label="Employees"
                      variant="standard"
                      placeholder="Search employee"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  label=" Password"
                  variant="standard"
                  fullWidth
                  required
                  name="password"
                  value={formData.password || ""}
                  onChange={handleFormChange}
                ></TextField>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={6}
                container
                direction="row"
                justifyContent="right"
                alignItems="center"
              >
                <LoadingButton
                  variant="contained"
                  type="submit"
                  loading={loading}
                  sx={{
                    backgroundColor: "#1b3779",
                    ":hover": { backgroundColor: "#1b3779" },
                  }}
                >
                  Change password
                </LoadingButton>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={6}
                container
                direction="row"
                justifyContent="left"
                alignItems="left"
              >
                {selectEmployee && (
                  <Button
                    variant="contained"
                    onClick={handleSendPassword}
                    sx={{
                      backgroundColor: "#1b3779",
                      ":hover": { backgroundColor: "#1b3779" },
                    }}
                  >
                    Send exsiting password to user
                  </Button>
                )}
              </Grid>
            </Grid>
          </form>
        </Box>
      </Card>
    </div>
  );
};

export default ResetPasswordUser;
