import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Grid,
  Paper,
  Modal,
  TextField,
  Tab,
  Tabs,
  Box,
  Typography,
  Tooltip,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Stack,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Delete } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { del, get, post, put } from "../../services/apis";
import { urls } from "../../services/urlConstant";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 450,
  bgcolor: "background.paper",
  p: 4,
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const EmployeeIssue = () => {
  const [tabValue, setTabValue] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = useState();
  const [date, setDate] = useState(dayjs(Date.now()));

  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [dueSearchFilter, setDueSearchFilter] = useState([]);
  const [dueSearch, setDueSearch] = useState("");
  const [hisSearchFilter, setHisSearchFilter] = useState([]);
  const [hisSearch, setHisSearch] = useState("");

  const [fromDate, setFromDate] = useState(dayjs(Date.now()));
  const [toDate, setToDate] = useState(dayjs(Date.now()));
  const [edate, setEDate] = useState(dayjs(Date.now()));

  const [issue, setIssue] = useState([]);
  const [dueIssue, setDueIssue] = useState([]);
  const [showPagination, setShowPagination] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [issueTotalCount, setIssueTotalCount] = useState();
  const [dueTotalCount, setDueTotalCount] = useState();
  const [book, setBook] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [history, setHistory] = useState([]);
  const [addForm, setAddForm] = useState({});
  const [editIssue, setEditBook] = useState();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const styles = {
    icon: {
      padding: "10px",
      fontSize: "12px",
      fontWeight: 500,
      backgroundColor: "#1b3779",
      color: "white",
    },
    button: {
      backgroundColor: "orange",
    },
    textfield: {
      marginTop: "25px",
      width: "70%",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
    tab: {
      width: "100%",
      borderCollapse: "collapse",
    },
    card: {
      padding: 10,
      margin: "0px 0px 20px 0px",
    },
    // textfield: {
    //   width: "100%",
    //   label: { fontSize: 12 },
    //   m: 1,
    // },
    Button: {
      background: "#1b3779",
      ":hover": { background: "#1b3779" },
      marginTop: "10px",
    },
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      issue.length > 0 &&
        setSearchFilter(
          issue.filter((ele) =>
            ele.book.booktitle
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };
  const handleDueSearch = (e) => {
    const { value } = e.target;
    setDueSearch(value.trim());
    if (value.trim() !== "") {
      dueIssue.length > 0 &&
        setDueSearchFilter(
          dueIssue.filter((ele) =>
            ele.book.booktitle
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setDueSearchFilter([]);
    }
  };
  const handleHisSearch = (e) => {
    const { value } = e.target;
    setHisSearch(value.trim());
    if (value.trim() !== "") {
      history.length > 0 &&
        setHisSearchFilter(
          history.filter((ele) =>
            ele.book.booktitle
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setHisSearchFilter([]);
    }
  };

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getIssueList();
    getDueIssueList();
    getBookList();
    getLibraryEmployee();
  }, []);

  const getIssueList = async () => {
    console.log("issue");
    try {
      setShowPagination(true);
      const response = await get(urls.empIssueList.get, {
        params: {
          limit: 10,
          page: 1,
        },
      });
      const filterIssue = response.data.data.filter(
        (issues) => issues.submitted === "no"
      );
      setIssue(filterIssue);
      console.log(filterIssue, "isssssssssss");
      setIssueTotalCount(filterIssue.length);
    } catch (error) {
      console.log(error);
    }
  };
  const getDueIssueList = async () => {
    console.log("due");

    try {
      setShowPagination(true);
      const response = await get(urls.empIssueList.getdue, {
        params: {
          limit: 10,
          page: 1,
        },
      });
      const filteredDue = response.data.data.filter(
        (dues) => dues.submitted === "no"
      );

      setDueIssue(filteredDue);
      console.log(filteredDue, "dueeeeeeeeeeeee");
      setDueTotalCount(filteredDue.length);
      console.log("sdfghjk", filteredDue.length);
    } catch (error) {
      console.log(error);
    }
  };

  const getBookList = async () => {
    try {
      setShowPagination(true);
      const response = await get(urls.book.getList, {
        params: {
          limit: 10,
          page: 1,
        },
      });
      setBook(response.data.data);
      setTotalCount(response.data.count);
    } catch (error) {
      console.log(error);
    }
  };

  const getLibraryEmployee = async () => {
    try {
      const searchEmployee = await get(urls.employee.getEmployee, {
        params: {
          search: {
            libraryMember: "yes",
          },
        },
      });
      setEmployee(searchEmployee.data.data);
      console.log(searchEmployee.data.data, "searchEmployee.data.data");

      setTotalCount(searchEmployee.length);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitList = async (e, id) => {
    console.log("jhjhjhjhj");
    e.preventDefault();
    try {
      const { status } = await put(`${urls.empIssueList.submitBook}/`, id, {});

      getDueIssueList();
      getIssueList();
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    try {
      const { status } = await post(`${urls.empIssueList.create}`, {
        issueDate: date,
        book: addForm.book,
        isbnNo: addForm.isbnNo,
        edison: addForm.edison,
        author: addForm.author,
        language: addForm.language,
        price: addForm.price,
        quantity: addForm.quantity,
        almiraNo: addForm.almiraNo,
        returnDate: edate,
        issuedTo: addForm.member,
      });

      getIssueList();
      getDueIssueList();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchHistory = async (e) => {
    e.preventDefault();
    try {
      const searchOptions = {
        fromDate: fromDate,
        toDate: toDate,
      };

      const searchHistory = await get(urls.empIssueList.getHistory, {
        params: {
          fromDate: searchOptions.fromDate,
          toDate: searchOptions.toDate,
        },
      });

      setHistory(searchHistory.data.data);
      console.log(searchHistory.data.data, "searchHistory");
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetDownloadSheet = async (e) => {
    e.preventDefault();
    try {
      const response = await get(
        `/empissue/downloadexcel/${fromDate}/${toDate}`,
        {
          responseType: "blob",
        }
      );

      const downloadUrl = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute(
        "download",
        `library-history${fromDate}-${toDate}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetPrintPdf = async (e) => {
    e.preventDefault();

    try {
      const getPrint = await get(
        `/empissue/downloadpdf/${fromDate}/${toDate}`,
        {
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(getPrint.data);
      window.open(uri, "__blank");
    } catch (error) {
      console.log(error);
    }
  };
  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  return (
    <div style={{ margin: "15px" }}>
      <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span
              style={{
                color: "red",
                fontFamily: "cursive",
                textAlign: "start",
              }}
            >
              Employee Issue:
            </span>
          </Grid>
        </Grid>
      </Card>
      <Card style={{ margin: 15, padding: 10 }}>
        <Box>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Issue List" {...a11yProps(0)} />
            <Tab label="Due List" {...a11yProps(1)} />
            <Tab label="History List" {...a11yProps(2)} />
            <Button
              variant="contained"
              style={{ marginLeft: "50%" }}
              //   style={styles.addbutton}
              onClick={handleClickOpen}
            >
              {" "}
              <AddIcon />
              Add Issue
            </Button>
          </Tabs>
        </Box>
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleAddSubmit}>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
              marginBottom="30px"
              // onChange={handleAddForm}
            >
              Add Issue
            </Typography>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  variant="standar"
                  label="Issue Date"
                  // openTo="day"
                  // views={["day", "month", "year"]}
                  inputFormat="DD-MM-YYYY"
                  value={date}
                  onChange={(newValue) => {
                    setDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ m: 1, minWidth: 227 }}
                      variant="standard"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>

              <FormControl variant="standard" sx={{ m: 0.3, minWidth: 227 }}>
                <InputLabel sx={{ fontSize: 8 }}>Select Book</InputLabel>
                <Select
                  labelId="demo-simpless-select-filled-label"
                  id="demo-simple-select-filled-l"
                  name="book"
                  value={addForm.book || ""}
                  onChange={handleAddForm}
                >
                  {book.map((name) => (
                    <MenuItem key={name._id} value={name._id}>
                      {name.booktitle}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                id="filled-required"
                variant="standard"
                sx={{ m: 1, minWidth: 227 }}
                label="ISBN No"
                name="isbnNo"
                onChange={handleAddForm}
                value={addForm.isbnNo || ""}
              />
              <div>
                <TextField
                  id="filled-required"
                  variant="standard"
                  sx={{ m: 1, minWidth: 227 }}
                  label="edison"
                  name="edison"
                  onChange={handleAddForm}
                  value={addForm.edison || ""}
                />

                <TextField
                  id="filled-required"
                  variant="standard"
                  label="Author"
                  name="author"
                  value={addForm.author || ""}
                  onChange={handleAddForm}
                  sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                />

                <TextField
                  id="filled-required"
                  variant="standard"
                  label="Language"
                  name="language"
                  value={addForm.language || ""}
                  onChange={handleAddForm}
                  sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                />
              </div>

              <div>
                <TextField
                  id="filled-required"
                  variant="standard"
                  sx={{ m: 1, minWidth: 227 }}
                  label="Price"
                  name="price"
                  onChange={handleAddForm}
                  value={addForm.price || ""}
                />

                <TextField
                  required
                  id="filled-required"
                  variant="standard"
                  label="Quantity"
                  name="quantity"
                  value={addForm.quantity || ""}
                  onChange={handleAddForm}
                  sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                />

                <TextField
                  id="filled-required"
                  variant="standard"
                  label="Almira No"
                  name="almiraNo"
                  value={addForm.almiraNo || ""}
                  onChange={handleAddForm}
                  sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                />
              </div>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  variant="standar"
                  label="Return Date"
                  // openTo="day"
                  // views={["day", "month", "year"]}
                  inputFormat="DD-MM-YYYY"
                  value={edate}
                  onChange={(newValue) => {
                    setEDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ m: 1, minWidth: 227 }}
                      variant="standard"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>

              <FormControl variant="standard" sx={{ m: 0.3, minWidth: 227 }}>
                <InputLabel sx={{ fontSize: 8 }}>Select Member</InputLabel>
                <Select
                  labelId="demo-simpless-select-filled-label"
                  id="demo-simple-select-filled-l"
                  name="member"
                  value={addForm.member || ""}
                  onChange={handleAddForm}
                >
                  {employee.map((data) => (
                    <MenuItem value={data._id}>
                      {data.basicInfo.empName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="cancel-submit-btn">
              <div>
                <Tooltip title="Cancel">
                  <Button
                    onClick={handleClose}
                    variant="outlined"
                    size="medium"
                    type="button"
                    sx={{ m: 1 }}
                  >
                    Cancel
                  </Button>
                </Tooltip>
                <Tooltip title="Submit">
                  <Button
                    variant="contained"
                    size="medium"
                    type="submit"
                    sx={{
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                    }}
                  >
                    Add
                  </Button>
                </Tooltip>
              </div>
            </div>
          </Box>
        </form>
      </Modal>
      <TabPanel value={tabValue} index={0}>
        <div style={{ margin: "15px" }}>
          <Paper sx={{ width: "100%", height: "60px", overflow: "hidden" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid xs={3}>
                  <TextField
                    size="small"
                    style={styles.textfield}
                    value={search}
                    onChange={handleSearch}
                    label="Search"
                    variant="outlined"
                    sx={{ ml: 8 }}
                  />
                </Grid>
              </Grid>
            </div>
          </Paper>
          <Table>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell></TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Issue
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Due
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Total</TableCell>
                <TableCell align="center">{issueTotalCount}</TableCell>
                <TableCell align="center">{dueTotalCount}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Name
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Library ID
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Title
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Book ID
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Issue Date
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Due Date
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Return Date
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter.map((data, index) => (
                    <TableRow>
                      {" "}
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {" "}
                        {data.issuedTo.basicInfo.empName}
                      </TableCell>
                      <TableCell align="center">
                        {data.issuedTo.basicInfo.empId}
                      </TableCell>
                      <TableCell align="center">
                        {data.book ? book.booktitle : "NA"}
                      </TableCell>
                      <TableCell align="center">
                        {data.book ? book.booksId : "NA"}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(data.issueDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(data.returnDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">--</TableCell>
                      <TableCell align="center">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            style={{ color: "#1b3779" }}
                            onClick={(e) => handleSubmitList(e, data._id)}
                          >
                            <AddIcon />
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                : issue.map((data, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {" "}
                        {data.issuedTo.basicInfo.empName}
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        {data.issuedTo.basicInfo.empId}
                      </TableCell>
                      <TableCell align="center">
                        {data.book ? book.booktitle : "NA"}
                      </TableCell>
                      <TableCell align="center">
                        {data.book ? book.booksId : "NA"}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(data.issueDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(data.returnDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">--</TableCell>

                      <TableCell align="center">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            style={{ color: "#1b3779" }}
                            onClick={(e) => handleSubmitList(e, data._id)}
                          >
                            <AddIcon />
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
          {!issue.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </div>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <div style={{ margin: "15px" }}>
          <Paper sx={{ width: "100%", height: "60px", overflow: "hidden" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid xs={3}>
                  <TextField
                    size="small"
                    style={styles.textfield}
                    value={dueSearch}
                    onChange={handleDueSearch}
                    label="Search"
                    variant="outlined"
                    sx={{ ml: 8 }}
                  />
                </Grid>
              </Grid>
            </div>
          </Paper>
          <Table>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Name
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Library ID
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Title
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Book ID
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Issue Date
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Due Date
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Return Date
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dueSearch
                ? dueSearchFilter.map((data, index) => (
                    <TableRow>
                      {" "}
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {" "}
                        {data.issuedTo.basicInfo.empName}
                      </TableCell>
                      <TableCell align="center">
                        {data.issuedTo.basicInfo.empId}
                      </TableCell>
                      <TableCell align="center">
                        {data.book ? book.booktitle : "NA"}
                      </TableCell>
                      <TableCell align="center">
                        {data.book ? book.booksId : "NA"}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(data.issueDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(data.returnDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">--</TableCell>
                      <TableCell align="center">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            style={{ color: "#1b3779" }}
                            onClick={(e) => handleSubmitList(e, data._id)}
                          >
                            <AddIcon />
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                : dueIssue.map((data, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {" "}
                        {data.issuedTo.basicInfo.empName}
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        {data.issuedTo.basicInfo.empId}
                      </TableCell>
                      <TableCell align="center">
                        {data.book ? book.booktitle : "NA"}
                      </TableCell>
                      <TableCell align="center">
                        {data.book ? book.booksId : "NA"}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(data.issueDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(data.returnDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">--</TableCell>

                      <TableCell align="center">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            style={{ color: "#1b3779" }}
                            onClick={(e) => handleSubmitList(e, data._id)}
                          >
                            <AddIcon />
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
          {!dueIssue.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {dueSearch.trim() && !dueSearchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </div>
      </TabPanel>

      <TabPanel value={tabValue} index={2}>
        <div style={{ margin: "15px" }}>
          <Card style={styles.card}>
            <Box>
              <form onSubmit={handleSearchHistory}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={4} sx={{ alignSelf: "center" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={2}>
                        <DatePicker
                          variant="standard"
                          label="From Date"
                          // openTo="day"
                          // views={["day", "month", "year"]}
                          inputFormat="DD-MM-YYYY"
                          value={fromDate}
                          onChange={(newValue) => {
                            setFromDate(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField variant="standard" {...params} />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} sx={{ alignSelf: "center" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={2}>
                        <DatePicker
                          variant="standard"
                          label="To Date"
                          // openTo="day"
                          // views={["day", "month", "year"]}
                          inputFormat="DD-MM-YYYY"
                          value={toDate}
                          onChange={(newValue) => {
                            setToDate(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField variant="standard" {...params} />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={1}
                    sx={{
                      alignSelf: "center",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Tooltip title="Find">
                      <Button
                        variant="contained"
                        style={styles.Button}
                        size="small"
                        type="submit"
                      >
                        Find
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
              </form>

              <Stack spacing={2} direction="row">
                <LoadingButton
                  onClick={handleGetDownloadSheet}
                  loading={loadingBtn}
                >
                  <Button style={styles.icon}>Download</Button>
                </LoadingButton>
                <LoadingButton onClick={handleGetPrintPdf} loading={loadingBtn}>
                  <Button style={styles.icon}>Print</Button>
                </LoadingButton>
              </Stack>
            </Box>
          </Card>
        </div>

        <div style={{ margin: "15px" }}>
          <Paper sx={{ width: "100%", height: "60px", overflow: "hidden" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid xs={3}>
                  <TextField
                    size="small"
                    style={styles.textfield}
                    value={hisSearch}
                    onChange={handleHisSearch}
                    label="Search"
                    variant="outlined"
                    sx={{ ml: 8 }}
                  />
                </Grid>
              </Grid>
            </div>
          </Paper>
          <Table>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Name
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Library ID
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Title
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Book ID
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Issue Date
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Due Date
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Return Date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {hisSearch
                ? hisSearchFilter.map((data, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {" "}
                        {data.issuedTo.basicInfo.empName}
                      </TableCell>
                      <TableCell align="center">
                        {data.issuedTo.basicInfo.empId}
                      </TableCell>
                      <TableCell align="center">
                        {data.book ? book.booktitle : "NA"}
                      </TableCell>
                      <TableCell align="center">
                        {data.book ? book.booksId : "NA"}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(data.issueDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(data.returnDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        {data.submittedDate
                          ? dayjs(data.submittedDate).format("DD-MM-YYYY")
                          : "NA"}
                      </TableCell>
                    </TableRow>
                  ))
                : history.map((data, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {" "}
                        {data.issuedTo.basicInfo.empName}
                      </TableCell>
                      <TableCell align="center">
                        {data.issuedTo.basicInfo.empId}
                      </TableCell>
                      <TableCell align="center">
                        {data.book ? book.booktitle : "NA"}
                      </TableCell>
                      <TableCell align="center">
                        {data.book ? book.booksId : "NA"}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(data.issueDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(data.returnDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        {data.submittedDate
                          ? dayjs(data.submittedDate).format("DD-MM-YYYY")
                          : "NA"}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
          {!history.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}

          {hisSearch.trim() && !hisSearchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </div>
      </TabPanel>
    </div>
  );
};

export default EmployeeIssue;
