import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Container } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const labels = ["Eng", "Kan", "Hin", "Mat", "Sci", "SS"];

const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [21, 35, 54],
      backgroundColor: "rgb(255, 99, 132)",
    },
    {
      label: "Dataset 2",
      data: [24, 59, 91],
      backgroundColor: "rgb(75, 192, 192)",
    },
    {
      label: "Dataset 3",
      data: [43, 54, 61],
      backgroundColor: "rgb(53, 162, 235)",
    },
  ],
};

export default function SubjectWiseChart() {
  return (
    <Container padding={100}>
      {" "}
      <Bar data={data} />
    </Container>
  );
}
