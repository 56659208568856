import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DownloadIcon from "@mui/icons-material/Download";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import StudentRoutine from "../components/StudentRoutine/StudentRoutine";
import Holidays from "../components/Holidays/Holidays";
import StudentAttendence from "../components/StudentAttendence/StudentAttendence";
import NoContent from "../components/NoContent";
import css from "../styles/Dashboard/Dashboard.module.css";
import Notice from "../components/Notice/Notice";
import StudentNews from "../components/StudentNews";
import { urls } from "../services/urlConstant";
import { get } from "../services/apis";
import dayjs from "dayjs";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function StudentDashbord() {
  const styles = {
    dash: {
      fontSize: "18px",
      color: "forestgreen",
    },
  };
  const [assignment, setAssignment] = useState();
  const [invoice, setInvoice] = useState();
  const [downloaded, setDownloaded] = useState();

  useEffect(() => {
    const getAssignment = async () => {
      try {
        const response = await get(
          `/student-dashboard/all/assignments/active`,
          {
            params: {
              studentId: "63c8eab8f7cfb0957f470f8c",
            },
          }
        );
        setAssignment([...response.data.data]);
      } catch (error) {
        console.log(error);
      }
    };
    getAssignment();
  }, []);
  useEffect(() => {
    const getInvoice = async () => {
      try {
        const response = await get(`/student-dashboard/all/fee-receipts`, {
          params: {
            studentId: "6399d75729fa86b424ce3957",
          },
        });
        setInvoice([...response.data.data]);
      } catch (error) {
        console.log(error);
      }
    };
    getInvoice();
  }, []);

  const handleGetPrintPdf = async (id) => {
    try {
      {
        const getPrint = await get(
          `/student-dashboard/download/fee-receipt/${id}`,
          {
            responseType: "blob",
          }
        );

        const uri = URL.createObjectURL(getPrint.data);
        window.open(uri, "__blank");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <div>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <paper sx={{ height: 250 }}>
                <Item style={{ paddingBottom: "10px" }}>
                  <b style={styles.dash}>Notice</b>
                  <Divider color="primary" />
                  <Notice />
                </Item>
              </paper>
            </Grid>
            <Grid item xs={4}>
              <Item sx={{ pt: 3 }}>
                <b style={styles.dash}>Attendence</b>
                <Divider
                  color="primary"
                  sx={{
                    fontSize: 20,
                  }}
                />
                <StudentAttendence />
              </Item>
            </Grid>

            <Grid item xs={4}>
              <Item sx={{ pb: 5 }}>
                <b style={styles.dash}>News</b>
                <Divider color="primary" />
                <StudentNews />
                {/* <NoContent info="the hindu" /> */}
              </Item>

              {/* <Grid>
                <Item>
                  <b style={styles.dash}>Holidays</b>
                  <Divider color="primary" />
                  <Holidays />
                </Item>
              </Grid> */}
            </Grid>
          </Grid>
        </Box>
      </div>
      <div>
        <Box sx={{ flexGrow: 1, mr: 6, ml: 6, mb: 5 }}>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Item>
                <b style={styles.dash}>Assignment</b>
                <Divider color="primary" />
                {/* <div className={css.scrollable}>
                  {assignment.map((row) => (
                    <div>
                      <Card
                        sx={{ minWidth: 275, textAlign: "center", mt: 2 }}
                        className={css.card}
                      >
                        <CardContent>
                          <Typography
                            sx={{ fontSize: 15, textAlign: "start" }}
                            color="text.secondary"
                            gutterBottom
                          >
                            <b style={{ color: "#E1AD01" }}>
                              {row.subject.subjectName}
                            </b>
                          </Typography>
                          <Typography
                            sx={{ fontSize: 15, textAlign: "start" }}
                            color="text.secondary"
                            gutterBottom
                          >
                            <b style={{ color: "#E1AD01" }}>
                              {row.assignmentTitle}
                            </b>
                          </Typography>
                          <Typography
                            sx={{ fontSize: 15, textAlign: "start" }}
                            color="text.secondary"
                            gutterBottom
                          >
                            <b style={{ color: "#E1AD01" }}> Due Date:</b>
                            {dayjs(assignment.createdAt).format("DD-MM-YYYY")}
                          </Typography>
                        </CardContent>
                      </Card>
                    </div>
                  ))}
                </div> */}
                <div className={css.scrollable}>
                  {assignment && assignment.length > 0 ? (
                    assignment.map((row) => (
                      <div key={row.id}>
                        <Card
                          sx={{ minWidth: 275, textAlign: "center", mt: 2 }}
                          className={css.card}
                        >
                          <CardContent>
                            <Typography
                              sx={{ fontSize: 15, textAlign: "start" }}
                              color="text.secondary"
                              gutterBottom
                            >
                              <b style={{ color: "#E1AD01" }}>Subject:</b>
                              {row.subject.subjectName}
                            </Typography>

                            <Typography
                              sx={{ fontSize: 15, textAlign: "start" }}
                              color="text.secondary"
                              gutterBottom
                            >
                              <b style={{ color: "#E1AD01" }}>Assignment:</b>
                              {row.assignmentTitle}
                            </Typography>
                            <Typography
                              sx={{ fontSize: 15, textAlign: "start" }}
                              color="text.secondary"
                              gutterBottom
                            >
                              <b style={{ color: "#E1AD01" }}> Due Date:</b>
                              {dayjs(row.deadline).format("DD-MM-YYYY")}
                            </Typography>
                          </CardContent>
                        </Card>
                      </div>
                    ))
                  ) : (
                    <p>No assignments found.</p>
                  )}
                </div>
              </Item>
            </Grid>
            <Grid item xs={8}>
              <Item>
                <b style={styles.dash}>Routine</b>
                <Divider color="primary" />
                <StudentRoutine />
              </Item>
            </Grid>
          </Grid>
        </Box>
      </div>
      <div>
        <Box sx={{ flexGrow: 1, mr: 6, ml: 6, mb: 5 }}>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Item>
                <b style={styles.dash}>Invoices</b>
                <Divider color="primary" />
                <Card sx={{ minWidth: 275, textAlign: "center", mt: 2 }}>
                  <CardContent sx={{ display: "flex" }}>
                    {invoice && invoice.length > 0 ? (
                      invoice.map((row) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <Typography
                              sx={{ fontSize: 15, textAlign: "start" }}
                              color="text.secondary"
                            >
                              <b style={{ color: "#E1AD01" }}>
                                {" "}
                                {row.receiptType.name}:
                              </b>
                              {row.totalAmountPaid}
                            </Typography>
                            <Typography
                              sx={{ fontSize: 15, textAlign: "start" }}
                              color="text.secondary"
                            >
                              <b style={{ color: "#E1AD01" }}> Date:</b>
                              {dayjs(row.dueDate).format("DD-MM-YYYY")}
                            </Typography>
                          </div>
                          <div
                            style={{ margin: "auto" }}
                            onClick={() => handleGetPrintPdf(row._id)}
                          >
                            <DownloadIcon
                              sx={{ fontSize: 35, ml: 12, cursor: "pointer" }}
                              color="success"
                            />
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>no Invoices</p>
                    )}
                  </CardContent>
                </Card>
              </Item>
            </Grid>
            {/* <Grid item xs={4}>
              <Item>
                <b style={styles.dash}>Primary</b>
                <Divider color="primary" />
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item>
                <b style={styles.dash}>High School</b>
                <Divider color="primary" />
              </Item>
            </Grid> */}
          </Grid>
        </Box>
      </div>
    </div>
  );
}
