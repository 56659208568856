import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
  Modal,
  Fab,
} from "@mui/material";
import React, { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useState } from "react";
import { Delete } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { get, post, put, del } from "../../services/apis";
import { urls } from "../../services/urlConstant";
import DeleteModal from "../Layout/Academic/DeleteModal";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1300,
  height: 850,
  bgcolor: "background.paper",
  p: 4,
  overflowY: "Scroll",
};

const VehicleLog = () => {
  const [date, setDate] = useState(dayjs(Date.now()));
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState([]);
  const [vehicleNumber, setVehicleNumber] = React.useState([]);
  const [transportRouteTitle, setTransportRouteTitle] = React.useState([]);
  const [fromDate, setFromDate] = useState(Date.now());
  const [toDate, setToDate] = useState(Date.now());
  const [addForm, setAddForm] = useState({});
  const [list, setList] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [editVehicleLog, setEditVehicleLog] = useState();
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [dToSclTime, setDToSclTime] = useState("");
  const [aToSclTime, setAToSclTime] = useState("");
  const [dFromSclTime, setDFromSclTime] = useState("");
  const [aFromSclTime, setAFromSclTime] = useState("");
  // const [addForm, setEditLog] = useState({});

  useEffect(() => {
    getVehicleLog();
  }, []);

  const getVehicleLog = async () => {
    try {
      const { data, status } = await get(urls.vehicleLog.getList, {
        params: {
          transportRouteTitle: addForm.transportRouteTitle,
          vehicleNumber: addForm.vehicleNumber,
          fromDate: fromDate,
          toDate: toDate,
        },
      });
      setList(data.data);
      console.log("lklklklklklklklk", data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(`${urls.vehicleLog.delete}`, id);
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const styles = {
    cardStyle: {
      margin: "15px",
      padding: "10px",
      overflowY: "hidden",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
    cardStyles: {
      padding: "10px",
    },
    card: {
      padding: 10,
      margin: "0px 0px 20px 0px",
    },
    Button: {
      background: "#1b3779",
      ":hover": { background: "#1b3779" },
      marginTop: "10px",
      marginLeft: "5px",
    },
    textfield: {
      marginTop: "25px",
      width: "95%",
    },
  };

  useEffect(() => {
    const getVehicleNumber = async () => {
      try {
        const { data, status } = await get(`${urls.transport.getList}`, {});
        if (status > 199 && status < 299) {
          setVehicleNumber(data.data);
          console.log(data.data, "dattttttttttt");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getVehicleNumber();
  }, []);

  const handleClose = () => {
    setAddForm({});
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    const getRouteTitle = async () => {
      try {
        const { data, status } = await get(`${urls.route.getList}`, {});
        if (status > 199 && status < 299) {
          setTransportRouteTitle(data.data);
          console.log(data.data, "vvvv");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getRouteTitle();
  }, []);

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEditFormChange = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDToSclTime = (e) => {
    setDToSclTime(e.target.value);
  };
  const handleAToSclTime = (e) => {
    setAToSclTime(e.target.value);
  };

  const handleDFromSclTime = (e) => {
    setDFromSclTime(e.target.value);
  };

  const handleAFromSclTime = (e) => {
    setAFromSclTime(e.target.value);
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!editVehicleLog) {
        const { status } = await post(`${urls.vehicleLog.create}`, {
          transportRouteTitle: addForm.transportRouteTitle,
          vehicleNumber: addForm.vehicleNumber,
          date: date,
          DeptartureToSchoolTime: dToSclTime,
          DeptarturetoSchoolKM: addForm.DeptarturetoSchoolKM,
          ArrivaltoschoolTime: aToSclTime,
          ArrivalToSchoolKM: addForm.ArrivalToSchoolKM,
          ArrivaltoKMRun: addForm.ArrivaltoKMRun,
          Spareusereason: addForm.Spareusereason,
          SpareuseKM: addForm.SpareuseKM,
          SpareuseKMrun: addForm.SpareuseKMrun,
          DeptartureFromSchoolTime: dFromSclTime,
          DeptartureFromSchoolKM: addForm.DeptartureFromSchoolKM,
          ArrivalFromschoolTime: aFromSclTime,
          ArrivalFromSchoolKM: addForm.ArrivalFromSchoolKM,
          ArrivalFromKMRun: addForm.ArrivalFromKMRun,
          totalKMRun: addForm.totalKMRun,
          diselFilled: addForm.diselFilled,
        });
        getVehicleLog();
        handleClose();
      } else {
        const { status } = await put(
          `${urls.vehicleLog.update}/${editVehicleLog}`,
          "",
          {
            transportRouteTitle: addForm.transportRouteTitle,
            vehicleNumber: addForm.vehicleNumber,
            date: date,
            DeptartureToSchoolTime: dToSclTime,
            DeptarturetoSchoolKM: addForm.DeptarturetoSchoolKM,
            ArrivaltoschoolTime: aToSclTime,
            ArrivalToSchoolKM: addForm.ArrivalToSchoolKM,
            ArrivaltoKMRun: addForm.ArrivaltoKMRun,
            Spareusereason: addForm.Spareusereason,
            SpareuseKM: addForm.SpareuseKM,
            SpareuseKMrun: addForm.SpareuseKMrun,
            DeptartureFromSchoolTime: dFromSclTime,
            DeptartureFromSchoolKM: addForm.DeptartureFromSchoolKM,
            ArrivalFromschoolTime: aFromSclTime,
            ArrivalFromSchoolKM: addForm.ArrivalFromSchoolKM,
            ArrivalFromKMRun: addForm.ArrivalFromKMRun,
            totalKMRun: addForm.totalKMRun,
            diselFilled: addForm.diselFilled,
          }
        );
        getVehicleLog();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleVehicleLogEdit = (id, data) => {
    setEditVehicleLog(id);
    setAddForm({
      transportRouteTitle: data.transportRouteTitle,
      vehicleNumber: data.vehicleNumber,
      date: data.date,
      DeptartureToSchoolTime: data.dToSclTime,
      DeptarturetoSchoolKM: data.DeptarturetoSchoolKM,
      ArrivaltoschoolTime: data.aToSclTime,
      ArrivalToSchoolKM: data.ArrivalToSchoolKM,
      ArrivaltoKMRun: data.ArrivaltoKMRun,
      Spareusereason: data.Spareusereason,
      SpareuseKM: data.SpareuseKM,
      SpareuseKMrun: data.SpareuseKMrun,
      DeptartureFromSchoolTime: data.dFromSclTime,
      DeptartureFromSchoolKM: data.DeptartureFromSchoolKM,
      ArrivalFromschoolTime: data.aFromSclTime,
      ArrivalFromSchoolKM: data.ArrivalFromSchoolKM,
      ArrivalFromKMRun: data.ArrivalFromKMRun,
      totalKMRun: data.totalKMRun,
      diselFilled: data.diselFilled,
    });
    handleClickOpen();
  };
  const formStyle = {
    margin: "10px",
  };

  return (
    <div>
      <div style={styles.cardStyle}>
        <Card style={styles.cardStyles}>
          <Grid container spacing={2}>
            <Grid item xs={12} mg={12} lg={12}>
              <span
                style={{
                  color: "red",
                  fontFamily: "cursive",
                  textAlign: "start",
                }}
              >
                Vehicle Log:
              </span>
            </Grid>
          </Grid>
        </Card>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form onSubmit={handleAddSubmit}>
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign="center"
                marginBottom="30px"
                onChange={handleEditFormChange}
              >
                {editVehicleLog ? "update vehicle" : "Add Vehicle"}
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date"
                    variant="standard"
                    value={date}
                    onChange={(newDate) => setDate(newDate)}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          m: 1.2,
                          label: { fontSize: 12 },
                        }}
                        {...params}
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel>Route Title</InputLabel>
                  <Select
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    label="Route Title"
                    variant="outlined"
                    name="transportRouteTitle"
                    size="small"
                    onChange={handleEditFormChange}
                    value={addForm.transportRouteTitle || ""}
                  >
                    {transportRouteTitle.map((name) => (
                      <MenuItem key={name._id} value={name._id}>
                        {name.transportRouteTitle}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel>Vehicle Number</InputLabel>
                  <Select
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    label="Vehicle Number"
                    name="vehicleNumber"
                    size="small"
                    variant="outlined"
                    onChange={handleEditFormChange}
                    value={addForm.vehicleNumber || ""}
                  >
                    {vehicleNumber.map((name) => (
                      <MenuItem key={name._id} value={name._id}>
                        {name.vehicleNumber}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <FormControl fullWidth required>
                <TextField
                  required
                  id="filled-required"
                  variant="outlined"
                  label="Deptarture To School Time"
                  type="time"
                  value={dToSclTime}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleDToSclTime}
                  sx={{
                    m: 1,
                    minWidth: 227,
                    label: { fontSize: 12 },
                  }}
                />
              </FormControl>
              <FormControl fullWidth required>
                <TextField
                  id="outlined-basic"
                  label="Deptarture to School KM"
                  variant="outlined"
                  sx={{ m: 1, minWidth: 227 }}
                  onChange={handleEditFormChange}
                  value={addForm.DeptarturetoSchoolKM || ""}
                  name="DeptarturetoSchoolKM"
                />
              </FormControl>
              <FormControl fullWidth required>
                <TextField
                  required
                  id="filled-required"
                  variant="outlined"
                  label="Arrival To School Time"
                  type="time"
                  value={aToSclTime}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleAToSclTime}
                  sx={{
                    m: 1,
                    minWidth: 227,
                    label: { fontSize: 12 },
                  }}
                />
              </FormControl>

              <FormControl fullWidth required>
                <TextField
                  id="outlined-basic"
                  label="Arrival To School KM"
                  variant="outlined"
                  sx={{ m: 1, minWidth: 227 }}
                  onChange={handleEditFormChange}
                  name="ArrivalToSchoolKM"
                  value={addForm.ArrivalToSchoolKM || ""}
                />
              </FormControl>
              <FormControl fullWidth required>
                <TextField
                  id="outlined-basic"
                  label="Arrival KM Run"
                  variant="outlined"
                  sx={{ m: 1, minWidth: 227 }}
                  onChange={handleEditFormChange}
                  value={addForm.ArrivaltoKMRun || ""}
                  name="ArrivaltoKMRun"
                />
              </FormControl>

              <FormControl fullWidth required>
                <TextField
                  id="outlined-basic"
                  label="Spare Use Reason"
                  variant="outlined"
                  sx={{ m: 1, minWidth: 227 }}
                  onChange={handleEditFormChange}
                  value={addForm.Spareusereason || ""}
                  name="Spareusereason"
                />
              </FormControl>
              <FormControl fullWidth required>
                <TextField
                  id="outlined-basic"
                  label="Spare Use Km"
                  variant="outlined"
                  sx={{ m: 1, minWidth: 227 }}
                  onChange={handleEditFormChange}
                  value={addForm.SpareuseKM || ""}
                  name="SpareuseKM"
                />
              </FormControl>
              <FormControl fullWidth required>
                <TextField
                  id="outlined-basic"
                  label="Spare Use Km Run"
                  variant="outlined"
                  sx={{ m: 1, minWidth: 227 }}
                  onChange={handleEditFormChange}
                  value={addForm.SpareuseKMrun || ""}
                  name="SpareuseKMrun"
                />
              </FormControl>

              <FormControl fullWidth required>
                <TextField
                  required
                  id="filled-required"
                  variant="outlined"
                  label="Depature from School Time"
                  type="time"
                  value={dFromSclTime}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleDFromSclTime}
                  sx={{
                    m: 1,
                    minWidth: 227,
                    label: { fontSize: 12 },
                  }}
                />
              </FormControl>
              <FormControl fullWidth required>
                <TextField
                  id="outlined-basic"
                  label="Depature From School Km"
                  variant="outlined"
                  sx={{ m: 1, minWidth: 227 }}
                  onChange={handleEditFormChange}
                  value={addForm.DeptartureFromSchoolKM || ""}
                  name="DeptartureFromSchoolKM"
                />
              </FormControl>
              <FormControl fullWidth required>
                <TextField
                  required
                  id="filled-required"
                  variant="outlined"
                  label="Arrival From School Time"
                  type="time"
                  value={aFromSclTime}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleAFromSclTime}
                  sx={{
                    m: 1,
                    minWidth: 227,
                    label: { fontSize: 12 },
                  }}
                />
              </FormControl>
              <FormControl fullWidth required>
                <TextField
                  id="outlined-basic"
                  label="Arrivel From School Km"
                  variant="outlined"
                  sx={{ m: 1, minWidth: 227 }}
                  onChange={handleEditFormChange}
                  value={addForm.ArrivalFromSchoolKM || ""}
                  name="ArrivalFromSchoolKM"
                />
              </FormControl>
              <FormControl fullWidth required>
                <TextField
                  id="outlined-basic"
                  label="Arrivel From School Km Run"
                  variant="outlined"
                  sx={{ m: 1, minWidth: 227 }}
                  onChange={handleEditFormChange}
                  value={addForm.ArrivalFromKMRun || ""}
                  name="ArrivalFromKMRun"
                />
              </FormControl>
              <FormControl fullWidth required>
                <TextField
                  required
                  id="outlined-basic"
                  label="Total Km Run"
                  variant="outlined"
                  sx={{ m: 1, minWidth: 227 }}
                  onChange={handleEditFormChange}
                  value={addForm.totalKMRun || ""}
                  name="totalKMRun"
                />
              </FormControl>
              <FormControl fullWidth required>
                <TextField
                  id="outlined-basic"
                  label="Disel Filled"
                  variant="outlined"
                  sx={{ m: 1, minWidth: 227 }}
                  onChange={handleEditFormChange}
                  value={addForm.diselFilled || ""}
                  name="diselFilled"
                />
              </FormControl>

              <div style={{ textAlign: "end" }}>
                <Tooltip title="Cancel">
                  <Button
                    variant="outlined"
                    size="medium"
                    type="button"
                    sx={{ m: 1 }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Tooltip>
                <Tooltip title="Submit">
                  <Button
                    variant="contained"
                    size="medium"
                    type="submit"
                    sx={{
                      marginRight: "1px",
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                    }}
                  >
                    Update
                  </Button>
                </Tooltip>
              </div>
            </Box>
          </form>
        </Modal>
        <div style={{ marginTop: "10px" }}>
          <Card style={styles.card}>
            <Box>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} lg={3}>
                    <FormControl fullWidth variant="standard" required>
                      <InputLabel>Route Title</InputLabel>
                      <Select
                        labelId="demo-simpless-select-filled-label"
                        id="demo-simple-select-filled-l"
                        label="Route Title"
                        name="transportRouteTitle"
                        onChange={handleAddForm}
                        value={addForm.transportRouteTitle || ""}
                      >
                        {transportRouteTitle.map((name) => (
                          <MenuItem key={name._id} value={name._id}>
                            {name.transportRouteTitle}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <FormControl fullWidth variant="standard" required>
                      <InputLabel>Vehicle Number</InputLabel>
                      <Select
                        labelId="demo-simpless-select-filled-label"
                        id="demo-simple-select-filled-l"
                        label="vehicle number"
                        name="vehicleNumber"
                        onChange={handleAddForm}
                        value={addForm.vehicleNumber || ""}
                      >
                        {vehicleNumber.map((name) => (
                          <MenuItem key={name._id} value={name._id}>
                            {name.vehicleNumber}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3} sx={{ alignSelf: "center" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={2}>
                        <DatePicker
                          label="From Date"
                          inputFormat="DD-MM-YYYY"
                          value={fromDate || ""}
                          onChange={setFromDate}
                          renderInput={(params) => (
                            <TextField variant="standard" {...params} />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3} sx={{ alignSelf: "center" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={2}>
                        <DatePicker
                          label="To Date"
                          inputFormat="DD-MM-YYYY"
                          value={toDate || ""}
                          onChange={setToDate}
                          renderInput={(params) => (
                            <TextField variant="standard" {...params} />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </form>

              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  style={styles.Button}
                  onClick={getVehicleLog}
                >
                  Find
                </Button>
                <Button variant="contained" style={styles.Button}>
                  Print
                </Button>
              </Stack>
            </Box>
          </Card>
        </div>
        <Paper sx={{ height: "60px", overflow: "hidden" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid xs="auto">
                <TextField
                  size="small"
                  style={styles.textfield}
                  value={search}
                  // onChange={handleSearch}
                  placeholder="enter student name"
                  label="Search"
                  variant="outlined"
                  sx={{ mt: 1.2 }}
                />
              </Grid>
            </Grid>
          </div>
        </Paper>
        <div>
          <Table>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  Date
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Arrival KM Run
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Spare Use KM Run
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Arrivel From school KM Run
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Total KM Run
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((data, index) => (
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {dayjs(data.date).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell align="center">{data.ArrivaltoKMRun}</TableCell>
                  <TableCell align="center">
                    {data.SpareuseKMrun ? data.SpareuseKMrun : "0"}
                  </TableCell>
                  <TableCell align="center">
                    {data.ArrivalFromSchoolKM}
                  </TableCell>
                  <TableCell align="center">{data.totalKMRun}</TableCell>
                  <TableCell align="center">
                    <div className="edit-delete">
                      <Button
                        style={{ color: "#1b3779" }}
                        onClick={() => handleVehicleLogEdit(data._id, data)}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        style={{
                          color: "#1b3779",
                        }}
                        onClick={() => setDeleteModal(data._id, data)}
                      >
                        <Delete />
                      </Button>
                      <DeleteModal
                        deleteModal={deleteModal}
                        handleDelete={handleDeleteone}
                        id={data._id}
                        setDeleteModal={setDeleteModal}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {!list.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}

          <div className="add-icon">
            <Fab
              variant="contained"
              style={{ color: "#ffff", backgroundColor: "#1b3779" }}
              onClick={handleClickOpen}
            >
              <AddIcon />
            </Fab>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleLog;
