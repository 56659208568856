import { Grid } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import paraInfo from "../../Data/para";
import "../../styles/components/AboutSchool/AboutSchool.scss";

const AboutSchool = () => {
  return (
    <div className="container-about-school">
      <Grid container>
        <Grid item xs={12} s={8}>
          <h1 className="about-school-title">{paraInfo.glance.title}</h1>
          <p>{paraInfo.glance.description}</p>
          <div className="view-more-btn">
            <Link to={'/our-school'}><button>{paraInfo.glance.viewMore}</button></Link>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AboutSchool;
