import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Grid,
  Paper,
  Modal,
  TextField,
  Tab,
  Tabs,
  Box,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Delete } from "@mui/icons-material";
import { del, get, post, put } from "../../services/apis";
import { urls } from "../../services/urlConstant";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const EmployeeMember = () => {
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [nonSearchFilter, setNonSearchFilter] = useState([]);
  const [nonSearch, setNonSearch] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [employee, setEmployee] = useState([]);
  const [nonemployee, setNonEmployee] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = useState(0);
  const [showPagination, setShowPagination] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [open, setOpen] = React.useState(false);
  const [addForm, setAddForm] = useState({});
  const [editForm, setEditForm] = useState({});
  const styles = {
    button: {
      backgroundColor: "orange",
    },
    textfield: {
      marginTop: "25px",
      width: "70%",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
    tab: {
      width: "100%",
      borderCollapse: "collapse",
    },
  };

  useEffect(() => {
    getNonLibraryEmployee();
    getLibraryEmployee();
  }, []);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      employee.length > 0 &&
        setSearchFilter(
          employee.filter((ele) =>
            ele.basicInfo.empName
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  const handleNonSearch = (e) => {
    const { value } = e.target;
    setNonSearch(value.trim());
    if (value.trim() !== "") {
      nonemployee.length > 0 &&
        setNonSearchFilter(
          nonemployee.filter((ele) =>
            ele.basicInfo.empName
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setNonSearchFilter([]);
    }
  };
  const getNonLibraryEmployee = async () => {
    try {
      setShowPagination(true);
      const response = await get(urls.employee.getAllEmployee, {
        params: {
          limit: 10,
          page: 1,
        },
      });
      const filteredEmployee = response.data.data.filter(
        (employee) => employee.libraryMember === "no"
      );

      setNonEmployee(filteredEmployee);
      console.log(filteredEmployee, "xxxxxxxx");
      setTotalCount(response.data.count);
    } catch (error) {
      console.log(error);
    }
  };

  const getLibraryEmployee = async () => {
    try {
      setShowPagination(true);
      const response = await get(urls.employee.getAllEmployee, {
        params: {
          limit: 10,
          page: 1,
        },
      });
      const filteredEmployee = response.data.data.filter(
        (employee) => employee.libraryMember === "yes"
      );

      setEmployee(filteredEmployee);
      console.log(filteredEmployee, "yyyyyyy");
      setTotalCount(response.data.count);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddSubmit = async (e, id) => {
    console.log("jhjhjhjhj");
    e.preventDefault();
    try {
      const { status } = await put(
        `${urls.libraryEmpMember.editMember}/`,
        id,
        {}
      );

      getNonLibraryEmployee();
      getLibraryEmployee();
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteMember = async (e, id) => {
    e.preventDefault();
    try {
      const { status } = await put(
        `${urls.libraryEmpMember.delMember}/`,
        id,
        {}
      );
      getLibraryEmployee();
      getNonLibraryEmployee();
    } catch (error) {
      console.log(error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <div style={{ margin: "15px" }}>
      <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span
              style={{
                color: "red",
                fontFamily: "cursive",
                textAlign: "start",
              }}
            >
              Employee Library Member:
            </span>
          </Grid>
        </Grid>
      </Card>

      <Card style={{ margin: 15, padding: 10 }}>
        <Box>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Employee Member List" {...a11yProps(0)} />
            <Tab label="Employee Non Member List" {...a11yProps(1)} />
          </Tabs>
        </Box>
      </Card>
      <TabPanel value={tabValue} index={0}>
        <div style={{ margin: "15px" }}>
          <Paper sx={{ width: "100%", height: "60px", overflow: "hidden" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid xs={3}>
                  <TextField
                    size="small"
                    style={styles.textfield}
                    value={search}
                    onChange={handleSearch}
                    label="Search"
                    variant="outlined"
                    sx={{ ml: 8 }}
                  />
                </Grid>
              </Grid>
            </div>
          </Paper>
          <Table>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Name
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Phone
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Library Id
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter.map((data, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {data.basicInfo.empName}
                      </TableCell>
                      <TableCell align="center">{data.contactNumber}</TableCell>
                      <TableCell align="center">
                        {data.basicInfo.empId}
                      </TableCell>

                      <TableCell align="center">
                        <div>
                          <Button
                            style={{ color: "#1b3779" }}
                            onClick={(e) => handleDeleteMember(e, data._id)}
                          >
                            <Delete />
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                : employee.map((data, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {data.basicInfo.empName}
                      </TableCell>
                      <TableCell align="center">{data.contactNumber}</TableCell>
                      <TableCell align="center">
                        {data.basicInfo.empId}
                      </TableCell>

                      <TableCell align="center">
                        <div>
                          <Button
                            style={{ color: "#1b3779" }}
                            onClick={(e) => handleDeleteMember(e, data._id)}
                          >
                            <Delete />
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
          {!employee.length && !search.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {search.trim() && !searchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </div>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <div style={{ margin: "15px" }}>
          <Paper sx={{ width: "100%", height: "60px", overflow: "hidden" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid xs={3}>
                  <TextField
                    size="small"
                    style={styles.textfield}
                    value={nonSearch}
                    onChange={handleNonSearch}
                    label="Search"
                    variant="outlined"
                    sx={{ ml: 8 }}
                  />
                </Grid>
              </Grid>
            </div>
          </Paper>
          <Table>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Name
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Phone
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nonSearch
                ? nonSearchFilter.map((data, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {data.basicInfo.empName}
                      </TableCell>
                      <TableCell align="center">{data.contactNumber}</TableCell>

                      <TableCell align="center">
                        <div>
                          <Button
                            style={{ color: "#ffff" }}
                            variant="contained"
                            onClick={(e) => handleAddSubmit(e, data._id)}
                          >
                            <AddIcon />
                            ADD Member
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                : nonemployee.map((data, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {data.basicInfo.empName}
                      </TableCell>
                      <TableCell align="center">{data.contactNumber}</TableCell>

                      <TableCell align="center">
                        <div>
                          <Button
                            style={{ color: "#ffff" }}
                            variant="contained"
                            onClick={(e) => handleAddSubmit(e, data._id)}
                          >
                            <AddIcon />
                            ADD Member
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
          {!nonemployee.length && !nonSearch.trim() && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
          {nonSearch.trim() && !nonSearchFilter.length && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </div>
      </TabPanel>
    </div>
  );
};

export default EmployeeMember;
