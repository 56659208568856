// import React from "react";
// import {
//   Box,
//   Button,
//   Card,
//   Container,
//   FormControl,
//   Grid,
//   InputLabel,
//   MenuItem,
//   Paper,
//   Select,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Typography,
// } from "@mui/material";
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   LabelList,
//   Legend,
// } from "recharts";

// import { useEffect } from "react";
// import { urls } from "../../../services/urlConstant";
// import { post, get } from "../../../services/apis";
// import { useState } from "react";
// const styles = {
//   card: {
//     padding: 10,
//     width: "100%",
//     marginBottom: "10px",
//     marginTop: "10px",
//   },
//   notfoundStyle: {
//     minHeight: "74vh",
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//     fontSize: "calc(10px + 2vmin)",
//     color: "white",
//   },
//   textfield: {
//     width: "100%",
//     label: { fontSize: 12 },
//     m: 1,
//   },
//   Button: {
//     background: "#1b3779",
//     ":hover": { background: "#1b3779" },
//     marginTop: "10px",
//   },
// };

// const colors = [
//   "#FF5733",
//   "#33FF57",
//   "#5733FF",
//   "#ebee56",
//   "#961665",
//   "#56eee6",
// ];

// export default function DivisionwiseReport() {
//   const [graphData, setGraphData] = useState([]);
//   const [classes, setClasses] = useState([]);
//   const [sections, setSections] = useState([]);
//   const [examTerms, setExamterms] = useState([]);
//   const [selectedClassName, setSelectedClassName] = useState("");
//   const exams = graphData?.labels || [];
//   const subjects = graphData?.dataSets?.map((dataset) => dataset.label) || [];

//   const data = subjects.map((subject) => {
//     const entry = { subject };

//     exams.forEach((exam) => {
//       const dataset = graphData.dataSets.find((ds) => ds.label === subject);
//       entry[exam] = dataset ? dataset.data[exams.indexOf(exam)] || 0 : 0;
//     });

//     return entry;
//   });

//   const [divisionwiseReport, setDivisonwiseReport] = useState({});
//   const handleClasses = async (e) => {
//     try {
//       const { name, value } = e.target;
//       setDivisonwiseReport({ ...divisionwiseReport, [name]: value });
//       const res = await get(urls.schoolSection.getAllSection, {
//         params: {
//           search: {
//             class: value,
//           },
//         },
//       });
//       setSections(res.data.data);
//       setSelectedClassName(
//         classes.find((c) => c._id === value)?.className || ""
//       );
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   const handleSections = (e) => {
//     const { name, value } = e.target;
//     setDivisonwiseReport({ ...divisionwiseReport, [name]: value });
//   };

//   const handleExamTerms = (e) => {
//     const { name, value } = e.target;
//     setDivisonwiseReport({ ...divisionwiseReport, [name]: value });
//   };

//   useEffect(() => {
//     const getClasses = async () => {
//       try {
//         const res = await get(urls.class.getAllClass);
//         setClasses(res.data.data);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     getClasses();
//   }, []);

//   useEffect(() => {
//     const getExamTerms = async () => {
//       try {
//         const res = await get(urls.examTerm.getAllExamTerm);
//         setExamterms(res.data.data);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     getExamTerms();
//   }, []);

//   const divisionwiseReport = async (e) => {
//     e.preventDefault();
//     try {
//       const res = await post(`${urls.divisionwisegraph.create}`, {
//         classId: divisionwiseReport.className,

//         examTermIds: divisionwiseReport.examTitle,
//       });
//       setGraphData(res.data);
//       console.log(res);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const CustomLabel = ({ x, y, width, value }) => {
//     const centerX = x + width / 2;
//     if (value !== 0) {
//       return (
//         <text
//           x={centerX}
//           y={y}
//           dy={-5}
//           fontSize={18}
//           fontWeight={"bold"}
//           fill="#000"
//           textAnchor="middle">
//           {value}
//         </text>
//       );
//     }
//     return null;
//   };

//   return (
//     <Container padding={2}>
//       <Card style={styles.card}>
//         <Box sx={{ flexGrow: 1 }}>
//           <Grid container spacing={2}>
//             <Grid item sx={12} md={12} lg={12}>
//               <span
//                 style={{
//                   color: "red",
//                   fontFamily: "cursive",
//                 }}>
//                 Division Wise Report :
//               </span>
//             </Grid>
//           </Grid>
//         </Box>
//       </Card>
//       <form onSubmit={divisionwiseReport}>
//         <Card style={styles.card}>
//           <Box>
//             <Grid container spacing={2}>
//               <Grid item xs={12} md={6} lg={3}>
//                 <FormControl variant="standard" sx={styles.textfield} required>
//                   <InputLabel
//                     id="demo-simple-select-filled-label"
//                     sx={{ fontSize: 12 }}>
//                     Class
//                   </InputLabel>
//                   <Select
//                     labelId="demo-simple-select-filled-label"
//                     id="demo-simple-select-filled"
//                     name="className"
//                     value={divisionwiseReport.className || ""}
//                     onChange={(event) => handleClasses(event)}>
//                     {classes &&
//                       classes.map((row, index) => (
//                         <MenuItem
//                           key={row._id}
//                           value={row._id}
//                           sx={{ fontSize: 12, fontWeight: 500 }}>
//                           {row.className}
//                         </MenuItem>
//                       ))}
//                   </Select>
//                 </FormControl>
//               </Grid>
//               {/* <Grid item xs={12} md={6} lg={3}>
//                 <FormControl variant="standard" sx={styles.textfield} required>
//                   <InputLabel
//                     id="demo-simple-select-filled-label"
//                     sx={{ fontSize: 12 }}>
//                     Section
//                   </InputLabel>
//                   <Select
//                     labelId="demo-simple-select-filled-label"
//                     id="demo-simple-select-filled"
//                     name="sectionName"
//                     value={divisionwiseReport.sectionName || ""}
//                     onChange={(event) => handleSections(event)}>
//                     {sections &&
//                       sections.map((row, index) => (
//                         <MenuItem
//                           key={row._id}
//                           value={row._id}
//                           sx={{ fontSize: 12, fontWeight: 500 }}>
//                           {row.sectionName}
//                         </MenuItem>
//                       ))}
//                   </Select>
//                 </FormControl>
//               </Grid> */}
//               <Grid item xs={12} md={6} lg={3}>
//                 <FormControl variant="standard" sx={styles.textfield} required>
//                   <InputLabel
//                     id="demo-simple-select-filled-label"
//                     sx={{ fontSize: 12 }}>
//                     Exam
//                   </InputLabel>
//                   <Select
//                     labelId="demo-simple-select-filled-label"
//                     id="demo-simple-select-filled"
//                     name="examTitle"
//                     multiple
//                     value={divisionwiseReport.examTitle || []}
//                     onChange={(event) => handleExamTerms(event)}>
//                     {examTerms &&
//                       examTerms.map((row, index) => (
//                         <MenuItem
//                           key={row._id}
//                           value={row._id}
//                           sx={{ fontSize: 12, fontWeight: 500 }}>
//                           {row.examTitle}
//                         </MenuItem>
//                       ))}
//                   </Select>
//                 </FormControl>
//               </Grid>
//               <Grid item xs={12} md={6} lg={3} sx={{ mt: 3 }}>
//                 <Button variant="contained" size="small" type="submit">
//                   Find
//                 </Button>
//               </Grid>
//             </Grid>
//           </Box>
//         </Card>
//       </form>

//       <Box sx={{ border: "1px solid gray", mt: 1, mb: 1 }}>
//         <Typography
//           variant="h5"
//           sx={{ fontWeight: "bold", textAlign: "center", mb: 2, mt: 1 }}>
//           {selectedClassName} Division Wise Analysis Report
//         </Typography>
//         <div style={{ width: "100%", marginTop: "20px" }}>
//           <BarChart
//             width={1000}
//             height={500}
//             data={data}
//             barCategoryGap={8}
//             barGap={0}
//             margin={{ top: 20, right: 30, left: 40, bottom: 5 }}>
//             <CartesianGrid strokeDasharray="3 3" />
//             <XAxis
//               label={{
//                 value: "Division name",
//                 position: "insideBottom",
//                 offset: -5,
//               }}
//               dataKey="subject"
//             />
//             <YAxis
//               label={{
//                 value: "Axis Title",
//                 angle: -90,
//                 position: "insideLeft",
//               }}
//             />
//             <Tooltip />

//             {exams.map((exam, index) => (
//               <Bar key={index} dataKey={exam} fill={colors[index]}>
//                 <LabelList dataKey={exam} content={<CustomLabel />} />
//               </Bar>
//             ))}
//           </BarChart>
//         </div>
//         <TableContainer sx={{ mt: 2 }}>
//           <Table>
//             <TableHead sx={{ alignItems: "center" }}>
//               <TableRow>
//                 {/* <TableCell
//                   colSpan={graphData?.dataSets?.length + 1}
//                   align="center">
//                   <Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
//                     Division Name
//                   </Typography>
//                 </TableCell> */}
//                 <TableCell sx={{ border: "1px solid #ddd" }}></TableCell>
//                 {graphData?.dataSets?.map((subject, index) => (
//                   <TableCell
//                     sx={{ border: "1px solid #ddd" }}
//                     key={index}
//                     align="center">
//                     {subject.label}
//                   </TableCell>
//                 ))}
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {graphData?.labels?.map((exam, examIndex) => (
//                 <TableRow key={examIndex}>
//                   <TableCell sx={{ border: "1px solid #ddd" }} align="center">
//                     <div style={{ display: "flex", alignItems: "center" }}>
//                       <span
//                         style={{
//                           backgroundColor: colors[examIndex],
//                           width: "10px",
//                           height: "10px",
//                           marginRight: "4px",
//                           display: "inline-block",
//                         }}></span>
//                       {exam}
//                     </div>
//                   </TableCell>
//                   {graphData?.dataSets?.map((dataset, datasetIndex) => (
//                     <TableCell
//                       sx={{ border: "1px solid #ddd" }}
//                       align="center"
//                       key={datasetIndex}>
//                       <Typography> {dataset.data[examIndex]}</Typography>
//                     </TableCell>
//                   ))}
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </Box>
//     </Container>
//   );
// }

import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";

import { urls } from "../../../services/urlConstant";
import { post, get } from "../../../services/apis";

import ReactToPrint, { useReactToPrint } from "react-to-print";

const styles = {
  card: {
    padding: 10,
    width: "100%",
    marginBottom: "10px",
    marginTop: "10px",
  },

  textfield: {
    width: "100%",
    label: { fontSize: 12 },
    m: 1,
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
  },
};

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const DivisionWiseReportPrintable = React.forwardRef((props, ref) => {
  const { graphData, selectedClassName } = props;

  const exams = graphData?.labels || [];
  const subjects = graphData?.dataSets?.map((dataset) => dataset.label) || [];

  const data = subjects.map((subject) => {
    const entry = { subject };

    exams.forEach((exam) => {
      const dataset = graphData.dataSets.find((ds) => ds.label === subject);
      entry[exam] = dataset ? dataset.data[exams.indexOf(exam)] || 0 : 0;
    });

    return entry;
  });

  const colors = subjects.map(() => getRandomColor());

  const CustomLabel = ({ x, y, width, value }) => {
    const centerX = x + width / 2;
    if (value !== 0) {
      return (
        <text
          x={centerX}
          y={y}
          dy={-5}
          fontSize={18}
          fontWeight={"bold"}
          fill="#000"
          textAnchor="middle">
          {value}
        </text>
      );
    }
    return null;
  };
  return (
    <div ref={ref}>
      <Box sx={{ border: "1px solid gray", mt: 1, mb: 1 }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", textAlign: "center", mb: 2, mt: 1 }}>
          {selectedClassName} Division Wise Analysis Report
        </Typography>
        <div style={{ width: "100%", marginTop: "20px" }}>
          <BarChart
            width={1000}
            height={500}
            data={data}
            barCategoryGap={8}
            barGap={0}
            margin={{ top: 20, right: 30, left: 40, bottom: 15 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              label={{
                value: "Division name",
                position: "insideBottom",
                offset: -8,
              }}
              dataKey="subject"
            />
            <YAxis
              label={{
                value: "Percentage",
                angle: -90,
                position: "insideLeft",
              }}
            />
            <Tooltip />

            {exams.map((exam, index) => (
              <Bar key={index} dataKey={exam} fill={colors[index]}>
                <LabelList dataKey={exam} content={<CustomLabel />} />
              </Bar>
            ))}
          </BarChart>
        </div>
        <TableContainer sx={{ mt: 2 }}>
          <Table>
            <TableHead sx={{ alignItems: "center" }}>
              <TableRow>
                {/* <TableCell
                  colSpan={graphData?.dataSets?.length + 1}
                  align="center">
                  <Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                    Division Name
                  </Typography>
                </TableCell> */}
                <TableCell sx={{ border: "1px solid #ddd" }}></TableCell>
                {graphData?.dataSets?.map((subject, index) => (
                  <TableCell
                    sx={{ border: "1px solid #ddd" }}
                    key={index}
                    align="center">
                    {subject.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {graphData?.labels?.map((exam, examIndex) => (
                <TableRow key={examIndex}>
                  <TableCell sx={{ border: "1px solid #ddd" }} align="center">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={{
                          backgroundColor: colors[examIndex],
                          width: "10px",
                          height: "10px",
                          marginRight: "4px",
                          display: "inline-block",
                        }}></span>
                      {exam}
                    </div>
                  </TableCell>
                  {graphData?.dataSets?.map((dataset, datasetIndex) => (
                    <TableCell
                      sx={{ border: "1px solid #ddd" }}
                      align="center"
                      key={datasetIndex}>
                      <Typography> {dataset.data[examIndex]}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
});

export default function DivisionwiseReport() {
  const [graphData, setGraphData] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);

  const [examTerms, setExamterms] = useState([]);
  const [selectedClassName, setSelectedClassName] = useState("");
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [divisionwiseReport, setDivisonwiseReport] = useState({});
  const handleClasses = async (e) => {
    try {
      const { name, value } = e.target;
      setDivisonwiseReport({ ...divisionwiseReport, [name]: value });
      const res = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: value,
          },
        },
      });
      setSections(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleExamTerms = (e) => {
    const { name, value } = e.target;
    setDivisonwiseReport({ ...divisionwiseReport, [name]: value });
  };

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        setClasses(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);

  useEffect(() => {
    const getExamTerms = async () => {
      try {
        const res = await get(urls.examTerm.getAllExamTerm);
        setExamterms(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getExamTerms();
  }, []);

  const divisionwisereport = async (e) => {
    e.preventDefault();
    try {
      const res = await post(`${urls.divisionwisegraph.create}`, {
        classId: divisionwiseReport.className,
        sectionIds: divisionwiseReport.sectionIds,
        examTermIds: divisionwiseReport.examTitle,
      });
      setGraphData(res.data);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSections = (e) => {
    const { name, value } = e.target;
    setDivisonwiseReport({ ...divisionwiseReport, [name]: value });
  };

  return (
    <Container padding={2}>
      <Card style={styles.card}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item sx={12} md={12} lg={12}>
              <span
                style={{
                  color: "red",
                  fontFamily: "cursive",
                }}>
                Division Wise Report :
              </span>
            </Grid>
          </Grid>
        </Box>
      </Card>
      <form onSubmit={divisionwisereport}>
        <Card style={styles.card}>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl variant="standard" sx={styles.textfield} required>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}>
                    Class
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="className"
                    value={divisionwiseReport.className || ""}
                    onChange={(event) => handleClasses(event)}>
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}>
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl variant="standard" sx={styles.textfield} required>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}>
                    Section
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="sectionIds"
                    multiple
                    value={divisionwiseReport.sectionIds || []}
                    onChange={(event) => handleSections(event)}>
                    {sections &&
                      sections.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}>
                          {row.sectionName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <FormControl variant="standard" sx={styles.textfield} required>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}>
                    Exam
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="examTitle"
                    multiple
                    value={divisionwiseReport.examTitle || []}
                    onChange={(event) => handleExamTerms(event)}>
                    {examTerms &&
                      examTerms.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}>
                          {row.examTitle}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3} sx={{ mt: 3 }}>
                <Button
                  variant="contained"
                  size="small"
                  style={styles.Button}
                  type="submit">
                  Find
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </form>
      <DivisionWiseReportPrintable
        ref={componentRef}
        graphData={graphData}
        selectedClassName={selectedClassName}
      />
      <ReactToPrint
        trigger={() => (
          <Button
            variant="contained"
            size="small"
            style={{
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
              background: "#1b3779",
              ":hover": { background: "#1b3779" },
            }}
            onClick={handlePrint}>
            Print
          </Button>
        )}
        content={() => componentRef.current}
      />
    </Container>
  );
}
