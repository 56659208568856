import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { del, get, post, put } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const styles = {
  icon: {
    padding: "10px",
    fontSize: "12px",
    fontWeight: 500,
  },
  card: {
    padding: 15,
    marginBottom: 20,
    marginLeft: "1rem",
    marginRight: "1rem",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
};
const QuickAdmit = () => {
  const [academicYears, setAcademicYears] = useState([]);
  const [formData, setFormData] = useState([]);
  const [admissionDate, setAdmissionDate] = useState(dayjs());
  const [dob, setDob] = useState(dayjs());
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll);
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data[0]._id,
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);
  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        const resSections = await get(urls.schoolSection.getAllSection, {
          params: {
            search: {
              class: res.data.data[0]._id,
            },
          },
        });
        const searchStudents = await get(urls.students.getAllStudent, {
          params: {
            search: {
              academicYear: formData.academicYear,
              "academicInfo.class": res.data.data[0]._id,
              "academicInfo.section": resSections.data.data[0]._id,
            },
          },
        });
        setSections(resSections.data.data);
        setClasses(res.data.data);
        setFormData((prev) => ({
          ...prev,
          className: res.data.data[0]._id,
          section: resSections.data.data[0]._id,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);
  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      if (section.data.data.length > 0) {
        setFormData((prev) => ({
          ...prev,
          section: section.data.data[0]._id,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          section: "",
        }));
      }
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = {
        basicInfo: {
          name: formData.name,
          admissionDate: dayjs(admissionDate).toDate(),
          dob: dayjs(dob).toDate(),
          gender: formData.gender,
          caste: formData.caste,
        },
        academicInfo: {
          class: formData.className,
          section: formData.section,
          rollNo: formData.rollNo,
        },
        fatherInfo: {
          fatherName: formData.fatherName,
          fatherPhone: formData.fatherPhoneNo,
        },
        motherInfo: {
          motherName: formData.motherName,
          motherPhone: formData.motherPhoneNo,
        },
        academicYear: formData.academicYear,
        contactNumber: formData.contactNumber,
        // status: e.target.status.value,
      };
      const res = await post(urls.students.postStudent, data);
      console.log(res);
      if (res.data.success) {
        navigate("/admitstudent");
      } else {
        console.log("error ", res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span
              style={{
                color: "red",
                fontFamily: "cursive",
              }}
            >
              Quick Admit:
            </span>
          </Grid>
        </Grid>
      </Card>

      <form onSubmit={handleSubmit}>
        <Card style={styles.card}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <h6 style={{ color: "#1b3779" }}>
                  <span style={{ color: "red" }}> Note:</span> Student will be
                  admited to session
                </h6>
              </Grid>
              <Grid item sx={12} md={6} lg={3}>
                <FormControl
                  variant="standard"
                  style={styles.textfield}
                  required
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Academic Year
                  </InputLabel>
                  <Select
                    label="Academic Year"
                    id="demo-simple-select-filled"
                    name="academicYear"
                    variant="standard"
                    value={formData.academicYear || ""}
                    onChange={handleOnChange}
                    size="small"
                  >
                    {academicYears &&
                      academicYears.map((row, index) => (
                        <MenuItem key={row._id} value={row._id}>
                          {row.academicYearFrom} - {row.academicYearTo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Card>

        <Card style={styles.card}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <h5 style={{ color: "#1b3779" }}>Basic Information:</h5>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="filled-"
                  variant="standard"
                  label="Name"
                  name="name"
                  value={formData.name || ""}
                  onChange={handleAddForm}
                  required
                  style={styles.textfield}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="filled-"
                  variant="standard"
                  required
                  label="Admission No"
                  style={styles.textfield}
                  disabled
                />
                <FormHelperText>Auto generated</FormHelperText>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Admission Date"
                    value={admissionDate}
                    inputFormat="DD-MM-YYYY"
                    onChange={(newValue) => {
                      setAdmissionDate(newValue);
                    }}
                    required
                    renderInput={(params) => (
                      <TextField
                        style={styles.textfield}
                        variant="standard"
                        required
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date of Birth"
                    inputFormat="DD-MM-YYYY"
                    value={dob}
                    onChange={(newValue) => {
                      setDob(newValue);
                    }}
                    required
                    renderInput={(params) => (
                      <TextField
                        style={styles.textfield}
                        required
                        variant="standard"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl
                  variant="standard"
                  style={styles.textfield}
                  required
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Gender
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="gender"
                    value={formData.gender || ""}
                    onChange={handleAddForm}
                  >
                    <MenuItem
                      value={"male"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Male
                    </MenuItem>
                    <MenuItem
                      value={"female"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Female
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="filled-"
                  variant="standard"
                  label="Caste"
                  name="caste"
                  value={formData.caste}
                  onChange={handleAddForm}
                  sx={{ m: 0.5 }}
                  style={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="filled-"
                  variant="standard"
                  label="Contact Number"
                  name="number"
                  value={formData.contactNumber}
                  onChange={handleAddForm}
                  required
                  sx={{ m: 0.5 }}
                  style={styles.textfield}
                />
              </Grid>
            </Grid>
          </Box>
        </Card>
        <Card style={styles.card}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} lg={3}>
                <h5 style={{ color: "#1b3779" }}>Parent Information:</h5>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="filled-"
                  variant="standard"
                  label="Father Name"
                  name="fatherName"
                  style={styles.textfield}
                  value={formData.fatherName}
                  onChange={handleAddForm}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="filled-"
                  variant="standard"
                  label="Father Phone No"
                  name="fatherPhoneNo"
                  style={styles.textfield}
                  value={formData.fatherPhoneNo}
                  onChange={handleAddForm}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="filled-"
                  variant="standard"
                  label="Mother Name"
                  name="motherName"
                  style={styles.textfield}
                  value={formData.motherName}
                  onChange={handleAddForm}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="filled-"
                  variant="standard"
                  label="Mother Phone No"
                  name="motherPhoneNo"
                  style={styles.textfield}
                  value={formData.motherPhoneNo}
                  onChange={handleAddForm}
                />
              </Grid>
            </Grid>
          </Box>
        </Card>
        <Card style={styles.card}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} lg={3}>
                <h5 style={{ color: "#1b3779" }}>Academic Information:</h5>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel>Class</InputLabel>
                  <Select
                    label="Class"
                    id="demo-simple-select-filled"
                    name="className"
                    value={formData.className || ""}
                    onChange={handleClassChange}
                    fontSize="small"
                  >
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel sx={{ fontSize: 12, fontWeight: 400 }}>
                    Section
                  </InputLabel>
                  <Select
                    label="Section"
                    id="demo-simple-select-filled"
                    name="section"
                    value={formData.section || ""}
                    onChange={handleOnChange}
                    fontSize="small"
                  >
                    {formData.className && (
                      <MenuItem value="all">--all--</MenuItem>
                    )}
                    {sections.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.sectionName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="filled-"
                  variant="standard"
                  label="Roll No"
                  name="rollNo"
                  value={formData.rollno}
                  onChange={handleAddForm}
                  required
                  sx={{ m: 0.5 }}
                  style={styles.textfield}
                />
              </Grid>
            </Grid>
          </Box>
        </Card>

        <Card style={{ margin: "15px" }}>
          <div style={{ textAlign: "end" }}>
            <Tooltip title="Cancel">
              <Button
                variant="outlined"
                size="medium"
                type="button"
                sx={{ m: 1 }}
              >
                Cancel
              </Button>
            </Tooltip>
            <Tooltip title="Submit">
              <Button
                variant="contained"
                size="medium"
                type="submit"
                sx={{
                  marginRight: "1px",
                  background: "rgb(27, 55, 121)",
                  ":hover": { background: "rgb(27, 55, 121)" },
                }}
              >
                Submit
              </Button>
            </Tooltip>
          </div>
        </Card>
      </form>
    </div>
  );
};

export default QuickAdmit;
