import {
  Box,
  Button,
  Card,
  Fab,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Delete, Menu, Search } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 460,
  bgcolor: "background.paper",
  p: 4,
};
const VisitoryInfo = () => {
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [dialog, setOpenDialog] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const styles = {
    button: {
      backgroundColor: "orange",
    },
    card: {
      padding: 10,
      margin: "10px 0px",
    },
    cardButton: {
      display: "flex",
      justifyContent: "flex-end",
      textAlign: "center",
      gap: "10px",
    },

    textfield: {
      marginTop: "25px",
      width: "70%",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
    tab: {
      width: "100%",
      borderCollapse: "collapse",
    },
  };
  return (
    <div>
      <div style={{ margin: 15 }}>
        <Card style={styles.card}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <span
                style={{
                  color: "red",
                  fontSize: "large",
                  fontFamily: "cursive",
                }}
              >
                VisitorInfo:
              </span>
            </Grid>
          </Grid>
        </Card>

        <Card style={{ padding: "10px", margin: "10px 0px" }}>
          <div style={styles.cardButton}>
            <Button variant="contained">Excel</Button>
            <Button variant="contained">Pdf</Button>
          </div>
        </Card>
        <Paper
          sx={{
            width: "100%",
            height: "60px",
            overflow: "hidden",
            marginTop: "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: "22px",
            }}
          >
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid xs="auto">
                <form>
                  <TextField
                    size="small"
                    value={search}
                    // onChange={handleSearch}
                    label="Search"
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end" type="submit">
                            <Search />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </form>
              </Grid>
            </Grid>
          </div>
        </Paper>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form className="form-tag">
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign="center"
                marginBottom="30px"
              >
                Add Info
              </Typography>
              <div>
                <TextField
                  required
                  id="filled-required"
                  variant="standard"
                  label=" Name"
                  name="name"
                  sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                />
                <FormControl
                  variant="standard"
                  sx={{ m: 1, minWidth: 227 }}
                  required
                >
                  <TextField
                    required
                    id="filled-required"
                    variant="standard"
                    label="Phone "
                    name="contactNumber"
                    sx={{ minWidth: 227, label: { fontSize: 12 } }}
                  />
                </FormControl>
                <TextField
                  required
                  id="filled-required"
                  variant="standard"
                  label="Coming From"
                  name="comingFrom"
                  sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                />

                <div>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 227 }}>
                    <InputLabel sx={{ fontSize: 8 }}>
                      To Meet User Type
                    </InputLabel>
                    <Select
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      name="type"
                    >
                      <MenuItem value={"Admin"}>Admin</MenuItem>
                      <MenuItem value={"Teacher"}>Teacher</MenuItem>
                      <MenuItem value={"Student"}>Student</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 227 }}>
                    <InputLabel sx={{ fontSize: 8 }}>To Meet Use</InputLabel>
                    <Select
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      name="type"
                    >
                      <MenuItem value={"Admin"}>Admin</MenuItem>
                      <MenuItem value={"Teacher"}>Teacher</MenuItem>
                      <MenuItem value={"Student"}>Student</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 227 }}>
                    <InputLabel sx={{ fontSize: 8 }}>Reason to Meet</InputLabel>
                    <Select
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      name="type"
                    >
                      <MenuItem value={"yyhu"}>yyhu</MenuItem>
                      <MenuItem value={"nnn"}>nnnn</MenuItem>
                      <MenuItem value={"vhvbh"}>vhvbh</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div>
                  <TextareaAutosize
                    placeholder="Drop a note"
                    name="note"
                    style={{
                      width: 712,
                      maxHeight: 110,
                      margin: 10,
                      padding: 10,
                      height: 118,
                      overflow: "auto",
                    }}
                  />
                </div>
              </div>

              <div className="cancel-submit-btn">
                <div>
                  <Tooltip title="Cancel">
                    <Button
                      onClick={handleClose}
                      variant="outlined"
                      size="medium"
                      type="button"
                      sx={{ m: 1 }}
                    >
                      Cancel
                    </Button>
                  </Tooltip>
                  <Tooltip title="Submit">
                    <Button
                      variant="contained"
                      size="medium"
                      type="submit"
                      sx={{
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      Add
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </Box>
          </form>
        </Modal>

        <Table>
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow sx={{ color: "white" }}>
              <TableCell sx={{ color: "white" }} align="center">
                #SL
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Name
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Phone
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                To Meet
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Reason To Meet
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Check In
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Check Out
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">1</TableCell>
              <TableCell align="center">xyz</TableCell>
              <TableCell align="center">88888888899</TableCell>
              <TableCell align="center">hkbkh</TableCell>
              <TableCell align="center">bhjb</TableCell>
              <TableCell align="center">kkkkkk</TableCell>
              <TableCell align="center">hkbkh</TableCell>
              <TableCell align="center">
                <div className="edit-delete">
                  <Button style={{ color: "#1b3779" }}>
                    <EditIcon />
                  </Button>
                  <Button
                    style={{ color: "#1b3779" }}
                    onClick={() => handleClickOpenDialog()}
                  >
                    <VisibilityIcon />
                  </Button>
                  <Button
                    style={{
                      color: "#1b3779",
                    }}
                  >
                    <Delete />
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div className="add-icon">
          <Fab
            variant="contained"
            style={{ color: "#ffff", backgroundColor: "#1b3779" }}
            onClick={handleClickOpen}
          >
            <AddIcon />
          </Fab>
        </div>
        <BootstrapDialog
          onClose={handleCloseDialog}
          aria-labelledby="customized-dialog-title"
          open={dialog}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: "#1b3779", color: "white" }}
          >
            Visitor Information
          </BootstrapDialogTitle>

          <DialogContent dividers>
            <table className="hostel" style={styles.tab}>
              <tbody>
                <div>
                  <tr>
                    <td>School Name</td>
                    <td>Excellent English Medium Primary & High School</td>
                  </tr>
                  <tr>
                    <td> Name</td>
                    <td>name</td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td>8989899999</td>
                  </tr>
                  <tr>
                    <td>Coming From</td>
                    <td>address</td>
                  </tr>
                  <tr>
                    <td>User Type</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>To Meet</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Reason To Meet</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Check In</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Check Out</td>
                    <td></td>
                  </tr>

                  <tr>
                    <td>Note</td>
                    <td>ggggy</td>
                  </tr>
                </div>
              </tbody>
            </table>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </div>
  );
};

export default VisitoryInfo;
