import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Tooltip,
} from "@mui/material";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import Axios from "axios";
import { get } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 550,
  bgcolor: "background.paper",
  p: 4,
  overflowY: "scroll",
  borderRadius: "5px",
};

const AddSectionModal = (props) => {
  // console.log(props.updateClass && props.updateClass.className);
  const [sectionName, setSectionName] = useState("");
  const [classSection, setClassSection] = useState("");
  const [classes, setClasses] = useState([]);
  const [sectionTeacher, setSectionTeacher] = useState("");
  const [sectionNote, setSectionNote] = useState("");
  const [status, setStatus] = useState("");
  const [isPublish, setIsPublish] = useState("");
  const [employees, setEmployees] = useState([]);
  const [subjects, setSubjects] = useState([]);

  const [subjectTeachers, setSubjectTeachers] = useState([]);

  const getAllSubjects = async () => {
    try {
      const { data } = await get(urls.schoolSubject.getAllSubject, {
        params: { search: { class: classSection } },
      });
      let subjects = data.data;
      setSubjects(subjects);

      let previousSubjectTeachers = props.updateSection?.subjectTeachers || [];

      let subjectsAndTheirTeachers = subjects?.map((s) => ({
        subject: s._id,
        teacher:
          previousSubjectTeachers?.find((t) => t.subject?._id === s._id)
            ?.teacher?._id || "",
      }));
      setSubjectTeachers(subjectsAndTheirTeachers);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (classSection) {
      getAllSubjects();
    }
  }, [classSection, props.updateSection]);

  useEffect(() => {
    const getEmps = async () => {
      try {
        const getEmps = await get(urls.employee.getAllEmployee);
        setEmployees(getEmps.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getEmps();
  }, []);

  useEffect(() => {
    if (props.updateSection) {
      console.log(props.updateSection);
      setSectionName(props.updateSection.sectionName);
      setClassSection(props.updateSection.class._id);
      setSectionNote(props.updateSection.sectionNote);
      setSectionTeacher(
        props.updateSection.sectionTeacher
          ? props.updateSection.sectionTeacher._id
          : ""
      );
      setStatus(props.updateSection.status);
      setIsPublish(props.updateSection.isPublish);
    }
  }, [props.updateSection]);

  useEffect(() => {
    const sectionClass = async () => {
      try {
        const response = await get(urls.class.getAllClass);
        setClasses(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    sectionClass();
  }, []);

  const handleSectionName = (e) => {
    setSectionName(e.target.value);
  };
  const handleClassSection = (e) => {
    setClassSection(e.target.value);
  };
  const handleSectionNote = (e) => {
    setSectionNote(e.target.value);
  };
  const handleSectionTeacher = (e) => {
    setSectionTeacher(e.target.value);
  };
  const handleStatus = (e) => {
    setStatus(e.target.value);
  };
  const handleIsPublish = (e) => {
    setIsPublish(e.target.value);
  };

  const handleSubmit = (e) => {
    if (props.updateSection) {
      props.handleUpdate(
        {
          sectionName: sectionName,
          class: classSection,
          sectionNote,
          sectionTeacher,
          status,
          isPublish,
          subjectTeachers: subjectTeachers.filter(
            (s) => s.subject && s.teacher
          ),
        },
        props.updateSection._id
      );
    } else {
      props.handleSubmit(e, {
        sectionName: sectionName,
        class: classSection,
        sectionNote,
        sectionTeacher,
        status,
        isPublish,
        subjectTeachers: subjectTeachers.filter((s) => s.subject && s.teacher),
      });
    }
    setSectionName("");
    setClassSection("");
    setSectionNote("");
    setSectionTeacher("");
    setStatus("");
    setIsPublish("");
    props.handleClose();
  };

  const handleCloseModal = () => {
    setSectionName("");
    setSubjectTeachers([]);
    setClassSection("");
    setSectionNote("");
    setSectionTeacher("");
    setStatus("");
    setIsPublish("");
    props.handleClose();
  };

  const handleClose = () => {
    setSectionName("");
    setSubjectTeachers([]);

    setClassSection("");
    setSectionNote("");
    setSectionTeacher("");
    setStatus("");
    setIsPublish("");
    props.handleClose();
  };

  const handleSelectSubjectTeacher = (s, e) => {
    setSubjectTeachers(
      subjectTeachers.map((t) =>
        t.subject == s.subject ? { ...t, teacher: e.target.value } : t
      )
    );
  };

  return (
    <div>
      <Tooltip title="Add" disableInteractive>
        <Fab
          size="medium"
          style={{ background: "#1b3779", color: "#fff" }}
          aria-label="add"
          onClick={props.handleOpen}
        >
          <Add />
        </Fab>
      </Tooltip>
      <Modal
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleSubmit}>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
              marginBottom="30px"
            >
              Add Section
            </Typography>
            <div>
              <TextField
                required
                id="filled-required"
                variant="standard"
                label="Section Name"
                value={sectionName}
                onChange={handleSectionName}
                sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
              />
              <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 227 }}
                required
              >
                <InputLabel
                  id="demo-simple-select-filled-label"
                  sx={{ fontSize: 12 }}
                >
                  Class
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={classSection}
                  onChange={handleClassSection}
                >
                  {classes &&
                    classes.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.className}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 227 }}>
                <InputLabel sx={{ fontSize: 12 }}>Section Teacher</InputLabel>
                <Select
                  labelId="demo-simpless-select-filled-label"
                  id="demo-simple-select-filled-l"
                  value={sectionTeacher}
                  onChange={handleSectionTeacher}
                >
                  {employees &&
                    employees.map((emp) => (
                      <MenuItem
                        key={emp._id}
                        value={emp._id}
                        style={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {emp.basicInfo.empName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <div>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 227 }}>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={status}
                    onChange={handleStatus}
                  >
                    <MenuItem
                      value={"inactive"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      inactive
                    </MenuItem>
                    <MenuItem
                      value={"active"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      active
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 227 }}>
                  <InputLabel
                    id="demo-simple-select-required-label"
                    sx={{ fontSize: 12 }}
                  >
                    Is Publish? *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-required-label"
                    id="demo-simple-select-required"
                    value={isPublish}
                    onChange={handleIsPublish}
                  >
                    <MenuItem
                      value={"inactive"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      inactive
                    </MenuItem>
                    <MenuItem
                      value={"active"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      active
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>

              <Box
                sx={{
                  padding: "10px",
                  border: "1px solid lightgray",
                  borderRadius: "5px",
                }}
              >
                <Typography>Select subject teachers</Typography>
                <Box>
                  {subjectTeachers.map((s) => (
                    <Box
                      key={s.subject}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                        {
                          subjects.find((sub) => sub._id === s.subject)
                            ?.subjectName
                        }
                      </Typography>
                      <FormControl
                        variant="standard"
                        sx={{ m: 1, minWidth: 227 }}
                      >
                        <InputLabel sx={{ fontSize: 12 }}>Teacher</InputLabel>
                        <Select
                          labelId="demo-simpless-select-filled-label-subject-teacher-select"
                          id="demo-simple-select-filled-l"
                          value={s.teacher || ""}
                          onChange={(e) => handleSelectSubjectTeacher(s, e)}
                        >
                          {employees &&
                            employees.map((emp) => (
                              <MenuItem
                                key={emp._id}
                                value={emp._id}
                                style={{ fontSize: 12, fontWeight: 500 }}
                              >
                                {emp.basicInfo?.empName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                  ))}
                </Box>
              </Box>

              <div>
                <TextareaAutosize
                  placeholder="Drop a note"
                  onChange={handleSectionNote}
                  style={{
                    width: 712,
                    maxHeight: 110,
                    margin: 8,
                    padding: 10,
                    height: 118,
                    overflow: "auto",
                  }}
                />
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div>
                <Tooltip title="Cancel">
                  <Button
                    onClick={handleClose}
                    variant="outlined"
                    size="medium"
                    type="button"
                    sx={{ m: 1 }}
                  >
                    Cancel
                  </Button>
                </Tooltip>
                <Tooltip title="Submit">
                  <Button
                    variant="contained"
                    size="medium"
                    type="submit"
                    sx={{
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                    }}
                  >
                    Submit
                  </Button>
                </Tooltip>
              </div>
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
};

export default AddSectionModal;
