import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React from "react";
import { useEffect, useState } from "react";
import { get } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import Autocomplete from "@mui/material/Autocomplete";
import Dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";

const styles = {
  card: {
    padding: 10,
    width: "100%",
    marginBottom: "10px",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
    m: 1,
  },
};

const StudyCertificate = () => {
  const [studyCertificateDetails, setStudyCertificateDetails] = useState({});
  const [academicYears, setAcademicYears] = useState([]);
  const [studentsSC, setStudentsSC] = useState([]);
  const [fromDate, setFromDate] = useState(Dayjs(Date.now()));
  const [toDate, setToDate] = useState(Dayjs(Date.now()));
  const [studentSelect, setStudentSelect] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll);
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);
  const handleAcademicYears = async (e) => {
    try {
      const { name, value } = e.target;
      setStudyCertificateDetails({ ...studyCertificateDetails, [name]: value });
      const res = await get(urls.students.getAllStudent, {
        params: {
          search: {
            academicYear: value,
          },
        },
      });
      setStudentsSC(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleStudentSelect = (val, newVal) => {
    setStudentSelect(newVal);
  };
  const handleSubmitCertificate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const studyCertificateRes = await get(
        `/study-certificate/${fromDate.format("YYYY")}/${toDate.format(
          "YYYY"
        )}/${studentSelect._id}`,
        {
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(studyCertificateRes.data);
      window.open(uri, "__blank");
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <div style={{ margin: 15 }}>
        <form onSubmit={handleSubmitCertificate}>
          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item sx={12} md={6} lg={2}>
                  <FormControl variant="standard" style={styles.textfield}>
                    <InputLabel>Academic Year</InputLabel>
                    <Select
                      name="academicYear"
                      value={studyCertificateDetails.academicYear}
                      onChange={(event) => handleAcademicYears(event)}
                    >
                      {academicYears &&
                        academicYears.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.academicYearFrom} - {row.academicYearTo}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="From Date"
                      name="fromDate"
                      value={fromDate}
                      inputFormat="DD-MM-YYYY"
                      onChange={(newValue) => {
                        setFromDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          sx={{ width: "100%" }}
                          variant="standard"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="To Date"
                      value={toDate}
                      inputFormat="DD-MM-YYYY"
                      onChange={(newValue) => {
                        setToDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          sx={{ width: "100%" }}
                          variant="standard"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Autocomplete
                    disablePortal
                    getOptionLabel={(option) => option.basicInfo.name}
                    id="combo-box-demo"
                    options={studentsSC}
                    onChange={handleStudentSelect}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        label="Students"
                      />
                    )}
                  />
                </Grid>
                <Grid item sx={{ alignSelf: "flex-end" }}>
                  <Tooltip title="Issue">
                    <LoadingButton
                      style={{ background: "#1b3779" }}
                      variant="contained"
                      size="small"
                      type="submit"
                      loading={loading}
                    >
                      <span>Issue</span>
                    </LoadingButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </form>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              style={{
                height: "740px",
                width: "800px",
                border: "1px solid black",
              }}
              src="/studyCertificate.png"
              alt=""
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default StudyCertificate;
