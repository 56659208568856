import { Search } from "@mui/icons-material";
import RemoveIcon from "@mui/icons-material/Remove";
import { del, get, post, put } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";

import {
  Box,
  Button,
  Card,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import DeleteModal from "../../Layout/Academic/DeleteModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  p: 4,
};

const StudentActivity = () => {
  const [formData, setFormData] = useState({});
  const [academicYears, setAcademicYears] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [student, setStudent] = useState([]);
  const [open, setOpen] = useState("");
  const [date, setDate] = useState(Date.now());
  const [list, setList] = React.useState([]);
  const [addForm, setAddForm] = useState({});
  const [data, setData] = useState([{ name: "", date: "" }]);
  const [editActivity, setEditActivity] = useState();
   const [deleteModal, setDeleteModal] = React.useState(false);
   const [search, setSearch] = useState("");
   const [searchFilter, setSearchFilter] = useState([]);

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll);
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data[0]._id,
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);

  const handleClassChange = async (e) => {
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      if (section.data.data.length > 0) {
        setFormData((prev) => ({
          ...prev,
          section: section.data.data[0]._id,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          section: "",
        }));
      }
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    const getsearchStudents = async () => {
      try {
        if (!formData.className || !formData.section) return;
        const response = await get(urls.students.getAllStudent, {
          params: {
            search: {
              academicYear: formData.academicYear,
              "academicInfo.class": formData.className,
              "academicInfo.section": formData.section,
            },
          },
        });
        setStudent(response.data.data);
      } catch (error) {}
    };
    getsearchStudents();
  }, [formData.className, formData.section]);

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        const resSections = await get(urls.schoolSection.getAllSection, {
          params: {
            search: {
              class: res.data.data[0]._id,
            },
          },
        });
        setClasses(res.data.data);
        setFormData((prev) => ({
          ...prev,
          className: res.data.data[0]._id,
          section: resSections.data.data[0]._id,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);

  const styles = {
    icon: {
      padding: "10px",
      fontSize: "12px",
      fontWeight: 500,
    },
    card: {
      padding: 15,
      marginBottom: 20,
    },
    textfield: {
      width: "100%",
      label: { fontSize: 12 },
    },
    Button: {
      background: "#1b3779",
      color: "#fff",
      ":hover": { background: "#1b3779" },
    },
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm({
      ...addForm,
      [name]: value,
    });
  };
  console.log(addForm, "gfff");

  const handleClickOpen = () => {
    if (!formData.student) return alert("Please select student..");
    setOpen(true);
  };

  const handleClose = () => {
    setEditActivity(null);
    setAddForm({});
    setOpen(false);
  };

  const getActivity = async () => {
    try {
      const { data } = await get(
        `${urls.studentActivity.getList}/${formData.student}/${formData.academicYear}`,
        {
          params: {
           
              student: formData.student,
              academicYear: formData.academicYear,
            },
    
        }
      );
      setList(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!editActivity) {
        const { status } = await post(`${urls.studentActivity.create}`, {
          date: date,
          name: addForm.name,
          description: addForm.description,
          student: formData.student,
          academicYear: formData.academicYear,
        });
        getActivity();
        handleClose();
      } else {
        const { status } = await put(
          `${urls.studentActivity.update}${editActivity}`,
          "",
          {
            date: date,
            name: addForm.name,
            description: addForm.description,
            student: formData.student,
            academicYear: formData.academicYear,
          }
        );
        getActivity();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(`${urls.studentActivity.delete}`, id);
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };


  const handleActivityEdit = (id, data) => {
    setEditActivity(id);
    setAddForm({
     
      name: data.name,
      description: data.description,
      student: data.student,
      academicYear: data.academicYear,
    });
    setDate(data.date)
    handleClickOpen();
  };

    const handleSearch = (e) => {
      const { value } = e.target;
      setSearch(value.trim());
      if (value.trim() !== "") {
        list.length > 0 &&
          setSearchFilter(
            list.filter((ele) =>
              ele.name.toLowerCase().includes(value.toLowerCase().trim())
            )
          );
      } else {
        setSearchFilter([]);
      }
    };


  return (
    <div>
      <Card sx={{ margin: "15px", padding: "10px" }}>
        <span
          style={{
            color: "red",
            fontSize: "large",
            fontFamily: "cursive",
          }}>
          Student Activity :
        </span>
      </Card>

      <Card style={{ margin: 15, padding: 10 }}>
        <Box sx={{ flexGrow: 1 }}>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl fullWidth required>
                  <InputLabel>Academic Year</InputLabel>
                  <Select
                    label="Academic Year"
                    id="demo-simple-select-filled"
                    name="academicYear"
                    variant="standard"
                    value={formData.academicYear || ""}
                    onChange={handleOnChange}
                    size="small">
                    {academicYears &&
                      academicYears.map((row, index) => (
                        <MenuItem key={row._id} value={row._id}>
                          {row.academicYearFrom} - {row.academicYearTo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl fullWidth required>
                  <InputLabel>Class</InputLabel>
                  <Select
                    label="Class"
                    variant="standard"
                    id="demo-simple-select-filled"
                    name="className"
                    value={formData.className || ""}
                    onChange={handleClassChange}
                    size="small">
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem key={row._id} value={row._id}>
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel>Section</InputLabel>
                  <Select
                    label="Section"
                    variant="standard"
                    id="demo-simple-select-filled"
                    name="section"
                    value={formData.section || ""}
                    onChange={handleOnChange}
                    size="small">
                    {sections.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}>
                        {row.sectionName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl fullWidth required>
                  <InputLabel>Select Student</InputLabel>
                  <Select
                    label="Select student"
                    id="demo-simple-select-filled"
                    name="student"
                    variant="standard"
                    value={formData.student || ""}
                    onChange={handleOnChange}
                    size="small">
                    {student.map((row, index) => (
                      <MenuItem key={row._id} value={row._id}>
                        {row.basicInfo.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Button
                  size="small"
                  onClick={getActivity}
                  variant="contained"
                  style={styles.Button}>
                  Find
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Card>

      <Card sx={{ margin: "15px", padding: "10px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: "22px",
          }}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid xs="auto">
              <form>
                <TextField
                  size="small"
                  value={search}
                  onChange={handleSearch}
                  label="Search"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" type="submit">
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </Grid>
          </Grid>
        </div>
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <form onSubmit={handleAddSubmit} className="form-tag">
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
              marginBottom="30px">
              Add Student Activity
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={2}>
                      <DatePicker
                        label="Select date"
                        inputFormat="DD-MM-YYYY"
                        value={date}
                        onChange={setDate}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            variant="standard"
                            {...params}
                          />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <FormControl required fullWidth>
                  <TextField
                    variant="standard"
                    label="Activity Name"
                    value={addForm.name || ""}
                    onChange={handleAddForm}
                    name="name"
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextareaAutosize
                  placeholder="Drop a note"
                  name="description"
                  onChange={handleAddForm}
                  value={addForm.description || ""}
                  style={{
                    maxHeight: 110,
                    marginTop: 20,
                    width: 712,
                    padding: 10,
                    height: 118,
                    overflow: "auto",
                  }}
                />
              </Grid>
            </Grid>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}>
              <Tooltip title="Cancel">
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  size="medium"
                  type="button"
                  sx={{ m: 1 }}>
                  Cancel
                </Button>
              </Tooltip>
              <Tooltip title="Submit">
                <Button
                  variant="contained"
                  size="medium"
                  type="submit"
                  sx={{
                    background: "rgb(27, 55, 121)",
                    ":hover": { background: "rgb(27, 55, 121)" },
                  }}>
                  Add
                </Button>
              </Tooltip>
            </div>
          </Box>
        </form>
      </Modal>
      <div style={{ margin: "15px" }}>
        <Table>
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow sx={{ color: "white" }}>
              <TableCell align="center">
                <span className="class-table-header">#SL</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Name</span>
              </TableCell>
              <TableCell align="center">
                <span className="class-table-header">Date</span>
              </TableCell>

              <TableCell align="center">
                <span className="class-table-header">Action</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {search
              ? searchFilter.map((activity, index) => (
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{activity.name}</TableCell>
                    <TableCell align="center">
                      {dayjs(activity.date).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell align="center">
                      <div>
                        <Button
                          style={{ color: "#1b3779" }}
                          onClick={() =>
                            handleActivityEdit(activity._id, activity)
                          }>
                          <EditIcon />
                        </Button>
                        <Button
                          style={{ color: "#1b3779" }}
                          onClick={() =>
                            setDeleteModal(activity._id, activity)
                          }>
                          <DeleteIcon />
                        </Button>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={activity._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              : list.map((activity, index) => (
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{activity.name}</TableCell>
                    <TableCell align="center">
                      {dayjs(activity.date).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell align="center">
                      <div>
                        <Button
                          style={{ color: "#1b3779" }}
                          onClick={() =>
                            handleActivityEdit(activity._id, activity)
                          }>
                          <EditIcon />
                        </Button>
                        <Button
                          style={{ color: "#1b3779" }}
                          onClick={() =>
                            setDeleteModal(activity._id, activity)
                          }>
                          <DeleteIcon />
                        </Button>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={activity._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
        {!list.length && !search.trim() && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}>
            No data found
          </Typography>
        )}
        {search.trim() && !searchFilter.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}>
            No data found
          </Typography>
        )}
      </div>

      <div className="add-icon">
        <Fab
          variant="contained"
          style={{ color: "#ffff", backgroundColor: "#1b3779" }}
          onClick={handleClickOpen}>
          <AddIcon />
        </Fab>
      </div>
    </div>
  );
};

export default StudentActivity;
