import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Button,
  TextField,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import CloudUploadRounded from "@mui/icons-material/CloudUploadRounded";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Stack from "@mui/material/Stack";
import AddDocInfo from "./AddDocInfo";
import { useEffect } from "react";
import { get, post, put } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { objHas } from "../../../utils/utils";
import { useNavigation } from "react-router-dom";

const styles = {
  card: {
    padding: 15,
    marginBottom: 20,
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
    m: 1,
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
  },
};

const AddEmployee = (props) => {
  const [roles, setRoles] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [designtions, setDesignations] = useState([]);
  const navigate = useNavigate();
  const [employeeDetails, setEmployeeDetails] = useState({});
  const [resume, setResume] = useState(false);
  const [empPhoto, setEmpPhoto] = useState(false);
  const [dob, setDob] = useState(Date.now());
  const [joinDate, setJoinDate] = useState(Date.now());
  const [disabled, setDisabled] = useState(false);
  const [salaryGrade, setSalaryGrade] = useState([]);

  const handleFileChange = (e) => {};
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    //let temp = [...employeeDetails];

    setEmployeeDetails({ ...employeeDetails, [name]: value });
    //setEmpName(e.target.value);
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      let data = {
        basicInfo: {
          empName: employeeDetails.empName,
          empId: employeeDetails.empId,
          responsibility: employeeDetails.responsibility,
          designation: employeeDetails.designation,
          secMobileNo: employeeDetails.secMobileNo,
          empGender: employeeDetails.empGender,
          empBloodGroup: employeeDetails.empBloodGroup,
          Religion: employeeDetails.Religion,
          dob: dayjs(dob),
          presentAddress: employeeDetails.presentAddress,
          permanentAddress: employeeDetails.permanentAddress,
          fatherName: employeeDetails.fatherName,
          spouseName: employeeDetails.spouseName,
          aadharNo: employeeDetails.aadharNo,
          fatherOccupation: employeeDetails.fatherOccupation,
          spouseOccupation: employeeDetails.spouseOccupation,
        },
        academicInfo: {
          qualification: employeeDetails.qualification,
          workExp: employeeDetails.workExp,
          email: employeeDetails.email,
          salaryGrade: employeeDetails.salaryGrade,
          salaryType: employeeDetails.salaryType,
          department: employeeDetails.department,
          joiningDate: dayjs(joinDate),
          resume: employeeDetails.resume,
          annualSalary: employeeDetails.annualSalary,
        },
        username: employeeDetails.username,
        password: employeeDetails.password,
        status: employeeDetails.status,
        role: employeeDetails.role,
        empPhoto: employeeDetails.empPhoto,
        contactNumber: employeeDetails.contactNumber,
      };
      if (props.employee) {
        delete data.username;
        delete data.password;
        const res = await put(`/employee/${props.employee._id}`, "", data);
        if (res.data.success) {
          navigate("/dashboard/employee");
        } else {
          console.log("error ", res.data);
        }
      } else {
        const res = await post(urls.employee.postEmployee, data);
        if (res.data.success) {
          navigate("/dashboard/employee");
        } else {
          console.log("error ", res);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (props.employee) {
      const update = {
        ...props.employee.basicInfo,
        ...props.employee.academicInfo,
        ...props.employee,
      };
      // setDisabled(false); //to change based on employee status
      setEmployeeDetails(update);
      setDob(objHas(props, "employee.basicInfo.dob", ""));
      setJoinDate(objHas(props, "employee.academicInfo.joiningDate", ""));
    }
  }, [props]);

  useEffect(() => {
    const getRole = async () => {
      try {
        const res = await get(urls.role.getAllRole);
        setRoles(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getRole();
  }, []);
  useEffect(() => {
    const getSalaryGrade = async () => {
      try {
        const res = await get(urls.salaryGrade.getList);
        setSalaryGrade(res.data.result.data);
        console.log("setSalaryGrade", res.data.result.data);
      } catch (error) {
        console.log(error);
      }
    };
    getSalaryGrade();
  }, []);

  useEffect(() => {
    const getDepartment = async () => {
      try {
        const res = await get(urls.department.getDepartment);
        setDepartments(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getDepartment();
  }, []);
  useEffect(() => {
    const getDesignation = async () => {
      try {
        const res = await get(urls.designation.getAllDesignation);
        setDesignations(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getDesignation();
  }, []);
  const handleResume = async (e) => {
    const formData = new FormData();
    if (e.target.files.length > 0) {
      formData.append("files", e.target.files[0]);
      try {
        const res = await post(urls.fileUpload.postFileUpload, formData);
        if (res.data.success) {
          setEmployeeDetails({
            ...employeeDetails,
            resume: res.data.result[0]._id,
          });
          setResume(e.target.files[0].name);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleEmpPhoto = async (e) => {
    const formData = new FormData();
    if (e.target.files.length > 0) {
      formData.append("files", e.target.files[0]);
      try {
        const res = await post(urls.fileUpload.postFileUpload, formData);
        if (res.data.success) {
          setEmployeeDetails({
            ...employeeDetails,
            empPhoto: res.data.result[0]._id,
          });
          setEmpPhoto(e.target.files[0].name);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleNav = () => {
    navigate("/dashboard/employee");
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div style={{ margin: "15px" }}>
          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <h5 style={{ color: "#1b3779" }}>Basic Information:</h5>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    required
                    id="filled-required"
                    variant="standard"
                    label="Name"
                    disabled={props.view && true}
                    name="empName"
                    value={employeeDetails.empName}
                    onChange={(event) => handleOnChange(event)}
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-required"
                    variant="standard"
                    value={employeeDetails.empId}
                    name="empId"
                    //onChange={handleEmpid}

                    disabled={props.view && true}
                    onChange={(event) => handleOnChange(event)}
                    label="Employee Id"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-required"
                    variant="standard"
                    disabled={props.view && true}
                    value={employeeDetails.responsibility}
                    name="responsibility"
                    onChange={(event) => handleOnChange(event)}
                    label="Responsibility"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl
                    variant="standard"
                    sx={styles.textfield}
                    required
                  >
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Designation
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      disabled={props.view && true}
                      value={employeeDetails.designation}
                      defaultValue={
                        props.employee &&
                        props.employee.basicInfo &&
                        props.employee.basicInfo.designation
                      }
                      name="designation"
                      onChange={(event) => handleOnChange(event)}
                    >
                      {designtions &&
                        designtions.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.designation}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    required
                    id="filled-required"
                    variant="standard"
                    disabled={props.view && true}
                    value={employeeDetails.contactNumber}
                    type="number"
                    name="contactNumber"
                    onChange={(event) => handleOnChange(event)}
                    label="Contact Number"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-required"
                    variant="standard"
                    disabled={props.view && true}
                    value={employeeDetails.secMobileNo}
                    name="secMobileNo"
                    onChange={(event) => handleOnChange(event)}
                    label="Secondary mobile number"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl variant="standard" sx={styles.textfield}>
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Gender
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      disabled={props.view && true}
                      value={employeeDetails.empGender}
                      defaultValue={
                        props.employee &&
                        props.employee.basicInfo &&
                        props.employee.basicInfo.empGender
                      }
                      name="empGender"
                      onChange={(event) => handleOnChange(event)}
                    >
                      <MenuItem
                        value={"male"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Male
                      </MenuItem>
                      <MenuItem
                        value={"female"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Female
                      </MenuItem>
                      <MenuItem
                        value={"others"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Others
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl variant="standard" sx={styles.textfield}>
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Blood group
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={employeeDetails.empBloodGroup}
                      defaultValue={
                        props.employee &&
                        props.employee.basicInfo &&
                        props.employee.basicInfo.empBloodGroup
                      }
                      disabled={props.view && true}
                      name="empBloodGroup"
                      onChange={(event) => handleOnChange(event)}
                    >
                      <MenuItem
                        value={"a+"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        A+
                      </MenuItem>
                      <MenuItem
                        value={"a-"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        A-
                      </MenuItem>
                      <MenuItem
                        value={"b+"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        B+
                      </MenuItem>
                      <MenuItem
                        value={"b-"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        B-
                      </MenuItem>
                      <MenuItem
                        value={"o+"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        O+
                      </MenuItem>
                      <MenuItem
                        value={"o-"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        O-
                      </MenuItem>
                      <MenuItem
                        value={"ab+"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        AB+
                      </MenuItem>
                      <MenuItem
                        value={"ab-"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        AB-
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-required"
                    variant="standard"
                    label="Religion"
                    disabled={props.view && true}
                    value={employeeDetails.Religion}
                    name="Religion"
                    onChange={(event) => handleOnChange(event)}
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date of birth"
                      value={dob}
                      inputFormat="DD-MM-YYYY"
                      disabled={props.view && true}
                      onChange={(newValue) => {
                        setDob(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          style={styles.textfield}
                          variant="standard"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-required"
                    variant="standard"
                    disabled={props.view && true}
                    value={employeeDetails.presentAddress}
                    name="presentAddress"
                    onChange={(event) => handleOnChange(event)}
                    label="Present Address"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-required"
                    variant="standard"
                    disabled={props.view && true}
                    value={employeeDetails.permanentAddress}
                    name="permanentAddress"
                    onChange={(event) => handleOnChange(event)}
                    label="Permanent Address"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-required"
                    variant="standard"
                    disabled={props.view && true}
                    value={employeeDetails.fatherName}
                    name="fatherName"
                    onChange={(event) => handleOnChange(event)}
                    label="Father Name"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-required"
                    disabled={props.view && true}
                    variant="standard"
                    value={employeeDetails.spouseName}
                    name="spouseName"
                    onChange={(event) => handleOnChange(event)}
                    label="Spouse Name"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-required"
                    variant="standard"
                    disabled={props.view && true}
                    value={employeeDetails.aadharNo}
                    name="aadharNo"
                    pattern="^\d{4}-\d{4}-\d{4}$"
                    onChange={(event) => handleOnChange(event)}
                    label="Aadhar No."
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    id="filled-required"
                    variant="standard"
                    disabled={props.view && true}
                    value={employeeDetails.fatherOccupation}
                    name="fatherOccupation"
                    onChange={(event) => handleOnChange(event)}
                    label="Father Occupation"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    id="filled-required"
                    variant="standard"
                    disabled={props.view && true}
                    value={employeeDetails.spouseOccupation}
                    name="spouseOccupation"
                    onChange={(event) => handleOnChange(event)}
                    label="Spouse Occupation"
                    sx={styles.textfield}
                  />
                </Grid>
              </Grid>
            </Box>
          </Card>
          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3} lg={3}>
                  <h5 style={{ color: "#1b3779" }}>Academic Information:</h5>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    required
                    disabled={props.view && true}
                    id="filled-required"
                    variant="standard"
                    value={employeeDetails.qualification}
                    name="qualification"
                    onChange={(event) => handleOnChange(event)}
                    label="Qualification"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    disabled={props.view && true}
                    id="filled-required"
                    variant="standard"
                    value={employeeDetails.workExp}
                    name="workExp"
                    onChange={(event) => handleOnChange(event)}
                    label="Work Experience"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl
                    variant="standard"
                    sx={styles.textfield}
                    //required
                  >
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      SalaryGrade
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={employeeDetails.salaryGrade}
                      defaultValue={
                        props.employee &&
                        props.employee.academicInfo &&
                        props.employee.academicInfo.salaryGrade
                      }
                      name="salaryGrade"
                      onChange={(event) => handleOnChange(event)}
                    >
                      {salaryGrade &&
                        salaryGrade.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.grade}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    required
                    disabled={props.view && true}
                    id="filled-required"
                    variant="standard"
                    value={employeeDetails.email}
                    name="email"
                    onChange={(event) => handleOnChange(event)}
                    label="Email"
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl
                    variant="standard"
                    sx={styles.textfield}
                    required
                  >
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Salary Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={employeeDetails.salaryType}
                      disabled={props.view && true}
                      defaultValue={
                        props.employee &&
                        props.employee.academicInfo &&
                        props.employee.academicInfo.salaryType
                      }
                      name="salaryType"
                      onChange={(event) => handleOnChange(event)}
                    >
                      <MenuItem
                        value={"monthly"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Monthly
                      </MenuItem>
                      <MenuItem
                        value={"hourly"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Hourly
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl
                    variant="standard"
                    sx={styles.textfield}
                    required
                  >
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Role
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      disabled={props.view && true}
                      value={employeeDetails.role}
                      defaultValue={props.employee && props.employee.role}
                      name="role"
                      onChange={(event) => handleOnChange(event)}
                    >
                      {roles &&
                        roles.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.roleName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl
                    variant="standard"
                    sx={styles.textfield}
                    required
                  >
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Department
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={employeeDetails.department}
                      disabled={props.view && true}
                      defaultValue={
                        props.employee &&
                        props.employee.academicInfo &&
                        props.employee.academicInfo.department
                      }
                      name="department"
                      onChange={(event) => handleOnChange(event)}
                    >
                      {departments &&
                        departments.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.department}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Admission Date"
                      value={joinDate}
                      inputFormat="DD-MM-YYYY"
                      onChange={(newValue) => {
                        setJoinDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          disabled={props.view && true}
                          style={styles.textfield}
                          variant="standard"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <div style={{ marginLeft: 10 }}>
                      <div style={{ fontSize: 12, color: "#666" }}>Resume:</div>
                      <Tooltip title="Upload">
                        <IconButton
                          sx={{
                            color: "#1b3779",
                            ":hover": { color: "#1b3779" },
                          }}
                          color="primary"
                          aria-label="upload picture"
                          component="label"
                        >
                          <input
                            hidden
                            disabled={props.view && true}
                            name="resume"
                            label="resume"
                            onChange={(event) => handleResume(event)}
                            accept=".pdf, .doc, .docx, .txt"
                            type="file"
                          />
                          <CloudUploadRounded />
                          {resume && (
                            <span
                              style={{ marginLeft: "10px", fontSize: "12px" }}
                            >
                              {resume}
                            </span>
                          )}
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    // required
                    disabled={props.view && true}
                    id="filled-required"
                    variant="standard"
                    value={employeeDetails.annualSalary}
                    name="annualSalary"
                    onChange={(event) => handleOnChange(event)}
                    label="annualSalary"
                    sx={styles.textfield}
                  />
                </Grid>
              </Grid>
            </Box>
          </Card>
          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3} lg={3}>
                  <h5 style={{ color: "#1b3779" }}>Log In Information:</h5>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    disabled={props.view && true}
                    id="filled-required"
                    variant="standard"
                    label="Username"
                    value={employeeDetails.username}
                    name="username"
                    onChange={(event) => handleOnChange(event)}
                    sx={styles.textfield}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  {!props.employee && (
                    <TextField
                      required
                      disabled={props.view && true}
                      id="filled-required"
                      variant="standard"
                      value={employeeDetails.password}
                      name="password"
                      onChange={(event) => handleOnChange(event)}
                      label="Password"
                      sx={styles.textfield}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          </Card>
          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3} lg={3}>
                  <h5 style={{ color: "#1b3779" }}>Other Information:</h5>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl variant="standard" sx={styles.textfield}>
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Is View on Web?
                    </InputLabel>
                    <Select
                      disabled={props.view && true}
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                    >
                      <MenuItem
                        value={"yes"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Yes
                      </MenuItem>
                      <MenuItem
                        value={"no"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        No
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl variant="standard" sx={styles.textfield}>
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Have details for web
                    </InputLabel>
                    <Select
                      disabled={props.view && true}
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                    >
                      <MenuItem
                        value={"yes"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Yes
                      </MenuItem>
                      <MenuItem
                        value={"no"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        No
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <div style={{ marginLeft: 10 }}>
                      <div style={{ fontSize: 12, color: "#666" }}>Photo:</div>
                      <Tooltip title="Upload">
                        <IconButton
                          sx={{
                            color: "#1b3779",
                            ":hover": { color: "#1b3779" },
                          }}
                          color="primary"
                          aria-label="upload picture"
                          component="label"
                        >
                          <input
                            disabled={props.view && true}
                            hidden
                            name="empPhoto"
                            onChange={(event) => handleEmpPhoto(event)}
                            accept="image/*"
                            type="file"
                          />
                          <PhotoCamera />
                          {empPhoto && (
                            <span
                              style={{ marginLeft: "10px", fontSize: "12px" }}
                            >
                              {empPhoto}
                            </span>
                          )}
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Card>
          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3} lg={3}>
                  <h5 style={{ color: "#1b3779" }}>Profile Information:</h5>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <FormControl variant="standard" sx={styles.textfield}>
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Status
                    </InputLabel>
                    <Select
                      disabled={props.view && true}
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={employeeDetails.status}
                      defaultValue={props.employee && props.employee.status}
                      name="status"
                      onChange={(event) => handleOnChange(event)}
                    >
                      <MenuItem
                        value={"active"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Active
                      </MenuItem>
                      <MenuItem
                        value={"inactive"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Inactive
                      </MenuItem>

                      <MenuItem
                        value={"off-board"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Off Boarding
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Card>
          {/* <AddDocInfo /> */}
          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <div className="emp-cancel-submit">
                <div>
                  <Tooltip title="Cancel">
                    <Button
                      style={styles.Button}
                      variant="contained"
                      size="small"
                      onClick={handleNav}
                    >
                      Cancel
                    </Button>
                  </Tooltip>
                </div>
                <div>
                  <Button
                    style={styles.Button}
                    variant="contained"
                    size="small"
                    disabled={disabled}
                    type="submit"
                  >
                    {props.employee ? "UPDATE" : "CREATE"}
                  </Button>
                </div>
              </div>
            </Box>
          </Card>
        </div>
      </form>
    </div>
  );
};

export default AddEmployee;
