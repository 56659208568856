import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "./../styles/components/navbar/Navbar.scss";
import { Language } from "@mui/icons-material";
import "./../styles/components/navbar/NavTop.scss";
import css from "./Addmission.module.css";
import Footer from "./Footer/Footer";
import TopNav from "./navbar/TopNav";
import Navbar from "./navbar/Navbar";
import Demo from "./NewsNotic/Demo";

const Library = () => {
  const [addClass, updateClass] = useState(false);
  const [stkyClass, setStkyClass] = useState(false);
  const [mega, setMega] = useState(false);

  const handleClickCross = () => {
    updateClass(false);
    window.scrollTo(0, 0);
  };
  const scrollHandleScroll = () => {
    if (window.scrollY > 20) {
      setStkyClass(true);
    } else {
      setStkyClass(false);
    }
  };
  const handleMega = () => {
    setMega(!mega);
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollHandleScroll);
  }, []);

  const menuItems = [
    {
      title: "Home",
      children: [],
    },
    {
      title: "About",
      children: [
        {
          title: "Our School",
          link: "/our-school",
        },
        {
          title: "Principal Desk",
          link: "/principal-desk",
        },
        {
          title: "Faculty",
          link: "/faculty",
        },
        {
          title: "Guardian Feedback",
          link: "/guardian-feedback",
        },
      ],
    },
    {
      title: "Gallery",
      children: [],
    },
    {
      title: "Academics",
      children: [],
    },
    {
      title: "Facilities",
      children: [
        {
          title: "Library",
          link: "/library",
        },
        {
          title: "Sports",
          link: "/sports",
        },
        {
          title: "Canteen",
          link: "/canteen",
        },
        {
          title: "Transport",
          link: "/transport",
        },
        {
          title: "Edusmart",
          link: "/Edusmart",
        },
      ],
    },
    {
      title: "Jobs",
      children: [],
    },
    {
      title: "Exam",
      children: [
        {
          title: "Exam Time Table",
          link: "/birth-day",
        },
        {
          title: "Hall Ticket",
          link: "/annual-day",
        },
        {
          title: "Results",
          link: "/freshers-day",
        },
      ],
    },
    {
      title: "Contact Us",
      children: [],
    },
  ];

  return (
    <>
      <TopNav />
      <Navbar />
      <div className={css.headerContainer}>
        <div>
          <h1>LIBRARY</h1>
          <h5>
            <Link
              style={{
                color: "white",
              }}
              to="/"
            >
              Home
            </Link>{" "}
            / LIBRARY
          </h5>
        </div>
      </div>

      <Grid container spacing={12} marginLeft={"-76px"}>
        <Grid
          items
          xs={12}
          mg={12}
          lg={8}
          style={{
            margin: "40px 0px 20px 0px",
            "border-right": "1px solid #BDBDBD",
          }}
        >
          <p style={{ margin: "0px 20px 20px 24px", fontSize: "1.11rem" }}>
            The school library is an integral part of teaching and learning in
            the school. It scaffolds and provides access to resources, which
            support the India curriculum.
          </p>
          <p style={{ margin: "10px 10px 5px 24px", fontSize: "1.11rem" }}>
            The school library embodies the principles of the Indian curriculum:
          </p>
          <ul style={{ margin: "-5px 10px 10px 24px", fontSize: "1.11rem" }}>
            <li>Promoting cultural diversity and inclusion.</li>
            <li>
              Developing all learners in a quality and coherent learning
              environment.
            </li>
          </ul>
          <p style={{ margin: "10px 10px 20px 24px", fontSize: "1.11rem" }}>
            The library plays a key role in enabling community engagement, and
            promotes outward thinking and future focused thought and discussion.
          </p>
          <p style={{ margin: "10px 10px 20px 24px", fontSize: "1.11rem" }}>
            School libraries incorporate and reflect the key competencies and
            values of the India curriculum, and develop “ confident, connected,
            actively involved life-long learners.
          </p>
        </Grid>
        <Grid
          items
          xs={12}
          mg={12}
          lg={4}
          style={{ height: "80vh", paddingTop: "40px" }}
        >
          <Demo />
        </Grid>
      </Grid>
      {/* <div className={css.contentContainer}>
          <div className={css.content}>
          <div className={css.contentHolder}>
                    <div className={css.contentText}>
                    The school library is an integral part of teaching and learning in the school. It scaffolds and provides access to resources, which support the India curriculum.
                    </div>
                </div>
                <div className={css.contentHolder}>
                    <div className={css.contentText}>
                    The school library embodies the principles of the Indian curriculum:
                    </div>
                </div>
                <div className={css.contentHolder}>
                    <ul className={css.contentText}>
                        <li>Promoting cultural diversity and inclusion.</li>
                        <li>Developing all learners in a quality and coherent learning environment.</li>
                    </ul>
                </div>
                <div className={css.contentHolder}>
                    <div className={css.contentText}>
                    The library plays a key role in enabling community engagement, and promotes outward thinking and future focused thought and discussion.                    </div>
                </div>
                <div className={css.contentHolder}>
                    <div className={css.contentText}>
                    School libraries incorporate and reflect the key competencies and values of the India curriculum, and develop “ confident, connected, actively involved life-long learners.                
                    </div>
                </div>
          </div>
          <div className={css.newsNoticeContainer}>
            <div className={css.newsNotice}>
                <Demo />
            </div>
        </div>
      </div> */}

      <Footer />
    </>
  );
};

export default Library;
