import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import { get } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 390,
  bgcolor: "background.paper",
  p: 4,
  textfield: {
    minWidth: 227,
    label: { fontSize: 12 },
    m: 1,
  },
  Button: {
    color: "#fff",
    m: 1,
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
  },
};

const AddExamSchedule = (props) => {
  const [exam, setExam] = useState("");
  const [classSchedule, setClassSchedule] = useState("");
  const [subjectSchedule, setSubjectSchedule] = useState("");
  const [examDate, setExamDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [marksFreezeDate, setMarksFreezeDate] = useState("");
  const [maxMarks, setMaxMarks] = useState("");
  const [wriiten, setWriiten] = useState("");
  const [pratical, setPratical] = useState("");
  const [minMarks, setMinMarks] = useState("");
  const [orderSequence, setOrderSequence] = useState(0);
  const [praticalMarks, setPraticalMarks] = useState("");
  const [examTitles, setExamTitles] = useState([]);
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");

  const handleExam = (e) => {
    setExam(e.target.value);
  };
  const handleClassSchedule = async (e) => {
    setClassSchedule(e.target.value);

    try {
      const sections = await get(urls.schoolSubject.getAllSubject, {
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      setSubjects(sections.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubjectSchedule = (e) => {
    setSubjectSchedule(e.target.value);
  };
  const handleExamDate = (e) => {
    setExamDate(e.target.value);
  };
  const handleStartTime = (e) => {
    setStartTime(e.target.value);
  };
  const handleEndTime = (e) => {
    setEndTime(e.target.value);
  };
  const handleMarksFreezeDate = (e) => {
    setMarksFreezeDate(e.target.value);
  };
  const handleMaxMarks = (e) => {
    setMaxMarks(e.target.value);
  };
  const handleWritten = (e) => {
    setWriiten(e.target.value);
  };
  const handlePratical = (e) => {
    setPratical(e.target.value);
  };
  const handleMinMarks = (e) => {
    setMinMarks(e.target.value);
  };
  const handleOrderSequence = (e) => {
    setOrderSequence(e.target.value);
  };
  const handlePraticalMarks = (e) => {
    setPraticalMarks(e.target.value);
  };

  useEffect(() => {
    const getExamTitle = async () => {
      try {
        const res = await get(urls.examTerm.getAllExamTerm);
        setExamTitles(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getExamTitle();
  }, []);

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        setClasses(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);

  useEffect(() => {
    const getSubjects = async () => {
      try {
        const res = await get(urls.schoolSubject.getAllSubject);
        setSubjects(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getSubjects();
  }, []);

  useEffect(() => {
    const getSubjectsForClass = async (classId) => {
      try {
        const res = await get(urls.schoolSubject.getAllSubject, {
          params: {
            search: {
              class: classId,
            },
          },
        });
        setSubjects(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    if (props.updateExamSchedule) {
      setExam(props.updateExamSchedule.exam._id);
      setClassSchedule(props.updateExamSchedule.class._id);

      setSubjectSchedule(
        props.updateExamSchedule.subject
          ? props.updateExamSchedule.subject._id
          : ""
      );
      setExamDate(
        dayjs(props.updateExamSchedule.examDate).format("YYYY-MM-DD")
      );
      setStartTime(
        dayjs(props.updateExamSchedule.startTime, "HH:mm").format("HH:mm")
      );
      setEndTime(
        dayjs(props.updateExamSchedule.endTime, "HH:mm").format("HH:mm")
      );
      setMarksFreezeDate(
        dayjs(props.updateExamSchedule.marksFreezDate).format("YYYY-MM-DD")
      );
      setMaxMarks(props.updateExamSchedule.maxMarks);
      setWriiten(props.updateExamSchedule.wriiten);
      setPratical(props.updateExamSchedule.pratical);
      setMinMarks(props.updateExamSchedule.minMarks);
      setPraticalMarks(props.updateExamSchedule.praticalMarks);
      setOrderSequence(props.updateExamSchedule.orderSequence);
      getSubjectsForClass(props.updateExamSchedule.class._id);
    }
  }, [props.open]);

  const handleSubmit = (e) => {
    if (props.updateExamSchedule) {
      props.handleUpdate(
        {
          exam,
          class: classSchedule,
          subject: subjectSchedule,
          examDate,
          startTime,
          endTime,
          marksFreezDate: marksFreezeDate,
          maxMarks,
          wriiten,
          minMarks,
          orderSequence,
        },
        props.updateExamSchedule._id
      );
    } else {
      props.handleSubmit(e, {
        exam,
        class: classSchedule,
        subject: subjectSchedule,
        examDate,
        startTime,
        endTime,
        marksFreezDate: marksFreezeDate,
        maxMarks,
        wriiten,
        minMarks,
        orderSequence,
      });
    }
    setExam("");
    setClassSchedule("");
    setSubjectSchedule("");
    setExamDate("");
    setStartTime("");
    setEndTime("");
    setMarksFreezeDate("");
    setMaxMarks("");
    setWriiten("");
    setPratical("");
    setMinMarks("");
    setPraticalMarks("");
    props.handleClose();
  };

  const handleCloseModal = () => {
    setExam("");
    setClassSchedule("");
    setSubjectSchedule("");
    setExamDate("");
    setStartTime("");
    setEndTime("");
    setMarksFreezeDate("");
    setMaxMarks("");
    setWriiten("");
    setPratical("");
    setMinMarks("");
    setPraticalMarks("");
    props.handleClose();
  };

  const handleClose = () => {
    setExam("");
    setClassSchedule("");
    setSubjectSchedule("");
    setExamDate("");
    setStartTime("");
    setEndTime("");
    setMarksFreezeDate("");
    setMaxMarks("");
    setWriiten("");
    setPratical("");
    setMinMarks("");
    setPraticalMarks("");
    props.handleClose();
  };
  return (
    <div>
      <Tooltip title="Add">
        <Fab
          size="medium"
          style={{ background: "#1b3779", color: "#fff" }}
          aria-label="add"
          onClick={props.handleOpen}
        >
          <Add />
        </Fab>
      </Tooltip>
      <Modal
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleSubmit}>
          <Box sx={style}>
            <div>
              <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 227 }}
                required
              >
                <InputLabel
                  id="demo-simple-select-filled-label"
                  sx={{ fontSize: 12 }}
                >
                  Exam
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={exam}
                  onChange={handleExam}
                >
                  {examTitles &&
                    examTitles.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.examTitle}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 227 }}
                required
              >
                <InputLabel
                  id="demo-simple-select-filled-label"
                  sx={{ fontSize: 12 }}
                >
                  Class
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={classSchedule}
                  onChange={handleClassSchedule}
                >
                  {classes &&
                    classes.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.className}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 227 }}
                required
              >
                <InputLabel
                  id="demo-simple-select-filled-label"
                  sx={{ fontSize: 12 }}
                >
                  Subject
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={subjectSchedule}
                  onChange={handleSubjectSchedule}
                >
                  {subjects &&
                    subjects.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.subjectName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <div>
                <TextField
                  required
                  id="filled-required"
                  variant="standard"
                  type="date"
                  value={examDate}
                  onChange={handleExamDate}
                  label="Exam Date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                />
                <TextField
                  required
                  id="filled-required"
                  variant="standard"
                  type="time"
                  value={startTime}
                  onChange={handleStartTime}
                  label="Start Time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                />
                <TextField
                  required
                  id="filled-required"
                  variant="standard"
                  type="time"
                  value={endTime}
                  onChange={handleEndTime}
                  label="End Time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                />

                <TextField
                  required
                  id="date"
                  label="Marks Freez Date"
                  variant="standard"
                  type="date"
                  sx={style.textfield}
                  value={marksFreezeDate}
                  onChange={handleMarksFreezeDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  required
                  id="filled-required"
                  variant="standard"
                  value={maxMarks}
                  onChange={handleMaxMarks}
                  label="Max Marks"
                  sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                />
                <TextField
                  required
                  id="filled-required"
                  variant="standard"
                  value={minMarks}
                  onChange={handleMinMarks}
                  label="Minimum Marks"
                  sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                />
                <TextField
                  id="filled-required"
                  variant="standard"
                  value={orderSequence}
                  onChange={handleOrderSequence}
                  label="Order Sequence"
                  type="number"
                  sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                />
              </div>
            </div>

            <div className="cancel-submit-btn">
              <div>
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  size="medium"
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  //   disabled={
                  //     !(
                  //       exam &&
                  //       classSchedule &&
                  //       subjectSchedule &&
                  //       examDate &&
                  //       startTime &&
                  //       endTime
                  //     )
                  //   }
                  variant="contained"
                  size="medium"
                  type="submit"
                  sx={style.Button}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
};

export default AddExamSchedule;
