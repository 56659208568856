import React, { useEffect } from "react";
import "../../../styles/components/Certificate/TransferCertificate.scss";
import {
  Card,
  Box,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  Autocomplete,
} from "@mui/material";
import { get } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import { useState } from "react";
import axios from "axios";
import { instance } from "../../../services/axios.Config";
import { LoadingButton } from "@mui/lab";

const styles = {
  card: {
    margin: 15,
    padding: 10,
  },
  textfield: {
    width: "100%",
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
  },
};

function TransferCertificate() {
  const [loading,setLoading]=useState(false);
  const [settings, setSettings] = useState({});
  const [formData, setFormData] = useState({});
  const [academicYears, setAcademicYears] = useState([]);
  const [selectAcademicYear, setSelectAcademicYear] = useState([]);
  const [studentsSC, setStudentsSC] = useState([]);
  const [studentSelect, setStudentSelect] = useState({});

  useEffect(() => {
    const getSettings = async () => {
      try {
        const settings = await get(urls.settings.getAllSettings);
        setSettings(settings.data.data[0]);
        const data = settings.data.data[0];
        setFormData({
          ...formData,
          schoolName: data.schoolName,
        });
      } catch (error) {
        console.log(error);
      }
    };
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll);
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
    getSettings();
  }, []);
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleAcademicYears = async (e) => {
    try {
      setSelectAcademicYear(e.target.value);
      const res = await get(urls.students.getAllStudent, {
        params: {
          search: {
            academicYear: e.target.value,
          },
        },
      });
      setStudentsSC(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleStudentSelect = (val, newVal) => {
    setStudentSelect(newVal);
    if (Object.keys(newVal).length) {
      const data = {
        studentName: newVal.basicInfo && newVal.basicInfo.name,
        birthPlace: newVal.basicInfo && newVal.basicInfo.birthPlace,
        sex: newVal.basicInfo && newVal.basicInfo.gender,
        religion: newVal.basicInfo && newVal.basicInfo.religion,
        caste: newVal.basicInfo && newVal.basicInfo.caste,
        aadhar: newVal.basicInfo && newVal.basicInfo.aadharNo,
        fatherName: newVal.fatherInfo && newVal.fatherInfo.fatherName,
        motherName: newVal.motherInfo && newVal.motherInfo.motherName,
        admissionDate: newVal.basicInfo && newVal.basicInfo.addmissionDate,
        prevSchool: newVal.prevSchInfo && newVal.prevSchInfo.prevSchool,
        currentClass:
          newVal.academicInfo && newVal.academicInfo.class.className,
      };
      setFormData({
        ...formData,
        ...data,
      });
    }
  };
  const handleSubmit = async () => {
    setLoading(true);
    try {
      const transferCertificate = await instance({
        method: "post",
        url: `/transfer-certificate`,
        data: {
          data: formData,
        },
        responseType: "blob",
      });
      const uri = URL.createObjectURL(transferCertificate.data);
      window.open(uri, "__blank");
       setTimeout(() => {
         setLoading(false);
       }, 2000);
    } catch (error) {
      console.log(error);
       setTimeout(() => {
         setLoading(false);
       }, 2000);
    }
  };
  return (
    <div>
      <Card style={styles.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <span
              style={{
                color: "red",
                fontSize: "20px",
                margin: "5px 0px",
                fontFamily: "cursive",
              }}>
              Transfer Certificate :
            </span>
          </Grid>
        </Grid>
      </Card>
      <Card style={styles.card}>
        <Box flexGrow={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={5}>
              <FormControl variant="standard" style={styles.textfield}>
                <InputLabel>Academic Year</InputLabel>
                <Select
                  name="academicYear"
                  value={selectAcademicYear}
                  onChange={(event) => handleAcademicYears(event)}>
                  {academicYears &&
                    academicYears.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}>
                        {row.academicYearFrom} - {row.academicYearTo}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={5}>
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => option.basicInfo.name}
                id="combo-box-demo"
                options={studentsSC}
                onChange={handleStudentSelect}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField variant="standard" {...params} label="Students" />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={2} style={{ alignSelf: "flex-end" }}>
              <LoadingButton
                onClick={handleSubmit}
                size="small"
                loading={loading}
                variant="contained"
                sx={{
                  backgroundColor: "#1b3779",
                  ":hover": { backgroundColor: "#1b3779" },
                }}>
                Issue
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Card>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12} style={{ textAlign: "center" }}>
          <span className="excellent">{settings && settings.schoolName}</span>{" "}
          <br />
          <div className="schl-name">
            ENGLISH MEDIUM PRIMARY AND HIGH SCHOOL
          </div>
          <div className="address-in-certificate">
            {settings && settings.address}
          </div>
          <span className="tc-title">TRANSFER CERTIFICATE</span>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <div className="main-container">
            <div className="tc-container">
              <div className="tc-details">
                <div className="tc-label">1] Name of the School:</div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="schoolName"
                    value={
                      formData.schoolName || (settings && settings.schoolName)
                    }
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">2] Name of the Pupil in full:</div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="studentName"
                    value={formData.studentName || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">3] G. R. No:</div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="grNo"
                    value={formData.grNo || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">4] Place of Birth:</div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="birthPlace"
                    value={formData.birthPlace || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">
                  5] Date of Birth(in figures and words):
                </div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="dobWords"
                    value={formData.dobWords || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">6] Sex:</div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="sex"
                    value={formData.sex || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">7] Nationality:</div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="nationality"
                    value={formData.nationality || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">8] Religion:</div>

                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="religion"
                    value={formData.religion || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">9] Caste:</div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="caste"
                    value={formData.caste || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">
                  10] Whether the candidate belongs to SC and ST":
                </div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="belongs"
                    value={formData.belongs || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">11] AAdhar No.:</div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="aadhar"
                    value={formData.aadhar || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">12] Name of the father:</div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="fatherName"
                    value={formData.fatherName || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">13] Name of the mother:</div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="motherName"
                    value={formData.motherName || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">
                  14] Date of Admission in the Scchool:
                </div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="admissionDate"
                    value={formData.admissionDate || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">15] School Previously Attended:</div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="prevSchool"
                    value={formData.prevSchool || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">
                  16] Standard in which admission is granted:
                </div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="admissionGrant"
                    value={formData.admissionGrant}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">
                  17] Standard in which the pupil was studying at the time of
                  leaving the school(in words):
                </div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="currentClass"
                    value={formData.currentClass || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">18] Medium of instruction:</div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="instructions"
                    value={formData.instructions || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">
                  19] Whether the pupil has paid all the free due of the school:
                </div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="fees"
                    value={formData.fees || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">20] Promoted to/ Detained in:</div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="proDet"
                    value={formData.proDet || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">
                  21] Whether medically examinated or not ? :
                </div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="medical"
                    value={formData.medical || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">
                  22] Date of pupil's last attendance at the school:
                </div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="lastAttend"
                    value={formData.lastAttend || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">
                  23] Date of which the application for the Transfer Certificate
                  was received:
                </div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="transferRecivied"
                    value={formData.transferRecivied || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">
                  24] Date of issue of the Transfer Certificate:
                </div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="dateIssue"
                    value={formData.dateIssue || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">25] Attendance:</div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="attendance"
                    value={formData.attendance || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="tc-details">
                <div className="tc-label">26] Character & Conduct:</div>
                <div className="tc-inputs">
                  <TextField
                    sx={{ width: "80%" }}
                    id="standard-basic"
                    variant="standard"
                    name="character"
                    value={formData.character || ""}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={12} style={{ textAlign: "center" }}>
          <span className="certificate">CERTIFICATE</span> <br />
          <div className="certif-info">
            <div className="note-in-certificate">
              Certified that the entries made in this Transfer Certificate are
              taken from the entries made as per the general egister maintained
              in the institution. The entries with the original records
              persented by the parents
            </div>
          </div>
          <div className="tc-sign">
            <div>Prepared By</div>
            <div>Checked By</div>
            <div>Head Master</div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default TransferCertificate;
