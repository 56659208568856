import React, { useState } from "react";
import {
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
    makeStyles
} from "@material-ui/core";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(() => ({
    link: {
        textDecoration: "none",
        color: "blue",
        fontSize: "20px",
    },
    icon: {
        color: "white"
    }
}));

function DrawerComponent({ menus }) {
    const classes = useStyles();
  const [addClass, updateClass] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    return (
        <>
   
            <Drawer className="small-menu"
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
            >
                     <IconButton className="close-menu" onClick={() => setOpenDrawer(!openDrawer)}>
        <CloseIcon />
      </IconButton>
                <List className={addClass ? "vissible" : "hidden"}>
                    {menus.map((item, index) => {
                         return (
                    <ListItem className="menu" onClick={() => setOpenDrawer(false)} key={item.title}>
                         {item.link ?  <ListItemText > <Link to={`${item.link}`}>{item.title}  </Link> </ListItemText> :<ListItemText >{item.title} <ListItem >
                                        {item.children.map((child, index2) => {
                                            return (<ListItemText  key={child.title}> <Link to={`${child.link}`} >{child.title} </Link> </ListItemText>);
                                        })}
                                    </ListItem>  </ListItemText>}
                    </ListItem>
                         );
                    })}
                </List>
            </Drawer>
            <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
                <MenuIcon />
            </IconButton>
        </>
    );
}
export default DrawerComponent;