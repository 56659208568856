import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "./../styles/components/navbar/Navbar.scss";
import { Language } from "@mui/icons-material";
import "./../styles/components/navbar/NavTop.scss";
import css from './Addmission.module.css'
import Footer from "./Footer/Footer";
import TopNav from "./navbar/TopNav";
import Navbar from "./navbar/Navbar";
import Demo from "./NewsNotic/Demo";

const KnowledgeOfParents = () => {
    const [addClass, updateClass] = useState(false);
  const [stkyClass, setStkyClass] = useState(false);
  const [mega, setMega] = useState(false);

  const handleClickCross = () => {
    updateClass(false);
    window.scrollTo(0, 0);
  };
  const scrollHandleScroll = () => {
    if (window.scrollY > 20) {
      setStkyClass(true);
    } else {
      setStkyClass(false);
    }
  };
  const handleMega = () => {
    setMega(!mega);
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollHandleScroll);
  }, []);

  return (
    <>
        <TopNav />
        <Navbar />
      <div className={css.headerContainer}>
                <div>
                    <h1>TO THE KNOWLEDGE OF PARENTS</h1>
                    <h5><Link style={{
                        color:'white'
                    }} to='/'>Home</Link> / TO THE KNOWLEDGE OF PARENTS</h5>
                </div>
      </div>
      <div className={css.contentContainer}>
        <div className={css.content}>
            <div className={css.contentHolder}>
                <div className={css.contentText}>
                    <ul style={{
                        paddingLeft:'170px'
                    }}>
                        <li>Admission of students to the hostel will be renewed every year. On the basis of behaviour and conduct of student.</li>
                        <li>Parents have pay prescribed fees in fixed installments. NO influence or entry of third person in this regard is entertained</li>
                        <li>The hostel authorities/ management cannot be held responsible if student leaves campus without the written permission of authorities. In such cases no second chance is given to students in hostel</li>
                        <li>Parents should co-operate in maintaining cordial relation between students and management/ teachers. Parents should not talk anything that damages respect of students towards institutions and the staff.</li>
                        <li>Parents shall not enter the dormitory when they visit campus.</li>
                        <li>Parents are requested not to bring junk food/ soft drinks to thei kids. They can bring only fruits/ dry fruits instead.</li>
                        <li>In case of illness to their kids, they will be taken to near by hospital. Please remember that no resident doctor is present in the hostel. However in severe cases. Parents are intimated about the same.</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className={css.newsNoticeContainer}>
            <div className={css.newsNotice}>
                <Demo />
            </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default KnowledgeOfParents;