import { Box, Button, Card, FormControl, Grid, TextField } from "@mui/material";
import React from "react";
import Stack from "@mui/material/Stack";

const style = {
  card: {
    color: "red",
    fontFamily: "cursive",
    textAlign: "start",
  },
  card1: {
    padding: 15,
    marginBottom: 20,
    margin: "70px 200px",
  },
};
const PasswordReset = () => {
  return (
    <div>
      <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <span style={style.card}>Reset Password</span>
          </Grid>
        </Grid>
      </Card>
      <Card style={style.card1}>
        <Box sx={{ flexGrow: 1 }}>
          <form>
            <Grid container spacing={4}>
              <Grid item xs={12} md={12} lg={12}>
                <FormControl
                  variant="standard"
                  sx={style.textfield}
                  fullWidth
                  required>
                  <TextField
                    variant="standard"
                    label="Password"
                    name="password"
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <FormControl
                  variant="standard"
                  sx={style.textfield}
                  fullWidth
                  required>
                  <TextField
                    variant="standard"
                    label=" Confirm Password"
                    name="confirmPassword"
                    required
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginTop: "15px",
              }}>
              <Stack spacing={2} direction="row">
                <Button variant="outlined">Cancel</Button>

                <Button
                  variant="contained"
                  sx={{
                    background: "rgb(27, 55, 121)",
                    ":hover": { background: "rgb(27, 55, 121)" },
                  }}>
                  Submit
                </Button>
              </Stack>
            </div>
          </form>
        </Box>
      </Card>
    </div>
  );
};

export default PasswordReset;
