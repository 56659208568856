import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Delete, Edit, Search } from "@mui/icons-material";
import styles from "../../../styles/components/Layout/Administrator/UserRole.module.css";
import AddSplashNews from "./AddSplashNews";
import { urls } from "../../../services/urlConstant";
import { get, post, put, del } from "../../../services/apis";
import DeleteModal from "../../Layout/Academic/DeleteModal";

const SplashNews = () => {
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState([]);
  const [open, setOpen] = useState(false);
  const [flashNews, setFlashNews] = useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [editData, setEditData] = useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const handleLinkClick = (url) => {
    window.open(url, "_blank");
  };
  const handleDeleteone = async (id) => {
    try {
      const res = await del(`${urls.announceFlashNews.delFlashNews}`, id);
      if (res.data.success) {
        const filteredEvent = flashNews.filter((item) => item._id !== id);
        setFlashNews(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    if (!value) return;
    setSearch(value.trim());
    if (value.trim() !== "") {
      flashNews.length > 0 &&
        setSearchFilter(
          flashNews.filter((ele) =>
            ele.title.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  useEffect(() => {
    const getSplashNews = async () => {
      try {
        const response = await get(urls.announceFlashNews.getFlashNews, {
          params: {
            page: 1,
            limit: 10,
          },
        });
        setFlashNews(response.data.data);
        console.log("setFlashNews", response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getSplashNews();
  }, []);
  const getSplashNews = async () => {
    try {
      const response = await get(urls.announceFlashNews.getFlashNews);
      setFlashNews(response.data.data);
      console.log("setFlashNews", response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleEditClick = (data) => {
    setEditData(data);
    handleOpen();
  };
  return (
    <div>
      <Card style={{ margin: "15px", padding: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span
              style={{
                color: "red",
                fontFamily: "cursive",
              }}
            >
              Splash News :
            </span>
          </Grid>
        </Grid>
      </Card>
      <div style={{ margin: "15px" }}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <div className={styles.searchInputContainer}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid xs="auto">
                <form>
                  <TextField
                    size="small"
                    value={search}
                    onChange={handleSearch}
                    label="Search"
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end" type="submit">
                            <Search />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </form>
              </Grid>
            </Grid>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Title</span>
                  </TableCell>

                  <TableCell align="center">
                    <span className="class-table-header">Is View On Web?</span>
                  </TableCell>

                  <TableCell align="center">
                    <span className="class-table-header">Type</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Action </span>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {search
                  ? searchFilter.map((data, index) => (
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {data.title}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {data.webView}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {data.type}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="edit-delete">
                            <Tooltip title="Edit">
                              <Button onClick={() => handleEditClick(data)}>
                                <Edit />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Link">
                              <Button
                                onClick={() => handleLinkClick(data.link)}
                              >
                                Link
                              </Button>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <div>
                                <Button
                                  style={{
                                    color: "#1b3779",
                                  }}
                                  onClick={() => setDeleteModal(data._id, data)}
                                >
                                  <Delete />
                                </Button>
                                <DeleteModal
                                  deleteModal={deleteModal}
                                  handleDelete={handleDeleteone}
                                  id={data._id}
                                  setDeleteModal={setDeleteModal}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  : flashNews.map((data, index) => (
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {data.title}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {data.webView}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {data.type}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="edit-delete">
                            <Tooltip title="Edit">
                              <Button onClick={() => handleEditClick(data)}>
                                <Edit />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Link">
                              <Button
                                onClick={() => handleLinkClick(data.link)}
                              >
                                Link
                              </Button>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <div>
                                <Button
                                  style={{
                                    color: "#1b3779",
                                  }}
                                  onClick={() => setDeleteModal(data._id, data)}
                                >
                                  <Delete />
                                </Button>
                                <DeleteModal
                                  deleteModal={deleteModal}
                                  handleDelete={handleDeleteone}
                                  id={data._id}
                                  setDeleteModal={setDeleteModal}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
            {!flashNews.length && !search.trim() && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            )}
            {search.trim() && !searchFilter.length && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            )}
          </TableContainer>
        </Paper>
        <div className="add-icon">
          <AddSplashNews
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            editData={editData}
            getSplashNews={getSplashNews}
          />
        </div>
      </div>
    </div>
  );
};

export default SplashNews;
