import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';

// styles
import css from '../../../../styles/fees/FeeRemittanceViewDetails.module.css'
import dayjs from 'dayjs';

const FeeRemittanceViewDetails = (props) => {
    return(
        <div>
            <Dialog fullWidth maxWidth='sm' open={props.open} onClose={props.onClose}>
                <DialogTitle>Detailed Remittance</DialogTitle>
                <DialogContent>
                    <div className={css.dialogContainer}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{backgroundColor:"#1b3779"}}>
                                        <TableCell sx={{color:'white'}} align='center'>Date</TableCell>
                                        <TableCell sx={{color:'white'}} align='center'>Time</TableCell>
                                        <TableCell sx={{color:'white'}} align='center'>Total Amount</TableCell>
                                        <TableCell sx={{color:'white'}} align='center'>Deposit Amount</TableCell>
                                        <TableCell sx={{color:'white'}} align='center'>In Hand Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align='center'>{dayjs(props?.data?.createdAt).format('DD-MM-YYYY')}</TableCell>
                                        <TableCell align='center'>{dayjs(props?.data?.createdAt).format('HH:mm A')}</TableCell>
                                        <TableCell align='center'>{(props?.data?.cashDeposited) + (props?.data?.cashInHand)}</TableCell>
                                        <TableCell align='center'>{props?.data?.cashDeposited}</TableCell>
                                        <TableCell align='center'>{props?.data?.cashInHand}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className={css.imgContainer}>
                            <img src={props?.data?.challanImage?.link} alt="" srcset="" style={{
                                width:'350px',
                                height:'500px'
                            }} />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default FeeRemittanceViewDetails;