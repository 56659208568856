import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Fab, TextareaAutosize, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import { get, post } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Grid,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 600,
  bgcolor: "background.paper",
  p: 4,
};
const styles = {
  textfield: {
    width: "227px",
    label: { fontSize: 12 },
    m: 1,
  },
};

const AddEvent = (props) => {
  const [fromDate, setFromDate] = useState(dayjs("2022-04-06"));
  const [toDate, setToDate] = useState("2022-04-06");

  const [eventDetails, setEventDetails] = useState({});
  const [roles, setRoles] = useState([]);
  const [imageEvent,setImageEvent] = useState(null)

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setEventDetails({ ...eventDetails, [name]: value });
  };
  useEffect(() => {
    const getRole = async () => {
      try {
        const res = await get(urls.role.getAllRole);
        setRoles(res.data.data);
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    };
    getRole();
  }, []);

  useEffect(() => {
    if (props.updateEvent) {
      setEventDetails(props.updateEvent);
      setFromDate(props.updateEvent.fromDate);
      setToDate(props.updateEvent.toDate);
    }
  }, [props.updateEvent]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fileForm = new FormData();
    let img = undefined;
    if(imageEvent) {
      fileForm.append('files',imageEvent);
      img = await post(urls.fileUpload.postFileUpload,fileForm);
    }
    const data = {
      eventTitle: eventDetails.eventTitle,
      eventFor: eventDetails.eventFor,
      location: eventDetails.location,
      fromDate,
      toDate,
      status: eventDetails.status,
      hostedBy: eventDetails.hostedBy,
      note:eventDetails.note,
      shortEvent:eventDetails.shortEvent,
      video:eventDetails.video,
      webView:eventDetails.webView,
    }
    if(imageEvent) {
      data.image = img.data.result[0]._id
    }
    if (props.updateEvent) {
      props.handleUpdate(data,props.updateEvent._id
      );
    } else {
      props.handleSubmit(e, data);
    }
    setEventDetails("");
    setFromDate("");
    setToDate("");
    setImageEvent(null)
    props.getEvents();
    props.handleClose();
  };

  const handleCloseModal = () => {
    setEventDetails("");
    setFromDate("");
    setToDate("");
    setImageEvent(null)
    props.handleClose();
  };
  const handleClose = () => {
    setEventDetails("");
    setFromDate("");
    setToDate("");
    setImageEvent(null)
    props.handleClose();
  };
  return (
    <div>
      <Fab
        size="medium"
        style={{ background: "#1b3779", color: "#fff" }}
        aria-label="add"
        onClick={props.handleOpen}
      >
        <Add />
      </Fab>
      <Modal
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleSubmit}>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
            >
              Add Event
            </Typography>
            <div>
              <TextField
                required
                id="filled-required"
                variant="standard"
                label="Event Title"
                name="eventTitle"
                value={eventDetails.eventTitle || ''}
                onChange={(event) => handleOnChange(event)}
                sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
              />

              <FormControl variant="standard" sx={styles.textfield} required>
                <InputLabel
                  id="demo-simple-select-filled-label"
                  sx={{ fontSize: 12 }}
                >
                  <span style={{ fontSize: 12 }}>EventFor</span>
                </InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={eventDetails.eventFor || ''}
                  // defaultValue={props.employee && props.employee.role}
                  name="eventFor"
                  onChange={(event) => handleOnChange(event)}
                >
                  <MenuItem
                  key={'all'}
                  value={'all'}
                  sx={{ fontSize: 12, fontWeight: 500 }}
                  >
                    All
                  </MenuItem>
                  {roles &&
                    roles.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row.roleName}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.roleName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <TextField
                required
                id="filled-required"
                variant="standard"
                label="location"
                name="location"
                value={eventDetails.location || ''}
                onChange={(event) => handleOnChange(event)}
                sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From Date"
                  name="fromDate"
                  inputFormat="DD-MM-YYYY"
                  value={fromDate}
                  onChange={(newValue) => {
                    {
                      setFromDate(newValue);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      required
                      sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To Date"
                  name="toDate"
                  inputFormat="DD-MM-YYYY"
                  value={toDate}
                  onChange={(newValue) => {
                    {
                      setToDate(newValue);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      required
                      sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
              <TextField
                required
                id="filled-required"
                name="hostedBy"
                variant="standard"
                value={eventDetails.hostedBy}
                onChange={(event) => handleOnChange(event)}
                label="Hosted By"
                sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
              />
              <Grid container spacing={2}>
                <Grid item sm={12} md={4}>
                <div>
              <TextField
                
                id="filled-required"
                name="video"
                variant="standard"
                value={eventDetails.video}
                onChange={(event) => handleOnChange(event)}
                label="video"
                placeholder="only embedded video"
                sx={{ minWidth: 227, label: { fontSize: 12 },marginTop:"5px"}}
              />
              {/* <TextareaAutosize style={{
                width:'100%',
                height:'50px'
              }} 
              name='video'
              value={eventDetails.video || ''}
              label="video"
              placeholder="only embedded video"
              onChange={(event) => handleOnChange(event)}
              /> */}
            </div>
            </Grid>
            <Grid item sm={12} md={4}>
            <Button variant="outlined" component="label" style={{  marginTop:"9px"}}>
                Upload image
                <input onChange={(e) => setImageEvent(e.target.files[0])} type='file' accept="image/*" hidden />
              </Button> 
              <span
              style={{
                marginLeft:'10xp'
              

              }}
              >{imageEvent && imageEvent.name}</span>
              {!imageEvent && eventDetails.image && (<img style={{marginLeft:10}} src={`${eventDetails.image.link}`} alt="" height={50} width={50} />)}
           </Grid>
           <Grid item sm={12} md={4}>
           <FormControl variant="standard" sx={styles.textfield}>
                <InputLabel
                  id="demo-simple-select-filled-label"
                  sx={{ fontSize: 12 }}
                >
                  <span style={{ fontSize: 12 }}>Is View on Web? </span>
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  name="webView"
                  value={eventDetails.webView || ''}
                  onChange={(event) => handleOnChange(event)}
                >
                  <MenuItem
                    value={"yes"}
                    sx={{ fontSize: 12, fontWeight: 500 }}
                  >
                    Yes
                  </MenuItem>
                  <MenuItem
                    value={"no"}
                    sx={{ fontSize: 12, fontWeight: 500 }}
                  >
                    no
                  </MenuItem>
                </Select>
              </FormControl>
           
           </Grid>
              </Grid>
            
              
              
             

            </div>
            
            <div>
              <TextareaAutosize style={{
                width:'100%',
                height:'50px'
              }} 
              required
              name='shortEvent'
              value={eventDetails.shortEvent || ''}
              placeholder="Short event *"
              onChange={(event) => handleOnChange(event)}
              />
            </div>
            
            <div>
              <TextareaAutosize style={{
                width:'100%',
                height:'50px'
              }} 
              name='note'
              value={eventDetails.note || ''}
              placeholder="Note"
              onChange={(event) => handleOnChange(event)}
              />
            </div>

            <div className="cancel-submit-btn">
              <div>
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  size="medium"
                  type="button"
                  sx={{
                    m: 1,
                    color: "rgb(27, 55, 121)",
                    borderColor: "rgb(27, 55, 121)",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  type="submit"
                  sx={{ background: "rgb(27, 55, 121)" }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
};

export default AddEvent;
