import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Fab,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Delete } from "@material-ui/icons";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DirectionsTransitFilledIcon from "@mui/icons-material/DirectionsTransitFilled";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { get, post, put, del } from "../../services/apis";
import { urls } from "../../services/urlConstant";
import DeleteModal from "../Layout/Academic/DeleteModal";
import { Search } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 520,
  bgcolor: "background.paper",
  p: 4,
};
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const Vehicle = () => {
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState([]);
  const [list, setList] = React.useState([]);
  const [listRoute, setListRoute] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = useState(dayjs(Date.now()));
  const [dialog, setOpenDialog] = React.useState(false);
  const [dialogRoute, setOpenDialogRoute] = React.useState(false);
  const [editVehicle, setEditVehicle] = useState();
  const [addForm, setAddForm] = useState({});
  const [renewalDate, setRenewelDate] = useState();
  const [employees, setEmployees] = useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [showDetails, setShowDetails] = useState(null);

  useEffect(() => {
    getVehicle();
  }, []);

  useEffect(() => {
    const getDriver = async () => {
      try {
        const { data: role, status } = await get(`${urls.role.getAllRole}`, {
          params: {
            search: {
              roleName: "Driver",
            },
          },
        });
        if (status > 199 && status < 299) {
          const { data: employees, status } = await get(
            `${urls.employee.getAllEmployee}`,
            {
              params: {
                search: {
                  role: role.data[0]?._id,
                },
              },
            }
          );
          if (status > 199 && status < 299) {
            setEmployees(employees.data);
            console.log(employees.data, "driverr");
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getDriver();
  }, []);

  useEffect(() => {
    getVehicle();
  }, []);

  const getVehicle = async () => {
    console.log("lklklklklklklklk");
    try {
      const { data, status } = await get(urls.transport.getList);
      setList(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(`${urls.transport.deletVehicle}`, id);
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setEditVehicle(null);
    setAddForm({});
    setOpen(false);
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!editVehicle) {
        const { status } = await post(`${urls.transport.create}`, {
          vehicleNumber: addForm.vehicleNumber,
          vehicleModel: addForm.vehicleModel,
          driver: addForm.driver,
          vehicleLicense: addForm.vehicleLicense,
          vehicleContact: addForm.vehicleContact,
          trackingId: addForm.trackingId,
          insuranceName: addForm.insuranceName,
          insuranceId: addForm.insuranceId,
          renewalDate: renewalDate,
          totalPassenger: addForm.totalPassenger,
          note: addForm.note,
        });
        getVehicle();
        handleClose();
      } else {
        const { status } = await put(
          `${urls.transport.update}/${editVehicle}`,
          "",
          {
            vehicleNumber: addForm.vehicleNumber,
            vehicleModel: addForm.vehicleModel,
            driver: addForm.driver,
            vehicleLicense: addForm.vehicleLicense,
            vehicleContact: addForm.vehicleContact,
            trackingId: addForm.trackingId,
            insuranceName: addForm.insuranceName,
            insuranceId: addForm.insuranceId,
            renewalDate: renewalDate,
            totalPassenger: addForm.totalPassenger,
            note: addForm.note,
          }
        );
        getVehicle();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const styles = {
    button: {
      backgroundColor: "orange",
    },
    textfield: {
      marginTop: "25px",
      width: "70%",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
    tab: {
      width: "100%",
      borderCollapse: "collapse",
    },
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      list.length > 0 &&
        setSearchFilter(
          list.filter((ele) =>
            ele.vehicleNumber.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenDialog = (vehicle) => {
    setShowDetails(vehicle);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setShowDetails(null);
    setOpenDialog(false);
  };

  const handleClickOpenDialogRoute = () => {
    setOpenDialogRoute(true);
  };
  const handleCloseDialogRoute = () => {
    setOpenDialogRoute(false);
  };

  const handleClassMapEdit = (id, data) => {
    setEditVehicle(id);
    setAddForm({
      vehicleNumber: data.vehicleNumber,
      driver: data.driver_id,
      totalPassenger: data.totalPassenger,
      trackingId: data.trackingId,
      vehicleModel: data.vehicleModel,
      insuranceName: data.insuranceName,
      renewalDate: data.renewalDate,
      insuranceId: data.insuranceId,
      vehicleContact: data.vehicleContact,
      vehicleLicense: data.vehicleLicense,
    });
    handleClickOpen();
  };

  return (
    <div>
      <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span
              style={{
                color: "red",
                fontFamily: "cursive",
                textAlign: "start",
              }}
            >
              Vehicle:
            </span>
          </Grid>
        </Grid>
      </Card>
      <div style={{ margin: "15px", overflow: "hidden" }}>
        <Paper sx={{ width: "100%", height: "60px", overflow: "hidden" }}>
          <div
            style={{
              paddingTop: "22px",
            }}
          >
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid xs={3}>
                <TextField
                  size="small"
                  value={search}
                  onChange={handleSearch}
                  label="Search"
                  variant="outlined"
                  placeholder="enter vehicle number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </Paper>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form className="form-tag" onSubmit={handleAddSubmit}>
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign="center"
                marginBottom="30px"
                // onChange={handleAddForm}
              >
                {editVehicle ? "update vehicle" : "Add Vehicle"}
              </Typography>
              <div>
                <TextField
                  id="filled-required"
                  variant="standard"
                  label="Vehicle Number"
                  name="vehicleNumber"
                  value={addForm.vehicleNumber || ""}
                  onChange={handleAddForm}
                  sx={{
                    m: 1,
                    minWidth: 227,
                    label: { fontSize: 12 },
                    label: { fontSize: 12 },
                  }}
                />
                <TextField
                  id="filled-required"
                  variant="standard"
                  sx={{ m: 1, minWidth: 227 }}
                  label="Vehicle Model"
                  name="vehicleModel"
                  value={addForm.vehicleModel || ""}
                  onChange={handleAddForm}
                />

                <FormControl variant="standard" sx={{ m: 0.3, minWidth: 227 }}>
                  <InputLabel sx={{ fontSize: 8 }}>Driver</InputLabel>
                  <Select
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    name="driver"
                    value={addForm.driver || ""}
                    onChange={handleAddForm}
                  >
                    {employees.map((employee) => (
                      <MenuItem key={employee._id} value={employee._id}>
                        {employee.basicInfo.empName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div>
                  <TextField
                    id="filled-required"
                    variant="standard"
                    sx={{ m: 1, minWidth: 227 }}
                    label="Vehicle License"
                    name="vehicleLicense"
                    onChange={handleAddForm}
                    value={addForm.vehicleLicense || ""}
                  />

                  <TextField
                    id="filled-required"
                    variant="standard"
                    label="Vehicle Contact"
                    name="vehicleContact"
                    value={addForm.vehicleContact || ""}
                    onChange={handleAddForm}
                    sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                  />

                  <TextField
                    id="filled-required"
                    variant="standard"
                    label="Tracking ID"
                    name="trackingId"
                    value={addForm.trackingId || ""}
                    onChange={handleAddForm}
                    sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                  />
                </div>

                <div>
                  <TextField
                    id="filled-required"
                    variant="standard"
                    sx={{ m: 1, minWidth: 227 }}
                    label="Insurence Name"
                    name="insuranceName"
                    onChange={handleAddForm}
                    value={addForm.insuranceName || ""}
                  />

                  <TextField
                    id="filled-required"
                    variant="standard"
                    label="Insurence ID"
                    name="insuranceId"
                    value={addForm.insuranceId || ""}
                    onChange={handleAddForm}
                    sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                  />
                  <TextField
                    id="filled-required"
                    variant="standard"
                    sx={{ m: 1, minWidth: 227 }}
                    label="Total Seats"
                    name="totalPassenger"
                    onChange={handleAddForm}
                    value={addForm.totalPassenger || ""}
                  />
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date"
                    variant="standard"
                    value={renewalDate}
                    onChange={(newDate) => setRenewelDate(newDate)}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        sx={{
                          m: 1.2,

                          label: { fontSize: 12 },
                        }}
                        {...params}
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>

                <div>
                  <TextareaAutosize
                    placeholder="Drop a note"
                    name="note"
                    value={addForm.note || ""}
                    onChange={handleAddForm}
                    style={{
                      width: 712,
                      maxHeight: 110,
                      margin: 10,
                      padding: 10,
                      height: 118,
                      overflow: "auto",
                    }}
                  />
                </div>
                <div style={{ textAlign: "end" }}>
                  <Tooltip title="Cancel">
                    <Button
                      onClick={handleClose}
                      variant="outlined"
                      size="medium"
                      type="button"
                      sx={{ m: 1 }}
                    >
                      Cancel
                    </Button>
                  </Tooltip>
                  <Tooltip title="Submit">
                    <Button
                      variant="contained"
                      size="medium"
                      type="submit"
                      sx={{
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      {editVehicle ? "update" : "Add"}
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </Box>
          </form>
        </Modal>

        <Table>
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow sx={{ color: "white" }}>
              <TableCell sx={{ color: "white" }} align="center">
                #SL
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                vehicle Number
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Driver
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Contact
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Total passengers
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Tracking ID
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Vehicle Model
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Insurance Name
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Renewal Date
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {search
              ? searchFilter.map((vehicle, index) => (
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">
                      {vehicle.vehicleNumber}
                    </TableCell>
                    <TableCell align="center">
                      {vehicle.driver.basicInfo.empName}
                    </TableCell>
                    <TableCell align="center">
                      {vehicle.vehicleContact}
                    </TableCell>
                    <TableCell align="center">
                      {vehicle.totalPassenger}
                    </TableCell>
                    <TableCell align="center">{vehicle.trackingId}</TableCell>
                    <TableCell align="center">{vehicle.vehicleModel}</TableCell>
                    <TableCell align="center">
                      {vehicle.insuranceName}
                    </TableCell>
                    <TableCell align="center">
                      {dayjs(vehicle.renewalDate).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          style={{ color: "#1b3779" }}
                          onClick={() =>
                            handleClassMapEdit(vehicle._id, vehicle)
                          }
                        >
                          <EditIcon />
                        </Button>
                        <Button
                          style={{ color: "#1b3779" }}
                          onClick={() => handleClickOpenDialog(vehicle)}
                        >
                          <VisibilityIcon />
                        </Button>
                        <Button
                          style={{
                            color: "#1b3779",
                          }}
                          onClick={() => setDeleteModal(vehicle._id, vehicle)}
                        >
                          <Delete />
                        </Button>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={vehicle._id}
                          setDeleteModal={setDeleteModal}
                        />
                        <Button
                          style={{
                            color: "#1b3779",
                          }}
                          onClick={() => handleClickOpenDialogRoute()}
                        >
                          <DirectionsTransitFilledIcon />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              : list.map((vehicle, index) => (
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">
                      {vehicle.vehicleNumber}
                    </TableCell>
                    <TableCell align="center">
                      {vehicle.driver.basicInfo.empName}
                    </TableCell>
                    <TableCell align="center">
                      {vehicle.vehicleContact}
                    </TableCell>
                    <TableCell align="center">
                      {vehicle.totalPassenger}
                    </TableCell>
                    <TableCell align="center">{vehicle.trackingId}</TableCell>
                    <TableCell align="center">{vehicle.vehicleModel}</TableCell>
                    <TableCell align="center">
                      {vehicle.insuranceName}
                    </TableCell>
                    <TableCell align="center">
                      {dayjs(vehicle.renewalDate).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        className="edit-delete"
                      >
                        <Button
                          onClick={() =>
                            handleClassMapEdit(vehicle._id, vehicle)
                          }
                        >
                          <EditIcon />
                        </Button>
                        <Button onClick={() => handleClickOpenDialog(vehicle)}>
                          <VisibilityIcon />
                        </Button>
                        <Button
                          style={{ color: "#1b3779", fontSize: "5px" }}
                          onClick={() => setDeleteModal(vehicle._id, vehicle)}
                        >
                          <Delete />
                        </Button>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={vehicle._id}
                          setDeleteModal={setDeleteModal}
                        />
                        <Button onClick={() => handleClickOpenDialogRoute()}>
                          <DirectionsTransitFilledIcon />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
        {!list.length && !search.trim() && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        {search.trim() && !searchFilter.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        <div className="add-icon">
          <Fab
            variant="contained"
            style={{ color: "#ffff", backgroundColor: "#1b3779" }}
            onClick={handleClickOpen}
          >
            <AddIcon />
          </Fab>
        </div>
        <BootstrapDialog
          onClose={handleCloseDialog}
          aria-labelledby="customized-dialog-title"
          open={dialog}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: "#1b3779", color: "white" }}
          >
            Vehicle Information
          </BootstrapDialogTitle>

          <DialogContent dividers>
            <table className="hostel" style={styles.tab}>
              <tbody>
                {showDetails ? (
                  <div>
                    <tr>
                      <td>School Name</td>
                      <td>Excellent English Medium Primary & High School</td>
                    </tr>
                    <tr>
                      <td>Vehicle Number</td>
                      <td>{showDetails.vehicleNumber}</td>
                    </tr>
                    <tr>
                      <td>Vehicle Modal</td>
                      <td>{showDetails.vehicleModel}</td>
                    </tr>
                    <tr>
                      <td>Driver</td>
                      {/* <td>{showDetails.driver.basicInfo.empName}</td> */}
                      <td></td>
                    </tr>
                    <tr>
                      <td>Vehicle License</td>
                      <td>{showDetails.vehicleLicense}</td>
                    </tr>
                    <tr>
                      <td>Vehicle Contact</td>
                      <td>{showDetails.vehicleContact}</td>
                    </tr>
                    <tr>
                      <td>Note</td>
                      <td>{showDetails.note}</td>
                    </tr>
                    <tr>
                      <td>Created</td>
                      <td>
                        {dayjs(showDetails.createdAt).format("DD-MM-YYYY")}
                      </td>
                    </tr>
                  </div>
                ) : null}
              </tbody>
            </table>
          </DialogContent>
        </BootstrapDialog>

        <BootstrapDialog
          onClose={handleCloseDialogRoute}
          aria-labelledby="customized-dialog-title"
          open={dialogRoute}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDialogRoute}
            style={{ backgroundColor: "#1b3779", color: "white" }}
          >
            Vehicle Route Information
          </BootstrapDialogTitle>

          <DialogContent dividers>
            <table className="hostel" style={styles.tab}>
              <tbody>
                <div>
                  <tr>
                    <td>School Name</td>
                    <td>Excellent English Medium Primary & High School</td>
                  </tr>
                  <tr>
                    <td>Transport Route Title</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Vehicle Start</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Vehicle End</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Route Stop Fare</td>
                    <td>
                      <tr
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <th>Stop Name</th>
                        <th>Stop KM</th>
                        <th>Pick Time</th>
                        <th>Drop Time</th>
                      </tr>
                    </td>
                  </tr>
                </div>
              </tbody>
            </table>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </div>
  );
};

export default Vehicle;
