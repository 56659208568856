import * as React from "react";
import Grid from "@mui/material/Grid";
import "../../styles/components/SchoolCard/SchoolCard.scss";
import { Link } from "react-router-dom";

export default function SchoolCard() {
  return (
    <div>
      <div className="quick-link-title">
        <div>
          <h1>
            <span style={{ color: "#f07b47" }}>Quick</span> link
            <div className="all-line-2">
              <div className="first-line"></div>
              <div className="middle-line"></div>
              <div className="first-line last-line"></div>
            </div>
          </h1>
        </div>
      </div>

      <div className="card-items">
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Link to={'/to-the-knowledge-of-parents'}><img src="d1.webp" alt="" /></Link>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Link to={'/unique-features'}><img src="d2.webp" alt="" /></Link>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Link to={'/results'}><img src="d3.webp" alt="" /></Link>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Link to={'/admitstudent'}><img src="d4.webp" alt="" /></Link>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
