import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { get, post, put } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import { useEffect } from "react";
import dayjs from "dayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import EmployeeAttendanceReport from "./EmployeeAttendanceReport";
import { Diversity1Rounded } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
const styles = {
  card: {
    padding: 10,
    margin: "0px 0px 20px 0px",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
    m: 1,
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
    marginTop: "10px",
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EmployeeAttendance = () => {
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState(0);
  const [formData, setFormData] = useState({});
  const [date, setDate] = useState(dayjs(Date.now()));
  // const [studentsAttendence, setStudentsAttendence] = useState([]);
  const [roles, setRoles] = useState([]);
  const [employeeAttendances, setEmployeeAttendances] = useState([]);

  const [sort, setSort] = useState({
    empName: "asc",
  });

  const sortByName = () => {
    console.log("Name");
    if (sort.empName === "asc") {
      let sortedList = [
        ...employeeAttendances.sort((a, b) =>
          b.attendance.employee.basicInfo.empName.localeCompare(
            a.attendance.employee.basicInfo.empName
          )
        ),
      ];

      setEmployeeAttendances(sortedList);
      setSort({ ...sort, empName: "des" });
    } else {
      let sortedList = [
        ...employeeAttendances.sort((a, b) =>
          a.attendance.employee.basicInfo.empName.localeCompare(
            b.attendance.employee.basicInfo.empName
          )
        ),
      ];
      setEmployeeAttendances(sortedList);
      setSort({ ...sort, empName: "asc" });
    }
  };

  useEffect(() => {
    const getRoles = async () => {
      try {
        const rolesRes = await get(urls.role.getAllRole);
        setRoles(rolesRes.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getRoles();
  }, []);

  const handleSections = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEmployeeAttendanceFindSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = {
        date: date.format("YYYY-MM-DD"),
        role: formData.role,
      };
      const employeeAttendanceRes = await post(`/emp-att-test`, data);
      if (employeeAttendanceRes.data.success) {
        setEmployeeAttendances(employeeAttendanceRes.data.data);
        console.log("setEmployeeAttendances", employeeAttendanceRes.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAttendanceStatusChange = (e, id) => {
    const status = e.target.value;
    setEmployeeAttendances(
      employeeAttendances.map((empAttend) => {
        if (empAttend._id === id) {
          return {
            ...empAttend,
            attendanceStatus: status,
          };
        } else {
          return empAttend;
        }
      })
    );
  };
  const handleUpdateEmployeeAttendance = async () => {
    setLoading(true);
    try {
      const updateStudAttendance = await put(`/employee-attendance-bulk`, "", {
        attendances: employeeAttendances,
      });
      console.log(updateStudAttendance);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };
  return (
    <div>
      <div style={{ margin: 15 }}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Attendance" {...a11yProps(0)} />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <Card style={styles.card}>
              <Box>
                <form onSubmit={handleEmployeeAttendanceFindSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={5}>
                      <FormControl
                        variant="standard"
                        sx={styles.textfield}
                        required
                      >
                        <InputLabel
                          id="demo-simple-select-filled-label"
                          sx={{ fontSize: 12 }}
                        >
                          Roles
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          name="role"
                          value={formData.role || ""}
                          onChange={(event) => handleSections(event)}
                        >
                          {roles.length > 0 &&
                            roles.map((row, index) => (
                              <MenuItem
                                key={row._id}
                                value={row._id}
                                sx={{ fontSize: 12, fontWeight: 500 }}
                              >
                                {row.roleName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={5}
                      sx={{ alignSelf: "center" }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Date"
                          value={date}
                          inputFormat="DD-MM-YYYY"
                          onChange={(newValue) => {
                            setDate(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              style={styles.textfield}
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={1}
                      sx={{
                        alignSelf: "center",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Tooltip title="Find">
                        <Button
                          variant="contained"
                          style={styles.Button}
                          size="small"
                          type="submit"
                        >
                          Find
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Card>
            <div>
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead style={{ background: "rgb(27 55 121)" }}>
                      <TableRow>
                        <TableCell align="center">
                          <span className="class-table-header">#SL</span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="class-table-header">
                            Employee Name
                          </span>
                          <span
                            onClick={sortByName}
                            style={{ cursor: "pointer", color: "#fff" }}
                          >
                            {sort.empName === "asc" ? "▲" : "▼"}
                          </span>
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <div align="center">
                              <span className="class-table-header">
                                Present All
                              </span>
                            </div>
                            <div align="center">
                              <span className="class-table-header">
                                Absent All
                              </span>
                            </div>
                            <div align="center">
                              <span className="class-table-header">Late</span>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {employeeAttendances.length > 0 &&
                        employeeAttendances.map((attendance, index) => (
                          <TableRow
                            key={attendance._id}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell
                              align="center"
                              style={{ textTransform: "capitalize" }}
                            >
                              {attendance.employee.basicInfo.empName}
                            </TableCell>
                            <TableCell>
                              <RadioGroup
                                style={{ justifyContent: "space-around" }}
                                value={attendance.attendanceStatus}
                                defaultValue={attendance.attendanceStatus}
                                onChange={(e) =>
                                  handleAttendanceStatusChange(
                                    e,
                                    attendance._id
                                  )
                                }
                                row
                              >
                                <div>
                                  <div
                                    align="center"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    <FormControlLabel
                                      style={{
                                        position: "relative",
                                        left: "22px",
                                      }}
                                      value="present"
                                      control={<Radio />}
                                    />
                                  </div>
                                </div>
                                <div>
                                  <div
                                    align="center"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    <FormControlLabel
                                      style={{
                                        position: "relative",
                                        left: "34px",
                                      }}
                                      value="absent"
                                      control={<Radio />}
                                    />
                                  </div>
                                </div>
                                <div>
                                  <div
                                    align="center"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    <FormControlLabel
                                      style={{
                                        position: "relative",
                                        left: "30px",
                                      }}
                                      value="late"
                                      control={<Radio />}
                                    />
                                  </div>
                                </div>
                              </RadioGroup>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {!employeeAttendances.length && (
                  <Typography
                    variant="h6"
                    sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
                  >
                    No data found
                  </Typography>
                )}
              </Paper>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Tooltip title="Submit">
                  <LoadingButton
                    size="small"
                    variant="contained"
                    onClick={handleUpdateEmployeeAttendance}
                    loading={loading}
                    sx={{
                      backgroundColor: "#1b3779",
                      ":hover": { backgroundColor: "#1b3779" },
                    }}
                  >
                    Submit
                  </LoadingButton>
                </Tooltip>
              </div>
            </div>
          </TabPanel>
        </Box>
      </div>
    </div>
  );
};

export default EmployeeAttendance;
