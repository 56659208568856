import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
} from "@mui/material";
import "../../../styles/components/AcademicYear/DateModal.scss";
import { Add } from "@mui/icons-material";
import { useState, useEffect } from "react";
import { urls } from "../../../services/urlConstant";
import { get } from "../../../services/apis";
import set from "date-fns/set/index.js";
import { objHas } from "../../../utils/utils";
import dayjs from "dayjs"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 400,
  bgcolor: "background.paper",
  p: 4,
};

const AddClassRoutine = (props) => {
  const [routineClass, setRoutineClass] = useState("");
  const [section, setSection] = useState("");
  const [subject, setSubject] = useState("");
  const [teacher, setTeacher] = useState("");
  const [day, setDay] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [roomNo, setRoomNo] = useState("");
  const [classes, setClasses] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [sections, setSections] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [classRoutineDetails, setClassRoutineDetailes] = useState({});
  const handleClasses = async (e) => {
    try {
      const { name, value } = e.target;
      setClassRoutineDetailes({ ...classRoutineDetails, [name]: value });
      const res = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: value,
          },
        },
      });
      setSections(res.data.data);
      const response = await get(urls.schoolSubject.getAllSubject, {
        params: {
          search: {
            class: value,
          },
        },
      });
      setSubjects(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        setClasses(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);
  useEffect(() => {
    const getEmployees = async () => {
      try {
        const res = await get(urls.employee.getAllEmployee);
        setEmployees(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getEmployees();
  }, []);
  useEffect(() => {
    if (props.updateClassRoutine) {
      const data = {
        className: props.updateClassRoutine.class._id,
        sectionName: props.updateClassRoutine.section._id,
        subjectName: props.updateClassRoutine.subject._id,
        teacher: props.updateClassRoutine.teacher,
        day: props.updateClassRoutine.day,
        startTime:dayjs(props.updateClassRoutine.startTime,'HH:mm').format('HH:mm'),
        endTime: dayjs(props.updateClassRoutine.endTime,'HH:mm').format('HH:mm'),
        roomNo: props.updateClassRoutine.roomNo,
      };
      setClassRoutineDetailes(data)
      const getSectionsAndSubject = async () => {
        try {
          const sections = await get(urls.schoolSection.getAllSection,{
            params:{
              search:{
                class:props.updateClassRoutine.class._id
              }
            }
          })
          setSections(sections.data.data);
          const subject = await get(urls.schoolSubject.getAllSubject,{
            params:{
              search:{
                class:props.updateClassRoutine.class._id
              }
            }
          })
          setSubjects(subject.data.data)
        } catch (error) {
          console.log(error)
        }
      }
      getSectionsAndSubject();
    }
  }, [props.updateClassRoutine]);

  const handleOnchange = (e,) => {
    const { name, value } = e.target;
    console.log(value)
    setClassRoutineDetailes({ ...classRoutineDetails, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (props.updateClassRoutine) {
      console.log("test ",classRoutineDetails.startTime);
      props.handleUpdate(
        {
          class: classRoutineDetails.className,
          section: classRoutineDetails.sectionName,
          subject: classRoutineDetails.subjectName,
          teacher: classRoutineDetails.teacher,
          day: classRoutineDetails.day,
          startTime: classRoutineDetails.startTime,
          endTime:classRoutineDetails.endTime,
          roomNo: classRoutineDetails.roomNo,
        },
        props.updateClassRoutine._id,
        props.updateClassRoutine.dayIndex
      );
    } else {
      props.handleSubmit(e, {
        class: classRoutineDetails.className,
        section: classRoutineDetails.sectionName,
        subject: classRoutineDetails.subjectName,
        teacher: classRoutineDetails.teacher,
        day: classRoutineDetails.day,
        startTime: classRoutineDetails.startTime,
        endTime: classRoutineDetails.endTime,
        roomNo: classRoutineDetails.roomNo,
      });
    }
    setRoutineClass("");
    setSection("");
    setSubject("");
    setTeacher("");
    setDay("");
    setStartTime("");
    setEndTime("");
    setRoomNo("");
    setClassRoutineDetailes({})
    props.handleClose();
  };

  const handleCloseModal = () => {
    setRoutineClass("");
    setSection("");
    setSubject("");
    setTeacher("");
    setDay("");
    setStartTime("");
    setEndTime("");
    setRoomNo("");
    setClassRoutineDetailes({})

    props.handleClose();
  };

  const handleClose = () => {
    setRoutineClass("");
    setSection("");
    setSubject("");
    setTeacher("");
    setDay("");
    setStartTime("");
    setEndTime("");
    setRoomNo("");
    setClassRoutineDetailes({})
    props.handleClose();
  };
  return (
    <div>
      <Fab
        size="medium"
        style={{ background: "#1b3779", color: "#fff" }}
        aria-label="add"
        onClick={props.handleOpen}
      >
        <Add />
      </Fab>
      <Modal
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleSubmit}>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
              marginBottom="30px"
            >
              Add Class Routine
            </Typography>
            <div>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 227 }}>
                <InputLabel sx={{ fontSize: 12 }}>Class</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  name="className"
                  value={classRoutineDetails.className || ""}
                  onChange={(event) => handleClasses(event)}
                  defaultValue={props.updateClassRoutine && props.updateClassRoutine.class && props.updateClassRoutine.class._id}
                >
                  {classes &&
                    classes.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.className}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 227 }}>
                <InputLabel sx={{ fontSize: 12 }}>Section</InputLabel>
                <Select
                  id="demo-simple-select-filled"
                  name="sectionName"
                  value={classRoutineDetails.sectionName || ""}
                  onChange={(event) => handleOnchange(event)}
                  defaultValue={props.updateClassRoutine && props.updateClassRoutine.section && props.updateClassRoutine.section._id}
                >
                  {sections.length>0 &&
                    sections.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.sectionName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 227 }}>
                <InputLabel sx={{ fontSize: 12 }}>Subject</InputLabel>
                <Select
                  id="demo-simple-select-filled"
                  name="subjectName"
                  value={classRoutineDetails.subjectName}
                  onChange={(event) => handleOnchange(event)}
                  defaultValue={objHas(props,'updateClassRoutine.subject._id',"")}
                >
                  {subjects &&
                    subjects.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.subjectName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 227 }}>
                <InputLabel sx={{ fontSize: 12 }}>Day</InputLabel>
                <Select
                  id="demo-simple-select-filled"
                  name="day"
                  value={classRoutineDetails.day}
                  onChange={(event) => handleOnchange(event)}
                  defaultValue={objHas(props,'updateClassRoutine.day',"")}
                >
                  <MenuItem
                    value={"monday"}
                    style={{ fontSize: 12, fontWeight: 500 }}
                  >
                    Monday
                  </MenuItem>
                  <MenuItem
                    value={"tuesday"}
                    style={{ fontSize: 12, fontWeight: 500 }}
                  >
                    Tuesday
                  </MenuItem>
                  <MenuItem
                    value={"wednesday"}
                    style={{ fontSize: 12, fontWeight: 500 }}
                  >
                    Wednesday
                  </MenuItem>
                  <MenuItem
                    value={"thursday"}
                    style={{ fontSize: 12, fontWeight: 500 }}
                  >
                    Thursday
                  </MenuItem>
                  <MenuItem
                    value={"friday"}
                    style={{ fontSize: 12, fontWeight: 500 }}
                  >
                    Friday
                  </MenuItem>
                  <MenuItem
                    value={"saturday"}
                    style={{ fontSize: 12, fontWeight: 500 }}
                  >
                    Saturday
                  </MenuItem>
                  <MenuItem
                    value={"sunday"}
                    style={{ fontSize: 12, fontWeight: 500 }}
                  >
                    Sunday
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 227 }}>
                <InputLabel sx={{ fontSize: 12 }}>Teacher</InputLabel>
                <Select
                  id="demo-simple-select-filled"
                  name="teacher"
                  value={classRoutineDetails.teacher}
                  onChange={(event) => handleOnchange(event)}
                  defaultValue={objHas(props,'updateClassRoutine.teacher',"")}

                >
                  {employees &&
                    employees.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.basicInfo.empName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                type="time"
                variant="standard"
                name="startTime"
                label="Start Time"
                value={classRoutineDetails.startTime}
                onChange={(event) => handleOnchange(event)}
                sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
              />
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
                label="End Time"
                type="time"
                name="endTime"
                value={classRoutineDetails.endTime}
                onChange={(event) => handleOnchange(event)}
                sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
              />
              <TextField
                variant="standard"
                label="Room No"
                name="roomNo"
                value={classRoutineDetails.roomNo}
                onChange={(event) => handleOnchange(event)}
                sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
              />
            </div>

            <div className="cancel-submit-btn">
              <div>
                <Button
                  variant="outlined"
                  size="medium"
                  type="button"
                  sx={{ m: 1 }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  type="submit"
                  sx={{ background: "rgb(27, 55, 121)" }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
};

export default AddClassRoutine;
