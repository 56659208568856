import React from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

const PublicRoute = ({ children }) => {
  const isAuth = window.localStorage.getItem("token");

  const navDashboard = () => {
    toast.warning("Already login");
    return <Navigate to="/dashboard" />;
  };
  return isAuth ? navDashboard() : children;
};

export default PublicRoute;
