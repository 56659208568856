import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "./../styles/components/navbar/Navbar.scss";
import { Language } from "@mui/icons-material";
import "./../styles/components/navbar/NavTop.scss";
import css from "./Addmission.module.css";
import Footer from "./Footer/Footer";
import TopNav from "./navbar/TopNav";
import Navbar from "./navbar/Navbar";
import scanner from "../assets/Images/MicrosoftTeams-image (33).png";

const ContactUs = () => {
  // const [addClass, updateClass] = useState(false);
  // const [stkyClass, setStkyClass] = useState(false);
  // const [mega, setMega] = useState(false);

  // const handleClickCross = () => {
  //   updateClass(false);
  //   window.scrollTo(0, 0);
  // };
  // const scrollHandleScroll = () => {
  //   if (window.scrollY > 20) {
  //     setStkyClass(true);
  //   } else {
  //     setStkyClass(false);
  //   }
  // };
  // const handleMega = () => {
  //   setMega(!mega);
  // };
  // useEffect(() => {
  //   window.addEventListener("scroll", scrollHandleScroll);
  // }, []);
  const defaultProps = {
    center: {
      lat: 16.837211,
      lng: 75.705505,
    },
    zoom: 20,
  };

  return (
    <>
      <TopNav />
      <Navbar />

      <div className={css.headerContainer}>
        <div>
          <h1>Contact Us</h1>
          <h5>
            <Link
              style={{
                color: "white",
              }}
              to="/"
            >
              Home
            </Link>{" "}
            / Contact Us
          </h5>
        </div>
      </div>

      <Grid container spacing={12} marginLeft={"-76px"}>
        <Grid items xs={12} mg={12} lg={6}>
          <h5 style={{ margin: "65px 10px -5px 24px", fontWeight: "700" }}>
            Address
          </h5>
          <p style={{ margin: "10px 10px 20px 24px", fontSize: "0.95rem" }}>
            VECAS Associations (R)<br></br>
            EXCELLENT ENGLISH MEDIUM PRIMARY AND HIGH SCHOOL,<br></br>
            DARGA ROAD, ITTANGIHAL,<br></br>
            VIJAYPUR - 586104
          </p>
          <h5 style={{ margin: "5px 10px -5px 24px", fontWeight: "700" }}>
            Contact
          </h5>
          <p style={{ margin: "10px 10px 20px 24px", fontSize: "0.95rem" }}>
            +91 8884446691
          </p>
          <h5 style={{ margin: "5px 10px -5px 24px", fontWeight: "700" }}>
            Email
          </h5>
          <p style={{ margin: "10px 10px 20px 24px", fontSize: "0.95rem" }}>
            excellentschool2008@gmail.com
          </p>

          {/* <h5 style={{ margin: "5px 10px -5px 24px", fontWeight: "700" }}>
            Scan to get directions
          </h5>
          <div style={{ margin: "10px 10px -5px 24px" }}>
            {" "}
            <img src={scanner} height={180} width={200} />
          </div> */}
        </Grid>
        <Grid items xs={12} mg={8} lg={6}>
          <div style={{ height: "450px", width: "650px" }}>
            <iframe
              style={{ padding: "30px 0px" }}
              width="100%"
              height="100%"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=RPP4+V6J, DARGA ROAD, ITTANGIHAL, Vijayapura, Karnataka 586104&t=&z=18&ie=UTF8&iwloc=&output=embed"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            ></iframe>
          </div>
        </Grid>
      </Grid>

      <Footer />
    </>
  );
};

export default ContactUs;
