import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import { format } from "date-fns";
import "react-calendar/dist/Calendar.css";
import "../../styles/Calendar/Calendar.scss";
import CircleIcon from "@mui/icons-material/Circle";
import { green, orange, red } from "@mui/material/colors";
import { get } from "../../services/apis";
const StudentAttendence = () => {
  const [attendanceData, setAttendanceData] = useState([
    // { date: "2023-05-01", status: "present" },
    // { date: "2023-05-10", status: "absent" },
    // { date: "2023-05-20", status: "present" },
    // { date: "2023-05-13", status: "absent" },
    // { date: "2023-05-23", status: "present" },
    // { date: "2023-05-26", status: "absent" },
    // { date: "2023-05-27", status: "halfday" },
    // { date: "2023-05-11", status: "halfday" },
    // { date: "2023-06-03", status: "present" },
    // { date: "2023-06-02", status: "holiday" },
  ]);
  useEffect(() => {
    const getAttendence = async () => {
      try {
        const response = await get(`/student-dashboard/attendance/list`, {
          params: {
            studentId: "6399d75729fa86b424ce3957",
          },
        });
        setAttendanceData([...response.data.data]);
        console.log([...response.data.data], "0000000000000");
      } catch (error) {
        console.log(error);
      }
    };
    getAttendence();
  }, []);
  const renderCell = ({ date }) => {
    const dateKey = format(date, "yyyy-MM-dd");
    const attendance = attendanceData.find((data) => data.date === dateKey);
    // if (!attendance) {
    //   return null;
    // }

    let cellClassName = "";
    if (attendance.status === "present") {
      cellClassName = "present";
    } else if (attendance.status === "absent") {
      cellClassName = "absent";
    } else if (attendance.status === "halfday") {
      cellClassName = "halfday";
    } else if (attendance.status === "holiday") {
      cellClassName = "holiday";
    }
    return <div className={`cell ${cellClassName}`}></div>;
  };

  const tileContent = ({ date }) => {
    const dateKey = format(date, "yyyy-MM-dd");
    const attendance = attendanceData.find((data) => data.date === dateKey);
    if (attendance) {
      return renderCell({ date });
    }
    return null;
  };

  const tileClassName = ({ date }) => {
    const currentDate = new Date();
    const isSaturday = date.getDay() === 6;
    if (date.toDateString() === currentDate.toDateString()) {
      return "current-date";
    }
    if (isSaturday) {
      return "saturday-date";
    }
    return "";
  };

  return (
    <div className="AppStudent">
      <Calendar tileContent={tileContent} tileClassName={tileClassName} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "5px",
        }}
      >
        <div className="colorBotton">
          <CircleIcon sx={{ fontSize: 10, color: "green" }} />
          <p style={{ marginTop: "12px" }}>Present</p>
        </div>
        <div className="colorBotton">
          <CircleIcon sx={{ fontSize: 10, color: "red" }} />
          <p style={{ marginTop: "12px" }}>Absent</p>
        </div>
        <div className="colorBotton">
          <CircleIcon sx={{ fontSize: 10, color: "orangered" }} />
          <p style={{ marginTop: "12px" }}>Half Day</p>
        </div>
        <div className="colorBotton">
          <CircleIcon sx={{ fontSize: 10, color: "orange" }} />
          <p style={{ marginTop: "12px" }}>Holiday</p>
        </div>
      </div>
    </div>
  );
};
export default StudentAttendence;
