import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Button,
  Grid,
  Tooltip,
} from "@mui/material";
import "../../../styles/components/AcademicYear/DateModal.scss";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import { get } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 400,
  bgcolor: "background.paper",
  p: 4,
};

const AddNotice = (props) => {
  const [title, setTitle] = useState("");
  const [date, setDate] = useState(dayjs(12/12/2001).format("DD/MM/YYYY"));
  const [noticeFor, setNoticeFor] = useState("");
  const [roles, setRoles] = useState([]);
  const [notice, setNotice] = useState("");
  const [webView, setWebView] = useState("");

  useEffect(() => {
    if (props.updateNotice) {
      setTitle(props.updateNotice.title);
      setDate(dayjs(props.updateNotice.date).format('YYYY-MM-DD'));
      setNoticeFor(props.updateNotice.noticeFor);
      setNotice(props.updateNotice.notice);
      setWebView(props.updateNotice.webView);
    }
  }, [props.updateNotice]);

  useEffect(() => {
    const getRoles = async () => {
      try {
        const res = await get(urls.role.getAllRole);
        console.log(res);
        setRoles(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getRoles();
  }, []);
  const handleTitle = (e) => {
    setTitle(e.target.value);
  };
  const handleDate = (e) => {
    setDate(e.target.value);
  };
  const handleNoticeFor = (e) => {
    setNoticeFor(e.target.value);
  };
  const handleNotice = (e) => {
    setNotice(e.target.value);
  };
  const handleWebView = (e) => {
    setWebView(e.target.value);
  };

  const handleSubmit = (e) => {
    if (props.updateNotice) {
      props.handleUpdate(
        {
          title,
          date:dayjs(date).toDate(),
          noticeFor,
          notice,
          webView,
        },
        props.updateNotice._id
      );
    } else {
      props.handleSubmit(e, {
        title,
        date:dayjs(date).toDate(),
        noticeFor,
        notice,
        webView,
      });
    }
    setTitle("");
    setDate("");
    setNoticeFor("");
    setNotice("");
    setWebView("");

    props.handleClose();
  };

  const handleCloseModal = () => {
    setTitle("");
    setDate("");
    setNoticeFor("");
    setNotice("");
    setWebView("");

    props.handleClose();
  };

  const handleClose = () => {
    setTitle("");
    setDate("");
    setNoticeFor("");
    setNotice("");
    setWebView("");
    props.handleClose();
  };

  return (
    <div>
      <Fab
        size="medium"
        style={{ background: "#1b3779", color: "#fff" }}
        aria-label="add"
        onClick={props.handleOpen}
      >
        <Tooltip title="Add">
          <Add />
        </Tooltip>
      </Fab>
      <Modal
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleSubmit}>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
              marginBottom="20px"
            >
              Add Notice
            </Typography>
            <div className="">
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <TextField
                    required
                    variant="standard"
                    label="Notice Title"
                    value={title}
                    onChange={handleTitle}
                    sx={{ m: 1, width: "100%", label: { fontSize: 12 } }}
                  />
                </Grid>
              </Grid>
              <TextField
                required
                variant="standard"
                label="Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={date}
                onChange={handleDate}
                sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
              />
              <FormControl variant="standard" required sx={{ m: 1, width: 227 }}>
                <InputLabel sx={{ fontSize: 12 }}>Notice for</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={noticeFor}
                  onChange={handleNoticeFor}
                >
                  <MenuItem
                  key={'all'}
                  value={'all'}
                  sx={{ fontSize: 12, fontWeight: 500 }}
                  >
                  All
                  </MenuItem>
                  {roles &&
                    roles.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row.roleName}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.roleName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl
              
                variant="standard"
                sx={{ m: 1, minWidth: 227 }}
                required
              >
                <InputLabel sx={{ fontSize: 12 }}>Is view on web?</InputLabel>
                <Select value={webView} onChange={handleWebView}>
                  <MenuItem
                    value={"yes"}
                    sx={{ fontSize: 12, fontWeight: 500 }}
                  >
                    Yes
                  </MenuItem>
                  <MenuItem value={"no"} sx={{ fontSize: 12, fontWeight: 500 }}>
                    No
                  </MenuItem>
                </Select>
              </FormControl>
              <TextareaAutosize
              required
                maxRows={4}
                aria-label="maximum height"
                placeholder="Notice"
                value={notice}
                onChange={handleNotice}
                style={{
                  width: 712,
                  maxHeight: 110,
                  margin: 8,
                  padding: 10,
                  height: 70,
                  overflow: "auto",
                }}
              />
            </div>

            <div className="cancel-submit-btn">
              <div>
                <Tooltip title="Cancel">
                <Button
                  variant="outlined"
                  size="medium"
                  type="button"
                  sx={{ m: 1 }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                </Tooltip>
                <Tooltip title="Submit">
                <Button
                  variant="contained"
                  size="medium"
                  type="submit"
                  sx={{ background: "rgb(27, 55, 121)", ":hover":{background: "rgb(27, 55, 121)"} }}
                >
                  Submit
                </Button>
                </Tooltip>
              </div>
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
};

export default AddNotice;