import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  Avatar,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { objHas } from "../../utils/utils";
import React, { useEffect, useState } from "react";
import { del, get, post, put } from "../../services/apis";
import { urls } from "../../services/urlConstant";
import { Delete } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 520,
  bgcolor: "background.paper",
  p: 4,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}
const styles = {
  cardsMargin: {
    margin: "15px",
  },
  cardStyle: {
    margin: "15px",
    padding: "10px",
  },
  icon: {
    padding: "10px",
    fontSize: "12px",
    fontWeight: 500,
  },
  card: {
    padding: 15,
    marginBottom: 20,
    width: "100%",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
};

const TransportMember = () => {
  const [tabValue, setTabValue] = useState(0);
  const [formData, setFormData] = useState([]);
  const [addData, setAddData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(1000);
  const [students, setStudents] = useState([]);
  const [showPagination, setShowPagination] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [academicYears, setAcademicYears] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [nonStudents, setNonStudents] = useState([]);
  const [studentToAdd, setStudentToAdd] = useState("");
  const [mode, setMode] = useState("edit");
  const [routes, setRoutes] = useState([]);

  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [nonSearchFilter, setNonSearchFilter] = useState([]);
  const [nonSearch, setNonSearch] = useState("");
  const [list, setList] = React.useState([]);

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll);
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data[0]._id,
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);

  useEffect(() => {
    const getRoutes = async () => {
      try {
        const { data, status } = await get(urls.route.getList);
        setRoutes(data.data);
        console.log(data.data, "setRoutes");
      } catch (error) {
        console.log(error);
      }
    };
    getRoutes();
  }, []);
  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        const resSections = await get(urls.schoolSection.getAllSection, {
          params: {
            search: {
              class: res.data.data[0]._id,
            },
          },
        });
        const searchStudents = await get(urls.students.getAllStudent, {
          params: {
            search: {
              academicYear: formData.academicYear,
              "academicInfo.class": res.data.data[0]._id,
              "academicInfo.section": resSections.data.data[0]._id,
            },
          },
        });
        setSections(resSections.data.data);
        setClasses(res.data.data);
        setFormData((prev) => ({
          ...prev,
          className: res.data.data[0]._id,
          section: resSections.data.data[0]._id,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);

  useEffect(() => {
    getVehicle();
  }, []);

  const getVehicle = async () => {
    console.log("lklklklklklklklk");
    try {
      const { data, status } = await get(urls.transport.getList);
      setList(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      students.length > 0 &&
        setSearchFilter(
          students.filter((ele) =>
            ele.basicInfo.name
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  const handleNonSearch = (e) => {
    const { value } = e.target;
    setNonSearch(value.trim());
    if (value.trim() !== "") {
      nonStudents.length > 0 &&
        setNonSearchFilter(
          nonStudents.filter((ele) =>
            ele.basicInfo.name
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setNonSearchFilter([]);
    }
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleStudentSearchSubmit = async (e) => {
    e.preventDefault();
    try {
      const searchOptions = {
        academicYear: formData.academicYear,
        "academicInfo.class": formData.className,
        // status: formData.status,
      };

      let searchParams = {
        params: {
          search: searchOptions,
          limit: rowsPerPage,
          page: page === 0 ? 1 : page + 1,
        },
      };

      if (formData.section !== "all") {
        searchOptions["academicInfo.section"] = formData.section;
      }

      const searchStudents = await get(
        urls.students.getAllStudent,
        searchParams
      );
      const sortedStudents = searchStudents.data.data.sort((a, b) => {
        const aIsHostelMember = a.otherInfo.transportMember === "yes";
        const bIsHostelMember = b.otherInfo.transportMember === "yes";
        if (aIsHostelMember && !bIsHostelMember) {
          return -1; // a comes first
        } else if (!aIsHostelMember && bIsHostelMember) {
          return 1; // b comes first
        } else {
          return 0; // maintain the original order
        }
      });

      setStudents(sortedStudents);
      console.log(sortedStudents, "sorttttttttt");
      if (searchStudents.data.count > rowsPerPage) {
        setShowPagination(true);
      }
      setTotalCount(searchStudents.data.count);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNonStudentSearchSubmit = async (e) => {
    e.preventDefault();

    try {
      const searchOptions = {
        academicYear: formData.academicYear,
        "academicInfo.class": formData.class,
        // status: formData.status,
      };

      if (formData.section === "all") {
        const searchStudents = await get(urls.students.getAllStudent, {
          params: {
            search: searchOptions,
            limit: rowsPerPage,
            page: page + 1,
          },
        });

        const filteredStudents = searchStudents.data.data.filter(
          (student) => student.otherInfo.transportMember === "no"
        );

        console.log(filteredStudents);

        setNonStudents(filteredStudents);
        if (searchStudents.data.count > rowsPerPage) {
          setShowPagination(true);
        }
        setTotalCount(filteredStudents.length);
      } else {
        const searchStudent = await get(urls.students.getAllStudent, {
          params: {
            search: {
              ...searchOptions,
              "academicInfo.section": formData.section,
            },
            limit: rowsPerPage,
            page: page === 0 ? 1 : page + 1,
          },
        });

        const filteredStudents = searchStudent.data.data.filter(
          (student) => student.otherInfo.transportMember === "no"
        );
        setNonStudents(filteredStudents);
        if (searchStudent.data.count > rowsPerPage) {
          setShowPagination(true);
        }
        setTotalCount(filteredStudents.length);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // const handleNonStudentSearchSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const searchOptions = {
  //       academicYear: formData.academicYear,
  //       "academicInfo.class": formData.class,
  //       status: formData.status,
  //     };

  //     let filteredStudents = [];

  //     if (formData.section === "all") {
  //       const searchStudents = await get(urls.students.getAllStudent, {
  //         params: {
  //           search: searchOptions,
  //           limit: rowsPerPage,
  //           page: page,
  //         },
  //       });

  //       filteredStudents = searchStudents.data.data.filter(
  //         (student) => student.otherInfo.transportMember === "no"
  //       );
  //     } else {
  //       const searchStudent = await get(urls.students.getAllStudent, {
  //         params: {
  //           search: {
  //             ...searchOptions,
  //             "academicInfo.section": formData.section,
  //           },
  //           limit: rowsPerPage,
  //           page: page,
  //         },
  //       });

  //       filteredStudents = searchStudent.data.data.filter(
  //         (student) => student.otherInfo.transportMember === "no"
  //       );
  //     }

  //     setNonStudents(filteredStudents);
  //     if (filteredStudents.length > rowsPerPage) {
  //       setShowPagination(true);
  //     }
  //     setTotalCount(filteredStudents.length);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOnChange2 = (e) => {
    const { name, value } = e.target;
    setAddData({
      ...addData,
      [name]: value,
    });
  };
  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      setFormData((prev) => ({
        ...prev,
        section: "all",
      }));
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteMember = async (e, id) => {
    e.preventDefault();

    try {
      const { status } = await put(
        `${urls.transportMember.deleteMember}/`,
        id,
        {}
      );
      // getHostelMembers();
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddTransportButtonClick = (id) => {
    console.log(id, "ihhhhh");
    setMode("create");
    setStudentToAdd(id);
    setOpen(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();

    try {
      const { status } = await put(
        `${urls.transportMember.editMember}/`,
        studentToAdd,
        {
          transportRouteTitle: addData.route,
          vehicleNumber: addData.vehicle,
          stopName: addData.busStop,
          pickType: addData.pickType,
        }
      );
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetPrintPdf = async (e, id) => {
    e.preventDefault();

    try {
      const getPrint = await get(`/transport-member/downloadpdf/${id}`, {
        responseType: "blob",
      });
      console.log(getPrint, "pdf");
      const uri = URL.createObjectURL(getPrint.data);
      window.open(uri, "__blank");
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetPrintWholePdf = async (e) => {
    e.preventDefault();

    try {
      const getPrint = await get(
        `/transport-member/downloadfullpdf/${formData.academicYear}/${formData.className}/${formData.section}`,
        {
          responseType: "blob",
        }
      );
      console.log(getPrint, "pdf");
      const uri = URL.createObjectURL(getPrint.data);
      window.open(uri, "__blank");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <div style={styles.cardsMargin}>
        <Card style={styles.cardStyle}>
          <Grid container spacing={2}>
            <Grid item xs={12} mg={12} lg={12}>
              <span
                style={{
                  color: "red",
                  fontFamily: "cursive",
                  textAlign: "start",
                }}
              >
                Transport Member
              </span>
            </Grid>
          </Grid>
        </Card>
        <Card style={styles.cardsMargin}>
          <Box>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab label="Student List" {...a11yProps(0)} />
              <Tab label="Non Member Student" {...a11yProps(1)} />
            </Tabs>
          </Box>
        </Card>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form onSubmit={(e) => handleAddSubmit(e)}>
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign="center"
                marginBottom="30px"
                // onChange={handleAddForm}
              >
                {mode === "edit" ? "Update" : "Add"}
              </Typography>
              <div>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6} lg={4} sx={{ mt: 2, padding: 1 }}>
                    <FormControl fullWidth required>
                      <InputLabel>Select Route</InputLabel>
                      <Select
                        label="Section"
                        id="demo-simple-select-filled"
                        name="route"
                        value={addData.route || ""}
                        onChange={handleOnChange2}
                        size="small"
                      >
                        {routes.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.transportRouteTitle}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6} lg={4} sx={{ mt: 2, padding: 1 }}>
                    <FormControl fullWidth required>
                      <InputLabel>Select Vehicle</InputLabel>
                      <Select
                        label="Section"
                        id="demo-simple-select-filled"
                        name="vehicle"
                        value={addData.vehicle || ""}
                        onChange={handleOnChange2}
                        size="small"
                      >
                        {list.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.vehicleNumber}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} sx={{ mt: 2, padding: 1 }}>
                    <FormControl fullWidth required>
                      <InputLabel>Select Bus Stop</InputLabel>
                      <Select
                        label="Section"
                        id="demo-simple-select-filled"
                        name="busStop"
                        value={addData.busStop || ""}
                        onChange={handleOnChange2}
                        size="small"
                      >
                        {routes.map((row, index) =>
                          row.routeDetails.map((route, routeIndex) => (
                            <MenuItem
                              key={route._id}
                              value={route.stopName} // Use the stopName value as the value
                              sx={{ fontSize: 12, fontWeight: 500 }}
                            >
                              {route.stopName}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6} lg={4} sx={{ mt: 2, padding: 1 }}>
                    <FormControl fullWidth required>
                      <InputLabel>Select Pick Type</InputLabel>
                      <Select
                        label="Hostel Name"
                        id="demo-simple-select-filled"
                        name="pickType"
                        value={addData.pickType || ""}
                        onChange={handleOnChange2}
                        size="small"
                      >
                        <MenuItem value={"Both"}>Both</MenuItem>
                        <MenuItem value={"Pick"}>Pick</MenuItem>
                        <MenuItem value={"Drop"}>Drop</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <div className="cancel-submit-btn">
                  <div>
                    <Tooltip title="Cancel">
                      <Button
                        onClick={handleClose}
                        variant="outlined"
                        size="medium"
                        type="button"
                        sx={{ m: 1 }}
                      >
                        Cancel
                      </Button>
                    </Tooltip>
                    <Tooltip title="Submit">
                      <Button
                        variant="contained"
                        size="medium"
                        type="submit"
                        sx={{
                          background: "rgb(27, 55, 121)",
                          ":hover": { background: "rgb(27, 55, 121)" },
                        }}
                      >
                        Add
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </Box>
          </form>
        </Modal>
        <TabPanel value={tabValue} index={0}>
          <Card style={styles.cardStyle}>
            <Box sx={{ flexGrow: 1 }}>
              <form onSubmit={handleStudentSearchSubmit}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl fullWidth required>
                      <InputLabel>Academic Year</InputLabel>
                      <Select
                        label="Academic Year"
                        id="demo-simple-select-filled"
                        name="academicYear"
                        value={formData.academicYear || ""}
                        onChange={handleOnChange}
                        size="small"
                      >
                        {academicYears &&
                          academicYears.map((row, index) => (
                            <MenuItem key={row._id} value={row._id}>
                              {row.academicYearFrom} - {row.academicYearTo}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl fullWidth required>
                      <InputLabel>Class</InputLabel>
                      <Select
                        label="Class"
                        id="demo-simple-select-filled"
                        name="className"
                        value={formData.className || ""}
                        onChange={handleClassChange}
                        size="small"
                      >
                        {classes &&
                          classes.map((row, index) => (
                            <MenuItem key={row._id} value={row._id}>
                              {row.className}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl fullWidth required>
                      <InputLabel>Section</InputLabel>
                      <Select
                        label="Section"
                        id="demo-simple-select-filled"
                        name="section"
                        value={formData.section || ""}
                        onChange={handleOnChange}
                        size="small"
                      >
                        {formData.className && (
                          <MenuItem value="all">--all--</MenuItem>
                        )}
                        {sections.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.sectionName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={1}
                    style={{ alignSelf: "flex-end" }}
                  >
                    <Button
                      type="submit"
                      size="small"
                      variant="contained"
                      style={styles.Button}
                    >
                      SEARCH
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Card>
          <div style={{ margin: 15 }}>
            <Paper sx={{ height: "60px", overflow: "hidden" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  margin: "15px",
                }}
              >
                <Button style={styles.Button} onClick={handleGetPrintWholePdf}>
                  Download
                </Button>
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid xs={3}>
                    <TextField
                      size="small"
                      style={styles.textfield}
                      value={search}
                      onChange={handleSearch}
                      placeholder="enter student name"
                      label="Search"
                      variant="outlined"
                      sx={{ mt: 1.2 }}
                    />
                  </Grid>
                </Grid>
              </div>
            </Paper>

            <Table>
              <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
                <TableRow sx={{ color: "white" }}>
                  <TableCell sx={{ color: "white" }} align="center">
                    #SL
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Name
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Admission No
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Stop Name
                  </TableCell>

                  <TableCell sx={{ color: "white" }} align="center">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {search
                  ? searchFilter.map((data, index) => (
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {data.basicInfo.name}
                        </TableCell>

                        <TableCell align="center">
                          {data.academicInfo.admissionNo}
                        </TableCell>
                        <TableCell align="center">
                          {data.transportInfo && data.transportInfo.stopName
                            ? data.transportInfo.stopName
                            : "NA"}{" "}
                        </TableCell>
                        <TableCell align="center">
                          {data.otherInfo.transportMember === "yes" ? (
                            <div className="edit-delete">
                              <Button
                                style={{ color: "#1b3779" }}
                                onClick={() => handleClickOpen(data._id)}
                              >
                                <EditIcon />
                              </Button>

                              <Button
                                style={{
                                  color: "#1b3779",
                                }}
                                onClick={(e) => handleDeleteMember(e, data._id)}
                              >
                                <Delete />
                              </Button>
                              <Button
                                onClick={(e) => handleGetPrintPdf(e, data._id)}
                              >
                                <DownloadIcon />
                              </Button>
                            </div>
                          ) : (
                            <Button
                              onClick={() =>
                                handleAddTransportButtonClick(data._id)
                              }
                            >
                              Add
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  : students.map((data, index) => (
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {data.basicInfo.name}
                        </TableCell>

                        <TableCell align="center">
                          {data.academicInfo.admissionNo}
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          {data.transportInfo && data.transportInfo.stopName
                            ? data.transportInfo.stopName
                            : "NA"}
                        </TableCell>
                        <TableCell align="center">
                          {data.otherInfo.transportMember === "yes" ? (
                            <div className="edit-delete">
                              <Button
                                style={{ color: "#1b3779" }}
                                onClick={() => handleClickOpen(data._id)}
                              >
                                <EditIcon />
                              </Button>

                              <Button
                                style={{
                                  color: "#1b3779",
                                }}
                                onClick={(e) => handleDeleteMember(e, data._id)}
                              >
                                <Delete />
                              </Button>
                              <Button
                                onClick={(e) => handleGetPrintPdf(e, data._id)}
                              >
                                <DownloadIcon />
                              </Button>
                            </div>
                          ) : (
                            <Button
                              onClick={() =>
                                handleAddTransportButtonClick(data._id)
                              }
                            >
                              Add
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
            {!students.length && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            )}
            {search.trim() && !searchFilter.length && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            )}
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Card style={styles.cardStyle}>
            <Box sx={{ flexGrow: 1 }}>
              <form onSubmit={(e) => handleNonStudentSearchSubmit(e)}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl fullWidth required>
                      <InputLabel>Academic Year</InputLabel>
                      <Select
                        label="Academic Year"
                        id="demo-simple-select-filled"
                        name="academicYear"
                        value={formData.academicYear || ""}
                        onChange={handleOnChange}
                        size="small"
                      >
                        {academicYears &&
                          academicYears.map((row) => (
                            <MenuItem key={row._id} value={row._id}>
                              {row.academicYearFrom} - {row.academicYearTo}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl fullWidth required>
                      <InputLabel>Class</InputLabel>
                      <Select
                        label="Class"
                        id="demo-simple-select-filled"
                        name="className"
                        value={formData.className || ""}
                        onChange={handleClassChange}
                        size="small"
                      >
                        {classes &&
                          classes.map((row) => (
                            <MenuItem key={row._id} value={row._id}>
                              {row.className}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FormControl fullWidth required>
                      <InputLabel>Section</InputLabel>
                      <Select
                        label="Section"
                        id="demo-simple-select-filled"
                        name="section"
                        value={formData.section || ""}
                        onChange={handleOnChange}
                        size="small"
                      >
                        {formData.className && (
                          <MenuItem value="all">--all--</MenuItem>
                        )}
                        {sections.map((row) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.sectionName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={1}
                    style={{ alignSelf: "flex-end" }}
                  >
                    <Button
                      type="submit"
                      size="small"
                      variant="contained"
                      style={styles.Button}
                    >
                      SEARCH
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Card>
          <div style={{ margin: "15px" }}>
            <Paper sx={{ height: "60px", overflow: "hidden" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  margin: "15px",
                }}
              >
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid xs={3}>
                    <TextField
                      size="small"
                      style={styles.textfield}
                      value={nonSearch}
                      onChange={handleNonSearch}
                      placeholder="enter student name"
                      label="Search"
                      variant="outlined"
                      sx={{ mt: 1.2 }}
                    />
                  </Grid>
                </Grid>
              </div>
            </Paper>
            <Table>
              <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
                <TableRow sx={{ color: "white" }}>
                  <TableCell sx={{ color: "white" }} align="center">
                    #SL
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Photo
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Name
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Class
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Section
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Roll No
                  </TableCell>

                  <TableCell sx={{ color: "white" }} align="center">
                    Select
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {nonSearch
                  ? nonSearchFilter.map((data, index) => (
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          <Avatar src={objHas(data, "studentPhoto.link", "")} />
                        </TableCell>

                        <TableCell align="center">
                          {data.basicInfo.name}
                        </TableCell>

                        <TableCell align="center">
                          {data.academicInfo.class.className}
                        </TableCell>
                        <TableCell align="center"></TableCell>

                        <TableCell align="center">
                          {data.academicInfo.rollNo}
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={() =>
                              handleAddTransportButtonClick(data._id)
                            }
                          >
                            Add Member
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  : nonStudents.map((data, index) => (
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          <Avatar src={objHas(data, "studentPhoto.link", "")} />
                        </TableCell>

                        <TableCell align="center">
                          {data.basicInfo.name}
                        </TableCell>

                        <TableCell align="center">
                          {data.academicInfo.class.className}
                        </TableCell>
                        <TableCell align="center"></TableCell>

                        <TableCell align="center">
                          {data.academicInfo.rollNo}
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={() =>
                              handleAddTransportButtonClick(data._id)
                            }
                          >
                            Add Member
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
            {!nonStudents.length && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            )}
            {nonSearch.trim() && !nonSearchFilter.length && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            )}
          </div>
        </TabPanel>
      </div>
    </div>
  );
};

export default TransportMember;
