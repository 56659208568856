import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Tooltip,
  styled,
  IconButton,
} from "@mui/material";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import { get } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 550,
  bgcolor: "background.paper",
  p: 4,
};
const ListContainer = styled(Box)(() => ({
  flexWrap: "nowrap",
  overflowY: "hidden",
  overflowX: "auto",
  flexDirection: "row",
  display: "flex",
  paddingLeft: "10px",
}));
const ListItem = styled(Typography)(() => ({
  fontSize: "14px",
  paddingRight: "5px",
  paddingLeft: "5px",
}));

const ListItemContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  backgroundColor: "#f9f9f9",
  marginRight: "10px",
  alignItems: "center",
  borderRadius: "10@s",
  minWidth: "150px",
}));

const AddSubjectModal = (props) => {
  const [subjectName, setSubjectName] = useState("");
  const [subjectCode, setSubjectCode] = useState("");
  const [subjectType, setSubjectType] = useState("");
  const [subjectAuthor, setSubjectAuthor] = useState("");
  const [subjectGroup, setSubjectGroup] = useState("");
  const [subjectTeacher, setSubjectTeacher] = useState("");
  const [subjectTeachers, setSubjectTeachers] = useState([]);
  const [subjectClass, setSubjectClass] = useState("");
  const [subjectNote, setSubjectNote] = useState("");
  const [classes, setClasses] = useState([]);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    if (props.updateSubject) {
      setSubjectName(props.updateSubject.subjectName);
      setSubjectTeachers(props.updateSubject.subjectTeachers || []);
      setSubjectCode(props.updateSubject.subjectCode);
      setSubjectType(props.updateSubject.subjectType);
      setSubjectAuthor(props.updateSubject.subjectAuthor);
      setSubjectGroup(props.updateSubject.subjectGroup);
      setSubjectTeacher(props.updateSubject.subjectTeacher);
      setSubjectClass(props.updateSubject?.class?._id);
      setSubjectNote(props.updateSubject.note);
    }
  }, [props.updateSubject]);

  useEffect(() => {
    const getClasses = async () => {
      try {
        const response = await get(urls.class.getAllClass);
        setClasses(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);

  useEffect(() => {
    const getEmployee = async () => {
      try {
        const response = await get(urls.employee.getAllEmployee);
        setEmployees(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getEmployee();
  }, []);

  const handleSubjectName = (e) => {
    setSubjectName(e.target.value);
  };
  const handleSubjectCode = (e) => {
    setSubjectCode(e.target.value);
  };
  const handleSubjectType = (e) => {
    setSubjectType(e.target.value);
  };

  const handleSubjectAuthor = (e) => {
    setSubjectAuthor(e.target.value);
  };

  const handleGroup = (e) => {
    setSubjectGroup(e.target.value);
  };
  const handleSubjectTeacher = (e) => {
    setSubjectTeacher(e.target.value);

    if (subjectTeachers.filter((t) => t == e.target.value)[0]) {
      let newList = subjectTeachers.filter((t) => t != e.target.value);
      setSubjectTeachers(newList);
    } else {
      let itemToAdd = employees.filter((emp) => emp._id == e.target.value)[0];
      let newList = [...subjectTeachers, itemToAdd._id];
      setSubjectTeachers(newList);
    }
  };

  const handleRemoveSubjectTeacher = (emp) => {
    let newList = subjectTeachers.filter((t) => t != emp);
    setSubjectTeachers(newList);
  };
  const handleSubjectClass = (e) => {
    setSubjectClass(e.target.value);
  };
  const handleSubjectNote = (e) => {
    setSubjectNote(e.target.value);
  };
  const handleSubmit = (e) => {
    if (props.updateSubject) {
      props.handleUpdate(
        {
          subjectName,
          subjectCode,
          subjectType,
          subjectAuthor,
          subjectGroup,
          subjectTeacher,
          class: subjectClass,
          note: subjectNote,
          subjectTeachers,
        },
        props.updateSubject._id
      );
    } else {
      props.handleSubmit(e, {
        subjectName,
        subjectCode,
        subjectType,
        subjectAuthor,
        subjectGroup,
        subjectTeacher,
        class: subjectClass,
        note: subjectNote,
        subjectTeachers,
      });
    }
    setSubjectName("");
    setSubjectCode("");
    setSubjectType("");
    setSubjectAuthor("");
    setSubjectGroup("");
    setSubjectTeacher("");
    setSubjectClass("");
    setSubjectNote("");
    setSubjectTeachers([]);
    props.handleClose();
  };

  const handleCloseModal = () => {
    setSubjectName("");
    setSubjectCode("");
    setSubjectType("");
    setSubjectAuthor("");
    setSubjectGroup("");
    setSubjectTeacher("");
    setSubjectClass("");
    setSubjectNote("");
    setSubjectTeachers([]);
    props.handleClose();
  };

  const handleClose = () => {
    setSubjectName("");
    setSubjectCode("");
    setSubjectType("");
    setSubjectAuthor("");
    setSubjectGroup("");
    setSubjectTeacher("");
    setSubjectClass("");
    setSubjectNote("");
    setSubjectTeachers([]);
    props.handleClose();
  };
  return (
    <div>
      <Tooltip title="Add" disableInteractive>
        <Fab
          size="medium"
          style={{ background: "#1b3779", color: "#fff" }}
          aria-label="add"
          onClick={props.handleOpen}
        >
          <Add />
        </Fab>
      </Tooltip>
      <Modal
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleSubmit}>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
              marginBottom="30px"
            >
              Add Subject
            </Typography>
            <div>
              <TextField
                required
                id="filled-required"
                variant="standard"
                label="Subject Name"
                value={subjectName}
                onChange={handleSubjectName}
                sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
              />
              <TextField
                required
                id="filled-required"
                variant="standard"
                value={subjectCode}
                onChange={handleSubjectCode}
                label="Subject Code"
                sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
              />

              <TextField
                required
                id="filled-required"
                variant="standard"
                label="Author"
                value={subjectAuthor}
                onChange={handleSubjectAuthor}
                sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
              />
              <div>
                <FormControl
                  variant="standard"
                  sx={{ m: 1, minWidth: 227 }}
                  required
                >
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    Subject type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={subjectType}
                    onChange={handleSubjectType}
                  >
                    <MenuItem
                      value={"mandatory"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Mandatory
                    </MenuItem>
                    <MenuItem
                      value={"optional"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Optional
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 227 }}>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    Group
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={subjectGroup}
                    onChange={handleGroup}
                  >
                    <MenuItem
                      value={"scholastic"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Scholastic
                    </MenuItem>
                    <MenuItem
                      value={"co-scholastic"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Co-scholastic
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 227 }}>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    Class
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={subjectClass}
                    onChange={handleSubjectClass}
                  >
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              <div>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 227 }}>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    Select Subject teachers
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={""}
                    onChange={handleSubjectTeacher}
                  >
                    {employees &&
                      employees.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.basicInfo.empName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              {subjectTeachers.length ? (
                <ListContainer>
                  {subjectTeachers.map((t) => (
                    <ListItemContainer key={t}>
                      <ListItem component="span">
                        {employees.filter((e) => e._id == t)[0]
                          ? employees.filter((e) => e._id == t)[0].basicInfo
                              .empName
                          : ""}
                      </ListItem>
                      <IconButton onClick={() => handleRemoveSubjectTeacher(t)}>
                        <CloseRoundedIcon fontSize="small" />
                      </IconButton>
                    </ListItemContainer>
                  ))}
                </ListContainer>
              ) : null}
              <div>
                <TextareaAutosize
                  maxRows={4}
                  aria-label="maximum height"
                  placeholder="Drop a note"
                  value={subjectNote}
                  onChange={handleSubjectNote}
                  style={{
                    width: 712,
                    maxHeight: 110,
                    margin: 8,
                    padding: 10,
                    minHeight: 118,
                  }}
                />
              </div>
            </div>

            <div className="cancel-submit-btn">
              <div>
                <Tooltip title="Cancel">
                  <Button
                    onClick={handleClose}
                    variant="outlined"
                    size="medium"
                    type="button"
                    sx={{
                      m: 1,
                      color: "rgb(27, 55, 121)",
                      borderColor: "rgb(27, 55, 121)",
                    }}
                  >
                    Cancel
                  </Button>
                </Tooltip>
                <Tooltip title="Submit">
                  <Button
                    disabled={!(subjectName && subjectCode && subjectType)}
                    variant="contained"
                    size="medium"
                    type="submit"
                    sx={{
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                    }}
                  >
                    Submit
                  </Button>
                </Tooltip>
              </div>
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
};

export default AddSubjectModal;
