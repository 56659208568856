import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Fab,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Delete } from "@material-ui/icons";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DirectionsTransitFilledIcon from "@mui/icons-material/DirectionsTransitFilled";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import RemoveIcon from "@mui/icons-material/Remove";
import { get, post, put, del } from "../../services/apis";
import { urls } from "../../services/urlConstant";
import DeleteModal from "../Layout/Academic/DeleteModal";
import { Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 560,
  overflowY: "scroll",
  bgcolor: "background.paper",
  p: 4,
  marginTop: "10px",
};

const modal = {
  position: "absolute",
  top: "50%",
  left: "50%",

  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
function TableRows({ rows, tableRowRemove, onValUpdate }) {
  return rows
    ? rows.map((rowData, index) => {
        const { stopName, stopKM, pickTime, dropTime } = rowData;

        const handleValueUpdate = (event) => {
          onValUpdate(index, event);
        };

        return (
          <tr key={index}>
            <td>
              <input
                type="text"
                value={stopName}
                onChange={handleValueUpdate}
                name="stopName"
                placeholder="Stop Name"
                style={{ fontSize: "12px", padding: "4px" }}
              />
            </td>
            <td>
              <input
                type="text"
                value={stopKM}
                onChange={handleValueUpdate}
                name="stopKM"
                placeholder="Stop Km"
                style={{ fontSize: "12px", padding: "4px" }}
              />
            </td>
            <td>
              <input
                type="time"
                value={pickTime}
                onChange={handleValueUpdate}
                name="pickTime"
                placeholder="Pick Time"
                style={{ fontSize: "12px", padding: "4px" }}
              />
            </td>
            <td>
              <input
                type="time"
                value={dropTime}
                onChange={handleValueUpdate}
                name="dropTime"
                placeholder="Drop Time"
                style={{ fontSize: "12px", padding: "4px" }}
              />
            </td>
            <td>
              <button
                className="btn btn-danger"
                onClick={(e) => tableRowRemove(index, e)}
              >
                <RemoveIcon />
              </button>
            </td>
          </tr>
        );
      })
    : "";
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const ManageRoute = () => {
  const [search, setSearch] = useState("");
  const [addForm, setAddForm] = useState({
    transportRouteTitle: "",
    vehicleNumber: "",
    routeStart: "",
    routeEnd: "",
    pickStart: "",
    pickEnd: "",
    dropStart: "",
    dropEnd: "",
    routeDetails: "",
    note: "",
    isMultiSchool: "",
    routeDetails: "",
    stopName: "",
    stopKM: "",
    routeDetails: [],
  });
  const [open, setOpen] = React.useState(false);
  const [searchFilter, setSearchFilter] = useState([]);
  const [list, setList] = React.useState([]);
  const [editRoute, setEditRoute] = useState();
  const [dialog, setOpenDialog] = React.useState(false);
  const [date, setDate] = useState(dayjs(Date.now()));
  const [rows, setRows] = useState([]);
  const [pickStart, setPickStart] = useState("");
  const [pickEnd, setPickEnd] = useState("");
  const [dropStart, setDropStart] = useState("");
  const [dropEnd, setDropEnd] = useState("");
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [showDetails, setShowDetails] = useState(null);
  const [vehicleNumber, setVehicleNumber] = React.useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const [bulkUpdateModal, setBulkUpdateModal] = useState(false);
  const [fileChoosen, setFileChoosen] = useState(undefined);

  const addRowTable = (e) => {
    console.log(e, "event");
    e.preventDefault();
    const data = {
      stopName: "",
      stopKM: "",
      pickTime: "",
      dropTime: "",
    };
    let initialRouteList = [...addForm.routeDetails, data];
    setAddForm({ ...addForm, routeDetails: initialRouteList });
    console.log(initialRouteList, "ininininininin");
  };
  const tableRowRemove = (index, e) => {
    e.preventDefault();
    let newRouteList = addForm.routeDetails.filter((item, i) => i !== index);
    setAddForm({ ...addForm, routeDetails: newRouteList });
  };
  const onValUpdate = (i, event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let initialRoute = [...addForm.routeDetails];
    let newRoutes = [];
    for (let b of initialRoute) {
      if (initialRoute.indexOf(b) == i) {
        let newItem = { ...b, [name]: value };
        newRoutes.push(newItem);
      } else {
        newRoutes.push(b);
      }
    }
    setAddForm({ ...addForm, routeDetails: newRoutes });
  };

  const styles = {
    button: {
      backgroundColor: "orange",
    },
    textfield: {
      marginTop: "25px",
      width: "70%",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
    tab: {
      width: "100%",
      borderCollapse: "collapse",
    },
    cardButton: {
      display: "flex",
      justifyContent: "flex-end",
      textAlign: "center",
    },
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      list.length > 0 &&
        setSearchFilter(
          list.filter((ele) =>
            ele.transportRouteTitle
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setEditRoute(null);
    setAddForm({
      transportRouteTitle: "",
      vehicleNumber: "",
      routeStart: "",
      routeEnd: "",
      pickStart: "",
      pickEnd: "",
      dropStart: "",
      dropEnd: "",
      routeDetails: "",
      note: "",
      isMultiSchool: "",
      routeDetails: "",
      stopName: "",
      stopKM: "",
      routeDetails: [],
    });
    setOpen(false);
    setRows([]);
  };

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClickOpenDialog = (route) => {
    setShowDetails(route);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setShowDetails(null);
    setOpenDialog(false);
  };
  useEffect(() => {
    getRoute();
  }, []);

  const getRoute = async () => {
    console.log("lklklklklklklklk");
    try {
      const { data, status } = await get(urls.route.getList);
      setList(data.data);
      console.log(data.data, "getRouteeeeee");
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!editRoute) {
        const { status } = await post(`${urls.route.create}`, {
          transportRouteTitle: addForm.transportRouteTitle,
          vehicleNumber: addForm.vehicleNumber,
          routeStart: addForm.routeStart,
          routeEnd: addForm.routeEnd,
          pickStart: pickStart,
          pickEnd: pickEnd,
          dropStart: dropStart,
          dropEnd: dropEnd,
          routeDetails: addForm.routeDetails,
          note: addForm.note,
          isMultiSchool: addForm.isMultiSchool,
          routeDetails: addForm.routeDetails,
        });
        getRoute();
        handleClose();
      } else {
        const { status } = await put(`${urls.route.update}${editRoute}`, "", {
          transportRouteTitle: addForm.transportRouteTitle,
          vehicleNumber: addForm.vehicleNumber,
          routeStart: addForm.routeStart,
          routeEnd: addForm.routeEnd,
          pickStart: pickStart,
          pickEnd: pickEnd,
          dropStart: dropStart,
          dropEnd: dropEnd,
          routeDetails: addForm.routeDetails,
          note: addForm.note,
          isMultiSchool: addForm.isMultiSchool,
          routeDetails: addForm.routeDetails,
        });
        getRoute();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePickStart = (e) => {
    setPickStart(e.target.value);
  };
  const handlePickEnd = (e) => {
    setPickEnd(e.target.value);
  };

  const handleDropStart = (e) => {
    setDropStart(e.target.value);
  };
  const handleDropEnd = (e) => {
    setDropEnd(e.target.value);
  };

  useEffect(() => {
    const getVehicleNumber = async () => {
      try {
        const { data, status } = await get(`${urls.transport.getList}`);
        if (status > 199 && status < 299) {
          setVehicleNumber(data.data);
          console.log(data.data, "pppppppppp");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getVehicleNumber();
  }, []);

  const handleDeleteone = async (id) => {
    try {
      const res = await del(`${urls.route.deletRoute}`, id);
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleFileChange = (e) => {
    setFileChoosen(e.target.files[0]);
  };

  const handleClassMapEdit = (id, data) => {
    setEditRoute(id);
    setAddForm({
      transportRouteTitle: data.transportRouteTitle,
      vehicleNumber: data.vehicleNumber._id,
      routeStart: data.routeStart,
      routeEnd: data.routeEnd,
      pickStart: data.pickStart,
      pickEnd: data.pickEnd,
      dropStart: data.dropStart,
      dropEnd: data.dropEnd,
      routeDetails: data.routeDetails,
      note: data.note,
      isMultiSchool: data.isMultiSchool,
      routeDetails: data.routeDetails,
    });
    handleClickOpen();
  };
  const handleDownloadSample = async (e) => {
    e.preventDefault();
    try {
      const response = await get(`/route/downloadsample`, {
        responseType: "blob",
      });

      const downloadUrl = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `sample-file.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSheetUpload = async (e) => {
    e.preventDefault();
    console.log("test ", fileChoosen);
    try {
      if (!fileChoosen) {
        console.error("No file selected.");
        return;
      }

      const formData = new FormData();
      formData.append("sheet", fileChoosen);
      const response = await put(`/route/uploadsample`, "", formData);
      console.log("Response:", response.data);
      console.log("Sheet uploaded successfully!");
      // getbooks();
    } catch (error) {
      console.error(error, "errrorrrr");
    }
  };
  return (
    <div>
      <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span
              style={{
                color: "red",
                fontFamily: "cursive",
                textAlign: "start",
              }}
            >
              Manage Route & Trips:
            </span>
          </Grid>
        </Grid>
      </Card>
      <div style={{ margin: "15px" }}>
        <Card style={{ padding: "10px", margin: "10px 0px" }}>
          <div style={styles.cardButton}>
            <Button
              variant="contained"
              onClick={() => setBulkUpdateModal(true)}
            >
              Bulk Transport Route Update
            </Button>
          </div>
        </Card>
      </div>

      <div style={{ margin: "15px" }}>
        <Paper sx={{ width: "100%", height: "60px", overflow: "hidden" }}>
          <div
            style={{
              paddingTop: "22px",
            }}
          >
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid xs="auto">
                <TextField
                  size="small"
                  value={search}
                  onChange={handleSearch}
                  label="Search"
                  placeholder="enter place route"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </Paper>
        <Modal
          open={bulkUpdateModal}
          onClose={() => setBulkUpdateModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modal}>
            <div style={{ textAlign: "center", marginBottom: "30px" }}>
              <span style={{ color: "red", fontFamily: "cursive" }}>
                Bulk Upload
              </span>
            </div>
            <form onSubmit={handleSheetUpload}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Tooltip title="Get Sample">
                    <LoadingButton
                      variant="contained"
                      size="small"
                      type="submit"
                      loading={loadingBtn}
                      // onClick={handleDownloadSample}
                      sx={{
                        backgroundColor: "#1b3779",
                        ":hover": { backgroundColor: "#1b3779" },
                      }}
                    >
                      Sample
                    </LoadingButton>
                  </Tooltip>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>
                    <Tooltip title="Upload">
                      <Button variant="contained" component="label">
                        Upload
                        <input
                          onChange={handleFileChange}
                          name="file"
                          hidden
                          accept=".xlsx, .xls, xlsm, .xlsb, .csv, .ods"
                          type="file"
                        />
                      </Button>
                    </Tooltip>

                    <div>{fileChoosen && fileChoosen.name}</div>
                  </div>
                </div>
                <div>
                  <Tooltip title="Submit">
                    <Button
                      variant="contained"
                      style={styles.Button}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </form>
          </Box>
        </Modal>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form className="form-tag" onSubmit={handleAddSubmit}>
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign="center"
                marginBottom="30px"
                onChange={handleAddForm}
              >
                {editRoute ? "update" : " Add"}
              </Typography>
              <div>
                <TextField
                  required
                  id="filled-required"
                  variant="standard"
                  label="Transport Route Title"
                  name="transportRouteTitle"
                  value={addForm.transportRouteTitle || ""}
                  onChange={handleAddForm}
                  sx={{
                    m: 1,
                    minWidth: 227,
                    label: { fontSize: 12 },
                    label: { fontSize: 12 },
                  }}
                />
                <FormControl variant="standard" sx={{ m: 0.5, minWidth: 227 }}>
                  <InputLabel required sx={{ fontSize: 12 }}>
                    Vehicle Number
                  </InputLabel>
                  <Select
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    label="vehicle number"
                    name="vehicleNumber"
                    onChange={handleAddForm}
                    value={addForm.vehicleNumber || ""}
                  >
                    {vehicleNumber.map((name) => (
                      <MenuItem key={name._id} value={name._id}>
                        {name.vehicleNumber}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  required
                  id="filled-required"
                  variant="standard"
                  sx={{ m: 1, minWidth: 227 }}
                  label="Route Start"
                  name="routeStart"
                  onChange={handleAddForm}
                  value={addForm.routeStart || ""}
                />

                <div>
                  <TextField
                    required
                    id="filled-required"
                    variant="standard"
                    sx={{ m: 1, minWidth: 227 }}
                    label="Route End"
                    name="routeEnd"
                    onChange={handleAddForm}
                    value={addForm.routeEnd || ""}
                  />
                  <TextField
                    required
                    id="filled-required"
                    variant="standard"
                    type="time"
                    value={pickStart}
                    onChange={handlePickStart}
                    label="Pick Start"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                  />

                  <TextField
                    required
                    id="filled-required"
                    variant="standard"
                    label="Pick End"
                    type="time"
                    value={pickEnd}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handlePickEnd}
                    sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                  />
                </div>

                <div>
                  <TextField
                    required
                    id="filled-required"
                    variant="standard"
                    label="Drop Start"
                    type="time"
                    value={dropStart}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleDropStart}
                    sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                  />
                  <TextField
                    required
                    id="filled-required"
                    variant="standard"
                    sx={{ m: 1, minWidth: 227 }}
                    label="Drop End"
                    type="time"
                    onChange={handleDropEnd}
                    value={dropEnd}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <FormControl
                    variant="standard"
                    sx={{ m: 0.5, minWidth: 227 }}
                  >
                    <InputLabel sx={{ fontSize: 8 }}>
                      Is multi school
                    </InputLabel>
                    <Select
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      name="isMultiSchool"
                      value={addForm.isMultiSchool || ""}
                      onChange={handleAddForm}
                    >
                      <MenuItem value={"active"}>Active</MenuItem>
                      <MenuItem value={"inactive"}>Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <table className="table table-striped">
                    <thead
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontSize: "13px",
                        marginTop: "10px",
                      }}
                    >
                      <p>Stop Name</p>
                      <p>Stop Km</p>
                      <p>Pick Start</p>
                      <p>Pick End</p>
                    </thead>

                    <tbody>
                      <TableRows
                        rows={addForm.routeDetails}
                        tableRowRemove={tableRowRemove}
                        onValUpdate={onValUpdate}
                      />
                    </tbody>
                    <button
                      style={{
                        backgroundColor: "orange",
                        color: "white",
                        marginTop: "10px",
                        fontSize: "16px",
                        padding: "8px 16px",
                        borderRadius: "4px",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onClick={addRowTable}
                    >
                      Add more
                    </button>
                  </table>
                </div>

                <div>
                  <TextareaAutosize
                    placeholder="Drop a note"
                    name="note"
                    value={addForm.note || ""}
                    onChange={handleAddForm}
                    style={{
                      width: 712,
                      maxHeight: 110,
                      margin: 10,
                      padding: 10,
                      height: 118,
                      overflow: "auto",
                    }}
                  />
                  <div style={{ textAlign: "end" }}>
                    <Tooltip title="Cancel">
                      <Button
                        onClick={handleClose}
                        variant="outlined"
                        size="medium"
                        type="button"
                        sx={{ m: 1 }}
                      >
                        Cancel
                      </Button>
                    </Tooltip>
                    <Tooltip title="Submit">
                      <Button
                        variant="contained"
                        size="medium"
                        type="submit"
                        sx={{
                          background: "rgb(27, 55, 121)",
                          ":hover": { background: "rgb(27, 55, 121)" },
                        }}
                      >
                        {editRoute ? "update" : " Add"}
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </Box>
          </form>
        </Modal>

        <Table>
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow sx={{ color: "white" }}>
              <TableCell sx={{ color: "white" }} align="center">
                #SL
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Transport Route Title
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Route Start
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Route End
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Vehicle For Route
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Stop Name
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Is Multi School
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {search
              ? searchFilter.map((route, index) => (
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">
                      {route.transportRouteTitle}
                    </TableCell>
                    <TableCell align="center">{route.routeStart}</TableCell>
                    <TableCell align="center">{route.routeEnd}</TableCell>
                    <TableCell align="center">
                      {route.vehicleNumber
                        ? route.vehicleNumber.vehicleNumber
                        : "NA"}
                    </TableCell>
                    <TableCell align="center">
                      {route.routeDetails.map((n) => n.stopName).join(" || ")}
                    </TableCell>
                    <TableCell align="center">{route.isMultiSchool}</TableCell>
                    <TableCell align="center">
                      <div className="edit-delete">
                        <Button
                          style={{ color: "#1b3779" }}
                          onClick={() => handleClassMapEdit(route._id, route)}
                        >
                          <EditIcon />
                        </Button>
                        <Button
                          style={{ color: "#1b3779" }}
                          onClick={() => handleClickOpenDialog(route)}
                        >
                          <VisibilityIcon />
                        </Button>
                        <Button
                          style={{
                            color: "#1b3779",
                          }}
                          onClick={() => setDeleteModal(route._id, route)}
                        >
                          <Delete />
                        </Button>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={route._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              : list.map((route, index) => (
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">
                      {route.transportRouteTitle}
                    </TableCell>
                    <TableCell align="center">{route.routeStart}</TableCell>
                    <TableCell align="center">{route.routeEnd}</TableCell>
                    <TableCell align="center">
                      {route.vehicleNumber
                        ? route.vehicleNumber.vehicleNumber
                        : "NA"}
                    </TableCell>
                    <TableCell align="center">
                      {route.routeDetails.map((n) => n.stopName).join(" || ")}
                    </TableCell>
                    <TableCell align="center">{route.isMultiSchool}</TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        className="edit-delete"
                      >
                        <Button
                          onClick={() => handleClassMapEdit(route._id, route)}
                        >
                          <EditIcon />
                        </Button>
                        <Button onClick={() => handleClickOpenDialog(route)}>
                          <VisibilityIcon />
                        </Button>
                        <Button
                          style={{
                            color: "#1b3779",
                          }}
                          onClick={() => setDeleteModal(route._id, route)}
                        >
                          <Delete />
                        </Button>

                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={route._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
        {!list.length && !search.trim() && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        {search.trim() && !searchFilter.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        <div className="add-icon">
          <Fab
            variant="contained"
            style={{ color: "#ffff", backgroundColor: "#1b3779" }}
            onClick={handleClickOpen}
          >
            <AddIcon />
          </Fab>
        </div>
        <BootstrapDialog
          onClose={handleCloseDialog}
          aria-labelledby="customized-dialog-title"
          open={dialog}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: "#1b3779", color: "white" }}
          >
            Trasport Route Information
          </BootstrapDialogTitle>

          <DialogContent dividers>
            <table className="hostel" style={styles.tab}>
              <tbody>
                {showDetails ? (
                  <div>
                    <tr>
                      <td>School Name</td>
                      <td>Excellent English Medium Primary & High School</td>
                    </tr>
                    <tr>
                      <td>Transport Route Title</td>
                      <td>{showDetails.transportRouteTitle}</td>
                    </tr>
                    <tr>
                      <td>Route Start</td>
                      <td>{showDetails.routeStart}</td>
                    </tr>
                    <tr>
                      <td>ROute End</td>
                      <td>{showDetails.routeEnd}</td>
                    </tr>
                    <tr>
                      <td>Vehicle For Route</td>
                      <td>{showDetails.vehicleNumber.vehicleNumber}</td>
                    </tr>

                    <tr>
                      <td>Route Stop Fare</td>
                      <td>
                        <tr
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <th>Stop Name</th>
                          <th>Stop KM</th>
                        </tr>
                        {showDetails.routeDetails.map((route, index) => (
                          <tr key={index}>
                            <td className="borderName">{route.stopName}</td>
                            <td className="borderPosition">{route.stopKM}</td>
                          </tr>
                        ))}
                      </td>
                    </tr>

                    <tr>
                      <td>Note</td>
                      <td>{showDetails.note}</td>
                    </tr>
                  </div>
                ) : null}
              </tbody>
            </table>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </div>
  );
};

export default ManageRoute;
