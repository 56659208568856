import { TableRows } from "@mui/icons-material";
import {
  Box,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextareaAutosize,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Menu from "@mui/material/Menu";

import {
  Button,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../../styles/ProfileTable/ProfileTable.scss";
import { urls } from "../../../services/urlConstant";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const styles = {
  icon: {
    padding: "10px",
    fontSize: "12px",
    fontWeight: 500,
  },
  card: {
    padding: 15,
    marginBottom: 20,
    marginLeft: "1rem",
    marginRight: "1rem",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  tab: {
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: "5px",
  },
  Button: {
    background: "#1b3779",
    marginLeft: "20px",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}
const style = {
  card: {
    color: "red",
    fontFamily: "cursive",
    textAlign: "start",
  },
  card1: {
    padding: 15,
    marginBottom: 20,
    margin: "70px 200px",
  },
};

const NavbarProfile = () => {
  const [tabValue, setTabValue] = useState(0);

  const [dob, setDob] = useState(dayjs());
  const [join, setJoin] = useState(dayjs());
  const [resume, setresume] = useState(null);
  const [photo, setPhoto] = useState(null);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <div>
      <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <span style={style.card}>Profile</span>
          </Grid>
        </Grid>
      </Card>
      <Card style={{ margin: 15, padding: 10 }}>
        <Box>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example">
            <Tab label="Profile" {...a11yProps(0)} />
            <Tab label="Update" {...a11yProps(1)} />
          </Tabs>
        </Box>
      </Card>
      <TabPanel value={tabValue} index={0}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}></Grid>

          <Grid item xs={12} md={12} lg={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                color: "red",
              }}>
              <img
                src="/principal.jpg"
                height={85}
                width={75}
                style={{ borderRadius: "50%" }}
              />
            </div>
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                color: "red",
              }}>
              Admin
            </span>
          </Grid>
        </Grid>

        <table className="profile" width="100%" style={styles.tab}>
          <tbody
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}>
            <div>
              <tr>
                <td>Name</td>
                <td>Adminhhjfkjkjkjfkjjfj</td>
              </tr>
              <tr>
                <td>Designation</td>
                <td>Admin</td>
              </tr>
              <tr>
                <td>Present Address</td>
                <td>Bengalore</td>
              </tr>
              <tr>
                <td>Gender</td>
                <td>male</td>
              </tr>
              <tr>
                <td>Religion</td>
                <td>male</td>
              </tr>
              <tr>
                <td>Salary Grade</td>
                <td>A</td>
              </tr>
              <tr>
                <td>Email</td>
                <td></td>
              </tr>

              <tr>
                <td>Joining Date</td>
                <td></td>
              </tr>
            </div>
            <div>
              <tr>
                <td>National Id</td>
                <td>XYZ</td>
              </tr>
              <tr>
                <td>Phone</td>
                <td>abc</td>
              </tr>
              <tr>
                <td>Permanent Address</td>
                <td>email</td>
              </tr>
              <tr>
                <td>Blood Group</td>
                <td>male</td>
              </tr>

              <tr>
                <td>Birth Date</td>
                <td></td>
              </tr>
              <tr>
                <td>Salary Type</td>
                <td>Monthly</td>
              </tr>
              <tr>
                <td>Other Info</td>
                <td></td>
              </tr>
              <tr>
                <td>Resume</td>
                <td></td>
              </tr>
            </div>
          </tbody>
        </table>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Card style={styles.card}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} lg={3}>
                <h5 style={{ color: "#1b3779" }}>Basic Information:</h5>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="filled-"
                  variant="standard"
                  label="Name"
                  name="name"
                  required
                  style={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="filled-"
                  variant="standard"
                  label="National Id"
                  name="nationalId"
                  style={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="filled-"
                  variant="standard"
                  label="Designation"
                  name="designation"
                  style={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="filled-"
                  variant="standard"
                  label="Phone"
                  name="phone"
                  required
                  style={styles.textfield}
                />
              </Grid>

              {/* <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="filled-"
                  variant="standard"
                  label="Admission No"
                  style={styles.textfield}
                  disabled
                />
                <FormHelperText>Auto generated</FormHelperText>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Admission Date"
                    value={admissionDate}
                    inputFormat="DD-MM-YYYY"
                    onChange={(newValue) => {
                      setAdmissionDate(newValue);
                    }}
                    required
                    renderInput={(params) => (
                      <TextField
                        style={styles.textfield}
                        variant="standard"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date of Birth"
                    inputFormat="DD-MM-YYYY"
                    value={dob}
                    onChange={(newValue) => {
                      setDob(newValue);
                    }}
                    required
                    renderInput={(params) => (
                      <TextField
                        style={styles.textfield}
                        variant="standard"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid> */}
              <Grid item xs={12} md={6} lg={3}>
                <FormControl
                  variant="standard"
                  style={styles.textfield}
                  required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Gender
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="gender">
                    <MenuItem
                      value={"male"}
                      sx={{ fontSize: 12, fontWeight: 500 }}>
                      Male
                    </MenuItem>
                    <MenuItem
                      value={"female"}
                      sx={{ fontSize: 12, fontWeight: 500 }}>
                      Female
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl
                  variant="standard"
                  style={styles.textfield}
                  required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Blood Group
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="gender">
                    <MenuItem
                      value={"a+"}
                      sx={{ fontSize: 12, fontWeight: 500 }}>
                      A+
                    </MenuItem>
                    <MenuItem
                      value={"a-"}
                      sx={{ fontSize: 12, fontWeight: 500 }}>
                      A-
                    </MenuItem>
                    <MenuItem
                      value={"b+"}
                      sx={{ fontSize: 12, fontWeight: 500 }}>
                      B+
                    </MenuItem>
                    <MenuItem
                      value={"b-"}
                      sx={{ fontSize: 12, fontWeight: 500 }}>
                      B-
                    </MenuItem>
                    <MenuItem
                      value={"o+"}
                      sx={{ fontSize: 12, fontWeight: 500 }}>
                      O+
                    </MenuItem>
                    <MenuItem
                      value={"o-"}
                      sx={{ fontSize: 12, fontWeight: 500 }}>
                      O-
                    </MenuItem>
                    <MenuItem
                      value={"ab+"}
                      sx={{ fontSize: 12, fontWeight: 500 }}>
                      AB+
                    </MenuItem>
                    <MenuItem
                      value={"ab-"}
                      sx={{ fontSize: 12, fontWeight: 500 }}>
                      AB-
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="filled-"
                  variant="standard"
                  label="Religion"
                  name="religion"
                  sx={{ m: 0.5 }}
                  style={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date of Birth"
                    inputFormat="DD-MM-YYYY"
                    value={dob}
                    onChange={(newValue) => {
                      setDob(newValue);
                    }}
                    required
                    renderInput={(params) => (
                      <TextField
                        style={styles.textfield}
                        variant="standard"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <TextareaAutosize
                  placeholder="Present Address"
                  name="presentaddress"
                  style={{
                    width: 500,
                    maxHeight: 80,
                    margin: 5,
                    padding: 10,
                    height: 80,
                    overflow: "auto",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <TextareaAutosize
                  placeholder="Permanent Address"
                  name="permanentaddress"
                  style={{
                    width: 500,
                    maxHeight: 80,
                    margin: 5,
                    padding: 10,
                    height: 80,
                    overflow: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Card>
        {/* <Card style={styles.card}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} lg={3}>
                <h5 style={{ color: "#1b3779" }}>Parent Information:</h5>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="filled-"
                  variant="standard"
                  label="Father Name"
                  name="fatherName"
                  required
                  style={styles.textfield}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="filled-"
                  variant="standard"
                  label="Father Phone No"
                  name="fatherPhoneNo"
                  style={styles.textfield}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="filled-"
                  variant="standard"
                  label="Mother Name"
                  name="motherName"
                  required
                  style={styles.textfield}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="filled-"
                  variant="standard"
                  label="Mother Phone No"
                  name="motherPhoneNo"
                  style={styles.textfield}
                />
              </Grid>
            </Grid>
          </Box>
        </Card> */}
        <Card style={styles.card}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <h5 style={{ color: "#1b3779" }}>Academic Information:</h5>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="filled-"
                  variant="standard"
                  label="Email"
                  name="email"
                  required
                  style={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="filled-"
                  variant="standard"
                  label="User Name"
                  name="nationalId"
                  style={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date of Joining"
                    inputFormat="DD-MM-YYYY"
                    value={join}
                    onChange={(newValue) => {
                      setJoin(newValue);
                    }}
                    required
                    renderInput={(params) => (
                      <TextField
                        style={styles.textfield}
                        variant="standard"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <p style={{ color: "#1b3779", marginLeft: "20px" }}>
                  Upload Resume:
                </p>
                <Button
                  variant="contained"
                  component="label"
                  sx={styles.Button}>
                  Upload
                  <input hidden accept="image/*" multiple type="file" />
                </Button>

                {resume && (
                  <img
                    src={resume}
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "contain",
                      marginLeft: " 40px",
                    }}
                    alt="Preview"
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </Card>

        <Card style={styles.card}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <h5 style={{ color: "#1b3779" }}>Other Information:</h5>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="facebookUrl"
                  id="filled-required"
                  variant="standard"
                  label="Facebook URL"
                  sx={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="twitterUrl"
                  id="filled-required"
                  variant="standard"
                  label="Twitter URL"
                  sx={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="linkedinUrl"
                  id="filled-required"
                  variant="standard"
                  label="Linkedin URL"
                  sx={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="gplusUrl"
                  id="filled-required"
                  variant="standard"
                  label="Google Plus URL"
                  sx={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="youtubeUrl"
                  id="filled-required"
                  variant="standard"
                  label="Youtube URL"
                  sx={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="filled-required"
                  variant="standard"
                  label="Instagram URL"
                  sx={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="pinterestUrl"
                  id="filled-required"
                  variant="standard"
                  label="Pinterest URL "
                  sx={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <TextareaAutosize
                  placeholder="Other Info"
                  name="otherInfo"
                  style={{
                    width: 500,
                    maxHeight: 80,
                    margin: 5,
                    padding: 10,
                    height: 80,
                    overflow: "auto",
                  }}
                />
              </Grid>

              <Grid item xs={12} md={12} lg={6}>
                <p style={{ color: "#1b3779", marginLeft: "20px" }}>
                  Upload photo:
                </p>
                <Button
                  variant="contained"
                  component="label"
                  sx={styles.Button}>
                  Upload
                  <input hidden accept="image/*" multiple type="file" />
                </Button>

                {photo && (
                  <img
                    src={photo}
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "contain",
                      marginLeft: " 40px",
                    }}
                    alt="Preview"
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </Card>

        <Card style={{ margin: "15px" }}>
          <div style={{ textAlign: "end" }}>
            <Tooltip title="Cancel">
              <Button
                variant="outlined"
                size="medium"
                type="button"
                sx={{ m: 1 }}>
                Cancel
              </Button>
            </Tooltip>
            <Tooltip title="Submit">
              <Button
                variant="contained"
                size="medium"
                type="submit"
                sx={{
                  marginRight: "1px",
                  background: "rgb(27, 55, 121)",
                  ":hover": { background: "rgb(27, 55, 121)" },
                }}>
                Upload
              </Button>
            </Tooltip>
          </div>
        </Card>
      </TabPanel>
    </div>
  );
};
export default NavbarProfile;
