import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Card,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import DeleteModal from "../Academic/DeleteModal";
import { del, get, post, put } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import { objHas } from "../../../utils/utils";
import TablePagination from "@mui/material/TablePagination";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import { LoadingButton } from "@mui/lab";
import {
  PrintSharp,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import DownloadForOfflineSharpIcon from "@mui/icons-material/DownloadForOfflineSharp";
import { toast } from "react-toastify";
const styles = {
  icon: {
    padding: "10px",
    fontSize: "12px",
    fontWeight: 500,
  },
  card: {
    padding: 15,
    marginBottom: 20,
    width: "100%",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
};

const BalanceFeeReport = () => {
  const [academicYears, setAcademicYears] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [formData, setFormData] = useState([]);
  const [receipts, setReceipts] = useState([]);
  const [schoolStudents, setSchoolStudents] = useState([]);
  const [showPagination, setShowPagination] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [page, setPage] = useState(0);
  const [sheetLoader, setSheetLoader] = useState(false);
  const [totalValue, setTotalValue] = useState(0);

  const [sort, setSort] = useState({
    rollNumber: "asc",
    classNumber: "asc",
    section: "asc",
    name: "asc",
    payableAmount: "asc",
  });

  const sortByRollNo = () => {
    console.log("RollNumber");
    if (sort.rollNumber === "asc") {
      let sortedList = [
        ...schoolStudents.sort(
          (a, b) =>
            b.latestReceipt.studentDetails.rollNumber -
            a.latestReceipt.studentDetails.rollNumber
        ),
      ];

      setSchoolStudents(sortedList);
      setSort({ ...sort, rollNumber: "des" });
    } else {
      let sortedList = [
        ...schoolStudents.sort(
          (a, b) =>
            a.latestReceipt.studentDetails.rollNumber -
            b.latestReceipt.studentDetails.rollNumber
        ),
      ];
      setSchoolStudents(sortedList);
      setSort({ ...sort, rollNumber: "asc" });
    }
  };

  const sortByClassNumber = () => {
    console.log("ClassNumber");
    if (sort.classNumber === "asc") {
      let sortedList = [
        ...schoolStudents.sort(
          (a, b) =>
            b.latestReceipt.studentDetails.classNumber -
            a.latestReceipt.studentDetails.classNumber
        ),
      ];

      setSchoolStudents(sortedList);
      setSort({ ...sort, classNumber: "des" });
    } else {
      let sortedList = [
        ...schoolStudents.sort(
          (a, b) =>
            a.latestReceipt.studentDetails.classNumber -
            b.latestReceipt.studentDetails.classNumber
        ),
      ];
      setSchoolStudents(sortedList);
      setSort({ ...sort, classNumber: "asc" });
    }
  };

  const sortByAmount = () => {
    console.log("Amount");
    if (sort.payableAmount === "asc") {
      let sortedList = [
        ...schoolStudents.sort(
          (a, b) =>
            b.latestReceipt.payableAmount - a.latestReceipt.payableAmount
        ),
      ];

      setSchoolStudents(sortedList);
      setSort({ ...sort, payableAmount: "des" });
    } else {
      let sortedList = [
        ...schoolStudents.sort(
          (a, b) =>
            a.latestReceipt.payableAmount - b.latestReceipt.payableAmount
        ),
      ];
      setSchoolStudents(sortedList);
      setSort({ ...sort, payableAmount: "asc" });
    }
  };

  const sortBySection = () => {
    console.log("Section");
    if (sort.section === "asc") {
      let sortedList = [
        ...schoolStudents.sort((a, b) =>
          b.latestReceipt.studentDetails.section.localeCompare(
            a.latestReceipt.studentDetails.section
          )
        ),
      ];

      setSchoolStudents(sortedList);
      setSort({ ...sort, section: "des" });
    } else {
      let sortedList = [
        ...schoolStudents.sort((a, b) =>
          a.latestReceipt.studentDetails.section.localeCompare(
            b.latestReceipt.studentDetails.section
          )
        ),
      ];
      setSchoolStudents(sortedList);
      setSort({ ...sort, section: "asc" });
    }
  };

  const sortByName = () => {
    console.log("Name");
    if (sort.name === "asc") {
      let sortedList = [
        ...schoolStudents.sort((a, b) =>
          b.latestReceipt.studentDetails.name.localeCompare(
            a.latestReceipt.studentDetails.name
          )
        ),
      ];

      setSchoolStudents(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      let sortedList = [
        ...schoolStudents.sort((a, b) =>
          a.latestReceipt.studentDetails.name.localeCompare(
            b.latestReceipt.studentDetails.name
          )
        ),
      ];
      setSchoolStudents(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };

  const calculateTotalValue = (data) => {
    let total = 0;
    data.forEach((item) => {
      total += item.value; // Adjust the property name according to your data structure
    });
    return total;
  };

  useEffect(() => {
    const newTotalValue = calculateTotalValue(schoolStudents);
    setTotalValue(newTotalValue);
  }, [schoolStudents]);
  console.log(totalValue, "tottalvalue");

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll);
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data[0]._id,
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);

  useEffect(() => {
    const getAllReceipts = async () => {
      try {
        const res = await get(urls.recepitBook.getAll);
        setFormData((prev) => ({
          ...prev,
          name: res.data.data[0]._id,
        }));
        setReceipts(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAllReceipts();
  }, []);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      setFormData((prev) => ({
        ...prev,
        section: "all",
      }));
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        const resSections = await get(urls.schoolSection.getAllSection, {
          params: {
            search: {
              class: res.data.data[0]._id,
            },
          },
        });
        const searchStudents = await get(urls.students.getAllStudent, {
          params: {
            search: {
              academicYear: formData.academicYear,
              "academicInfo.class": res.data.data[0]._id,
            },
          },
        });
        // setSchoolStudents(searchStudents.data.data);
        setSections(resSections.data.data);
        setClasses(res.data.data);
        setFormData((prev) => ({
          ...prev,
          className: res.data.data[0]._id,
          section: "all",
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);

  const handleFeeSearchSubmit = async (e) => {
    e.preventDefault();
    try {
      setShowPagination(true);

      const searchParams = {
        params: {
          academicYearId: formData.academicYear,
          receiptType: formData.receipt,
          classId: formData.schoolClass,
          sectionId: formData.section,
        },
      };

      if (formData.section === "all") {
        const searchStudents = await get(urls.balanceFeereceipt.getAllSection, {
          params: {
            academicYearId: formData.academicYear,
            receiptType: formData.receipt,
            classId: formData.schoolClass,
          },
        });
        console.log(searchStudents, "searchstuuu");

        setPage(0);
        setSchoolStudents(searchStudents.data.data);
        // setTotalCount(searchStudents.data.count);
      } else {
        const searchStudent = await get(
          urls.balanceFeereceipt.getAll,
          searchParams
        );
        console.log(searchStudent, "search////////////////////////////");
        setPage(0);
        setSchoolStudents(searchStudent.data.data);
        // setTotalCount(searchStudent.data.count);
      }

      // setFormData({});
    } catch (error) {
      console.log(error);
    }
  };

  console.log("total", totalCount);
  console.log("stuude", schoolStudents);
  const handleMarksExcelDownload = async () => {
    try {
      setSheetLoader(true);
      if (
        formData.academicYear &&
        formData.schoolClass &&
        formData.section !== "all" &&
        formData.receipt
      ) {
        const getMarksListSheet = await get(
          `/fee-receipt/download/report/balance-fee`,
          {
            params: {
              academicYearId: formData.academicYear,
              receiptType: formData.receipt,
              classId: formData.schoolClass,
              sectionId: formData.section,
            },
            responseType: "blob",
          }
        );

        if (getMarksListSheet.status === 200) {
          const uri = URL.createObjectURL(getMarksListSheet.data);
          const link = document.createElement("a");
          link.href = uri;
          const academicYearGet = formData.academicYear;
          const getExcelByYearId = academicYears.find(
            (excel) => excel._id === academicYearGet
          );
          const classename = formData.schoolClass;
          const getExcelByClassId = classes.find(
            (excel) => excel._id === classename
          );
          const sectionsname = formData.section;
          const getExcelsectionById = sections.find(
            (excel) => excel._id === sectionsname
          );
          const receiptss = formData.receipt;
          const getExcelreceiptById = receipts.find(
            (excel) => excel._id === receiptss
          );

          link.setAttribute(
            "download",
            `${getExcelByYearId.academicYearFrom}-${getExcelByYearId.academicYearTo} ${getExcelByClassId.className}th std , ${getExcelreceiptById.name}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          console.log(getExcelByYearId, "getyear");
          console.log(getExcelsectionById, "getSec");

          link.parentNode.removeChild(link);
        }
      } else if (
        formData.academicYear &&
        formData.schoolClass &&
        formData.section == "all" &&
        formData.receipt
      ) {
        const getMarksListSheet = await get(
          `/fee-receipt/download/report/all-sections/balance-fee`,
          {
            params: {
              academicYearId: formData.academicYear,
              receiptType: formData.receipt,
              classId: formData.schoolClass,
            },
            responseType: "blob",
          }
        );

        if (getMarksListSheet.status === 200) {
          const uri = URL.createObjectURL(getMarksListSheet.data);
          const link = document.createElement("a");
          link.href = uri;
          const academicYearGet = formData.academicYear;
          const getExcelByYearId = academicYears.find(
            (excel) => excel._id === academicYearGet
          );
          const classename = formData.schoolClass;
          const getExcelByClassId = classes.find(
            (excel) => excel._id === classename
          );
          const sectionsname = formData.section;
          const getExcelsectionById = sections.find(
            (excel) => excel._id === sectionsname
          );
          const receiptss = formData.receipt;
          const getExcelreceiptById = receipts.find(
            (excel) => excel._id === receiptss
          );

          link.setAttribute(
            "download",
            `${getExcelByYearId.academicYearFrom}-${getExcelByYearId.academicYearTo} ${getExcelByClassId.className}th std ,${getExcelByClassId.sectionName} ${getExcelreceiptById.name}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      }

      setSheetLoader(false);
    } catch (error) {
      console.log(error, "error");
      toast.error(error.message);
    }
  };
  return (
    <div>
      <div>
        <Card style={{ margin: 15, padding: 10 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} mg={12} lg={12}>
              <span
                style={{
                  color: "red",
                  fontFamily: "cursive",
                }}
              >
                Balance Fee Report:
              </span>
            </Grid>
          </Grid>
        </Card>
        <Card style={{ margin: 15, padding: 10 }}>
          <Box sx={{ flexGrow: 1 }}>
            <form onSubmit={handleFeeSearchSubmit}>
              <Grid item xs={12} md={6} lg={2}>
                <FormControl
                  variant="standard"
                  style={{ width: "200px", margin: "0px 0px 20px -77%" }}
                  required
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Academic Year
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="academicYear"
                    value={formData.academicYear || ""}
                    onChange={handleOnChange}
                  >
                    {academicYears &&
                      academicYears.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.academicYearFrom} - {row.academicYearTo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl
                    variant="standard"
                    style={{ width: "200px" }}
                    required
                  >
                    <InputLabel id="demo-simple-select-filled-label">
                      Class
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="schoolClass"
                      value={formData.schoolClass || ""}
                      onChange={handleClassChange}
                    >
                      {classes &&
                        classes.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.className}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl
                    variant="standard"
                    style={{ width: "200px" }}
                    required
                  >
                    <InputLabel id="demo-simple-select-filled-label">
                      Section
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="section"
                      value={formData.section || ""}
                      onChange={handleOnChange}
                    >
                      {formData.schoolClass && (
                        <MenuItem value="all">--all--</MenuItem>
                      )}
                      {sections &&
                        sections.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.sectionName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl
                    variant="standard"
                    style={{ width: "200px" }}
                    required
                  >
                    <InputLabel id="demo-simple-select-filled-label">
                      Receipt
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="receipt"
                      value={formData.receipt || ""}
                      onChange={handleOnChange}
                    >
                      {receipts &&
                        receipts.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={1}
                  style={{ alignSelf: "flex-end" }}
                >
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    style={styles.Button}
                  >
                    find
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Card>
      </div>
      <div style={{ margin: 7, padding: 10 }}>
        <div>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead style={{ background: "rgb(27 55 121)" }}>
                  <TableRow>
                    <TableCell align="center">
                      <span className="class-table-header">#SL</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Roll No</span>
                      <span
                        onClick={sortByRollNo}
                        style={{ cursor: "pointer", color: "#fff" }}
                      >
                        {sort.rollNumber === "asc" ? "▲" : "▼"}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Class</span>
                      <span
                        onClick={sortByClassNumber}
                        style={{ cursor: "pointer", color: "#fff" }}
                      >
                        {sort.classNumber === "asc" ? "▲" : "▼"}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Section</span>
                      <span
                        onClick={sortBySection}
                        style={{ cursor: "pointer", color: "#fff" }}
                      >
                        {sort.section === "asc" ? "▲" : "▼"}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Name</span>
                      <span
                        onClick={sortByName}
                        style={{ cursor: "pointer", color: "#fff" }}
                      >
                        {sort.name === "asc" ? "▲" : "▼"}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Father</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Phone</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Amount</span>
                      <span
                        onClick={sortByAmount}
                        style={{ cursor: "pointer", color: "#fff" }}
                      >
                        {sort.payableAmount === "asc" ? "▲" : "▼"}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Concession</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Fine</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Paid</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Balance</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {schoolStudents.map((row, index) => (
                    <TableRow
                      key={row.latestReceipt._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.latestReceipt.studentDetails.rollNumber}
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.latestReceipt.studentDetails.classNumber}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.latestReceipt.studentDetails.section}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.latestReceipt.studentDetails.name}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.latestReceipt.studentDetails.parentName}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.latestReceipt.studentDetails.contactNumber}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.latestReceipt.payableAmount}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.latestReceipt.totalConcession}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.latestReceipt.totalPenalty}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.latestReceipt.payableAmount -
                          row.latestReceipt.netDue}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.latestReceipt.netDue}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {!schoolStudents.length && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            )}
          </Paper>
        </div>
        {/* <div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{ background: "rgb(27 55 121)" }}>
              <TableRow>
                <TableCell align="center">
                  <span className="class-table-header">#SL</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Roll No</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Class</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Section</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Name</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Father</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Phone</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Amount</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Concession</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Fine</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Paid</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Balance</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {totalValue.map((row, index) => (
                <TableRow
                  key={row.latestReceipt._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row.latestReceipt.payableAmount}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row.latestReceipt.totalConcession}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row.latestReceipt.totalPenalty}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row.latestReceipt.payableAmount-row.latestReceipt.netDue}
                  
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                      {row.latestReceipt.netDue}
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!totalValue.length && <Typography variant ="h6" sx={{textAlign:"center" ,margin:"5px" ,padding:"5px"}}>
            No data found
          </Typography>

          
        } 
      </Paper>
      </div> */}
        {formData.academicYear &&
          formData.receipt &&
          formData.schoolClass &&
          formData.section && (
            <div>
              <div>
                {sheetLoader ? (
                  <Button size="small">
                    <CircularProgress size={25} />
                  </Button>
                ) : (
                  <Button
                    onClick={handleMarksExcelDownload}
                    size="small"
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: "990px", marginTop: "10px" }}
                  >
                    DOWNLOAD
                  </Button>
                )}
              </div>
            </div>
          )}
      </div>
    </div>
  );
};
export default BalanceFeeReport;
