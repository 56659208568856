import {
  Box,
  Button,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { Add } from "@mui/icons-material";

import { useState, useEffect } from "react";
import { urls } from "../../../services/urlConstant";
import { get, post, put, del } from "../../../services/apis";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 400,
  bgcolor: "background.paper",
  p: 4,
};

const AddSplashNews = (props) => {
  const [editFlash, setEditFlash] = useState();
  const [addForm, setAddForm] = useState({});

  useEffect(() => {
    if (props.editData) {
      setAddForm(props.editData);
      setEditFlash(props.editData._id);
    } else {
      setAddForm({});
      setEditFlash(null);
    }
  }, [props.editData]);

  const handleCloseModal = () => {
    props.handleClose();
    resetForm();
  };
  const handleClose = () => {
    props.handleClose();
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAddForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };
  const resetForm = () => {
    setAddForm({});
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!editFlash) {
        const { status } = await post(
          `${urls.announceFlashNews.postFlashNews}`,
          {
            title: addForm.title,
            link: addForm.link,
            webView: addForm.webView,
            type: addForm.type,
          }
        );
        props.getSplashNews();
        handleClose();
      } else {
        const { status } = await put(
          `${urls.announceFlashNews.putFlashNews}${editFlash}`,
          "",
          {
            title: addForm.title,
            link: addForm.link,
            webView: addForm.webView,
            type: addForm.type,
          }
        );
        props.getSplashNews();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Fab
        size="medium"
        style={{ background: "#1b3779", color: "#fff" }}
        aria-label="add"
        onClick={props.handleOpen}
      >
        <Tooltip title="Add">
          <Add />
        </Tooltip>
      </Fab>
      <Modal
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleAddSubmit}>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
              marginBottom="20px"
            >
              {editFlash ? "update" : " Add"}
            </Typography>
            <div className="">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    variant="standard"
                    label=" Splash News Title"
                    name="title"
                    value={addForm.title || ""}
                    onChange={handleInputChange}
                    sx={{ m: 1, width: "100%", label: { fontSize: 12 } }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    variant="standard"
                    label="Link"
                    name="link"
                    value={addForm.link || ""}
                    onChange={handleInputChange}
                    sx={{ m: 1, width: "100%", label: { fontSize: 12 } }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 345 }}>
                    <InputLabel sx={{ fontSize: 12 }}>Type</InputLabel>
                    <Select
                      value={addForm.type || ""}
                      name="type"
                      onChange={handleInputChange}
                    >
                      <MenuItem
                        sx={{ fontSize: 12, fontWeight: 500 }}
                        value={"Popup"}
                      >
                        Popup
                      </MenuItem>
                      <MenuItem
                        sx={{ fontSize: 12, fontWeight: 500 }}
                        value={"Horizontal"}
                      >
                        Horizontal
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 345 }}>
                    <InputLabel sx={{ fontSize: 12 }}>
                      Is view on web?
                    </InputLabel>
                    <Select
                      value={addForm.webView || ""}
                      name="webView"
                      onChange={handleInputChange}
                    >
                      <MenuItem
                        sx={{ fontSize: 12, fontWeight: 500 }}
                        value={"yes"}
                      >
                        Yes
                      </MenuItem>
                      <MenuItem
                        sx={{ fontSize: 12, fontWeight: 500 }}
                        value={"no"}
                      >
                        No
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>

            <div className="cancel-submit-btn">
              <div>
                <Tooltip title="Cancel">
                  <Button
                    variant="outlined"
                    size="medium"
                    type="button"
                    sx={{ m: 1 }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Tooltip>
                <Tooltip title="Submit">
                  <Button
                    variant="contained"
                    size="medium"
                    type="submit"
                    sx={{
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                    }}
                  >
                    Submit
                  </Button>
                </Tooltip>
              </div>
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
};

export default AddSplashNews;
