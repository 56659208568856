import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { Delete } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import dayjs from "dayjs";
import VehicleMaintenanceView from "./components/VehicleMaintenanceView";
import VehicleMaintenanceGreecing from "./components/VehicleMaintenanceGreecing";
import VehicleMaintenanceRepair from "./components/VehicleMaintenanceRepair";
import VehicleMaintenanceTire from "./components/VehicleMaintenanceTire";
import Firm from "./components/Firm";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

const styles = {
  cardsMargin: {
    margin: "15px",
  },
  cardStyle: {
    margin: "15px",
    padding: "10px",
  },
  icon: {
    padding: "10px",
    fontSize: "12px",
    fontWeight: 500,
  },
  card: {
    padding: 15,
    marginBottom: 20,
    width: "100%",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
  addbutton: {
    paddingTop: "6.2px",
    paddingBottom: "6.2px",
  },
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const VehicleMaintenance = () => {
  const [tabValue, setTabValue] = useState(0);
  const [date, setDate] = useState(dayjs(Date.now()));
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <div>
      <div>
        <Card style={styles.cardStyle}>
          <Grid container spacing={2}>
            <Grid item xs={12} mg={12} lg={12}>
              <span
                style={{
                  color: "red",
                  fontFamily: "cursive",
                  textAlign: "start",
                }}>
                Transport Maintenance
              </span>
            </Grid>
          </Grid>
        </Card>
        <Card style={styles.cardsMargin}>
          <Box>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example">
              <Tab label="Vehicle Fuel" {...a11yProps(0)} />
              <Tab label="Repair Maintenance" {...a11yProps(1)} />
              <Tab label="New Tire/Resole" {...a11yProps(2)} />
              <Tab label="Greecing" {...a11yProps(3)} />
              <Tab label="Firm" {...a11yProps(4)} />
            </Tabs>
          </Box>
        </Card>
        <TabPanel value={tabValue} index={0}>
          <VehicleMaintenanceView />
          </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <VehicleMaintenanceRepair  />
          </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <VehicleMaintenanceTire />
          
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <VehicleMaintenanceGreecing />
       </TabPanel>
       <TabPanel value={tabValue} index={4}>
          <Firm />
       </TabPanel>
      </div>
    </div>
  );
};

export default VehicleMaintenance;
