import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Button,
  Grid,
  Tooltip,
} from "@mui/material";
import "../../../styles/components/AcademicYear/DateModal.scss";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 500,
  bgcolor: "background.paper",
  p: 4,
};

const AddHoliday = (props) => {
  const [title, setTitle] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [note, setNote] = useState("");
  const [webView, setWebView] = useState("");

  useEffect(() => {
    if (props.updateHoliday) {
      setTitle(props.updateHoliday.title);
      setFromDate(dayjs(props.updateHoliday.fromDate).format("YYYY-MM-DD"));
      setToDate(dayjs(props.updateHoliday.toDate).format("YYYY-MM-DD"));
      setNote(props.updateHoliday.note);
      setWebView(props.updateHoliday.webView);
    }
  }, [props.updateHoliday]);

  const handleTitle = (e) => {
    setTitle(e.target.value);
  };
  const handleFromDate = (e) => {
    setFromDate(e.target.value);
  };
  const handleToDate = (e) => {
    setToDate(e.target.value);
  };
  const handleNote = (e) => {
    setNote(e.target.value);
  };
  const handleWebView = (e) => {
    setWebView(e.target.value);
  };

  const handleSubmit = (e) => {
    if (props.updateHoliday) {
      props.handleUpdate(
        {
          title,
          fromDate: dayjs(fromDate).toDate(),
          toDate: dayjs(toDate).toDate(),
          note,
          webView,
        },
        props.updateHoliday._id
      );
    } else {
      props.handleSubmit(e, {
        title,
        fromDate: dayjs(fromDate).toDate(),
        toDate: dayjs(toDate).toDate(),
        note,
        webView,
      });
    }
    setTitle("");
    setFromDate("");
    setToDate("");
    setNote("");
    setWebView("");

    props.handleClose();
  };

  const handleCloseModal = () => {
    setTitle("");
    setFromDate("");
    setToDate("");
    setNote("");
    setWebView("");

    props.handleClose();
  };
  const handleClose = () => {
    setTitle("");
    setFromDate("");
    setToDate("");
    setNote("");
    setWebView("");
    props.handleClose();
  };

  return (
    <div>
      <Fab
        size="medium"
        style={{ background: "#1b3779", color: "#fff" }}
        aria-label="add"
        onClick={props.handleOpen}
      >
        <Tooltip title="Add">
          <Add />
        </Tooltip>
      </Fab>
      <Modal
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleSubmit}>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
              marginBottom="20px"
            >
              Add Holiday
            </Typography>
            <div className="">
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <TextField
                    required
                    variant="standard"
                    label="Holiday Title"
                    value={title}
                    onChange={handleTitle}
                    sx={{ m: 1, width: "100%", label: { fontSize: 12 } }}
                  />
                </Grid>
              </Grid>
              <TextField
                required
                variant="standard"
                label="From Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={fromDate}
                onChange={handleFromDate}
                sx={{ m: 1, minWidth: 341, label: { fontSize: 12 } }}
              />
              <TextField
                required
                variant="standard"
                label="To Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={toDate}
                onChange={handleToDate}
                sx={{ m: 1, minWidth: 362, label: { fontSize: 12 } }}
              />
              <FormControl
                variant="standard"
                sx={{ m: 1, width: "98%" }}
                required
              >
                <InputLabel sx={{ fontSize: 12 }}>Is view on web?</InputLabel>
                <Select value={webView} onChange={handleWebView}>
                  <MenuItem
                    value={"yes"}
                    sx={{ fontSize: 12, fontWeight: 500 }}
                  >
                    Yes
                  </MenuItem>
                  <MenuItem value={"no"} sx={{ fontSize: 12, fontWeight: 500 }}>
                    No
                  </MenuItem>
                </Select>
              </FormControl>
              <TextareaAutosize
                maxRows={4}
                aria-label="maximum height"
                placeholder="Drop a note"
                value={note}
                onChange={handleNote}
                style={{
                  width: 712,
                  maxHeight: 110,
                  margin: 8,
                  padding: 10,
                  height: 70,
                  overflow: "auto",
                }}
              />
            </div>

            <div className="cancel-submit-btn">
              <div>
                <Tooltip title="Cancel">
                  <Button
                    variant="outlined"
                    size="medium"
                    type="button"
                    sx={{ m: 1 }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Tooltip>
                <Tooltip title="Submit">
                  <Button
                    variant="contained"
                    size="medium"
                    type="submit"
                    sx={{
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                    }}
                  >
                    Submit
                  </Button>
                </Tooltip>
              </div>
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
};

export default AddHoliday;
