import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "./../styles/components/navbar/Navbar.scss";
import { Language } from "@mui/icons-material";
import "./../styles/components/navbar/NavTop.scss";
import css from './Addmission.module.css'
import Footer from "./Footer/Footer";
import TopNav from "./navbar/TopNav";
import Navbar from "./navbar/Navbar";
import Demo from "./NewsNotic/Demo";

const UniqueFeatures = () => {
    const [addClass, updateClass] = useState(false);
  const [stkyClass, setStkyClass] = useState(false);
  const [mega, setMega] = useState(false);

  const handleClickCross = () => {
    updateClass(false);
    window.scrollTo(0, 0);
  };
  const scrollHandleScroll = () => {
    if (window.scrollY > 20) {
      setStkyClass(true);
    } else {
      setStkyClass(false);
    }
  };
  const handleMega = () => {
    setMega(!mega);
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollHandleScroll);
  }, []);

  return (
    <>
        <TopNav />
        <Navbar />
      <div className={css.headerContainer}>
                <div>
                    <h1>UNIQUE FEATURES</h1>
                    <h5><Link style={{
                        color:'white'
                    }} to='/'>Home</Link> / UNIQUE FEATURES</h5>
                </div>
      </div>
      <div className={css.contentContainer}>
        <div className={css.content}>
            <div className={css.contentHolder}>
                <div className={css.contentText}>
                    <ul style={{
                        paddingLeft:'170px'
                    }}>
                        <li>Team of well qualified, experienced and dedicated faculty.</li>
                        <li>State of the art computer lab</li>
                        <li>Digital class rooms/Edusmart class room.</li>
                        <li>Reliable transport facility</li>
                        <li>Periodical test/examinations and critical evaluations.</li>
                        <li>Remedial classes for under performing students</li>
                        <li>Well ventilated and illuminated classrooms.</li>
                        <li>Well-stocked library/laboratory</li>
                        <li>Vast Playground and sports equipments.</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className={css.newsNoticeContainer}>
            <div className={css.newsNotice}>
                <Demo />
            </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default UniqueFeatures;