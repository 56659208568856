import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import React, { useState } from 'react';

// style
import css from '../../../../styles/fees/QuickFeeCollectModal.module.css'
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const QuickFeeCollectModal = (props) => {

    const [formData,setFormData] = useState({
        payment:'cash'
    });
    const [addDate,setAddDate] = useState(dayjs());
    const [paymentDetails,setPaymentDetails] = useState({});

    const handleFormChange = (e) => {
        const {name,value} = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]:value
        }))
    }
    const handlePaymentDetails = (e) => {
        const {name,value} = e.target;
        setPaymentDetails((prev) => ({
            ...prev,
            [name]:value
        }))
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setFormData({});
        props.onSubmit({
            ...formData,
            date:addDate
        },paymentDetails);
    }  
    const handleClose = () => {
        setFormData({
            payment:'cash'
        });
        props.onClose();
    }
    return(
        <Dialog fullWidth maxWidth="sm" open={props.open} onClose={props.onClose}>
            <form onSubmit={handleSubmit}>
            <DialogTitle>Collect Fee</DialogTitle>
            <DialogContent>
                <div className={css.dialogContainer}>
                    <div>Amount Payable:- {`₹${props.feeReceipt?.dueAmount}`}</div>
                </div>
                <div className={css.formFlex}>
                    <FormControl>
                        <FormLabel>Select payment method</FormLabel>
                        <RadioGroup
                        row
                        value={formData.payment || ''}
                        name='payment'
                        onChange={handleFormChange}
                        
                        >
                            <FormControlLabel value="Cash" control={<Radio />} label="CASH" />
                            <FormControlLabel value="Cheque" control={<Radio />} label="Cheque" />
                            <FormControlLabel value="DD" control={<Radio />} label="DD" />
                            <FormControlLabel value="Upi" control={<Radio />} label="UPI" />
                            <FormControlLabel value="Card" control={<Radio />} label="Card" />
                            <FormControlLabel value="Netbanking" control={<Radio />} label="Netbanking" />
                        </RadioGroup>
                    </FormControl>
                    <TextField 
                     label="Note"
                     multiline
                     rows={3}
                     name='note'
                     value={formData.note || ''}
                     size='small'
                     fullWidth
                     onChange={handleFormChange}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker 
                        label="Date/Time"
                        value={addDate}
                        onChange={(newDate) => setAddDate(newDate)}
                        renderInput={(params) => (
                            <TextField {...params} size="small" fullWidth />
                        )}
                        />
                    </LocalizationProvider>
                    {formData.payment === 'Cheque' && (
                        <>
                        <TextField
                         size='small'
                         label="Bank Name"
                         value={paymentDetails.bankName || ''}
                         fullWidth
                         name='bankName'
                         onChange={handlePaymentDetails}
                        />
                        <TextField
                         size='small'
                         label="Branch Name"
                         value={paymentDetails.branchName || ''}
                         fullWidth
                         name='branchName'
                         onChange={handlePaymentDetails}
                        />
                        <TextField
                         size='small'
                         label="Cheque Number"
                         value={paymentDetails.chequeNumber || ''}
                         fullWidth
                         name='chequeNumber'
                         onChange={handlePaymentDetails}
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker 
                            label="Cheque Date"
                            value={paymentDetails.chequeDate || ''}
                            onChange={(newDate) => handlePaymentDetails({
                                target:{
                                    name:'chequeDate',
                                    value:newDate
                                }
                            })}
                            renderInput={(params) => (
                                <TextField {...params} size="small" fullWidth />
                            )}
                            />
                        </LocalizationProvider>
                        </>
                    )}
                    {formData.payment === 'DD' && (
                        <>
                        <TextField
                         size='small'
                         label="Bank Name"
                         value={paymentDetails.bankName || ''}
                         fullWidth
                         name='bankName'
                         onChange={handlePaymentDetails}
                        />
                        <TextField
                         size='small'
                         label="Branch Name"
                         value={paymentDetails.branchName || ''}
                         fullWidth
                         name='branchName'
                         onChange={handlePaymentDetails}
                        />
                        </>
                    )}
                    {formData.payment === 'Upi' && (
                        <>
                        <TextField
                         size='small'
                         label="Bank Name"
                         value={paymentDetails.bankName || ''}
                         fullWidth
                         name='bankName'
                         onChange={handlePaymentDetails}
                        />
                        <TextField
                         size='small'
                         label="Ref Number"
                         value={paymentDetails.refNumber || ''}
                         fullWidth
                         name='refNumber'
                         onChange={handlePaymentDetails}
                        />
                        <TextField
                         size='small'
                         label="Paid By"
                         value={paymentDetails.paidByName || ''}
                         fullWidth
                         name='paidByName'
                         onChange={handlePaymentDetails}
                        />
                        </>
                    )}
                    {formData.payment === 'Card' && (
                        <>
                        <TextField
                         size='small'
                         label="Bank Name"
                         value={paymentDetails.bankName || ''}
                         fullWidth
                         name='bankName'
                         onChange={handlePaymentDetails}
                        />
                        <TextField
                         size='small'
                         label="Card Type"
                         value={paymentDetails.cardType || ''}
                         fullWidth
                         name='cardType'
                         onChange={handlePaymentDetails}
                        />
                        </>
                    )}
                    {formData.payment === 'Netbanking' && (
                        <>
                        <TextField
                         size='small'
                         label="Bank Name"
                         value={paymentDetails.bankName || ''}
                         fullWidth
                         name='bankName'
                         onChange={handlePaymentDetails}
                        />
                        <TextField
                         size='small'
                         label="Bank Name"
                         value={paymentDetails.bankName || ''}
                         fullWidth
                         name='bankName'
                         onChange={handlePaymentDetails}
                        />
                        </>
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()} variant='contained' color='warning'>cancel</Button> 
                <Button type='submit' variant='contained'>collect</Button>
                <Button variant='contained'>preview</Button>
            </DialogActions>
            </form>
        </Dialog>
    )
}

export default QuickFeeCollectModal;