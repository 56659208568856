import React from "react";
import css from "../../styles/Dashboard/Dashboard.module.css";
// import { Box, Card, CardContent, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { urls } from "../../services/urlConstant";
import { get } from "../../services/apis";
import dayjs from "dayjs";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { blue, red } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
const Notice = () => {
  const [announceNotice, setAnounceNotice] = useState([]);
  const [expanded, setExpanded] = React.useState(false);
  useEffect(() => {
    const getNotice = async () => {
      try {
        const response = await get(`${urls.announceNotice.getNotice}/home`, {
          params: {
            search: {
              webView: true,
            },
          },
        });
        setAnounceNotice([...response.data.data]);
        console.log([...response.data.data], "hhhhhhhhhhh");
      } catch (error) {
        console.log(error);
      }
    };
    getNotice();
  }, []);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));
  return (
    <div className={css.noticescoll}>
      <div>
        <Card
          sx={{ minWidth: 200, mt: 3, textAlign: "start" }}
          className={css.card}
        >
          {announceNotice.map((news) => (
            // <>
            //   <CardContent>
            //     <Typography sx={{ fontSize: 14, mb: 1 }}>
            //       <b>{news.title}</b>
            //     </Typography>
            //     <Typography sx={{ fontSize: 14, mb: 1 }}>
            //       <p>{news.notice} </p>
            //     </Typography>
            //     <Typography sx={{ fontSize: 12, mb: 1 }}>
            //       <div style={{ display: "flex" }}>
            //         <b>Date:</b>
            //         <b>{dayjs(news.date).format("DD/MM/YYYY")}</b>
            //       </div>
            //     </Typography>
            //   </CardContent>
            // </>
            <>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                    N
                  </Avatar>
                }
                action={
                  <CardActions disableSpacing>
                    <ExpandMore
                      expand={expanded}
                      onClick={handleExpandClick}
                      aria-expanded={expanded}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </CardActions>
                }
                sx={{ textAlign: "center" }}
                title={news.title}
              />

              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                  <CardMedia
                  // component="img"
                  // height="194"
                  // image=""
                  // alt="no image"
                  />
                  <Typography
                    paragraph
                    sx={{ textAlign: "left", fontSize: 14 }}
                  >
                    {news.notice}
                  </Typography>
                  <Typography style={{ display: "flex", textAlign: "left" }}>
                    <b>{dayjs(news.date).format("DD/MM/YYYY")}</b>
                  </Typography>
                </CardContent>
              </Collapse>
            </>
          ))}
        </Card>
      </div>
    </div>
  );
};

export default Notice;
