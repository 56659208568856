import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { get } from "../../services/apis";
import { Link, NavLink } from "react-router-dom";
import "../../styles/components/navbar/NavTop.scss";
// import navbar from "../../Data/navbar.json";
import Language from "./Language";
import { fontSize } from "@mui/joy/styles/styleFunctionSx";
import { hover } from "@testing-library/user-event/dist/hover";
// import { Button } from "@material-ui/core";
const TopNav = () => {
  const [schoolName, setSchoolName] = useState("");

  useEffect(() => {
    const getSchoolName = async () => {
      const resSchoolName = await get(`/school-name/setting`);
      if (resSchoolName.status === 200) {
        if (resSchoolName.data.data.schoolName !== schoolName) {
          console.log("reach");
          setSchoolName(resSchoolName.data.data.schoolName);
        }
      }
    };
    getSchoolName();
  }, []);

  return (
    <div className="navtop-container">
      <div className="contact-no">
        <i className="fa-solid fa-phone" />
        +91 8884446691
      </div>
      <div className="nav-items">
        <h3>{schoolName && schoolName}</h3>
      </div>

      <ul className="ul-custom" style={{ display: "flex" }}>
        <li className="custom-li">
          <Language />
        </li>
        <Link style={{ color: "white" }} to="/toppers-list">
          <div style={{ marginTop: "15.7px" }}>
            <li
              className="custom-list"
              style={{ display: "flex", color: "black", fontSize: "12px" }}
            >
              TOPPERS
              <i className="fa-solid fa-ranking-star" />
            </li>
          </div>
        </Link>
      </ul>
    </div>
  );
};

export default TopNav;
