import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import "../../styles/components/navbar/Language.scss";

const options = ["--LANGUAGE--", "ENGLISH", "KANNADA", "HINDI", "TELUGU"];

const Language = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <List>
        <div>
          <ListItem
            aria-expanded={open ? "true" : undefined}
            onClick={handleClickListItem}
          >
            <div className="language-list">{options[selectedIndex]}</div>
          </ListItem>
        </div>
      </List>
      <div style={{ fontSize: "12px" }}>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {options.map((option, index) => (
            <div className="language-btn">
              <MenuItem
                key={option}
                disabled={index === 0}
                selected={index === selectedIndex}
                onClick={(event) => handleMenuItemClick(event, index)}
              >
                {option}
              </MenuItem>
            </div>
          ))}
        </Menu>
      </div>
    </div>
  );
};

export default Language;
