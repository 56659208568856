import {
  Box,
  Button,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get, put } from "../../../services/apis";
import LoadingButton from "@mui/lab/LoadingButton";
import roleSettings from './roleSettings.json';
import roleSetting from './roleSetting.json';

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const CRUD_OPERATIONS = ['view', 'add', 'update', 'delete'];

function AssignRolePermission() {
const [loading, setLoading] = useState(false);
  const [roleData, setRoleData] = useState({});
  const [storageData, setStorageData] = useState({});
  const params = useParams();

  const handleCheckBoxClicked = ( event ) => {
    
    const { name, checked } = event.target;
    const nameArray = name.split('/');
    const [ moduleName, propertyName ] = nameArray;
    
    const moduleValue = roleData[moduleName];
    const updatedModuleValue = {
      ...moduleValue,
      [propertyName]: checked
    };

    setRoleData({
      ...roleData,
      [moduleName]: updatedModuleValue
    })

  }

  useEffect( () => {
    function setUserRole() {
      const storageData = localStorage.getItem('role')
  
      if (storageData) {
        setStorageData(JSON.parse(storageData));
      }
    }
    setUserRole();
    window.addEventListener('storage', setUserRole);
  
    return () => {
      window.removeEventListener('storage', setUserRole);
    }
  }, [])

  const getRoleData = async () => {
    const roleData = await get(`/role/${params.id}`);
    setRoleData( roleData.data.data );
  }

  const handleBulkUpdate = ( event ) => {
    const { name, checked } = event.target;
    const toUpdateRoleDate = { ...roleData };
    for(const roleKey of Object.keys(toUpdateRoleDate)) {

      const moduleName = toUpdateRoleDate[roleKey];
      if( typeof moduleName.accessible !== 'undefined' ) { // this check is for roleName property
        moduleName[name] = checked;
      }
    }
    setRoleData(toUpdateRoleDate);
  }

  const updateRole = async () => {
 setLoading(true);
    const updatedRoleData = await put(`/role/${params.id}`, '', roleData);
    console.log(updatedRoleData);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
  }

  useEffect(() => {
    getRoleData()
  }, [params.id]);

  return (
    roleData &&
    Object.keys(roleData).length > 0 && (
      <div>
        <div style={{ margin: 15 }}>
          <Card>
            <Box sx={{ flexGrow: 1 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Module Name</TableCell>
                    <TableCell>Function Name</TableCell>
                    <TableCell>
                      View
                      <Checkbox onChange={handleBulkUpdate} name="view" />
                    </TableCell>
                    <TableCell>
                      Add <Checkbox onChange={handleBulkUpdate} name="add" />{" "}
                    </TableCell>
                    <TableCell>
                      Update{" "}
                      <Checkbox onChange={handleBulkUpdate} name="update" />
                    </TableCell>
                    <TableCell>
                      Delete{" "}
                      <Checkbox onChange={handleBulkUpdate} name="delete" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {roleSetting.modules.map((roleModule) => {
                    const { label, functionalities } = roleModule;
                    return (
                      <>
                        <TableRow>
                          <TableCell
                            sx={{
                              backgroundColor: "#EEEEEE",
                            }}
                            colSpan={6}>
                            <b>{label}</b>
                          </TableCell>
                        </TableRow>
                        {functionalities.map((func) => {
                          const { label, id } = func;
                          const markup = (
                            <TableRow>
                              <TableCell>{"   "}</TableCell>
                              <TableCell>{label}</TableCell>
                              {CRUD_OPERATIONS.map((operation) => {
                                return roleData[id] &&
                                  roleData[id][operation] !== undefined ? (
                                  <TableCell key={operation}>
                                    <Checkbox
                                      name={`${id}/${operation}`}
                                      onChange={handleCheckBoxClicked}
                                      value={
                                        roleData[id]
                                          ? roleData[id][operation]
                                          : false
                                      }
                                      checked={
                                        roleData[id]
                                          ? roleData[id][operation]
                                          : false
                                      }
                                    />
                                  </TableCell>
                                ) : (
                                  <TableCell></TableCell>
                                );
                              })}
                            </TableRow>
                          );
                          return markup;
                        })}
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
            <Box>
              <Button>Cancel</Button>
              <LoadingButton
                onClick={updateRole}
                loading={loading}
               
                >
                Update
              </LoadingButton>
            </Box>
          </Card>
        </div>
      </div>
    )
  );
}

export default AssignRolePermission;
