import React, { useState, useEffect } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "./../styles/components/navbar/Navbar.scss";
import { Language } from "@mui/icons-material";
import "./../styles/components/navbar/NavTop.scss";
import css from './Addmission.module.css'
import componentCss from '../styles/AssignmentView.module.css'
import Footer from "./Footer/Footer";
import TopNav from "./navbar/TopNav";
import Navbar from "./navbar/Navbar";
import Demo from "./NewsNotic/Demo";
import { get } from "../services/apis";
import { Button, FormControl, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import dayjs from "dayjs";

const AssignmentView = () => {
    const [addClass, updateClass] = useState(false);
  const [stkyClass, setStkyClass] = useState(false);
  const [mega, setMega] = useState(false);
  const [schoolClass,setSchoolClass] = useState(null);
  const [assignments,setAssignments] = useState([]);
  const [formData,setFormData] = useState({});
  const [schoolClasses,setSchoolClasses] = useState([]);
  const [sections,setSections] = useState([]);

  const handleClickCross = () => {
    updateClass(false);
    window.scrollTo(0, 0);
  };
  const scrollHandleScroll = () => {
    if (window.scrollY > 20) {
      setStkyClass(true);
    } else {
      setStkyClass(false);
    }
  };
  const handleMega = () => {
    setMega(!mega);
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollHandleScroll);
  }, []);

  useEffect(() => {
    const getSections = async () => {
      try {
        const sections = await get(`/home/section`,{
          params:{
            search:{
              class:formData.class 
            }
          }
        });
        if(sections.data.success) {
          setSections(sections.data.data);
        }
      } catch (error) {
        
      }
    }
    if(formData.class) {
      getSections();
    }
  },[formData.class])

  useEffect(() => {
    const getClasses = async () => {
      try {
        const schoolClasses = await get(`/home/class`);
        if(schoolClasses.data.success) {
          setSchoolClasses(schoolClasses.data.data)
        }
      } catch (error) {
        
      }
    }
    getClasses();
  },[])
  const handleFormChange = (e) => {
    const {name,value} = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]:value
    }))
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (formData.section === "all"){
        const assignment = await get(`/home/assignment`,{
          params:{
            search:{
              class:formData.class,
            },
            limit:10,
            page:1
          }
        })
        if(assignment.data.success) {
          setAssignments(assignment.data.data)
        }
      }else{
        const assignment = await get(`/home/assignment`,{
          params:{
            search:{
              class:formData.class,
              section:formData.section
            },
            limit:10,
            page:1
          }
        })
        if(assignment.data.success) {
          setAssignments(assignment.data.data)
        }
      } 
      
    } catch (error) {
      
    }
  }
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const assignments = await get(`/home/assignment`,{
  //       params:{
  //         search:{
  //           class:formData.class,
  //           section:formData.section
  //         }
  //       }
  //     });
  //     if(assignments.data.success) {
  //       setAssignments(assignments.data.data)
  //     }
  //   } catch (error) {
      
  //   }
  // }
  return (
    <>
        <TopNav />
        <Navbar />
      <div className={css.headerContainer}>
                <div>
                    <h1>ASSIGNMENT</h1>
                    <h5><Link style={{
                        color:'white'
                    }} to='/'>Home</Link> / ASSIGNMENT</h5>
                </div>
      </div>
      <div className={css.contentContainer}>
        <div className={css.content}>
            <div className={componentCss.formContainer} >
              <Paper>
                <form onSubmit={handleSubmit} className={componentCss.form}>
                  <FormControl style={{width:'30%'}}>
                    <InputLabel>Class</InputLabel>
                    <Select
                    name="class"
                    variant="standard"
                    value={formData.class || ''}
                    label="Class"
                    onChange={handleFormChange}
                    required
                    >
                      {schoolClasses.map((schoolClass) => (
                        <MenuItem key={schoolClass._id} value={schoolClass._id}>{schoolClass.className}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl style={{width:'30%'}}>
                    <InputLabel>Sections</InputLabel>
                    <Select
                    name="section"
                    required
                    variant="standard"
                    value={formData.section || ''}
                    label="Section"
                    onChange={handleFormChange}
                    >
                      {formData.class && (
                      <MenuItem value="all">-all-</MenuItem>
                    )}
                      {sections.map((section) => (
                        <MenuItem key={section._id} value={section._id}>{section.sectionName}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                  type="submit"
                  variant="contained">
                    SEARCH
                  </Button>
                </form>
              </Paper>
            </div>
            <div>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth:650}}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">#SL</TableCell>
                                <TableCell align="center">Title</TableCell>
                                <TableCell align="center">Subject</TableCell>
                                <TableCell align="center">Created</TableCell>
                                <TableCell align="center">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {assignments.map((assignment,index) => (
                                <TableRow
                                key={assignment._id}
                                >
                                    <TableCell align="center">{index+1}</TableCell>
                                    <TableCell align="center">{assignment.assignmentTitle}</TableCell>
                                    <TableCell align="center">{assignment.subject.subjectName}</TableCell>
                                    <TableCell align="center">{dayjs(assignment.createdAt).format('DD-MM-YYYY')}</TableCell>
                                    <TableCell align="center">
                                        {assignment.file && (
                                          <a href={assignment.file.link} download target='__window'><Button  size="small" color="success" variant="contained">Download</Button></a>
                                        )}
                                        {assignment.link && (
                                          <a href={assignment.link} rel='noreferrer' target='_blank'><Button size="small" color="success" variant="contained">Link</Button></a>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {!assignments.length && (<h5 style={{textAlign:'center'}}>No Assignments</h5>)}
                </TableContainer>
            </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default AssignmentView;