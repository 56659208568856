import React from "react";
// styles
import css from '../../styles/ui/HeaderCard.module.css'
import { Box, Card } from "@mui/material";

const HeaderCard = (props) => {

    return(
        <Card className={css.card}>
            <Box>
                <header className={css.header} style={{color:props.color ? props.color : 'inherit'}}>{props.header}</header>
            </Box>
        </Card>
    )
}
export default HeaderCard;  