import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  styled,
  Stack,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tooltip,
  InputLabel,
  Card,
} from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";

// custom imports
import FormInput from "../../../FormInput";
import FormSelect from "../../../FormSelect";

// Icons
import AddIcon from "@mui/icons-material/Add";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";

// forms
import { useFormik } from "formik";
import * as Yup from "yup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// api

import { urls } from "../../../../services/urlConstant";

import { get, post, put, del } from "../../../../services/apis";
import { useContext } from "react";

import { Paper } from "@material-ui/core";
import StickyBar from "../../StickyBar";

const Label = styled("label")(() => ({
  fontWeight: "bold",
  fontSize: "14px",
  // paddingLeft: "10px",
}));

const Title = styled(Typography)(() => ({
  fontWeight: "bold",
  fontSize: "20px",
  textAlign: "left",
  marginLeft: "10px",
}));

// const InputLabel = styled(Typography)(() => ({
//   fontWeight: "bold",
//   color: "#1b3779",
//   fontSize: "20px",
// }));

export default function AddCourse({
  classe,
  subjectId,
  edit,
  formData,
  setEditCourse,
  history,
}) {
  const fileInputRef = React.useRef(null);

  const [imageFile, setImageFile] = React.useState(
    formData && formData.thumbnailImage ? formData.thumbnailImage : null
  );
  const [selectedImage, setSelectedImage] = useState(null);
  const [inputlist, setInputList] = useState(
    formData?.overview || [{ point: "" }]
  );
  const [inputlistBenifits, setInputListBenifits] = useState(
    formData?.benefits || [{ point: "" }]
  );

  useEffect(() => {
    if (formData && Object.keys(formData).length) {
      setImageFile(formData.thumbnailImage);
    }
  }, [formData]);

  const [list, setList] = useState([]);
  const [subject, setSubject] = useState([]);

  const [subjectName, setSubjectName] = useState(subjectId);
  const [menuList, setMenuList] = useState([]);
  const [className, setClassName] = useState(
    classe ? classe.map((c) => c._id) : []
  );
  const [loading, setLoading] = useState(false);

  const handleSubjectChange = (e) => {
    setSubjectName(e.target.value);
  };

  const handleClassChange = (e) => {
    setClassName(e.target.value);
  };

  const navigate = useNavigate();

  const handleUploadButtonClick = () => {
    fileInputRef?.current?.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
    setImageFile(URL.createObjectURL(file));
    formik.setFieldError("thumbnailImage", "");
  };

  const formik = useFormik({
    initialValues: {
      title: edit ? formData.title : "",
      isTrending: edit ? formData.isTrending : false,
      description: edit ? formData.description : "",
    },
    validationSchema: Yup.object({
      description: Yup.string().required().label("Description"),
      isTrending: Yup.boolean().label("Is a Trending Course"),
      title: Yup.string().required().label("Title").max(50),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true);

      try {
        const body = {
          description: values.description,
          title: values.title,
          classIds: className,
          subject: subjectName,
          isTrending: values.isTrending,
          courseDetails: {
            overview: inputlist,
            benefits: inputlistBenifits,
          },
        };

        const formsData = new FormData();
        formsData.append("bodyData", JSON.stringify(body));
        formsData.append("file", selectedImage);
        if (!edit) {
          if (!imageFile) {
            setLoading(false);
            return toast.error("Please select a thumnail image");
          }

          const res = await post(`${urls.course.createCourse}`, formsData);
          if (res.data.success) {
            setLoading(false);
            navigate("/dashboard/course");
          }
        } else {
          const res = await put(
            `${urls.course.editCourse}/${edit}`,
            "",
            formsData
          );
          if (res.data.success) {
            setLoading(false);
            setEditCourse(null);
            navigate("/dashboard/course");
          }
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      setLoading(false);
    },
    validateOnBlur: false,
  });

  useEffect(() => {
    const getMenu = async () => {
      try {
        const res = await get(`${urls.class.getAllClass}`);
        setMenuList(res.data.data);
        setList(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getMenu();
  }, []);

  const handleFileChangeMaterial = (e) => {
    const selectedFile = e.target.files[0];
  };

  const getSubject = async () => {
    try {
      const res = await get(urls.schoolSubject.getAllSubject, {
        params: {
          search: {
            class: { $in: className },
          },
        },
      });
      setSubject(res.data.data);
      if (subjectId) {
        setSubjectName(subjectId);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (className.length) {
      getSubject();
    }
  }, [className]);

  const handleAddClick = () => {
    setInputList([...inputlist, { point: "" }]);
  };

  const handleAddClickBenifites = () => {
    setInputListBenifits([...inputlistBenifits, { point: "" }]);
  };

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const overviewList = [...inputlist];

    let newList = overviewList.map((l, i) =>
      i === index ? { ...l, point: value } : { ...l }
    );
    setInputList(newList);
  };

  const handleInputChangeBenifits = (e, index) => {
    const { value } = e.target;
    const benifitsList = [...inputlistBenifits];
    let newList = benifitsList.map((l, i) =>
      i === index ? { ...l, point: value } : { ...l }
    );
    setInputListBenifits(newList);
  };

  const handleRemoveClick = (i) => {
    const list = [...inputlist];
    list.splice(i, 1);
    setInputList(list);
  };

  const handleRemoveClickBenifits = (i) => {
    const list = [...inputlistBenifits];
    list.splice(i, 1);
    setInputListBenifits(list);
  };

  console.log(subject, "suuuuuuuuuuuuuuuuuuuuuu");
  return (
    <>
      <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span
              style={{
                color: "red",
                fontFamily: "cursive",
                textAlign: "start",
              }}>
              {edit ? "Update Course" : "Add Course"}
            </span>
          </Grid>
        </Grid>
      </Card>

      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            padding: "20px 8px",
            borderRadius: "10px",
            margin: 1,
            borderRight: "10px",
            border: "1px solid lightGrey",
            backgroundColor: "whitesmoke",
          }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <FormControl size="small" fullWidth>
                <InputLabel>Select Class</InputLabel>
                <Select
                  labelId="demo-simpless-select-filled-label"
                  id="demo-simple-select-filled-l"
                  value={className || []}
                  label="Select Class"
                  multiple
                  name="className"
                  onChange={handleClassChange}
                  size="small">
                  {list.map((sub) => (
                    <MenuItem value={sub._id} key={sub._id}>
                      {sub.className}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {className.length <= 1 && (
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <FormControl size="small" fullWidth>
                  <InputLabel>Select Subject</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={subjectName || ""}
                    label="Select Subject"
                    name="subjectName"
                    onChange={handleSubjectChange}
                    size="small">
                    {subject.map((sub) => (
                      <MenuItem value={sub._id} key={sub._id}>
                        {sub.subjectName} {""} ({sub.class.className})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12} sm={6} md={4} lg={4}>
              <FormInput
                size="small"
                name="title"
                label="Enter Course Name"
                placeholder="course name"
                required
                fullWidth
                formik={formik}
                backgroundColor="whitesmoke"
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              direction="flex"
              alignSelf="center">
              {imageFile && (
                <>
                  <img
                    src={imageFile}
                    alt="Flashcard"
                    style={{
                      width: "100px",
                      marginBottom: "20px",
                      borderRadius: "5px",
                    }}
                  />
                  <br />
                </>
              )}

              <Button
                onClick={handleUploadButtonClick}
                variant="contained"
                sx={{
                  background: "#1b3779",
                  ":hover": { background: "#1b3779" },
                  color: "#fff",
                }}
                size="small"
                startIcon={<UploadFileIcon />}>
                Upload Thumbnail
              </Button>

              <input
                type="file"
                accept="image/*"
                name="thumbnailImage"
                onChange={handleFileChange}
                style={{ display: "none" }}
                ref={fileInputRef}
              />

              {formik.errors.thumbnailImage && (
                <Typography color="error" variant="caption" sx={{ mt: 1 }}>
                  {formik.errors.thumbnailImage}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} mt={1}>
            <Box
              sx={{
                border: "1px solid lightGrey",
                borderRadius: "10px",
                padding: 2,
                backgroundColor: "whitesmoke",
              }}>
              <Label htmlFor="description">Short Description</Label>

              <br />
              <FormInput
                size="small"
                name="description"
                placeholder="description"
                required
                fullWidth
                formik={formik}
                backgroundColor="whitesmoke"
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12} sx={{ mb: 1.5 }}>
            <Box
              sx={{
                border: "1px solid lightGrey",
                borderRadius: "10px",
                padding: 2,
                backgroundColor: "whitesmoke",
              }}>
              <Label htmlFor="overview">Overview (Add points)</Label>
              <br />

              {inputlist.map((data, i) => (
                <Box
                  key={i}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "5px",
                  }}>
                  <KeyboardDoubleArrowRightIcon
                    fontSize="small"
                    sx={{ color: "#1b3779" }}
                  />
                  <TextField
                    size="small"
                    placeholder="overview"
                    fullWidth
                    // required
                    sx={{
                      mb: "5px",
                      mt: 1,
                      // bgcolor: "white"
                    }}
                    value={data.point || ""}
                    name="overview"
                    inputProps={{
                      maxLength: 200,
                    }}
                    onChange={(e) => handleInputChange(e, i)}
                  />
                  <Tooltip title="Delete">
                    <DeleteIcon
                      fontSize="small"
                      onClick={() => handleRemoveClick(i)}
                      color="error"
                    />
                  </Tooltip>
                </Box>
              ))}

              <Button
                variant="contained"
                size="small"
                sx={{ mt: 1 }}
                onClick={handleAddClick}>
                <AddIcon />
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Box
              sx={{
                border: "1px solid lightGrey",
                padding: 2,
                borderRadius: "10px",
                backgroundColor: "whitesmoke",
              }}>
              <Label htmlFor="benefits">
                What the students will learn (Add points)
              </Label>
              <br />
              {inputlistBenifits.map((data, i) => (
                <Box
                  key={i}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "5px",
                  }}>
                  <KeyboardDoubleArrowRightIcon
                    fontSize="small"
                    sx={{ color: "#1b3779" }}
                  />
                  <TextField
                    size="small"
                    placeholder="what you will learn"
                    sx={{ mb: "5px", mt: 1 }}
                    fullWidth
                    name="benefits"
                    value={data.point || ""}
                    inputProps={{
                      maxLength: 200,
                    }}
                    onChange={(e) => handleInputChangeBenifits(e, i)}
                  />
                  <Tooltip title="Delete">
                    <DeleteIcon
                      fontSize="small"
                      color="error"
                      onClick={() => handleRemoveClickBenifits(i)}
                    />
                  </Tooltip>
                </Box>
              ))}

              <Button
                variant="contained"
                size="small"
                sx={{ mt: 1 }}
                onClick={handleAddClickBenifites}>
                <AddIcon />
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={formik.values.isTrending} />}
                name="isTrending"
                onChange={(e) => {
                  formik.setFieldValue("isTrending", e.target.checked);
                }}
                label="This course is Trending?"
              />
            </FormGroup>
          </Grid>
        </Grid>
        {inputlist.length > 0 && inputlistBenifits.length > 0 && (
          <StickyBar
            content={
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "5px",
                }}>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={() =>
                    !setEditCourse
                      ? navigate(history ? history : "/dashboard/course")
                      : setEditCourse(null)
                  }>
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  size="small"
                  sx={{
                    background: "rgb(27, 55, 121)",
                    ":hover": { background: "rgb(27, 55, 121)" },
                  }}
                  onClick={formik.handleSubmit}
                  loading={loading}>
                  Submit
                </LoadingButton>
              </Grid>
            }
          />
        )}
        <div style={{ marginBottom: "70px" }}></div>
      </form>
    </>
  );
}
