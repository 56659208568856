import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Fab, TextareaAutosize, TextField, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import Axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 360,
  bgcolor: "background.paper",
  p: 4,
};

const AddDesignation = (props) => {
  const [designation, setDesignation] = useState("");
  const [note, setNote] = useState("");
  const [schoolDesignation, setSchoolDesignation] = useState([]);

  useEffect(() => {
    if (props.updateDesignation) {
      setDesignation(props.updateDesignation.designation);
      setNote(props.updateDesignation.note);
    }
  }, [props.updateDesignation]);

  useEffect(() => {
    const schoolDesignationGet = async () => {
      try {
        const response = await Axios.get("http://localhost:3001/api/class");
        setSchoolDesignation(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    schoolDesignationGet();
  }, []);

  const handleDesignation = (e) => {
    setDesignation(e.target.value);
  };

  const handleDesignationNote = (e) => {
    setNote(e.target.value);
  };

  const handleSubmit = (e) => {
    if (props.updateDesignation) {
      props.handleUpdate(
        {
          designation,
          note,
        },
        props.updateDesignation._id
      );
    } else {
      props.handleSubmit(e, {
        designation,
        note,
      });
    }
    setDesignation("");
    setNote("");

    props.handleClose();
  };

  const handleCloseModal = () => {
    setDesignation("");
    setNote("");

    props.handleClose();
  };
  return (
    <div>
      <Tooltip title="Add" disableInteractive>
        <Fab
          size="medium"
          style={{ background: "#1b3779", color: "#fff" }}
          aria-label="add"
          onClick={props.handleOpen}
        >
          <Add />
        </Fab>
      </Tooltip>
      <Modal
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleSubmit}>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
            >
              Add Designation
            </Typography>
            <div>
              <TextField
                required
                id="filled-required"
                variant="standard"
                label="Designation"
                value={designation}
                onChange={handleDesignation}
                sx={{ m: 1, minWidth: "96%", label: { fontSize: 12 } }}
              />

              <div>
                <TextareaAutosize
                  maxRows={4}
                  aria-label="maximum height"
                  placeholder="Drop a note"
                  value={note}
                  onChange={handleDesignationNote}
                  style={{
                    width: 712,
                    maxHeight: 110,
                    margin: 8,
                    padding: 10,
                    minHeight: 118,
                  }}
                />
              </div>
            </div>

            <div className="cancel-submit-btn">
              <div>
                <Button
                  onClick={() => props.handleClose()}
                  variant="outlined"
                  size="medium"
                  type="button"
                  sx={{
                    m: 1,
                    color: "rgb(27, 55, 121)",
                    borderColor: "rgb(27, 55, 121)",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!designation}
                  variant="contained"
                  size="medium"
                  type="submit"
                  sx={{ background: "rgb(27, 55, 121)" }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
};

export default AddDesignation;
