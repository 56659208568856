import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Tooltip,
} from "@mui/material";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 500,
  bgcolor: "background.paper",
  p: 4,
};

const AddExamGrade = (props) => {
  const [examGrade, setExamGrade] = useState("");
  const [examGroup, setExamGroup] = useState("");
  const [examGradePoint, setExamGradePoint] = useState("");
  const [markFrom, setMarkFrom] = useState("");
  const [markTo, setMarkTo] = useState("");
  const [note, setNote] = useState("");
  useEffect(() => {
    if (props.updateExam) {
      setExamGrade(props.updateExam.examGrade);
      setExamGroup(props.updateExam.group);
      setExamGradePoint(props.updateExam.gradePoint);
      setMarkFrom(props.updateExam.markFrom);
      setMarkTo(props.updateExam.markTo);
      setNote(props.updateExam.note);
    }
  }, [props.updateExam]);

  const handleExamGrade = (e) => {
    setExamGrade(e.target.value);
  };
  const handleExamgroup = (e) => {
    setExamGroup(e.target.value);
  };
  const handleExamGradePoint = (e) => {
    setExamGradePoint(e.target.value);
  };

  const handleMarkFrom = (e) => {
    setMarkFrom(e.target.value);
  };

  const handleMarkTo = (e) => {
    setMarkTo(e.target.value);
  };
  const handleExamNote = (e) => {
    setNote(e.target.value);
  };
  const handleSubmit = (e) => {
    if (props.updateExam) {
      props.handleUpdate(
        {
          examGrade,
          group: examGroup,
          gradePoint: examGradePoint,
          markFrom,
          markTo,
          note,
        },
        props.updateExam._id
      );
    } else {
      props.handleSubmit(e, {
        examGrade,
        group: examGroup,
        gradePoint: examGradePoint,
        markFrom,
        markTo,
        note,
      });
    }
    setExamGrade("");
    setExamGroup("");
    setExamGradePoint("");
    setMarkFrom("");
    setMarkTo("");
    setNote("");
    props.handleClose();
  };

  const handleCloseModal = () => {
    setExamGrade("");
    setExamGroup("");
    setExamGradePoint("");
    setMarkFrom("");
    setMarkTo("");
    setNote("");
    props.handleClose();
  };

  const handleClose = () => {
    setExamGrade("");
    setExamGroup("");
    setExamGradePoint("");
    setMarkFrom("");
    setMarkTo("");
    setNote("");
    props.handleClose();
  };
  return (
    <div>
      <Tooltip title="Add" disableInteractive>
        <Fab
          size="medium"
          style={{ background: "#1b3779", color: "#fff" }}
          aria-label="add"
          onClick={props.handleOpen}
        >
          <Add />
        </Fab>
      </Tooltip>
      <Modal
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleSubmit}>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
              marginBottom="30px"
            >
              Add Exam Grade
            </Typography>
            <div>
              <TextField
                required
                id="filled-required"
                variant="standard"
                label="Exam Grade"
                value={examGrade}
                onChange={handleExamGrade}
                sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
              />
              <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 227 }}
                required
              >
                <InputLabel
                  id="demo-simple-select-filled-label"
                  sx={{ fontSize: 12 }}
                >
                  Group
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={examGroup}
                  onChange={handleExamgroup}
                >
                  <MenuItem
                    value={"scholastic"}
                    sx={{ fontSize: 12, fontWeight: 500 }}
                  >
                    Scholastic
                  </MenuItem>
                  <MenuItem
                    value={"co-scholastic"}
                    sx={{ fontSize: 12, fontWeight: 500 }}
                  >
                    Co-scholastic
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                required
                id="filled-required"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  type: "number",
                }}
                variant="standard"
                value={examGradePoint}
                onChange={handleExamGradePoint}
                label="Grade Point"
                sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
              />
              <div>
                <TextField
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    type: "number",
                  }}
                  id="filled-required"
                  variant="standard"
                  value={markFrom}
                  onChange={handleMarkFrom}
                  label="Percentage From"
                  sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                />
                <TextField
                  required
                  id="filled-required"
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    type: "number",
                  }}
                  value={markTo}
                  onChange={handleMarkTo}
                  label="Percentage To"
                  sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                />
              </div>
              <div>
                <TextareaAutosize
                  maxRows={4}
                  aria-label="maximum height"
                  placeholder="Drop a note"
                  value={note}
                  onChange={handleExamNote}
                  style={{
                    width: 712,
                    maxHeight: 110,
                    margin: 8,
                    padding: 10,
                    minHeight: 118,
                  }}
                />
              </div>
            </div>

            <div className="cancel-submit-btn">
              <div>
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  size="medium"
                  type="button"
                  sx={{
                    m: 1,
                    color: "rgb(27, 55, 121)",
                    borderColor: "rgb(27, 55, 121)",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={
                    !(
                      examGrade &&
                      examGroup &&
                      examGradePoint &&
                      markFrom &&
                      markTo
                    )
                  }
                  variant="contained"
                  size="medium"
                  type="submit"
                  sx={{ background: "rgb(27, 55, 121)" }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
};

export default AddExamGrade;
