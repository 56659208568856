import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import ModalVideo from "react-modal-video";
import Slider1 from "react-slick";
import { Link } from "react-router-dom";
import KidsSlider from "../../assets/slider/SliderKids.svg";
import LeftArrow from "../../assets/slider/LeftArrow.svg";
import RightArrow from "../../assets/slider/RightArrow.svg";
import PlayButton from "../../assets/slider/PlayButton.svg";
import Kids1 from "../../assets/slider/Kids2.svg";
import Kids2 from "../../assets/slider/Kids1.svg";
import "../../styles/components/slider/Slider.scss";
import ExcellentSchool from "../../assets/Images/ExcellentSchool (3).webp";

const Slider = () => {
  const [isOpen, setOpen] = useState(false);
  const [clickVideo, setClickVideo] = useState("gACFczD91OY");
  const SampleNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="slick__arrow__next" onClick={onClick}>
        <img src={RightArrow} alt="" className="img-fluid image-width" />
        {/* <p>Next </p> */}
      </div>
    );
  };
  const SamplePrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="slick__arrow__prev" onClick={onClick}>
        <img src={LeftArrow} alt="" className="img-fluid image-width" />
        {/* <p>Prev</p> */}
      </div>
    );
  };
  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const SliderContents = [
    {
      h1a: "We Are Child Care",
      h1: "Professional",
      p: "You must know that there is nothing higher and stronger and more wholesome and good for life in the future than some good memory,  specially a memory of childhood.",
      vid: "",
      img: ExcellentSchool,
    },
    {
      h1a: " ನ ಹಿ ಜ್ಞಾನೇನ ಸದೃಶಂ",
      h1: "",
      p: "VISHWAKALAYANA EDUCATIONAL, CULTURAL AND SOCIAL ASSOCISTION (R) ",
      vid: "gACFczD91OY",
    },
  ];
  const videoHandle = (vid) => {
    setOpen(true);
    setClickVideo(vid);
  };
  var sectionStyle = {
    width: "100%",
    height: "400px",
    backgroundImage: `url(${ExcellentSchool})`,
  };

  return (
    <div className="slider__wrapper" style={{ sectionStyle }}>
      <Grid container spacing={2} className="">
        <Grid item xs={12} md={6}>
          <div className="slider__inner">
            <ModalVideo
              channel="youtube"
              youtube={{
                autoplay: 0,
              }}
              isOpen={isOpen}
              videoId={clickVideo}
              onClose={() => setOpen(false)}
            />
            <Slider1 cl {...settings}>
              {SliderContents.map((SliderContent, i) => (
                <div className="single__slider" key={i}>
                  <div className="video__modal">
                    {SliderContent.vid === "" ? (
                      ""
                    ) : (
                      <button
                        className="btn video__btn"
                        onClick={() => videoHandle(SliderContent.vid)}
                      >
                        <img src={PlayButton} alt="" className="img-fluid" />
                      </button>
                    )}
                  </div>
                  {SliderContent.img ? (
                    <div>
                      <img
                        src={SliderContent.img}
                        alt=""
                        className="img-fluid"
                      />
                      <div className="school-info">
                        <p style={{ width: "fit-content" }}>
                          "Our vision is to be the pre-eminent <br></br>centre
                          of learning in the country,<br></br>
                          producing future leaders with a global <br></br>
                          perspective and an Indian ethos"
                          <div className="writer-title">
                            - Dr. Shivanand Kelur
                          </div>
                        </p>
                      </div>
                    </div>
                  ) : (
                    <>
                      <h1>
                        <span className="above__title">
                          {SliderContent.h1a}
                        </span>
                        <br />
                        {SliderContent.h1}
                      </h1>
                      <p className="slider__para">{SliderContent.p}</p>
                      <div className="slider__btns">
                        <Link to="/addmission" className="cmn__btn">
                          Addmission
                          <img
                            src={RightArrow}
                            alt=""
                            className="img-fluid image-width1"
                          />
                        </Link>
                        <Link
                          to="/home-careers"
                          className="learn__btn cmn__btn"
                        >
                          Career{" "}
                          <img
                            src={RightArrow}
                            alt=""
                            className="img-fluid image-width1"
                          />
                        </Link>
                      </div>
                    </>
                  )}
                </div>
              ))}
            </Slider1>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <img src={KidsSlider} alt="" className="img-fluid" />
        </Grid>
      </Grid>
      <div className="slider__side__color">
        <img src={Kids1} alt="kid2" />
      </div>
      <div className="slider__side__color1">
        <img src={Kids2} alt="kid1" />
      </div>
    </div>
  );
};
export default Slider;
