import React, { useEffect, useState } from "react";

import css from "../../../styles/fees/ClassMap.module.css";
import {
  Button,
  Dialog,
  DialogActions,
  IconButton,
  Card,
  Grid,
  DialogContent,
  DialogTitle,
  FormControl,
  Toolbar,
  Tooltip,
  InputLabel,
  Checkbox,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { get, post, put, del } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import { Add } from "@mui/icons-material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DeleteModal from "../Academic/DeleteModal";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import dayjs from "dayjs";

const FeeCategory = () => {
  const [selectCategory, setSelectCategory] = useState("");
  const [category, setCategory] = useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [feeCategory, setFeeCategory] = useState([]);
  const [addDialog, setAddDialog] = useState(false);
  const [addForm, setAddForm] = useState({});
  const [editFeeCategory, setEditFeeCategory] = useState();
  const [selected, setSelected] = useState([]);
  const [alldeleteModal, setAlldeleteModal] = useState([]);
  const [isRowSelected, setISRowSelected] = useState([]);
  const [sort, setSort] = useState({
    name: "asc",
    description: "asc",
    percentageShare: "asc",
  });

  // useEffect(() => {
  //     const init = async () => {
  //         try {
  //             const {data:allCategory,status} = await get(`${urls.CategoryList.getAllCategoryList}`);
  //             if(status > 199 && status < 299) {
  //                 setCategory(allCategory.data);
  //                 if(allCategory.data.length > 0) {
  //                     setSelectCategory(allCategory.data[0]?._id);
  //                 }
  //             }
  //         } catch (error) {

  //         }
  //     }
  //     init();
  // },[])

  useEffect(() => {
    getFeeCategory();
  }, []);

  const getFeeCategory = async () => {
    try {
      const { data: feeCategoryList, status } = await get(
        `${urls.feeCategory.getAllFeeCategory}`
      );
      const compare = (a, b) => a.priority - b.priority;
      let feeCategoryss = feeCategoryList.data;
      feeCategoryss.sort(compare);

      if (status > 199 && status < 299) {
        setFeeCategory(feeCategoryss);
        return {
          data: feeCategoryList,
          status,
        };
      }
      console.log("jkjk", feeCategory);
    } catch (error) {
      return error;
    }
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(`${urls.feeCategory.deleteFeeCategory}`, id);
      console.log(res);
      if (res.data.success) {
        const filteredEvent = feeCategory.filter((item) => item._id !== id);
        setFeeCategory(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteMultiple = async (id) => {
    try {
      const res = await del(
        `${urls.feeCategory.deleteMultipleFeeCategory}`,
        "",
        { feeCategoryIds: selected }
      );
      console.log(res);
      if (res.data.success) {
        const filteredEvents = feeCategory.filter(
          (item) => !selected.includes(item._id)
        );
        setFeeCategory(filteredEvents);
        setSelected([]);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log("kjhgfd", error);
    }
  };

  const handleSelectAllClick = (event) => {
    console.log("oooooooooo", selected);
    if (event.target.checked) {
      const ids = feeCategory.map((fee) => fee._id);
      setSelected([...ids]);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event, id) => {
    if (event.target.checked) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter((_id) => _id !== id));
    }
  };

  const handleFavorite = async (id) => {
    console.log("selectCategory", selectCategory);
    try {
      const res = await put(
        `${urls.feeCategory.toggleFeeCategoryFavorite}${id}`,
        ""
      );
      console.log("res", res);
      if (res.data.success) {
        const res = await get(`${urls.feeCategory.getAllFeeCategory}`);
        setFeeCategory([...res.data.data]);
        console.log(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handlePriorityUp = async (index, id) => {
    console.log("selectCategory", selectCategory);
    let interestedindex = index - 1;
    if (interestedindex < 0) {
      return;
    }
    let idOfTheCategoryToModify = feeCategory[interestedindex]._id;
    try {
      const res = await put(`${urls.feeCategory.changePriority}${id}`, "", {
        idOfTheCategoryToModify,
      });
      console.log("res", res);
      if (res.data.success) {
        const res = await get(`${urls.feeCategory.getAllFeeCategory}`);
        const compare = (a, b) => a.priority - b.priority;
        const list = res.data.data;
        list.sort(compare);
        setFeeCategory(list);
        console.log(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handlePriorityDown = async (index, id) => {
    console.log("selectCategory", selectCategory);
    let lengthoflist = feeCategory.length;

    let interestedindex = index + 1;
    console.log("intrer", interestedindex);
    if (interestedindex >= lengthoflist) {
      return;
    }
    let idOfTheCategoryToModify = feeCategory[interestedindex]._id;
    try {
      const res = await put(`${urls.feeCategory.changePriority}${id}`, "", {
        idOfTheCategoryToModify,
      });
      console.log("res", res);
      if (res.data.success) {
        const res = await get(`${urls.feeCategory.getAllFeeCategory}`);
        const compare = (a, b) => a.priority - b.priority;
        const list = res.data.data;
        list.sort(compare);
        setFeeCategory(list);
        console.log(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCloseAddDialog = () => {
    setEditFeeCategory(null);
    setAddForm({});
    setAddDialog(false);
  };
  const handleOpenAddDialog = () => {
    setAddDialog(true);
  };
  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleAddSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!editFeeCategory) {
        const { status } = await post(`${urls.feeCategory.postFeeCategory}`, {
          name: addForm.name,
          description: addForm.description,
          percentageShare: addForm.percentageShare,
        });
        if (status > 199 && status < 299) {
          await getFeeCategory();
          handleCloseAddDialog();
        }
      } else {
        const { status } = await put(
          `${urls.feeCategory.editFeeCategory}${editFeeCategory}`,
          "",
          {
            name: addForm.name,
            description: addForm.description,
            percentageShare: addForm.percentageShare,
          }
        );
        if (status > 199 && status < 299) {
          await getFeeCategory();
          handleCloseAddDialog();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  // const handleAddSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     if (!editFeeCategory) {
  //       const { status } = await post(`${urls.feeCategory.postFeeCategory}`, {
  //         name: addForm.name,
  //         description: addForm.description,
  //         amount: addForm.amount,
  //       });
  //       if (status >= 200 && status < 300) {
  //         await getFeeCategory();
  //         handleCloseAddDialog();
  //       }
  //     } else {
  //       const { status } = await put(`${urls.feeCategory.editFeeCategory}/${editFeeCategory}`, {
  //         name: addForm.name,
  //         description: addForm.description,
  //         amount: addForm.amount,
  //       });
  //       if (status >= 200 && status < 300) {
  //         await getFeeCategory();
  //         handleCloseAddDialog();
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleClassMapEdit = (id, data) => {
    setEditFeeCategory(id);
    setAddForm({
      name: data.name,
      description: data.description,
      percentageShare: data.percentageShare,
    });

    handleOpenAddDialog();
  };
  const sortByFeeCategory = (e) => {
    console.log("name");
    if (sort.name == "asc") {
      let sortedList = [
        ...feeCategory.sort((a, b) => b.name.localeCompare(a.name)),
      ];
      setFeeCategory(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      let sortedList = [
        ...feeCategory.sort((a, b) => a.name.localeCompare(b.name)),
      ];
      setFeeCategory(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };
  const sortByDescription = (e) => {
    console.log("description");
    if (sort.description == "asc") {
      let sortedList = [
        ...feeCategory.sort((a, b) =>
          b.description.localeCompare(a.description)
        ),
      ];
      setFeeCategory(sortedList);
      setSort({ ...sort, description: "des" });
    } else {
      let sortedList = [
        ...feeCategory.sort((a, b) =>
          a.description.localeCompare(b.description)
        ),
      ];
      setFeeCategory(sortedList);
      setSort({ ...sort, description: "asc" });
    }
  };

  const sortByPercentage = () => {
    console.log("percentageShare");
    if (sort.percentageShare == "asc") {
      let sortedList = [
        ...feeCategory.sort((a, b) => b.percentageShare - a.percentageShare),
      ];

      setFeeCategory(sortedList);
      setSort({ ...sort, percentageShare: "des" });
    } else {
      let sortedList = [
        ...feeCategory.sort((a, b) => a.percentageShare - b.percentageShare),
      ];
      setFeeCategory(sortedList);
      setSort({ ...sort, percentageShare: "asc" });
    }
  };

  console.log("feeCategory", feeCategory);
  return (
    <div>
      <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span style={{ color: "red", fontFamily: "cursive" }}>
              Fee Category List:
            </span>
          </Grid>
        </Grid>
      </Card>
      <div className={css.container}>
        <div className={css.funcContainer}>
          {/* <FormControl sx={{
                    width:'16%'
                }}>
                    <InputLabel>Select Category</InputLabel>
                    <Select
                    size="small"
                    value={selectCategory || ''}
                    onChange={(e) => setSelectCategory(e.target.value)}
                    label="Select Receipt"
                    >
                        {category.map((fee) => (
                            <MenuItem value={fee._id} key={fee._id}>{fee.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl> */}
          <Button
            onClick={handleOpenAddDialog}
            size="small"
            variant="contained"
          >
            <Add />
            add fee category
          </Button>
        </div>
        <div className={css.tableContainer}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
                <TableRow>
                  <TableCell sx={{ color: "white" }} align="center">
                    fav
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    <Checkbox
                      checked={selected.length === feeCategory.length}
                      indeterminate={
                        selected.length > 0 &&
                        feeCategory.length > selected.length
                      }
                      onChange={handleSelectAllClick}
                      color="primary"
                    />
                  </TableCell>

                  <TableCell sx={{ color: "white" }} align="center">
                    #SL
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Fee Category
                    <span
                      onClick={sortByFeeCategory}
                      style={{ cursor: "pointer" }}
                    >
                      {sort.name === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Description
                    <span
                      onClick={sortByDescription}
                      style={{ cursor: "pointer" }}
                    >
                      {sort.description === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Percentage
                    <span
                      onClick={sortByPercentage}
                      style={{ cursor: "pointer" }}
                    >
                      {sort.percentageShare === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {feeCategory.map((fees, index) => (
                  <TableRow
                  // key={fees._id}
                  // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  // hover
                  // onClick={(event) => handleClick(event, fees._id)}
                  // role="checkbox"
                  // aria-checked={isRowSelected}
                  // selected={isRowSelected}
                  >
                    <TableCell align="center">
                      <div
                        variant="contained"
                        size="small"
                        onClick={() => handleFavorite(fees._id)}
                      >
                        {fees.addedToFavorites === true ? (
                          <FavoriteIcon style={{ color: "red" }} />
                        ) : (
                          <FavoriteBorderIcon style={{ color: "red" }} />
                        )}
                      </div>
                    </TableCell>

                    <TableCell align="center">
                      <Checkbox
                        checked={selected.includes(fees._id)}
                        onChange={(e) => handleClick(e, fees._id)}
                        color="primary"
                      />
                    </TableCell>

                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{fees.name}</TableCell>
                    <TableCell align="center">{fees.description}</TableCell>
                    <TableCell align="center">{fees.percentageShare}</TableCell>

                    <TableCell align="right">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                        }}
                      >
                        <div
                          onClick={() => handleClassMapEdit(fees._id, fees)}
                          size="small"
                          variant="contained"
                        >
                          <ModeEditIcon />
                        </div>
                        <div size="small" variant="contained">
                          |
                        </div>
                        <Button
                          onClick={() => setDeleteModal(fees._id, fees)}
                          size="small"
                          variant="contained"
                        >
                          <DeleteIcon />
                        </Button>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={fees._id}
                          setDeleteModal={setDeleteModal}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                          }}
                        >
                          <div
                            variant="contained"
                            size="small"
                            onClick={() => handlePriorityUp(index, fees._id)}
                          >
                            <ArrowUpwardIcon />
                          </div>
                          <div
                            variant="contained"
                            size="small"
                            onClick={() => handlePriorityDown(index, fees._id)}
                          >
                            <ArrowDownwardIcon />
                          </div>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {selected.length > 1 && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleDeleteMultiple}
              >
                Delete
              </Button>
            )}
          </TableContainer>
        </div>

        <Dialog open={addDialog} onClose={handleCloseAddDialog}>
          <form onSubmit={handleAddSubmit}>
            <DialogTitle>
              {editFeeCategory
                ? "Update Fee Category Name"
                : "Create New Fee Category Name"}
            </DialogTitle>
            <DialogContent>
              <div className={css.dialogContainer}>
                <div className={css.dialogContainerFlex}>
                  <TextField
                    className={css.dialog_select}
                    label="Name"
                    size="small"
                    name="name"
                    value={addForm.name || ""}
                    onChange={handleAddForm}
                  />
                  <TextField
                    className={css.dialog_select}
                    label="Description"
                    size="small"
                    name="description"
                    value={addForm.description || ""}
                    onChange={handleAddForm}
                  />{" "}
                  <TextField
                    className={css.dialog_select}
                    label="Percentage"
                    size="small"
                    name="percentageShare"
                    value={addForm.percentageShare || ""}
                    onChange={handleAddForm}
                  />
                </div>
              </div>
            </DialogContent>

            <DialogActions>
              <Button color="warning" onClick={handleCloseAddDialog}>
                Cancel
              </Button>
              <Button type="submit">
                {editFeeCategory ? "update Fee Category" : "Add Fee Category"}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    </div>
  );
};

export default FeeCategory;
