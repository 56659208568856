import React, { useState, useEffect } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "./../styles/components/navbar/Navbar.scss";
import { Language } from "@mui/icons-material";
import "./../styles/components/navbar/NavTop.scss";
import css from './Addmission.module.css'
import Footer from "./Footer/Footer";
import TopNav from "./navbar/TopNav";
import Navbar from "./navbar/Navbar";
import Demo from "./NewsNotic/Demo";
import { get } from "../services/apis";

const AwardsAndAchivments = () => {
    const [addClass, updateClass] = useState(false);
  const [stkyClass, setStkyClass] = useState(false);
  const [mega, setMega] = useState(false);
  const [award,setAward] = useState(null);

  const {awardsId} = useParams();

  const handleClickCross = () => {
    updateClass(false);
    window.scrollTo(0, 0);
  };
  const scrollHandleScroll = () => {
    if (window.scrollY > 20) {
      setStkyClass(true);
    } else {
      setStkyClass(false);
    }
  };
  const handleMega = () => {
    setMega(!mega);
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollHandleScroll);
  }, []);

  useEffect(() => {
    const getAwards = async () => {
        try {
            const award = await get(`/award/list/single/${awardsId}`);
            console.log(award)
            if(award.data.success) {
                setAward(award.data.data)
            }
        } catch (error) {
            
        }
    }
    getAwards();
  },[])
  return (
    <>
        <TopNav />
        <Navbar />
      <div className={css.headerContainer}>
                <div>
                    <h1>AWARDS AND ACHIEVEMENTS</h1>
                    <h5><Link style={{
                        color:'white'
                    }} to='/'>Home</Link> / AWARDS AND ACHIEVEMENTS</h5>
                </div>
      </div>
      <div className={css.contentContainer}>
        <div className={css.content}>
            {award && (
                <>
                <div>
                    <img width={500} height={300} src={award.image ? award.image.link : ''} alt="" />
                </div>
                <h5>{award.title}</h5>
                <p>
                    {award.note}
                </p>
                </>
            )}
        </div>
      </div>

      <Footer />
    </>
  );
}

export default AwardsAndAchivments;