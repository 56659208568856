import React, { useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
  Card,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TableHead,
  Avatar,
  Switch,
  Tooltip,
} from "@mui/material";
import { useEffect } from "react";
import { urls } from "../../../services/urlConstant";
import "../../../styles/components/Exam/Exam.scss";
import { get, post, put } from "../../../services/apis";
import { objHas } from "../../../utils/utils";
import { toast } from "react-toastify";

const styles = {
  card: {
    padding: 10,
    width: "100%",
    marginBottom: "10px",
  },
  notfoundStyle: {
    minHeight: "74vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
    color: "white",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
    m: 1,
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
    marginTop: "10px",
  },
};

const ExamAttendance = () => {
  const [studentsAttendence, setStudentsAttendence] = useState(undefined);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [examTerms, setExamterms] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [attendanceDetails, setAttendanceDetails] = useState({});
  const [allPresent, setAllPresent] = useState(false);
  // const[change,setChange]=useState(false);

  useEffect(() => {
    if (studentsAttendence) {
      const studentsDetails = studentsAttendence.studentsAttendence;
      const allStudPresent = studentsDetails.every(
        (curVal) => curVal.examAttended === true
      );
      setAllPresent(allStudPresent);
    }
  }, [studentsAttendence]);

  const handleClasses = async (e) => {
    try {
      const { name, value } = e.target;
      setAttendanceDetails({ ...attendanceDetails, [name]: value });
      const res = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: value,
          },
        },
      });
      setSections(res.data.data);
      const response = await get(urls.schoolSubject.getAllSubject, {
        params: {
          search: {
            class: value,
          },
        },
      });
      setSubjects(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSections = (e) => {
    const { name, value } = e.target;
    setAttendanceDetails({ ...attendanceDetails, [name]: value });
  };

  const handleExamTerms = (e) => {
    const { name, value } = e.target;
    setAttendanceDetails({ ...attendanceDetails, [name]: value });
  };

  const handleSubjects = (e) => {
    const { name, value } = e.target;
    setAttendanceDetails({ ...attendanceDetails, [name]: value });
  };
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const data = {
        classId: attendanceDetails.className,
        sectionId: attendanceDetails.sectionName,
        examTermId: attendanceDetails.examTitle,
        subjectId: attendanceDetails.subjectName,
      };

      const res = await post(urls.examAttendance.postExamAttendance, data);
      setStudentsAttendence(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect....

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        setClasses(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);

  useEffect(() => {
    const getExamTerms = async () => {
      try {
        const res = await get(urls.examTerm.getAllExamTerm);
        setExamterms(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getExamTerms();
  }, []);

  const handleAttendanceChange = async (e, id, examAttended, studentId) => {
    if (examAttended) {
      const res = await put(
        `${urls.examAttendance.putExamAttendancePresent}/${id}/absent/${studentId}`
      );
      setStudentsAttendence(res.data.data);
    } else {
      const res = await put(
        `${urls.examAttendance.putExamAttendancePresent}/${id}/present/${studentId}`
      );
      setStudentsAttendence(res.data.data);
    }
  };
  const handleAllPresent = async (e) => {
    const checked = e.target.checked;
    let result = {};
    const studentsDetails = studentsAttendence.studentsAttendence;
    if (!checked) {
      for (const student of studentsDetails) {
        const res = await put(
          `${urls.examAttendance.putExamAttendancePresent}/${studentsAttendence._id}/absent/${student.student._id}`
        );
        toast.dismiss();
        result = res.data.data;
      }
    } else {
      for (const student of studentsDetails) {
        const res = await put(
          `${urls.examAttendance.putExamAttendancePresent}/${studentsAttendence._id}/present/${student.student._id}`
        );
        toast.dismiss();
        result = res.data.data;
      }
    }
    setStudentsAttendence({ ...result });
  };

  return (
    <div>
      <div className="examfilter-searchbar">
        <form onSubmit={handleSubmit}>
          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <span
                    style={{
                      color: "red",
                      fontFamily: "cursive",
                      marginLeft: "-86%",
                    }}
                  >
                    Exam Attendance
                  </span>
                </Grid>
              </Grid>
            </Box>
          </Card>
          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={2}>
                  <FormControl
                    variant="standard"
                    sx={styles.textfield}
                    required
                  >
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Class
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="className"
                      value={attendanceDetails.className || ""}
                      onChange={(event) => handleClasses(event)}
                    >
                      {classes &&
                        classes.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.className}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl
                    variant="standard"
                    sx={styles.textfield}
                    required
                  >
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Section
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="sectionName"
                      value={attendanceDetails.sectionName || ""}
                      onChange={(event) => handleSections(event)}
                    >
                      {sections &&
                        sections.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.sectionName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl
                    variant="standard"
                    sx={styles.textfield}
                    required
                  >
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Exam
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="examTitle"
                      value={attendanceDetails.examTitle || ""}
                      onChange={(event) => handleExamTerms(event)}
                    >
                      {examTerms &&
                        examTerms.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.examTitle}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl
                    variant="standard"
                    sx={styles.textfield}
                    required
                  >
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Subject
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="subjectName"
                      value={attendanceDetails.subjectName || ""}
                      onChange={(event) => handleSubjects(event)}
                    >
                      {subjects &&
                        subjects.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.subjectName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={1}
                  sx={{
                    alignSelf: "center",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Tooltip title="Find">
                    <Button
                      variant="contained"
                      style={styles.Button}
                      size="small"
                      type="submit"
                    >
                      Find
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </form>
      </div>

      {!studentsAttendence ? (
        <div style={styles.notfoundStyle}>
          <img style={{ height: 241 }} src="./record.webp" alt="notfound" />
        </div>
      ) : (
        <div style={{ margin: "15px" }}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead style={{ background: "rgb(27 55 121)" }}>
                  <TableRow>
                    <TableCell align="center">
                      <span className="class-table-header">#SL</span>
                    </TableCell>

                    <TableCell align="center">
                      <span className="class-table-header">Photo</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Name</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Phone</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">Roll No</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="class-table-header">
                        <Switch
                          checked={allPresent}
                          onChange={handleAllPresent}
                        />{" "}
                        Attend All
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {studentsAttendence &&
                    studentsAttendence.studentsAttendence.map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>

                        <TableCell
                          align="center"
                          style={{
                            textTransform: "capitalize",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Avatar
                            alt={row.student.basicInfo.name}
                            src={objHas(row, "student.studentPhoto.link", "")}
                          />
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.student.basicInfo.name}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.student.contactNumber}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.student.academicInfo.rollNo}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <Switch
                            onChange={(e) =>
                              handleAttendanceChange(
                                e,
                                studentsAttendence._id,
                                row.examAttended,
                                row.student._id
                              )
                            }
                            checked={row.examAttended}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      )}
    </div>
  );
};

export default ExamAttendance;
