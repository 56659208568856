import React, { useState } from "react";

import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
  Card,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TableHead,
  Modal,
  Typography,
  TextField,
  Collapse,
  IconButton,
  Tooltip,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import { urls } from "../../../services/urlConstant";
import "../../../styles/components/Exam/Exam.scss";
import { get, post } from "../../../services/apis";
import { Close, DownloadForOffline } from "@mui/icons-material";

import style from "../../../styles/components/Layout/Attendance/AttendanceReport.module.css";
import { instance } from "../../../services/axios.Config";
import { LoadingButton } from "@mui/lab";

const styles = {
  card: {
    padding: 10,
    width: "100%",
    marginBottom: "10px",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
    m: 1,
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
  },
};
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const Consolidated = () => {
  const [studentsExamResult, setStudentsExamResult] = useState([]);

  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [examTerms, setExamterms] = useState([]);
  const [examResultDetails, setExamResultDetails] = useState({});
  const [studentMarksDetailsModal, setStudentMarksDetailsModal] =
    useState(false);
  const [studentExamResult, setStudentExamResult] = useState(undefined);
  const [consolidated, setConsolidated] = useState([]);
  const [selectClass, setSelectClass] = useState("");
  const [selectSection, setSelectSection] = useState("");
  const [consolidatedMarks, setConsolidatedMarks] = useState([]);
  const [consolidatedClass, setConsolidatedClass] = useState([]);
  const consolidate = useStyles();
  const [open, setOpen] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({});

  const [consolidating, setConsolidating] = useState(false);
  const [consolidatingMarks, setConsolidatingMarks] = useState(false);

  useEffect(() => {
    setIsSubmitted(false);
  }, [consolidated]);

  useEffect(() => {
    const getSubjects = async () => {
      try {
        if (selectClass) {
          const subjects = await get(urls.schoolSubject.getAllSubject, {
            params: {
              search: {
                class: selectClass,
              },
            },
          });

          if (subjects.data.success) {
            setSubjects(subjects.data.data);
          }
        }
      } catch (error) {}
    };
    getSubjects();
  }, [selectClass]);

  const handleClick = (index) => {
    if (index === open) return setOpen(false);
    setOpen(index);
  };

  const handleAddConsolidated = () => {
    setConsolidated([
      ...consolidated,
      {
        examId: "",
        compress: 0,
      },
    ]);
  };
  const handleCompressChange = (e, index) => {
    setConsolidated(
      consolidated.map((con, i) => {
        if (index == i) {
          return {
            ...con,
            compress: parseInt(e.target.value),
          };
        } else {
          return con;
        }
      })
    );
  };
  const handleExamChange = (e, index) => {
    setConsolidated(
      consolidated.map((con, i) => {
        if (index == i) {
          return {
            ...con,
            examId: e.target.value,
          };
        } else {
          return con;
        }
      })
    );
  };
  const handleSubjectChange = (e, examIndex, subjectIndex) => {
    setConsolidated(
      consolidated.map((con, i) => {
        if (examIndex === i) {
          return {
            ...con,
            subjects: con.subjects.map((subject, i) => {
              if (subjectIndex === i) {
                return {
                  ...subject,
                  subject: e.target.value,
                };
              } else {
                return subject;
              }
            }),
          };
        } else {
          return con;
        }
      })
    );
  };
  const handleSubjectCompressChange = (e, examIndex, subjectIndex) => {
    let compress = Number(e.target.value);
    setConsolidated(
      consolidated.map((con, i) => {
        if (examIndex === i) {
          return {
            ...con,
            subjects: con.subjects.map((subject, i) => {
              if (subjectIndex === i) {
                return {
                  ...subject,
                  compress: compress ? compress : "",
                };
              } else {
                return subject;
              }
            }),
          };
        } else {
          return con;
        }
      })
    );
  };

  const handleClasses = async (e) => {
    try {
      const { name, value } = e.target;
      setExamResultDetails({ ...examResultDetails, [name]: value });
      const res = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: value,
          },
        },
      });
      setSections(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getClasses = async () => {
      try {
        const schoolClasses = await get(urls.class.getAllClass);
        if (schoolClasses.data.success) {
          setClasses(schoolClasses.data.data);
        }
      } catch (error) {}
    };
    getClasses();
  }, []);

  useEffect(() => {
    const getSections = async () => {
      try {
        const sections = await get(urls.schoolSection.getAllSection, {
          params: {
            search: {
              class: selectClass,
            },
          },
        });
        if (sections.data.success) {
          setSections(sections.data.data);
        }
      } catch (error) {}
    };
    if (selectClass) {
      getSections();
    }
  }, [selectClass]);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    const getExamTerms = async () => {
      try {
        const res = await get(urls.examTerm.getAllExamTerm);
        setExamterms(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getExamTerms();
  }, []);

  const handleFindStudentResult = async (e) => {
    e.preventDefault();
    try {
      const data = {
        con: consolidated,
        classId: selectClass,
        sectionId: selectSection,
      };
      setConsolidatingMarks(true);
      const consolidatedRes = await post(`/exam-res/con`, data);
      console.log(consolidatedRes.data.data, "==============");
      setConsolidatedMarks(consolidatedRes.data.data);
      setIsSubmitted(true);
    } catch (error) {
      console.log(error);
    }
    setConsolidatingMarks(false);
  };
  const handleModalClose = () => setStudentMarksDetailsModal(false);
  const handleModalOpen = () => setStudentMarksDetailsModal(true);

  const handleRemoveCon = (removeIndex) => {
    setConsolidated((prev) =>
      prev.filter((ele, index) => index !== removeIndex)
    );
  };

  const handleSheetConsolidated = async () => {
    if (!selectClass || !selectSection)
      return alert("Please select class and section");
    try {
      setConsolidating(true);
      const data = {
        con: consolidated,
        classId: selectClass,
        sectionId: selectSection,
      };
      const getConsolidatedSheet = await instance({
        method: "post",
        url: `/exam-result-con-excel`,
        data: data,
        responseType: "blob",
      });
      if (!getConsolidatedSheet) return setConsolidating(false);
      const uri = URL.createObjectURL(getConsolidatedSheet.data);
      const link = document.createElement("a");
      link.href = uri;
      const getClass = await get(`${urls.class.getClass}/${selectClass}`);
      console.log(getClass, "0-09");
      link.setAttribute(
        "download",
        `consolidated(${getClass.data.data.className}).xlsx`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.log(error);
    }
    setConsolidating(false);
  };
  const handleAddSubject = (addIndex) => {
    setConsolidated(
      consolidated.map((con, index) => {
        if (index === addIndex) {
          if (con.subjects) {
            return {
              ...con,
              subjects: [
                ...con.subjects,
                {
                  subject: "",
                  compress: 0,
                },
              ],
            };
          } else {
            return {
              ...con,
              subjects: [
                {
                  subject: "",
                  compress: 0,
                },
              ],
            };
          }
        } else {
          return con;
        }
      })
    );
  };
  const handleRemoveSubject = (examIndex, subjectIndex) => {
    setConsolidated(
      consolidated.map((con, i) => {
        if (i === examIndex) {
          return {
            ...con,
            subjects: con.subjects.filter(
              (subject, index) => index !== subjectIndex
            ),
          };
        } else {
          return con;
        }
      })
    );
  };
  return (
    <div>
      <div>
        <Card style={{ margin: 15, padding: 10 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <span
                  style={{
                    color: "red",
                    fontFamily: "cursive",
                  }}
                >
                  Consolidate :
                </span>
              </Grid>
            </Grid>
          </Box>
        </Card>
        <form onSubmit={handleFindStudentResult}>
          <Card style={{ margin: "15px", padding: "10px" }}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl
                    variant="standard"
                    sx={styles.textfield}
                    required
                  >
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Class
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="selectClass"
                      value={selectClass}
                      onChange={(e) => setSelectClass(e.target.value)}
                      required
                    >
                      {classes.map((row) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.className}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl
                    variant="standard"
                    sx={styles.textfield}
                    required
                  >
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Sections
                    </InputLabel>
                    <Select
                      name="section"
                      required
                      variant="standard"
                      value={selectSection}
                      label="Section"
                      onChange={(e) => setSelectSection(e.target.value)}
                    >
                      {/* {formData.class && <MenuItem value="all">-all-</MenuItem>} */}
                      {selectClass && (
                        <MenuItem value="all">----all----</MenuItem>
                      )}
                      {sections.map((section) => (
                        <MenuItem key={section._id} value={section._id}>
                          {section.sectionName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <div style={{ display: "flex" }}>
                <div style={{ alignSelf: "center" }}>
                  <Button
                    variant="contained"
                    style={styles.Button}
                    size="small"
                    onClick={handleAddConsolidated}
                  >
                    Add Exams
                  </Button>
                </div>
                <div style={{ width: "90%" }}>
                  {consolidated.length > 0 &&
                    consolidated.map((con, index) => (
                      <div key={index}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "flex-end",
                          }}
                        >
                          <div style={{ width: "35%" }}>
                            <FormControl
                              variant="standard"
                              sx={styles.textfield}
                              required
                            >
                              <TextField
                                required
                                id="outlined-uncontrolled"
                                label="compress"
                                variant="standard"
                                sx={{ width: "100%" }}
                                type="number"
                                value={con.compress}
                                onChange={(e) => handleCompressChange(e, index)}
                              />
                            </FormControl>
                          </div>
                          <div style={{ width: "35%" }}>
                            <FormControl
                              variant="standard"
                              sx={styles.textfield}
                              required
                            >
                              <InputLabel
                                id="demo-simple-select-filled-label"
                                sx={{ fontSize: 12 }}
                              >
                                Exam
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                name="examTitle"
                                value={con.examId || ""}
                                onChange={(e) => handleExamChange(e, index)}
                              >
                                {examTerms &&
                                  examTerms.map((row, index) => (
                                    <MenuItem
                                      key={row._id}
                                      value={row._id}
                                      sx={{ fontSize: 12, fontWeight: 500 }}
                                    >
                                      {row.examTitle}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </div>
                          <div>
                            <Button onClick={() => handleRemoveCon(index)}>
                              <Close />
                            </Button>
                          </div>
                          <div>
                            <Button onClick={() => handleAddSubject(index)}>
                              Add Subject
                            </Button>
                          </div>
                        </div>
                        <div>
                          {con.subjects &&
                            con.subjects.map((subject, subjectIndex) => (
                              <div
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  justifyContent: "center",
                                  alignItems: "flex-end",
                                }}
                              >
                                <div style={{ width: "35%" }}>
                                  <FormControl
                                    variant="standard"
                                    sx={styles.textfield}
                                    required
                                  >
                                    <TextField
                                      required
                                      id="outlined-uncontrolled"
                                      label="compress"
                                      variant="standard"
                                      sx={{ width: "100%" }}
                                      type="number"
                                      value={subject.compress}
                                      onChange={(e) =>
                                        handleSubjectCompressChange(
                                          e,
                                          index,
                                          subjectIndex
                                        )
                                      }
                                    />
                                  </FormControl>
                                </div>
                                <div style={{ width: "35%" }}>
                                  <FormControl
                                    variant="standard"
                                    sx={styles.textfield}
                                    required
                                    fullWidth
                                  >
                                    <InputLabel
                                      id="demo-simple-select-filled-label"
                                      sx={{ fontSize: 12 }}
                                    >
                                      Subjects
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-filled-label"
                                      id="demo-simple-select-filled"
                                      name="examTitle"
                                      value={subject.subject || ""}
                                      onChange={(e) =>
                                        handleSubjectChange(
                                          e,
                                          index,
                                          subjectIndex
                                        )
                                      }
                                    >
                                      {subjects &&
                                        subjects.map((row, index) => (
                                          <MenuItem
                                            key={row._id}
                                            value={row.subjectName}
                                            sx={{
                                              fontSize: 12,
                                              fontWeight: 500,
                                            }}
                                          >
                                            {row.subjectName}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </FormControl>
                                </div>
                                <div>
                                  <Button
                                    onClick={() =>
                                      handleRemoveSubject(index, subjectIndex)
                                    }
                                  >
                                    <Close />
                                  </Button>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{
                    alignSelf: "center",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {consolidated.length > 0 && (
                    <LoadingButton
                      loading={consolidatingMarks}
                      variant="contained"
                      // style={styles.Button}
                      size="small"
                      type="submit"
                    >
                      Consolidate
                    </LoadingButton>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Card>
        </form>
      </div>
      <div style={{ margin: "15px" }}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          {selectClass && isSubmitted && (
            <div className={style.iconContainer}>
              <LoadingButton
                disabled={!selectClass || !selectSection}
                size="small"
                loading={consolidating}
                onClick={handleSheetConsolidated}
              >
                <DownloadForOffline />
              </LoadingButton>
            </div>
          )}
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "30%",
                        justifyContent: "flex-start ",
                      }}
                    >
                      <div align="center">
                        <span className="class-table-header">Name</span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-around",
                      }}
                    >
                      <div align="center">
                        <span className="class-table-header"></span>
                      </div>
                      <div align="center">
                        <span className="class-table-header"></span>
                      </div>
                      <div align="center">
                        <span className="class-table-header"></span>
                      </div>
                    </div>
                  </div>
                </TableRow>
              </TableHead>
              <TableContainer>
                <Table
                  style={{ width: "100%" }}
                  className={consolidate.table}
                  aria-label="collapsible table"
                >
                  {consolidatedMarks.length > 0 &&
                    consolidatedMarks.map((result, index) => (
                      <div>
                        <div style={{ width: "100%" }}>
                          <TableHead>
                            <TableRow>
                              <div style={{ padding: "10px" }}>
                                {result.basicInfo.name}({result.class.className}
                                )
                                <IconButton
                                  aria-label="expand rows"
                                  size="small"
                                  onClick={() => handleClick(index + 1)}
                                >
                                  {open === index + 1 ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}
                                </IconButton>
                              </div>
                            </TableRow>
                          </TableHead>
                        </div>

                        <div style={{ width: "100%" }}>
                          <Collapse
                            in={open === index + 1}
                            timeout="auto"
                            unmountOnExit
                          >
                            <TableCell
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <div>#SL</div>
                              </div>
                              <div>
                                <div>Subject</div>
                              </div>
                              <div>
                                <div>Consolidated Marks</div>
                              </div>
                            </TableCell>
                            {result.exams.map((exam, index) => (
                              <TableCell
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    position: "relative",
                                    left: "10px",
                                  }}
                                >
                                  {index + 1}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    position: " relative",
                                    right: "48px",
                                  }}
                                >
                                  {exam.subjectName}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    position: "relative",
                                    right: "58px",
                                  }}
                                >
                                  {exam.compressTotal?.toFixed(1)}
                                </div>
                              </TableCell>
                            ))}
                          </Collapse>
                        </div>
                      </div>
                    ))}
                </Table>
              </TableContainer>
            </Table>
          </TableContainer>
        </Paper>
        <Modal
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          open={studentMarksDetailsModal}
          onClose={handleModalClose}
        >
          <Box sx={modalStyle}>
            {studentExamResult && (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 250 }} aria-label="simple table">
                  <TableHead style={{ background: "rgb(27 55 121)" }}>
                    <TableRow>
                      <TableCell align="center">
                        <span className="class-table-header">SL No</span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="class-table-header">Subject</span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="class-table-header">Max Marks</span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="class-table-header">
                          Obtained Marks
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="class-table-header">Grade</span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="class-table-header">
                          Position In Section
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="class-table-header">
                          Postion In Class
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {studentExamResult &&
                      studentExamResult.studentMarks.map((mark, index) => (
                        <TableRow
                          key={mark._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {mark.subject.subjectName}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {mark.maxMarks}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {mark.writtenMarks}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {mark.grade}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {`postion in class`}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {`position in class`}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {studentExamResult && (
              <div>
                <Typography variant="subtitle1">
                  Total Subject: {studentExamResult.studentMarks.length}
                </Typography>
                <Typography variant="subtitle1">
                  Max Marks: {studentExamResult.totalMax}
                </Typography>
                <Typography variant="subtitle1">
                  Obtained Marks: {studentExamResult.totalMarks}
                </Typography>
                <Typography variant="subtitle1">
                  Percentage: {studentExamResult.percentage}
                </Typography>
              </div>
            )}
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default Consolidated;
