import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Button,
  Tooltip,
} from "@mui/material";
import "../../../styles/components/AcademicYear/DateModal.scss";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import { get } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 500,
  bgcolor: "background.paper",
  p: 4,
};

const AddClassModal = (props) => {
  const [className, setClassName] = useState("");
  const [numericName, setNumericName] = useState("");
  const [orderSequence, setOrderSequence] = useState("");
  const [studentAttendenceType, setStudentAttendenceType] = useState("");
  const [classTeacher, setClassTeacher] = useState("");
  const [classNote, setClassNote] = useState("");
  const [status, setStatus] = useState("");
  const [employees,setEmployees] = useState([]); 
  const [isPublish, setIsPublish] = useState("");

  useEffect(() => {
    if (props.updateClass) {
      setClassName(props.updateClass.className ? props.updateClass.className : "");
      setNumericName(props.updateClass.numericName ? props.updateClass.numericName : '');
      setOrderSequence(props.updateClass.orderSequence ? props.updateClass.orderSequence : '');
      setStudentAttendenceType(props.updateClass.studentAttendenceType ? props.updateClass.studentAttendenceType : '');
      setClassTeacher(props.updateClass.classTeacher ? props.updateClass.classTeacher : '');
      setClassNote(props.updateClass.classNote ? props.updateClass.classNote : '');
      setStatus(props.updateClass.status ? props.updateClass.status : '');
      setIsPublish(props.updateClass.isPublish ? props.updateClass.isPublish : '');
    }
  }, [props.updateClass]);

  const handleClassName = (e) => {
    setClassName(e.target.value);
  };
  const handleNumericName = (e) => {
    setNumericName(e.target.value);
  };
  const handleOrderSequence = (e) => {
    setOrderSequence(e.target.value);
  };
  const handleStudentAttendenceType = (e) => {
    setStudentAttendenceType(e.target.value);
  };
  const handleClassTeacher = (e) => {
    setClassTeacher(e.target.value);
  };
  const handleClassNote = (e) => {
    setClassNote(e.target.value);
  };
  const handleStatus = (e) => {
    setStatus(e.target.value);
  };
  const handleIsPublish = (e) => {
    setIsPublish(e.target.value);
  };
  const handleSubmit = (e) => {
    if (props.updateClass) {
      props.handleUpdate(
        {
          className,
          orderSequence,
          studentAttendenceType,
          classNote,
          classTeacher,
          numericName,
          status,
          isPublish,
        },
        props.updateClass._id
      );
    } else {
      props.handleSubmit(e, {
        className,
        orderSequence,
        studentAttendenceType,
        classNote,
        classTeacher,
        numericName,
        status,
        isPublish,
      });
    }
    setClassName("");
    setNumericName("");
    setOrderSequence("");
    setStudentAttendenceType("");
    setClassTeacher("");
    setClassNote("");
    setStatus("");
    setIsPublish("");
    props.handleClose();
  };

  const handleCloseModal = () => {
    setClassName("");
    setNumericName("");
    setOrderSequence("");
    setStudentAttendenceType("");
    setClassTeacher("");
    setClassNote("");
    setStatus("");
    setIsPublish("");
    props.handleClose();
  };
  useEffect(() => {
    const getEmps = async () => {
      try {
        const getEmps = await get(urls.employee.getAllEmployee);
        setEmployees(getEmps.data.data);
      } catch (error) {
        console.log(error);
      }
    }
    getEmps();
  },[]);
  return (
    <div>
      <Tooltip title="Add" disableInteractive>
      <Fab
        size="medium"
        style={{ background: "#1b3779", color: "#fff" }}
        aria-label="add"
        onClick={props.handleOpen}
      >
        <Add />
      </Fab>
      </Tooltip>
      <Modal
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleSubmit}>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
              marginBottom="30px"
            >
              Add Class
            </Typography>
            <div className="">
              <TextField
                required
                variant="standard"
                label="Class Name"
                value={className}
                onChange={handleClassName}
                sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
              />
              {/* <TextField
                required
                variant="standard"
                label="Numeric Name"
                value={numericName}
                onChange={handleNumericName}
                sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
              />
              <TextField
                required
                variant="standard"
                label="Order sequence"
                value={orderSequence}
                onChange={handleOrderSequence}
                sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
              /> */}
              
              <FormControl
                  variant="standard"
                  sx={{ m: 1, minWidth: 227 }}
                  required
                >
                  <InputLabel sx={{ fontSize: 12 }}>
                    Student Attendance Type
                  </InputLabel>
                  <Select
                    value={studentAttendenceType}
                    onChange={handleStudentAttendenceType}
                  >
                    <MenuItem
                      value={"classWise"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Classwise
                    </MenuItem>
                  </Select>
                </FormControl>

                <FormControl variant="standard" sx={{ m: 1, minWidth: 227 }}>
                  <InputLabel sx={{ fontSize: 12 }}>Class Teacher</InputLabel>
                  <Select
                    id="demo-simple-select-filled"
                    value={classTeacher}
                    onChange={handleClassTeacher}
                  >
                    {employees && employees.map((emp) => (
                    <MenuItem
                      value={emp._id}
                      style={{ fontSize: 12, fontWeight: 500 }}
                    >
                      {emp.basicInfo.empName}
                    </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              <div>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 227 }}>
                  <InputLabel sx={{ fontSize: 12 }}>Status</InputLabel>
                  <Select
                    id="demo-simple-select-filled"
                    value={status}
                    onChange={handleStatus}
                  >
                    <MenuItem
                      value={"inactive"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Inactive
                    </MenuItem>
                    <MenuItem
                      value={"active"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Active
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 227 }}>
                  <InputLabel
                    id="demo-simple-select-required-label"
                    sx={{ fontSize: 12 }}
                  >
                    Is Publish? *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-required-label"
                    id="demo-simple-select-required"
                    value={isPublish}
                    onChange={handleIsPublish}
                  >
                    <MenuItem
                      value={"inactive"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Inactive
                    </MenuItem>
                    <MenuItem
                      value={"active"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Active
                    </MenuItem>
                  </Select>
                </FormControl>
                <TextField
                required
                type='number'
                variant="standard"
                label="Order Sequence"
                value={orderSequence}
                onChange={handleOrderSequence}
                />
              </div>
              <div>
                <TextField
                  required
                  type='number'
                  variant="standard"
                  label="Numeric Name"
                  value={numericName}
                  onChange={handleNumericName}
                  />
              </div>
              <div>
                <TextareaAutosize
                  maxRows={4}
                  aria-label="maximum height"
                  placeholder="Drop a note"
                  value={classNote}
                  onChange={handleClassNote}
                  style={{
                    width: 712,
                    maxHeight: 110,
                    margin: 8,
                    padding: 10,
                    height: 118,
                    overflow: "auto",
                  }}
                />
              </div>
            </div>

            <div className="cancel-submit-btn">
              <div>
                <Button
                  variant="outlined"
                  size="medium"
                  type="button"
                  sx={{ m: 1 }}
                  onClick={() => props.handleClose()}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  type="submit"
                  sx={{ background: "rgb(27, 55, 121)", ":hover":{background: "rgb(27, 55, 121)"} }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
};

export default AddClassModal;