import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { del, get, post, put } from "../../services/apis";
import { urls } from "../../services/urlConstant";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import { Delete } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteModal from "../Layout/Academic/DeleteModal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}
const styles = {
  icon: {
    padding: "10px",
    fontSize: "12px",
    fontWeight: 500,
  },
  card: {
    padding: 15,
    marginBottom: 20,
    width: "100%",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
};

const HostelMember = () => {
  const [academicYears, setAcademicYears] = useState([]);
  const [formData, setFormData] = useState([]);
  const [fromDate, setFromDate] = useState(dayjs().subtract(1, "day"));
  const [toDate, setToDate] = useState(null);
  const [sections, setSections] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [classes, setClasses] = useState([]);
  const [hostelName, setHostelName] = React.useState([]);
  const [roomName, setRoomName] = React.useState([]);
  const [feePlan, setFeePlan] = React.useState([]);
  const [hostelfee, setHostelfee] = React.useState([]);
  const [roomNo, setRoomNo] = React.useState([]);
  const [students, setStudents] = useState([]);
  const [nonStudents, setNonStudents] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(1000);
  const [page, setPage] = useState(0);
  const [showPagination, setShowPagination] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [addForm, setAddForm] = useState({});
  const [editForm, setEditForm] = useState({});
  const [studentList, setStudentList] = React.useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [masterFeeOverview, setMasterFeeOverview] = useState([]);
  const [filterFeeOverview, setFilterFeeOverview] = useState([]);
  const [differentFeeOverView, setDifferentFeeOverView] = useState({
    modes: {},
    total: 0,
  });
  const [studentToAdd, setStudentToAdd] = useState("");
  const [mode, setMode] = useState("edit");
  const [beds, setBeds] = useState([]);
  const [rooms, setRooms] = useState([]);

  const [member, setMember] = useState();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    height: 460,
    bgcolor: "background.paper",
    p: 4,
  };

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll);
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data[0]._id,
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);

  const handleAddHostelButtonClick = (id) => {
    console.log(id, "ihhhhh");
    setMode("create");
    setStudentToAdd(id);
    setOpen(true);
  };

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        const resSections = await get(urls.schoolSection.getAllSection, {
          params: {
            search: {
              class: res.data.data[0]._id,
            },
          },
        });
        const searchStudents = await get(urls.students.getAllStudent, {
          params: {
            search: {
              academicYear: formData.academicYear,
              "academicInfo.class": res.data.data[0]._id,
              "academicInfo.section": resSections.data.data[0]._id,
            },
          },
        });
        setSections(resSections.data.data);
        setClasses(res.data.data);
        setFormData((prev) => ({
          ...prev,
          className: res.data.data[0]._id,
          section: resSections.data.data[0]._id,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // const handleOnEditChange = (e) => {
  //   const { name, value } = e.target;

  //   if (name=="hostel"){
  //     let newroomsList=roomNo.filter(r=>r.hostel==value)
  //     setEditForm({
  //       ...editForm,
  //       [name]: value,
  //     });
  //   }
  //   setEditForm({
  //     ...editForm,

  //     [name]: value,
  //   });
  // };

  const handleOnEditChange = (e) => {
    const { name, value } = e.target;

    // if (name === "hostel") {
    //   let newRoomsList = roomNo.filter((r) => r.hostel === value);
    //   setRoomNo(newRoomsList); // Set the filtered rooms based on the selected hostel
    // }

    setEditForm({
      ...editForm,
      [name]: value,
    });
  };

  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      if (section.data.data.length > 0) {
        setFormData((prev) => ({
          ...prev,
          section: section.data.data[0]._id,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          section: "",
        }));
      }
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleStudentSearchSubmit = async (e) => {
    e.preventDefault();
    try {
      const searchOptions = {
        academicYear: formData.academicYear,
        "academicInfo.class": formData.className,
        status: formData.status,
      };

      let searchParams = {
        params: {
          search: searchOptions,
          limit: rowsPerPage,
          page: page + 1,
        },
      };

      if (formData.section !== "all") {
        searchOptions["academicInfo.section"] = formData.section;
      }

      const searchStudents = await get(
        urls.students.getAllStudent,
        searchParams
      );
      const sortedStudents = searchStudents.data.data.sort((a, b) => {
        const aIsHostelMember = a.otherInfo.hostelMember === "yes";
        const bIsHostelMember = b.otherInfo.hostelMember === "yes";
        if (aIsHostelMember && !bIsHostelMember) {
          return -1; // a comes first
        } else if (!aIsHostelMember && bIsHostelMember) {
          return 1; // b comes first
        } else {
          return 0; // maintain the original order
        }
      });

      setStudents(sortedStudents);
      console.log(sortedStudents, "sorttttttttt");
      if (searchStudents.data.count > rowsPerPage) {
        setShowPagination(true);
      }
      setTotalCount(searchStudents.data.count);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNonStudentSearchSubmit = async (e) => {
    e.preventDefault();
    try {
      const searchOptions = {
        academicYear: formData.academicYear,
        "academicInfo.class": formData.class,
        status: formData.status,
      };

      if (formData.section === "all") {
        const searchStudents = await get(urls.students.getAllStudent, {
          params: {
            search: searchOptions,
            limit: rowsPerPage,
            page: page + 1,
          },
        });

        const filteredStudents = searchStudents.data.data.filter(
          (student) => student.otherInfo.hostelMember === "no"
        );

        setNonStudents(filteredStudents);
        if (searchStudents.data.count > rowsPerPage) {
          setShowPagination(true);
        }
        setTotalCount(filteredStudents.length);
      } else {
        const searchStudent = await get(urls.students.getAllStudent, {
          params: {
            search: {
              ...searchOptions,
              "academicInfo.section": formData.section,
            },
            limit: rowsPerPage,
            page: page + 1,
          },
        });

        const filteredStudents = searchStudent.data.data.filter(
          (student) => student.otherInfo.hostelMember === "no"
        );

        setNonStudents(filteredStudents);
        if (searchStudent.data.count > rowsPerPage) {
          setShowPagination(true);
        }
        setTotalCount(filteredStudents.length);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [selectedHostelName, setSelectedHostelName] = useState();
  // useEffect(() => {
  //   const getHostelNames = async () => {
  //     try {
  //       const { data, status } = await get(`${urls.hostel.getList}`);
  //       if (status > 199 && status < 299) {
  //         setHostelName(data.data);
  //         setSelectedHostelName(data.data)
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   getHostelNames();
  // }, []);

  useEffect(() => {
    const getHostelNames = async () => {
      try {
        const { data, status } = await get(`${urls.hostel.getList}`);
        if (status > 199 && status < 299) {
          setHostelName(data.data);
          setSelectedHostelName(data.data[0]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getHostelNames();
  }, []);

  useEffect(() => {
    const getRoomNo = async () => {
      try {
        const { data, status } = await get(`${urls.room.getList}`);
        if (status > 199 && status < 299) {
          const sortedRoomNo = data.data.sort((a, b) => a.number - b.number);
          setRoomNo(sortedRoomNo);
          console.log(sortedRoomNo, "sortedRoomNo");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getRoomNo();
  }, []);
  // useEffect(() => {
  //   if (editForm.hostel) {
  //     console.log("Edit Form Hostel:", editForm.hostel);
  //     console.log("All Hostels:", hostelName);
  //     console.log("All Rooms:", roomNo);

  //     const interestedHostel = hostelName.find(
  //       (h) => h._id === editForm.hostel
  //     );
  //     console.log("Interested Hostel:", interestedHostel);

  //     if (interestedHostel) {
  //       const interestedRooms = roomNo.filter(
  //         (r) => r.hostel === interestedHostel._id
  //       );
  //       console.log("Interested Rooms:", interestedRooms);
  //       setRooms(interestedRooms);
  //     }
  //   }
  // }, [editForm.hostel, hostelName, roomNo]);

  useEffect(() => {
    const getRoomName = async () => {
      try {
        const { data, status } = await get(`${urls.room.getList}`);
        if (status > 199 && status < 299) {
          setRoomName(data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getRoomName();
  }, []);

  useEffect(() => {
    if (editForm.room) {
      let interestedRoom = roomName.filter((r) => r._id == editForm.room)[0];
      setBeds(interestedRoom.beds);
    }
  }, [editForm]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const getHostelMembers = async () => {
    try {
      const searchOptions = {
        academicYear: formData.academicYear,
        "academicInfo.class": formData.className,
        status: formData.status,
      };

      let searchParams = {
        params: {
          search: searchOptions,
          limit: rowsPerPage,
          page: page + 1,
        },
      };

      if (formData.section !== "all") {
        searchOptions["academicInfo.section"] = formData.section;
      }

      const searchStudents = await get(
        urls.students.getAllStudent,
        searchParams
      );
      console.log(searchStudents.data, "searchStudents");
      const sortedStudents = searchStudents.data.data.sort((a, b) => {
        const aIsHostelMember = a.otherInfo.hostelMember === "yes";
        const bIsHostelMember = b.otherInfo.hostelMember === "yes";
        if (aIsHostelMember && !bIsHostelMember) {
          return -1; // a comes first
        } else if (!aIsHostelMember && bIsHostelMember) {
          return 1; // b comes first
        } else {
          return 0; // maintain the original order
        }
      });

      setStudents(sortedStudents);
      console.log(sortedStudents, "sorttttttttt");
      if (searchStudents.data.count > rowsPerPage) {
        setShowPagination(true);
      }
      setTotalCount(searchStudents.data.count);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getHostelMembers();
  }, [formData]);
  useEffect(() => {
    const getNonhostelStudent = async () => {
      try {
        const searchOptions = {
          academicYear: formData.academicYear,
          "academicInfo.class": formData.class,
          status: formData.status,
        };

        if (formData.section !== "all") {
          const searchStudents = await get(urls.students.getAllStudent, {
            params: {
              search: searchOptions,
              limit: rowsPerPage,
              page: page + 1,
            },
          });

          const filteredStudents = searchStudents.data.data.filter(
            (student) => student.otherInfo.hostelMember === "no"
          );

          setNonStudents(filteredStudents);
          if (searchStudents.data.count > rowsPerPage) {
            setShowPagination(true);
          }
          setTotalCount(filteredStudents.length);
        } else {
          const searchStudent = await get(urls.students.getAllStudent, {
            params: {
              search: {
                ...searchOptions,
                "academicInfo.section": formData.section,
              },
              limit: rowsPerPage,
              page: page + 1,
            },
          });

          const filteredStudents = searchStudent.data.data.filter(
            (student) => student.otherInfo.hostelMember === "no"
          );

          setNonStudents(filteredStudents);
          if (searchStudent.data.count > rowsPerPage) {
            setShowPagination(true);
          }
          setTotalCount(filteredStudents.length);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getNonhostelStudent();
  }, []);

  const handleClickOpen = (id) => {
    setMode("edit");
    let studentToEdit = students.filter((s) => s._id == id)[0];
    setEditForm({
      hostel: studentToEdit.hostelInfo ? studentToEdit.hostelInfo.hostel : "",
      room: studentToEdit.hostelInfo ? studentToEdit.hostelInfo.room._id : "",
      bed: studentToEdit.hostelInfo ? studentToEdit.hostelInfo.bed : "",
    });
    setStudentToAdd(studentToEdit._id);

    setOpen(true);
  };

  const handleClose = () => {
    // setEditHostel(null);
    setEditForm({});
    setOpen(false);
  };

  const handleAddSubmit = async (e) => {
    console.log(editForm, "edittt");

    e.preventDefault();

    try {
      const { status } = await put(
        `${urls.hostelMember.editMember}/`,
        studentToAdd,
        {
          hostel: editForm.hostel,
          room: editForm.room,
          bed: editForm.bed,
        }
      );
      handleClose();
      getHostelMembers();
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteMember = async (e, id) => {
    e.preventDefault();

    try {
      const { status } = await put(
        `${urls.hostelMember.deleteMember}/`,
        id,
        {}
      );
      getHostelMembers();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span
              style={{
                color: "red",
                fontFamily: "cursive",
                textAlign: "start",
              }}
            >
              Hostel Member:
            </span>
          </Grid>
        </Grid>
      </Card>

      <Card style={{ margin: 15, padding: 10 }}>
        <Box>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Student List" {...a11yProps(0)} />
            <Tab label="Non Member List" {...a11yProps(1)} />
          </Tabs>
        </Box>
      </Card>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container spacing={2} marginBottom={"5px"}>
          {/* <form className="form-tag" onSubmit={}> */}
          <form onSubmit={(e) => handleAddSubmit(e)}>
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign="center"
                marginBottom="30px"
                // onChange={handleAddForm}
              >
                {mode === "edit" ? "Update" : "Add"}
              </Typography>
              <div>
                <Grid item xs={12} md={6} lg={5} sx={{ mt: 2, padding: 1 }}>
                  <FormControl fullWidth required>
                    <InputLabel>Select Hostel</InputLabel>
                    <Select
                      label="Hostel Name"
                      id="demo-simple-select-filled"
                      value={editForm.hostel || ""}
                      name="hostel"
                      size="small"
                      onChange={(e) => handleOnEditChange(e)}
                    >
                      {hostelName.map((name) => (
                        <MenuItem key={name._id} value={name._id}>
                          {name.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={5} sx={{ mt: 2, padding: 1 }}>
                  <FormControl fullWidth required>
                    <InputLabel>Select Room No</InputLabel>
                    <Select
                      label="Room No"
                      id="demo-simple-select-filled"
                      name="room"
                      value={editForm.room || ""}
                      onChange={(e) => handleOnEditChange(e)}
                      size="small"
                    >
                      {roomNo.map((num) => (
                        <MenuItem key={num._id} value={num._id}>
                          {num.number}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={5} sx={{ mt: 2, padding: 1 }}>
                  <FormControl fullWidth required>
                    <InputLabel>Select Bed</InputLabel>
                    <Select
                      label="Bed"
                      id="demo-simple-select-filled"
                      value={editForm.bed || ""}
                      onChange={(e) => handleOnEditChange(e)}
                      name="bed"
                      size="small"
                    >
                      {beds.map((b) => (
                        <MenuItem key={b._id} value={b._id}>
                          {b.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </div>

              <div className="cancel-submit-btn">
                <div>
                  <Tooltip title="Cancel">
                    <Button
                      onClick={handleClose}
                      variant="outlined"
                      size="medium"
                      type="button"
                      sx={{ m: 1 }}
                    >
                      Cancel
                    </Button>
                  </Tooltip>
                  <Tooltip title="Submit">
                    <Button
                      variant="contained"
                      size="medium"
                      type="submit"
                      sx={{
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      {mode === "edit" ? "update" : "create"}
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </Box>
          </form>
        </Grid>
      </Modal>

      <TabPanel value={tabValue} index={0}>
        <Card style={{ margin: 15, padding: 10 }}>
          <Box sx={{ flexGrow: 1 }}>
            <form onSubmit={handleStudentSearchSubmit}>
              <Grid container spacing={2} marginBottom={"5px"}>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth required>
                    <InputLabel>Academic Year</InputLabel>
                    <Select
                      label="Academic Year"
                      id="demo-simple-select-filled"
                      name="academicYear"
                      value={formData.academicYear || ""}
                      onChange={handleOnChange}
                      size="small"
                    >
                      {academicYears &&
                        academicYears.map((row, index) => (
                          <MenuItem key={row._id} value={row._id}>
                            {row.academicYearFrom} - {row.academicYearTo}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth required>
                    <InputLabel>Class</InputLabel>
                    <Select
                      label="Class"
                      id="demo-simple-select-filled"
                      name="className"
                      value={formData.className || ""}
                      onChange={handleClassChange}
                      size="small"
                    >
                      {classes &&
                        classes.map((row, index) => (
                          <MenuItem key={row._id} value={row._id}>
                            {row.className}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth required>
                    <InputLabel>Section</InputLabel>
                    <Select
                      label="Section"
                      id="demo-simple-select-filled"
                      name="section"
                      value={formData.section || ""}
                      onChange={handleOnChange}
                      size="small"
                    >
                      {formData.className && (
                        <MenuItem value="all">--all--</MenuItem>
                      )}
                      {sections.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.sectionName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={1}
                  style={{ alignSelf: "flex-end" }}
                >
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    style={styles.Button}
                  >
                    SEARCH
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Card>
        <div style={{ margin: "15px" }}>
          <Table>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Name
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Admission No
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Room
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {students.map((name, index) => (
                <TableRow key={name._id}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{name.basicInfo.name}</TableCell>

                  <TableCell align="center">
                    {name.academicInfo.admissionNo}
                  </TableCell>

                  <TableCell align="center">
                    {name.hostelInfo
                      ? name.hostelInfo.room
                        ? name.hostelInfo.room.number
                        : "NA"
                      : "NA"}
                  </TableCell>
                  <TableCell align="center">
                    {name.otherInfo.hostelMember === "yes" ? (
                      <div>
                        <Button
                          style={{ color: "#1b3779" }}
                          onClick={() => handleClickOpen(name._id)}
                        >
                          <EditIcon />
                        </Button>

                        <Button
                          style={{
                            color: "#1b3779",
                          }}
                          onClick={(e) => handleDeleteMember(e, name._id)}
                        >
                          <Delete />
                        </Button>
                      </div>
                    ) : (
                      <Button
                        onClick={() => handleAddHostelButtonClick(name._id)}
                      >
                        Add Hostel
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <Card style={{ margin: 15, padding: 10 }}>
          <Box sx={{ flexGrow: 1 }}>
            <form onSubmit={handleNonStudentSearchSubmit}>
              <Grid container spacing={1} marginBottom={"5px"}>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth required>
                    <InputLabel>Academic Year</InputLabel>
                    <Select
                      label="Academic Year"
                      id="demo-simple-select-filled"
                      name="academicYear"
                      value={formData.academicYear || ""}
                      onChange={handleOnChange}
                      size="small"
                    >
                      {academicYears &&
                        academicYears.map((row, index) => (
                          <MenuItem key={row._id} value={row._id}>
                            {row.academicYearFrom} - {row.academicYearTo}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth required>
                    <InputLabel>Class</InputLabel>
                    <Select
                      label="Class"
                      id="demo-simple-select-filled"
                      name="className"
                      value={formData.className || ""}
                      onChange={handleClassChange}
                      size="small"
                    >
                      {classes &&
                        classes.map((row, index) => (
                          <MenuItem key={row._id} value={row._id}>
                            {row.className}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth required>
                    <InputLabel>Section</InputLabel>
                    <Select
                      label="Section"
                      id="demo-simple-select-filled"
                      name="section"
                      value={formData.section || ""}
                      onChange={handleOnChange}
                      size="small"
                    >
                      {formData.className && (
                        <MenuItem value="all">--all--</MenuItem>
                      )}
                      {sections &&
                        sections.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.sectionName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={1}
                  style={{ alignSelf: "flex-end" }}
                >
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    style={styles.Button}
                  >
                    SEARCH
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Card>
        <div style={{ margin: "15px" }}>
          <Table>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Name
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Admission No
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Room
                </TableCell>

                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nonStudents.map((name, index) => (
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{name.basicInfo.name}</TableCell>

                  <TableCell align="center">
                    {name.academicInfo.admissionNo}
                  </TableCell>
                  <TableCell align="center"></TableCell>

                  <TableCell align="center">
                    <Button
                      onClick={() => handleAddHostelButtonClick(name._id)}
                    >
                      Add Hostel
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </TabPanel>
    </div>
  );
};

export default HostelMember;
