import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Grid,
  Paper,
  Modal,
  TextField,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  TextareaAutosize,
  MenuItem,
  Tooltip,
  Dialog,
  DialogTitle,
  IconButton,
  Fab,
  InputAdornment,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import DeleteModal from "../Layout/Academic/DeleteModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styles from "../../styles/components/Layout/Administrator/UserRole.module.css";

import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { get, post, put, del } from "../../services/apis";
import { urls } from "../../services/urlConstant";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";

import { Delete, Edit, Search } from "@mui/icons-material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 450,
  bgcolor: "background.paper",
  p: 4,
};
const modal = {
  position: "absolute",
  top: "50%",
  left: "50%",

  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const StudentLibrary = () => {
  const [search, setSearch] = useState("");
  const [list, setList] = React.useState([]);
  const [searchFilter, setSearchFilter] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [openUpload, setOpenUpload] = React.useState(false);
  const [file, setFile] = useState();
  const [dialog, setOpenDialog] = React.useState(false);
  const [editBook, setEditBook] = useState();
  const [addForm, setAddForm] = useState({});
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [issueTotalCount, setIssueTotalCount] = useState();
  const [empIssueTotalCount, setEmpIssueTotalCount] = useState();
  const [issue, setIssue] = useState([]);
  const [empIssue, setEmpIssue] = useState([]);
  const [showDetails, setShowDetails] = useState(null);
  const [bulkUpdateModal, setBulkUpdateModal] = useState(false);
  const [fileChoosen, setFileChoosen] = useState(undefined);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    getbooks();
    getEmpIssueList();
    getStuIssueList();
  }, []);
  const getbooks = async () => {
    try {
      const data = await get(urls.book.getList);

      setList(data.data.data);
      console.log(data.data.data, "ddaaaaaaattaaaaa");
    } catch (error) {
      console.log(error);
    }
  };

  const getEmpIssueList = async () => {
    console.log("issue");
    try {
      const response = await get(urls.empIssueList.get, {
        params: {
          limit: 10,
          page: 1,
        },
      });
      const filterIssue = response.data.data.filter(
        (issues) => issues.submitted === "no"
      );
      setEmpIssue(filterIssue);
      console.log(filterIssue, "setEmpIssueTotalCount");
      setEmpIssueTotalCount(filterIssue.length);
    } catch (error) {
      console.log(error);
    }
  };

  const getStuIssueList = async () => {
    try {
      // setShowPagination(true);
      const response = await get(urls.issueList.get, {
        params: {
          limit: 10,
          page: 1,
        },
      });
      const filterIssue = response.data.data.filter(
        (issues) => issues.submitted === "no"
      );
      setIssue(filterIssue);
      console.log(filterIssue, "setIssueTotalCount");
      setIssueTotalCount(filterIssue.length);
    } catch (error) {
      console.log(error);
    }
  };
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  const styles = {
    button: {
      backgroundColor: "orange",
    },
    Button: {
      background: "#1b3779",
      color: "#fff",
      ":hover": { background: "#1b3779" },
    },
    textfield: {
      marginTop: "25px",
      width: "70%",
      marginLeft: "5%",
      marginRight: "5%",
    },
    addbutton: {
      paddingTop: "7px",
      paddingBottom: "7px",
      marginLeft: "-5%",
      backgroundColor: "",
    },
    tab: {
      width: "100%",
      borderCollapse: "collapse",
    },
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      list.length > 0 &&
        setSearchFilter(
          list.filter((ele) =>
            ele.booktitle.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };
  const handleDeleteone = async (id) => {
    try {
      const res = await del(`${urls.book.delBook}`, id);
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setEditBook(null);
    setOpenDialog(false);
    resetForm();
  };
  const resetForm = () => {
    setAddForm({});
  };
  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleClickOpenUpload = () => {
    setOpenUpload(true);
  };
  const handleCloseUpload = () => {
    setOpenUpload(false);
  };
  const handleClickOpenDialog = (data) => {
    setShowDetails(data);
    setOpenDialog(true);
  };

  const handleImages = (e) => {
    setImages((prev) => [...prev, ...e.target.files]);
  };

  const handleCloseDialog = (data) => {
    setShowDetails(data);
    setOpenDialog(false);
  };
  const handleGetDownloadSheet = async (e) => {
    e.preventDefault();
    try {
      const response = await get(`/library/downloadexcel`, {
        responseType: "blob",
      });

      const downloadUrl = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `book-file.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetCountDownloadSheet = async (e) => {
    e.preventDefault();
    try {
      const response = await get(`/library/downloadcountexcel`, {
        responseType: "blob",
      });

      const downloadUrl = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `library-count.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetStockDownloadSheet = async (e) => {
    e.preventDefault();
    try {
      const response = await get(`/library/downloadstockexcel`, {
        responseType: "blob",
      });

      const downloadUrl = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `Book-Stock-file.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!editBook) {
        let imageIds = [];
        const formImage = new FormData();
        if (images.length > 0) {
          for (const img of images) {
            formImage.append("files", img);
          }
          const res = await post(urls.fileUpload.postFileUpload, formImage);
          imageIds = res.data.result.map((id) => id._id);
        }

        const { status } = await post(`${urls.book.create}`, {
          booktitle: addForm.booktitle,
          booksId: addForm.booksId,
          department: addForm.department,
          number: addForm.number,
          isbnNo: addForm.isbnNo,
          edison: addForm.edison,
          author: addForm.author,
          language: addForm.language,
          price: addForm.price,
          quantity: addForm.quantity,
          almiraNo: addForm.almiraNo,
          backCover: imageIds,
        });
        getbooks();
        handleClose();
      } else {
        let imageIds = [];
        const formImage = new FormData();
        if (images.length > 0) {
          for (const img of images) {
            formImage.append("files", img);
          }
          const res = await post(urls.fileUpload.postFileUpload, formImage);
          imageIds = res.data.result.map((id) => id._id);
        }
        const { status } = await put(`${urls.book.editBook}${editBook}`, "", {
          booktitle: addForm.booktitle,
          booksId: addForm.booksId,
          department: addForm.department,
          number: addForm.number,
          isbnNo: addForm.isbnNo,
          edison: addForm.edison,
          author: addForm.author,
          language: addForm.language,
          price: addForm.price,
          quantity: addForm.quantity,
          almiraNo: addForm.almiraNo,
          // backCover: imageIds,
        });
        getbooks();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleTypeChange = (e) => {
    if (e.target.name === "bookCover") {
      setAddForm({
        ...addForm,
        bookCover: e.target.files[0],
      });
    } else {
      setAddForm({
        ...addForm,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleEditChange = (id, data) => {
    setEditBook(id);
    setAddForm({
      booktitle: data.booktitle,
      booksId: data.booksId,
      department: data.department,
      number: data.number,
      isbnNo: data.isbnNo,
      edison: data.edison,
      author: data.author,
      language: data.language,
      price: data.price,
      quantity: data.quantity,
      almiraNo: data.almiraNo,
      // backCover: data.backCover.link,
    });
    handleClickOpen();
  };

  const handleGetUpdateSheet = async (e) => {
    e.preventDefault();
    try {
      const response = await get(`/library/downloadsample`, {
        responseType: "blob",
      });

      const downloadUrl = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `sample-file.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileChange = (e) => {
    setFileChoosen(e.target.files[0]);
  };

  const handleSheetUpload = async (e) => {
    e.preventDefault();
    console.log("test ", fileChoosen);
    try {
      if (!fileChoosen) {
        console.error("No file selected.");
        return;
      }

      const formData = new FormData();
      formData.append("sheet", fileChoosen);
      const response = await put(`/library/uploadsample`, "", formData);
      console.log("Response:", response.data);
      console.log("Sheet uploaded successfully!");
      getbooks();
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div style={{ margin: "15px" }}>
      <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span
              style={{
                color: "red",
                fontFamily: "cursive",
                textAlign: "start",
              }}
            >
              Library Books:
            </span>
          </Grid>
        </Grid>
      </Card>
      <div style={{ margin: "15px" }}>
        <Paper sx={{ width: "100%", height: "60px", overflow: "hidden" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid xs={3}>
                <TextField
                  size="small"
                  style={styles.textfield}
                  value={search}
                  onChange={handleSearch}
                  placeholder="enter book name"
                  label="Search"
                  variant="outlined"
                />
              </Grid>
              <Grid>
                <Button
                  variant="contained"
                  sx={{ mt: 3.2 }}
                  style={styles.addbutton}
                  onClick={handleClickOpen}
                >
                  <AddIcon />
                  Add Book
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>
        <Modal
          open={bulkUpdateModal}
          onClose={() => setBulkUpdateModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modal}>
            <div style={{ textAlign: "center", marginBottom: "30px" }}>
              <span style={{ color: "red", fontFamily: "cursive" }}>
                Bulk Upload
              </span>
            </div>
            <form onSubmit={handleSheetUpload}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Tooltip title="Get Sample">
                    <LoadingButton
                      variant="contained"
                      size="small"
                      type="submit"
                      loading={loadingBtn}
                      onClick={handleGetUpdateSheet}
                      sx={{
                        backgroundColor: "#1b3779",
                        ":hover": { backgroundColor: "#1b3779" },
                      }}
                    >
                      Sample
                    </LoadingButton>
                  </Tooltip>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>
                    <Tooltip title="Upload">
                      <Button
                        variant="contained"
                        style={styles.Button}
                        component="label"
                      >
                        Upload
                        <input
                          onChange={handleFileChange}
                          name="file"
                          hidden
                          accept=".xlsx, .xls, xlsm, .xlsb, .csv, .ods"
                          type="file"
                        />
                      </Button>
                    </Tooltip>

                    <div>{fileChoosen && fileChoosen.name}</div>
                  </div>
                </div>
                <div>
                  <Tooltip title="Submit">
                    <Button
                      variant="contained"
                      style={styles.Button}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </form>
          </Box>
        </Modal>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form className="form-tag" onSubmit={handleAddSubmit}>
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign="center"
                marginBottom="30px"
                onChange={handleTypeChange}
              >
                {editBook ? "Update Book" : "Add Book"}{" "}
              </Typography>
              <div>
                <TextField
                  required
                  id="filled-required"
                  variant="standard"
                  label="Book Title"
                  name="booktitle"
                  value={addForm.booktitle || ""}
                  onChange={handleTypeChange}
                  sx={{
                    m: 1,
                    minWidth: 227,
                    label: { fontSize: 12 },
                    label: { fontSize: 12 },
                  }}
                />
                <TextField
                  required
                  id="filled-required"
                  variant="standard"
                  sx={{ m: 1, minWidth: 227 }}
                  label="Book ID"
                  name="booksId"
                  onChange={handleTypeChange}
                  value={addForm.booksId || ""}
                />
                <TextField
                  required
                  id="filled-required"
                  variant="standard"
                  sx={{ m: 1, minWidth: 227 }}
                  label="Department"
                  name="department"
                  onChange={handleTypeChange}
                  value={addForm.department || ""}
                />
                <div>
                  <TextField
                    required
                    id="filled-required"
                    variant="standard"
                    sx={{ m: 1, minWidth: 227 }}
                    label="Call Number"
                    name="number"
                    onChange={handleTypeChange}
                    value={addForm.number || ""}
                  />

                  <TextField
                    required
                    id="filled-required"
                    variant="standard"
                    label="ISBN No"
                    name="isbnNo"
                    value={addForm.isbnNo || ""}
                    onChange={handleTypeChange}
                    sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                  />

                  <TextField
                    required
                    id="filled-required"
                    variant="standard"
                    label="Edition"
                    name="edison"
                    value={addForm.edison || ""}
                    onChange={handleTypeChange}
                    sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                  />
                </div>

                <div>
                  <TextField
                    required
                    id="filled-required"
                    variant="standard"
                    sx={{ m: 1, minWidth: 227 }}
                    label="Author"
                    name="author"
                    onChange={handleTypeChange}
                    value={addForm.author || ""}
                  />

                  <TextField
                    required
                    id="filled-required"
                    variant="standard"
                    label="Language"
                    name="language"
                    value={addForm.language || ""}
                    onChange={handleTypeChange}
                    sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                  />

                  <TextField
                    required
                    id="filled-required"
                    variant="standard"
                    label="Price"
                    name="price"
                    value={addForm.price || ""}
                    onChange={handleTypeChange}
                    sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                  />
                </div>

                <TextField
                  required
                  id="filled-required"
                  variant="standard"
                  sx={{ m: 1, minWidth: 227 }}
                  label="Quantity"
                  name="quantity"
                  onChange={handleTypeChange}
                  value={addForm.quantity || ""}
                />

                <TextField
                  required
                  id="filled-required"
                  variant="standard"
                  label="Almira No"
                  name="almiraNo"
                  onChange={handleTypeChange}
                  value={addForm.almiraNo || ""}
                  sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                />
                {editBook ? (
                  ""
                ) : (
                  <div style={{ marginTop: "2px", display: "flex" }}>
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                    >
                      <label>Book Cover</label>
                      <input
                        type="file"
                        name="image"
                        accept="image/*"
                        onChange={handleImages}
                      />
                    </FormControl>
                  </div>
                )}
              </div>
              <div className="cancel-submit-btn">
                <div>
                  <Tooltip title="Cancel">
                    <Button
                      onClick={handleClose}
                      variant="outlined"
                      size="medium"
                      type="button"
                      sx={{ m: 1 }}
                    >
                      Cancel
                    </Button>
                  </Tooltip>
                  <Tooltip title="Submit">
                    <Button
                      variant="contained"
                      size="medium"
                      type="submit"
                      sx={{
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      Add
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </Box>
          </form>
        </Modal>
        <div>
          <Paper
            sx={{
              width: "100%",
              height: "60px",
              overflow: "hidden",
              mt: 3,
              padding: 2,
            }}
          >
            <Stack spacing={2} direction="row">
              <Button variant="contained" onClick={handleGetDownloadSheet}>
                Download
              </Button>
              <Button variant="contained" onClick={handleGetCountDownloadSheet}>
                Count By title
              </Button>
              <Button variant="contained" onClick={handleGetStockDownloadSheet}>
                Stock Verification
              </Button>
              <Button
                variant="contained"
                onClick={() => setBulkUpdateModal(true)}
              >
                Bulk Upload
              </Button>
            </Stack>
          </Paper>
        </div>
        {/*<Modal
          open={openUpload}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form className="form-tag">
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign="center"
                marginBottom="30px"
                // onChange={handleAddForm}
              >
                Bulk Uploadss
              </Typography>

              <div style={{ marginTop: "2px", display: "flex" }}>
                <FormControl
                  variant="standard"
                  sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                >
                  <label>Upload File</label>
                  <input type="file" onChange={handleChange} />
                </FormControl>
              </div>

              <div className="cancel-submit-btn">
                <div>
                  <Tooltip title="Cancel">
                    <Button
                      onClick={handleCloseUpload}
                      variant="outlined"
                      size="medium"
                      type="button"
                      sx={{ m: 1 }}
                    >
                      Cancel
                    </Button>
                  </Tooltip>
                  <Tooltip title="Submit">
                    <Button
                      variant="contained"
                      size="medium"
                      type="submit"
                      sx={{
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      Add
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </Box>
          </form>
                    </Modal>*/}

        <Table>
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow sx={{ color: "white" }}>
              <TableCell sx={{ color: "white" }} align="center">
                #SL
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Title
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Book ID
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Author
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Book Cover
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Price
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Quantity
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Issue
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Available
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {search
              ? searchFilter.map((book, index) => (
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{book.booktitle}</TableCell>
                    <TableCell align="center">{book.booksId}</TableCell>
                    <TableCell align="center">{book.author}</TableCell>
                    <TableCell align="center">
                      {" "}
                      {book.backCover ? (
                        <img
                          src={book.backCover.link}
                          alt="NA"
                          style={{
                            width: "100px",
                            height: "60px",
                            resize: "contain",
                          }}
                        ></img>
                      ) : (
                        "NA"
                      )}
                    </TableCell>
                    <TableCell align="center">{book.price}</TableCell>
                    <TableCell align="center">{book.quantity}</TableCell>
                    <TableCell align="center">{book.issue}</TableCell>
                    <TableCell align="center">{book.balance}</TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          style={{ color: "#1b3779" }}
                          onClick={() => handleEditChange(book._id, book)}
                        >
                          <EditIcon />
                        </Button>
                        <Button
                          style={{ color: "#1b3779" }}
                          onClick={() => handleClickOpenDialog(book)}
                        >
                          <VisibilityIcon />
                        </Button>
                        <Button
                          style={{
                            color: "#1b3779",
                          }}
                          onClick={() => setDeleteModal(book._id, book)}
                        >
                          <Delete />
                        </Button>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={book._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              : list.map((book, index) => (
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{book.booktitle}</TableCell>
                    <TableCell align="center">{book.booksId}</TableCell>
                    <TableCell align="center">{book.author}</TableCell>
                    <TableCell align="center">
                      {" "}
                      {book.backCover ? (
                        <img
                          src={book.backCover.link}
                          alt="NA"
                          style={{
                            width: "100px",
                            height: "60px",
                            resize: "contain",
                          }}
                        ></img>
                      ) : (
                        "NA"
                      )}
                    </TableCell>
                    <TableCell align="center">{book.price}</TableCell>
                    <TableCell align="center">{book.quantity}</TableCell>

                    <TableCell align="center">{book.issue}</TableCell>
                    <TableCell align="center">{book.balance}</TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          style={{ color: "#1b3779" }}
                          onClick={() => handleEditChange(book._id, book)}
                        >
                          <EditIcon />
                        </Button>
                        <Button
                          style={{ color: "#1b3779" }}
                          onClick={() => handleClickOpenDialog(book)}
                        >
                          <VisibilityIcon />
                        </Button>
                        <Button
                          style={{
                            color: "#1b3779",
                          }}
                          onClick={() => setDeleteModal(book._id, book)}
                        >
                          <Delete />
                        </Button>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={book._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
        {!list.length && !search.trim() && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        {search.trim() && !searchFilter.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        <BootstrapDialog
          onClose={handleCloseDialog}
          aria-labelledby="customized-dialog-title"
          open={dialog}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: "#1b3779", color: "white" }}
          >
            Book Information
          </BootstrapDialogTitle>

          <DialogContent dividers>
            <table className="hostel" style={styles.tab}>
              <tbody>
                {showDetails ? (
                  <div>
                    <tr>
                      <td>School Name</td>
                      <td>Excellent English Medium Primary & High School</td>
                    </tr>
                    <tr>
                      <td>Book Title</td>
                      <td>{showDetails.booktitle}</td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <td>Book ID</td>
                      <td>{showDetails.booksId}</td>
                    </tr>
                    <tr>
                      <td>Author</td>
                      <td>{showDetails.author}</td>
                    </tr>
                    <tr>
                      <td>Language</td>
                      <td>{showDetails.language}</td>
                    </tr>
                    <tr>
                      <td>Quantity</td>
                      <td>{showDetails.quantity}</td>
                    </tr>
                    <tr>
                      <td>Price</td>
                      <td>{showDetails.price}</td>
                    </tr>
                    <tr>
                      <td>Alamira No</td>
                      <td>{showDetails.almiraNo}</td>
                    </tr>
                    <tr>
                      <td>Created</td>
                      <td>
                        {dayjs(showDetails.entrydate).format("DD-MM-YYYY")}
                      </td>
                    </tr>
                  </div>
                ) : null}
              </tbody>
            </table>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </div>
  );
};

export default StudentLibrary;
