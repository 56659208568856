import { Box, Card, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";

// style
import css from "../../../styles/fees/FeeRemittance.module.css";
import FeeRemittanceView from "./components/FeeRemittanceView";
import FeeRemittanceAdd from "./components/FeeRemittanceAdd";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const FeeRemittance = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className={css.container}>
      <Card>
        <Box>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="FEE REMITTANCE" {...a11yProps(0)} />
            <Tab label="ADD FEE REMITTANCE" {...a11yProps(1)} />
          </Tabs>
        </Box>
      </Card>
      <TabPanel value={tabValue} index={0}>
        <FeeRemittanceView />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <FeeRemittanceAdd />
      </TabPanel>
    </div>
  );
};
export default FeeRemittance;
