import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useRef, useState } from 'react';

// style
import css from '../../../../styles/fees/QuickFeeConcessionModal.module.css';
import { toast } from 'react-toastify';

const QuickFeeConcessionModal = (props) => {
    const [formData,setFormData] = useState({});
    const [error,setError] = useState('')
    const formRef = useRef();
    const btnClick = useRef();

    const handleFormChange = (e) => {
        const {name,value} = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]:value
        }))
    }   
    const handleSubmit = (e) => {
        e.preventDefault();
        if(!formData.refer && !formData.format && !formData.concession) return setError('Please fill all fields')
        props.onSubmit(formData)
        setFormData({});
        setError('')
    }
    return(
        <div>
            <Dialog fullWidth maxWidth='sm' open={props.open} onClose={props.onClose}>
                <DialogTitle>Add Concession</DialogTitle>
                <DialogContent>
                    <div className={css.dialogForm}>
                        <form onSubmit={handleSubmit} ref={formRef} className={css.formContainer}>
                            <TextField 
                            className={css.form_field}
                            required
                            value={formData.refer || ''}
                            name='refer'
                            label="Reference/recommended"
                            onChange={handleFormChange}
                            size='small'
                            />
                            <FormControl
                            className={css.form_field}
                            >
                                <InputLabel>Concession format</InputLabel>
                                <Select
                                required
                                value={formData.format || ''}
                                name='format'
                                label='Concession format'
                                size='small'
                                onChange={handleFormChange}
                                >
                                    <MenuItem value="Value">Value</MenuItem>
                                    <MenuItem value="Percentage">Percentage</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField 
                            type='number'
                            required
                            className={css.form_field}
                            value={formData.concession || ''}
                            name='concession'
                            label='Concession'
                            size='small'
                            onChange={handleFormChange}
                            />
                        </form>
                    </div>
                    <div style={{color:'red'}}>{error}</div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} color='warning' variant='contained'>Cancel</Button>
                        <Button onClick={() => {
                            formRef.current.dispatchEvent(new Event('submit',{ cancelable: true, bubbles: true }));
                        }} variant='contained'>Add</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default QuickFeeConcessionModal;