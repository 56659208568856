import React, { useContext, useEffect, useState } from "react";
import { Paper, TextField, Typography, Button, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { Person, Key, VisibilityOff, Visibility } from "@mui/icons-material";
import { Avatar, Link } from "@mui/material";
import { LockOutlined } from "@mui/icons-material";
import "../../../styles/components/Login/Login.scss";
import { InputAdornment, IconButton } from "@mui/material";
import { urls } from "../../../services/urlConstant";
import { get, post } from "../../../services/apis";
import { useNavigate } from "react-router-dom";
import SettingContext from "../../../context/SettingsContext";
import { FaEye } from "react-icons/fa";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";

const style = {
  maxWidth: 350,
  height: "auto",
  p: 2,
  borderRadius: "20px",
  boxSizing: " borderBox",
  placeContent: "center",
  marginTop: "30px",
};

const paperStyle = { padding: 28, width: 320 };
const avatarStyle = { backgroundColor: "#1bbd7e" };
const btnstyle = { margin: "8px 0" };
const styles = {
  TextField: {
    label: {
      fontSize: 12,
    },
  },
};
const Login = (props) => {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [logo, setLogo] = useState(null);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    const getSettings = async () => {
      try {
        const settings = await get(urls.settings.getAllSettings);
        const res = settings.data.data[0];
        console.log(res, "popo");
        setLogo(res.logo?.link);
      } catch (error) {
        console.log(error);
      }
    };

    getSettings();
  }, []);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!name || !password)
        return alert("Please enter Username and Password");
      const data = {
        username: name,
        password: password,
        rememberMe: rememberMe,
      };
      console.log(data);
      const res = await post(`/login`, data);
      console.log(res);
      if (res.data.success) {
        window.localStorage.setItem("token", res.data.data.token);
        window.localStorage.setItem(
          "role",
          JSON.stringify(res.data.data.employee.role)
        );

        window.localStorage.setItem(
          "empName",
          res.data.data.employee.basicInfo.empName
        );

        window.localStorage.setItem("profileId", res.data.data.employee._id);
        const photo = res.data.data.employee.empPhoto
          ? res.data.data.employee.empPhoto.link
          : "";
        window.localStorage.setItem("empPhoto", photo);
        window.location.href = "/dashboard";
      }
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  return (
    <div className="imageStyles">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        {logo && (
          <img
            src={logo}
            height={"200px"}
            width={"200px"}
            style={{
              alignSelf: "center",
              resize: "contain",
              marginTop: "50px",
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Box sx={{ ...style }}>
          <Box>
            <form onSubmit={handleSubmit}>
              <Grid container rowSpacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    sx={{
                      boxShadow: (theme) => theme.shadows[5],
                      borderRadius: "20px",
                      overflow: "hidden",
                    }}
                    className="forms">
                    <TextField
                      size="small"
                      placeholder="Username"
                      fullWidth
                      onChange={(e) => setName(e.target.value)}
                      sx={{
                        ".css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root": {
                          color: "black",
                          borderRadius: "20px",
                        },
                      }}
                      className="box"
                      InputProps={{
                        sx: {
                          overflow: "hidden",
                          borderRadius: "20px",
                          backgroundColor: "white",
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton disabled edge="start">
                              <Person sx={{ color: "#ef6c00" }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    sx={{
                      boxShadow: (theme) => theme.shadows[5],
                      borderRadius: "20px",
                      overflow: "hidden",
                    }}
                    className="forms">
                    <TextField
                      size="small"
                      placeholder="Password"
                      fullWidth
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => setPassword(e.target.value)}
                      sx={{
                        ".css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root": {
                          color: "black",
                          borderRadius: "20px",
                        },
                      }}
                      className="box"
                      InputProps={{
                        sx: {
                          overflow: "hidden",
                          borderRadius: "20px",
                          backgroundColor: "white",
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton edge="start" disabled>
                              <Key sx={{ color: "#1976d2" }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={handleClickShowPassword}>
                              {showPassword ? (
                                <VisibilityOffRoundedIcon
                                  sx={{ color: "#1976d2" }}
                                />
                              ) : (
                                <RemoveRedEyeRoundedIcon
                                  sx={{ color: "#1976d2" }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  display="flex"
                  justifyContent="center"
                  sx={{
                    color: "#ffff",
                    fontSize: "20px",
                    fontWeight: "bold",
                    gap: 3,
                  }}>
                  <label>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        setRememberMe(e.target.checked);
                      }}
                    />
                    <span className="rememberStyle" style={{ color: "black" }}>
                      Remember me
                    </span>
                  </label>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  display="flex"
                  justifyContent="center">
                  <Button type="submit" variant="contained">
                    Log In
                  </Button>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  display="flex"
                  justifyContent="center"
                  item>
                  {" "}
                  <Typography>
                    <Link href="/forgot-password" variant="body2">
                      Forgot password ?
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Box>
      {/* <div className="divStyle">
        <form onSubmit={handleSubmit}>
          <Paper elevation={10} style={paperStyle}>
            <div align="center">
              <Avatar style={avatarStyle}>
                <LockOutlined />
              </Avatar>
              {error && <div className="errorStyle">{error}!!</div>}
            </div>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <Person sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
                id="input-with-sx"
                label="Username"
                value={name}
                onChange={(e) => setName(e.target.value)}
                variant="standard"
                style={styles.TextField.label}
                fullWidth
                required
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <Key sx={{ color: "action.active", mr: 1, my: 0.5 }} />

              <TextField
                label="password"
                variant="standard"
                fullWidth
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                id="standard-adornment-password"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <br />

            <label>
              <input
                type="checkbox"
                checked={rememberMe}
                onChange={(e) => {
                  setRememberMe(e.target.checked);
                }}
              />
              <span className="rememberStyle">Remember me</span>
            </label>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={btnstyle}
              fullWidth
            >
              Log In
            </Button>

            <Typography>
              <Link href="/forgot-password" variant="body2">
                Forgot password ?
              </Link>
            </Typography>
          </Paper>
        </form>
      </div> */}
    </div>
  );
};
export default Login;
