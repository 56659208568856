import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { blue, red } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import css from "../styles/Dashboard/Dashboard.module.css";
import { useState, useEffect } from "react";
import { urls } from "../services/urlConstant";
import { get } from "../services/apis";
import dayjs from "dayjs";
// import { Scrollbars } from "react-custom-scrollbars";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function StudentNews() {
  const [expanded, setExpanded] = React.useState(false);
  const [expanded1, setExpanded1] = React.useState(false);
  const [announceNotice, setAnounceNotice] = useState([]);

  useEffect(() => {
    const getNotice = async () => {
      try {
        const getNews = await get(`${urls.announceNews.getNews}/home`, {
          params: {
            search: {
              webView: "yes",
            },
          },
        });
        setAnounceNotice([...getNews.data.data]);
        console.log([...getNews.data.data], "news");
      } catch (error) {
        console.log(error);
      }
    };
    getNotice();
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleExpandClick1 = () => {
    setExpanded1(!expanded1);
  };

  return (
    <div className={css.scrollableCard}>
      <div>
        <Card sx={{ maxWidth: 345, mt: 3 }} className={css.cardScroll}>
          {announceNotice.map((news) => (
            <>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                    S
                  </Avatar>
                }
                action={
                  <CardActions disableSpacing>
                    <ExpandMore
                      expand={expanded}
                      onClick={handleExpandClick}
                      aria-expanded={expanded}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </CardActions>
                }
                title={news.title}
              />

              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                  <CardMedia
                    component="img"
                    height="194"
                    image={news.image.link}
                    alt="no image"
                  />
                  <Typography
                    paragraph
                    sx={{ textAlign: "left", fontSize: 14 }}
                  >
                    {news.news}
                  </Typography>
                  <Typography style={{ display: "flex", textAlign: "left" }}>
                    <b>{dayjs(news.date).format("DD/MM/YYYY")}</b>
                  </Typography>
                </CardContent>
              </Collapse>
            </>
          ))}
        </Card>
      </div>
    </div>
  );
}
