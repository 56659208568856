import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import { Search } from "@material-ui/icons";
import { TableBody } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import { get, post, put, del } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import DeleteModal from "../../Layout/Academic/DeleteModal";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 500,
  bgcolor: "background.paper",
  p: 4,
  overflowY: "scroll",
};
const styles = {
  button: {
    backgroundColor: "orange",
  },
  card: {
    padding: 10,
    margin: "10px 0px",
  },
  cardButton: {
    display: "flex",
    justifyContent: "flex-end",
    textAlign: "center",
    gap: "10px",
  },

  textfield: {
    marginTop: "25px",
    width: "70%",
  },
  addbutton: {
    paddingTop: "6.2px",
    paddingBottom: "6.2px",
  },
  tab: {
    width: "100%",
    borderCollapse: "collapse",
  },
};
const SalaryGrade = () => {
  const [search, setSearch] = useState("");
  const [open, setOpen] = React.useState(false);
  const [editSalaryGrade, setEditSalaryGrade] = useState();
  const [list, setList] = React.useState([]);
  const [searchFilter, setSearchFilter] = useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [removeField, setRemoveField] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [addForm, setAddForm] = useState({
    grade: "",
    gradeId: "",
    from: 0,
    to: 0,
    breakups: [{ id: 1, name: "", percentage: "" }],
    deduction: [{ id: 1, name: "", percentage: "" }],
  });

  const handleClick = () => {
    setAddForm({
      ...addForm,
      breakups: [
        ...addForm.breakups,
        {
          id: addForm.breakups.length + 1,
          name: "",
          percentage: "",
        },
      ],
    });
  };

  const handleClickDeduction = () => {
    setAddForm({
      ...addForm,
      deduction: [...addForm.deduction, { name: "", percentage: "" }],
    });
  };

  const handleChange = (e, i) => {
    const { name, value } = e.target;
    const onchangeVal = [...addForm];
    onchangeVal[i][name] = value;
    setAddForm(onchangeVal);
  };

  const handleChangeDeduction = (e, i) => {
    const { name, value } = e.target;
    const onchangeVal = [...addForm];
    onchangeVal[i][name] = value;
    setAddForm(onchangeVal);
  };

  const handleDelete = (i) => {
    const deleteVal = [...addForm];
    deleteVal.splice(i, 1);
    setAddForm(deleteVal);
  };
  const handleDeleteDeduction = (i) => {
    const deleteVal = [...addForm];
    deleteVal.splice(i, 1);
    setAddForm(deleteVal);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setAddForm({
      grade: "",
      gradeId: "",
      from: 0,
      to: 0,
      breakups: [{ name: "", percentage: "" }],
      deduction: [{ name: "", percentage: "" }],
    });
    setOpen(false);
  };

  useEffect(() => {
    getSalaryGrade();
  }, []);

  const getSalaryGrade = async () => {
    try {
      const { data, status } = await get(`${urls.salaryGrade.getList}`);

      setList(data.result.data);
      console.log(data, "sssss");
    } catch (error) {
      console.log(error);
    }
  };

  const handleBreakupInputChange = (e, id) => {
    setAddForm({
      ...addForm,
      breakups: [
        ...addForm.breakups.map((d) =>
          d.id === id ? { ...d, [e.target.name]: e.target.value } : d
        ),
      ],
    });
  };

  const handleDeductionInputChange = (e, id) => {
    setAddForm({
      ...addForm,
      deduction: [
        ...addForm.deduction.map((d) =>
          d.id === id ? { ...d, [e.target.name]: e.target.value } : d
        ),
      ],
    });
  };

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!editSalaryGrade) {
        const { status } = await post(`${urls.salaryGrade.create}`, {
          grade: addForm.grade,
          gradeId: addForm.gradeId,
          from: addForm.from,
          to: addForm.to,
          breakups: addForm.breakups,
          deduction: addForm.deduction,
          status: addForm.status,
        });
        getSalaryGrade();
        handleClose();
      } else {
        const { status } = await put(
          `${urls.salaryGrade.update}${editSalaryGrade}`,
          "",
          {
            grade: addForm.grade,
            gradeId: addForm.gradeId,
            from: addForm.from,
            to: addForm.to,
            breakups: addForm.breakups,
            deduction: addForm.deduction,
            status: addForm.status,
          }
        );
        getSalaryGrade();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGradeEdit = (id, data) => {
    setEditSalaryGrade(id);
    setAddForm({
      grade: data.grade,
      gradeId: data.gradeId,
      from: data.from,
      to: data.to,
      breakups: data.breakups,
      deduction: data.deduction,
      status: data.status,
    });
    handleClickOpen();
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(`${urls.salaryGrade.delete}`, id);
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      list.length > 0 &&
        setSearchFilter(
          list.filter((ele) =>
            ele.grade.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  const handleRemoveConBreakups = (indexToRemove) => {
    setAddForm((prevAddForm) => ({
      ...prevAddForm,
      breakups: prevAddForm.breakups.filter((val, i) => i !== indexToRemove),
    }));
  };

  const handleRemoveConDeduction = (indexToRemove) => {
    setAddForm((prevAddForm) => ({
      ...prevAddForm,
      deduction: prevAddForm.deduction.filter((val, i) => i !== indexToRemove),
    }));
  };

  const handleGetDownloadSheet = async (e) => {
    e.preventDefault();
    setLoadingBtn(true);
    try {
      const getDownload = await get(`/salaryGrade/excel/download`, {
        responseType: "blob",
      });
      const uri = URL.createObjectURL(getDownload.data);
      const link = document.createElement("a");
      console.log(uri);
      link.href = uri;
      document.body.appendChild(link);
      link.setAttribute("download", `salaryGrade.xlsx`);
      link.click();
      link.parentNode.removeChild(link);
      setLoadingBtn(false);
    } catch (error) {
      setLoadingBtn(false);
      console.log(error);
    }
  };
  const handleGetPrintPdf = async (e) => {
    e.preventDefault();
    console.log("kjkjkjk");
    try {
      const getPrint = await get(`/salaryGrade/pdf/download`, {
        responseType: "blob",
      });
      const uri = URL.createObjectURL(getPrint.data);
      window.open(uri, "__blank");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div style={{ margin: "15px" }}>
        <Card sx={{ padding: "10px" }}>
          <span
            style={{
              color: "red",
              fontSize: "large",
              fontFamily: "cursive",
            }}
          >
            Salary Grade:
          </span>
        </Card>

        <Paper
          sx={{ width: "100%", height: "60px", overflow: "hidden", mt: 2 }}
        >
          <div style={{ paddingTop: "23px" }}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid xs="auto" style={{ marginRight: "10px" }}>
                <div style={styles.cardButton}>
                  <LoadingButton
                    onClick={handleGetDownloadSheet}
                    variant="contained"
                    loading={loadingBtn}
                  >
                    <span style={styles.icon}>Excel </span>
                  </LoadingButton>
                  <Button variant="contained" onClick={handleGetPrintPdf}>
                    Pdf
                  </Button>
                </div>
              </Grid>

              <Grid xs="auto">
                <TextField
                  size="small"
                  label="Search"
                  value={search}
                  onChange={handleSearch}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" type="submit">
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </Paper>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form className="form-tag" onSubmit={handleAddSubmit}>
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign="center"
                marginBottom="30px"
              >
                Add Info
              </Typography>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormControl fullWidth required>
                      <TextField
                        required
                        label="Grade"
                        name="grade"
                        value={addForm.grade || ""}
                        onChange={handleAddForm}
                        size="small"
                        sx={{ m: 1, minWidth: 227 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormControl fullWidth required>
                      <TextField
                        required
                        label="From"
                        value={addForm.from || ""}
                        onChange={handleAddForm}
                        size="small"
                        name="from"
                        sx={{ m: 1, minWidth: 227 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormControl fullWidth required>
                      <TextField
                        required
                        size="small"
                        label="To"
                        name="to"
                        value={addForm.to || ""}
                        onChange={handleAddForm}
                        sx={{ m: 1, minWidth: 227 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormControl fullWidth required>
                      <InputLabel>Status</InputLabel>
                      <Select
                        labelId="demo-simpless-select-filled-label"
                        id="demo-simple-select-filled-l"
                        label="Status"
                        size="small"
                        name="status"
                        sx={{ m: 1, minWidth: 227 }}
                        value={addForm.status || ""}
                        onChange={handleAddForm}
                      >
                        <MenuItem value={"active"}>Active</MenuItem>
                        <MenuItem value={"inactive"}>InActive</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <div>
                  <p>Breakups:</p>
                  {addForm.breakups.map((val, i) => (
                    <div>
                      <Card sx={{ mb: 2 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6} lg={5}>
                            <FormControl fullWidth>
                              <TextField
                                required
                                value={val.name}
                                label="Name"
                                name="name"
                                size="small"
                                onChange={(e) =>
                                  handleBreakupInputChange(e, val.id)
                                }
                                sx={{ m: 1, ml: 4, minWidth: 227 }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} lg={5}>
                            <FormControl fullWidth>
                              <TextField
                                required
                                value={val.percentage}
                                label="Percentage"
                                name="percentage"
                                size="small"
                                onChange={(e) =>
                                  handleBreakupInputChange(e, val.id)
                                }
                                sx={{ m: 1, ml: 4, minWidth: 227 }}
                              />
                            </FormControl>
                          </Grid>

                          <Button
                            sx={{ ml: 5, mt: 2 }}
                            style={{ color: "#1b3779" }}
                            onClick={() => handleRemoveConBreakups(i)}
                          >
                            <ClearIcon />
                          </Button>
                        </Grid>
                      </Card>
                    </div>
                  ))}
                  <Button
                    onClick={handleClick}
                    style={{
                      color: "#ffff",
                      backgroundColor: "orange",
                      marginLeft: "-4px",
                      paddingBottom: "2px",
                    }}
                  >
                    Add New
                  </Button>
                </div>
                <div>
                  <p>Deduction:</p>

                  {addForm.deduction.map((val, i) => (
                    <div>
                      <Card sx={{ mb: 2 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6} lg={5}>
                            <FormControl fullWidth>
                              <TextField
                                required
                                label="Name"
                                value={val.name}
                                name="name"
                                size="small"
                                onChange={(e) =>
                                  handleDeductionInputChange(e, val.id)
                                }
                                sx={{ m: 1, ml: 4, minWidth: 227 }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} lg={5}>
                            <FormControl fullWidth>
                              <TextField
                                required
                                value={val.percentage}
                                label="Percentage"
                                name="percentage"
                                size="small"
                                onChange={(e) =>
                                  handleDeductionInputChange(e, val.id)
                                }
                                sx={{ m: 1, ml: 4, minWidth: 227 }}
                              />
                            </FormControl>
                          </Grid>

                          <Button
                            sx={{ ml: 5, mt: 2 }}
                            style={{ color: "#1b3779" }}
                            onClick={() => handleRemoveConDeduction(i)}
                          >
                            <ClearIcon />
                          </Button>
                        </Grid>
                      </Card>
                    </div>
                  ))}
                </div>

                <Button
                  onClick={handleClickDeduction}
                  style={{
                    color: "#ffff",
                    backgroundColor: "orange",
                    marginLeft: "-4px",
                  }}
                >
                  Add New
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Tooltip title="Cancel">
                  <Button
                    onClick={handleClose}
                    variant="outlined"
                    size="medium"
                    type="button"
                    sx={{ m: 1 }}
                  >
                    Cancel
                  </Button>
                </Tooltip>
                <Tooltip title="Submit">
                  <Button
                    variant="contained"
                    size="medium"
                    type="submit"
                    sx={{
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                    }}
                  >
                    Add
                  </Button>
                </Tooltip>
              </div>
            </Box>
          </form>
        </Modal>
        <Table sx={{ mt: 3 }}>
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow sx={{ color: "white" }}>
              <TableCell sx={{ color: "white" }} align="center">
                #SL
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Grade Id
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Grade
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                From
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                To
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Status
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {search
              ? searchFilter.map((grade, index) => (
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{grade.gradeCode}</TableCell>
                    <TableCell align="center">{grade.grade}</TableCell>
                    <TableCell align="center">{grade.from}</TableCell>
                    <TableCell align="center">{grade.to}</TableCell>
                    <TableCell align="center">{grade.status}</TableCell>
                    <TableCell align="center">
                      <div className="edit-delete">
                        <Button
                          style={{ color: "#1b3779" }}
                          onClick={() => handleGradeEdit(grade._id, grade)}
                        >
                          <EditIcon />
                        </Button>

                        <Button
                          style={{
                            color: "#1b3779",
                          }}
                          onClick={() => setDeleteModal(grade._id, grade)}
                        >
                          <Delete />
                        </Button>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={grade._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              : list.map((grade, index) => (
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{grade.gradeCode}</TableCell>
                    <TableCell align="center">{grade.grade}</TableCell>
                    <TableCell align="center">{grade.from}</TableCell>
                    <TableCell align="center">{grade.to}</TableCell>
                    <TableCell align="center">{grade.status}</TableCell>
                    <TableCell align="center">
                      <div className="edit-delete">
                        <Button
                          style={{ color: "#1b3779" }}
                          onClick={() => handleGradeEdit(grade._id, grade)}
                        >
                          <EditIcon />
                        </Button>

                        <Button
                          style={{
                            color: "#1b3779",
                          }}
                          onClick={() => setDeleteModal(grade._id, grade)}
                        >
                          <Delete />
                        </Button>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={grade._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
        {!list.length && !search.trim() && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        {search.trim() && !searchFilter.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        <div className="add-icon">
          <Fab
            variant="contained"
            style={{ color: "#ffff", backgroundColor: "#1b3779" }}
            onClick={handleClickOpen}
          >
            <AddIcon />
          </Fab>
        </div>
      </div>
    </div>
  );
};

export default SalaryGrade;
