import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { get, put } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import { Checkbox } from "@mui/material";
import { forwardRef } from "react";
import MuiAlert from "@mui/material/Alert";
import { toast } from "react-toastify";

const styles = {
  card: {
    margin: "15px",
    padding: "10px",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
};
const label = { inputProps: { "aria-label": "Checkbox demo" } };
const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Reshuffle = () => {
  const [academicYears, setAcademicYears] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [reshuffleDetails, setReshuffleDetails] = useState({});
  const [studentReshuffle, setStudentReshuffle] = useState([]);
  const [open, setOpen] = useState(false);
  const [checkBox, setCheckBox] = useState([]);
  const [bulkModal, setBulkModal] = useState(false);

  // let checkBox = [];

  const [sort, setSort] = useState({
    name: "asc",
    rollNo: "asc",
  });

  const sortByName = () => {
    console.log("name");
    if (sort.name === "asc") {
      let sortedList = [
        ...studentReshuffle.sort((a, b) =>
          b.basicInfo.name.localeCompare(a.basicInfo.name)
        ),
      ];

      setStudentReshuffle(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      let sortedList = [
        ...studentReshuffle.sort((a, b) =>
          a.basicInfo.name.localeCompare(b.basicInfo.name)
        ),
      ];
      setStudentReshuffle(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };

  const sortByRollNo = () => {
    console.log("RollNo");
    if (sort.rollNo === "asc") {
      let sortedList = [
        ...studentReshuffle.sort(
          (a, b) => b.academicInfo.rollNo - a.academicInfo.rollNo
        ),
      ];

      setStudentReshuffle(sortedList);
      setSort({ ...sort, rollNo: "des" });
    } else {
      let sortedList = [
        ...studentReshuffle.sort(
          (a, b) => a.academicInfo.rollNo - b.academicInfo.rollNo
        ),
      ];
      setStudentReshuffle(sortedList);
      setSort({ ...sort, rollNo: "asc" });
    }
  };

  const handleCloseBulkModal = () => {
    setBulkModal(false);
  };
  const handleOpenBulkModal = () => {
    setBulkModal(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleClasses = async (e) => {
    try {
      const { name, value } = e.target;
      setReshuffleDetails({ ...reshuffleDetails, [name]: value });
      setStudentReshuffle([]);
      const res = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: value,
          },
        },
      });
      setSections(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const res = await get(urls.students.getAllStudent, {
        params: {
          search: {
            "academicInfo.class": reshuffleDetails.className,
            "academicInfo.section": reshuffleDetails.sectionName,
            academicYear: reshuffleDetails.academicYear,
          },
        },
      });
      setStudentReshuffle(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleReshuffle = async () => {
    try {
      setOpen(true);
      const res = await put(urls.students.putStudentResuffle, "", {
        section: reshuffleDetails.changeTo,
        class: reshuffleDetails.className,
        currentSection: reshuffleDetails.sectionName,
        students: checkBox,
      });
      const reshuffleDetailsAdded = { ...reshuffleDetails };
      for (const key in reshuffleDetailsAdded) {
        reshuffleDetailsAdded[key] = "";
      }
      setReshuffleDetails({ ...reshuffleDetailsAdded });

      if (res.data.success) {
        setStudentReshuffle([]);
      } else {
        setOpen(false);
      }
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSections = (e) => {
    const { name, value } = e.target;
    setReshuffleDetails({ ...reshuffleDetails, [name]: value });
    setStudentReshuffle([]);
  };
  const handleReshffleCheckBox = (_id, e) => {
    if (e.target.checked) {
      setCheckBox((prev) => [...prev, _id]);
    } else {
      setCheckBox(checkBox.filter((item) => item !== _id));
    }
  };
  useEffect(() => {
    const getAcademicYear = async () => {
      const res = await get(urls.administrator.academicYearGetAll);
      setAcademicYears(res.data.data);
    };
    getAcademicYear();
  }, []);

  useEffect(() => {
    const getClasses = async () => {
      const res = await get(urls.class.getAllClass);
      setClasses(res.data.data);
    };
    getClasses();
  }, []);
  const handleMultipleChecks = (e) => {
    if (e.target.checked) {
      const ids = studentReshuffle.map((item) => item._id);
      setCheckBox([...ids]);
    } else {
      setCheckBox([]);
    }
  };
  const handleGetSample = async () => {
    try {
      if (!reshuffleDetails.className || !reshuffleDetails.academicYear)
        return toast.error("Please select class and academic year");
      const reshuffleList = await get(
        `/student-resuffle-list-excel/${reshuffleDetails.className}/${reshuffleDetails.academicYear}`,
        {
          responseType: "blob",
        }
      );
      if (reshuffleList.status === 200) {
        const uri = URL.createObjectURL(reshuffleList.data);
        const link = document.createElement("a");
        link.href = uri;
        link.setAttribute("download", "reshuffle-list.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleBulkSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!reshuffleDetails.className || !reshuffleDetails.academicYear)
        return toast.error("Please select class and academic year");
      if (!e.target.sheetFile.files.length)
        return toast.error("Please choose file");
      const formData = new FormData();
      formData.append("sheet", e.target.sheetFile.files[0]);
      await put(
        `/student-resuffle/${reshuffleDetails.className}/${reshuffleDetails.academicYear}`,
        "",
        formData
      );
      e.target.sheetFile.value = null;
      handleCloseBulkModal();
    } catch (error) {}
  };
  return (
    <>
      <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span
              style={{
                color: "red",
                fontFamily: "cursive",
              }}
            >
              Reshuffle :
            </span>
          </Grid>
        </Grid>
      </Card>
      <div>
        <form onSubmit={handleSubmit}>
          <Card sx={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={2}>
                  <FormControl variant="standard" style={styles.textfield}>
                    <InputLabel>Academic Year</InputLabel>
                    <Select
                      name="academicYear"
                      value={reshuffleDetails.academicYear}
                      onChange={(event) => handleClasses(event)}
                    >
                      {academicYears &&
                        academicYears.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.academicYearFrom} - {row.academicYearTo}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl variant="standard" style={styles.textfield}>
                    <InputLabel>Class</InputLabel>
                    <Select
                      name="className"
                      value={reshuffleDetails.clsasName}
                      onChange={(event) => handleClasses(event)}
                    >
                      {classes &&
                        classes.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.className}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl variant="standard" style={styles.textfield}>
                    <InputLabel>Current Section</InputLabel>
                    <Select
                      name="sectionName"
                      value={reshuffleDetails.sectionName}
                      onChange={(event) => handleSections(event)}
                    >
                      {/* {sections &&
                        sections.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.sectionName}
                          </MenuItem>
                        ))} */}
                      {sections &&
                        sections
                          .slice()
                          .sort((a, b) =>
                            a.sectionName.localeCompare(b.sectionName)
                          ) // Sort the sections array alphabetically
                          .map((row, index) => (
                            <MenuItem
                              key={row._id}
                              value={row._id}
                              sx={{ fontSize: 12, fontWeight: 500 }}
                            >
                              {row.sectionName}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl variant="standard" style={styles.textfield}>
                    <InputLabel>Change To</InputLabel>
                    <Select
                      name="changeTo"
                      value={reshuffleDetails.changeTo}
                      onChange={(event) => handleSections(event)}
                    >
                      {/* {sections &&
                        sections.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.sectionName}
                          </MenuItem>
                        ))} */}
                      {sections &&
                        sections
                          .slice()
                          .sort((a, b) =>
                            a.sectionName.localeCompare(b.sectionName)
                          ) // Sort the sections array alphabetically
                          .map((row, index) => (
                            <MenuItem
                              key={row._id}
                              value={row._id}
                              sx={{ fontSize: 12, fontWeight: 500 }}
                            >
                              {row.sectionName}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={1}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignSelf: "end",
                  }}
                >
                  <Tooltip title="Reshuffle">
                    <Button
                      varient="contained"
                      size="small"
                      type="submit"
                      sx={styles.Button}
                    >
                      Find
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Button
                    onClick={handleOpenBulkModal}
                    varient="contained"
                    size="small"
                    sx={styles.Button}
                  >
                    Bulk Reshuffle
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </form>

        <div style={{ margin: "15px" }}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead style={{ background: "rgb(27 55 121)" }}>
                  <TableRow>
                    <TableCell align="left">
                      <span className="class-table-header">#SL</span>
                    </TableCell>
                    <TableCell align="left">
                      <span className="class-table-header">Name</span>
                      <span
                        onClick={sortByName}
                        style={{ cursor: "pointer", color: "#fff" }}
                      >
                        {sort.name === "asc" ? "▲" : "▼"}
                      </span>
                    </TableCell>
                    <TableCell align="left">
                      <span className="class-table-header">Roll No</span>
                      <span
                        onClick={sortByRollNo}
                        style={{ cursor: "pointer", color: "#fff" }}
                      >
                        {sort.rollNo === "asc" ? "▲" : "▼"}
                      </span>
                    </TableCell>
                    <TableCell align="left">
                      <span className="class-table-header">Father Name</span>
                    </TableCell>
                    <TableCell align="left">
                      <Checkbox
                        onChange={handleMultipleChecks}
                        {...label}
                        sx={{
                          padding: 0,
                          color: "#fff",
                          "&.Mui-checked": {
                            color: "#fff",
                          },
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {studentReshuffle.map((row, index) => (
                    <TableRow
                      key={row._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">{index + 1}</TableCell>
                      <TableCell
                        align="left"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.basicInfo.name}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.academicInfo.rollNo}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.fatherInfo.fatherName}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ textTransform: "capitalize" }}
                      >
                        <Checkbox
                          checked={checkBox.includes(row._id)}
                          onChange={(e) => handleReshffleCheckBox(row._id, e)}
                          {...label}
                          sx={{
                            color: "#1b3779",
                            "&.Mui-checked": {
                              color: "#1b3779",
                            },
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {!studentReshuffle.length && (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
              >
                No data found
              </Typography>
            )}
          </Paper>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Tooltip title="Submit">
            <Button
              varient="contained"
              size="small"
              type="submit"
              onClick={handleReshuffle}
              sx={styles.Button}
            >
              Reshuffle
            </Button>
          </Tooltip>
        </div>
      </div>
      <Dialog open={bulkModal} onClose={handleCloseBulkModal}>
        <DialogTitle>Bulk Reshuffle</DialogTitle>
        <DialogContent>
          <form
            onSubmit={handleBulkSubmit}
            style={{ display: "flex", justifyContent: "center", gap: "1rem" }}
          >
            <Button onClick={handleGetSample} variant="contained">
              Get Sample
            </Button>
            <input type="file" name="sheetFile" />
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Reshuffle;
