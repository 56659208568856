import React, { useState, useEffect } from "react";
import ModalVideo from "react-modal-video";
import ExcellentSchool from "../../assets/Images/ShreeShivanand.webp";
import "../../styles/components/Chairman/Chairman.scss";
const Chairman = () => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    console.log("ll");
  }, []);
  return (
    <div
      className="why__register__wrapper padding__bottom"
      style={{ marginBottom: "60px" }}
    >
      <div className="container-fluid p-0">
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              borderRight: "5px solid white",
            }}
            className="col-lg-6"
          >
            <div className="why__img">
              <img src={ExcellentSchool} alt="" />
              <ModalVideo
                channel="youtube"
                youtube={{
                  autoplay: 0,
                }}
                isOpen={isOpen}
                videoId="HAsZeTmEzqI"
                onClose={() => setOpen(false)}
              />
              <button className="btn video__btn" onClick={() => setOpen(true)}>
                {/* <FontAwesomeIcon icon={["fa", "play"]} />
                <FontAwesomeIcon icon="fa-solid fa-play" /> */}
                <i class="fa-solid fa-play"></i>
              </button>
            </div>
          </div>
          <div className="col-lg-6 why__right__back">
            <div className="why__right">
              <div className="section__title__container">
                <h2 className="section__title">Dr. Shivanand Kelur</h2>
                <p className="section__contnet">
                  Excellent school was founded by Dr. Shivanand Kelur, to
                  quickly become one of the top educational institutions in
                  Karnataka, and soon one of the most recognized in India. The
                  idea behind the conception of victorious Excellent School was
                  to find a solution to all of the educational problems of our
                  time. He was looking for an ideal concept of education
                  approved by the people of the world. He wanted to bring
                  Vedanta back to moder
                  <div className="chairman-title">
                    Founder / Chairman <br />
                    Excellent School Vijapur
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Chairman;

// import React, { useState, useEffect } from "react";

// import ModalVideo from "react-modal-video";
// import ExcellentSchool from "../../assets/Images/ShreeShivanand.webp";
// import "../../styles/components/Chairman/Chairman.scss";
// const Chairman = () => {
//   const [isOpen, setOpen] = useState(false);

//   return (
//     <div
//       className="why__register__wrapper padding__bottom"
//       style={{ marginBottom: "60px" }}
//     >
//       <div className="container-fluid p-0">
//         <div className="row no-gutters">
//           <div className="col-lg-6">
//             <div className="why__img">
//               <img src={ExcellentSchool} alt="" />
//               <ModalVideo
//                 channel="youtube"
//                 youtube={{
//                   autoplay: 0,
//                 }}
//                 isOpen={isOpen}
//                 videoId="HAsZeTmEzqI"
//                 onClose={() => setOpen(false)}
//               />
//               <button className="btn video__btn" onClick={() => setOpen(true)}>
//                 {/* <FontAwesomeIcon icon={["fa", "play"]} />
//                 <FontAwesomeIcon icon="fa-solid fa-play" /> */}
//                 <i class="fa-solid fa-play"></i>
//               </button>
//             </div>
//           </div>
//           <div className="col-lg-6 why__right__back">
//             <div className="why__right">
//               <div className="section__title__container">
//                 <h2 className="section__title">Shree Shivanand Kelur</h2>
//                 <p className="section__contnet">
//                   Excellent school was founded by Shree Shivanand Kelur, to
//                   quickly become one of the top educational institutions in
//                   Karnataka, and soon one of the most recognized in India. The
//                   idea behind the conception of victorious Excellent School was
//                   to find a solution to all of the educational problems of our
//                   time. He was looking for an ideal concept of education
//                   approved by the people of the world. He wanted to bring
//                   Vedanta back to modern times.
//                   <div className="chairman-title">
//                     Founder / Chairman <br />
//                     Excellent School Vijapur
//                   </div>
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default Chairman;

// import { Grid } from "@mui/material";
// import React, { useState } from "react";
// import ModalVideo from "react-modal-video";
// const styles = {
//   paperContainer: {
//     backgroundImage: `url(${"ShriShivanda.webp"})`,
//     backgroundRepeat: "no-repeat",
//     backgroundSize: "cover",
//     height: "400px",
//   },
// };

// const Chairman = () => {
//   const [isOpen, setOpen] = useState(false);
//   return (
//     <div>
//       <Grid container spacing={2} sx={{ height: 500, alignItems: "center" }}>
//         <Grid item xs={12} md={6} lg={6} style={styles.paperContainer}>
//           <ModalVideo
//             channel="youtube"
//             youtube={{
//               autoplay: 0,
//             }}
//             isOpen={isOpen}
//             videoId="HAsZeTmEzqI"
//             onClose={() => setOpen(false)}
//           />
//           <button className="btn video__btn" onClick={() => setOpen(true)}>
//             {/* <FontAwesomeIcon icon={["fa", "play"]} />
//       <FontAwesomeIcon icon="fa-solid fa-play" /> */}
//             <i class="fa-solid fa-play"></i>
//           </button>
//         </Grid>
//       </Grid>
//     </div>
//   );
// };

// export default Chairman;
