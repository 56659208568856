import React, { useEffect, useState } from "react";
import ImageSlider from "./ImageSlider";
import List from "./List";
import birthday from "../../Data/birthday.json";
import { get } from "../../services/apis";

const Birthday = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const getBirthdayImage = async () => {
      try {
        const birthdayImages = await get(`/birthday`);
        if (birthdayImages.data.success) {
          setImages(birthdayImages.data.data);
        }
      } catch (error) {}
    };
    getBirthdayImage();
  }, []);

  const slides = birthday.birthday;
  const containerStyles = {
    width: "600px",
    height: "400px",
  };
  return (
    <div>
      <img className="dot-img" src="dots2.webp" alt="" />
      <div
        style={{
          backgroundImage: `url('bday-banner0.webp')`,
          backgroundRepeat: "no-repeat",
          height: "565px",
        }}
      >
        <div>
          <div className="birthday-title">
            <h1>{birthday.mainTitle.birthdayTitle}</h1>
          </div>
          <div className="slider-container">
            <div className="slider-item">
              <div className="images-container"></div>
              <div style={containerStyles} className="slider-item1">
                <ImageSlider slides={images} />
              </div>
            </div>
            <div className="slider-item2">
              <List />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Birthday;
