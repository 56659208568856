import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Delete } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { get, post, put, del } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import DeleteModal from "../../Layout/Academic/DeleteModal";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 550,
  bgcolor: "background.paper",
  p: 4,
};
const styles = {
  cardsMargin: {
    margin: "15px",
  },
  cardStyle: {
    margin: "15px",
    padding: "10px",
  },
  icon: {
    padding: "10px",
    fontSize: "12px",
    fontWeight: 500,
  },
  card: {
    padding: 15,
    marginBottom: 20,
    width: "100%",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
    marginLeft: "5px",
    marginTop: "5px",
  },
  addbutton: {
    paddingTop: "6.2px",
    paddingBottom: "6.2px",
    marginBottom: "4px",
  },
};
const Firm = () => {
  const [open, setOpen] = React.useState(false);
  const [editFirm, setEditFirm] = useState();
  const [addForm, setAddForm] = useState({});
  const [list, setList] = React.useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);
  useEffect(() => {
    getFirm();
  }, []);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setEditFirm(null);
    setAddForm({});
    setOpen(false);
  };
  const getFirm = async () => {
    console.log("lklklklklklklklk");
    try {
      const { data, status } = await get(urls.firm.getList);
      setList(data.data);
      console.log(data.data, "getFirmmmmmmmkkkk");
    } catch (error) {
      console.log(error);
    }
  };
  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleAddSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!editFirm) {
        const { status } = await post(`${urls.firm.create}`, {
          firmName: addForm.firmName,
          phoneNumber: addForm.phoneNumber,
          email: addForm.email,
          GSTNumber: addForm.GSTNumber,
          vendorWebsite: addForm.vendorWebsite,
          address: addForm.address,
          city: addForm.city,
          state: addForm.state,
          zipCode: addForm.zipCode,
        });
        getFirm();
        handleClose();
      } else {
        const { status } = await put(`${urls.firm.update}${editFirm}`, "", {
          firmName: addForm.firmName,
          phoneNumber: addForm.phoneNumber,
          email: addForm.email,
          GSTNumber: addForm.GSTNumber,
          vendorWebsite: addForm.vendorWebsite,
          address: addForm.address,
          city: addForm.city,
          state: addForm.state,
          zipCode: addForm.zipCode,
        });
        getFirm();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFirmEdit = (id, data) => {
    setEditFirm(id);
    setAddForm({
      firmName: data.firmName,
      nameSmall: data.nameSmall,
      phoneNumber: data.phoneNumber,
      email: data.email,
      GSTNumber: data.GSTNumber,
      vendorWebsite: data.vendorWebsite,
      address: data.address,
      city: data.city,
      state: data.state,
      zipCode: data.zipCode,
    });
    handleClickOpen();
  };
  const handleDeleteone = async (id) => {
    try {
      const res = await del(`${urls.firm.delFirm}`, id);
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
        getFirm();
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div style={styles.cardsMargin}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid>
            <Button
              variant="contained"
              style={styles.addbutton}
              onClick={handleClickOpen}
            >
              <AddIcon />
              Add
            </Button>
          </Grid>
        </Grid>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleAddSubmit}>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
              marginBottom="30px"
              onChange={handleAddForm}
            >
              {editFirm ? "Update" : "Add"}
            </Typography>
            <div>
              <TextField
                required
                id="outlined-basic"
                variant="outlined"
                sx={{ m: 1, minWidth: 227 }}
                label="Name"
                name="firmName"
                onChange={handleAddForm}
                value={addForm.firmName || ""}
              />
              <TextField
                required
                id="outlined-basic"
                variant="outlined"
                sx={{ m: 1, minWidth: 227 }}
                label="PhoneNumber"
                name="phoneNumber"
                onChange={handleAddForm}
                value={addForm.phoneNumber || ""}
              />
              <TextField
                required
                id="outlined-basic"
                variant="outlined"
                sx={{ m: 1, minWidth: 227 }}
                label="Email"
                name="email"
                onChange={handleAddForm}
                value={addForm.email || ""}
              />
            </div>
            <div>
              <TextField
                required
                id="outlined-basic"
                variant="outlined"
                sx={{ m: 1, minWidth: 227 }}
                label="GSTNumber"
                name="GSTNumber"
                onChange={handleAddForm}
                value={addForm.GSTNumber || ""}
              />
              <TextField
                required
                id="outlined-basic"
                variant="outlined"
                sx={{ m: 1, minWidth: 227 }}
                label="VendorWebsite"
                name="vendorWebsite"
                onChange={handleAddForm}
                value={addForm.vendorWebsite || ""}
              />
              <TextField
                required
                id="outlined-basic"
                variant="outlined"
                sx={{ m: 1, minWidth: 227 }}
                label="Address"
                name="address"
                onChange={handleAddForm}
                value={addForm.address || ""}
              />
            </div>
            <div>
              <TextField
                required
                id="outlined-basic"
                variant="outlined"
                sx={{ m: 1, minWidth: 227 }}
                label="city"
                name="city"
                onChange={handleAddForm}
                value={addForm.city || ""}
              />
              <TextField
                required
                id="outlined-basic"
                variant="outlined"
                sx={{ m: 1, minWidth: 227 }}
                label="state"
                name="state"
                onChange={handleAddForm}
                value={addForm.state || ""}
              />
              <TextField
                required
                id="outlined-basic"
                variant="outlined"
                sx={{ m: 1, minWidth: 227 }}
                label="zipCode"
                name="zipCode"
                onChange={handleAddForm}
                value={addForm.zipCode || ""}
              />
            </div>
            <div className="cancel-submit-btn">
              <div>
                <Tooltip title="Cancel">
                  <Button
                    onClick={handleClose}
                    variant="outlined"
                    size="medium"
                    type="button"
                    sx={{ m: 1 }}
                  >
                    Cancel
                  </Button>
                </Tooltip>
                <Tooltip title="Submit">
                  <Button
                    variant="contained"
                    size="medium"
                    type="submit"
                    sx={{
                      background: "rgb(27, 55, 121)",
                      ":hover": { background: "rgb(27, 55, 121)" },
                    }}
                  >
                    {editFirm ? "Update" : "Add"}
                  </Button>
                </Tooltip>
              </div>
            </div>
          </Box>
        </form>
      </Modal>
      <Table>
        <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
          <TableRow sx={{ color: "white" }}>
            <TableCell sx={{ color: "white" }} align="center">
              #Sl
            </TableCell>
            <TableCell sx={{ color: "white" }} align="center">
              Name
            </TableCell>
            <TableCell sx={{ color: "white" }} align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((firm, index) => (
            <TableRow>
              <TableCell align="center">{index + 1}</TableCell>
              <TableCell align="center">{firm.firmName}</TableCell>
              <TableCell align="center">
                <div className="edit-delete">
                  <Button
                    style={{ color: "#1b3779" }}
                    onClick={() => handleFirmEdit(firm._id, firm)}
                  >
                    <EditIcon />
                  </Button>

                  <Button
                    style={{
                      color: "#1b3779",
                    }}
                    onClick={() => setDeleteModal(firm._id, firm)}
                  >
                    <Delete />
                  </Button>
                  <DeleteModal
                    deleteModal={deleteModal}
                    handleDelete={handleDeleteone}
                    id={firm._id}
                    setDeleteModal={setDeleteModal}
                  />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!list.length && (
        <Typography
          variant="h6"
          sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
        >
          No data found
        </Typography>
      )}
    </div>
  );
};
export default Firm;
