import {
  Card,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const styles = {
  card: {
    margin: 15,
    padding: 10,
  },
};
const label = { inputProps: { "aria-label": "Checkbox demo" } };
const NewRolePermission = () => {
  return (
    <div>
      <Card style={styles.card}>
        <span style={{ color: "red", fontFamily: "cursive" }}>
          Role Permission :
        </span>
      </Card>
      <div style={{ margin: "15px" }}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Function Name</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">View</span>
                    <Checkbox {...label} sx={{ color: "#fff" }} />
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Add</span>
                    <Checkbox {...label} sx={{ color: "#fff" }} />
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Edit</span>
                    <Checkbox {...label} sx={{ color: "#fff" }} />
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Delete</span>
                    <Checkbox {...label} sx={{ color: "#fff" }} />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">1</TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Payment Setting
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    <Checkbox size="small" {...label} />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    <Checkbox size="small" {...label} />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    <Checkbox size="small" {...label} />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    <Checkbox size="small" {...label} />
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">2</TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    General Setting
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    <Checkbox size="small" {...label} />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    <Checkbox size="small" {...label} />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    <Checkbox size="small" {...label} />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    <Checkbox size="small" {...label} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
};

export default NewRolePermission;
