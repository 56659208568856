import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Fab, TextareaAutosize, TextField, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import { urls } from "../../../services/urlConstant";
import { get } from "../../../services/apis";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 250,
  bgcolor: "background.paper",
  p: 4,
};

const AddUserRole = (props) => {
  const [userRole, setUserRole] = useState([]);
  const [roleName, setRoleName] = useState("");
  const [userNote, setUserNote] = useState("");

  useEffect(() => {
    if (props.updateUserRole) {
      setRoleName(props.updateUserRole.roleName);
      setUserNote(props.updateUserRole.userNote);
    }
  }, [props.updateUserRole]);

  useEffect(() => {
    const role = async () => {
      try {
        const res = await get(urls.role.getAllRole);
        setUserRole(res.data.data);
      } catch (error) {}
    };
    role();
  }, []);

  const handleRoleName = (e) => {
    setRoleName(e.target.value);
  };
  const handleUserNote = (e) => {
    setUserNote(e.target.value);
  };

  const handleSubmit = (e) => {
    if (props.updateUserRole) {
      props.handleUpdate(
        {
          roleName,
          // userDefault,
          userNote,
        },
        props.updateUserRole._id
      );
    } else {
      props.handleSubmit(e, {
        roleName,
        // userDefault,
        userNote,
      });
    }
    setRoleName("");
    setUserNote("");
    props.handleClose();
  };

  const handleCloseModal = () => {
    setRoleName("");
    setUserNote("");
    props.handleClose();
  };

  const handleClose = () => {
    setRoleName("");
    setUserNote("");
    props.handleClose();
  };
  return (
    <div>
      <Tooltip title="Add" disableInteractive>
        <Fab
          size="medium"
          style={{ background: "#1b3779", color: "#fff" }}
          aria-label="add"
          onClick={props.handleOpen}
        >
          <Add />
        </Fab>
      </Tooltip>
      <Modal
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleSubmit}>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
              marginBottom="30px"
            >
              Add Role
            </Typography>
            <div>
              <TextField
                required
                id="filled-required"
                variant="standard"
                label="Role Name"
                value={roleName}
                onChange={handleRoleName}
                sx={{ m: 1, width: "98%", label: { fontSize: 12 } }}
              />
            </div>

            <div className="cancel-submit-btn">
              <div>
                <Tooltip title="Cancel">
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  size="medium"
                  type="button"
                  sx={{
                    m: 1,
                    color: "rgb(27, 55, 121)",
                    borderColor: "rgb(27, 55, 121)",
                  }}
                >
                  Cancel
                </Button>
                </Tooltip>
                <Tooltip title="Submit">
                <Button
                  disabled={!roleName}
                  variant="contained"
                  size="medium"
                  type="submit"
                  sx={{ background: "rgb(27, 55, 121)", ":hover":{background: "rgb(27, 55, 121)"} }}
                >
                  Submit
                </Button>
                </Tooltip>
              </div>
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
};

export default AddUserRole;
