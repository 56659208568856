import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "./../styles/components/navbar/Navbar.scss";
import { Language } from "@mui/icons-material";
import "./../styles/components/navbar/NavTop.scss";
import css from "./Addmission.module.css";
import TopNav from "./navbar/TopNav";
import Footer from "./Footer/Footer";
import Navbar from "./navbar/Navbar";
import Demo from "./NewsNotic/Demo";

const Addmission = () => {
  const [addClass, updateClass] = useState(false);
  const [stkyClass, setStkyClass] = useState(false);
  const [mega, setMega] = useState(false);

  const handleClickCross = () => {
    updateClass(false);
    window.scrollTo(0, 0);
  };
  const scrollHandleScroll = () => {
    if (window.scrollY > 20) {
      setStkyClass(true);
    } else {
      setStkyClass(false);
    }
  };
  const handleMega = () => {
    setMega(!mega);
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollHandleScroll);
  }, []);

  const menuItems = [
    {
      title: "Home",
      children: [],
    },
    {
      title: "About",
      children: [
        {
          title: "Our School",
          link: "/our-school",
        },
        {
          title: "Principal Desk",
          link: "/principal-desk",
        },
        {
          title: "Faculty",
          link: "/faculty",
        },
        {
          title: "Guardian Feedback",
          link: "/guardian-feedback",
        },
      ],
    },
    {
      title: "Gallery",
      children: [],
    },
    {
      title: "Academics",
      children: [],
    },
    {
      title: "Facilities",
      children: [
        {
          title: "Library",
          link: "/library",
        },
        {
          title: "Sports",
          link: "/sports",
        },
        {
          title: "Canteen",
          link: "/canteen",
        },
        {
          title: "Transport",
          link: "/transport",
        },
        {
          title: "Edusmart",
          link: "/Edusmart",
        },
      ],
    },
    {
      title: "Jobs",
      children: [],
    },
    {
      title: "Exam",
      children: [
        {
          title: "Exam Time Table",
          link: "/birth-day",
        },
        {
          title: "Hall Ticket",
          link: "/annual-day",
        },
        {
          title: "Results",
          link: "/freshers-day",
        },
      ],
    },
    {
      title: "Contact Us",
      children: [],
    },
  ];

  return (
    <>
      <TopNav />
      <Navbar />
      <div className={css.headerContainer}>
        <div>
          <h1>ADDMISSION</h1>
          <h5>
            <Link
              style={{
                color: "white",
              }}
              to="/"
            >
              Home
            </Link>{" "}
            / ADMISSION
          </h5>
        </div>
      </div>
      <Grid container spacing={12} marginLeft={"-76px"}>
        <Grid
          items
          xs={12}
          mg={12}
          lg={8}
          style={{
            margin: "40px 0px 20px 0px",
            "border-right": "1px solid #BDBDBD",
          }}
        >
          {/* <h5 style={{margin:"10px 10px -5px 100px",fontWeight:"700"}}>ADMISSION</h5>
          <h5 style={{margin:"10px 10px -5px 100px",fontWeight:"700"}}>Home / ADMISSION</h5> */}
          <p style={{ margin: "0px 10px 20px 24px", fontSize: "1.11rem" }}>
            {" "}
            Admission process starts usually from April 10 every year.
            Admissions are given on bases of performance shown by your child in
            screening test.
          </p>
          <h5 style={{ margin: "5px 10px -5px 24px", fontWeight: "700" }}>
            Screening Test
          </h5>
          <p style={{ margin: "10px 10px 20px 24px", fontSize: "1.11rem" }}>
            Comprises Mathematics and English paper carrying 25 marks each. i.e
            for 50 marks
          </p>
          <h5 style={{ margin: "5px 10px -5px 24px", fontWeight: "700" }}>
            Syllabus
          </h5>
          <p style={{ margin: "10px 10px 20px 24px", fontSize: "1.11rem" }}>
            5th standard syllabus
          </p>
          <h5 style={{ margin: "5px 10px -5px 24px", fontWeight: "700" }}>
            Registration for Screening Test
          </h5>
          <p style={{ margin: "10px 10px 20px 24px", fontSize: "1.11rem" }}>
            {" "}
            Parents can download registration form available in our website.
            Filled registration form can be submitted in school along with
            registration fees of Rs.100.Dates of day / time of screening test
            will be intimated to parents through phone
          </p>
          <h5 style={{ margin: "5px 10px 20px 24px", fontWeight: "700" }}>
            Please Note: No middleman is entertained with respect to admission.
          </h5>
          <h5 style={{ margin: "5px 10px 10px 24px", fontWeight: "700" }}>
            {" "}
            Office Timing
          </h5>
          <p style={{ margin: "-5px 10px 20px 24px", fontSize: "1.11rem" }}>
            09.00am to 04.30pm all days except Sunday and gazetted holidays.
          </p>
          <h5 style={{ margin: "5px 10px 10px 24px", fontWeight: "700" }}>
            Office Timing
          </h5>
          <p style={{ margin: "-5px 10px 20px 24px", fontSize: "1.11rem" }}>
            {" "}
            09.00am to 04.30pm all days except Sunday and gazetted holidays.
          </p>
          <h5 style={{ margin: "5px 10px 10px 24px", fontWeight: "700" }}>
            Documents required for admission
          </h5>
          <ul style={{ margin: "-5px 10px 10px 24px", fontSize: "1.11rem" }}>
            <li>Previous year marks card</li>
            <li>Caste certificate</li>
            <li>Aadhar card zerox</li>
            <li>4 Passport size photos (Recent photo)</li>
            <li>Parents ID proof</li>
            <li>SB account zerox copy (Nationalised bank)</li>
          </ul>
        </Grid>
        <Grid
          items
          xs={12}
          mg={6}
          lg={4}
          style={{ height: "80vh", paddingTop: "40px" }}
        >
          <Demo />
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default Addmission;
