import React, { useEffect, useState } from "react";

// styles
import css from "../../../styles/fees/QuickFee.module.css";
import { Box, Card, Grid } from "@mui/material";
import HeaderCard from "../../ui/HeaderCard";
import { get } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import { useNavigate } from "react-router-dom";

const QuickFee = () => {
  const navigate = useNavigate();

  const [receiptBooks, setReceiptBooks] = useState([]);

  useEffect(() => {
    const getReceiptsBooks = async () => {
      try {
        const { data: receiptsList, status } = await get(
          `${urls.recepitBook.getAllActiveReceipt}`
        );
        if (status > 199 && status < 299) {
          setReceiptBooks(receiptsList.data);
        }
      } catch (error) {}
    };
    getReceiptsBooks();
  }, []);
  return (
    <div className={css.container}>
      {/* <HeaderCard
        header="Quick Fee collection"
        color="#EB3223"
        style={{ marginLeft: "-80%" }}
      /> */}
      <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span
              style={{
                color: "red",
                fontFamily: "cursive",
              }}
            >
              Quick Fee collection:
            </span>
          </Grid>
        </Grid>
      </Card>
      <div className={css.subHeader}>SELECT RECEIPT</div>
      <div className={css.cardList}>
        {receiptBooks.map((receipt) => (
          <Card
            onClick={() =>
              navigate("/quick-fee-collection", {
                state: { receiptId: receipt._id },
              })
            }
            className={css.card}
          >
            <span>{receipt.name}</span>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default QuickFee;
