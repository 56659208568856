import { CircularProgress } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import {useParams} from 'react-router-dom';
import { get } from '../../../services/apis';
import { urls } from '../../../services/urlConstant';
import AddEmployee from './AddEmployee';

const EmployeeView = () => {

    const [employee,setEmployee] = useState(undefined);
    const {employeeId} = useParams();
    
    useEffect(() => {
        const getEmployee = async () => {
            try {
                const res = await get(urls.employee.getEmployee+employeeId);
                if(res.data.success) {
                    setEmployee(res.data.data);
                } else {
                    console.log(res.data);
                }
            } catch (error) {
                console.log(error);
            }
        }
        getEmployee();
    },[employeeId])
    return(
        <div>
            {employee ? (<AddEmployee view={true} employee={employee} />) : (<div 
            style={{
                display:'flex',
                justifyContent:"center",
                alignItems:'flex-end',
                height:"50vh"
                
            }}
            >
                <CircularProgress size={100} />
            </div>)}
        </div>
    )
}
export default EmployeeView;