import React from "react";
import { Grid } from "@material-ui/core";
import classroom from "../../assets/Images/icon-classroom.webp";
import Sports from "../../assets/Images/icon-playground.webp";
import Library from "../../assets/Images/icon-library.webp";
import Laboratory from "../../assets/Images/icon-laboratory.webp";
import Canteen from "../../assets/Images/icon-canteen.webp";
import CCTV from "../../assets/Images/icon-cctv.webp";
import "../../styles/components/Infrastructure/Infrastructure.scss";
import { Link } from "react-router-dom";

const Infrastructure = () => {
  const infraItems = [
    {
      src: classroom,
      title: "Classroom",
      link:'/facilities/edusmart'
    },
    {
      src: Sports,
      title: "Sports",
      link:'/facilities/sports'
    },
    {
      src: Library,
      title: "Library",
      link:'/facilities/library'
    },
    {
      src: Laboratory,
      title: "Laboratory",
      link:'/'
    },

    {
      src: Canteen,
      title: "Canteen",
      link:'/facilities/canteen'
    },
    {
      src: CCTV,
      title: "CCTV",
      link:'/'
    },
  ];
  return (
    <div className="infrastructure">
      <div className="main-container_infra">
        <div>
          <h1 className="infrastructure-title">
            INFRASTRUCTURE
            <div className="all-line-1">
              <div className="first-line"></div>
              <div className="middle-line"></div>
              <div className="first-line last-line"></div>
            </div>
          </h1>
        </div>
         <div>
        <Grid container justyfy="center" spacing={0.5}>
          {infraItems.map((item) => (
            <Grid item xs={12} sm={6} md={2}>
              <Link to={`${item.link}`} style={{color:'inherit'}}>
              <img src={item.src} alt="" />
              <div className="infra-title">{item.title}</div>
              </Link>
            </Grid>
          ))}
        </Grid>

         </div>
      </div>
    </div>
  );
};

export default Infrastructure;
