import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Button,
  Grid,
  Tooltip,
} from "@mui/material";
import "../../../styles/components/AcademicYear/DateModal.scss";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import dayjs from "dayjs";
import { post } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 500,
  bgcolor: "background.paper",
  p: 4,
};

const AddNews = (props) => {
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [shortNews, setShortNews] = useState("");
  const [news, setNews] = useState("");
  const [webView, setWebView] = useState("");
  const [image,setImage] = useState(null);
  const [imageLink,setImageLink] = useState('');

  useEffect(() => {
    if (props.updateNews) {
      setTitle(props.updateNews.title);
      setDate(dayjs(props.updateNews.date).format('YYYY-MM-DD'));
      setShortNews(props.updateNews.shortNews);
      setNews(props.updateNews.news);
      setWebView(props.updateNews.webView);
      setImageLink(props.updateNews.image ? props.updateNews.image.link : '')
    }
  }, [props.updateNews]);

  const handleTitle = (e) => {
    setTitle(e.target.value);
  };
  const handleDate = (e) => {
    setDate(e.target.value);
  };
  const handleShortNews = (e) => {
    setShortNews(e.target.value);
  };
  const handleNews = (e) => {
    setNews(e.target.value);
  };
  const handleWebView = (e) => {
    setWebView(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
    let imageRes = null;
    if(image) {
      console.log('reach img')
      const fileForm = new FormData();
      fileForm.append('files',image);
      imageRes = await post(urls.fileUpload.postFileUpload,fileForm);
    }
    let data = {
      title,
      date,
      shortNews,
      news,
      webView,
    }
    if(imageRes && imageRes.data.success) {
        data.image = imageRes.data.result[0]._id
    }
    if (props.updateNews) {

      props.handleUpdate(
        data,
        props.updateNews._id
      );
    } else {
      props.handleSubmit(e, data);
    }
    setImage(null)
    setImageLink('')
    props.getAllNews();
    setTitle("");
    setDate("");
    setShortNews("");
    setNews("");
    setWebView("");

    props.handleClose();
    } catch (error) {
      console.log(error)
      toast.error("Something went wrong try again later")
    }
  };

  const handleCloseModal = () => {
    setTitle("");
    setDate("");
    setShortNews("");
    setNews("");
    setWebView("");
    setImageLink('')
    setImage(null)
    props.handleClose();
  };

  const handleClose = () => {
    setTitle("");
    setDate("");
    setShortNews("");
    setNews("");
    setWebView("");
    setImageLink('')
    setImage(null)
    props.handleClose();
  };
  const handleImgChange = (e) => {
    setImage(e.target.files[0]);
  }
  return (
    <div>
      <Fab
        size="medium"
        style={{ background: "#1b3779", color: "#fff" }}
        aria-label="add"
        onClick={props.handleOpen}
      >
        <Tooltip title="Add">
          <Add />
        </Tooltip>
      </Fab>
      <Modal
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleSubmit}>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
              marginBottom="20px"
            >
              Add News
            </Typography>
            <div className="">
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <TextField
                    required
                    variant="standard"
                    label="News Title"
                    value={title}
                    onChange={handleTitle}
                    sx={{ m: 1, width: "100%", label: { fontSize: 12 } }}
                  />
                </Grid>
              </Grid>
              <TextField
                required
                variant="standard"
                label="Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={date}
                onChange={handleDate}
                sx={{ m: 1, minWidth: 341, label: { fontSize: 12 } }}
              />
              <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 345 }}
                required
              >
                <InputLabel sx={{ fontSize: 12 }}>Is view on web?</InputLabel>
                <Select value={webView} onChange={handleWebView}>
                  <MenuItem
                    value={"yes"}
                    sx={{ fontSize: 12, fontWeight: 500 }}
                  >
                    Yes
                  </MenuItem>
                  <MenuItem value={"no"} sx={{ fontSize: 12, fontWeight: 500 }}>
                    No
                  </MenuItem>
                </Select>
              </FormControl>
              <div style={{margin:'10px 0'}}>
                <input type='file' accept="image/*" onChange={handleImgChange}/>
                {props.updateNews && imageLink && (<img src={`${imageLink}`} alt='' width={30} height={30} />)}
              </div>
              <TextareaAutosize
                maxRows={4}
                aria-label="maximum height"
                placeholder="Short News"
                value={shortNews}
                onChange={handleShortNews}
                style={{
                  width: 712,
                  maxHeight: 110,
                  margin: 8,
                  padding: 10,
                  height: 70,
                  overflow: "auto",
                }}
              />
              <TextareaAutosize
                maxRows={4}
                aria-label="maximum height"
                placeholder="News"
                value={news}
                onChange={handleNews}
                style={{
                  width: 712,
                  maxHeight: 110,
                  margin: 8,
                  padding: 10,
                  height: 70,
                  overflow: "auto",
                }}
              />
            </div>

            <div className="cancel-submit-btn">
              <div>
                <Tooltip title="Cancel">
                <Button
                  variant="outlined"
                  size="medium"
                  type="button"
                  sx={{ m: 1 }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                </Tooltip>
                <Tooltip title="Submit">
                <Button
                  variant="contained"
                  size="medium"
                  type="submit"
                  sx={{ background: "rgb(27, 55, 121)", ":hover":{background: "rgb(27, 55, 121)"} }}
                >
                  Submit
                </Button>
                </Tooltip>
              </div>
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
};

export default AddNews;