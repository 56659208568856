import React, { useState, useEffect } from "react";

import {
  Box,
  Card,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  linearProgressClasses,
  MenuItem,
  Select,
  styled,
  TextareaAutosize,
  TextField,
  Switch,
  Button,
  Checkbox,
  ListItemText,
  Autocomplete,
  InputBase,
  Popper,
  InputAdornment,
} from "@mui/material";
import { get, post, put } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { BorderAll } from "@material-ui/icons";
import Multiselect from "multiselect-react-dropdown";
import { useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const styles = {
  card: {
    padding: 10,
    margin: "10px 0px",
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
  },
  switch: {
    color: "#1b3779",
    ":hover": {
      color: "#1b3779",
    },
  },
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 25,
  borderRadius: 15,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 800 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 15,
    backgroundColor: theme.palette.mode === "light" ? "#f42323" : "#308fe8",
  },
}));
const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,
  width: "100%",
  borderBottom: `1px solid ${
    theme.palette.mode === "light" ? "#eaecef" : "#30363d"
  }`,
  "& input": {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#0d1117",
    padding: 8,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    border: `1px solid ${
      theme.palette.mode === "light" ? "#eaecef" : "#30363d"
    }`,
    fontSize: 14,
    "&:focus": {
      boxShadow: `0px 0px 0px 3px ${
        theme.palette.mode === "light"
          ? "rgba(3, 102, 214, 0.3)"
          : "rgb(12, 45, 107)"
      }`,
      borderColor: theme.palette.mode === "light" ? "#0366d6" : "#388bfd",
    },
  },
}));

const TOTAL_CREDITS = 173875;

const Compose = () => {
  const { state } = useLocation();
  const [sendingMessage, setSendingMessage] = useState(false);

  const [roles, setRoles] = useState([]);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [receiverType, setReceiverType] = useState("");
  const [selectRoles, setSelectRoles] = useState([]);
  const [selectRole, setSelectRole] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [smsFrom, setSmsFrom] = useState({
    sms: state
      ? state.message
      : `Dear {{VAR}}, We would like to inform you {{VAR1}},{{VAR2}},{{VAR3}},{{VAR4}},{{VAR5}} Regards EXCELLENT SCHOOL VIJAYAPURA.`,
  });
  const [roleUsers, setRoleUsers] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [classPopper, setClassPopper] = useState(false);
  const [sectionPopper, setSectionPopper] = useState(false);
  const [contactsPopper, setContactsPopper] = useState(false);
  const [students, setStudents] = useState([]);
  const [notifyChecked, setNotifyChecked] = useState(false);
  const [selectClass, setSelectClass] = useState("");
  const [selectSection, setSelectSection] = useState("");
  const [selectContacts, setSelectContacts] = useState("");
  const [selectedStudentCount, setSelectedStudentCount] = useState(0);
  const [hidden, sethidden] = useState(true);
  const [classAutoSelect, setClassAutoSelect] = useState([]);
  const [sectionAutoSelect, setSectionAutoSelect] = useState([]);
  const [contactsAutoSelect, setContactsAutoSelect] = useState([]);
  const [selectFile, setSelectFile] = useState(null);

  const [balance, setBalance] = useState("");

  useEffect(() => {
    setSectionAutoSelect([]);
    setSelectSection("");
    setSelectContacts("");
    setContactsAutoSelect([]);
  }, [classAutoSelect]);

  useEffect(() => {
    setSelectContacts("");
    setContactsAutoSelect([]);
  }, [sectionAutoSelect]);

  const getSmsBalance = async () => {
    try {
      const response = await get(urls.communication.getBalance);
      setBalance(response.data.data.trans);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSmsBalance();
  }, []);

  useEffect(() => {
    const getRoles = async () => {
      try {
        const response = await get(urls.role.getAllRole);
        setRoles(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    const getClasses = async () => {
      try {
        const classes = await get(urls.class.getAllClass);
        if (classes.data.success) {
          setClasses(classes.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getRoles();
    getClasses();
  }, []);

  const handleReceiverType = (e) => {
    setReceiverType(e.target.value);
  };
  const handleSelectRoleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectRoles(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  useEffect(() => {
    const getSmsTemplates = async () => {
      try {
        const getSmsTemplatesRes = await get(`/template-sms`);
        if (getSmsTemplatesRes.data.success) {
          setTemplates(getSmsTemplatesRes.data.data);
        } else {
          console.log(getSmsTemplatesRes);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getSmsTemplates();
  }, []);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setSmsFrom({ ...smsFrom, [name]: value });
  };
  const handleTemplateTypeChange = (e) => {
    const msg = templates.find((template) => template._id == e.target.value);
    setSmsFrom({
      ...smsFrom,
      sms: state ? state.message : msg.textMessage,
      template: e.target.value,
    });
  };

  console.log(receiverType, "receiverType");
  const handleSubmitSms = async (e) => {
    e.preventDefault();
    setSendingMessage(true);
    try {
      if (receiverType === "role") {
        const tempRoles = roles.filter((role) =>
          selectRoles.includes(role.roleName)
        );
        const roleIds = tempRoles.map((id) => id._id);
        const data = {
          roles: roleIds,
          smsTemplate: smsFrom.template,
          message: smsFrom.sms,
          subject: smsFrom.subject,
          notify: notifyChecked,
          smsCategory: smsFrom.smsType,
        };
        const sendSms = await post(urls.communication.sendSmsToRole, data);
        if (sendSms.data.success) {
        }
      } else if (receiverType === "user" || receiverType === "students") {
        const data = {
          numbers: contacts,
          smsTemplate: smsFrom.template,
          message: smsFrom.sms,
          subject: smsFrom.subject,
          notify: notifyChecked,
          smsCategory: smsFrom.smsType,
        };
        const sendSms = await post(urls.communication.sendSmsToSingle, data);
        if (sendSms.data.success) {
        }
      } else if (receiverType === "All Students") {
        const data = {
          smsTemplate: smsFrom.template,
          message: smsFrom.sms,
          subject: smsFrom.subject,
          notify: notifyChecked,
          smsCategory: smsFrom.smsType,
        };
        await post(urls.communication.sendSmsToStudents, data);
      } else if (receiverType === "file") {
        if (selectFile) {
          const formData = new FormData();
          formData.append("fileNumbers", selectFile);
          formData.append("smsTemplate", smsFrom.template);
          formData.append("message", smsFrom.sms);
          formData.append("subject", smsFrom.subject);
          formData.append("notify", notifyChecked);
          formData.append("smsCategory", smsFrom.smsType);

          await put(urls.communication.sendBulkSms, "", formData);
        } else {
          toast.error("please choose a file");
        }
      }
      getSmsBalance();
    } catch (error) {
      console.log(error);
    }
    setSendingMessage(false);
  };
  const handleRoleChange = async (e) => {
    handleFormChange(e);
    const role = e.target.value;
    try {
      const contacts = await get(`/employee`, {
        params: {
          search: {
            role,
          },
        },
      });
      if (contacts.data.success) {
        setRoleUsers(contacts.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // const handleContactsList = (event, val) => {
  //   const studentNames = val.map((schclass) => schclass.basicInfo.name);
  //   setContactsAutoSelect(val);
  //   setSelectContacts(studentNames.join(","));
  //   const findAll = val.find((item) => item._id === "all");
  //   if (findAll) {
  //     const allStudentsContacts = students.map(
  //       (student) => student.contactNumber
  //     );
  //     setContacts(allStudentsContacts);
  //      setSelectedStudentCount(students.length);
  //   } else {
  //     const contacts = val.map((ele) => ele.contactNumber);
  //     setSelectedStudentCount(val.length);
  //     setContacts(contacts);
  //   }
  // };

  const handleContactsList = (event, val) => {
    const findAll = val.some((item) => item._id === "all");

    if (findAll) {
      const allStudentsContacts = students.map(
        (student) => student.contactNumber
      );
      setContacts(allStudentsContacts);
      setSelectedStudentCount(students.length);
      setSelectContacts("All");
    } else {
      const studentNames = val.map((schclass) => schclass.basicInfo.name);
      const contacts = val.map((ele) => ele.contactNumber);
      setContacts(contacts);
      setSelectedStudentCount(val.length);
      setSelectContacts(studentNames.join(", "));
    }

    setContactsAutoSelect(val);
  };

  const handleClassSelect = async (e, val) => {
    const classIds = val.map((schclass) => schclass._id);
    const classNames = val.map((schclass) => schclass.className);
    setClassAutoSelect(val);
    setSelectClass(classNames.join(","));

    try {
      if (val.length) {
        const getSections = await get(urls.schoolSection.getAllSection, {
          params: {
            search: {
              class: {
                $in: classIds,
              },
            },
          },
        });
        if (getSections.data.success) {
          const sortedSections = getSections.data.data.sort((a, b) =>
            a.sectionName.localeCompare(b.sectionName)
          );
          setSections(sortedSections);
        }
      } else {
        setSections([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSectionSelect = async (e, val) => {
    const sectionIds = val.map((section) => section._id);
    const sectionNames = val.map((schclass) => schclass.sectionName);
    const classNames = val.map((schclass) => schclass.class.className);
    setSectionAutoSelect(val);
    setSelectSection(
      sectionNames
        .map((name, index) => `${name} (${classNames[index]})`)
        .join(", ")
    );
    try {
      if (val.length) {
        const getStudents = await post(`/sections/all-students`, {
          sectionIds,
        });
        if (getStudents.data.success) {
          setStudents(getStudents.data.data);
        }
      } else {
        setStudents([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectClassChange = (event) => {
    const {
      target: { value },
    } = event;
    setClasses(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleClassChange = async (e) => {
    const { name, value } = e.target;
    setSmsFrom({ ...smsFrom, [name]: value });
    try {
      const getSections = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: value,
          },
        },
      });
      if (getSections.data.success) {
        setSections(getSections.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSectionChange = async (e) => {
    const { name, value } = e.target;
    setSmsFrom({ ...smsFrom, [name]: value });
    try {
      const getStudents = await get(urls.students.getAllStudent, {
        params: {
          search: {
            "academicInfo.class": smsFrom.class,
            "academicInfo.section": value,
          },
        },
      });
      if (getStudents.data.success) {
        console.log(getStudents.data.data);
        setStudents(getStudents.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleNotifiy = (e) => {
    setNotifyChecked(e.target.checked);
  };
  const handleDownloadSampleFile = async () => {
    try {
      const smsSample = await get(urls.communication.getUploadSampleFile, {
        responseType: "blob",
      });
      if (smsSample.status === 200) {
        const uri = URL.createObjectURL(smsSample.data);
        const link = document.createElement("a");
        link.href = uri;
        link.setAttribute("download", "sms_sample.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } catch (error) {}
  };

  const showColor = (percentage) => {
    if (percentage < 30) {
      return "red";
    } else if (percentage >= 30 && percentage <= 50) {
      return "orange";
    } else if (percentage > 50 && percentage <= 70) {
      return "#9c9208";
    } else return "green";
  };
  return (
    <div>
      <form onSubmit={handleSubmitSms}>
        <div style={{ margin: 15 }}>
          <Card style={styles.card}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <span
                  style={{
                    color: "red",
                    fontSize: "large",
                    fontFamily: "cursive",
                  }}
                >
                  Compose:
                </span>
              </Grid>
            </Grid>
          </Card>
          <Card>
            <div
              style={{
                paddingLeft: "1rem",
                display: "flex",
                gap: "5px",
              }}
            >
              <div>
                Total SMS :{" "}
                <span style={{ fontWeight: "bold" }}>{TOTAL_CREDITS}</span>
              </div>
              <div>
                Consumed SMS :{" "}
                <span style={{ fontWeight: "bold" }}>
                  {TOTAL_CREDITS - balance}
                </span>
              </div>
              <div>
                Balance SMS :{" "}
                <span style={{ fontWeight: "bold" }}>{balance}</span>
              </div>
            </div>
            <div
              style={{
                padding: "1rem",
              }}
            >
              <BorderLinearProgress
                sx={{
                  ".MuiLinearProgress-bar": {
                    backgroundColor: showColor(
                      100 - ((TOTAL_CREDITS - balance) * 100) / TOTAL_CREDITS
                    ),
                  },
                }}
                variant="determinate"
                value={100 - ((TOTAL_CREDITS - balance) * 100) / TOTAL_CREDITS}
              />
            </div>
          </Card>
          <Card style={styles.card}>
            <Box sx={{ flexFlow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl
                    variant="standard"
                    sx={{ m: 1, minWidth: "100%" }}
                  >
                    <InputLabel
                      id="demo-simple-select-filrlccled-label"
                      sx={{ fontSize: 12 }}
                    >
                      Receiver Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="receiverType"
                      value={receiverType}
                      onChange={(e) => setReceiverType(e.target.value)}
                    >
                      <MenuItem
                        value={"role"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Role
                      </MenuItem>
                      <MenuItem
                        value={"user"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        User
                      </MenuItem>
                      <MenuItem
                        value={"students"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Students
                      </MenuItem>
                      <MenuItem
                        value={"All Students"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        All Students
                      </MenuItem>
                      <MenuItem
                        value={"file"}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        File
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {receiverType === "file" && (
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      alignItems: "flex-end",
                    }}
                  >
                    <Button
                      onClick={handleDownloadSampleFile}
                      variant="contained"
                    >
                      Sample File
                    </Button>
                    <input
                      required
                      type="file"
                      onChange={(e) => setSelectFile(e.target.files[0])}
                    />
                  </div>
                )}
                {receiverType === "students" && (
                  <>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        fullWidth
                        value={selectClass}
                        aria-describedby={"classPopper"}
                        onFocus={(e) => {
                          setClassPopper(classPopper ? null : e.currentTarget);
                        }}
                        style={{ marginTop: "15px" }}
                        variant="standard"
                        label="Class"
                      />
                      <Popper
                        style={{ marginTop: "15px" }}
                        id={"classPopper"}
                        open={!!classPopper}
                        anchorEl={classPopper}
                      >
                        <FormControl
                          variant="standard"
                          sx={{ m: 1, width: "100%" }}
                        >
                          <Autocomplete
                            onBlur={() => setClassPopper(null)}
                            open={true}
                            value={classAutoSelect}
                            multiple
                            onChange={handleClassSelect}
                            isOptionEqualToValue={(option, value) =>
                              option._id === value._id
                            }
                            options={classes}
                            disableCloseOnSelect
                            getOptionLabel={(option) => `${option.className}`}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  checked={selected}
                                />
                                {`${option.className}`}
                              </li>
                            )}
                            renderInput={(params) => (
                              <StyledInput
                                ref={params.InputProps.ref}
                                inputProps={params.inputProps}
                                placeholder="Class"
                                autoFocus
                              />
                            )}
                          />
                        </FormControl>
                      </Popper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        fullWidth
                        value={selectSection}
                        aria-describedby={"sectionPopper"}
                        onFocus={(e) => {
                          setSectionPopper(
                            sectionPopper ? null : e.currentTarget
                          );
                        }}
                        variant="standard"
                        style={{ marginTop: "15px" }}
                        label="Section"
                      />
                      <Popper
                        style={{ marginTop: "15px" }}
                        id={"sectionPopper"}
                        open={!!sectionPopper}
                        anchorEl={sectionPopper}
                      >
                        <FormControl
                          variant="standard"
                          sx={{ m: 1, width: "100%" }}
                        >
                          <Autocomplete
                            multiple
                            onBlur={() => setSectionPopper(null)}
                            open={true}
                            value={sectionAutoSelect}
                            onChange={handleSectionSelect}
                            isOptionEqualToValue={(option, value) =>
                              option._id === value._id
                            }
                            id="checkboxes-tags-demo"
                            options={sections.sort((a, b) =>
                              a.class.className.localeCompare(b.class.className)
                            )}
                            disableCloseOnSelect
                            getOptionLabel={(option) =>
                              `${option.sectionName}(class:${option.class.className})`
                            }
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {`${option.sectionName}(class:${option.class.className})`}
                              </li>
                            )}
                            renderInput={(params) => (
                              <StyledInput
                                ref={params.InputProps.ref}
                                inputProps={params.inputProps}
                                placeholder="Section"
                                autoFocus
                              />
                            )}
                          />
                        </FormControl>
                      </Popper>
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        fullWidth
                        value={selectContacts}
                        aria-describedby={"contactsPopper"}
                        onFocus={(e) => {
                          setContactsPopper(
                            contactsPopper ? null : e.currentTarget
                          );
                        }}
                        variant="standard"
                        label="Students"
                        style={{ marginTop: "15px" }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              All Total-{selectedStudentCount}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Popper
                        style={{ marginTop: "15px" }}
                        id={"contactsPopper"}
                        open={!!contactsPopper}
                        anchorEl={contactsPopper}
                      >
                        <FormControl
                          variant="standard"
                          sx={{ m: 1, width: "100%" }}
                        >
                          <Autocomplete
                            multiple
                            onBlur={() => setContactsPopper(null)}
                            open={true}
                            value={contactsAutoSelect}
                            onChange={handleContactsList}
                            isOptionEqualToValue={(option, value) =>
                              option._id === value._id
                            }
                            id="checkboxes-tags-demo"
                            options={[
                              {
                                _id: "all",
                                basicInfo: {
                                  name: "All",
                                },
                                contactNumber: "",
                              },
                              ...students,
                            ]}
                            disableCloseOnSelect
                            getOptionLabel={(option) =>
                              `${option.basicInfo.name} ${option.contactNumber}`
                            }
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {`${option.basicInfo.name} (${option.contactNumber})`}
                              </li>
                            )}
                            renderInput={(params) => (
                              <StyledInput
                                ref={params.InputProps.ref}
                                inputProps={params.inputProps}
                                placeholder="Students"
                                autoFocus
                              />
                            )}
                          />
                        </FormControl>
                      </Popper>
                    </Grid>
                  </>
                )}

                {receiverType === "role" && (
                  <Grid item xs={12} md={6} lg={3}>
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, width: "100%" }}
                    >
                      <InputLabel sx={{ fontSize: 12 }}>Role</InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={selectRoles}
                        onChange={handleSelectRoleChange}
                        multiple
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {roles &&
                          roles.map((row, index) => (
                            <MenuItem
                              key={row._id}
                              value={row.roleName}
                              sx={{ fontSize: 12, fontWeight: 500 }}
                            >
                              <Checkbox
                                checked={selectRoles.indexOf(row.roleName) > -1}
                              />
                              <ListItemText primary={row.roleName} />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {receiverType === "user" && (
                  <Grid item xs={12} md={6} lg={3}>
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, width: "100%" }}
                    >
                      <InputLabel sx={{ fontSize: 12 }}>Role</InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={smsFrom.role}
                        name="role"
                        onChange={handleRoleChange}
                      >
                        {roles &&
                          roles.map((row, index) => (
                            <MenuItem
                              key={row._id}
                              value={row._id}
                              sx={{ fontSize: 12, fontWeight: 500 }}
                            >
                              {row.roleName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {smsFrom.role && receiverType === "user" && (
                  <Grid item xs={12} md={6} lg={3}>
                    <FormControl required={true} sx={{ width: "100%" }}>
                      <Autocomplete
                        multiple
                        onChange={handleContactsList}
                        id="checkboxes-tags-demo"
                        options={roleUsers}
                        disableCloseOnSelect
                        getOptionLabel={(option) =>
                          `${option.basicInfo.empName} (${option.contactNumber})`
                        }
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {`${option.basicInfo.empName} (${option.contactNumber})`}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            sx={{ m: 1, width: "100%" }}
                            {...params}
                            label="Employees"
                            placeholder="Favorites"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Card>
          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl
                    required={true}
                    variant="standard"
                    sx={{ m: 1, width: "100%" }}
                  >
                    <InputLabel sx={{ fontSize: 12 }}>SMS Type</InputLabel>
                    <Select
                      id="demo-simple-select-filled"
                      name="smsType"
                      value={smsFrom.smsType || ""}
                      onChange={handleFormChange}
                    >
                      <MenuItem
                        value={"general"}
                        style={{ fontSize: 12, fontWeight: 500 }}
                      >
                        General
                      </MenuItem>
                      <MenuItem
                        value={"attendance"}
                        style={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Attendence
                      </MenuItem>
                      <MenuItem
                        value={"fee"}
                        style={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Fee
                      </MenuItem>
                      <MenuItem
                        value={"credential"}
                        style={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Credential
                      </MenuItem>
                      <MenuItem
                        value={"exam"}
                        style={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Exam
                      </MenuItem>
                      <MenuItem
                        value={"leave"}
                        style={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Leave
                      </MenuItem>
                      <MenuItem
                        value={"library"}
                        style={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Library
                      </MenuItem>
                      <MenuItem
                        value={"transport"}
                        style={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Transport
                      </MenuItem>
                      <MenuItem
                        value={"hostel"}
                        style={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Hostel
                      </MenuItem>
                      <MenuItem
                        value={"preaddmission"}
                        style={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Preaddmission
                      </MenuItem>
                      <MenuItem
                        value={"parentMeeting"}
                        style={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Parent Meeting
                      </MenuItem>
                      <MenuItem
                        value={"schoolTiming"}
                        style={{ fontSize: 12, fontWeight: 500 }}
                      >
                        School Timing
                      </MenuItem>
                      <MenuItem
                        value={"notice"}
                        style={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Notice
                      </MenuItem>
                      <MenuItem
                        value={"schoolOpen"}
                        style={{ fontSize: 12, fontWeight: 500 }}
                      >
                        School Open
                      </MenuItem>
                      <MenuItem
                        value={"education"}
                        style={{ fontSize: 12, fontWeight: 500 }}
                      >
                        Education
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    required={true}
                    variant="standard"
                    label="Subject"
                    sx={{ m: 1, width: "100%", fontSize: 12 }}
                    name="subject"
                    value={smsFrom.subject || ""}
                    onChange={handleFormChange}
                  />
                </Grid>
                {/* <Grid item xs={12} md={6} lg={4}>
                  <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                    <InputLabel sx={{ fontSize: 12 }}>Template</InputLabel>
                    <Select
                      id="demo-simple-select-filled"
                      name="template"
                      value={smsFrom.template || ""}
                      onChange={handleTemplateTypeChange}>
                      {templates.length > 0 &&
                        templates.map((template) => (
                          <MenuItem
                            value={template._id}
                            key={template._id}
                            style={{ fontSize: 12, fontWeight: 500 }}>
                            {template.template}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid> */}
                <Grid item xs={12} md={12} lg={12}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <span>Dynamic Tag: </span>

                    <div>
                      [name],[school_name] - wil get autoreplaced,
                      <span style={{ color: "red" }}>
                        replace - {"{{var}}"} with your content, variable fields
                        may vary in length. Space consumed for 1 variable is 30
                        char and avoid double space.
                      </span>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  sx={12}
                  md={12}
                  lg={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <TextareaAutosize
                    maxRows={4}
                    aria-label="maximum height"
                    placeholder="Text Message"
                    style={{ width: "95%", height: "120px", padding: "10px" }}
                    name="sms"
                    value={smsFrom.sms}
                    onChange={handleFormChange}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <span>Click here to Notify Via SMS</span>
                  <Switch
                    style={styles.switch}
                    onChange={handleNotifiy}
                    checked={notifyChecked}
                  />
                </Grid>
              </Grid>
            </Box>
          </Card>
          <Card style={styles.card}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <LoadingButton
                    loading={sendingMessage}
                    variant="contained"
                    type="submit"
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </div>
      </form>
    </div>
  );
};

export default Compose;
