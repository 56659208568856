import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { get } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import css from "../../../styles/Overview.module.css";
import StudentCount from "./StudentCount";

const styles = {
  icon: {
    padding: "10px",
    fontSize: "12px",
    fontWeight: 500,
  },
  card: {
    padding: 15,
    marginBottom: 20,
    marginLeft: "1rem",
    marginRight: "1rem",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
};

const Overview = () => {
  const [academicYears, setAcademicYears] = useState([]);
  const [selectAcademicYear, setSelectAcademicYear] = useState("");
  const [overviewDetails, setOverviewDetails] = useState([]);
  const [totalStudents, setTotalStudents] = useState(0);
  const [totalMaleStudents, setTotalMaleStudents] = useState(0);
  const [totalFemaleStudents, setTotalFemaleStudents] = useState(0);
  const [inActiveoverviewDetails, setInActiveOverviewDetails] = useState([]);
  const [inActivetotalStudents, setInActiveTotalStudents] = useState(0);
  const [inActivetotalMaleStudents, setInActiveTotalMaleStudents] = useState(0);
  const [inActivetotalFemaleStudents, setInActiveTotalFemaleStudents] =
    useState(0);

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const { data: academicYears, status } = await get(
          urls.administrator.academicYearGetAll
        );
        if (status > 199 && status < 299) {
          setAcademicYears(academicYears.data);
          if (academicYears.data.length > 0) {
            setSelectAcademicYear(academicYears.data[0]._id);
          }
        }
      } catch (error) {}
    };
    getAcademicYears();
  }, []);
  useEffect(() => {
    const academicYearChanged = async () => {
      try {
        if (selectAcademicYear) {
          const { data: overview, status } = await get(
            `/activestudents-overview/${selectAcademicYear}`
          );
          if (status > 199 && status < 299) {
            setOverviewDetails(overview.data);
            setTotalStudents(overview.totalStudentsCount);
            setTotalMaleStudents(overview.totalMaleStudentsCount);
            setTotalFemaleStudents(overview.totalFemaleStudentsCount);
          }
        }
      } catch (error) {}
    };
    academicYearChanged();
  }, [selectAcademicYear]);

  useEffect(() => {
    const academicYearChanged = async () => {
      try {
        if (selectAcademicYear) {
          const { data: overview, status } = await get(
            `/inactivestudents-overview/${selectAcademicYear}`
          );
          if (status > 199 && status < 299) {
            setInActiveOverviewDetails(overview.data);
            setInActiveTotalStudents(overview.inActivetotalStudentsCount);
            setInActiveTotalMaleStudents(
              overview.inActivetotalMaleStudentsCount
            );
            setInActiveTotalFemaleStudents(
              overview.inActivetotalFemaleStudentsCount
            );
          }
          console.log(inActiveoverviewDetails, "InActiveOverviewDetails");
        }
      } catch (error) {}
    };
    academicYearChanged();
  }, [selectAcademicYear]);

  console.log(overviewDetails, "overDetails");

  // useEffect(() => {
  //   const academicYearChanged = async () => {
  //     try {
  //       if (selectAcademicYear) {
  //         const { data: overview, status } = await get(`/students-overview/${selectAcademicYear}`);
  //         if (status > 199 && status < 299) {
  //           const activeStudents = overview.data.filter(student => student.status === "active");
  //           const totalStudentsCount = activeStudents.length;
  //           const totalMaleStudentsCount = activeStudents.filter(student => student.gender === "male").length;
  //           const totalFemaleStudentsCount = activeStudents.filter(student => student.gender === "female").length;

  //           setOverviewDetails(overview.data);
  //           setTotalStudents(totalStudentsCount);
  //           setTotalMaleStudents(totalMaleStudentsCount);
  //           setTotalFemaleStudents(totalFemaleStudentsCount);
  //         }
  //         console.log(overviewDetails,"overviewdetails")
  //       }
  //     } catch (error) {
  //       // Handle error
  //     }
  //   };

  //   academicYearChanged();
  // }, [selectAcademicYear]);
  // useEffect(() => {
  //   const academicYearChanged = async () => {
  //     try {
  //       if (selectAcademicYear) {
  //         const { data: overview, status } = await get(`/students-overview/${selectAcademicYear}`);
  //         if (status > 199 && status < 299) {
  //           const activeStudents = overview.data.filter(student => student.status === "active");
  //           const totalStudentsCount = activeStudents.length;
  //           const totalMaleStudentsCount = activeStudents.filter(student => student.gender === "male").length;
  //           const totalFemaleStudentsCount = activeStudents.filter(student => student.gender === "female").length;

  //           setOverviewDetails(activeStudents);
  //           setTotalStudents(totalStudentsCount || 0); // Assign a default value of 0 if undefined
  //           setTotalMaleStudents(totalMaleStudentsCount || 0); // Assign a default value of 0 if undefined
  //           setTotalFemaleStudents(totalFemaleStudentsCount || 0); // Assign a default value of 0 if undefined
  //         }
  //       }
  //     } catch (error) {
  //       // Handle error
  //     }
  //   };

  //   academicYearChanged();
  // }, [selectAcademicYear]);

  return (
    <div>
      <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span
              style={{
                color: "red",
                fontFamily: "cursive",
              }}
            >
              Student Overview :
            </span>
          </Grid>
        </Grid>
      </Card>
      <Card style={styles.card}>
        <Box sx={{ flexGrow: 1 }}>
          <form>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6} lg={2}>
                <FormControl
                  variant="standard"
                  style={styles.textfield}
                  required
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Academic Year
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="academicYear"
                    value={selectAcademicYear}
                    onChange={(e) => setSelectAcademicYear(e.target.value)}
                  >
                    {academicYears &&
                      academicYears.map((row) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.academicYearFrom} - {row.academicYearTo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Card>
      <div
        style={{
          padding: "1.2rem",
        }}
      >
        <div className={css.totalOverviewContainer}>
          <div>Total Students:{totalStudents}</div>
          <div>Total Male Students:{totalMaleStudents}</div>
          <div>Total Female Students:{totalFemaleStudents}</div>
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className={css.tableHeader}>
              <TableRow>
                <TableCell className={css.tableCell} align="left">
                  Class
                </TableCell>
                {/* <TableCell className={css.tableCell} align='left'>Active / <br></br> Inactive</TableCell> */}
                <TableCell className={css.tableCell} align="left">
                  Students
                </TableCell>
                {/* <TableCell className={css.tableCell} align="left">
                  Male
                </TableCell>
                <TableCell className={css.tableCell} align="left">
                  Female
                </TableCell> */}
                <TableCell className={css.tableCell} align="left">
                  Sections
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {overviewDetails.map((overview, i) => (
                <TableRow key={overview.className}>
                  <TableCell align="left">{overview.className}</TableCell>

                  <TableCell align="left">
                    <Box
                      sx={{
                        //width: "100px",
                        marginTop: "40px",
                        display: "flex",
                        rowGap: "2px",
                        flexDirection: "column",
                      }}
                    >
                      <StudentCount
                        showTitle={true}
                        title="Male"
                        count={overview.maleStudents}
                      />
                      <StudentCount
                        showTitle={true}
                        title="Female"
                        count={overview.femaleStudents}
                      />
                      <StudentCount
                        showTitle={true}
                        title="Total"
                        count={overview.totalStudents}
                      />
                    </Box>
                  </TableCell>

                  <TableCell align="left">
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead className={css.tableHeader}>
                          <TableRow>
                            {overview.sections
                              .sort((a, b) =>
                                a.section.localeCompare(b.section)
                              ) // Sort sections alphabetically
                              .map((section) => (
                                <TableCell
                                  className={css.tableCell}
                                  align="left"
                                  key={section.section}
                                >
                                  {section.section}
                                </TableCell>
                              ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            {overview.sections
                              .sort((a, b) =>
                                a.section.localeCompare(b.section)
                              ) // Sort sections alphabetically
                              .map((section) => (
                                <TableCell align="start" key={section.section}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      rowGap: "2px",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <StudentCount
                                      title="Male"
                                      count={section.maleCount}
                                    />
                                    <StudentCount
                                      title="Female"
                                      count={section.femaleCount}
                                    />
                                    <StudentCount
                                      title="Total"
                                      count={
                                        section.maleCount + section.femaleCount
                                      }
                                    />
                                  </Box>
                                  {/* <Stack direction={"column"}>
                                    <Box>{section.maleCount} M</Box>
                                    <Box>{section.femaleCount} F</Box>
                                    <Box>
                                      Total :{" "}
                                      {section.maleCount + section.femaleCount}
                                    </Box>
                                  </Stack> */}
                                </TableCell>
                              ))}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Overview;
