import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import DirectionsTransitFilledIcon from "@mui/icons-material/DirectionsTransitFilled";
import { get, post, put, del } from "../../services/apis";
import { urls } from "../../services/urlConstant";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Trips = () => {
  const [dialogRoute, setOpenDialogRoute] = React.useState(false);
  const [dialog, setOpenDialog] = React.useState(false);
  const [showDetails, setShowDetails] = useState(null);
  const [list, setList] = React.useState([]);

  // useEffect(() => {
  //   const getVehicleNumber = async () => {
  //     try {
  //       const { data, status } = await get(`${urls.transport.getList}`);
  //       if (status > 199 && status < 299) {
  //         setVehicleNumber(data.data);
  //         console.log(data.data, "dattttttttttt");
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   getVehicleNumber();
  // }, []);

  useEffect(() => {
    getRoute();
  }, []);

  const getRoute = async () => {
    console.log("lklklklklklklklk");
    try {
      const { data, status } = await get(urls.route.getList);
      setList(data.data);
      console.log(data.data, "xxxxxxxxxxxx");
    } catch (error) {
      console.log(error);
    }
  };

  const styles = {
    button: {
      backgroundColor: "orange",
    },
    textfield: {
      marginTop: "25px",
      width: "70%",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
    tab: {
      width: "100%",
      borderCollapse: "collapse",
    },
  };
  const handleClickOpenDialogRoute = (trips) => {
    setShowDetails(trips);
    setOpenDialogRoute(true);
  };
  const handleCloseDialogRoute = () => {
    setOpenDialogRoute(false);
  };
  const handleCloseDialog = () => {
    setShowDetails(null);
    setOpenDialog(false);
  };
  return (
    <div>
      <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span
              style={{
                color: "red",
                fontFamily: "cursive",
                textAlign: "start",
              }}
            >
              Trips:
            </span>
          </Grid>
        </Grid>
      </Card>
      <div style={{ margin: "15px" }}>
        <Card style={{ padding: 10 }}>
          <div
            style={{
              paddingLeft: "1rem",
              display: "flex",
              gap: "20px",
            }}
          >
            <div>
              Vehicle:<span>{list.length}</span>
            </div>
            <div>
              Total Seats Added:<span>0</span>
            </div>
          </div>
        </Card>
        <Table>
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow sx={{ color: "white" }}>
              <TableCell sx={{ color: "white" }} align="center">
                Vehicle
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center"></TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Pick Start
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Pick End
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Drop Start
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Drop End
              </TableCell>

              <TableCell sx={{ color: "white" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((trips, index) => (
              <TableRow
                key={trips._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">
                  {trips.vehicleNumber
                    ? trips.vehicleNumber.vehicleNumber
                    : "NA"}
                </TableCell>
                <TableCell align="center">
                  {trips.routeStart} -- {trips.routeEnd}
                </TableCell>
                <TableCell align="center">{trips.routeStart}</TableCell>
                <TableCell align="center">{trips.routeEnd}</TableCell>
                <TableCell align="center">{trips.routeEnd}</TableCell>
                <TableCell align="center">{trips.routeStart}</TableCell>

                <TableCell align="center">
                  <div>
                    <Button
                      style={{ color: "#1b3779" }}
                      onClick={() => handleClickOpenDialogRoute(trips)}
                    >
                      <DirectionsTransitFilledIcon />
                    </Button>
                    {/*<Button style={{ color: "#1b3779" }}>
                      <DirectionsBusIcon />
                  </Button>*/}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <BootstrapDialog
          onClose={handleCloseDialogRoute}
          aria-labelledby="customized-dialog-title"
          open={dialogRoute}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDialogRoute}
            style={{ backgroundColor: "#1b3779", color: "white" }}
          >
            Vehicle Information
          </BootstrapDialogTitle>

          <DialogContent dividers>
            <table className="hostel" style={styles.tab}>
              <tbody>
                {showDetails ? (
                  <div>
                    <tr>
                      <td>School Name</td>
                      <td>Excellent English Medium Primary & High School</td>
                    </tr>
                    <tr>
                      <td>Transport Route Title</td>
                      <td>
                        {showDetails.routeStart}--{showDetails.routeEnd}
                      </td>
                    </tr>
                    <tr>
                      <td>Route Start</td>
                      <td>{showDetails.routeStart}</td>
                    </tr>
                    <tr>
                      <td>Route End</td>
                      <td>{showDetails.routeEnd}</td>
                    </tr>
                    <tr>
                      <td>Route Stop Fare</td>
                      <td>
                        <tr
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <th>Stop Name</th>
                          <th>Stop KM</th>
                          <th>Pick Time</th>
                          <th>Drop Time</th>
                        </tr>
                        {showDetails.routeDetails.map((bed, index) => (
                          <tr key={index}>
                            <td className="borderName1">{bed.stopName}</td>
                            <td>{bed.stopKM}</td>
                            <td>{bed.pickTime}</td>
                            <td>{bed.dropTime}</td>
                          </tr>
                        ))}
                      </td>
                    </tr>
                  </div>
                ) : null}
              </tbody>
            </table>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </div>
  );
};

export default Trips;
