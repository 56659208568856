import React, { useEffect, useState } from "react";
import css from "../../styles/Dashboard/Dashboard.module.css";
import Box from "@mui/material/Box";
import { get } from "../../services/apis";
const styles = {
  card: { margin: 15, padding: 10, marginBottom: "10px" },
  textfield: {
    width: "50%",
    label: { fontSize: 12 },
    m: 1,
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
    marginTop: "10px",
  },
  table: {
    width: "100%",
    marginTop: "15px",
    height: "100%",

    borderCollapse: "collapse",
    border: "1px solid black",
  },
  Paper: {
    margin: "6px 16px",
    width: "140px",
    height: "140px",
  },
};
export default function StudentRoutine() {
  const [routines, setRoutines] = useState([]);
  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  useEffect(() => {
    const getRoutines = async () => {
      try {
        const getRoutine = await get(`/student-dashboard/period-routine/list`, {
          params: {
            studentId: "6399d75729fa86b424ce3957",
          },
        });
        setRoutines(getRoutine.data.data);
      } catch (error) {}
    };
    getRoutines();
  }, []);

  return (
    <div>
      <table style={styles.table} className={css.alter}>
        <tr style={{ textAlign: "center" }}>
          <th
            style={{
              border: "1px solid black",
              width: "150px",
              padding: "10px",
              borderCollapse: "collapse",
            }}
          >
            Day
          </th>
          <th
            style={{
              border: "1px solid black",
              padding: "13px",
              borderCollapse: "collapse",
            }}
          >
            Routine
          </th>
        </tr>
        {routines.map((data) => (
          <tr style={{ border: "1px solid black" }}>
            <td
              style={{
                padding: "13px",
                border: "1px solid black",
                borderCollapse: "collapse",
              }}
            >
              {data.day}
            </td>
            <td
              style={{
                padding: "5px",
                border: "1px solid black",
                borderCollapse: "collapse",
              }}
            >
              <Box component="span" sx={{ p: 1.5, border: "1px solid grey" }}>
                {data.startTime}-{data.endTime}
              </Box>

              {/* <Box component="span" sx={{ p: 1.5, border: "1px solid grey" }}>
                ghghffdff
              </Box> */}
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
}
