import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  OutlinedInput,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Modal,
  Tooltip,
  Typography,
  InputAdornment,
  IconButton,
  TableContainer,
  Fab,
  TablePagination,
  Stack,
  Card,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import styled from "@emotion/styled";
// import { theme } from "../../theme";
import { Link, useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ClearIcon from "@mui/icons-material/Clear";
import Download from "@mui/icons-material/Download";
// custom imports
import AddCourse from "./AddCourse";

// Icons
import EditIcon from "@mui/icons-material/Edit";
import { CompressOutlined, Delete, Upload } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Search } from "@mui/icons-material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FilterListIcon from "@mui/icons-material/FilterList";

// apis
import { urls } from "../../../../services/urlConstant";

import { get, post, put, del } from "../../../../services/apis";
import deleteIcon from "../../../../assets/Images/deleteIcon.png";
import ModalDialog from "@mui/joy/ModalDialog";
import { async } from "q";
// import { theme } from "../../../../theme";
import { LoadingButton } from "@mui/lab";

const Label = styled("label")(() => ({
  fontWeight: "bold",
  paddingLeft: "10px",
  fontSize: "14px",
}));

const Heading = styled(TableCell)(() => ({
  fontWeight: "bold",
  textAlign: "center",
  color: "#ffff",
  backgroundColor: "#1b3779",
}));
const Data = styled(TableCell)(() => ({
  textAlign: "center",
}));
const DownloadBox = styled(Box)(() => ({
  background: `rgb(133 140 223 / 19%)`,
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "10px",
  //   color: `${theme.palette.primary.main}`,
}));
const Products = () => {
  const [open, setOpen] = React.useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [classes, setClasses] = useState([]);
  const [classe, setClass] = useState("");
  const [subject, setSubject] = useState("");
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [material, setMaterial] = useState(null);
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [editCourse, setEditCourse] = useState(null);
  const [courseId, setCourseId] = useState("");
  const [menuList, setMenuList] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState([]);
  const [materialURL, setMaterialURL] = useState();
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [deleteModal, setDeleteModal] = React.useState({
    open: false,
    itemToDelete: "",
  });
  const [uploadLoad, setUploadLoad] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);

  // filter pagination==========
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [sort, setSort] = useState({
    title: "asc",
    subject: "asc",
    courseHours: "asc",
  });

  useEffect(() => {
    if (classes.length > 0) {
      setSelectedMenu([classes[0]._id]);
    }
  }, [classes]);

  const [editFormData, setEditFormData] = useState({
    title: "",
    menu: "",
    pdu: "",
    courseHours: "",

    description: "",
    thumbnailImage: "",
    overview: [],
    selfPacedInfo: {
      earlyBirdEndDate: "",
      earlyBirdPrice: "",
      standardPrice: "",
    },

    benefits: [],

    isPaid: false,
    isTrending: false,
  });

  const handleNavigateCourse = (id) => {
    const selectedCourse = filteredCourses.find((course) => course._id === id);
    if (selectedCourse) {
      const selectedCourseTitle = selectedCourse.title;
      navigate("/dashboard/course-content", {
        state: {
          selectedCourseIds: id,
          selectedCourseTitle: selectedCourseTitle,
        },
      });
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;

    setSearch(value.trim());
    if (value.trim() !== "") {
      filteredCourses.length > 0 &&
        setSearchFilter(
          filteredCourses.filter((ele) =>
            ele.title.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);

        setClasses(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);

  const getCourse = async () => {
    try {
      const response = await get(`${urls.course.getList}`, {
        params: {
          search: { classIds: selectedMenu },
        },
      });
      const data = response;
      setCourseList(response.data.data);
      setFilteredCourses(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCourse();
  }, [selectedMenu, editCourse]);

  const handleChange = (event) => {
    setSelectedMenu(event.target.value);
  };

  const handleCourseEdit = (id, data) => {
    setEditCourse(id);
    setClass(data.class);
    setSubject(data.class.length === 1 ? data.subject._id : null);
    setEditFormData({
      title: data.title,

      isTrending: data.isTrending,
      courseHours: data.courseHours,

      description: data.description,
      thumbnailImage: data.thumbnailImage,
      overview: data.courseDetails.overview || [],

      benefits: data.courseDetails.benefits || [],

      courseId: editCourse,
    });
  };

  const handleClickOpen = (id, material) => {
    console.log(material, "materiaAAAl");
    setOpen(true);
    setCourseId(id);
    if (material) {
      const materialURLs = new URL(material);
      const fileName = decodeURIComponent(
        materialURLs.pathname.split("/").pop()
      );
      setMaterial(fileName);
    }
    setMaterialURL(material);
  };

  const handleClose = () => {
    setOpen(false);
    setMaterial(null);
  };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    setUploadLoad(true);
    try {
      const formData = new FormData();
      formData.append("file", file);

      const res = await put(
        `${urls.course.uploadCourseMaterial}/${courseId}`,
        "",
        formData
      );
      setUploadLoad(false);
      getCourse();
      handleClose();
    } catch (error) {
      console.error(error);
      setUploadLoad(false);
    }
    setUploadLoad(false);
  };

  const handleDownload = () => {
    try {
      window.open(materialURL, "_blank");
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteMaterial = async (e) => {
    e.preventDefault();
    try {
      const res = await put(
        `${urls.course.deleteCourseMaterial}/${courseId}`,
        "",
        {}
      );
      getCourse();
      setMaterial(null);
    } catch (error) {
      console.error(error);
    }
  };
  const handleCloseDeleteModal = () => {
    setDeleteModal({ open: false, itemToDelete: "" });
  };
  const handleDeleteone = async (e) => {
    e.preventDefault();
    try {
      const response = await del(
        `${urls.course.deleteCourse}`,
        `${deleteModal.itemToDelete}`,
        {}
      );

      setDeleteModal({ open: false, itemToDelete: "" });
      getCourse();
    } catch (error) {
      console.log(error);
    }
  };

  let addNewSetting = () => {
    navigate("/addCourse");
  };

  const sortByTitle = () => {
    if (sort.title == "asc") {
      let sortedList = [
        ...filteredCourses.sort((a, b) => b.title.localeCompare(a.title)),
      ];
      setFilteredCourses(sortedList);
      setSort({ ...sort, title: "des" });
    } else {
      let sortedList = [
        ...filteredCourses.sort((a, b) => a.title.localeCompare(b.title)),
      ];
      setFilteredCourses(sortedList);
      setSort({ ...sort, title: "asc" });
    }
  };
  const sortByCourseHour = () => {
    console.log(sort, "klklkl");
    if (sort.courseHours == "asc") {
      let sortedList = [
        ...filteredCourses.sort((a, b) => b.courseHours - a.courseHours),
      ];
      setFilteredCourses(sortedList);
      setSort({ ...sort, courseHours: "des" });
    } else {
      let sortedList = [
        ...filteredCourses.sort((a, b) => a.courseHours - b.courseHours),
      ];
      setFilteredCourses(sortedList);
      setSort({ ...sort, courseHours: "asc" });
    }
  };
  const sortBySubject = () => {
    if (sort.subject == "asc") {
      let sortedList = [
        ...filteredCourses.sort((a, b) =>
          b.subject.subjectName.localeCompare(a.subject.subjectName)
        ),
      ];
      setFilteredCourses(sortedList);
      setSort({ ...sort, subject: "des" });
    } else {
      let sortedList = [
        ...filteredCourses.sort((a, b) =>
          a.subject.subjectName.localeCompare(b.subject.subjectName)
        ),
      ];
      setFilteredCourses(sortedList);
      setSort({ ...sort, subject: "asc" });
    }
  };

  return (
    <Box sx={{ margin: "15px" }}>
      {editCourse !== null ? (
        <AddCourse
          edit={editCourse}
          classe={classe}
          subjectId={subject}
          formData={editFormData}
          setEditCourse={setEditCourse}
          courses={filteredCourses}
        />
      ) : (
        <>
          <Card style={{ padding: 10 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} mg={12} lg={12}>
                <span
                  style={{
                    color: "red",
                    fontFamily: "cursive",
                    textAlign: "start",
                  }}
                >
                  Courses:
                </span>
              </Grid>
            </Grid>
          </Card>

          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "10px",
              mt: 3,
              mb: 1,
            }}
          >
            <Grid item xs={12} sm={4} md={3}>
              <FormControl fullWidth size="small" required>
                <InputLabel>Class</InputLabel>
                <Select
                  size="small"
                  multiple
                  variant="outlined"
                  label="class"
                  labelId="demo-simpless-select-filled-label"
                  id="demo-simple-select-filled-l"
                  name="schoolClass"
                  value={selectedMenu}
                  onChange={handleChange}
                >
                  {classes &&
                    classes.map((row, index) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.className}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <FormControl size="small" fullWidth>
                <TextField
                  placeholder="Search Course Name"
                  size="small"
                  fullWidth
                  value={search}
                  onChange={handleSearch}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" type="submit">
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <Heading>S.No</Heading>
                  <Heading>Class</Heading>
                  <Heading onClick={sortByTitle} className="class-table-header">
                    Course Name
                    <span style={{ cursor: "pointer" }}>
                      {sort.title === "asc" ? "▲" : "▼"}
                    </span>
                  </Heading>
                  <Heading
                    onClick={sortBySubject}
                    className="class-table-header"
                  >
                    Subject{" "}
                    <span style={{ cursor: "pointer" }}>
                      {sort.subject === "asc" ? "▲" : "▼"}
                    </span>
                  </Heading>
                  <Heading
                    onClick={sortByCourseHour}
                    className="class-table-header"
                  >
                    Duration
                    <span style={{ cursor: "pointer" }}>
                      {sort.courseHours === "asc" ? "▲" : "▼"}
                    </span>
                  </Heading>
                  <Heading>Content</Heading>
                  <Heading>Material</Heading>
                  <Heading>Action</Heading>
                </TableRow>
              </TableHead>
              <TableBody>
                {search
                  ? searchFilter
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((course, index) => (
                        <TableRow key={course._id}>
                          <Data>{index + 1}</Data>
                          <Data>
                            {course.class.map((c) => c.className).join(" | ")}
                          </Data>
                          <Data>{course.title}</Data>
                          <Data>
                            {course.class.length > 1
                              ? "Universal"
                              : course.subject.subjectName}
                          </Data>
                          <Data>{course.courseHours}</Data>
                          <Data>
                            <Tooltip title="Upload course">
                              <Button
                                onClick={() => handleNavigateCourse(course._id)}
                              >
                                Upload
                              </Button>
                            </Tooltip>
                          </Data>

                          <Data>
                            <Tooltip title="Upload Material">
                              <Button
                                onClick={() =>
                                  handleClickOpen(course._id, course.material)
                                }
                              >
                                <FileUploadIcon />
                              </Button>
                            </Tooltip>
                          </Data>
                          <Data>
                            <Tooltip title="Update course">
                              <IconButton
                                size="small"
                                onClick={() =>
                                  handleCourseEdit(course._id, course)
                                }
                                sx={{ color: "#1b3779" }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton
                                color="error"
                                size="small"
                                onClick={() =>
                                  setDeleteModal({
                                    itemToDelete: course._id,
                                    open: true,
                                  })
                                }
                              >
                                <Delete color="error" fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Data>
                        </TableRow>
                      ))
                  : filteredCourses
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((course, index) => (
                        <TableRow key={course._id}>
                          <Data>{page * rowsPerPage + index + 1}</Data>
                          <Data>
                            {course.class.map((c) => c.className).join(" | ")}
                          </Data>
                          <Data>{course.title}</Data>
                          <Data>
                            {course.class.length > 1
                              ? "Universal"
                              : course.subject.subjectName}
                          </Data>
                          <Data>{course.courseHours}</Data>
                          <Data>
                            <Button
                              onClick={() => handleNavigateCourse(course._id)}
                            >
                              Upload
                            </Button>
                          </Data>

                          <Data>
                            <Tooltip title="Upload Material">
                              <Button
                                onClick={() =>
                                  handleClickOpen(course._id, course.material)
                                }
                              >
                                <FileUploadIcon />
                              </Button>
                            </Tooltip>
                          </Data>

                          <Data>
                            <Tooltip title="Update course">
                              <Button
                                onClick={() =>
                                  handleCourseEdit(course._id, course)
                                }
                                sx={{ color: "#1b3779" }}
                              >
                                <EditIcon fontSize="small" />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton
                                color="error"
                                size="small"
                                onClick={() =>
                                  setDeleteModal({
                                    itemToDelete: course._id,
                                    open: true,
                                  })
                                }
                              >
                                <Delete color="error" fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Data>
                        </TableRow>
                      ))}

                <TableRow>
                  {!filteredCourses.length && !search.trim() && (
                    <Data align="center" colSpan={12}>
                      <Typography variant="h6" align="center">
                        No data found
                      </Typography>
                    </Data>
                  )}
                  {search.trim() && !searchFilter.length && (
                    <Data align="center" colSpan={12}>
                      <Typography variant="h6" align="center">
                        No data found
                      </Typography>
                    </Data>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={search ? searchFilter.length : filteredCourses.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              my: 1,
            }}
          />
          <Box className="add-icon">
            <Tooltip title="Add Courses">
              <Fab
                variant="contained"
                sx={{
                  color: "#ffff",

                  background: "rgb(27, 55, 121)",
                  ":hover": { background: "rgb(27, 55, 121)" },
                }}
                onClick={() => addNewSetting()}
              >
                <AddIcon />
              </Fab>
            </Tooltip>
          </Box>
        </>
      )}

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{ fontSize: "20px", fontWeight: "bold" }}
        >
          {"Upload Your Material"} {filteredCourses.material}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <input type="file" onChange={handleFileChange} />
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          {material ? (
            <DownloadBox px={2} py={1}>
              <Typography>{material}</Typography>
              <Stack direction={"row"}>
                {material ? (
                  <Button size="small" onClick={handleDownload} color="error">
                    <Download fontSize="small" color="primary" />
                  </Button>
                ) : null}

                <Button
                  size="small"
                  onClick={handleDeleteMaterial}
                  color="error"
                >
                  <ClearIcon fontSize="small" />
                </Button>
              </Stack>
            </DownloadBox>
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            loading={uploadLoad}
            onClick={handleUpload}
            autoFocus
          >
            Upload
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={deleteModal.open}
        onClose={handleCloseDeleteModal}
      >
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          sx={{
            background: "#fff",
            border: "none",
          }}
        >
          <Box sx={{ textAlign: "center", margin: "10px auto" }}>
            <img src={deleteIcon} width={50} height={50} />
          </Box>

          <Box p={2}>
            <Typography
              id="alert-dialog-modal-description"
              textAlign="center"
              my={1}
            >
              Are you sure you want to remove this?
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 2,
                mt: 2,
              }}
            >
              <Button
                variant="plain"
                color="neutral"
                size="small"
                onClick={handleCloseDeleteModal}
                sx={{
                  background: "#ddd",
                  ":hover": { background: "#ddd", opacity: 0.8 },
                  objectFit: "contain",
                }}
              >
                No,Cancel
              </Button>

              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={(e) => handleDeleteone(e)}
              >
                Yes, I'm Sure
              </Button>
            </Box>
          </Box>
        </ModalDialog>
      </Modal>
    </Box>
  );
};

export default Products;
