import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  Paper,
  Typography,
  styled,
} from "@mui/material";

import { get } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";

const StorageStye = styled(Typography)(() => ({
  border: "1px solid lightGrey",
  fontWeight: "600",
  padding: 10,
  borderRadius: 5,
  textAlign: "center",
}));

const Storage = () => {
  const [storageDetails, setStorageDetails] = useState({
    totalStorage: 0,
    usedStorage: 0,
  });

  const getStorageDetails = async () => {
    try {
      const { data } = await get(urls.fileStorage.getDetails);
      setStorageDetails(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStorageDetails();
  }, []);
  return (
    <>
      <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span
              style={{
                color: "red",
                fontFamily: "cursive",
                textAlign: "start",
              }}>
              Storage:
            </span>
          </Grid>
        </Grid>
      </Card>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 1,
        }}>
        <Paper
          sx={{
            padding: 4,
            width: { xs: "100%", sm: "70%", md: "50%", lg: "40%" },
          }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <StorageStye>
                Total Storage:{" "}
                <span style={{ color: "#607d8b", fontWeight: "bold" }}>
                  {storageDetails.totalStorage}
                </span>{" "}
              </StorageStye>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <StorageStye>
                Used Storage:{" "}
                <span style={{ color: "#607d8b", fontWeight: "bold" }}>
                  {storageDetails.usedStorage}
                </span>{" "}
              </StorageStye>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

export default Storage;
