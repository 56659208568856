import React from "react";
import { Modal, Typography, Divider, Box, Button } from "@mui/material";
import { WarningRounded } from "@mui/icons-material";
import ModalDialog from "@mui/joy/ModalDialog";
import deleteIcon from "../../../assets/Images/deleteIcon.png";

const DeleteModal = (props) => {
  return (
    <Modal
      aria-labelledby="modal-content-container"
      aria-describedby="modal-modal-description"
      open={props.deleteModal === props.id}
      onClose={() => props.setDeleteModal(false)}>
      <ModalDialog
        variant="outlined"
        role="alertdialog"
        sx={{
          background: "#fff",
          border: "none",
        }}>
        <Box sx={{ textAlign: "center", margin: "10px auto" }}>
          <img src={deleteIcon} width={50} height={50} />
        </Box>

        <Box p={2}>
          <Typography
            id="alert-dialog-modal-description"
            textAlign="center"
            my={1}>
            Are you sure you want to remove this?
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
              mt: 2,
            }}>
            <Button
              variant="plain"
              color="neutral"
              size="small"
              onClick={() => props.setDeleteModal(false)}
              sx={{
                background: "#ddd",
                ":hover": { background: "#ddd", opacity: 0.8 },
                objectFit: "contain",
              }}>
              No,Cancel
            </Button>

            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={() => {
                props.handleDelete(props.id, props.routineIndex);
                props.setDeleteModal(false);
              }}>
              Yes, I'm Sure
            </Button>
          </Box>
        </Box>
      </ModalDialog>
    </Modal>
  );
};

export default DeleteModal;
