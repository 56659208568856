import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "./../styles/components/navbar/Navbar.scss";
import { Language } from "@mui/icons-material";
import "./../styles/components/navbar/NavTop.scss";
import css from "./Addmission.module.css";
import Footer from "./Footer/Footer";
import TopNav from "./navbar/TopNav";
import Navbar from "./navbar/Navbar";
import Demo from "./NewsNotic/Demo";

const HighSchool = () => {
  const [addClass, updateClass] = useState(false);
  const [stkyClass, setStkyClass] = useState(false);
  const [mega, setMega] = useState(false);

  const handleClickCross = () => {
    updateClass(false);
    window.scrollTo(0, 0);
  };
  const scrollHandleScroll = () => {
    if (window.scrollY > 20) {
      setStkyClass(true);
    } else {
      setStkyClass(false);
    }
  };
  const handleMega = () => {
    setMega(!mega);
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollHandleScroll);
  }, []);

  const menuItems = [
    {
      title: "Home",
      children: [],
    },
    {
      title: "About",
      children: [
        {
          title: "Our School",
          link: "/our-school",
        },
        {
          title: "Principal Desk",
          link: "/principal-desk",
        },
        {
          title: "Faculty",
          link: "/faculty",
        },
        {
          title: "Guardian Feedback",
          link: "/guardian-feedback",
        },
      ],
    },
    {
      title: "Gallery",
      children: [],
    },
    {
      title: "Academics",
      children: [],
    },
    {
      title: "Facilities",
      children: [
        {
          title: "Library",
          link: "/library",
        },
        {
          title: "Sports",
          link: "/sports",
        },
        {
          title: "Canteen",
          link: "/canteen",
        },
        {
          title: "Transport",
          link: "/transport",
        },
        {
          title: "Edusmart",
          link: "/Edusmart",
        },
      ],
    },
    {
      title: "Jobs",
      children: [],
    },
    {
      title: "Exam",
      children: [
        {
          title: "Exam Time Table",
          link: "/birth-day",
        },
        {
          title: "Hall Ticket",
          link: "/annual-day",
        },
        {
          title: "Results",
          link: "/freshers-day",
        },
      ],
    },
    {
      title: "Contact Us",
      children: [],
    },
  ];

  return (
    <>
      <TopNav />
      <Navbar />
      <div className={css.headerContainer}>
        <div>
          <h1>High School</h1>
          <h5>
            <Link
              style={{
                color: "white",
              }}
              to="/"
            >
              Home
            </Link>{" "}
            / High School
          </h5>
        </div>
      </div>

      <Grid container spacing={12} marginLeft={"-76px"}>
        <Grid
          items
          xs={12}
          mg={12}
          lg={8}
          style={{
            margin: "40px 0px 20px 0px",
            "border-right": "1px solid #BDBDBD",
          }}
        >
          <p style={{ margin: "0px 20px 20px 24px", fontSize: "1.11rem" }}>
            We just not teach but test skills and aptitude. We scrutinize
            his/her mettle, temperament and soul. We provide the safe and secure
            environment and engage the students in exciting activities. The
            school is the place that you can reckon with where your students
            will spend quality time. We prepare them to learn the right with
            lots of funs. You can see glee and grins when they return to home.
            <br></br>
            We aim to explore the potential of the individual and prepare them
            to face every challenges and rigors of the life and emerge as a
            proud citizens of the country. Our vision is to impart education
            with a view to create bright individuals, who may contribute their
            might for the development of country.
          </p>
        </Grid>
        <Grid
          items
          xs={12}
          mg={12}
          lg={4}
          style={{ height: "80vh", paddingTop: "40px" }}
        >
          <Demo />
        </Grid>
      </Grid>
      {/* <div className={css.contentContainer}>
          <div className={css.content}>
          <div className={css.contentHolder}>
                    <div className={css.contentText}>
                    We just not teach but test skills and aptitude. We scrutinize his/her mettle, temperament and soul. We provide the safe and secure environment and engage the students in exciting activities. The school is the place that you can reckon with where your students will spend quality time. We prepare them to learn the right with lots of funs. You can see glee and grins when they return to home.
                    </div>
                </div>
                <div className={css.contentHolder}>
                    <div className={css.contentText}>
                    We aim to explore the potential of the individual and prepare them to face every challenges and rigors of the life and emerge as a proud citizens of the country. Our vision is to impart education with a view to create bright individuals, who may contribute their might for the development of country.
                    </div>
                </div>
          </div>
          <div className={css.newsNoticeContainer}>
            <div className={css.newsNotice}>
                <Demo />
            </div>
        </div>
      </div> */}
      <Footer />
    </>
  );
};

export default HighSchool;
