import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import {
  Box,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Delete } from "@mui/icons-material";
import RemoveIcon from "@mui/icons-material/Remove";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { get, post, put, del } from "../../services/apis";
import { urls } from "../../services/urlConstant";
import DeleteModal from "../Layout/Academic/DeleteModal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { add } from "date-fns";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
// function TableRows({ rows, tableRowRemove, onValUpdate }) {
//   return rows.map((rowsData, index) => {
//     const { bedname, bedposition } = rowsData;
//     return (
//       <tr key={index}>
//         <td>
//           <input
//             type="text"
//             value={bedname}
//             onChange={(event) => onValUpdate(index, event)}
//             name="name"
//             placeholder="Bed Name"
//             style={{ fontSize: "12px", padding: "4px" }}
//           />
//         </td>
//         <td>
//           <input
//             type="text"
//             value={bedposition}
//             onChange={(event) => onValUpdate(index, event)}
//             name="position"
//             placeholder="Bed Position"
//             style={{ fontSize: "12px", padding: "4px" }}
//           />
//         </td>

//         <td>
//           <button
//             className="btn btn-danger"
//             onClick={() => tableRowRemove(index)}
//           >
//             <RemoveIcon />
//           </button>
//         </td>
//       </tr>
//     );
//   });
// }
function TableRows({ rows, tableRowRemove, onValUpdate }) {
  return rows
    ? rows.map((rowData, index) => {
        const { name, position } = rowData;

        const handleValueUpdate = (event) => {
          onValUpdate(index, event);
        };

        return (
          <tr key={index}>
            <td>
              <input
                type="text"
                value={name}
                onChange={handleValueUpdate}
                name="name"
                placeholder="Bed Name"
                style={{ fontSize: "12px", padding: "4px" }}
              />
            </td>
            <td>
              <input
                type="text"
                value={position}
                onChange={handleValueUpdate}
                name="position"
                placeholder="Bed Position"
                style={{ fontSize: "12px", padding: "4px" }}
              />
            </td>
            <td>
              <button
                className="btn btn-danger"
                onClick={(e) => tableRowRemove(index, e)}
              >
                <RemoveIcon />
              </button>
            </td>
          </tr>
        );
      })
    : "";
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 550,
  bgcolor: "background.paper",
  p: 4,
};

const ManageRoom = () => {
  const [rows, setRows] = useState([]);
  const [addForm, setAddForm] = useState({
    number: 0,
    totalSeats: 0,
    beds: [],
    type: "",
    note: "",
    hostel: "",
  });
  const [search, setSearch] = useState("");
  const [open, setOpen] = React.useState(false);
  const [dialog, setOpenDialog] = React.useState(false);
  const [editRoom, setEditRoom] = useState();
  const [list, setList] = React.useState([]);
  const [hostelName, setHostelName] = React.useState([]);
  const [roomType, setRoomType] = React.useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [searchFilter, setSearchFilter] = useState([]);
  const [showDetails, setShowDetails] = useState(null);
  const addRowTable = (e) => {
    e.preventDefault();
    const data = {
      name: "",
      position: "",
      alloted: false,
    };
    let initialBedList = [...addForm.beds, data];
    setAddForm({ ...addForm, beds: initialBedList });

    console.log(initialBedList, "ininininininin");
    // setRows([...rows, data]);
  };
  const tableRowRemove = (index, e) => {
    e.preventDefault();
    // const dataRow = [...rows];
    // dataRow.splice(index, 1);
    let newbedList = addForm.beds.filter((item, i) => i !== index);
    setAddForm({ ...addForm, beds: newbedList });
    // setRows(dataRow);
  };
  const onValUpdate = (i, event) => {
    event.preventDefault();
    const { name, value } = event.target;

    let initialBed = [...addForm.beds];
    let newBeds = [];
    for (let b of initialBed) {
      if (initialBed.indexOf(b) == i) {
        let newItem = { ...b, [name]: value };
        newBeds.push(newItem);
      } else {
        newBeds.push(b);
      }
    }
    setAddForm({ ...addForm, beds: newBeds });

    // const { name, value } = event.target;
    // const data = [...rows];
    // data[i][name] = value;
    // setRows(data);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setAddForm({
      number: 0,
      totalSeats: 0,
      beds: [],
      type: "",
      note: "",
      hostel: "",
    });
    setRows([]);
    setOpen(false);
    setEditRoom(null);
  };

  const handleClickOpenDialog = (room) => {
    setShowDetails(room);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setShowDetails(null);
    setOpenDialog(false);
  };

  const styles = {
    textfield: {
      marginTop: "25px",
      width: "70%",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      list.length > 0 &&
        setSearchFilter(
          list.filter((ele) =>
            ele.hostel.name.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!editRoom) {
        const { status } = await post(`${urls.room.create}`, {
          number: addForm.number,
          type: addForm.type,
          hostel: addForm.hostel,
          beds: addForm.beds,
          note: addForm.note,
          totalSeats: addForm.totalSeats,
        });
        setAddForm({
          number: 0,
          totalSeats: 0,
          beds: [],
          type: "",
          note: "",
          hostel: "",
        });

        getRoom();
        handleClose();
      } else {
        const { status } = await put(`${urls.room.editRoom}${editRoom}`, "", {
          number: addForm.number,
          type: addForm.type,
          hostel: addForm.hostel,
          beds: addForm.beds,
          note: addForm.note,
          totalSeats: addForm.totalSeats,
        });
        setAddForm({
          number: 0,
          totalSeats: 0,
          beds: [],
          type: "",
          note: "",
          hostel: "",
        });

        getRoom();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRoom();
  }, []);

  const getRoom = async () => {
    try {
      const rooms = await get(`${urls.room.getList}`);

      setList(rooms.data.data);

      console.log(rooms.data.data, "hostellluuuu");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getHostelNames = async () => {
      try {
        const { data, status } = await get(`${urls.hostel.getList}`);
        if (status > 199 && status < 299) {
          setHostelName(data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getHostelNames();
  }, []);

  useEffect(() => {
    const getRoomTypes = async () => {
      try {
        const { data, status } = await get(`${urls.roomType.getList}`, {
          params: {
            search: {},
            pageNumber: 1,
            limit: 1000,
          },
        });

        if (status > 199 && status < 299) {
          setRoomType(data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getRoomTypes();
  }, []);

  const handleAddForm = (event) => {
    const { name, value } = event.target;
    setAddForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleClassMapEdit = (id, data) => {
    setEditRoom(id);
    // console.log(data.beds, "bedddhvg88888");
    // let newRows = [];
    // for(let bed of data.beds) {
    //   let newBed = {
    //     bedname:bed.name,
    //     bedposition: bed.position,
    //     alloted:bed.alloted
    //   }
    //   newRows.push(newBed)
    // }
    // setRows(newRows)
    setAddForm({
      number: data.number,
      type: data.type._id,
      hostel: data.hostel._id,
      beds: data.beds || [],
      note: data.note,
      totalSeats: data.totalSeats,
    });
    handleClickOpen();
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(`${urls.room.delRoom}`, id);

      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span
              style={{
                color: "red",
                fontFamily: "cursive",
                textAlign: "start",
              }}
            >
              Hostel Room:
            </span>
          </Grid>
        </Grid>
      </Card>
      <div style={{ margin: "15px" }}>
        <Paper sx={{ width: "100%", height: "60px", overflow: "hidden" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid xs={3}>
                <TextField
                  size="small"
                  style={styles.textfield}
                  value={search}
                  onChange={handleSearch}
                  label="Search"
                  variant="outlined"
                  placeholder="enter hostel name"
                />
              </Grid>
              <Grid>
                <Button
                  variant="contained"
                  sx={{ mt: 3.2 }}
                  style={styles.addbutton}
                  onClick={handleClickOpen}
                >
                  <AddIcon />
                  Add Room
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form className="form-tag">
            <Box sx={style} style={{ overflowY: "scroll" }}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign="center"
                marginBottom="30px"
              >
                {editRoom ? "Update Room & Bed" : "Add Room & Bed"}
              </Typography>
              <div>
                <TextField
                  id="filled-required"
                  variant="standard"
                  label="Room No"
                  name="number"
                  value={addForm.number || ""}
                  onChange={handleAddForm}
                  sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                />
                <FormControl variant="standard" sx={{ m: 0.5, minWidth: 227 }}>
                  <InputLabel required sx={{ fontSize: 12 }}>
                    Room Type
                  </InputLabel>
                  <Select
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    name="type"
                    value={addForm.type || ""}
                    onChange={handleAddForm}
                  >
                    {roomType.map((name) => (
                      <MenuItem key={name._id} value={name._id}>
                        {name.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  required
                  name="totalSeats"
                  id="filled-required"
                  variant="standard"
                  label="Seat Total"
                  value={addForm.totalSeats || ""}
                  onChange={handleAddForm}
                  sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                />

                <div>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 227 }}>
                    <InputLabel required sx={{ fontSize: 12 }}>
                      Hostel Name
                    </InputLabel>
                    <Select
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      label="Hostel Name"
                      name="hostel"
                      onChange={handleAddForm}
                      value={addForm.hostel || ""}
                    >
                      {hostelName.map((name) => (
                        <MenuItem key={name._id} value={name._id}>
                          {name.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <table className="table table-striped">
                    <thead
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontSize: "13px",
                        marginTop: "10px",
                      }}
                    >
                      <p>Bed Name</p>
                      <p>Bed Position</p>
                      <p>Is Alloted</p>
                    </thead>

                    <tbody>
                      <TableRows
                        rows={addForm.beds}
                        tableRowRemove={tableRowRemove}
                        onValUpdate={onValUpdate}
                      />
                    </tbody>
                    <button
                      style={{
                        backgroundColor: "orange",
                        color: "white",
                        marginTop: "10px",
                        fontSize: "16px",
                        padding: "8px 16px",
                        borderRadius: "4px",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onClick={addRowTable}
                    >
                      Add more
                    </button>
                  </table>
                </div>

                <div>
                  <TextareaAutosize
                    label="Note"
                    name="note"
                    value={addForm.note || ""}
                    onChange={handleAddForm}
                    placeholder="Drop a note"
                    style={{
                      width: 712,
                      maxHeight: 110,
                      margin: 8,
                      padding: 10,
                      height: 118,
                      overflow: "auto",
                    }}
                  />
                  <div style={{ textAlign: "end" }}>
                    <Tooltip title="Cancel">
                      <Button
                        onClick={handleClose}
                        variant="outlined"
                        size="medium"
                        sx={{ m: 5 }}
                      >
                        Cancel
                      </Button>
                    </Tooltip>
                    <Tooltip title="Submit">
                      <Button
                        variant="contained"
                        size="medium"
                        onClick={handleAddSubmit}
                        sx={{
                          background: "rgb(27, 55, 121)",
                          ":hover": { background: "rgb(27, 55, 121)" },
                        }}
                      >
                        {editRoom ? "update" : "Add"}
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </Box>
          </form>
        </Modal>

        <Table>
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow sx={{ color: "white" }}>
              <TableCell sx={{ color: "white" }} align="center">
                #SL
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Room No
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Room Type
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Seat Total
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Hostel Name
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {search
              ? searchFilter.map((room, index) => (
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{room.number}</TableCell>
                    <TableCell align="center">{room.type.name}</TableCell>
                    <TableCell align="center">{room.totalSeats}</TableCell>
                    <TableCell align="center">{room.hostel.name}</TableCell>
                    <TableCell align="center">
                      <div style={{ marginLeft: "30px" }}>
                        <Button
                          style={{
                            color: "#1b3779",
                          }}
                          onClick={() => handleClassMapEdit(room._id, room)}
                        >
                          <EditIcon />
                        </Button>
                        <Button
                          style={{
                            color: "#1b3779",
                          }}
                          onClick={() => handleClickOpenDialog(room._id)}
                        >
                          <VisibilityIcon />
                        </Button>

                        <Button
                          style={{
                            color: "#1b3779",
                          }}
                          onClick={() => setDeleteModal(room._id, room)}
                        >
                          <Delete />
                        </Button>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={room._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              : list.map((room, index) => (
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{room.number}</TableCell>
                    <TableCell align="center">{room.type.name}</TableCell>
                    <TableCell align="center">{room.totalSeats}</TableCell>
                    <TableCell align="center">{room.hostel.name}</TableCell>
                    <TableCell align="center">
                      <div style={{ marginLeft: "30px" }}>
                        <Button
                          style={{
                            color: "#1b3779",
                          }}
                          onClick={() => handleClassMapEdit(room._id, room)}
                        >
                          <EditIcon />
                        </Button>
                        <Button
                          style={{
                            color: "#1b3779",
                          }}
                          onClick={() => handleClickOpenDialog(room)}
                        >
                          <VisibilityIcon />
                        </Button>

                        <Button
                          style={{
                            color: "#1b3779",
                          }}
                          onClick={() => setDeleteModal(room._id, room)}
                        >
                          <Delete />
                        </Button>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={room._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
        {!list.length && !search.trim() && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        {search.trim() && !searchFilter.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}

        <BootstrapDialog
          onClose={handleCloseDialog}
          aria-labelledby="customized-dialog-title"
          open={dialog}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: "#1b3779", color: "white" }}
          >
            Room Information
          </BootstrapDialogTitle>

          <DialogContent dividers>
            <table className="hostel" style={styles.tab}>
              <tbody>
                {showDetails ? (
                  <div>
                    <tr>
                      <td>School Name</td>
                      <td>Excellent English Medium Primary & High School</td>
                    </tr>
                    <tr>
                      <td>Room No</td>
                      <td>{showDetails.number}</td>
                    </tr>
                    <tr>
                      <td>Seat Total</td>
                      <td>{showDetails.totalSeats}</td>
                    </tr>
                    <tr>
                      <td>Hostel Name</td>
                      <td>{showDetails.hostel.name}</td>
                    </tr>
                    <tr>
                      <td>Bed</td>
                      <td>
                        <tr>
                          <th className="borderName">Bed Name</th>
                          <th className="borderPosition">Bed Position</th>
                          {/* <th>Bed Allocated</th> */}
                        </tr>
                        {showDetails.beds.map((bed, index) => (
                          <tr key={index}>
                            <td>{bed.name}</td>
                            <td>{bed.position}</td>
                            {/* <td>{bed.allocated?"Yes":"No"}</td> */}
                          </tr>
                        ))}
                      </td>
                    </tr>

                    <tr>
                      <td>Note</td>
                      <td>{showDetails.note}</td>
                    </tr>
                    <tr>
                      <td>Created</td>
                      <td>{showDetails.type.createdAt}</td>
                    </tr>
                  </div>
                ) : null}
              </tbody>
            </table>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </div>
  );
};
export default ManageRoom;
