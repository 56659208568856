import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useEffect, useState } from "react";
import { get } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";

const styles = {
  card: {
    padding: 15,
    marginBottom: 20,
  },
  textfield: {
    width: "100%",
    fontSize: 12,
    m: 1,
  },
};

const HallTicket = () => {
  const [examTerms, setExamTerms] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [formData, setFormData] = useState({});
  const [loadngBtn, setLoadingBtn] = useState(false);

  const handleClasses = async (e) => {
    try {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
      const res = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: value,
          },
        },
      });
      setSections(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const getExamTitle = async () => {
      try {
        const res = await get(urls.examTerm.getAllExamTerm);
        setExamTerms(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getExamTitle();
  }, []);

  useEffect(() => {
    const getClassName = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        setClasses(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getClassName();
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoadingBtn(true);
    try {
      const hallTicket = await get(
        `/hallticket/exam-schedule/exam=term/${formData.exam}/class/${formData.schoolClass}/section/${formData.section}`,
        {
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(hallTicket.data);
      setLoadingBtn(false);
      window.open(uri, "__blank");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Card style={{ margin: 15, padding: 10 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <span
                style={{
                  color: "red",
                  fontFamily: "cursive",
                }}>
                Hall Ticket :
              </span>
            </Grid>
          </Grid>
        </Box>
      </Card>
      <div style={{ margin: 15 }}>
        <Card style={styles.card}>
          <Box>
            <form onSubmit={handleFormSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl
                    variant="standard"
                    style={styles.textfield}
                    required>
                    <InputLabel id="demo-simple-select-filled-label">
                      Exam
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      onChange={handleOnChange}
                      value={formData.exam || ""}
                      name="exam">
                      {examTerms &&
                        examTerms.map((row, index) => (
                          <MenuItem key={row._id} value={row._id}>
                            {row.examTitle}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl
                    variant="standard"
                    style={styles.textfield}
                    required>
                    <InputLabel id="demo-simple-select-filled-label">
                      Class
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="schoolClass"
                      value={formData.schoolClass || ""}
                      onChange={(event) => handleClasses(event)}>
                      {classes &&
                        classes.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}>
                            {row.className}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl
                    variant="standard"
                    style={styles.textfield}
                    required>
                    <InputLabel
                      id="demo-simple-select-filled-label"
                      sx={{ fontSize: 12 }}>
                      Section
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="section"
                      value={formData.section || ""}
                      onChange={(event) => handleOnChange(event)}>
                      {sections &&
                        sections.map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}>
                            {row.sectionName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  style={{ alignSelf: "flex-end" }}
                  xs={12}
                  md={6}
                  lg={2}>
                  <Tooltip title="Generate">
                    <LoadingButton
                      variant="contained"
                      size="small"
                      type="submit"
                      loading={loadngBtn}
                      sx={{
                        backgroundColor: "#1b3779",
                        ":hover": { backgroundColor: "#1b3779" },
                      }}>
                      Generate
                    </LoadingButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Card>
      </div>
    </div>
  );
};

export default HallTicket;
