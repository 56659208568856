import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import "../../styles/HostelTable/HostelTable.scss";
import {
  Box,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Delete } from "@mui/icons-material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { get, post, put, del } from "../../services/apis";
import { urls } from "../../services/urlConstant";
import DeleteModal from "../Layout/Academic/DeleteModal";

import { useContext } from "react";
import SettingContext from "../../context/SettingsContext";
import dayjs from "dayjs";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 460,
  bgcolor: "background.paper",
  p: 4,
};

const Hostel = () => {
  const [search, setSearch] = useState("");
  const [open, setOpen] = React.useState(false);
  const [dialog, setOpenDialog] = React.useState(false);
  const [editHostel, setEditHostel] = useState();
  const [addForm, setAddForm] = useState({});
  const [hostel, setHostel] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [type, setType] = React.useState("");
  const [list, setList] = React.useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [searchFilter, setSearchFilter] = useState([]);
  const [showDetails, setShowDetails] = useState(null);
  const { settingsContext } = useContext(SettingContext);
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;
  useEffect(() => {
    getHostel();
  }, []);
  useEffect(() => {
    const getList = async () => {
      try {
        const getEmps = await get(urls.hostel.getList);
        setList(getEmps.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getList();
  }, []);

  useEffect(() => {
    const getWarden = async () => {
      try {
        const { data: role, status } = await get(`${urls.role.getAllRole}`, {
          params: {
            search: {
              roleName: "Warden",
            },
          },
        });
        if (status > 199 && status < 299) {
          const { data: employees, status } = await get(
            `${urls.employee.getAllEmployee}`,
            {
              params: {
                search: {
                  role: role.data[0]?._id,
                },
              },
            }
          );
          if (status > 199 && status < 299) {
            setEmployees(employees.data);
            console.log(employees.data, "warden");
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getWarden();
  }, []);

  const getHostel = async () => {
    try {
      const { data: hostels, status } = await get(`${urls.hostel.getList}`);

      setList(hostels.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(`${urls.hostel.delHostel}`, id);
      if (res.data.success) {
        const filteredEvent = list.filter((item) => item._id !== id);
        setList(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setEditHostel(null);
    setAddForm({});
    setOpen(false);
  };

  const handleAddForm = (e) => {
    const { name, value } = e.target;
    setAddForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClickOpenDialog = (hostel) => {
    setShowDetails(hostel);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setShowDetails(null);
    setOpenDialog(false);
  };

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!editHostel) {
        const { status } = await post(`${urls.hostel.create}`, {
          name: addForm.name,
          type: addForm.type,
          warden: addForm.warden,
          note: addForm.note,
          address: addForm.address,
          contactNumber: addForm.contactNumber,
        });
        getHostel();
        handleClose();
      } else {
        const { status } = await put(
          `${urls.hostel.editHostel}${editHostel}`,
          "",
          {
            name: addForm.name,
            type: addForm.type,
            warden: addForm.warden,
            note: addForm.note,
            address: addForm.address,
            contactNumber: addForm.contactNumber,
          }
        );
        getHostel();
        handleClose();
        // if (status > 199 && status < 299) {
        //    getHostel();
        //   handleClose();
        // }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const styles = {
    button: {
      backgroundColor: "orange",
    },
    textfield: {
      marginTop: "25px",
      width: "70%",
    },
    addbutton: {
      paddingTop: "6.2px",
      paddingBottom: "6.2px",
    },
    tab: {
      width: "100%",
      borderCollapse: "collapse",
    },
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      list.length > 0 &&
        setSearchFilter(
          list.filter((ele) =>
            ele.name.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  const handleClassMapEdit = (id, data) => {
    setEditHostel(id);
    setAddForm({
      name: data.name,
      type: data.type,
      warden: data.warden._id,
      note: data.note,
      address: data.address,
      contactNumber: data.contactNumber,
    });
    handleClickOpen();
  };

  return (
    <div>
      <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span
              style={{
                color: "red",
                fontFamily: "cursive",
                textAlign: "start",
              }}
            >
              Hostel:
            </span>
          </Grid>
        </Grid>
      </Card>
      <div style={{ margin: "15px" }}>
        <Paper sx={{ width: "100%", height: "60px", overflow: "hidden" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid xs={3}>
                <TextField
                  size="small"
                  style={styles.textfield}
                  value={search}
                  onChange={handleSearch}
                  label="Search"
                  placeholder="enter hostel name"
                  variant="outlined"
                />
              </Grid>
              <Grid>
                <Button
                  variant="contained"
                  sx={{ mt: 3.2 }}
                  style={styles.addbutton}
                  onClick={handleClickOpen}
                >
                  <AddIcon />
                  Add Hostel
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form className="form-tag" onSubmit={handleAddSubmit}>
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign="center"
                marginBottom="30px"
                onChange={handleAddForm}
              >
                {editHostel ? "Update Hostel" : "Add Hostel"}
              </Typography>
              <div>
                <TextField
                  required
                  id="filled-required"
                  variant="standard"
                  label="Hostel Name"
                  name="name"
                  value={addForm.name || ""}
                  onChange={handleAddForm}
                  sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
                />
                <FormControl
                  variant="standard"
                  sx={{ m: 1, minWidth: 227 }}
                  required
                >
                  <TextField
                    required
                    id="filled-required"
                    variant="standard"
                    label="Phone No"
                    name="contactNumber"
                    onChange={handleAddForm}
                    value={addForm.contactNumber || ""}
                    sx={{ minWidth: 227, label: { fontSize: 12 } }}
                  />
                </FormControl>
                <FormControl variant="standard" sx={{ m: 0.3, minWidth: 227 }}>
                  <InputLabel sx={{ fontSize: 8 }}>Hostel Type</InputLabel>
                  <Select
                    labelId="demo-simpless-select-filled-label"
                    id="demo-simple-select-filled-l"
                    name="type"
                    value={addForm.type || ""}
                    onChange={handleAddForm}
                  >
                    <MenuItem value={"Boys"}>Boys</MenuItem>
                    <MenuItem value={"Girls"}>Girls</MenuItem>
                    <MenuItem value={"Combined"}>Combined</MenuItem>
                  </Select>
                </FormControl>
                <div>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 227 }}>
                    <InputLabel sx={{ fontSize: 8 }}>Warden</InputLabel>
                    <Select
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      name="warden"
                      value={addForm.warden || ""}
                      onChange={handleAddForm}
                    >
                      {employees.map((employee) => (
                        <MenuItem key={employee._id} value={employee._id}>
                          {employee.basicInfo.empName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    required
                    id="filled-required"
                    variant="standard"
                    label="Address"
                    name="address"
                    value={addForm.address || ""}
                    onChange={handleAddForm}
                    sx={{ m: 1.7, minWidth: 227, label: { fontSize: 12 } }}
                  />
                </div>

                <div>
                  <TextareaAutosize
                    placeholder="Drop a note"
                    name="note"
                    value={addForm.note || ""}
                    onChange={handleAddForm}
                    style={{
                      width: 712,
                      maxHeight: 110,
                      margin: 10,
                      padding: 10,
                      height: 118,
                      overflow: "auto",
                    }}
                  />
                </div>
              </div>

              <div className="cancel-submit-btn">
                <div>
                  <Tooltip title="Cancel">
                    <Button
                      onClick={handleClose}
                      variant="outlined"
                      size="medium"
                      type="button"
                      sx={{ m: 1 }}
                    >
                      Cancel
                    </Button>
                  </Tooltip>
                  <Tooltip title="Submit">
                    <Button
                      variant="contained"
                      size="medium"
                      type="submit"
                      sx={{
                        background: "rgb(27, 55, 121)",
                        ":hover": { background: "rgb(27, 55, 121)" },
                      }}
                    >
                      {editHostel ? "update" : "Add"}
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </Box>
          </form>
        </Modal>

        <Table>
          <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
            <TableRow sx={{ color: "white" }}>
              <TableCell sx={{ color: "white" }} align="center">
                #SL
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Hostel Name
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Hostel Type
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Address
              </TableCell>
              <TableCell sx={{ color: "white" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {search
              ? searchFilter.map((hostel, index) => (
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{hostel.name}</TableCell>
                    <TableCell align="center">{hostel.type}</TableCell>
                    <TableCell align="center">{hostel.address}</TableCell>
                    <TableCell align="center">
                      <div>
                        <Button
                          style={{ color: "#1b3779" }}
                          onClick={() => handleClassMapEdit(hostel._id, hostel)}
                        >
                          <EditIcon />
                        </Button>
                        <Button
                          onClick={() => handleClickOpenDialog(hostel._id)}
                          style={{ color: "#1b3779" }}
                        >
                          <VisibilityIcon />
                        </Button>
                        <Button
                          style={{
                            color: "#1b3779",
                          }}
                          onClick={() => setDeleteModal(hostel._id, hostel)}
                        >
                          <Delete />
                        </Button>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={hostel._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              : list.map((hostel, index) => (
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{hostel.name}</TableCell>
                    <TableCell align="center">{hostel.type}</TableCell>
                    <TableCell align="center">{hostel.address}</TableCell>
                    <TableCell align="center">
                      <div>
                        <Button
                          style={{ color: "#1b3779" }}
                          onClick={() => handleClassMapEdit(hostel._id, hostel)}
                        >
                          <EditIcon />
                        </Button>
                        <Button
                          onClick={() => handleClickOpenDialog(hostel)}
                          style={{ color: "#1b3779" }}
                        >
                          <VisibilityIcon />
                        </Button>
                        <Button
                          style={{
                            color: "#1b3779",
                          }}
                          onClick={() => setDeleteModal(hostel._id, hostel)}
                        >
                          <Delete />
                        </Button>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDeleteone}
                          id={hostel._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
        {!list.length && !search.trim() && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        {search.trim() && !searchFilter.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        <BootstrapDialog
          onClose={handleCloseDialog}
          aria-labelledby="customized-dialog-title"
          open={dialog}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: "#1b3779", color: "white" }}
          >
            Hostel Information
          </BootstrapDialogTitle>

          <DialogContent dividers>
            <table className="hostel" style={styles.tab}>
              <tbody>
                {showDetails ? (
                  <div>
                    <tr>
                      <td>School Name</td>
                      <td>{settingsContext.schoolName}</td>
                    </tr>
                    <tr>
                      <td>Hostel Name</td>
                      <td>{showDetails.name}</td>
                    </tr>
                    <tr>
                      <td>Hostel Type</td>
                      <td>{showDetails.type}</td>
                    </tr>
                    <tr>
                      <td>Address</td>
                      <td>{showDetails.address}</td>
                    </tr>
                    <tr>
                      <td>Note</td>
                      <td>{showDetails.note}</td>
                    </tr>
                    <tr>
                      <td>Created</td>

                      <td>
                        {dayjs(showDetails.createdAt).format("DD-MM-YYYY")}
                      </td>
                    </tr>
                  </div>
                ) : null}
              </tbody>
            </table>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </div>
  );
};
export default Hostel;
