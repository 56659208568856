import React, { useState, useEffect } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "./../styles/components/navbar/Navbar.scss";
import { Language } from "@mui/icons-material";
import "./../styles/components/navbar/NavTop.scss";
import css from './Addmission.module.css'
import Footer from "./Footer/Footer";
import TopNav from "./navbar/TopNav";
import Navbar from "./navbar/Navbar";
import Demo from "./NewsNotic/Demo";
import ReactPlayer from 'react-player';
import { get } from "../services/apis";

const LatestEventsById = () => {
    const [addClass, updateClass] = useState(false);
  const [stkyClass, setStkyClass] = useState(false);
  const [mega, setMega] = useState(false);
  const [event,setEvent] = useState(null);

  const {eventsId} = useParams();

  const handleClickCross = () => {
    updateClass(false);
    window.scrollTo(0, 0);
  };
  const scrollHandleScroll = () => {
    if (window.scrollY > 20) {
      setStkyClass(true);
    } else {
      setStkyClass(false);
    }
  };
  const handleMega = () => {
    setMega(!mega);
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollHandleScroll);
  }, []);

  useEffect(() => {
    const getEvents = async () => {
        try {
            const event = await get(`/event/list/single/${eventsId}`);
            console.log(event)
            if(event.data.success) {
              setEvent(event.data.data)
            }
        } catch (error) {
            
        }
    }
    getEvents();
  },[])
  console.log("event",event)
  return (
    <>
        <TopNav />
        <Navbar />
      <div className={css.headerContainer}>
                <div>
                    <h1>Latest Events</h1>
                    <h5><Link style={{
                        color:'white'
                    }} to='/'>Home</Link> LatestEvents</h5>
                </div>
      </div>
      <div className={css.contentContainer}>
        <div className={css.content} style={{marginLeft:"-370px"}}>
            {!event ? null : (
                <>

               
                {event.image ? (
                  <img src={event.image.link} alt="" width="500" height="300"/>
                ) : null}
                <div style={{marginTop:"40px",width:"100px" ,height:"200",}}>
                {event.video ? (
                  <ReactPlayer url={event.video} />
                ) : null}
                </div>
                
                <h5>{event.eventTitle}</h5>
                <p>
                    {event.shortEvent}
                </p>
                </>
            )}
        </div>
      </div>

      <Footer />
    </>
  );
}

export default LatestEventsById;