import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "../../styles/components/JoinSession/JoinSession.scss";
const JoinSession = () => {
  return (
    <div className="joinsession__wrapper margin__bottom">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 offset-sm-0 col-lg-6 text-center offset-lg-5 mx-auto">
            <h5 className="session__sub">Join Our New Session</h5>
            <h2 className="session__title">
              Call To Enroll Your Child +91 8884446691
            </h2>
            <Link to="/moreproject" className="session__join">
              Call Us Now
              <FontAwesomeIcon icon="caret-right" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default JoinSession;
