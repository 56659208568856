import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { Delete, Edit } from "@mui/icons-material";
import { useEffect } from "react";
import AddClassModal from "./AddClassModal";
import "../../../styles/components/Academic/Academic.scss";
import { useState } from "react";
import { del, get, post, put } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import DeleteModal from "./DeleteModal";
import { Grid, TextField, Tooltip, Typography } from "@mui/material";

import styles from "../../../styles/components/Layout/Administrator/UserRole.module.css";

const SchoolClass = () => {
  const [schoolClass, setSchoolClass] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateClass, setUpdateClass] = useState(undefined);
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({
    className: "asc",
    studentAttendenceType: "asc",
    classNote: "asc",
    isPublish: "asc",
    status: "asc",
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUpdateClass(undefined);
  };

  const handleDelete = async (id) => {
    try {
      const response = await del(urls.class.delClass, id);
      if (response.data.success) {
        const filteredClass = schoolClass.filter(
          (item) => item._id !== response.data.data._id
        );
        setSchoolClass(filteredClass);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = async (e, data) => {
    e.preventDefault();
    try {
      const response = await post(urls.class.postClass, data);
      setSchoolClass([...schoolClass, response.data.data]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async (data, id) => {
    try {
      const response = await put(urls.class.putClass, id, data);
      setSchoolClass(
        schoolClass.map((e) => {
          if (e._id === response.data.data._id) {
            return response.data.data;
          } else {
            return e;
          }
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
  const handleEdit = async (id) => {
    try {
      setOpen(true);
      const getClassById = schoolClass.find((item) => item._id === id);
      setUpdateClass(getClassById);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getSchoolClass = async () => {
      try {
        const response = await get(urls.class.getAllClass);
        console.log(response.data.data, "alll classessss");
        setSchoolClass([...response.data.data]);
        setSearchFilter([...response.data.data]);
      } catch (error) {
        console.log(error);
      }
    };
    getSchoolClass();
  }, []);
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      setSearchFilter(
        schoolClass.filter((ele) => ele.className.includes(value))
      );
    } else {
      setSearchFilter([]);
    }
  };
  console.log("searchFilter", searchFilter);

  // const sortBySectionName = () => {
  //   console.log("sortBySectionName");
  //   let sortedList;

  //   if (search) {
  //     if (sort.sectionName === "asc") {
  //       sortedList = [
  //         ...searchFilter.sort((a, b) =>
  //           b.sectionName.localeCompare(a.sectionName)
  //         ),
  //       ];
  //       setSort({ ...sort, sectionName: "des" });
  //     } else {
  //       sortedList = [
  //         ...searchFilter.sort((a, b) =>
  //           a.sectionName.localeCompare(b.sectionName)
  //         ),
  //       ];
  //       setSort({ ...sort, sectionName: "asc" });
  //     }
  //   } else {
  //     if (sort.sectionName === "asc") {
  //       sortedList = [
  //         ...schoolSection.sort((a, b) =>
  //           b.sectionName.localeCompare(a.sectionName)
  //         ),
  //       ];
  //       setSort({ ...sort, sectionName: "des" });
  //     } else {
  //       sortedList = [
  //         ...schoolSection.sort((a, b) =>
  //           a.sectionName.localeCompare(b.sectionName)
  //         ),
  //       ];
  //       setSort({ ...sort, sectionName: "asc" });
  //     }
  //   }
  //   setSchoolSection(sortedList);
  // };

  const classNameSorted = () => {
    console.log("className");
    let sortedList;
    if (search) {
      if (sort.className === "asc") {
        sortedList = [
          ...searchFilter.sort((a, b) => b.className - a.className),
        ];
        setSort({ ...sort, className: "des" });
      } else {
        sortedList = [
          ...searchFilter.sort((a, b) => a.className - b.className),
        ];
        setSort({ ...sort, className: "asc" });
      }
    } else {
      if (sort.className === "asc") {
        sortedList = [...schoolClass.sort((a, b) => b.className - a.className)];
        setSort({ ...sort, className: "des" });
      } else {
        sortedList = [...schoolClass.sort((a, b) => a.className - b.className)];
        setSort({ ...sort, className: "asc" });
      }
    }

    setSearchFilter(sortedList);
  };

  const studentAttendenceTypeSorted1 = () => {
    console.log("studentAttendanceType");
    if (sort.studentAttendenceType === "dec") {
      let sortedList = [
        ...schoolClass.sort((a, b) => {
          if (
            a.studentAttendenceType === "classWise" &&
            b.studentAttendenceType === "subjectWise"
          ) {
            console.log("yes");
            return -1;
          } else if (
            a.studentAttendenceType === "subjectWise" &&
            b.studentAttendenceType === "classWise"
          ) {
            console.log("no");

            return 1;
          } else {
            return 0;
          }
        }),
      ];
      setSchoolClass(sortedList);
      setSort({ ...sort, studentAttendenceType: "asc" });
    } else {
      let sortedList = [
        ...schoolClass.sort((a, b) => {
          if (
            a.studentAttendenceType === "classWise" &&
            b.studentAttendenceType === "subjectWise"
          ) {
            console.log("yes");
            return 1;
          } else if (
            a.studentAttendenceType === "subjectWise" &&
            b.studentAttendenceType === "classWise"
          ) {
            console.log("no");

            return -1;
          } else {
            return 0;
          }
        }),
      ];
      setSchoolClass(sortedList);
      setSort({ ...sort, studentAttendenceType: "dec" });
    }
  };
  const statusSorted = () => {
    console.log("statusSorted");
    if (sort.status === "asc") {
      let sortedList = [
        ...schoolClass.sort((a, b) => {
          if (a.status === "inactive" && b.status === "active") {
            console.log("yes");
            return -1;
          } else if (a.status === "active" && b.status === "inactive") {
            console.log("no");

            return 1;
          } else {
            return 0;
          }
        }),
      ];
      setSchoolClass(sortedList);
      setSort({ ...sort, status: "des" });
    } else {
      let sortedList = [
        ...schoolClass.sort((a, b) => {
          if (a.status === "inactive" && b.status === "active") {
            console.log("yes");
            return 1;
          } else if (a.status === "active" && b.status === "inactive") {
            console.log("no");

            return -1;
          } else {
            return 0;
          }
        }),
      ];
      setSchoolClass(sortedList);
      setSort({ ...sort, status: "asc" });
    }
  };

  const publishSorted = () => {
    console.log("publishSorted");
    if (sort.isPublish === "asc") {
      let sortedList = [
        ...schoolClass.sort((a, b) => {
          if (a.isPublish === "inactive" && b.isPublish === "active") {
            console.log("yes");
            return -1;
          } else if (a.isPublish === "active" && b.isPublish === "inactive") {
            console.log("no");

            return 1;
          } else {
            return 0;
          }
        }),
      ];
      setSchoolClass(sortedList);
      setSort({ ...sort, isPublish: "des" });
    } else {
      let sortedList = [
        ...schoolClass.sort((a, b) => {
          if (a.isPublish === "inactive" && b.isPublish === "active") {
            console.log("yes");
            return 1;
          } else if (a.isPublish === "active" && b.isPublish === "inactive") {
            console.log("no");

            return -1;
          } else {
            return 0;
          }
        }),
      ];
      setSchoolClass(sortedList);
      setSort({ ...sort, isPublish: "asc" });
    }
  };

  const classNoteSorted = () => {
    console.log("classNoteSorted");
    let sortedList;

    if (search) {
      if (sort.classNote === "asc") {
        sortedList = [
          ...searchFilter.sort((a, b) =>
            b.classNote.localeCompare(a.classNote)
          ),
        ];
        setSort({ ...sort, classNote: "des" });
      } else {
        sortedList = [
          ...searchFilter.sort((a, b) =>
            a.classNote.localeCompare(b.classNote)
          ),
        ];
        setSort({ ...sort, classNote: "asc" });
      }
    } else {
      if (sort.classNote === "asc") {
        sortedList = [
          ...schoolClass.sort((a, b) => b.classNote.localeCompare(a.classNote)),
        ];
        setSort({ ...sort, classNote: "des" });
      } else {
        sortedList = [
          ...schoolClass.sort((a, b) => a.classNote.localeCompare(b.classNote)),
        ];
        setSort({ ...sort, classNote: "asc" });
      }
    }

    setSearchFilter(sortedList);
  };
  console.log("schoolClass", schoolClass);
  return (
    <div style={{ margin: "15px" }}>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <div className={styles.searchInputContainer}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid xs="auto">
              <TextField
                size="small"
                value={search}
                onChange={handleSearch}
                label="Search"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{ background: "rgb(27 55 121)" }}>
              <TableRow>
                <TableCell align="center">
                  <span className="class-table-header">#SL</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">
                    Class
                    <span
                      onClick={classNameSorted}
                      style={{ cursor: "pointer" }}
                    >
                      {sort.className === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">
                    Attendance
                    <span
                      onClick={studentAttendenceTypeSorted1}
                      style={{ cursor: "pointer" }}
                    >
                      {sort.studentAttendenceType === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Note</span>
                  <span
                    onClick={classNoteSorted}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.classNote === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Status</span>
                  <span
                    onClick={statusSorted}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.status === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Is Publish</span>
                  <span
                    onClick={publishSorted}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.isPublish === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Action </span>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {search
                ? searchFilter.map((row, index) => (
                    <TableRow
                      key={row._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.className}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.studentAttendenceType}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.classNote}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.status}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.isPublish}
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        <div className="edit-delete">
                          <Tooltip title="Edit" disableInteractive>
                            <Button onClick={() => handleEdit(row._id)}>
                              <Edit />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Delete" disableInteractive>
                            <Button onClick={() => setDeleteModal(row._id)}>
                              <Delete />
                            </Button>
                          </Tooltip>
                        </div>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDelete}
                          id={row._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                : schoolClass.map((row, index) => (
                    <TableRow
                      key={row._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.className}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.studentAttendenceType}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.classNote}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.status}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.isPublish}
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        <div className="edit-delete">
                          <Tooltip title="Edit" disableInteractive>
                            <Button onClick={() => handleEdit(row._id)}>
                              <Edit />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Delete" disableInteractive>
                            <Button onClick={() => setDeleteModal(row._id)}>
                              <Delete />
                            </Button>
                          </Tooltip>
                        </div>
                        <DeleteModal
                          deleteModal={deleteModal}
                          handleDelete={handleDelete}
                          id={row._id}
                          setDeleteModal={setDeleteModal}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!schoolClass.length && !search.trim() && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
        {search.trim() && !searchFilter.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
      </Paper>
      <div className="add-icon">
        <AddClassModal
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          updateClass={updateClass}
          handleUpdate={handleUpdate}
        />
      </div>
    </div>
  );
};

export default SchoolClass;
