import {
  Autocomplete,
  Box,
  Button,
  Card,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { get } from "../services/apis";
import { urls } from "../services/urlConstant";
import {
  Add,
  CardMembership,
  FindInPage,
  Groups,
  Message,
} from "@mui/icons-material";
import ChartBar from "../components/Layout/ChartBar/ChartBar";
import "../styles/components/Dashboard/Dashboard.scss";
import { Link, useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Collapsible from "react-collapsible";
import { toast } from "react-toastify";
import dayjs from "dayjs";

// import NewsNotice from "../Data/news.json";

const styles = {
  card: {
    margin: 15,
    padding: 10,
  },
  textfield: {
    width: "100%",
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
  },
};

const DashBoard = () => {
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [dashboardDetails, setDashboardDetails] = useState({});
  const [calValue, setCalValue] = useState(new Date());
  const [announceNotice, setAnounceNotice] = useState([]);
  const [selectStudent, setSelectStudent] = useState("");
  const [notice, setNotice] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [studentAttendanceStats, setStudentAttendanceStats] = useState(null);
  const [empAttendanceStat, setEmpAttendanceStat] = useState(null);

  const navigate = useNavigate();

  const handleOnchange = async (e) => {
    const { name, value } = e.target;
    setDashboardDetails({ ...dashboardDetails, [name]: value });
    try {
      if (value === "all") {
        const getStudents = await get(urls.students.getAllStudent);
        setStudents(getStudents.data.data);
      } else {
        const getStudents = await get(urls.students.getAllStudent, {
          params: {
            search: {
              "academicInfo.class": value,
            },
          },
        });
        setStudents(getStudents.data.data);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  useEffect(() => {
    const studentsAttendanceStatsRes = async () => {
      const { data, status } = await get(`/dashboard/attendance-total`);
      const { data: empStat, status: empStatStatus } = await get(
        `/dashboard/employee-attendance-stats`
      );
      if (status === 200) {
        setStudentAttendanceStats(data.data);
      }
      if (empStatStatus === 200) {
        setEmpAttendanceStat(empStat.data);
      }
    };
    studentsAttendanceStatsRes();
  }, []);
  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        const getStudents = await get(urls.students.getAllStudent);
        setClasses(res.data.data);
        setStudents(getStudents.data.data);
        setDashboardDetails((prev) => ({
          ...prev,
          className: "all",
        }));
      } catch (error) {
        // toast.error(error.response.data.message)
      }
    };
    getClasses();
  }, []);

  useEffect(() => {
    const getStudents = async () => {
      try {
        const res = await get(urls.students.getAllStudent);
        setStudents(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getStudents();
  }, []);
  const handleStudentAuto = (event, newVal) => {
    setSelectStudent(newVal);
  };
  const handleSearchStudentSubmit = (e) => {
    e.preventDefault();
    if (selectStudent) navigate(`/edit-student/${selectStudent._id}`);
  };
  useEffect(() => {
    const getNoticeReq = async () => {
      try {
        const getNotice = await get(
          `/dashboard/month-notice/${dayjs().format("YYYY-MM-DD")}`
        );
        setNotice(getNotice.data.data);
        const getHolidays = await get(
          `/dashboard/month-holiday/${dayjs().format("YYYY-MM-DD")}`
        );
        setHolidays(getHolidays.data.data);
      } catch (error) {}
    };
    getNoticeReq();
  }, []);
  const handleCalChange = async (e) => {
    setCalValue(e);
    try {
      const getNotice = await get(
        `/dashboard/month-notice/${dayjs(e).format("YYYY-MM-DD")}`
      );
      setNotice(getNotice.data.data);
      const getHolidays = await get(
        `/dashboard/month-holiday/${dayjs(e).format("YYYY-MM-DD")}`
      );
      setHolidays(getHolidays.data.data);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  return (
    <div>
      <Card style={styles.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span
              style={{
                color: "red",
                fontFamily: "cursive",
                backgroundcolor: "blue",
              }}
            >
              Dashboard :
            </span>
          </Grid>
        </Grid>
      </Card>
      <Card style={styles.card}>
        <Box>
          <form onSubmit={handleSearchStudentSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={5}>
                <FormControl variant="standard" sx={styles.textfield} required>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    Class
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="className"
                    value={dashboardDetails.className || ""}
                    onChange={(event) => handleOnchange(event)}
                  >
                    <MenuItem value="all">All</MenuItem>
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <FormControl variant="standard" sx={styles.textfield} required>
                  <Autocomplete
                    onChange={handleStudentAuto}
                    options={students}
                    disableCloseOnSelect
                    getOptionLabel={(option) =>
                      option &&
                      `${option && option.basicInfo.name} | ${
                        option && option.academicInfo.rollNo
                      } | ${option && option.contactNumber}`
                    }
                    value={selectStudent}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label="Student"
                        variant="standard"
                        placeholder="Search by Name | Roll No | Contact Number"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={1}
                style={{ alignSelf: "flex-end" }}
              >
                <Button
                  style={styles.Button}
                  size="small"
                  variant="contained"
                  type="submit"
                >
                  find
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Card>
      <div style={{ display: "flex" }}>
        <div style={{ width: "100%" }}>
          <Grid container spacing={2} className="cus-grid">
            <Grid
              item
              xs={6}
              md={6}
              lg={6}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Card
                style={{
                  margin: "15px 12px",
                  padding: "10px",
                  height: "115px",
                  width: "340px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <Box sx={{ alignSelf: "center" }}>
                  <Groups
                    style={{
                      width: "40px",
                      height: "40px",
                      color: "#1b3779",
                    }}
                  />
                </Box>
                <Link
                  to={"/student-attendance"}
                  style={{
                    color: "inherit",
                  }}
                >
                  <Box
                    style={{
                      textAlign: "right",
                      alignSelf: "center",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    <div>Students Attendance </div>
                    <div>
                      {studentAttendanceStats &&
                        `${studentAttendanceStats.presentCount}/${studentAttendanceStats.studentCount}`}
                    </div>
                  </Box>
                </Link>
              </Card>
            </Grid>
            <Grid
              item
              xs={6}
              md={6}
              lg={6}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Card
                style={{
                  margin: "15px",
                  padding: "10px",
                  height: "115px",
                  width: "300px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <Box sx={{ alignSelf: "center" }}>
                  <Groups
                    style={{
                      width: "40px",
                      height: "40px",
                      color: "#1b3779",
                    }}
                  />
                </Box>
                <Link
                  to={"/employee-attendance"}
                  style={{
                    color: "inherit",
                  }}
                >
                  <Box
                    style={{
                      textAlign: "right",
                      alignSelf: "center",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    <div>Employee Attendance</div>
                    <div>
                      {empAttendanceStat &&
                        `${empAttendanceStat.presentEmpAttendanceCount}/${empAttendanceStat.employeeCount}`}
                    </div>
                  </Box>
                </Link>
              </Card>
            </Grid>
          </Grid>
          <ChartBar />
        </div>
        <div style={{ width: "100%", margin: "15px" }}>
          <Card
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <span style={{ fontWeight: 500 }}>Quick Links</span>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Link to="/admitstudent" className="quick-link">
                    <Card className="quick-links-cards">
                      <Box>
                        <Fab
                          sx={{ background: "#1b3779", color: "#fff" }}
                          size="small"
                        >
                          <Groups />
                        </Fab>
                      </Box>
                      <Box>Admit Students</Box>
                    </Card>
                  </Link>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Link to="/addemployee" className="quick-link">
                    <Card className="quick-links-cards">
                      <Box>
                        <Fab
                          sx={{ background: "#1b3779", color: "#fff" }}
                          size="small"
                        >
                          <Groups />
                        </Fab>
                      </Box>
                      <Box>Admit Employee</Box>
                    </Card>
                  </Link>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Link to="/examresult" className="quick-link">
                    <Card className="quick-links-cards">
                      <Box>
                        <Fab
                          sx={{ background: "#1b3779", color: "#fff" }}
                          size="small"
                        >
                          <FindInPage />
                        </Fab>
                      </Box>
                      <Box> Exam Results</Box>
                    </Card>
                  </Link>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Link to="/marks-card" className="quick-link">
                    <Card className="quick-links-cards">
                      <Box>
                        <Fab
                          sx={{ background: "#1b3779", color: "#fff" }}
                          size="small"
                        >
                          <CardMembership />
                        </Fab>
                      </Box>
                      <Box>Marks Cards</Box>
                    </Card>
                  </Link>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Link to="/compose" className="quick-link">
                    <Card className="quick-links-cards">
                      <Box>
                        <Fab
                          sx={{ background: "#1b3779", color: "#fff" }}
                          size="small"
                        >
                          <Message />
                        </Fab>
                      </Box>
                      <Box>Communication</Box>
                    </Card>
                  </Link>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Link to="/compose" className="quick-link">
                    <Card className="quick-links-cards">
                      <Box>
                        <Fab
                          sx={{ background: "#1b3779", color: "#fff" }}
                          size="small"
                        >
                          <Add />
                        </Fab>
                      </Box>
                      <Box>Admit Students</Box>
                    </Card>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </div>
      </div>
      <div className="calendar-events">
        <div className="calendar" style={{ width: "100%" }}>
          <Card style={{ padding: "10px" }}>
            <Calendar
              style={{ width: "100%" }}
              onChange={handleCalChange}
              value={calValue}
            />
          </Card>
        </div>

        <div style={{ width: "100%" }}>
          <Card style={{ margin: " 0px 10px ", padding: 10 }}>
            <Card style={{ padding: 10, margin: "10px 0px", height: "50%" }}>
              <Collapsible trigger="Notices">
                <div className="news-container">
                  <div className="scroll-container">
                    {notice.map((data) => (
                      <Card className="dashboard-scroll-text">
                        <div className="dashboard-news-content">
                          <Card className="news-date-month">
                            <div className="date">
                              {dayjs(data.date).format("DD-MM-YYYY")}
                            </div>
                          </Card>
                          <div className="title-discription">
                            <div>
                              <h4>{data.title}</h4>
                            </div>
                            <div className="discription">{data.notice}</div>
                          </div>
                        </div>
                      </Card>
                    ))}
                  </div>
                </div>
              </Collapsible>
            </Card>
            <Card style={{ padding: 10, margin: "10px 0px", height: "50%" }}>
              <Collapsible trigger="Holidays">
                <div className="news-container">
                  <div className="scroll-container">
                    {holidays.map((data) => (
                      <Card className="dashboard-scroll-text">
                        <div className="dashboard-news-content">
                          <Card className="news-date-month">
                            <div className="date">{data.fromDate}</div>
                          </Card>
                          <div className="title-discription">
                            <div>
                              <h4>{data.title}</h4>
                            </div>
                            <div className="discription">{data.note}</div>
                          </div>
                        </div>
                      </Card>
                    ))}
                  </div>
                </div>
              </Collapsible>
            </Card>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default DashBoard;
