import React, { useEffect } from "react";

// style
import css from "../../../styles/fees/QuickFeeCollection.module.css";
import HeaderCard from "../../ui/HeaderCard";
import { useState } from "react";
import { get, post } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Delete } from "@mui/icons-material";
import QuickFeeConcessionModal from "./components/QuickFeeConcessionModal";
import QuickFeeCollectModal from "./components/QuickFeeCollectModal";
import { instance } from "../../../services/axios.Config";

const styles = {
  card: {
    padding: 10,
    margin: "0px 0px 20px 0px",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
    m: 1,
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
    marginTop: "1rem",
  },
};

const DetailedFeeCollection = () => {
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [formData, setFormData] = useState({});
  const [students, setStudents] = useState([]);
  const [selectStudent, setSelectStudent] = useState(null);
  const [feeReceipt, setFeeReceipt] = useState(null);
  const [itemDetails, setItemDetails] = useState([]);
  const [concessionDialog, setConcessionDialog] = useState(false);
  const [concession, setConcession] = useState(null);
  const [collectFeeModal, setCollectFeeModal] = useState(false);
  const [feeCollect, setFeeCollect] = useState(null);
  const [receiptBooks, setReceiptBooks] = useState([]);
  const [studentCategories, setStudentCategories] = useState([]);
  const [selectCategory, setSelectCategory] = useState("");
  const [categoryFeesList, setCategoryFeesList] = useState([]);

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        setClasses(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    const getstudentCategories = async () => {
      try {
        const { data: studentCategoryList, status } = await get(
          `${urls.CategoryList.getAllCategoryList}`
        );
        if (status > 199 && status < 299) {
          setStudentCategories(studentCategoryList.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
    getstudentCategories();
  }, []);
  useEffect(() => {
    const getReceiptsBooks = async () => {
      try {
        const { data: receiptsList, status } = await get(
          `${urls.recepitBook.getAllActiveReceipt}`
        );
        if (status > 199 && status < 299) {
          setReceiptBooks(receiptsList.data);
        }
      } catch (error) {}
    };
    getReceiptsBooks();
  }, []);

  useEffect(() => {
    const getStudents = async () => {
      try {
        if (formData.class && formData.section) {
          const { data: students, status } = await get(
            `${urls.students.getAllStudent}`,
            {
              params: {
                search: {
                  "academicInfo.class": formData.class,
                  "academicInfo.section": formData.section,
                },
              },
            }
          );
          if (status > 199 && status < 299) {
            setSelectStudent(null);
            setStudents(students.data);
          }
        }
      } catch (error) {}
    };
    getStudents();
  }, [formData]);
  useEffect(() => {
    if (selectCategory) {
      const getFeeCategories = async () => {
        try {
          const { data: list, status } = await get(
            `${urls.recepitBook.categories}${selectCategory}`
          );
          if (status > 199 && status < 299) {
            if (Array.isArray(list.data)) {
              setCategoryFeesList(
                list.data.map((ele) => ({
                  name: ele.name,
                  amount: ele.amount,
                }))
              );
            }
          }
        } catch (error) {
          console.log(error);
        }
      };
      getFeeCategories();
    }
  }, [selectCategory]);

  const handleClasses = async (e) => {
    try {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
      const res = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: value,
          },
        },
      });
      setSections(res.data.data);
      setFormData((prev) => ({
        ...prev,
        section: "",
      }));
    } catch (error) {
      console.log(error);
    }
  };
  const handleSections = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleStudentSelect = (e, val) => {
    setSelectStudent(val);
  };
  const handleSubmitFind = async (e) => {
    e.preventDefault();
    try {
      const { data: feeReceipt, status } = await get(
        `${urls.feeReceipt.detailFee}`,
        {
          params: {
            classId: formData.class,
            sectionId: formData.section,
            studentId: selectStudent._id,
            receiptBookItem: formData.receiptBookItem,
          },
        }
      );
      if (status > 199 && status < 299) {
        setFeeReceipt(feeReceipt.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleAddedRowsChange = (e, changeIndex) => {
    const { name, value } = e.target;
    setItemDetails((prev) =>
      prev.map((itemDetail, index) =>
        index === changeIndex ? { ...itemDetail, [name]: value } : itemDetail
      )
    );
  };
  const handleAddRow = () => {
    setItemDetails((prev) => [
      ...prev,
      {
        name: "",
        amount: 0,
      },
    ]);
  };
  const handleRemoveAddedRow = (removeIndex) => {
    setItemDetails((prev) =>
      prev.filter((ele, index) => removeIndex !== index)
    );
  };
  const handleConcessionDialogClose = () => {
    setConcessionDialog(false);
  };
  const handleConcessionDialogOpen = () => {
    setConcessionDialog(true);
  };
  const handleCollectFeeDialogClose = () => {
    setCollectFeeModal(false);
  };
  const handleCollectFeeDialogOpen = () => {
    setCollectFeeModal(true);
  };
  const handleConcessionSubmit = (data) => {
    setConcession(data);
    handleConcessionDialogClose();
  };
  // const handleFeeCollect = (data) => {
  //   setFeeCollect(data);
  //   console.log(data);
  //   handleCollectFeeDialogClose();
  // }
  const handleFeeCollect = async (submitData, paymentDetails) => {
    console.log(submitData);
    const data = {
      studentId: selectStudent._id,
      classId: formData.class,
      sectionId: formData.section,
      paymentMode: submitData.payment,
      itemDetails: [...categoryFeesList, ...itemDetails],
      concessionDetails: concession
        ? {
            amount: concession.concession,
            referredBy: concession.refer,
            givenAs: concession.format,
          }
        : {},
      receiptItem: formData.receiptBookItem,
    };
    if (submitData.payment === "Cash") {
      data.cashDetails = {
        note: submitData.note,
        date: submitData.date,
      };
    }
    if (submitData.payment === "Cheque") {
      data.chequeDetails = {
        bankName: paymentDetails.bankName,
        branchName: paymentDetails.branchName,
        chequeNumber: paymentDetails.chequeNumber,
        chequeDate: paymentDetails.chequeDate,
      };
    }
    if (submitData.payment === "DD") {
      data.ddDetails = {
        bankName: paymentDetails.bankName,
        branchName: paymentDetails.branchName,
      };
    }
    if (submitData.payment === "Upi") {
      data.upiDetails = {
        bankName: paymentDetails.bankName,
        refNumber: paymentDetails.refNumber,
        paidByName: paymentDetails.paidByName,
      };
    }
    if (submitData.payment === "Card") {
      data.cardDetails = {
        bankName: paymentDetails.bankName,
        cardType: paymentDetails.cardType,
      };
    }
    if (submitData.payment === "Netbanking") {
      data.netBankingDetails = {
        bankName: paymentDetails.bankName,
        refNumber: paymentDetails.refNumber,
        paidByName: paymentDetails.paidByName,
      };
    }
    try {
      const { data: collectFee, status } = await instance({
        method: "post",
        url: `${urls.feeReceipt.create}`,
        data,
        responseType: "blob",
      });
      if (status > 199 && status < 299) {
        const uri = URL.createObjectURL(collectFee);
        window.open(uri, "__blank");
        toast.success("Fees collected successfully");
        setFormData({});
        setSelectStudent(null);
        setFeeReceipt(null);
        setItemDetails([]);
        setConcessionDialog(false);
        setConcession(null);
        setCollectFeeModal(false);
      }
    } catch (error) {
      let jsonBlob = new Blob([error.response.data], {
        type: "application/json",
      });
      const jsonErr = JSON.parse(await jsonBlob.text());
      toast.error(jsonErr.message);
    }
  };
  return (
    <div className={css.container}>
      {/* <HeaderCard header="Detailed Fee collection" color="#EB3223" /> */}
      <Card style={{ margin: 5, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span
              style={{
                color: "red",
                fontFamily: "cursive",
              }}
            >
              Detailed Fee collection:
            </span>
          </Grid>
        </Grid>
      </Card>
      <Card className={css.cardForm}>
        <Box>
          <form onSubmit={handleSubmitFind}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl variant="standard" sx={styles.textfield} required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Receipt
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="receiptBookItem"
                    label="Receipt"
                    required
                    value={formData.receiptBookItem || ""}
                    onChange={(event) => handleSections(event)}
                  >
                    {receiptBooks &&
                      receiptBooks.map((receiptBook, index) => (
                        <MenuItem
                          key={receiptBook._id}
                          value={receiptBook._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {receiptBook.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl variant="standard" sx={styles.textfield} required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Class
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="class"
                    label="Class"
                    required
                    value={formData.class || ""}
                    onChange={(event) => handleClasses(event)}
                  >
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl variant="standard" sx={styles.textfield} required>
                  <InputLabel id="demo-simple-select-filled-label">
                    Section
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="section"
                    label="Section"
                    value={formData.section || ""}
                    required
                    onChange={(event) => handleSections(event)}
                  >
                    {sections &&
                      sections.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.sectionName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Autocomplete
                  value={selectStudent}
                  sx={styles.textfield}
                  onChange={handleStudentSelect}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  options={students}
                  getOptionLabel={(option) =>
                    `${option.basicInfo.name} | ${option.academicInfo.admissionNo} | ${option.academicInfo.rollNo}`
                  }
                  renderInput={(params) => (
                    <TextField
                      required
                      variant="standard"
                      {...params}
                      label="Students"
                      placeholder="Name | Admission no | Roll no"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Button
                  style={styles.Button}
                  size="small"
                  type="submit"
                  variant="contained"
                >
                  find
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Card>
      {selectStudent && feeReceipt && (
        <div>
          <Card className={css.dueInfoContainer}>
            <Box className={css.dueInfoContainerFlex}>
              <div>{selectStudent?.basicInfo?.name}</div>
              <div>
                Total Due - ₹ <span>{feeReceipt.dueAmount}</span>
              </div>
            </Box>
            <Box className={css.dueInfoContainerFlex}>
              <div style={{ width: "25%" }}>
                <FormControl fullWidth>
                  <InputLabel>Category</InputLabel>
                  <Select
                    size="small"
                    value={selectCategory}
                    label="Category"
                    onChange={(e) => setSelectCategory(e.target.value)}
                  >
                    {studentCategories.map((studentCategory) => (
                      <MenuItem
                        key={studentCategory._id}
                        value={studentCategory._id}
                      >
                        {studentCategory.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div>
                Past Due - ₹ <span>{feeReceipt.previousDue}</span>
              </div>
            </Box>
          </Card>
          <div className={css.tableContainer}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead sx={{ backgroundColor: "#1b3779" }}>
                  <TableRow>
                    <TableCell sx={{ color: "white" }} align="center">
                      #SL
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Category
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      Amount
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categoryFeesList.map((categoryFeesItem, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {categoryFeesItem.name}
                      </TableCell>
                      <TableCell align="center">
                        {categoryFeesItem.amount}
                      </TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  ))}
                  {itemDetails.map((itemDetail, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">1</TableCell>
                      <TableCell align="center">
                        <FormControl>
                          <Select
                            sx={{ width: "200px" }}
                            value={itemDetail.name}
                            size="small"
                            name="name"
                            onChange={(e) => handleAddedRowsChange(e, index)}
                          >
                            <MenuItem value="Miscellaneous">
                              Miscelleneous
                            </MenuItem>
                            <MenuItem value="Penalty">Penalty</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          size="small"
                          type="number"
                          value={itemDetail.amount || ""}
                          name="amount"
                          onChange={(e) => handleAddedRowsChange(e, index)}
                          placeholder="Amount"
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleRemoveAddedRow(index)}>
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <div className={css.addRowBtn}>
                <div>
                  <Button
                    onClick={handleAddRow}
                    variant="contained"
                    size="small"
                  >
                    add row
                  </Button>
                </div>
                <div className={css.addRowBtn_childFlex}>
                  <div>
                    <Button
                      onClick={handleConcessionDialogOpen}
                      variant="contained"
                      size="small"
                    >
                      add concession
                    </Button>
                    <div className={css.concessionText}>
                      {concession
                        ? concession?.format === "Value"
                          ? `Concession:- ₹${concession?.concession}`
                          : `Concession:- ${concession?.concession}%`
                        : ""}
                    </div>
                  </div>
                  <Button
                    onClick={handleCollectFeeDialogOpen}
                    variant="contained"
                    size="small"
                  >
                    collect fee
                  </Button>
                </div>
              </div>
            </TableContainer>
          </div>
        </div>
      )}
      <QuickFeeConcessionModal
        onSubmit={handleConcessionSubmit}
        open={concessionDialog}
        onClose={handleConcessionDialogClose}
        opener={handleConcessionDialogOpen}
      />
      <QuickFeeCollectModal
        feeReceipt={feeReceipt}
        onSubmit={handleFeeCollect}
        open={collectFeeModal}
        onClose={handleCollectFeeDialogClose}
      />
    </div>
  );
};

export default DetailedFeeCollection;
