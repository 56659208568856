import { useState, useEffect, useContext } from "react";
import {
  Button,
  Grid,
  Typography,
  Box,
  styled,
  TextField,
  Tooltip,
  Divider,
  Dialog,
  useMediaQuery,
  useTheme,
  Card,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import Select from "react-select";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
// custom imports
import ShowUploadTable from "./ShowUploadTable";

//Icons
import FilterListIcon from "@mui/icons-material/FilterList";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
// apis
import { get, post, put, del } from "../../../../services/apis";
import { urls } from "../../../../services/urlConstant";
import SectionSubHeader from "../../SectionSubHeader";

const Label = styled("label")(() => ({
  fontWeight: "bold",
  fontSize: "14px",
  // paddingLeft: "10px",
  color: "black",
  "@media (max-width: 768px)": {
    fontSize: "12px",
    // paddingLeft: "5px",
  },
}));

const DownloadBox = styled(Box)(() => ({
  background: `rgb(133 140 223 / 19%)`,
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "10px",
  //   color: `${theme.palette.primary.main}`,
}));

export default function UploadCourse() {
  const theme = useTheme();
  const { state } = useLocation();
  const navigate = useNavigate();

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [courseDetails, setCourseDetails] = useState({ chapters: [] });
  const [courseName, setCourseName] = useState("");
  const [creatingChapter, setCreatingChapter] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [showFileInput, setShowFileInput] = useState(false);
  const location = useLocation();
  const [list, setList] = useState([]);
  const [openChapter, setOpenChapter] = useState(false);
  const [chapterName, setChapterName] = useState("");
  const [selectedCourseId, setSelectedCourseId] = useState(
    state ? state.selectedCourseIds : ""
  );
  const [chapterDescription, setChapterDescription] = useState();
  const [showBackArrow, setShowBackArrow] = useState(false);
  useEffect(() => {
    setShowBackArrow(selectedCourseId !== "");
  }, [selectedCourseId]);

  const createChapter = async (e) => {
    console.log("chapterrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr");
    e.preventDefault();
    setCreatingChapter(true);

    try {
      if (!chapterName) {
        return toast.error("Please mention Chapter Name");
      }
      const body = { title: chapterName };
      const formsData = new FormData();
      formsData.append("body", JSON.stringify(body));

      formsData.append("file", file);

      const { data } = await post(
        `${urls.courseContent.createChapter}/${selectedCourseId}`,
        formsData
      );

      // setCourseDetails(data.result);
      setChapterName("");

      setOpenChapter(false);
      getCourseDetails();
      setFile(null);
      setFileName("");
      setChapterDescription("");
      setCreatingChapter(false);
    } catch (error) {
      setCreatingChapter(false);
      console.log(error);
    }
  };

  const getCourse = async () => {
    try {
      const { data, status } = await get(`${urls?.course?.courselist}`);
      setList(data?.data?.map((m) => ({ label: m?.title, value: m?._id })));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCourse();
  }, []);

  const handleChangeCourse = (e) => {
    setSelectedCourseId(e.value);
    setCourseDetails({ chapters: [] });
  };

  const handleOpenChapter = () => setOpenChapter(true);

  const handleChapterClose = () => {
    setOpenChapter(false);
    setFileName("");
    setChapterName("");
    setFile(null);
  };

  const getCourseDetails = async () => {
    try {
      const res = await get(
        `${urls.courseContent.getCourseContent}/${selectedCourseId}`
      );
      setCourseDetails(res.data.data);
      console.log(res, "kjkjkjkjkj");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectedCourseId) {
      getCourseDetails();
      // getCourseName();
    }
  }, [selectedCourseId]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);
  };
  console.log(file, "filllele");
  const handleClickOpen = () => {
    setShowFileInput(true);
  };

  const handleRemoveFileClick = () => {
    setFile(null);
    setFileName("");
  };
  const handleNavigateToCourse = () => {
    navigate("/dashboard/course");
  };
  return (
    <Box sx={{ margin: "15px" }}>
      <SectionSubHeader
        title="Course Content"
        goBackArrow={showBackArrow}
        handleNavigate={handleNavigateToCourse}
      />

      {/* <Card style={{ padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span
              style={{
                color: "red",
                fontFamily: "cursive",
                textAlign: "start",
              }}>
              Course Content:
            </span>
          </Grid>
        </Grid>
      </Card> */}
      <Grid
        container
        sx={{
          p: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          my={2}
          gap={2}
          display="flex"
          alignItems="center">
          <Box sx={{ overflow: "hidden" }}>
            <Label htmlFor="">Select Course To Add Content</Label>

            <Select
              name="title"
              type="text"
              options={list}
              menuPortalTarget={document.body}
              value={list.find((m) => m.value === selectedCourseId) || ""}
              onChange={handleChangeCourse}
              styles={{
                container: (provided, state) => ({
                  ...provided,
                  marginBottom: "2px",
                }),
                menu: (provided, state) => ({
                  ...provided,
                  zIndex: 1000,
                }),

                control: (provided, state) => ({
                  ...provided,
                  borderRadius: "5px",
                }),
              }}
            />
          </Box>

          <Box sx={{ width: "50%", marginTop: 3 }}>
            <Button
              variant="contained"
              size="medium"
              disabled={!selectedCourseId}
              sx={{
                background: "#1b3779",
                ":hover": { background: "#1b3779" },
              }}
              startIcon={<AddIcon />}
              onClick={handleOpenChapter}>
              Chapter
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Divider />
      {courseDetails.chapters?.map((chapter, i) => (
        <ShowUploadTable
          updateList={getCourseDetails}
          key={i}
          chapter={chapter}
          course={courseDetails}
          courseName={list}
        />
      ))}
      {/* </Box> */}

      {/* chapter model -------------- */}
      <Dialog
        fullScreen={fullScreen}
        open={openChapter}
        onClose={handleChapterClose}
        aria-labelledby="responsive-dialog-title">
        <form onSubmit={createChapter}>
          <Grid container rowSpacing={2} p={2}>
            <Grid item xs={12} md={12}>
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: 600,
                  fontSize: "18px",
                }}>
                Add Chapter for Course:
                {list.find((m) => m.value === selectedCourseId)?.label}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                columnGap: 2,
              }}>
              <TextField
                fullWidth
                size="small"
                name="title"
                required
                value={chapterName}
                placeholder="Enter Chapter Name"
                onChange={(e) => setChapterName(e.target.value)}
              />

              <Button
                component="label"
                variant="outlined"
                fullWidth
                sx={{
                  padding: "7px",
                  maxWidth: 200,
                }}
                startIcon={<FileUploadIcon fontSize="small" />}
                size="small">
                upload material
                <input
                  hidden
                  type="file"
                  onChange={handleFileChange}
                  style={{ display: "block" }}
                />
              </Button>
            </Grid>
            <Grid item xs={12} md={12}>
              {fileName ? (
                <DownloadBox px={2} py={1}>
                  <Typography>{fileName}</Typography>
                  <Button onClick={handleRemoveFileClick} color="error">
                    <ClearIcon />
                  </Button>
                </DownloadBox>
              ) : (
                ""
              )}
            </Grid>

            <Grid
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                justifyContent: "center",
              }}
              item
              xs={12}
              md={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 2,
                  alignSelf: "center",
                  flex: 1,
                  width: "100%",
                }}>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleChapterClose}
                  sx={{ width: "45%" }}>
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  sx={{
                    width: "45%",
                    background: "rgb(27, 55, 121)",
                    ":hover": { background: "rgb(27, 55, 121)" },
                  }}
                  loading={creatingChapter}>
                  Submit
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Dialog>
    </Box>
  );
}
