import React, { useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Card,
  Grid,
  Tooltip,
  TextField,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import TableHead from "@mui/material/TableHead";
import { useEffect } from "react";
import DeleteModal from "../Academic/DeleteModal";
import { urls } from "../../../services/urlConstant";
import { del, post, put, get } from "../../../services/apis";
import TablePagination from "@mui/material/TablePagination";

import styles from "../../../styles/components/Layout/Administrator/UserRole.module.css";

import AddExamGrade from "./AddExamGrade";

const ExamGrade = () => {
  const [schoolExam, setSchoolExam] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateExam, setUpdateExam] = useState(undefined);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({
    examGrade: "asc",
    group: "asc",
    gradePoint: "asc",
    markFrom: "asc",
    markTo: "asc",
    note: "asc",
  });

  const sortByGrade = () => {
    console.log("Grade");
    if (search) {
      if (sort.examGrade === "asc") {
        let sortedList = [
          ...searchFilter.sort((a, b) =>
            b.examGrade.localeCompare(a.examGrade)
          ),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, examGrade: "des" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) =>
            a.examGrade.localeCompare(b.examGrade)
          ),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, examGrade: "asc" });
      }
    } else {
      if (sort.examGrade === "asc") {
        let sortedList = [
          ...schoolExam.sort((a, b) => b.examGrade.localeCompare(a.examGrade)),
        ];
        setSchoolExam(sortedList);
        setSort({ ...sort, examGrade: "des" });
      } else {
        let sortedList = [
          ...schoolExam.sort((a, b) => a.examGrade.localeCompare(b.examGrade)),
        ];
        setSchoolExam(sortedList);
        setSort({ ...sort, examGrade: "asc" });
      }
    }
  };

  const sortByGroup = () => {
    console.log("Group");
    if (search) {
      if (sort.group === "asc") {
        let sortedList = [
          ...searchFilter.sort((a, b) => b.group.localeCompare(a.group)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, group: "des" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) => a.group.localeCompare(b.group)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, group: "asc" });
      }
    } else {
      if (sort.group === "asc") {
        let sortedList = [
          ...schoolExam.sort((a, b) => b.group.localeCompare(a.group)),
        ];
        setSchoolExam(sortedList);
        setSort({ ...sort, group: "des" });
      } else {
        let sortedList = [
          ...schoolExam.sort((a, b) => a.group.localeCompare(b.group)),
        ];
        setSchoolExam(sortedList);
        setSort({ ...sort, group: "asc" });
      }
    }
  };

  const sortByGradePoint = () => {
    console.log("GradePoint");
    if (search) {
      if (sort.gradePoint === "asc") {
        let sortedList = [
          ...searchFilter.sort((a, b) => b.gradePoint - a.gradePoint),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, gradePoint: "des" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) => a.gradePoint - b.gradePoint),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, gradePoint: "asc" });
      }
    } else {
      if (sort.gradePoint === "asc") {
        let sortedList = [
          ...schoolExam.sort((a, b) => b.gradePoint - a.gradePoint),
        ];
        setSchoolExam(sortedList);
        setSort({ ...sort, gradePoint: "des" });
      } else {
        let sortedList = [
          ...schoolExam.sort((a, b) => a.gradePoint - b.gradePoint),
        ];
        setSchoolExam(sortedList);
        setSort({ ...sort, gradePoint: "asc" });
      }
    }
  };

  const sortByMarkFrom = () => {
    console.log("MarkFrom");
    if (search) {
      if (sort.markFrom === "asc") {
        let sortedList = [
          ...searchFilter.sort((a, b) => b.markFrom - a.markFrom),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, markFrom: "des" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) => a.markFrom - b.markFrom),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, markFrom: "asc" });
      }
    } else {
      if (sort.markFrom === "asc") {
        let sortedList = [
          ...schoolExam.sort((a, b) => b.markFrom - a.markFrom),
        ];
        setSchoolExam(sortedList);
        setSort({ ...sort, markFrom: "des" });
      } else {
        let sortedList = [
          ...schoolExam.sort((a, b) => a.markFrom - b.markFrom),
        ];
        setSchoolExam(sortedList);
        setSort({ ...sort, markFrom: "asc" });
      }
    }
  };

  const sortByMarkTo = () => {
    console.log("MarkTo");
    if (search) {
      if (sort.markTo === "asc") {
        let sortedList = [...searchFilter.sort((a, b) => b.markTo - a.markTo)];
        setSearchFilter(sortedList);
        setSort({ ...sort, markTo: "des" });
      } else {
        let sortedList = [...searchFilter.sort((a, b) => a.markTo - b.markTo)];
        setSearchFilter(sortedList);
        setSort({ ...sort, markTo: "asc" });
      }
    } else {
      if (sort.markTo === "asc") {
        let sortedList = [...schoolExam.sort((a, b) => b.markTo - a.markTo)];
        setSchoolExam(sortedList);
        setSort({ ...sort, markTo: "des" });
      } else {
        let sortedList = [...schoolExam.sort((a, b) => a.markTo - b.markTo)];
        setSchoolExam(sortedList);
        setSort({ ...sort, markTo: "asc" });
      }
    }
  };

  const sortByNote = () => {
    console.log("Note");
    if (search) {
      if (sort.note === "asc") {
        let sortedList = [
          ...searchFilter.sort((a, b) => b.note.localeCompare(a.note)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, note: "des" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) => a.note.localeCompare(b.note)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, note: "asc" });
      }
    } else {
      if (sort.note === "asc") {
        let sortedList = [
          ...schoolExam.sort((a, b) => b.note.localeCompare(a.note)),
        ];
        setSchoolExam(sortedList);
        setSort({ ...sort, note: "des" });
      } else {
        let sortedList = [
          ...schoolExam.sort((a, b) => a.note.localeCompare(b.note)),
        ];
        setSchoolExam(sortedList);
        setSort({ ...sort, note: "asc" });
      }
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUpdateExam(undefined);
  };

  const handleDelete = async (id) => {
    try {
      const res = await del(urls.examGrade.delExamGrade, id);
      if (res.data.success) {
        setSchoolExam(
          schoolExam.filter((item) => item._id !== res.data.data._id)
        );
      }
    } catch (error) {}
  };

  const handleSubmit = async (e, data) => {
    try {
      e.preventDefault();
      const res = await post(urls.examGrade.postExamGrade, data);
      setSchoolExam([...schoolExam, res.data.data]);
    } catch (error) {}
  };

  const handleUpdate = async (data, id) => {
    try {
      const response = await put(urls.examGrade.putExamGrade, id, data);
      setSchoolExam(
        schoolExam.map((e) => {
          if (e._id === response.data.data._id) {
            return response.data.data;
          } else {
            return e;
          }
        })
      );
    } catch (error) {}
  };

  const handleEdit = async (id) => {
    try {
      setOpen(true);
      const getExamById = schoolExam.find((item) => item._id === id);
      setUpdateExam(getExamById);
    } catch (error) {}
  };

  useEffect(() => {
    const getSchoolExam = async () => {
      try {
        const res = await get(urls.examGrade.getExamGrade);
        if (res && res.data && res.data.data) {
          setSchoolExam([...res.data.data]);
        }
      } catch (error) {}
    };
    getSchoolExam();
  }, []);

  const handleChangePage = async (event, newPage) => {
    try {
      const res = await get(urls.examGrade.getExamGrade, {
        params: {
          limit: 10,
          page: newPage + 1,
        },
      });
      setPage(newPage);
      setSchoolExam([...res.data.data]);
    } catch (error) {}
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      setSearchFilter(
        schoolExam.filter((ele) => ele.examGrade.toLowerCase().includes(value))
      );
    } else {
      setSearchFilter([]);
    }
  };
  return (
    <div>
      <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span
              style={{
                color: "red",
                fontFamily: "cursive",
              }}
            >
              Exam Grade :
            </span>
          </Grid>
        </Grid>
      </Card>
      <div style={{ margin: "15px" }}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <div className={styles.searchInputContainer}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid xs="auto">
                <TextField
                  size="small"
                  value={search}
                  onChange={handleSearch}
                  label="Search"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Exam Grade</span>
                    <span
                      onClick={sortByGrade}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.examGrade === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Group</span>
                    <span
                      onClick={sortByGroup}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.group === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Grade Point</span>
                    <span
                      onClick={sortByGradePoint}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.gradePoint === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Percentage From</span>
                    <span
                      onClick={sortByMarkFrom}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.markFrom === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Percentage To</span>
                    <span
                      onClick={sortByMarkTo}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.markTo === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Note</span>
                    <span
                      onClick={sortByNote}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.note === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {search
                  ? searchFilter.map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.examGrade}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.group}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.gradePoint}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.markFrom}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.markTo}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.note}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="edit-delete">
                            <Tooltip title="Edit" disableInteractive>
                              <Button onClick={() => handleEdit(row._id)}>
                                <Edit />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Delete" disableInteractive>
                              <Button onClick={() => setDeleteModal(row._id)}>
                                <Delete />
                              </Button>
                            </Tooltip>
                          </div>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={row._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  : schoolExam.map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.examGrade}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.group}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.gradePoint}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.markFrom}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.markTo}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.note}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="edit-delete">
                            <Tooltip title="Edit" disableInteractive>
                              <Button onClick={() => handleEdit(row._id)}>
                                <Edit />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Delete" disableInteractive>
                              <Button onClick={() => setDeleteModal(row._id)}>
                                <Delete />
                              </Button>
                            </Tooltip>
                          </div>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={row._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <div className="add-icon">
          <AddExamGrade
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            handleUpdate={handleUpdate}
            updateExam={updateExam}
          />
        </div>
      </div>
    </div>
  );
};

export default ExamGrade;
