import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  Assignment,
  Collections,
  Dashboard,
  Event,
  ExpandLess,
  ExpandMore,
  Logout,
  NotificationsActive,
  Public,
  StarBorder,
  SubdirectoryArrowRight,
} from "@mui/icons-material";
import dataSidebar from "./components/Navigation/components/DataSidebar";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import { urls } from "./services/urlConstant";
import { get } from "./services/apis";
import { objHas, subMenuPermissionChecker } from "./utils/utils";
import ContentCopy from "@mui/icons-material/ContentCopy";
import Badge from "@mui/material/Badge";
import styles from "./NavBar.module.css";
import { toast } from "react-toastify";
import { Avatar, Box, Button, Card, Grid, Menu, MenuItem } from "@mui/material";
import { useContext } from "react";
import SettingContext from "./context/SettingsContext";
import NotificationsIcon from "@mui/icons-material/Notifications";
const styless = {
  position: "absolute",
  top: "20%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  height: 300,
  bgcolor: "background.paper",
  p: 4,
};

const style = {
  card: {
    color: "red",
    fontFamily: "cursive",
    textAlign: "start",
  },
  card1: {
    padding: 15,
    marginBottom: 20,
    margin: "70px 200px",
  },
};
const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const NavBar = (props) => {
  const menuItems = [
    {
      title: "Profile",
      link: "/profile",
      children: [],
    },

    {
      title: "Reset Password",
      link: "/resetPassword",
      children: [],
    },
    {
      title: "Log Out",
      link: "/logOut",
      children: [],
    },
  ];

  let [profile, setProfile] = useState(" ");
  let handleProfileChange = (e) => {
    setProfile(e.target.value);
  };
  const theme = useTheme();
  const location = useLocation();
  const [storageData, setStorageData] = useState({});
  const [open, setOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState([]);
  const [settings, setSettings] = useState(false);
  const [activeYear, setActiveYear] = useState("");
  const [isLandingPage, setIsLandingPage] = useState(true);
  const [login, setLogin] = useState(false);
  const [profileLink, setProfileLink] = useState("");
  const [profileId, setProfileId] = useState("");
  const [sideData, setSideDate] = useState(...dataSidebar);
  const { settingsContext, setSettingsContext } = useContext(SettingContext);
  const [tabValue, setTabValue] = useState(0);

  const navigate = useNavigate();
  const { newsId } = useParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open2 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  // useEffect(() => {
  //   setLogin(true)
  // },[props.reRender])
  useEffect(() => {
    const getSettings = async () => {
      try {
        const settings = await get(urls.settings.getAllSettings);
        const res = settings.data.data[0];
        console.log(res, "popo");
        setSettings(res);
        setSettingsContext((prev) => ({
          ...prev,
          schoolName: res ? res.schoolName : "",
          schoolLogo: res ? res.logo.link : "",
        }));
      } catch (error) {
        console.log(error);
      }
    };
    const getactiveYear = async () => {
      try {
        const activeYear = await get(urls.administrator.academicYearGetAll, {
          params: {
            search: {
              status: "active",
            },
          },
        });
        const res = activeYear.data.data[0];
        if (res) {
          setSettingsContext((prev) => ({
            ...prev,
            activeAcademicYear: `${res.academicYearFrom}-${res.academicYearTo}`,
          }));
        }
      } catch (error) {
        console.log(error);
      }
    };
    // const { pathname } = location;
    // const token = window.localStorage.getItem("token");
    getSettings();
    getactiveYear();
    // if (
    //   pathname !== "/" &&
    //   pathname !== "/login" &&
    //   pathname !== "/forgot-password" &&
    //   token
    // ) {
    //   getSettings();
    //   getactiveYear();
    // }
  }, []);

  useEffect(() => {
    setSideDate([]);
  }, []);

  useEffect(() => {
    function setUserRole() {
      const storageData = localStorage.getItem("role");

      if (storageData) {
        setStorageData(JSON.parse(storageData));
      }
    }
    setUserRole();
    window.addEventListener("storage", setUserRole);

    return () => {
      window.removeEventListener("storage", setUserRole);
    };
  }, []);

  useEffect(() => {
    const profileLink = window.localStorage.getItem("empPhoto");
    setProfileLink(profileLink);
    const profileId = window.localStorage.getItem("profileId");
    setProfileId(profileId);
  }, []);

  useEffect(() => {
    console.log(newsId);
    const { pathname } = location;
    const excludePath = [
      "/home-assignment",
      "/home-notice",
      `/home-news/${newsId}`,
      "/home-news",
      "/home-careers",

      "/to-the-knowledge-of-parents",
      "/unique-features",
      "/home-gallery",
      "/results",
      "/guardian-feedback",
      "/contact-us",
      "/facilities/edusmart",
      "/facilities/transport",
      "/",
      "/login",
      "/forgot-password",
      "/principal",
      "/desk",
      "/facality",
      "/addmission",
      "/our-school",
      "/guardian-feedback",
      "/academics",
      "/academics/primary",
      "/academics/high-school",
      "/facilities/library",
      "/facilities/sports",
      "/facilities/canteen",
    ];
    const exclude =
      excludePath.includes(pathname) ||
      pathname.startsWith("/home-news") ||
      pathname.startsWith("/home-notice") ||
      pathname.startsWith("/view-assignment") ||
      pathname.startsWith("/awards-home") ||
      pathname.startsWith("/events-home") ||
      pathname.startsWith("/toppers-list");
    setIsLandingPage(exclude);
  }, [location.pathname]);

  const handleSubClick = (index) => {
    setOpen(true);
    if (menuOpen.includes(index)) {
      setMenuOpen(menuOpen.filter((item) => item !== index));
    } else {
      setMenuOpen([...menuOpen, index]);
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setMenuOpen([]);
    setOpen(false);
  };
  const handleLogout = async () => {
    try {
      const logout = await get(`/logout`);
      if (logout.data.success) {
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("role");
        navigate("/login");
        toast.success("Logout Successful");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <>
      {!isLandingPage && (
        <>
          <AppBar position="fixed" open={open}>
            <Toolbar sx={{ background: "#ffffff", color: "forestgreen" }}>
              <div className={styles.toolBarContainer}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    ...(open && { visibility: "hidden" }),
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <div className={styles.barHeader}>
                  <Typography variant="h6" noWrap component="div">
                    {settingsContext.schoolName}-
                    {settingsContext.activeAcademicYear}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "1rem",
                  }}
                >
                  {/* <Link to={`/profile-view/${profileId}`}> */}
                  {/* <div onClick={handleDropdownToggle}> */}
                  {/* <Avatar src={`${profileLink}`} /> */}
                  {/* <Avatar />
                  </div> */}
                  {/* </Link> */}
                  <div style={{ marginTop: "3px" }}>
                    <Link style={{ color: "inherit" }} to="/">
                      <IconButton color="inherit">
                        <Public />
                      </IconButton>
                    </Link>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <Badge
                      badgeContent={1}
                      color="success"
                      sx={{
                        "& .MuiBadge-badge": {
                          // fontSize: 12,
                          minWidth: 18,
                          height: 18,
                        },
                      }}
                    >
                      <NotificationsIcon color="action" />
                    </Badge>
                  </div>

                  <Button
                    id="basic-button"
                    aria-controls={open2 ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open2 ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <Avatar />
                  </Button>

                  {/* <div>
                    <IconButton onClick={handleLogout} color="inherit">
                      <Logout />
                    </IconButton>
                  </div> */}
                </div>
              </div>
            </Toolbar>
            <div>
              <Menu
                id="basic-menu"
                sx={{ width: 227 }}
                anchorEl={anchorEl}
                open={open2}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleClose}>
                  <a href="/profile">
                    <p style={{ color: "black" }}>Profile</p>
                  </a>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <a href="/resetPassword">
                    <p style={{ color: "black" }}>Reset Password</p>
                  </a>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <ListItemText> Log Out</ListItemText>
                  <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                  </ListItemIcon>
                </MenuItem>
              </Menu>
            </div>

            {/* {isDropdownOpen && (
              <Card>
                <ul>
                  {menuItems.map((item, index) => (
                    <li key={index}>
                      <a
                        href={item.link}
                        style={{
                          color: "black",
                        }}>
                        {item.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </Card>
            )} */}
          </AppBar>

          <Drawer variant="permanent" open={open}>
            <DrawerHeader style={{ gap: "45px" }}>
              {settings && (
                <img
                  src={settingsContext.schoolLogo}
                  alt="logo"
                  style={{
                    height: "90%",
                    objectFit: "contain",
                    width: "auto",
                    maxWidth: "100px",
                    // ma
                  }}
                />
              )}
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              <ListItem disablePadding sx={{ display: "block" }}>
                <Link
                  to={"/dashboard"}
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      mr: !open && 3,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <Dashboard />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Dashboard"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
              {dataSidebar.map((menu, index) => {
                const { renderName } = menu;
                const value = subMenuPermissionChecker(renderName, storageData)
                  ? true
                  : false;

                return (
                  value && (
                    <ListItem
                      key={index}
                      className="side-item"
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <ListItemButton
                        onClick={() => handleSubClick(index)}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          {menu.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={menu.name}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                        {menuOpen.includes(index) ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItemButton>
                      {menu.subMenu && (
                        <Collapse
                          in={menuOpen.includes(index)}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {menu.subMenu.map((ele, i) => {
                              const { renderName } = ele;
                              const value = storageData[renderName]
                                ? storageData[renderName]["accessible"]
                                : true;
                              return (
                                value && (
                                  <NavLink
                                    style={({ isActive }) =>
                                      isActive
                                        ? {
                                            backgroundColor: "#1b3779",
                                            color: "white",
                                            ":hover": { background: "#1b3779" },
                                          }
                                        : { color: "#1b3779" }
                                    }
                                    key={i}
                                    to={ele.path}
                                  >
                                    <ListItemButton
                                      sx={{
                                        pl: 4,
                                        backgroundColor: "inherit",
                                        transition: "0.3s",
                                        color: "inherit",
                                        ":hover": {
                                          background: "#1b3779",
                                          color: "white",
                                        },
                                      }}
                                    >
                                      <ListItemIcon sx={{ color: "inherit" }}>
                                        <SubdirectoryArrowRight
                                          sx={{ color: "inherit" }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        style={{ whiteSpace: "pre-line" }}
                                        primary={ele.name}
                                      />
                                    </ListItemButton>
                                  </NavLink>
                                )
                              );
                            })}
                          </List>
                        </Collapse>
                      )}
                    </ListItem>
                  )
                );
              })}
              <ListItem
                disablePadding
                sx={{ display: "block" }}
                className="side-item"
              >
                <Link
                  to={"/assignment"}
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      mr: !open && 3,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <Assignment />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Assignment"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
              <ListItem
                disablePadding
                sx={{ display: "block" }}
                className="side-item"
              >
                <Link
                  to={"/gallery"}
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      mr: !open && 3,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <Collections />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Gallery"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
              <ListItem
                disablePadding
                sx={{ display: "block" }}
                className="side-item"
              >
                <Link
                  to={"/text-message"}
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      mr: !open && 3,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <NotificationsActive />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Notifications"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
              <ListItem
                disablePadding
                sx={{ display: "block" }}
                className="side-item"
              >
                <Link
                  to={"/event"}
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      mr: !open && 3,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <Event />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Event"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
            </List>
            <Divider />
          </Drawer>
        </>
      )}
    </>
  );
};

export default NavBar;
