import React from "react";
import {
  Card,
  Box,
  FormControl,
  Stack,
  Avatar,
  Button,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  CircularProgress,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useState, useEffect } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { get } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import { toast } from "react-toastify";

const styles = {
  card: {
    padding: 10,
    margin: "0px 0px 20px 0px",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
    m: 1,
  },
};

const AttendanceOverview = () => {
  const [date, setDate] = useState(dayjs());
  const [attendanceDetails, setAttendanceDetails] = useState({});
  const [classes, setClasses] = useState([]);
  const [attendanceStudents, setAttendanceStudents] = useState([]);
  const [absentDownloadLoader, setAbsentDownloadLoader] = useState("");

  const [sort, setSort] = useState({
    sectionName: "asc",
    presentTotal: "asc",
    absentTotal: "asc",
    percentage: "asc",
  });

  const sortBySection = () => {
    console.log("Section");
    if (sort.sectionName === "asc") {
      let sortedList = [
        ...attendanceStudents.sort((a, b) =>
          b.sectionName.localeCompare(a.sectionName)
        ),
      ];

      setAttendanceStudents(sortedList);
      setSort({ ...sort, sectionName: "des" });
    } else {
      let sortedList = [
        ...attendanceStudents.sort((a, b) =>
          a.sectionName.localeCompare(b.sectionName)
        ),
      ];
      setAttendanceStudents(sortedList);
      setSort({ ...sort, sectionName: "asc" });
    }
  };

  const sortByPresent = (e) => {
    console.log("Present");
    if (sort.presentTotal === "asc") {
      let sortedList = [
        ...attendanceStudents.sort((a, b) => b.presentTotal - a.presentTotal),
      ];

      setAttendanceStudents(sortedList);
      setSort({ ...sort, presentTotal: "des" });
    } else {
      let sortedList = [
        ...attendanceStudents.sort((a, b) => a.presentTotal - b.presentTotal),
      ];
      setAttendanceStudents(sortedList);
      setSort({ ...sort, presentTotal: "asc" });
    }
  };

  const sortByAbsent = (e) => {
    console.log("Absent");
    if (sort.absentTotal === "asc") {
      let sortedList = [
        ...attendanceStudents.sort((a, b) => b.absentTotal - a.absentTotal),
      ];

      setAttendanceStudents(sortedList);
      setSort({ ...sort, absentTotal: "des" });
    } else {
      let sortedList = [
        ...attendanceStudents.sort((a, b) => a.absentTotal - b.absentTotal),
      ];
      setAttendanceStudents(sortedList);
      setSort({ ...sort, absentTotal: "asc" });
    }
  };

  const sortByPercentage = (e) => {
    console.log("Percentage");
    if (sort.percentage === "asc") {
      let sortedList = [
        ...attendanceStudents.sort((a, b) => b.percentage - a.percentage),
      ];

      setAttendanceStudents(sortedList);
      setSort({ ...sort, percentage: "des" });
    } else {
      let sortedList = [
        ...attendanceStudents.sort((a, b) => a.percentage - b.percentage),
      ];
      setAttendanceStudents(sortedList);
      setSort({ ...sort, percentage: "asc" });
    }
  };

  const handleClasses = async (e) => {
    const { name, value } = e.target;
    setAttendanceDetails({ ...attendanceDetails, [name]: value });
  };

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        if (res.data.success && res.data.data.length) {
          setAttendanceDetails((prev) => ({
            ...prev,
            schoolClass: res.data.data[0]._id,
          }));
          setClasses(res.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);

  useEffect(() => {
    const getOverview = async () => {
      try {
        if (Object.keys(attendanceDetails).length > 0) {
          const res = await get(
            `/students-attendance-overview/${
              attendanceDetails.schoolClass
            }/${dayjs(date).format("YYYY-MM-DD")}`
          );
          setAttendanceStudents(res.data.data);
          console.log(res.data.data, "vvvvvvvv");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getOverview();
  }, [attendanceDetails]);

  const handleAbsentDownload = async (sectionName) => {
    try {
      setAbsentDownloadLoader(sectionName);
      const absentDownload = await get(
        `/students-absent-pdf/${
          attendanceDetails.schoolClass
        }/${sectionName}/${dayjs(date).format("YYYY-MM-DD")}`,
        {
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(absentDownload.data);
      const link = document.createElement("a");
      link.href = uri;
      link.setAttribute("download", "absentStudents.pdf");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setAbsentDownloadLoader("");
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <div>
      <div>
        <Card>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl variant="standard" sx={styles.textfield} required>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    Class
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="schoolClass"
                    value={attendanceDetails.schoolClass || ""}
                    onChange={(event) => handleClasses(event)}
                  >
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4} sx={{ alignSelf: "center" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={2}>
                    <DatePicker
                      disableFuture
                      variant="standard"
                      label="Date"
                      openTo="day"
                      views={["day", "month", "year"]}
                      inputFormat="DD-MM-YYYY"
                      value={date}
                      onChange={(newValue) => {
                        setDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField variant="standard" {...params} />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{ background: "rgb(27 55 121)" }}>
              <TableRow>
                <TableCell align="center">
                  <span className="class-table-header">Section</span>
                  <span
                    onClick={sortBySection}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.sectionName === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">present</span>

                  <span
                    onClick={sortByPresent}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.presentTotal === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Absent</span>
                  <span
                    onClick={sortByAbsent}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.absentTotal === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Percentage</span>
                  <span
                    onClick={sortByPercentage}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.percentage === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attendanceStudents.length > 0 &&
                attendanceStudents.map((attendanceStudent, index) => (
                  <TableRow
                    key={attendanceStudent._id}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {attendanceStudent.sectionName}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {attendanceStudent.presentTotal}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {attendanceStudent.absentTotal}{" "}
                      {absentDownloadLoader ===
                      attendanceStudent.sectionName ? (
                        <Button variant="text">Downloading...</Button>
                      ) : (
                        <Button
                          onClick={() =>
                            handleAbsentDownload(attendanceStudent.sectionName)
                          }
                          variant="text"
                        >
                          Download
                        </Button>
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {attendanceStudent.percentage !== "NaN"
                        ? attendanceStudent.percentage
                        : "NA"}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!attendanceStudents.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
      </Paper>
    </div>
  );
};

export default AttendanceOverview;
