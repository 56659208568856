import React from "react";
import {
  Grid,
  Card,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Tooltip,
  Button,
  Autocomplete,
  Popper,
  Checkbox,
  styled,
  InputBase,
  InputAdornment,
} from "@mui/material";
import { useState, useEffect } from "react";
import { post, get } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import { toast } from "react-toastify";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import LoadingButton from "@mui/lab/LoadingButton";

const styles = {
  card1: {
    padding: 15,
    marginBottom: 20,
    margin: "50px 200px",
  },
  card: {
    padding: 15,
    margin: "20px 1rem",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
    m: 0.5,
  },
};
const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,
  width: "100%",

  borderBottom: `1px solid ${
    theme.palette.mode === "light" ? "#eaecef" : "#30363d"
  }`,
  "& input": {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#0d1117",
    padding: 8,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    border: `1px solid ${
      theme.palette.mode === "light" ? "#eaecef" : "#30363d"
    }`,
    fontSize: 14,
    "&:focus": {
      boxShadow: `0px 0px 0px 3px ${
        theme.palette.mode === "light"
          ? "rgba(3, 102, 214, 0.3)"
          : "rgb(12, 45, 107)"
      }`,
      borderColor: theme.palette.mode === "light" ? "#0366d6" : "#388bfd",
    },
  },
}));
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Credentails = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [roles, setRoles] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectEmployee, setSelectEmployee] = useState("");
  const [selectRoles, setSelectRoles] = useState([]);
  const [employeeListPopper, setEmployeeListPopper] = useState(false);
  const [employeeAutoSelect, setEmployeeAutoSelect] = useState([]);
  const [classPopper, setClassPopper] = useState(false);
  const [sectionPopper, setSectionPopper] = useState(false);
  const [selectClass, setSelectClass] = useState("");
  const [classes, setClasses] = useState([]);
  const [contactsPopper, setContactsPopper] = useState(false);
  const [sections, setSections] = useState([]);
  const [selectSection, setSelectSection] = useState("");
  const [selectContacts, setSelectContacts] = useState("");
  const [classAutoSelect, setClassAutoSelect] = useState([]);
  const [sectionAutoSelect, setSectionAutoSelect] = useState([]);
  const [students, setStudents] = useState([]);
  const [contactsAutoSelect, setContactsAutoSelect] = useState([]);
  const [contacts, setContacts] = useState([]);

  const [selectedStudentCount, setSelectedStudentCount] = useState(0);
  const [selectedEmployeeCount, setSelectedEmployeeCount] = useState(0);

  useEffect(() => {
    const getRoles = async () => {
      try {
        const response = await get(urls.role.getAllRole);
        setRoles(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    const getClasses = async () => {
      try {
        const classes = await get(urls.class.getAllClass);
        if (classes.data.success) {
          setClasses(classes.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getRoles();
    getClasses();
    console.log("classes", classes);
  }, []);

  //useEffect.......

  const handleClassSelect = async (e, val) => {
    const classIds = val.map((schclass) => schclass._id);
    const classNames = val.map((schclass) => schclass.className);
    setClassAutoSelect(val);
    setSelectClass(classNames.join(","));

    try {
      if (val.length) {
        const getSections = await get(urls.schoolSection.getAllSection, {
          params: {
            search: {
              class: {
                $in: classIds,
              },
            },
          },
        });
        if (getSections.data.success) {
          const sortedSections = getSections.data.data.sort((a, b) =>
            a.sectionName.localeCompare(b.sectionName)
          );
          setSections(sortedSections);
        }
      } else {
        setSections([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSectionSelect = async (e, val) => {
    const sectionIds = val.map((section) => section._id);
    const sectionNames = val.map((schclass) => schclass.sectionName);
    const classNames = val.map((schclass) => schclass.class.className);
    setSectionAutoSelect(val);
    setSelectSection(
      sectionNames
        .map((name, index) => `${name} (${classNames[index]})`)
        .join(", ")
    );
    try {
      if (val.length) {
        const getStudents = await post(`/sections/all-students`, {
          sectionIds,
        });
        if (getStudents.data.success) {
          setStudents(getStudents.data.data);
        }
      } else {
        setStudents([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleContactsList = (event, val) => {
    const findAll = val.some((item) => item._id === "all");

    if (findAll) {
      const allStudentsContacts = students.map((student) => student._id);
      setContacts(allStudentsContacts);
      setSelectedStudentCount(students.length);
      setSelectContacts("All");
    } else {
      const studentNames = val.map((schclass) => schclass.basicInfo.name);
      const contacts = val.map((ele) => ele._id);
      setContacts(contacts);
      setSelectedStudentCount(val.length);
      setSelectContacts(studentNames.join(", "));
    }

    setContactsAutoSelect(val);
  };

  useEffect(() => {
    const getRole = async () => {
      try {
        const res = await get(urls.role.getAllRole);
        setRoles(res.data.data);
        console.log("asdfg", res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getRole();
  }, []);

  const handleRoleChange = async (e) => {
    try {
      const {
        target: { value },
      } = e;
      setSelectRoles(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
      const getEmployees = await get(urls.employee.getAllEmployee, {
        params: {
          search: {
            role: {
              $in: value,
            },
          },
        },
      });
      if (getEmployees.data.success) setEmployees(getEmployees.data.data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  //  const handleEmployeeAuto = (e, val) => {
  //     setEmployeeAutoSelect(val);
  //     setSelectEmployee(val.map((emp) => emp?.basicInfo?.empName).join(","));
  //   };

  const handleEmployeeAuto = (event, val) => {
    setEmployeeAutoSelect(val);
    if (val.filter((v) => v._id == "all").length) {
      setSelectEmployee("All");
      setSelectedEmployeeCount(employees.length);
      return;
    }
    const selectedEmployees = val.filter((emp) => emp._id !== "all");

    setSelectedEmployeeCount(selectedEmployees.length);

    if (selectedEmployees.length === employees.length) {
      setSelectEmployee("All");
    } else {
      setSelectEmployee(
        selectedEmployees.map((emp) => emp.basicInfo.empName).join(", ")
      );
    }

    setEmployeeAutoSelect(val);
  };

  const handleFormChange = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSendPassword = async (e) => {
    setLoading(true);
    e.preventDefault();

    console.log(
      selectRoles.some((roleId) => {
        const role = roles.find((r) => r._id === roleId);
        return role && role.roleName.includes("Student");
      }),
      "check"
    );

    try {
      if (
        selectRoles.some((roleId) => {
          const role = roles.find((r) => r._id === roleId);
          return role && role.roleName.includes("Student");
        })
      ) {
        let studentIds;
        let findAllStudents = contactsAutoSelect.filter(
          (stud) => stud._id === "all"
        ).length;

        if (findAllStudents) {
          studentIds = contacts;
        } else {
          studentIds = contactsAutoSelect.map((c) => c._id);
        }

        console.log(studentIds, "stu");

        await post(urls.communication.sendStudentCredentials, {
          studentIds,
          smsCategory: "Login Credentials",
        });

        if (
          selectRoles.length === 1 &&
          selectRoles.some((roleId) => {
            const role = roles.find((r) => r._id === roleId);
            return role && role.roleName.includes("Student");
          })
        )
          return setLoading(false);
      }

      let employeeIds;
      let findAll = employeeAutoSelect.filter(
        (emp) => emp._id === "all"
      ).length;
      if (findAll) {
        employeeIds = employees.map((emp) => emp._id);
      } else {
        employeeIds = employeeAutoSelect.map((emp) => emp._id);
      }
      console.log(employeeIds, "emp");

      await post(urls.communication.sendEmployeeCredentials, {
        employees: employeeIds,
        smsCategory: "Login Credentials",
      });

      setLoading(false);
    } catch (error) {
      toast.error(error.message);

      setLoading(false);
    }
  };

  const isStudentRoleSelected = selectRoles.some((roleId) => {
    const role = roles.find((r) => r._id === roleId);
    return role && role.roleName.includes("Student");
  });

  return (
    <div>
      <Card style={styles.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span
              style={{
                color: "red",
                fontFamily: "cursive",
              }}
            >
              Send credentails
            </span>
          </Grid>
        </Grid>
      </Card>
      <Card style={styles.card1}>
        <Box sx={{ flexGrow: 1 }}>
          <form onSubmit={handleSendPassword}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={12} lg={12}>
                <FormControl variant="standard" fullWidth required>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    User Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="role"
                    sx={{ marginBottom: "15px" }}
                    multiple
                    onChange={handleRoleChange}
                    value={selectRoles}
                  >
                    {roles &&
                      roles.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.roleName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {selectRoles.some((roleId) => {
                  const role = roles.find((r) => r._id === roleId);
                  return role && role.roleName.includes("Student");
                }) && selectRoles.length == 1 ? (
                  <>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        value={selectClass}
                        fullWidth
                        sx={{ marginBottom: "15px" }}
                        aria-describedby={"classPopper"}
                        onFocus={(e) => {
                          setClassPopper(classPopper ? null : e.currentTarget);
                        }}
                        variant="standard"
                        label="Class"
                      />
                      <Popper
                        style={{ width: "70%" }}
                        id={"classPopper"}
                        open={!!classPopper}
                        anchorEl={classPopper}
                      >
                        <FormControl
                          variant="standard"
                          fullWidth
                          sx={{ m: 1, width: "100%" }}
                        >
                          <Autocomplete
                            onBlur={() => setClassPopper(null)}
                            open={true}
                            value={classAutoSelect}
                            multiple
                            onChange={handleClassSelect}
                            isOptionEqualToValue={(option, value) =>
                              option._id === value._id
                            }
                            options={classes}
                            disableCloseOnSelect
                            getOptionLabel={(option) => `${option.className}`}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  checked={selected}
                                />
                                {`${option.className}`}
                              </li>
                            )}
                            renderInput={(params) => (
                              <StyledInput
                                ref={params.InputProps.ref}
                                inputProps={params.inputProps}
                                placeholder="Class"
                                autoFocus
                              />
                            )}
                          />
                        </FormControl>
                      </Popper>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        value={selectSection}
                        aria-describedby={"sectionPopper"}
                        sx={{ marginBottom: "15px" }}
                        fullWidth
                        onFocus={(e) => {
                          setSectionPopper(
                            sectionPopper ? null : e.currentTarget
                          );
                        }}
                        variant="standard"
                        label="Section"
                      />
                      <Popper
                        style={{ width: "70%" }}
                        id={"sectionPopper"}
                        open={!!sectionPopper}
                        anchorEl={sectionPopper}
                      >
                        <FormControl
                          variant="standard"
                          fullWidth
                          sx={{ m: 1, width: "100%" }}
                        >
                          <Autocomplete
                            multiple
                            onBlur={() => setSectionPopper(null)}
                            open={true}
                            value={sectionAutoSelect}
                            onChange={handleSectionSelect}
                            isOptionEqualToValue={(option, value) =>
                              option._id === value._id
                            }
                            id="checkboxes-tags-demo"
                            options={sections.sort((a, b) =>
                              a.class.className.localeCompare(b.class.className)
                            )}
                            disableCloseOnSelect
                            getOptionLabel={(option) =>
                              `${option.sectionName}(class:${option.class.className})`
                            }
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {`${option.sectionName}(class:${option.class.className})`}
                              </li>
                            )}
                            renderInput={(params) => (
                              <StyledInput
                                ref={params.InputProps.ref}
                                inputProps={params.inputProps}
                                placeholder="Section"
                                autoFocus
                              />
                            )}
                          />
                        </FormControl>
                      </Popper>
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        value={selectContacts}
                        aria-describedby={"contactsPopper"}
                        fullWidth
                        onFocus={(e) => {
                          setContactsPopper(
                            contactsPopper ? null : e.currentTarget
                          );
                        }}
                        variant="standard"
                        label="Students"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              All Total-{selectedStudentCount}
                            </InputAdornment>
                          ),
                        }}
                      />

                      <Popper
                        style={{ width: "70%" }}
                        id={"contactsPopper"}
                        open={!!contactsPopper}
                        anchorEl={contactsPopper}
                      >
                        <FormControl
                          variant="standard"
                          sx={{ m: 1, width: "100%" }}
                        >
                          <Autocomplete
                            multiple
                            onBlur={() => setContactsPopper(null)}
                            open={true}
                            value={contactsAutoSelect}
                            onChange={handleContactsList}
                            isOptionEqualToValue={(option, value) =>
                              option._id === value._id
                            }
                            id="checkboxes-tags-demo"
                            options={[
                              {
                                _id: "all",
                                basicInfo: {
                                  name: "All",
                                },
                                contactNumber: "",
                              },
                              ...students,
                            ]}
                            disableCloseOnSelect
                            getOptionLabel={(option) =>
                              option._id === "all"
                                ? "All"
                                : `${option.basicInfo.name} (${option.contactNumber})`
                            }
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {/* {option._id === "all"
                                  ? "All"
                                  : option.basicInfo.name} */}
                                {`${option.basicInfo.name} (${option.contactNumber})`}
                              </li>
                            )}
                            renderInput={(params) => (
                              <StyledInput
                                ref={params.InputProps.ref}
                                inputProps={params.inputProps}
                                placeholder="Students"
                                autoFocus
                              />
                            )}
                          />
                        </FormControl>
                      </Popper>
                    </Grid>
                  </>
                ) : null}
              </Grid>
              {!isStudentRoleSelected ? (
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    fullWidth
                    value={selectEmployee}
                    aria-describedby={"employeePopper"}
                    onFocus={(e) => {
                      setEmployeeListPopper(
                        employeeListPopper ? null : e.currentTarget
                      );
                    }}
                    variant="standard"
                    label="Employees"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {/* {employeeAutoSelect.some((emp) => emp._id === "all")
                          ? `All Total - ${employees.length}`
                          : ""} */}
                          All Total- {selectedEmployeeCount}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Popper
                    style={{ width: "70%" }}
                    id={"employeePopper"}
                    open={!!employeeListPopper}
                    anchorEl={employeeListPopper}
                  >
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, width: "100%" }}
                    >
                      <Autocomplete
                        onBlur={() => setEmployeeListPopper(null)}
                        open={true}
                        value={employeeAutoSelect}
                        multiple
                        onChange={handleEmployeeAuto}
                        isOptionEqualToValue={(option, value) =>
                          option._id === value._id
                        }
                        options={[
                          {
                            _id: "all",
                            basicInfo: { empName: "All" },
                          },
                          ...employees,
                        ]}
                        disableCloseOnSelect
                        getOptionLabel={(option) =>
                          `${option?.basicInfo.empName} (${option?.contactNumber})`
                        }
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              checked={selected}
                            />
                            {`${option?.basicInfo.empName} (${option?.contactNumber})`}
                          </li>
                        )}
                        renderInput={(params) => (
                          <StyledInput
                            ref={params.InputProps.ref}
                            inputProps={params.inputProps}
                            placeholder="Employees"
                            autoFocus
                          />
                        )}
                      />
                    </FormControl>
                  </Popper>
                </Grid>
              ) : null}

              {selectRoles.length >= 2 &&
              selectRoles.some((roleId) => {
                const role = roles.find((r) => r._id === roleId);
                return role && role.roleName.includes("Student");
              }) ? (
                <>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      value={selectClass}
                      fullWidth
                      sx={{ marginBottom: "15px" }}
                      aria-describedby={"classPopper"}
                      onFocus={(e) => {
                        setClassPopper(classPopper ? null : e.currentTarget);
                      }}
                      variant="standard"
                      label="Class"
                    />
                    <Popper
                      style={{ width: "70%" }}
                      id={"classPopper"}
                      open={!!classPopper}
                      anchorEl={classPopper}
                    >
                      <FormControl
                        variant="standard"
                        fullWidth
                        sx={{ m: 1, width: "100%" }}
                      >
                        <Autocomplete
                          onBlur={() => setClassPopper(null)}
                          open={true}
                          value={classAutoSelect}
                          multiple
                          onChange={handleClassSelect}
                          isOptionEqualToValue={(option, value) =>
                            option._id === value._id
                          }
                          options={classes}
                          disableCloseOnSelect
                          getOptionLabel={(option) => `${option.className}`}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                checked={selected}
                              />
                              {`${option.className}`}
                            </li>
                          )}
                          renderInput={(params) => (
                            <StyledInput
                              ref={params.InputProps.ref}
                              inputProps={params.inputProps}
                              placeholder="Class"
                              autoFocus
                            />
                          )}
                        />
                      </FormControl>
                    </Popper>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      value={selectSection}
                      aria-describedby={"sectionPopper"}
                      sx={{ marginBottom: "15px" }}
                      fullWidth
                      onFocus={(e) => {
                        setSectionPopper(
                          sectionPopper ? null : e.currentTarget
                        );
                      }}
                      variant="standard"
                      label="Section"
                    />
                    <Popper
                      style={{ width: "70%" }}
                      id={"sectionPopper"}
                      open={!!sectionPopper}
                      anchorEl={sectionPopper}
                    >
                      <FormControl
                        variant="standard"
                        fullWidth
                        sx={{ m: 1, width: "100%" }}
                      >
                        <Autocomplete
                          multiple
                          onBlur={() => setSectionPopper(null)}
                          open={true}
                          value={sectionAutoSelect}
                          onChange={handleSectionSelect}
                          isOptionEqualToValue={(option, value) =>
                            option._id === value._id
                          }
                          id="checkboxes-tags-demo"
                          options={sections.sort((a, b) =>
                            a.class.className.localeCompare(b.class.className)
                          )}
                          disableCloseOnSelect
                          getOptionLabel={(option) =>
                            `${option.sectionName}(class:${option.class.className})`
                          }
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {`${option.sectionName}(class:${option.class.className})`}
                            </li>
                          )}
                          renderInput={(params) => (
                            <StyledInput
                              ref={params.InputProps.ref}
                              inputProps={params.inputProps}
                              placeholder="Section"
                              autoFocus
                            />
                          )}
                        />
                      </FormControl>
                    </Popper>
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      value={selectContacts}
                      aria-describedby={"contactsPopper"}
                      fullWidth
                      onFocus={(e) => {
                        setContactsPopper(
                          contactsPopper ? null : e.currentTarget
                        );
                      }}
                      variant="standard"
                      label="Students"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            All Total-{selectedStudentCount}
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Popper
                      style={{ width: "70%" }}
                      id={"contactsPopper"}
                      open={!!contactsPopper}
                      anchorEl={contactsPopper}
                    >
                      <FormControl
                        variant="standard"
                        sx={{ m: 1, width: "100%" }}
                      >
                        <Autocomplete
                          multiple
                          onBlur={() => setContactsPopper(null)}
                          open={true}
                          value={contactsAutoSelect}
                          onChange={handleContactsList}
                          isOptionEqualToValue={(option, value) =>
                            option._id === value._id
                          }
                          id="checkboxes-tags-demo"
                          options={[
                            {
                              _id: "all",
                              basicInfo: {
                                name: "All",
                              },
                              contactNumber: "",
                            },
                            ...students,
                          ]}
                          disableCloseOnSelect
                          getOptionLabel={(option) =>
                            option._id === "all"
                              ? "All"
                              : `${option.basicInfo.name} (${option.contactNumber})`
                          }
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {/* {option._id === "all"
                                  ? "All"
                                  : option.basicInfo.name} */}
                              {`${option.basicInfo.name} (${option.contactNumber})`}
                            </li>
                          )}
                          renderInput={(params) => (
                            <StyledInput
                              ref={params.InputProps.ref}
                              inputProps={params.inputProps}
                              placeholder="Students"
                              autoFocus
                            />
                          )}
                        />
                      </FormControl>
                    </Popper>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      fullWidth
                      value={selectEmployee}
                      aria-describedby={"employeePopper"}
                      onFocus={(e) => {
                        setEmployeeListPopper(
                          employeeListPopper ? null : e.currentTarget
                        );
                      }}
                      variant="standard"
                      label="Employees"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {/* {employeeAutoSelect.some((emp) => emp._id === "all")
                          ? `All Total - ${employees.length}`
                          : ""} */}
                            All Total- {selectedEmployeeCount}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Popper
                      style={{ width: "70%" }}
                      id={"employeePopper"}
                      open={!!employeeListPopper}
                      anchorEl={employeeListPopper}
                    >
                      <FormControl
                        variant="standard"
                        sx={{ m: 1, width: "100%" }}
                      >
                        <Autocomplete
                          onBlur={() => setEmployeeListPopper(null)}
                          open={true}
                          value={employeeAutoSelect}
                          multiple
                          onChange={handleEmployeeAuto}
                          isOptionEqualToValue={(option, value) =>
                            option._id === value._id
                          }
                          options={[
                            {
                              _id: "all",
                              basicInfo: { empName: "All" },
                            },
                            ...employees,
                          ]}
                          disableCloseOnSelect
                          getOptionLabel={(option) =>
                            `${option?.basicInfo.empName} (${option?.contactNumber})`
                          }
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                checked={selected}
                              />
                              {`${option?.basicInfo.empName} (${option?.contactNumber})`}
                            </li>
                          )}
                          renderInput={(params) => (
                            <StyledInput
                              ref={params.InputProps.ref}
                              inputProps={params.inputProps}
                              placeholder="Employees"
                              autoFocus
                            />
                          )}
                        />
                      </FormControl>
                    </Popper>
                  </Grid>
                </>
              ) : (
                ""
              )}

              <Grid
                item
                xs={12}
                md={12}
                lg={6}
                container
                // direction="row"
                // justifyContent="right"
                // alignItems="end"
              >
                <LoadingButton
                  variant="contained"
                  loading={loading}
                  type="submit"
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    backgroundColor: "#1b3779",
                    ":hover": { backgroundColor: "#1b3779" },
                  }}
                >
                  Send Credentials
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Card>
    </div>
  );
};

export default Credentails;
