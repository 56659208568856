import React,{useEffect,useState} from 'react';
import AddAdmitStudent from './AddAdmitStudent';
import {useParams} from "react-router-dom";
import { get } from '../../../services/apis';
import { urls } from '../../../services/urlConstant';

const EditStudent = (props) => {
    const [student,setStudent] = useState(undefined);
    const {studentId} = useParams();

    useEffect(() => {
        const getStudent = async () => {
            try {
                const res = await get(urls.students.getStudent+studentId);
                setStudent(res.data.data);
            } catch (error) {
                console.log(error)
            }
        }
        getStudent();
    },[studentId])
    return(     
        <div>
            {student ? (<AddAdmitStudent student={student} />):(<h1>loading</h1> )}
        </div>
    )
}

export default EditStudent;