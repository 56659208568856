import React, { useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Card,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  TablePagination,
} from "@mui/material";
import { del, get, post, put } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import { Delete, Edit, Search } from "@mui/icons-material";
import TableHead from "@mui/material/TableHead";
import Navigation from "../../Navigation/components/Navigation";
import SearchbarAndFilter from "../Administrator/SearchbarAndFilter";
import Sidebar from "../../Navigation/components/Sidebar";
import { useEffect } from "react";
import AddAwards from "./AddAwards";
import DeleteModal from "../Academic/DeleteModal";

import dayjs from "dayjs";

import styles from "../../../styles/components/Layout/Administrator/UserRole.module.css";

const Awards = () => {
  const [event, setEvent] = React.useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [updateEvent, setUpdateEvent] = React.useState(undefined);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [showSearch, setShowSearch] = useState(false);

  const [sort, setSort] = useState({
    title: "asc",
    location: "asc",
    webView: "asc",
    date: "asc",
  });

  const sortByTitle = () => {
    console.log("Title");
    if (search) {
      if (sort.title === "asc") {
        let sortedList = [
          ...searchFilter.sort((a, b) => b.title.localeCompare(a.title)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, title: "des" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) => a.title.localeCompare(b.title)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, title: "asc" });
      }
    } else {
      if (sort.title === "asc") {
        let sortedList = [
          ...event.sort((a, b) => b.title.localeCompare(a.title)),
        ];
        setEvent(sortedList);
        setSort({ ...sort, title: "des" });
      } else {
        let sortedList = [
          ...event.sort((a, b) => a.title.localeCompare(b.title)),
        ];
        setEvent(sortedList);
        setSort({ ...sort, title: "asc" });
      }
    }
  };

  const sortByLocation = () => {
    console.log("Location");
    if (search) {
      if (sort.location === "des") {
        let sortedList = [
          ...searchFilter.sort((a, b) => b.location.localeCompare(a.location)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, location: "asc" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) => a.location.localeCompare(b.location)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, location: "des" });
      }
    } else {
      if (sort.location === "des") {
        let sortedList = [
          ...event.sort((a, b) => b.location.localeCompare(a.location)),
        ];
        setEvent(sortedList);
        setSort({ ...sort, location: "asc" });
      } else {
        let sortedList = [
          ...event.sort((a, b) => a.location.localeCompare(b.location)),
        ];
        setEvent(sortedList);
        setSort({ ...sort, location: "des" });
      }
    }
  };

  const sortByWebView = () => {
    console.log("WebView");
    if (search) {
      if (sort.webView === "asc") {
        let sortedList = [
          ...searchFilter.sort((a, b) => b.webView.localeCompare(a.webView)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, webView: "des" });
      } else {
        let sortedList = [
          ...searchFilter.sort((a, b) => a.webView.localeCompare(b.webView)),
        ];
        setSearchFilter(sortedList);
        setSort({ ...sort, webView: "asc" });
      }
    } else {
      if (sort.webView === "asc") {
        let sortedList = [
          ...event.sort((a, b) => b.webView.localeCompare(a.webView)),
        ];
        setEvent(sortedList);
        setSort({ ...sort, webView: "des" });
      } else {
        let sortedList = [
          ...event.sort((a, b) => a.webView.localeCompare(b.webView)),
        ];
        setEvent(sortedList);
        setSort({ ...sort, webView: "asc" });
      }
    }
  };

  const compareDatesAsc = (a, b) => {
    let dateA = new Date(a.date);
    let dateB = new Date(b.date);

    return dateA - dateB;
  };

  const compareDatesDec = (a, b) => {
    let dateA = new Date(a.date);
    let dateB = new Date(b.date);

    return dateB - dateA;
  };

  const sortByDate = () => {
    console.log("Date");
    if (search) {
      if (sort.date === "asc") {
        let sortedList = [...searchFilter.sort(compareDatesDec)];
        setSearchFilter(sortedList);
        setSort({ ...sort, date: "des" });
      } else {
        let sortedList = [...searchFilter.sort(compareDatesAsc)];
        setSearchFilter(sortedList);
        setSort({ ...sort, date: "asc" });
      }
    } else {
      if (sort.date === "asc") {
        let sortedList = [...event.sort(compareDatesDec)];
        setEvent(sortedList);
        setSort({ ...sort, date: "des" });
      } else {
        let sortedList = [...event.sort(compareDatesAsc)];
        setEvent(sortedList);
        setSort({ ...sort, date: "asc" });
      }
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUpdateEvent(undefined);
  };

  const handleDelete = async (id) => {
    try {
      const res = await del(`/award/delete/`, id);
      console.log(res);
      if (res.data.success) {
        const filteredEvent = event.filter((item) => item._id !== id);
        setEvent(filteredEvent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = async (e, data) => {
    e.preventDefault();
    try {
      // e.preventDefault();
      const res = await post(`/award/create`, data);
      setEvent([...event, res.data.result]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async (data, id) => {
    try {
      const response = await put(`/award/update/`, id, data);
      console.log("reach1", data);
      setEvent(
        event.map((e) => {
          if (e._id === response.data.result.data._id) {
            console.log("res", response);
            return response.data.result.data;
          } else {
            return e;
          }
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
  const handleEdit = async (id) => {
    try {
      setOpen(true);
      const getEventById = event.find((item) => item._id === id);
      console.log(getEventById);
      setUpdateEvent(getEventById);
    } catch (error) {
      console.log(error);
    }
  };
  const getEvent = async () => {
    try {
      const res = await get(`/award/list`);
      console.log("reach ", res);
      setEvent([...res.data.result.data]);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const getEvent = async () => {
      try {
        const res = await get(`/award/list`, {
          params: {
            limit: 10,
            page: 1,
          },
        });
        setEvent([...res.data.result.data]);
        setTotalCount(res.data.result.count);
      } catch (error) {
        console.log(error);
      }
    };
    getEvent();
  }, []);
  const handleChangePage = async (event, newPage) => {
    try {
      const res = await get(`/award/list`, {
        params: {
          limit: 10,
          page: newPage + 1,
        },
      });
      setEvent([...res.data.result.data]);
      setPage(newPage);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    if (value.trim() === "") {
      setSearchFilter([]);
      setShowSearch(false);
    }
  };
  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    try {
      const awardsSearch = await get(`/award/list`, {
        params: {
          search: {
            $or: [
              {
                title: { $regex: search.trim() },
              },
              {
                date: dayjs(search, "DD-MM-YYYY").isValid()
                  ? dayjs(search, "DD-MM-YYYY").toDate()
                  : "",
              },
              {
                location: { $regex: search.trim() },
              },
              {
                webView: search,
              },
            ],
          },
        },
      });
      setSearchFilter(awardsSearch.data.result.data);
      setShowSearch(true);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Card style={{ margin: 15, padding: 10 }}>
        <span
          style={{ color: "red", fontFamily: "cursive", marginLeft: "-90%" }}
        >
          Awards :
        </span>
      </Card>
      <div style={{ margin: "15px" }}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <div className={styles.searchInputContainer}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid xs="auto">
                <form onSubmit={handleSearchSubmit}>
                  <TextField
                    size="small"
                    value={search}
                    onChange={handleSearch}
                    label="Search"
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end" type="submit">
                            <Search />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </form>
              </Grid>
            </Grid>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Title</span>
                    <span
                      onClick={sortByTitle}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.title === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  {/* <TableCell align="center">
                    <span className="class-table-header">Event For</span>
                  </TableCell> */}
                  <TableCell align="center">
                    <span className="class-table-header">Location</span>
                    <span
                      onClick={sortByLocation}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.location === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Date</span>
                    <span
                      onClick={sortByDate}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.date === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Web View</span>
                    <span
                      onClick={sortByWebView}
                      style={{ cursor: "pointer", color: "#fff" }}
                    >
                      {sort.webView === "asc" ? "▲" : "▼"}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {showSearch
                  ? searchFilter.map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.title}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.location}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {dayjs(row.date).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.webView}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="edit-delete">
                            <Button onClick={() => handleEdit(row._id)}>
                              <Edit />
                            </Button>
                            <Button onClick={() => setDeleteModal(row._id)}>
                              <Delete />
                            </Button>
                          </div>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={row._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  : event.map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.title}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.location}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {dayjs(row.date).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.webView}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="edit-delete">
                            <Button onClick={() => handleEdit(row._id)}>
                              <Edit />
                            </Button>
                            <Button onClick={() => setDeleteModal(row._id)}>
                              <Delete />
                            </Button>
                          </div>
                          <DeleteModal
                            deleteModal={deleteModal}
                            handleDelete={handleDelete}
                            id={row._id}
                            setDeleteModal={setDeleteModal}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={totalCount}
          page={page}
          style={{ position: "sticky", overflow: "visible" }}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
        />
        <div className="add-icon">
          <AddAwards
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            handleUpdate={handleUpdate}
            updateEvent={updateEvent}
            getEvents={getEvent}
          />
        </div>
      </div>
    </div>
  );
};

export default Awards;
