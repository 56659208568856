import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import Stack from "@mui/material/Stack";
import {
  Avatar,
  Box,
  Button,
  Card,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  Checkbox,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import DeleteModal from "../Academic/DeleteModal";
import { del, get, post, put } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import { objHas } from "../../../utils/utils";
import TablePagination from "@mui/material/TablePagination";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import { LoadingButton } from "@mui/lab";
import {
  PrintSharp,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import DownloadForOfflineSharpIcon from "@mui/icons-material/DownloadForOfflineSharp";
import { toast } from "react-toastify";

const styles = {
  icon: {
    padding: "10px",
    fontSize: "12px",
    fontWeight: 500,
  },
  card: {
    padding: 15,
    marginBottom: 20,
    width: "100%",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  Button: {
    background: "#1b3779",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
};
const modal = {
  position: "absolute",
  top: "50%",
  left: "50%",

  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const StudentTable = () => {
  const pageRef = useRef(null);
  const [academicYears, setAcademicYears] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [schoolStudents, setSchoolStudents] = useState([]);
  const [updateStudent, setUpdateStudent] = useState(undefined);
  const [formData, setFormData] = useState({
    status: "active",
  });
  const [bulkAdmitModal, setBulkAdmitModal] = useState(false);
  const [bulkUpdateModal, setBulkUpdateModal] = useState(false);
  const [fileChoosen, setFileChoosen] = useState(undefined);
  const [totalCount, setTotalCount] = useState(100);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [showPagination, setShowPagination] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [rowsData, setRowsData] = useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [updatingBulk, setUpdatingBulk] = useState(false);

  const [disable, setDisable] = useState(false);

  const handleInputChange = (e) => {
    setSearch(e.target.value);
  };
  const [activeSortColumn, setActiveSortColumn] = useState("");
  // const [sortOrder, setSortOrder] = useState("asc");
  // const [sortedData, setSortedData] = useState(schoolStudents);

  const [sortField, setSortField] = useState("sectionOrder");
  const [sortOrder, setSortOrder] = useState("asc");
  const [selectedUser, setSelectedUser] = useState(null);
  const [selected, setSelected] = useState([]);

  //key value pair
  const [sort, setSort] = useState({
    order: "asc",
    rollNo: "asc",
    admissionNo: "asc",
    name: "asc",
    status: "asc",
    section: "asc",
  });

  const sortByRoll = (e) => {
    console.log("jjjj");
    if (sort.rollNo == "asc") {
      let sortedList = [
        ...schoolStudents.sort(
          (a, b) => b.academicInfo.rollNo - a.academicInfo.rollNo
        ),
      ];

      setSchoolStudents(sortedList);
      setSort({ ...sort, rollNo: "des" });
    } else {
      let sortedList = [
        ...schoolStudents.sort(
          (a, b) => a.academicInfo.rollNo - b.academicInfo.rollNo
        ),
      ];
      setSchoolStudents(sortedList);
      setSort({ ...sort, rollNo: "asc" });
    }
  };
  // admissionNo

  const sortByAdmissionNo = (e) => {
    console.log("jjjj");
    if (sort.admissionNo == "asc") {
      let sortedList = [
        ...schoolStudents.sort(
          (a, b) => b.academicInfo.admissionNo - a.academicInfo.admissionNo
        ),
      ];

      setSchoolStudents(sortedList);
      setSort({ ...sort, admissionNo: "des" });
    } else {
      let sortedList = [
        ...schoolStudents.sort(
          (a, b) => a.academicInfo.admissionNo - b.academicInfo.admissionNo
        ),
      ];
      setSchoolStudents(sortedList);
      setSort({ ...sort, admissionNo: "asc" });
    }
  };
  // {sortField === 'sectionOrder' && (
  //   <span style={{ cursor: 'pointer' }}>
  //     {sortOrder === 'asc' ? '▲' : '▼'}
  //   </span>

  // sorting.....
  const sortByName = (e) => {
    console.log("name");
    if (sort.name == "asc") {
      let sortedList = [
        ...schoolStudents.sort((a, b) =>
          b.basicInfo.name.localeCompare(a.basicInfo.name)
        ),
      ];

      setSchoolStudents(sortedList);
      setSort({ ...sort, name: "des" });
    } else {
      let sortedList = [
        ...schoolStudents.sort((a, b) =>
          a.basicInfo.name.localeCompare(b.basicInfo.name)
        ),
      ];
      setSchoolStudents(sortedList);
      setSort({ ...sort, name: "asc" });
    }
  };
  // status
  const sortByStatus = (e) => {
    console.log("status");

    if (sort.status == "asc") {
      let sortedList = [
        ...schoolStudents.sort((a, b) => b.status.localeCompare(a.status)),
      ];

      setSchoolStudents(sortedList);
      setSort({ ...sort, status: "des" });
    } else {
      let sortedList = [
        ...schoolStudents.sort((a, b) => a.status.localeCompare(b.status)),
      ];
      setSchoolStudents(sortedList);
      setSort({ ...sort, status: "asc" });
    }
  };

  const sortBySection = (e) => {
    if (sort.section == "asc") {
      let sortedList = [
        ...schoolStudents.sort((a, b) =>
          b.academicInfo.section.sectionName.localeCompare(
            a.academicInfo.section.sectionName
          )
        ),
      ];

      setSchoolStudents(sortedList);
      setSort({ ...sort, section: "des" });
    } else {
      let sortedList = [
        ...schoolStudents.sort((a, b) =>
          a.academicInfo.section.sectionName.localeCompare(
            b.academicInfo.section.sectionName
          )
        ),
      ];
      setSchoolStudents(sortedList);
      setSort({ ...sort, section: "asc" });
    }
  };

  const sortedUsers = schoolStudents.slice().sort((a, b) => {
    const fieldA = a[sortField];
    const fieldB = b[sortField];
    if (fieldA < fieldB) {
      return sortOrder === "asc" ? -1 : 1;
    }
    if (fieldA > fieldB) {
      return sortOrder === "asc" ? 1 : -1;
    }
    return 0;
  });

  const handleSelectAllClick = (event) => {
    console.log("oooooooooo", selected);
    if (event.target.checked) {
      const ids = schoolStudents.map((stu) => stu._id);
      setSelected([...ids]);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event, id) => {
    if (event.target.checked) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter((_id) => _id !== id));
    }
  };

  const handleIconChange = async (e) => {
    e.preventDefault();
    try {
      if (formData.section === "all") {
        const searchStudents = await get(urls.students.getAllStudent, {
          params: {
            search: {
              "basicInfo.name": { $regex: search, $options: "i" },
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              status: formData.status,
            },
            limit: rowsPerPage,
            page: page === 0 ? 1 : page + 1,
          },
        });

        setSchoolStudents(searchStudents.data.data);
        setShowPagination(true);
        setTotalCount(searchStudents.data.count);
      } else {
        const searchStudent = await get(urls.students.getAllStudent, {
          params: {
            search: {
              "basicInfo.name": { $regex: search, $options: "i" },
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              "academicInfo.section": formData.section,
              status: formData.status,
            },
            limit: rowsPerPage,
            page: page === 0 ? 1 : page + 1,
          },
        });

        setSchoolStudents(searchStudent.data.data);
        setShowPagination(true);
        setTotalCount(searchStudent.data.count);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const [count,setCount] = useState(0);

  useEffect(() => {
    // sortByName();
    // sortByAdmissionNo();
    //sortBySection();

    setSort({
      order: "asc",
      rollNo: "asc",
      admissionNo: "asc",
      name: "asc",
      status: "asc",
      section: "asc",
    });
    if (totalCount < rowsPerPage) setShowPagination(false);
  }, [schoolStudents.length]);

  useEffect(() => {
    setPage(0);
  }, [rowsPerPage]);

  useEffect(() => {
    const getAcademicYears = async () => {
      setPage(0);
      try {
        const res = await get(urls.administrator.academicYearGetAll);
        setFormData((prev) => ({
          ...prev,
          academicYear: res.data.data[0]._id,
        }));
        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, []);

  // useEffect(() => {
  //   const schoolStudentGet = async () => {
  //     try {
  //       const response = await get(urls.students.getAllStudent);
  //       setSchoolStudent(response.data.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   schoolStudentGet();
  // }, []);
  const handleOnChange = (e) => {
    setPage(0);
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleClassChange = async (e) => {
    setPage(0);

    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    try {
      const section = await get(urls.schoolSection.getAllSection, {
        params: {
          search: {
            class: e.target.value,
          },
        },
      });
      setFormData((prev) => ({
        ...prev,
        section: "all",
      }));
      console.log(section.data.data, "kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk________");
      setSections(section.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDelete = async (id) => {
    try {
      const res = await del(urls.students.delStudent, id);
      if (res.data.success) {
        const filteredStudent = schoolStudents.filter(
          (item) => item._id !== res.data.data._id
        );
        setSchoolStudents(filteredStudent);
      } else {
        console.log("Couldn't complete your request, Try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };
  function handlepaginationSearch() {
    if (!schoolStudents.length) {
      setShowPagination(false);
    }
  }

  //sortorder
  // const handleSortClick = (field) => {
  //   onSort(field);
  // };

  const handleGetAdmitSheet = async (e) => {
    e.preventDefault();

    try {
      const getSheet = await get(`/bulk-admit/student`, {
        // const getSheet = await get(`/bulk-admit/student/${formData.schoolClass}/${formData.section}/${formData.academicYear}`, {

        responseType: "blob",
      });
      const uri = URL.createObjectURL(getSheet.data);

      const link = document.createElement("a");
      link.href = uri;
      console.log("from", formData);
      const academicsYear = formData.academicYear;
      const getExcelyearById = academicYears.find(
        (excel) => excel._id === academicsYear
      );
      const classename = formData.schoolClass;
      const getExcelById = classes.find((excel) => excel._id === classename);
      const sectionsname = formData.section;
      const getExcelsectionById = sections.find(
        (excel) => excel._id === sectionsname
      );
      // link.setAttribute("download", `studentadmit.xlsx`);
      link.setAttribute(
        "download",
        `${getExcelyearById.academicYearFrom}-${getExcelyearById.academicYearTo} ${getExcelById.className}th std ,${getExcelsectionById.sectionName}.xlsx`
      );

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setLoadingBtn(false);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleGetPrintPdf = async (e) => {
    try {
      if (formData.section === "all") {
        const getPrint = await get(
          `/students-list-pdf/${formData.schoolClass}/${formData.academicYear}`,
          {
            responseType: "blob",
          }
        );
        const uri = URL.createObjectURL(getPrint.data);
        window.open(uri, "__blank");
      } else {
        const getPrint = await get(
          `/students-list-sections/${formData.schoolClass}/${formData.section}/${formData.academicYear}`,
          {
            responseType: "blob",
          }
        );

        const uri = URL.createObjectURL(getPrint.data);
        window.open(uri, "__blank");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleStudentSearch();
  }, [page, rowsPerPage, formData]);

  // const handleStudentSearch = async () => {
  //   try {
  //     if (formData.section === "all") {
  //       const searchParams = search.trim().length
  //         ? {
  //             "basicInfo.name": search.trim().length
  //               ? { $regex: search, $options: "i" }
  //               : "",
  //             academicYear: formData.academicYear,
  //             "academicInfo.class": formData.schoolClass,
  //             status: formData.status,
  //           }
  //         : {
  //             academicYear: formData.academicYear,
  //             "academicInfo.class": formData.schoolClass,
  //             status: formData.status,
  //           };
  //       const searchStudents = await get(urls.students.getAllStudent, {
  //         params: {
  //           search: searchParams,
  //           limit: rowsPerPage,
  //           page: page === 0 ? 1 : page + 1,
  //         },
  //       });

  //       setSchoolStudents(searchStudents.data.data);
  //       setShowPagination(true);
  //       setTotalCount(searchStudents.data.count);
  //     } else {
  //       let searchParams = search.trim().length
  //         ? {
  //             "basicInfo.name": search.trim().length
  //               ? { $regex: search, $options: "i" }
  //               : "",
  //             academicYear: formData.academicYear,
  //             "academicInfo.class": formData.schoolClass,
  //             "academicInfo.section": formData.section,
  //             status: formData.status,
  //           }
  //         : {
  //             academicYear: formData.academicYear,
  //             "academicInfo.class": formData.schoolClass,
  //             "academicInfo.section": formData.section,
  //             status: formData.status,
  //           };
  //       const searchStudent = await get(urls.students.getAllStudent, {
  //         params: {
  //           search: searchParams,
  //           limit: rowsPerPage,
  //           page: page === 0 ? 1 : page + 1,
  //         },
  //       });

  //       setSchoolStudents(searchStudent.data.data);
  //       setShowPagination(true);
  //       setTotalCount(searchStudent.data.count);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleGetDownloadSheet = async (e) => {
    e.preventDefault();

    setLoadingBtn(true);
    try {
      const getDownload = await get(
        `students-list-excel/${formData.schoolClass}/${formData.academicYear}`,
        {
          responseType: "blob",
        }
      );
      const uri = URL.createObjectURL(getDownload.data);
      const link = document.createElement("a");
      console.log(uri);
      link.href = uri;

      const academicsYear = formData.academicYear;
      const getExcelyearById = academicYears.find(
        (excel) => excel._id === academicsYear
      );
      const classename = formData.schoolClass;
      const getExcelById = classes.find((excel) => excel._id === classename);
      link.setAttribute(
        "download",
        `${getExcelyearById.academicYearFrom}-${getExcelyearById.academicYearTo} ${getExcelById.className}th std.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      setLoadingBtn(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        let search = res.data.data.length
          ? { class: res.data.data[0]._id }
          : {};
        const resSections = await get(urls.schoolSection.getAllSection, {
          params: {
            search,
          },
        });

        setSections(resSections.data.data);
        setClasses(res.data.data);
        setFormData((prev) => ({
          ...prev,
          schoolClass: res.data.data[0]._id,
          section: "all",
        }));
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);

  useEffect(() => {
    const getSections = async () => {
      try {
        const res = await get(urls.schoolSection.getAllSection);
        setSections(res.data.data);
        setFormData((prev) => ({
          ...prev,
          section: "all",
        }));
        setPage(0);
      } catch (error) {
        console.log(error);
      }
    };
    getSections();
  }, []);

  useEffect(() => {
    setPage(0);
  }, [formData]);

  const handleEdit = async (id) => {
    try {
      setOpen(true);
      const getStudentById = schoolStudents.find((item) => item._id === id);
      setUpdateStudent(getStudentById);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleStudentSearch();
  }, [page, rowsPerPage, formData]);

  const handleStudentSearch = async () => {
    try {
      if (formData.section === "all") {
        const searchParams = search.trim().length
          ? {
              "basicInfo.name": search.trim().length
                ? { $regex: search, $options: "i" }
                : "",
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              status: formData.status,
            }
          : {
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              status: formData.status,
            };
        const searchStudents = await get(urls.students.getAllStudent, {
          params: {
            search: searchParams,
            limit: rowsPerPage,
            page: page === 0 ? 1 : page + 1,
          },
        });

        console.log(searchStudents.data.data, "1234567890");

        setSchoolStudents(searchStudents.data.data);
        setShowPagination(true);
        setTotalCount(searchStudents.data.count);
      } else {
        let searchParams = search.trim().length
          ? {
              "basicInfo.name": search.trim().length
                ? { $regex: search, $options: "i" }
                : "",
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              "academicInfo.section": formData.section,
              status: formData.status,
            }
          : {
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              "academicInfo.section": formData.section,
              status: formData.status,
            };
        const searchStudent = await get(urls.students.getAllStudent, {
          params: {
            search: searchParams,
            limit: rowsPerPage,
            page: page === 0 ? 1 : page + 1,
          },
        });

        setSchoolStudents(searchStudent.data.data);
        setShowPagination(true);
        setTotalCount(searchStudent.data.count);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleStudentSearchSubmit = async (e) => {
    e.preventDefault();
    try {
      if (formData.section === "all") {
        const searchParams = search.trim().length
          ? {
              "basicInfo.name": search.trim().length
                ? { $regex: search, $options: "i" }
                : "",
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              status: formData.status,
            }
          : {
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              status: formData.status,
            };
        const searchStudents = await get(urls.students.getAllStudent, {
          params: {
            search: searchParams,
            limit: rowsPerPage,
            page: page === 0 ? 1 : page + 1,
          },
        });

        setSchoolStudents(searchStudents.data.data);
        setShowPagination(true);
        setTotalCount(searchStudents.data.count);
      } else {
        let searchParams = search.trim().length
          ? {
              "basicInfo.name": search.trim().length
                ? { $regex: search, $options: "i" }
                : "",
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              "academicInfo.section": formData.section,
              status: formData.status,
            }
          : {
              academicYear: formData.academicYear,
              "academicInfo.class": formData.schoolClass,
              "academicInfo.section": formData.section,
              status: formData.status,
            };
        const searchStudent = await get(urls.students.getAllStudent, {
          params: {
            search: searchParams,
            limit: rowsPerPage,
            page: page === 0 ? 1 : page + 1,
          },
        });

        console.log(searchStudent.data.data, "1234567890");

        setSchoolStudents(searchStudent.data.data);
        setShowPagination(true);
        setTotalCount(searchStudent.data.count);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    console.log(newPage);
    setPage(newPage);
  };
  const handleSubmitBulkAdmit = async (e) => {
    e.preventDefault();
    try {
      const formDataFile = new FormData();
      formDataFile.append("sheet", e.target.bulkAdmit.files[0]);
      if (formData.section !== "all")
        await post(
          `/bulk-admit/student/${formData.schoolClass}/${formData.section}/${formData.academicYear}`,
          formDataFile
        );
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetUpdateSheet = async (e) => {
    e.preventDefault();
    setLoadingBtn(true);

    try {
      if (formData.section !== "all") {
        const getSheet = await get(
          `/bulk-update/student/${formData.schoolClass}/${formData.section}/${formData.academicYear}`,
          {
            responseType: "blob",
          }
        );
        const uri = URL.createObjectURL(getSheet.data);

        const link = document.createElement("a");
        link.href = uri;

        const academicsYear = formData.academicYear;
        const getExcelyearById = academicYears.find(
          (excel) => excel._id === academicsYear
        );
        const classename = formData.schoolClass;
        const getExcelById = classes.find((excel) => excel._id === classename);
        const sectionsname = formData.section;
        const getExcelsectionById = sections.find(
          (excel) => excel._id === sectionsname
        );

        link.setAttribute(
          "download",
          `${getExcelyearById.academicYearFrom}-${getExcelyearById.academicYearTo} ${getExcelById.className}th std ,${getExcelsectionById.sectionName}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoadingBtn(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingBtn(false);
    }
  };
  const handleSubmitBulkUpdate = async (e) => {
    e.preventDefault();
    setUpdatingBulk(true);
    try {
      const formData = new FormData();
      formData.append("sheet", e.target.bulkUpdate.files[0]);
      await put(`/bult-update/student`, "", formData);
    } catch (error) {
      console.log(error);
    }
    setUpdatingBulk(false);
  };

  const handleFileChange = (e) => {
    setFileChoosen(e.target.files[0].name);
  };

  const getSerialNumber = (index) => {
    return (
      schoolStudents[index]?.serialNumber || page * rowsPerPage + index + 1
    );
  };

  const handleChangeMultiple = async (e) => {
    try {
      const { data, status } = await post(`${urls.students.changeStatus}`, {
        studentIds: selected,
        status: formData.status,
      });
      if (data.success) {
        handleStudentSearch();
        setSelected([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ margin: "15px" }}>
      <Card style={styles.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span style={{ color: "red", fontFamily: "cursive" }}>
              Students :
            </span>
          </Grid>
        </Grid>
      </Card>
      <Card style={styles.card}>
        <Box sx={{ flexGrow: 1 }}>
          <form onSubmit={handleStudentSearchSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6} lg={2}>
                <FormControl
                  variant="standard"
                  style={styles.textfield}
                  required
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Academic Year
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="academicYear"
                    value={formData.academicYear || ""}
                    onChange={handleOnChange}
                  >
                    {academicYears &&
                      academicYears.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.academicYearFrom} - {row.academicYearTo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl
                  variant="standard"
                  style={styles.textfield}
                  required
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Class
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="schoolClass"
                    value={formData.schoolClass || ""}
                    onChange={handleClassChange}
                  >
                    {classes &&
                      classes.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.className}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl
                  variant="standard"
                  style={styles.textfield}
                  required
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Section
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="section"
                    value={formData.section || ""}
                    onChange={handleOnChange}
                  >
                    {formData.schoolClass && (
                      <MenuItem value="all">----all----</MenuItem>
                    )}
                    {sections &&
                      sections
                        .slice() // Creating the copy of the array to avoid mutating the original array
                        .sort((a, b) =>
                          a.sectionName.localeCompare(b.sectionName)
                        )
                        .map((row, index) => (
                          <MenuItem
                            key={row._id}
                            value={row._id}
                            sx={{ fontSize: 12, fontWeight: 500 }}
                          >
                            {row.sectionName}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl
                  variant="standard"
                  style={styles.textfield}
                  required
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="status"
                    value={formData.status || ""}
                    onChange={handleOnChange}
                  >
                    <MenuItem
                      value={"active"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Active
                    </MenuItem>
                    <MenuItem
                      value={"inactive"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Inactive
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={1}
                style={{ alignSelf: "flex-end" }}
              >
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  style={styles.Button}
                  onClick={handlepaginationSearch}
                >
                  SEARCH
                </Button>
              </Grid>
              {formData.schoolClass &&
                formData.academicYear &&
                formData.section !== "all" && (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={3}
                    style={{
                      alignSelf: "flex-end",
                      display: "flex",
                      gap: "45px",
                      // justifyContent: "flex-end",
                    }}
                  >
                    <Tooltip title="Bulk Admit">
                      <Button
                        size="small"
                        variant="contained"
                        style={styles.Button}
                        onClick={() => setBulkAdmitModal(true)}
                      >
                        BULK ADMIT
                      </Button>
                    </Tooltip>
                    <Tooltip title="Bulk Update">
                      <Button
                        size="small"
                        variant="contained"
                        style={styles.Button}
                        onClick={() => setBulkUpdateModal(true)}
                      >
                        BULK UPDATE
                      </Button>
                    </Tooltip>
                  </Grid>
                )}
            </Grid>
          </form>
        </Box>
      </Card>
      <Card style={styles.card}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <LoadingButton
              onClick={handleGetDownloadSheet}
              loading={loadingBtn}
            >
              <span style={styles.icon}>
                <DownloadForOfflineSharpIcon />
              </span>
            </LoadingButton>

            <LoadingButton onClick={handleGetPrintPdf}>
              <span style={styles.icon}>
                <PrintSharp />
              </span>
            </LoadingButton>

            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search here..."
              inputProps={{ "aria-label": "search google maps" }}
              onChange={handleInputChange}
            />
            <IconButton
              onClick={handleIconChange}
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "10px",
            }}
          >
            <Stack spacing={4} direction="row">
              <Link to={"/student-bulk-photo"}>
                <Button size="small" variant="contained">
                  Bulk Photo
                </Button>
              </Link>
              {selected.length >= 1 && formData.status == "inactive" ? (
                <Button
                  variant="contained"
                  onClick={handleChangeMultiple}
                  size="small"
                  style={{ width: "30px", height: "30px", marginTop: "1px" }}
                  color="primary"
                >
                  Active
                </Button>
              ) : null}

              {selected.length >= 1 && formData.status == "active" ? (
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleChangeMultiple}
                  style={{ width: "80px", height: "30px", marginTop: "1px" }}
                  color="primary"
                >
                  InActive
                </Button>
              ) : null}
            </Stack>
          </div>
        </div>
      </Card>
      <Box sx={{ width: "100%" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead
              style={{
                background: "rgb(27 55 121)",
                position: "relative",
                botton: "30px",
              }}
            >
              <TableRow>
                <TableCell sx={{ color: "white" }} align="center">
                  <Checkbox
                    checked={selected.length === schoolStudents.length}
                    indeterminate={
                      selected.length > 0 &&
                      schoolStudents.length > selected.length
                    }
                    onChange={handleSelectAllClick}
                    color="primary"
                  />
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Section Order</span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Photo</span>
                </TableCell>
                <TableCell align="center">
                  <span onClick={sortByName} className="class-table-header">
                    Name
                    <span style={{ cursor: "pointer" }}>
                      {sort.name === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span onClick={sortByStatus} className="class-table-header">
                    Status
                    <span style={{ cursor: "pointer" }}>
                      {sort.status === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span onClick={sortBySection} className="class-table-header">
                    Section
                    <span style={{ cursor: "pointer" }}>
                      {sort.section === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span onClick={sortByRoll} className="class-table-header">
                    Roll No.
                    <span style={{ cursor: "pointer" }}>
                      {sort.rollNo === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span
                    onClick={sortByAdmissionNo}
                    className="class-table-header"
                  >
                    Admission No.
                    <span style={{ cursor: "pointer" }}>
                      {sort.admissionNo === "asc" ? "▲" : "▼"}
                    </span>
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span className="class-table-header">Action</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schoolStudents.map((row, index) => (
                <TableRow
                  key={row._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">
                    <Checkbox
                      checked={selected.includes(row._id)}
                      onChange={(e) => handleClick(e, row._id)}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell align="center">{getSerialNumber(index)}</TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    <Avatar src={objHas(row, "studentPhoto.link", "")} />
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                    key={row.id}
                  >
                    {row.basicInfo.name}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row.status}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row.academicInfo.section
                      ? row.academicInfo.section.sectionName
                      : "NA"}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row.academicInfo.rollNo}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row.academicInfo.admissionNo}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    <div className="edit-delete">
                      <Tooltip title="Edit" disableInteractive>
                        <Link to={`/edit-student/${row._id}`}>
                          <Button onClick={() => handleEdit(row._id)}>
                            <Edit />
                          </Button>
                        </Link>
                      </Tooltip>
                      <Tooltip title="Delete" disableInteractive>
                        <Button onClick={() => setDeleteModal(row._id)}>
                          <Delete />
                        </Button>
                      </Tooltip>
                    </div>
                    <DeleteModal
                      deleteModal={deleteModal}
                      handleDelete={handleDelete}
                      id={row._id}
                      setDeleteModal={setDeleteModal}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!schoolStudents.length && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
          >
            No data found
          </Typography>
        )}
      </Box>
      {showPagination ? (
        <TablePagination
          ref={pageRef}
          style={{ display: "flex", alignItems: "start" }}
          //rowsPerPageOptions={rowsData || [10, 20, 30, 40]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}

          // onPageChange={handleChangePage}
          // rowsPerPage={rowsPerPage}
          // onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}

      <Modal
        open={bulkAdmitModal}
        onClose={() => setBulkAdmitModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modal}>
          <div style={{ textAlign: "center", marginBottom: "30px" }}>
            <span style={{ color: "red", fontFamily: "cursive" }}>
              Bulk Admit
            </span>
          </div>
          <form onSubmit={handleSubmitBulkAdmit}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Tooltip title="Get Sample">
                  <LoadingButton
                    variant="contained"
                    loading={loadingBtn}
                    // style={styles.Button}
                    onClick={handleGetAdmitSheet}
                    sx={{
                      backgroundColor: "#1b3779",
                      ":hover": { backgroundColor: "#1b3779" },
                    }}
                  >
                    Sample
                  </LoadingButton>
                </Tooltip>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <Tooltip title="Upload">
                    <Button
                      variant="contained"
                      style={styles.Button}
                      component="label"
                    >
                      upload
                      <input
                        name="bulkAdmit"
                        onChange={handleFileChange}
                        hidden
                        accept=".xlsx, .xls, xlsm, .xlsb, .csv, .ods"
                        type="file"
                      />
                    </Button>
                  </Tooltip>
                </div>
                <div>{fileChoosen && fileChoosen}</div>
              </div>
              <div>
                <Tooltip title="Submit">
                  <Button
                    variant="contained"
                    style={styles.Button}
                    type="submit"
                  >
                    submit
                  </Button>
                </Tooltip>
              </div>
            </div>
          </form>
        </Box>
      </Modal>
      <Modal
        open={bulkUpdateModal}
        onClose={() => setBulkUpdateModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modal}>
          <div style={{ textAlign: "center", marginBottom: "30px" }}>
            <span style={{ color: "red", fontFamily: "cursive" }}>
              Bulk Update
            </span>
          </div>
          <form onSubmit={handleSubmitBulkUpdate}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Tooltip title="Get Sample">
                  <LoadingButton
                    variant="contained"
                    size="small"
                    type="submit"
                    loading={loadingBtn}
                    onClick={handleGetUpdateSheet}
                    sx={{
                      backgroundColor: "#1b3779",
                      ":hover": { backgroundColor: "#1b3779" },
                    }}
                  >
                    Download
                  </LoadingButton>
                </Tooltip>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <Tooltip title="Upload">
                    <Button
                      variant="contained"
                      style={styles.Button}
                      component="label"
                    >
                      upload
                      <input
                        name="bulkUpdate"
                        onChange={handleFileChange}
                        hidden
                        accept=".xlsx, .xls, xlsm, .xlsb, .csv, .ods"
                        type="file"
                      />
                    </Button>
                  </Tooltip>

                  <div>{fileChoosen && fileChoosen}</div>
                </div>
              </div>
              <div>
                <Tooltip title="Submit">
                  <LoadingButton
                    loading={updatingBulk}
                    variant="contained"
                    color="primary"
                    //style={styles.Button}
                    type="submit"
                    disabled={updatingBulk}
                  >
                    submit
                  </LoadingButton>
                </Tooltip>
              </div>
            </div>
          </form>
        </Box>
      </Modal>

      <div className="add-btn">
        <Tooltip title="Add" disableInteractive>
          <Link to="/addstudent">
            <Fab
              size="medium"
              style={{ background: "#1b3779", color: "#fff" }}
              aria-label="add"
            >
              <Add />
            </Fab>
          </Link>
        </Tooltip>
      </div>
    </div>
  );
};

export default StudentTable;
