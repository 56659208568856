import React, { useEffect, useState } from "react";

import css from "../../../styles/fees/ClassMap.module.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { get, post, put } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import { Add } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";

const ClassMap = () => {
  const [selectReceipt, setSelectReceipt] = useState("");
  const [receipts, setReceipts] = useState([]);
  const [classMaps, setClassMaps] = useState([]);
  const [addDialog, setAddDialog] = useState(false);
  const [addForm, setAddForm] = useState({});
  const [schoolClasses, setSchoolClasses] = useState([]);
  const [addDate, setAddDate] = useState(dayjs());
  const [editClassMap, setEditClassMap] = useState("");
  const [installments, setInstallments] = useState([]);
  const [sort, setSort] = useState({
    className: "asc",
    dueDate: "asc",
    fee: "asc",
  });

  const sortByClass = () => {
    console.log("className");

    if (sort.className === "asc") {
      let sortedList = [
        ...classMaps.sort((a, b) => b.class.className - a.class.className),
      ];

      setClassMaps(sortedList);
      setSort({ ...sort, className: "des" });
    } else {
      let sortedList = [
        ...classMaps.sort((a, b) => a.class.className - b.class.className),
      ];
      setClassMaps(sortedList);
      setSort({ ...sort, className: "asc" });
    }
  };

  const sortByFee = () => {
    console.log("Fee");

    if (sort.fee === "asc") {
      let sortedList = [...classMaps.sort((a, b) => b.fee - a.fee)];

      setClassMaps(sortedList);
      setSort({ ...sort, fee: "des" });
    } else {
      let sortedList = [...classMaps.sort((a, b) => a.fee - b.fee)];
      setClassMaps(sortedList);
      setSort({ ...sort, fee: "asc" });
    }
  };

  const compareDatesAsc = (a, b) => {
    let dateA = new Date(a.dueDate);
    let dateB = new Date(b.dueDate);

    return dateA - dateB;
  };

  const compareDatesDec = (a, b) => {
    let dateA = new Date(a.dueDate);
    let dateB = new Date(b.dueDate);

    return dateB - dateA;
  };

  const sortByDate = () => {
    console.log("Date");

    if (sort.dueDate === "asc") {
      let sortedList = [...classMaps.sort(compareDatesDec)];

      setClassMaps(sortedList);
      setSort({ ...sort, dueDate: "des" });
    } else {
      let sortedList = [...classMaps.sort(compareDatesAsc)];
      setClassMaps(sortedList);
      setSort({ ...sort, dueDate: "asc" });
    }
  };

  useEffect(() => {
    const init = async () => {
      try {
        const { data: allReceipts, status } = await get(
          `${urls.recepitBook.getAll}`
        );
        if (status > 199 && status < 299) {
          setReceipts(allReceipts.data);
          if (allReceipts.data.length > 0) {
            setSelectReceipt(allReceipts.data[0]?._id);
          }
        }
      } catch (error) {}
    };
    const getSchoolClasses = async () => {
      try {
        const { data: schoolClassesList, status } = await get(
          `${urls.class.getAllClass}`
        );
        if (status > 199 && status < 299) {
          setSchoolClasses(schoolClassesList.data);
        }
      } catch (error) {}
    };
    init();
    getSchoolClasses();
  }, []);
  useEffect(() => {
    if (selectReceipt) {
      const fetchClassMaps = async () => {
        try {
          await getClassMaps();
        } catch (error) {}
      };
      fetchClassMaps();
    }
  }, [selectReceipt]);

  const getClassMaps = async () => {
    try {
      const { data: classMapList, status } = await get(
        `${urls.classMap.list}${selectReceipt}`
      );
      if (status > 199 && status < 299) {
        setClassMaps(classMapList.data);
        return {
          data: classMapList,
          status,
        };
      }
    } catch (error) {
      return error;
    }
  };

  const handleCloseAddDialog = () => {
    setAddForm({});
    setEditClassMap("");
    setInstallments([]);
    setAddDialog(false);
  };
  const handleOpenAddDialog = () => {
    setAddDialog(true);
  };
  const handleAddForm = (e) => {
    const { name, value } = e.target;
    if (name == "fee") {
      setAddForm((prev) => ({
        ...prev,
        [name]: parseInt(value),
      }));
    } else {
      setAddForm((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  const handleAddSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!editClassMap) {
        const { status } = await post(
          `${urls.classMap.create}${selectReceipt}`,
          {
            classId: addForm.schoolClass,
            dueDate: addDate.toDate(),
            fee: addForm.fee,
            // installMents:installments,
            // paymentStyle:installments.length ? 'Installments' : 'All at once'
          }
        );
        if (status > 199 && status < 299) {
          await getClassMaps();
          handleCloseAddDialog();
        }
      } else {
        const { status } = await put(
          `${urls.classMap.update}${editClassMap}`,
          "",
          {
            classId: addForm.schoolClass,
            dueDate: addDate.toDate(),
            fee: addForm.fee,
            // installMents:installments,
            // paymentStyle:installments.length ? 'Installments' : 'All at once'
          }
        );
        if (status > 199 && status < 299) {
          await getClassMaps();
          handleCloseAddDialog();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClassMapEdit = (id, data) => {
    setEditClassMap(id);
    setAddForm({
      schoolClass: data.class._id,
      fee: data.fee,
    });
    setAddDate(dayjs(data.dueDate));
    setInstallments(data?.installMents);
    handleOpenAddDialog();
  };
  const handleAddInstallments = () => {
    setInstallments((prev) => [
      ...prev,
      {
        amount: 0,
        dueDate: dayjs(),
      },
    ]);
  };
  const handleInstallmentChange = (val, key, changeIndex) => {
    setInstallments((prev) =>
      prev.map((installment, index) =>
        index === changeIndex
          ? key == "amount"
            ? { ...installment, [key]: parseInt(val) }
            : { ...installment, [key]: val }
          : installment
      )
    );
  };
  const handleStatusChange = async (id) => {
    try {
      const { status } = await put(`${urls.classMap.status}${id}`, "");
      if (status > 199 && status < 299) {
        await getClassMaps();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={css.container}>
      <div className={css.funcContainer}>
        <FormControl
          sx={{
            width: "16%",
          }}
        >
          <InputLabel>Select Receipt</InputLabel>
          <Select
            size="small"
            value={selectReceipt || ""}
            onChange={(e) => setSelectReceipt(e.target.value)}
            label="Select Receipt"
          >
            {receipts.map((receipt) => (
              <MenuItem value={receipt._id} key={receipt._id}>
                {receipt.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button onClick={handleOpenAddDialog} size="small" variant="contained">
          <Add /> Add class map
        </Button>
      </div>
      <div className={css.tableContainer}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow>
                <TableCell sx={{ color: "white" }} align="center">
                  #SL
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Class
                  <span
                    onClick={sortByClass}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.className === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Fee
                  <span
                    onClick={sortByFee}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.fee === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell sx={{ color: "white" }} align="center">
                  Due Date
                  <span
                    onClick={sortByDate}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.dueDate === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                {/* <TableCell sx={{color:'white'}} align="center">Installments</TableCell> */}
                <TableCell sx={{ color: "white" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {classMaps.map((classMap, index) => (
                <TableRow
                  key={classMap._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {classMap.class?.className}
                  </TableCell>
                  <TableCell align="center">{classMap.fee}</TableCell>
                  <TableCell align="center">
                    {dayjs(classMap.dueDate).format("DD-MM-YYYY")}
                  </TableCell>
                  {/* <TableCell align="center">{classMap.installMents?.length}</TableCell> */}
                  <TableCell align="center">
                    <div className={css.tableActions}>
                      <Button
                        onClick={() =>
                          handleClassMapEdit(classMap._id, classMap)
                        }
                        size="small"
                        variant="contained"
                      >
                        edit
                      </Button>
                      <Button
                        color={classMap.activated ? "primary" : "error"}
                        onClick={() => handleStatusChange(classMap._id)}
                        size="small"
                        variant="contained"
                      >
                        {classMap.activated ? "active" : "inactive"}
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Dialog open={addDialog} onClose={handleCloseAddDialog}>
        <form onSubmit={handleAddSubmit}>
          <DialogTitle>{editClassMap ? "Update" : "Add"} Class Map</DialogTitle>
          <DialogContent>
            <div className={css.dialogContainer}>
              <div className={css.dialogContainerFlex}>
                <FormControl className={css.dialog_select}>
                  <InputLabel>Class</InputLabel>
                  <Select
                    size="small"
                    name="schoolClass"
                    value={addForm.schoolClass || ""}
                    onChange={handleAddForm}
                    label="Class"
                  >
                    {schoolClasses.map((schoolClass) => (
                      <MenuItem key={schoolClass._id} value={schoolClass._id}>
                        {schoolClass.className}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  type="number"
                  className={css.dialog_select}
                  label="Fee"
                  size="small"
                  name="fee"
                  value={addForm.fee || ""}
                  onChange={handleAddForm}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Due Date"
                    value={addDate}
                    onChange={(newDate) => setAddDate(newDate)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        className={css.dialog_select}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              {/* <div>
                            <Button onClick={handleAddInstallments} size="small" variant="contained"><Add /> Add installments</Button>
                        </div>
                        <div className={css.installmentsContainer}>
                            {installments.map((installment,index) => (
                                <div className={css.installmentsContainerFlex}>
                                    <TextField 
                                    label={`installment ${index+1}`}
                                    value={installment.amount}
                                    size="small"
                                    onChange={(e) =>  (Number(e.target.value) || e.target.value === '') && handleInstallmentChange(Number(e.target.value),'amount',index)}
                                    />
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker 
                                        label="Due Date"
                                        value={installment.dueDate}
                                        onChange={(newDate) => handleInstallmentChange(newDate,'dueDate',index)}
                                        renderInput={(params) => (
                                            <TextField  {...params} size="small" />
                                        )}
                                        />
                                    </LocalizationProvider>
                                </div>
                            ))}
                        </div> */}
            </div>
          </DialogContent>
          <DialogActions>
            <Button color="warning" onClick={handleCloseAddDialog}>
              Cancel
            </Button>
            <Button type="submit">{editClassMap ? "update" : "add"}</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default ClassMap;
