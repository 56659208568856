import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Tooltip,
} from "@mui/material";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 500,
  bgcolor: "background.paper",
  p: 4,
  button: {
    background: "#1b3779",
    color: "#fff",
    m: 1,
    ":hover": { background: "#1b3779" },
  },
};

const AddExamTerm = (props) => {
  const [examTitle, setExamTitle] = useState("");
  const [examType, setExamType] = useState("");
  const [assigmentMark, setAssigmentMark] = useState("");

  const [note, setNote] = useState("");
  useEffect(() => {
    if (props.updateExamTerm) {
      setExamTitle(props.updateExamTerm.examTitle);
      setExamType(props.updateExamTerm.examType);
      setAssigmentMark(props.updateExamTerm.assigmentMark);
      setNote(props.updateExamTerm.note);
    }
  }, [props.updateExamTerm]);

  const handleExamTitle = (e) => {
    setExamTitle(e.target.value);
  };
  const handleExamType = (e) => {
    setExamType(e.target.value);
  };
  const handleAssignmentMark = (e) => {
    setAssigmentMark(e.target.value);
  };

  const handleExamNote = (e) => {
    setNote(e.target.value);
  };
  const handleSubmit = (e) => {
    if (props.updateExamTerm) {
      props.handleUpdate(
        {
          examTitle,
          examType,
          assigmentMark,
          note,
        },
        props.updateExamTerm._id
      );
    } else {
      props.handleSubmit(e, {
        examTitle,
        examType,
        assigmentMark,
        note,
      });
    }
    setExamTitle("");
    setExamType("");
    setAssigmentMark("");
    setNote("");
    props.handleClose();
  };

  const handleCloseModal = () => {
    setExamTitle("");
    setExamType("");
    setAssigmentMark("");
    setNote("");
    props.handleClose();
  };

  const handleClose = () => {
    setExamTitle("");
    setExamType("");
    setAssigmentMark("");
    setNote("");
    props.handleClose();
  };
  return (
    <div>
      <Tooltip title="Add" disableInteractive>
        <Fab
          size="medium"
          style={{ background: "#1b3779", color: "#fff" }}
          aria-label="add"
          onClick={props.handleOpen}
        >
          <Add />
        </Fab>
      </Tooltip>
      <Modal
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="form-tag" onSubmit={handleSubmit}>
          <Box sx={styles}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign="center"
              marginBottom="30px"
            >
              Add Exam Grade
            </Typography>
            <div>
              <TextField
                required
                id="filled-required"
                variant="standard"
                label="Exam Title"
                value={examTitle}
                onChange={handleExamTitle}
                sx={{ m: 1, minWidth: 227, label: { fontSize: 12 } }}
              />
              <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 227 }}
                required
              >
                <InputLabel
                  id="demo-simple-select-filled-label"
                  sx={{ fontSize: 12 }}
                >
                  Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={examType}
                  onChange={handleExamType}
                >
                  <MenuItem
                    value={"general"}
                    sx={{ fontSize: 12, fontWeight: 500 }}
                  >
                    general
                  </MenuItem>
                  <MenuItem
                    value={"competitive"}
                    sx={{ fontSize: 12, fontWeight: 500 }}
                  >
                    competitive
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 227 }}
                required
              >
                <InputLabel
                  id="demo-simple-select-filled-label"
                  sx={{ fontSize: 12 }}
                >
                  Assignment Mark
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={assigmentMark}
                  onChange={handleAssignmentMark}
                >
                  <MenuItem
                    value={"active"}
                    sx={{ fontSize: 12, fontWeight: 500 }}
                  >
                    Active
                  </MenuItem>
                  <MenuItem
                    value={"inactive"}
                    sx={{ fontSize: 12, fontWeight: 500 }}
                  >
                    Inactive
                  </MenuItem>
                </Select>
              </FormControl>
              <div>
                <TextareaAutosize
                  maxRows={4}
                  aria-label="maximum height"
                  placeholder="Drop a note"
                  value={note}
                  onChange={handleExamNote}
                  style={{
                    width: 712,
                    maxHeight: 110,
                    margin: 8,
                    padding: 10,
                    minHeight: 118,
                  }}
                />
              </div>
            </div>
            <div className="cancel-submit-btn">
              <div>
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  size="medium"
                  type="button"
                  style={styles.button}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!(examTitle && examType && assigmentMark)}
                  variant="contained"
                  size="medium"
                  type="submit"
                  style={styles.button}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
};

export default AddExamTerm;
