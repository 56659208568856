import React, { useEffect, useState } from "react";
import "../../styles/components/BirthdayEvents/ImageSlider.scss";
import birthday from "../../Data/birthday.json";
import {get} from '../../services/apis'

const List = () => {
  const [birthdays,setBirthdays] = useState([]);

  useEffect(() => {
    const getBirthdays = async () => {
      try {
        const birthdays = await get(`/student-birthday`);
        setBirthdays(birthdays.data.data);
      } catch (error) {
      }
    }
    getBirthdays();
  },[])

  
  const getBdayList = () => {
    return birthdays.map((student) => {
      return <p key={student._id}>{`${student.basicInfo.name} - ${student.academicInfo.class.className} (${student.academicInfo.section.sectionName})`}</p>;
    });
  };

  return (
    <div>
      <div className="birthday-today">
        <h1>
          {birthday.todayEvent.todayBday}
          <img className="bday-img" src="cake-bg3.webp" alt="" />
        </h1>
        {birthdays.length === 0 && <h5 style={{textAlign:'center'}}>No birthdays today</h5>}
        <div className="">
          <div className="d-flex">
            <div className="scroll-b-t list-item-color">{getBdayList()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
